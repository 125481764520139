import React from "react";
import { getLoggedUser } from "../../context/auth";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { handleExportPdf } from "../GrowerStatement/export";
import TableVariety from "../BonusDeduct/TableVariety";

const DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    const currentDate = parseInt(DateFormat(new Date(), "yyyymmdd"));
    loggedUser = getLoggedUser();
    let urlApiCropYear =
      GlobalConfig.REACT_APP_API_CROPYEAR_URL +
      "allbyprocessor/" +
      loggedUser.processorId;
    let urlapi = "";
    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      urlapi =
        GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL +
        "bycropyear/" +
        props.growerId;
    }

    let labelValueInshell = `Inshell`;
    let labelValueShell = `Shelled`;
    this.state = {
      currentDate: currentDate,
      urlapi: urlapi,
      urlApiCropYear: urlApiCropYear,
      urllist: process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST,
      processorId: loggedUser.processorId,
      cropYearId: "",
      growerId: props.growerId,
      testType: {
        xpool: false,
        isDynamic: false,
        basePriceByPayment: false,
        paymentPercentBased: true,
        multiPricesSameLot: false,
      },
      paymentSetting: [],
      basePrice: [],
      bonusDeduct: [],
      taxFee: [],
      growerStatement: [],
      labelValueInshell: labelValueInshell,
      labelValueShell: labelValueShell,
    };
  }

  async componentDidMount() { }

  async queryData() {
    if (this.state.cropYearId && this.state.cropYearId !== "") {
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_CROPYEAR_URL +
        "bycropyeargrower/" +
        this.state.cropYearId +
        "/" +
        this.state.growerId
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            let labelValueInshell = ``;
            let labelValueShell = `Shelled`;
            if (resultObject.testType.isDynamic) {
              if (resultObject.testType.xpool === true) {
                labelValueInshell = "Regular Price";
                labelValueShell = "Alternate Price";
              } else {
                labelValueInshell = "Price";
              }
            } else {
              labelValueInshell = "Inshell";
            }

            that.setState(
              {
                paymentSetting: resultObject.paymentSetting,
                basePrice: resultObject.basePrice,
                bonusDeduct: resultObject.bonusDeduct,
                taxFee: resultObject.taxFee,
                growerStatement: resultObject.growerStatement,
                testType: resultObject.testType,
                labelValueInshell: labelValueInshell,
                labelValueShell: labelValueShell,
              },
              function callBack() {
                if (that.tableVariety) {
                  that.tableVariety.bindData(resultObject.bonusDeduct);
                }
              }
            );
          } else {
            that.setState(
              {
                paymentSetting: [],
                basePrice: [],
                bonusDeduct: [],
                taxFee: [],
                growerStatement: [],
              },
              function callBack() {
                if (that.tableVariety) {
                  that.tableVariety.bindData([]);
                }
              }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      function callBack() {
        this.queryData();
      }
    );
  };

  conditionalRowStyles = () => {
    // return [
    //   {
    //     when: (row) => row.isDefault,
    //     style: {
    //       backgroundColor: "#33ffcc",
    //       color: "black",
    //     },
    //   },
    //   {
    //     when: (row) => !row.isDefault,
    //     style: {
    //       backgroundColor: "#b3e6ff",
    //       color: "black",
    //     },
    //   },
    // ];
  };

  formatStatus = (row) => {
    if (row.status == "Preview") {
      return (
        <span
          className="label label-primary"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    if (row.status == "Paid") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.status}
      </span>
    );
  };

  handleUpdateStatus = async (e) => {
    const cropYearId = e.currentTarget.dataset.cropyearid;
    const growerId = e.currentTarget.dataset.growerid;
    const paymentId = e.currentTarget.dataset.paymentid;
    const varietyId = e.currentTarget.dataset.varietyid
      ? e.currentTarget.dataset.varietyid
      : ``;
    const status = e.currentTarget.dataset.status;
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status,
        modifiedUserId: loggedUser._id,
        createdUserId: loggedUser._id,
        cropYearId: cropYearId,
        growerId: growerId,
        paymentId: paymentId,
        varietyId: varietyId,
      }),
    };
    const that = this;
    fetch(
      GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL + "updatestatus",
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          //Update status of selected row in table
          that.setState({
            growerStatement: that.state.growerStatement.map((u) =>
              u.growerId === growerId &&
                u.cropYearId === cropYearId &&
                u.paymentId === paymentId
                ? Object.assign({}, u, {
                  status: status,
                  modifiedDate: resultObject.data.modifiedDate,
                  reportId: resultObject.data._id,
                })
                : u
            ),
          });

          window.showAlert("", "Payment status is updated successfully.", "");
          that.setState({ show: false });
        } else {
          that.setState({ errorMessage: resultObject.message });
        }
      })
      .catch(function (error) {
        that.setState({ errorMessage: error });
      });
  };

  setColumnsPaymentSetting = () => {
    return [
      {
        name: "Payment Date",
        selector: "paymentDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.paymentDate, "mm/dd/yyyy");
        },
      },
      {
        name: "Payment Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.paymentPercent == null ||
              row.paymentPercent == undefined ||
              isNaN(row.paymentPercent)
              ? 0
              : parseFloat(row.paymentPercent)
            ).toFixed(1)
          ) + "%",
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
      },
    ];
  };

  setColumnsBasePrice = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.state.testType.varietyOptional,
      },
      {
        name: "Primary Price",
        selector: "primaryPrice",
        sortable: true,
        center: true,
        omit: this.state.testType.isDynamic,
      },
      {
        name: this.state.labelValueInshell,
        selector: "valueInshell",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueInshell == null ||
              row.valueInshell === undefined ||
              isNaN(row.valueInshell)
              ? 0
              : parseFloat(row.valueInshell)
            ).toFixed(2)
          ),
        width: "160px",
      },
      {
        name: this.state.labelValueShell,
        selector: "valueShelled",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueShelled == null ||
              row.valueShelled === undefined ||
              isNaN(row.valueShelled)
              ? 0
              : parseFloat(row.valueShelled)
            ).toFixed(2)
          ),
        omit:
          this.state.testType.isDynamic && this.state.testType.xpool !== true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.state.testType.isDynamic,
      },
      {
        name: "Payment",
        selector: "paymentName",
        sortable: false,
        omit: !this.state.testType.basePriceByPayment,
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
        width: "160px",
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
        width: "160px",
      },
    ];
  };

  setColumnsBonusDeduct = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        omit: this.state.testType.varietyOptional === true,
      },
      {
        name: "Attribute",
        selector: "attribute",
        sortable: true,
      },
      {
        name: "Applies To",
        selector: "applyTo",
        sortable: false,
        width: "90px",
        omit: this.state.testType.isDynamic,
      },
      {
        name: "From",
        selector: "min",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.min == null || row.min === undefined || isNaN(row.min)
              ? 0
              : parseFloat(row.min)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "To",
        selector: "max",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.max == null || row.max === undefined || isNaN(row.max)
              ? 0
              : parseFloat(row.max)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) => {
          return parseFloat(row.value) < 0 ? (
            <span className="deduct-color">
              ${CommonUtilities.numberWithCommasNegative(parseFloat(row.value))}
            </span>
          ) : (
            <span className="bonus-color">
              ${CommonUtilities.numberWithCommas(parseFloat(row.value))}
            </span>
          );
        },
      },
      {
        name: "Bonus or Deduction Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
      },
    ];
  };

  setColumnsTaxFee = () => {
    return [
      {
        name: "Rule Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.state.testType.taxFeeByMeta,
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        cell: (row) => {
          {
            return parseFloat(row.value) < 0 ? (
              <span style={{ color: "red" }}>
                ${CommonUtilities.numberWithCommasNegative(row.value)}
              </span>
            ) : (
              "$" + CommonUtilities.numberWithCommasNegative(row.value)
            );
          }
        },
      },
      {
        name: "Rule Type",
        selector: "type",
        sortable: true,
        center: true,
        cell: (row) => row.type,
      },
      {
        name: "Tax or Fee Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(new Date(row.modifiedDate), "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
      },
    ];
  };

  setColumnsGrowerStatement = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.state.testType.paymentByVariety !== true,
      },
      {
        name: "No",
        selector: "paymentNo",
        sortable: true,
        center: true,
        width: "45px",
      },
      {
        name: "Date",
        selector: "paymentDate",
        sortable: true,
        center: true,
        width: "100px",
        cell: (row) => {
          return parseInt(DateFormat(row.paymentDate, "yyyymmdd")) <
            this.state.currentDate && row.status !== "Paid" ? (
            <span
              className="label label-danger"
              style={{
                fontSize: "80%",
                paddingTop: "5px",
                paddingBottom: "7px",
                width: "70px",
              }}
            >
              {DateFormat(row.paymentDate, "mm/dd/yyyy")}
            </span>
          ) : (
            DateFormat(row.paymentDate, "mm/dd/yyyy")
          );
        },
      },
      {
        name: "Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        width: "100px",
        cell: (row) => {
          return (
            CommonUtilities.numberWithCommas(
              (row.paymentPercent == null ||
                row.paymentPercent == undefined ||
                isNaN(row.paymentPercent)
                ? 0
                : parseFloat(row.paymentPercent)
              ).toFixed(1)
            ) + "%"
          );
        },
      },
      {
        name: "Amount",
        selector: "payment",
        sortable: true,
        right: true,
        width: "200px",
        cell: (row) => {
          return row.payment < 0
            ? "$(" +
            CommonUtilities.numberWithCommas(
              parseFloat(Math.abs(row.payment)).toFixed(2)
            ) +
            ")"
            : "$" +
            CommonUtilities.numberWithCommas(
              parseFloat(row.payment).toFixed(2)
            );
        },
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        sortable: true,
        width: "100px",
        cell: (row) => {
          return this.formatStatus(row);
        },
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        width: "180px",
        cell: (row) => {
          return row.createdDate
            ? DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "",
        selector: "",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.status == "Preview" ? (
            <>
              <a
                href="#"
                variant="link"
                className="on-default remove-row"
                data-id={row.reportId}
                data-growerid={row.growerId}
                data-cropyearid={row.cropYearId}
                data-paymentid={row.paymentId}
                data-varietyid={row.varietyId}
                data-action="preview"
                onClick={handleExportPdf}
                title="Preview grower statement"
              >
                {/* <i className="fa fa-eye fa-lg"></i> */}
                Preview |
              </a>
              &nbsp;
              <a
                href="#"
                variant="link"
                className="on-default edit-row"
                data-id={row.reportId}
                data-growerid={row.growerId}
                data-cropyearid={row.cropYearId}
                data-paymentid={row.paymentId}
                data-varietyid={row.varietyId}
                data-status="Unpaid"
                title="Generate grower statement, status of statement to be unpaid as default."
                onClick={this.handleUpdateStatus.bind(this)}
              >
                Generate Statement
              </a>
            </>
          ) : (
            <>
              {row.status == "Unpaid" ? (
                <a
                  href="#"
                  variant="link"
                  className="on-default edit-row"
                  data-id={row.reportId}
                  data-growerid={row.growerId}
                  data-cropyearid={row.cropYearId}
                  data-paymentid={row.paymentId}
                  data-varietyid={row.varietyId}
                  data-status="Paid"
                  title="Click here to update payment status."
                  onClick={this.handleUpdateStatus.bind(this)}
                >
                  Mark Paid |
                </a>
              ) : (
                <a
                  href="#"
                  variant="link"
                  className="on-default edit-row"
                  data-id={row.reportId}
                  data-growerid={row.growerId}
                  data-cropyearid={row.cropYearId}
                  data-paymentid={row.paymentId}
                  data-varietyid={row.varietyId}
                  data-status="Unpaid"
                  title="Click here to revert payment status."
                  onClick={this.handleUpdateStatus.bind(this)}
                >
                  Revert Unpaid |
                </a>
              )}
              &nbsp;
              <a
                href="#"
                variant="link"
                className="on-default remove-row"
                data-id={row.reportId}
                data-growerid={row.growerId}
                data-cropyearid={row.cropYearId}
                data-paymentid={row.paymentId}
                data-varietyid={row.varietyId}
                data-action="print"
                onClick={handleExportPdf}
                title="Print grower statement"
              >
                <i className="fa fa-print fa-lg"></i>
              </a>
              &nbsp;
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row.reportId
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
            </>
          );
        },
      },
    ];
  };

  conditionalRowStyles = () => {
    return [
      {
        when: (row) =>
          parseInt(DateFormat(row.paymentDate, "yyyymmdd")) <
          this.state.currentDate,
        style: {
          backgroundColor: "#ffdd99",
        },
      },
    ];
  };

  render = () => {
    return (
      <>
        <fieldset>
          <div className="form-group">
            <label
              className="col-md-1 control-label"
              htmlFor="cropYearId"
              style={{
                marginLeft: "0px",
                paddingTop: "5px",
                marginRight: "-40px",
              }}
            >
              Crop Year
            </label>
            <div className="col-md-3">
              <FilterSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.filterCropYearControl = ref)}
                placeholder=""
                controlId="cropYearId"
                urlapilist={this.state.urlApiCropYear}
                isSetCheckedFromSource={true}
              />
            </div>
          </div>
          {this.state.cropYearId === undefined ||
            this.state.cropYearId === "" ? (
            ""
          ) : (
            <div className="form-group">
              <div className="col-md-12">
                <div className="tabs">
                  <ul className="nav nav-tabs tabs-primary">
                    <li className="active">
                      <a href="#paymentSettings" data-toggle="tab">
                        Payment Settings{" "}
                        {this.state.paymentSetting === undefined ||
                          this.state.paymentSetting.length <= 0 ? (
                          ""
                        ) : (
                          <span className="badge badge-light">
                            {this.state.paymentSetting.length}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#basePrices" data-toggle="tab">
                        Base Prices{" "}
                        {this.state.basePrice === undefined ||
                          this.state.basePrice.length <= 0 ? (
                          ""
                        ) : (
                          <span className="badge badge-light">
                            {this.state.basePrice.length}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#bonusDeduct" data-toggle="tab">
                        Bonuses & Deducts
                      </a>
                    </li>
                    <li>
                      <a href="#taxFee" data-toggle="tab">
                        Taxes & Fees{" "}
                        {this.state.taxFee === undefined ||
                          this.state.taxFee.length <= 0 ? (
                          ""
                        ) : (
                          <span className="badge badge-light">
                            {this.state.taxFee.length}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#growerStatement" data-toggle="tab">
                        Grower Statements{" "}
                        {this.state.growerStatement === undefined ||
                          this.state.growerStatement.length <= 0 ? (
                          ""
                        ) : (
                          <span className="badge badge-light">
                            {this.state.growerStatement.length}
                          </span>
                        )}
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div id="paymentSettings" className="tab-pane active">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="card">
                            <h5 className="card-header">
                              {this.state.paymentSetting === undefined ||
                                this.state.paymentSetting.length <= 0
                                ? ""
                                : this.state.paymentSetting.length +
                                (this.state.paymentSetting.length > 0 &&
                                  this.state.paymentSetting[0].isDefault
                                  ? " Default "
                                  : " Custom ") +
                                " payment setting" +
                                (this.state.paymentSetting.length > 1
                                  ? "s"
                                  : "") +
                                " found"}
                            </h5>
                            <div className="card-body">
                              <DataTable
                                highlightOnHover
                                striped
                                highlightOnHover
                                striped
                                noHeader={true}
                                columns={this.setColumnsPaymentSetting()}
                                data={this.state.paymentSetting}
                                className="table table-bordered table-striped mb-none"
                                pagination={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="basePrices" className="tab-pane">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="card">
                            <h5 className="card-header">
                              {this.state.basePrice === undefined ||
                                this.state.basePrice.length <= 0
                                ? ""
                                : this.state.basePrice.length +
                                (this.state.basePrice.length > 0 &&
                                  this.state.basePrice[0].isDefault
                                  ? " Default "
                                  : " Custom ") +
                                " base price" +
                                (this.state.basePrice.length > 1 ? "s" : "") +
                                " found"}
                            </h5>
                            <div className="card-body">
                              <DataTable
                                highlightOnHover
                                striped
                                noHeader={true}
                                columns={this.setColumnsBasePrice()}
                                data={this.state.basePrice}
                                className="table table-bordered table-striped mb-none"
                                pagination={false}
                                conditionalRowStyles={this.conditionalRowStyles()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="bonusDeduct" className="tab-pane">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="card">
                            {/* <h5 className="card-header">
                              {this.state.bonusDeduct === undefined ||
                              this.state.bonusDeduct === undefined ||
                              this.state.bonusDeduct.length <= 0
                                ? ""
                                : this.state.bonusDeduct.length +
                                  (this.state.bonusDeduct.length > 1
                                    ? " Bonuses & deducts"
                                    : " Bonus & deduct") +
                                  " found"}
                            </h5> */}
                            <div className="card-body">
                              {this.state.testType.isDynamic === true ? (
                                <DataTable
                                  highlightOnHover
                                  striped
                                  noHeader={true}
                                  columns={this.setColumnsBonusDeduct()}
                                  data={this.state.bonusDeduct}
                                  className="table table-bordered table-striped mb-none"
                                  pagination={false}
                                  conditionalRowStyles={this.conditionalRowStyles()}
                                />
                              ) : (
                                <TableVariety
                                  data={this.state.bonusDeduct}
                                  onRef={(ref) => (this.tableVariety = ref)}
                                ></TableVariety>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="taxFee" className="tab-pane">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="card">
                            <h5 className="card-header">
                              {this.state.taxFee === undefined ||
                                this.state.taxFee.length <= 0
                                ? ""
                                : this.state.taxFee.length +
                                (this.state.taxFee.length > 0 &&
                                  this.state.taxFee[0].isDefault
                                  ? " Default "
                                  : " Custom ") +
                                (this.state.taxFee.length > 1
                                  ? " taxes & fees"
                                  : " tax & fee") +
                                " found"}
                            </h5>
                            <div className="card-body">
                              <DataTable
                                highlightOnHover
                                striped
                                noHeader={true}
                                columns={this.setColumnsTaxFee()}
                                data={this.state.taxFee}
                                className="table table-bordered table-striped mb-none"
                                pagination={false}
                                conditionalRowStyles={this.conditionalRowStyles()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="growerStatement" className="tab-pane">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="card">
                            <h5 className="card-header">
                              {this.state.growerStatement === undefined ||
                                this.state.growerStatement.length <= 0
                                ? ""
                                : this.state.growerStatement.length +
                                " Grower statement" +
                                (this.state.growerStatement.length > 1
                                  ? "s"
                                  : "") +
                                " found"}
                            </h5>
                            <div className="card-body">
                              <DataTable
                                // highlightOnHover
                                // striped
                                noHeader={true}
                                columns={this.setColumnsGrowerStatement()}
                                data={this.state.growerStatement}
                                className="table table-bordered table-striped mb-none"
                                pagination={false}
                                conditionalRowStyles={this.conditionalRowStyles()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </fieldset>
      </>
    );
  };
}
export default App;
