import React, { Component } from "react";
import Select from "react-select";
import StringChecker from "../Utilities/String";

export default class MultiSelectTestType extends Component {
  constructor(props) {
    super();

    this.state = {
      testTypes: [],
      selectedTestTypes: [],
      empty: [],
      isLoading: false,
    };
    this.getOptions = this.getOptions.bind(this);
  }

  async componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    this.getTestTypes();
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.urlapi !== this.props.urlapi) {
      this.getTestTypes();
    }
  }

  resetSelect = () => {
    this.setState({
      selectedTestTypes: [],
    });
  };

  getTestTypes = async () => {
    if (
      StringChecker.isString(this.props.urlapi) &&
      !StringChecker.isEmpty(this.props.urlapi)
    ) {
      this.setState({
        isLoading: true,
      });
      const response = await fetch(this.props.urlapi);
      const data = await response.json();
      this.setState({
        isLoading: false,
        testTypes: this.getOptions(data.data),
      });
    }
  };

  getOptions = (testTypes) => {
    const options = testTypes.map((testType) => {
      return {
        value: testType._id,
        label: testType.name,
      };
    });
    return options;
  };

  onChange = (e) => {
    this.setState({
      selectedTestTypes: e,
    });
    this.props.setTestTypeIds(e);
  };

  getTestTypesOfGrower = () => {
    const testTypeIds = this.props.testTypeId.split(`,`);
    const values = testTypeIds.map((testTypeId) => {
      const testType = this.state.testTypes.find(
        (testType) => testType.value === testTypeId
      );
      return testType;
    });
    return values;
  };

  render() {
    return (
      <Select
        placeholder={this.props.placeholder ? this.props.placeholder : "All"}
        options={this.state.testTypes}
        closeMenuOnSelect={false}
        isMulti
        value={
          this.props.testTypeId && this.props.testTypeId !== ""
            ? this.getTestTypesOfGrower()
            : this.state.selectedTestTypes
        }
        onChange={this.onChange}
        isLoading={this.state.isLoading}
      />
    );
  }
}
