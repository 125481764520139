import {
  SET_TEST_TYPES,
  ADD_TEST_TYPE,
  REMOVE_TEST_TYPE,
  UPDATE_TEST_TYPE,
} from "./types";

export const setTestTypes = (data) => {
  return {
    type: SET_TEST_TYPES,
    payload: data,
  };
};

export const addTestType = (data) => {
  return {
    type: ADD_TEST_TYPE,
    payload: data,
  };
};

export const removeTestType = (data) => {
  return {
    type: REMOVE_TEST_TYPE,
    payload: data,
  };
};

export const updateTestType = (data) => {
  return {
    type: UPDATE_TEST_TYPE,
    payload: data,
  };
};
