import React, { useState, useEffect } from "react";
import config from "./config"; //do not remove

import LabUserList from "./Components/LabUser/route";
import LabUserEdit from "./Components/LabUser/routeedit";

import ProcessorList from "./Components/Processor/route";
import ProcessorAdd from "./Components/Processor/routeadd";
import ProcessorEdit from "./Components/Processor/routeedit";

import AdministratorList from "./Components/Administrator/route";
import AdministratorAdd from "./Components/Administrator/routeadd";
import AdministratorEdit from "./Components/Administrator/routeedit";

import HelpList from "./Components/Help/route";
import HelpAdd from "./Components/Help/routeadd";
import HelpEdit from "./Components/Help/routeedit";
import HelpContent from "./Components/Help/content";

import MailTemplateList from "./Components/MailTemplate/route";
import MailTemplateAdd from "./Components/MailTemplate/routeadd";
import MailTemplateEdit from "./Components/MailTemplate/routeedit";

import MembershipTypeList from "./Components/MembershipType/route";
import MembershipTypeAdd from "./Components/MembershipType/routeadd";
import MembershipTypeEdit from "./Components/MembershipType/routeedit";

import NutTypeList from "./Components/TestType/Route";
import NutTypeEdit from "./Components/TestType/RouteEdit";
import GrowerStatementDesign from "./Components/TestType/RouteGrowerStatement";

import VarietyList from "./Components/Variety/route";
import VarietyAdd from "./Components/Variety/routeadd";
import VarietyEdit from "./Components/Variety/routeedit";

import ClassificationList from "./Components/Classification/route";

import ClassificationItemList from "./Components/ClassificationItem/route";
import ClassificationItemAdd from "./Components/ClassificationItem/routeadd";
import ClassificationItemEdit from "./Components/ClassificationItem/routeedit";

import WalnutTestList from "./Components/WalnutTest/route";
import WalnutTestAdd from "./Components/WalnutTest/routeadd";
import WalnutTestEdit from "./Components/WalnutTest/routeedit";

import HandlerList from "./Components/Handler/route";
import HandlerAdd from "./Components/Handler/routeadd";
import HandlerEdit from "./Components/Handler/routeedit";

import GrowerList from "./Components/Grower/route";
import GrowerAdd from "./Components/Grower/routeadd";
import GrowerEdit from "./Components/Grower/routeedit";
import GrowerImport from "./Components/Grower/routeimport";

import AttributeList from "./Components/Attribute/route";
import CropYearList from "./Components/CropYear/route";

import PaymentSettingList from "./Components/PaymentSetting/route";
import BasePriceList from "./Components/BasePrice/route";
import CashContractPriceList from "./Components/CashContractPrice/route";
import BonusDeductList from "./Components/BonusDeduct/route";
import TaxFeeList from "./Components/TaxFee/route";

import AuditLogList from "./Components/AuditLog/route";
import AuditLogView from "./Components/AuditLog/routeview";

import ConfigList from "./Components/Config/route";
import ConfigAdd from "./Components/Config/routeadd";
import ConfigEdit from "./Components/Config/routeedit";

import Page404 from "./Components/Pages/PageNotFound";

import SubscriptionList from "./Components/Subscription/route";

import BillList from "./Components/Bill/route";
import BillDetail from "./Components/Bill/routedetail";

import TierList from "./Components/Tier/route";
import TierAdd from "./Components/Tier/routeadd";
import TierEdit from "./Components/Tier/routeedit";

import StaffList from "./Components/Staff/route";
import StaffAdd from "./Components/Staff/routeadd";
import StaffEdit from "./Components/Staff/routeedit";

import TestFieldList from "./Components/TestField/route";
import TestFieldAdd from "./Components/TestField/routeadd";
import TestFieldEdit from "./Components/TestField/routeedit";

import GrowerStatementList from "./Components/GrowerStatement/route";
import GrowerPaymentReport from "./Components/GrowerPaymentReport/route";

import PaymentDetailViewList from "./Components/PaymentDetailView/route";

import StaticMessageList from "./Components/StaticMessage/route";

import MailboxList from "./Components/Mailbox/route";
import ImportLogList from "./Components/ImportLog/route";
import ImportLogError from "./Components/ImportLog/routeerror";

import SystemLogList from "./Components/SystemLog/route";

import TimeOnPage from "./Components/Analytics/TimeOnPage/route";

import SitePerformanceReport from "./Components/Analytics/SitePerformanceReport/route";

import EmailSetting from "./Components/EmailSetting/route";

import ScheduledTaskList from "./Components/ScheduledTask/route";
import ScheduledTaskAdd from "./Components/ScheduledTask/routeadd";
import ScheduledTaskEdit from "./Components/ScheduledTask/routeedit";

import Home from "./Components/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import Login from "./Components/Pages/Login";
import Activation from "./Components/Pages/Activation";
import Signup from "./Components/Pages/Signup";
import SignupThankYou from "./Components/Pages/SignupThankYou";
import TermsOfUse from "./Components/Pages/TermsOfUse";
import RequestUpdateGrower from "./Components/Pages/RequestUpdateGrower";
import PlansPricing from "./Components/Pages/PlansPricing";
import RecoverPassword from "./Components/Pages/RecoverPassword";
import Profile from "./Components/User/Profile";
import resetPassword from "./Components/Pages/ResetPassword";
import DocsTestsList from "./Components/Docs/API-Reference/Test/List";
import DocsTestsCreate from "./Components/Docs/API-Reference/Test/Create";

import { socketAnalytics } from "./setupSockets";
import { getLoggedUser } from "./context/auth";
import { USER_ROLE_ADMINISTRATOR } from "./Components/Utilities/Constant";
import { calculateFinishTime } from "./Components/Analytics/PagePerformance";

const GlobalConfig = new window.globalConfig();

function App(props) {
  const tokens = localStorage.getItem("tokens");
  let existingTokens = null;
  if (
    tokens !== null &&
    tokens !== undefined &&
    tokens.toString() !== "undefined" &&
    tokens.toString() !== ""
  ) {
    existingTokens = JSON.parse(tokens);
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [loggedUser, setLoggedUser] = useState(null);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  const onAccess = (payload) => {
    socketAnalytics.connect();
    socketAnalytics.emit("access", payload);
  };

  useEffect(() => {
    const user = getLoggedUser();
    // Set the current 'loggedUser' to state
    setLoggedUser(user);
  }, []);

  useEffect(() => {
    // This useEffect has 'loggedUser' in the dependency array to ensure it executes every time 'loggedUser' changes.
    if (loggedUser && loggedUser.role !== USER_ROLE_ADMINISTRATOR) {
      calculateFinishTime(loggedUser);
    }
  }, [loggedUser]);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <div>
          <Switch>
            <Route
              path={process.env.REACT_APP_URL_LOGIN}
              exact
              component={Login}
            />
            <Route
              path={process.env.REACT_APP_URL_ACTIVATION}
              exact
              component={Activation}
            />
            <Route
              path={process.env.REACT_APP_URL_SIGNUP}
              exact
              component={Signup}
            />
            <Route
              path={process.env.REACT_APP_URL_SIGNUP_THANK_YOU}
              exact
              component={SignupThankYou}
            />
            <Route
              path={process.env.REACT_APP_URL_TERMS_OF_USE}
              exact
              component={TermsOfUse}
            />
            <Route
              path={`${process.env.REACT_APP_URL_REQUEST_UPDATE_GROWER}/:action/:growerTempId`}
              exact
              component={RequestUpdateGrower}
            />
            <Route
              path={process.env.REACT_APP_URL_PLAN_PRICING}
              exact
              component={PlansPricing}
            />
            <Route
              path={process.env.REACT_APP_URL_RECOVER_PASSWORD}
              exact
              component={RecoverPassword}
            />
            <Route
              path={process.env.REACT_APP_URL_RESET_PASSWORD}
              exact
              component={resetPassword}
            />
            <Route
              path={process.env.REACT_APP_URL_DOCS_TEST_LIST}
              exact
              component={DocsTestsList}
            />
            <Route
              path={process.env.REACT_APP_URL_DOCS_TEST_CREATE + "/:id"}
              exact
              component={DocsTestsCreate}
            />

            <PrivateRoute
              path="/"
              exact
              component={Home}
              navRoute={["dashboard", "summary"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_TIME_REPORT}
              exact
              component={TimeOnPage}
              navRoute={["analytics", "time-reports"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_SITE_PERFORMANCE_REPORT}
              exact
              component={SitePerformanceReport}
              navRoute={["analytics", "site-performance-report"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_LAB_USER_LIST}
              exact
              component={LabUserList}
              navRoute={["users", "lab-users"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_LAB_USER_LIST + "/:id"}
              exact
              component={LabUserEdit}
              navRoute={["users", "lab-users"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST}
              exact
              component={AdministratorList}
              navRoute={["users", "administrators"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST + "-add"}
              exact
              component={AdministratorAdd}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_ADMINISTRATOR_LIST + "/:id"}
              exact
              component={AdministratorEdit}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_PROCESSOR_LIST}
              exact
              component={ProcessorList}
              navRoute={["users", "processors"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_PROCESSOR_LIST + "-add"}
              exact
              component={ProcessorAdd}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_PROCESSOR_LIST + "/:id"}
              exact
              component={ProcessorEdit}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_HANDLER_LIST}
              exact
              component={HandlerList}
              navRoute={["users", "handlers"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HANDLER_LIST + "-add"}
              exact
              component={HandlerAdd}
              navRoute={["users", "handlers"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HANDLER_LIST + "/:id"}
              exact
              component={HandlerEdit}
              navRoute={["users", "handlers"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_GROWER_LIST}
              exact
              component={GrowerList}
              navRoute={["grower-tools", "growers"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_GROWER_LIST + "-import"}
              exact
              component={GrowerImport}
              navRoute={["grower-tools", "growers"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_GROWER_LIST + "-add"}
              exact
              component={GrowerAdd}
              navRoute={["grower-tools", "growers"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_GROWER_LIST + "/:id"}
              exact
              component={GrowerEdit}
              navRoute={["grower-tools", "growers"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_ATTRIBUTE_LIST}
              exact
              component={AttributeList}
              navRoute={["grower-tools", "attribute"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_CROPYEAR_LIST}
              exact
              component={CropYearList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
                "/:id"
              }
              exact
              component={PaymentSettingList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_BASEPRICE_LIST +
                "/:id"
              }
              exact
              component={BasePriceList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
                "/:id"
              }
              exact
              component={CashContractPriceList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_BONUSDEDUCT_LIST +
                "/:id"
              }
              exact
              component={BonusDeductList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_TAXFEE_LIST +
                "/:id"
              }
              exact
              component={TaxFeeList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_STATICMESSAGE_LIST +
                "/:id"
              }
              exact
              component={StaticMessageList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_HELP_LIST}
              exact
              component={HelpList}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HELP_LIST + "/category/:category"}
              exact
              component={HelpList}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HELP_LIST + "-add"}
              exact
              component={HelpAdd}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HELP_LIST + "/:id"}
              exact
              component={HelpEdit}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_HELP_LIST + "/content/:id"}
              exact
              component={HelpContent}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "/:id"}
              exact
              component={MailTemplateEdit}
              navRoute={["administration", "mail-templates"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST}
              exact
              component={MailTemplateList}
              navRoute={["administration", "mail-templates"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "-add"}
              exact
              component={MailTemplateAdd}
              navRoute={["administration", "mail-templates"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST}
              exact
              component={ConfigList}
              navRoute={["administration", "system-settings"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "-add"}
              exact
              component={ConfigAdd}
              navRoute={["administration", "system-settings"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEMCONFIG_LIST + "/:id"}
              exact
              component={ConfigEdit}
              navRoute={["administration", "system-settings"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_NUTTYPE_LIST + "/:id"}
              exact
              component={NutTypeEdit}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_NUTTYPE_LIST}
              exact
              component={NutTypeList}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_NUTTYPE_LIST + "/varieties/:id"}
              exact
              component={VarietyList}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_NUTTYPE_LIST + "/variety-add/:id"}
              exact
              component={VarietyAdd}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_NUTTYPE_LIST + "/variety-edit/:id"
              }
              exact
              component={VarietyEdit}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_NUTTYPE_LIST + "/grower-statement/:id"
              }
              exact
              component={GrowerStatementDesign}
              navRoute={["grower-tools", "test-types"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
              exact
              component={ClassificationList}
              navRoute={["grower-tools", "classifications"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST +
                "/classification-items/:id"
              }
              exact
              component={ClassificationItemList}
              navRoute={["grower-tools", "classifications"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST +
                "/classification-item-add/:id"
              }
              exact
              component={ClassificationItemAdd}
              navRoute={["grower-tools", "classifications"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CLASSIFICATION_LIST +
                "/classification-item-edit/:id"
              }
              exact
              component={ClassificationItemEdit}
              navRoute={["grower-tools", "classifications"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_WALNUT_TEST_LIST}
              exact
              component={WalnutTestList}
              navRoute={["grading-test", "test"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_WALNUT_TEST_LIST + "/:id"}
              exact
              component={WalnutTestEdit}
              navRoute={["grading-test", "test"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_WALNUT_TEST_LIST + "-add"}
              exact
              component={WalnutTestAdd}
              navRoute={["grading-test", "test"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_MY_PROFILE}
              exact
              component={Profile}
              onAccess={onAccess}
            ></PrivateRoute>

            {/* <Route
              path={process.env.REACT_APP_URL_TEST_FORM_LIST}
              exact
              component={PageUnderConstruction}
            ></Route> */}

            <PrivateRoute
              path={process.env.REACT_APP_URL_MEMBERSHIP_TYPE_LIST + "/:id"}
              exact
              component={MembershipTypeEdit}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_MEMBERSHIP_TYPE_LIST}
              exact
              component={MembershipTypeList}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_MEMBERSHIP_TYPE_ADD}
              exact
              component={MembershipTypeAdd}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_SUBSCRIPTION_LIST}
              exact
              component={SubscriptionList}
              navRoute={["account-settings", "subscription"]}
              onAccess={onAccess}
            />

            <PrivateRoute
              path={process.env.REACT_APP_URL_BILL_LIST}
              exact
              component={BillList}
              navRoute={["account-settings", "bill"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_BILL_LIST + "/:id"}
              exact
              component={BillDetail}
              navRoute={["account-settings", "bill"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_TIER_LIST}
              exact
              component={TierList}
              navRoute={["account-settings", "tiers"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_TIER_LIST + "/:id"}
              exact
              component={TierEdit}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_TIER_LIST + "-add"}
              exact
              component={TierAdd}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST}
              exact
              component={AuditLogList}
              navRoute={["grower-tools", "revision-history"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/:id"
              }
              exact
              component={AuditLogList}
              navRoute={["grower-tools", "revision-history"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/:id"}
              exact
              component={AuditLogView}
              navRoute={["grower-tools", "revision-history"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST +
                "/:id"
              }
              exact
              component={GrowerStatementList}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST +
                process.env.REACT_APP_URL_PAYMENT_DETAIL_VIEW_LIST +
                "/:id/:paymentId/:growerId"
              }
              exact
              component={PaymentDetailViewList}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={
                process.env.REACT_APP_URL_CROPYEAR_LIST +
                process.env.REACT_APP_URL_GROWER_PAYMENT_REPORT +
                "/:id/:processorId?"
              }
              exact
              component={GrowerPaymentReport}
              navRoute={["grower-tools", "crop-years"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_GROWER_PAYMENT_REPORT}
              exact
              component={GrowerPaymentReport}
              navRoute={["dashboard", "grower-payment-report"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_STAFF_LIST}
              exact
              component={StaffList}
              navRoute={["users", "staffs"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_STAFF_LIST + "-add"}
              exact
              component={StaffAdd}
              navRoute={["users", "staffs"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_STAFF_LIST + "/:id"}
              exact
              component={StaffEdit}
              navRoute={["users", "staffs"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_TEST_FIELD_LIST}
              exact
              component={TestFieldList}
              navRoute={["grower-tools", "test-fields"]}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_TEST_FIELD_LIST + "-add"}
              exact
              component={TestFieldAdd}
              navRoute={["grower-tools", "test-fields"]}
              onAccess={onAccess}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_TEST_FIELD_LIST + "/:id"}
              exact
              component={TestFieldEdit}
              navRoute={["grower-tools", "test-fields"]}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={GlobalConfig.REACT_APP_URL_MAILBOX_LIST}
              exact
              component={MailboxList}
              onAccess={onAccess}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_IMPORT_LOG_LIST}
              exact
              navRoute={["grower-tools", "import-logs"]}
              component={ImportLogList}
              onAccess={onAccess}
            />
            <PrivateRoute
              path={process.env.REACT_APP_URL_IMPORT_LOG_LIST + "/error/:id"}
              exact
              component={ImportLogError}
              navRoute={["grower-tools", "import-logs"]}
              onAccess={onAccess}
            />

            <PrivateRoute
              path={process.env.REACT_APP_URL_SYSTEM_LOG_LIST}
              exact
              component={SystemLogList}
              navRoute={["administration", "system-logs"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_EMAIL_SETTING}
              exact
              component={EmailSetting}
              navRoute={["administration", "email-settings"]}
            ></PrivateRoute>

            <PrivateRoute
              path={process.env.REACT_APP_URL_CRON_JOB_SETTING}
              exact
              component={ScheduledTaskList}
              navRoute={["administration", "scheduled-tasks"]}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_CRON_JOB_SETTING + "/add"}
              exact
              component={ScheduledTaskAdd}
              navRoute={["administration", "scheduled-tasks"]}
            ></PrivateRoute>
            <PrivateRoute
              path={process.env.REACT_APP_URL_CRON_JOB_SETTING + "/:id"}
              exact
              component={ScheduledTaskEdit}
              navRoute={["administration", "scheduled-tasks"]}
            ></PrivateRoute>

            <Route
              path={process.env.REACT_APP_URL_PAGE_NOT_FOUND}
              exact
              component={Page404}
            ></Route>

            <Route component={Page404}></Route>
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
