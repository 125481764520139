import React, { useEffect, useState, createContext, useContext } from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser, useAuthContext } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import axios from "axios";
import CommonUtilities from "../Utilities/Common";
import SaveButton from "../Controls/SaveButton";
import Switch from "react-switch";

var jwt = require("jsonwebtoken");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "",
      urllist: "",
      urlapi: GlobalConfig.REACT_APP_API_STAFF_URL,
      urlsubmitapi: GlobalConfig.REACT_APP_API_STAFF_URL + "profile/",
      data: {
        _id: loggedUser.referenceUserId,
        processorId: "",
        name: "",
        contactEmail: "",
        newPassword: "",
        currentPassword: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
        processorName: "", //extra field
        allowNotifyImportResult: false,
        notifyImportResult: false,
      },
      errors: {
        email: "",
      },
      loading: parentState.loading,
    };
    this.handleNotifyImportResultChangeSwitch =
      this.handleNotifyImportResultChangeSwitch.bind(this);
  }

  handleNotifyImportResultChangeSwitch = (checked) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        notifyImportResult: checked,
      }),
    });
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(
      this.state.data.contactEmail
    );
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return "My Profile";
  };

  doSomethingAfterSaved = (resultObject) => {
    axios
      .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/queryprofilebytoken", {
        id: this.state.data._id,
      })
      .then((result) => {
        if (result.status === 200) {
          const token = jwt.sign(
            result.data.data,
            process.env.REACT_APP_TOKEN_SECRET
          );
          // localStorage.clear();
          localStorage.setItem("isActive", result.data.data.isActive);
          localStorage.setItem("tokens", JSON.stringify(token));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  render() {
    const title = "My Profile";
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#Profile" data-toggle="tab">
                          Profile & Account
                        </a>
                      </li>
                    </ul>
                    <form
                      className="form-horizontal form-bordered"
                      onSubmit={this.handleSubmit}
                      id="form"
                    >
                      <div className="tab-content">
                        <div id="Profile" className="tab-pane active">
                          <h4 className="mb-xlg">Staff Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Behalf of processor
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  <strong>
                                    {this.state.data.processorName}
                                  </strong>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Name <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  name="name"
                                  autoFocus={true}
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="contactEmail"
                              >
                                Email <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="email"
                                  name="contactEmail"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.contactEmail}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            {this.state.data.allowNotifyImportResult && (
                              <div className="form-group">
                                <label className="col-md-3 control-label">
                                  Notify Import Result
                                </label>
                                <div className="col-md-8">
                                  <Switch
                                    name="notifyImportResult"
                                    onChange={
                                      this.handleNotifyImportResultChangeSwitch
                                    }
                                    checked={this.state.data.notifyImportResult}
                                    className="react-switch"
                                  />
                                </div>
                              </div>
                            )}
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Change Password</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="currentPassword"
                              >
                                Current Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="currentPassword"
                                  id="currentPassword"
                                  maxLength={100}
                                  value={this.state.data.currentPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="newPassword"
                              >
                                New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="newPassword"
                                  name="newPassword"
                                  maxLength={100}
                                  value={this.state.data.newPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="newPasswordRepeat"
                              >
                                Repeat New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="newPasswordRepeat"
                                  name="newPasswordRepeat"
                                  maxLength={100}
                                  value={
                                    this.state.data.newPasswordRepeat ?? ""
                                  }
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="row">
                          <div className="col-md-9 col-md-offset-3">
                            <SaveButton loading={this.state.loading} />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
