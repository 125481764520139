import React from "react";
import moment from "moment";
import Header from "../Header";
import Alert from "../Utilities/Alert";
import Navigation from "../Navigation";
import EditComponent from "../Base/EditComponent";
import MultiSelect from "./../FormControl/MultiSelect";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Button, Modal } from "react-bootstrap";
import TableLoadingComponent from "../Base/TableLoadingComponent";
import {
  CRON_JOB_TYPE_DAILY,
  CRON_JOB_TYPE_HOURLY,
  VALUE_QUERY_PARAM_IGNORE,
} from "../Utilities/Constant";
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    this.state.listtitle = "Scheduled Task";
    this.state.urllist = process.env.REACT_APP_URL_CRON_JOB_SETTING;
    this.state.urlapi = process.env.REACT_APP_API_CRON_JOB_SETTING_URL;
    this.state.show = false;
    this.state.getCronJobHistoryLoading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.selectedProcessorIds = "";
    this.state.selectedStatus = "";
    this.state.cronJobHistories = [];
    this.state.cronJobHistoryMessages = [];
    this.state.startInfo = null;
    this.state.data = {
      _id: props.match.params.id,
      taskName: "",
      typeCronJob: CRON_JOB_TYPE_HOURLY,
      cronStyle: "",
      stepSize: "1",
      startTime: "00:00",
      description: "",
      startDateTime: null,
    };
  }

  componentDidUpdate = (_, previousState) => {
    const currentState = this.state;
    if (
      previousState.data.startTime !== currentState.data.startTime ||
      previousState.data.typeCronJob !== currentState.data.typeCronJob
    ) {
      this.setStartInfo(
        currentState.data.typeCronJob,
        currentState.data.startTime
      );
    }
    if (this.state.isChanged) {
      // if user change data
      // warn user will lose the changes
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  };

  doSomethingAfterGetItemById = async () => {
    this.setStartInfo(this.state.data.typeCronJob, this.state.data.startTime);
  };

  getCronJobHistories = () => {
    this.setState({
      getCronJobHistoryLoading: true,
    });
    let taskId = VALUE_QUERY_PARAM_IGNORE;
    if (this.state.data._id) {
      taskId = this.state.data._id;
    }
    let status = VALUE_QUERY_PARAM_IGNORE;
    if (this.state.selectedStatus) {
      status = this.state.selectedStatus;
    }
    let processorIds = VALUE_QUERY_PARAM_IGNORE;
    if (this.state.selectedProcessorIds) {
      processorIds = this.state.selectedProcessorIds;
    }
    fetch(
      `${process.env.REACT_APP_API_CRON_JOB_HISTORY_URL}${taskId}/${status}/${processorIds}/${this.state.currentPage}/${this.state.perPage}`
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.result === "OK") {
          this.setState({
            cronJobHistories: responseData.data,
            totalRows: responseData.totalCount,
          });
        } else {
          window.showAlert("Error", `${responseData.message}`, "error");
        }
        this.setState({
          getCronJobHistoryLoading: false,
        });
      })
      .catch((exception) => {
        window.showAlert(
          "Error",
          `Could not get job histories. ${exception.message}`,
          "error"
        );
        this.setState({
          getCronJobHistoryLoading: false,
        });
      });
  };

  getTitle = () => {
    return this.state.data.taskName;
  };

  onTypeCronJobChange = ({ value }) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, typeCronJob: value },
    });
  };

  onStartTimeChange = (e) => {
    if (!e.target.value) {
      e.target.value = "00:00";
    }
    this.handleChange(e);
  };

  onPerPageChange = (perPage, currentPage) => {
    this.setState({
      perPage: perPage,
      currentPage: currentPage,
    });
  };

  onPageChange = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  onProcessorChange = (selectedProcessors) => {
    const processorIds = Array.isArray(selectedProcessors)
      ? selectedProcessors.map((processor) => processor.value).join(",")
      : "";
    this.setState({
      currentPage: 1,
      selectedProcessorIds: processorIds,
    });
  };

  setStartInfo(type, startAt) {
    let datetime = new Date(
      `${moment(new Date()).format("YYYY-MM-DD").toString()} ${startAt}:00`
    );
    const now = new Date();
    if (datetime < now && type === CRON_JOB_TYPE_HOURLY) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === CRON_JOB_TYPE_DAILY) {
      datetime = new Date(datetime.getTime() + 86400000);
    }
    this.setState({
      data: { ...this.state.data, startDateTime: datetime },
      startInfo: moment(datetime).format("MM/DD/YYYY hh:mm A"),
    });
  }

  handleShow = (row) => {
    const message = JSON.parse(row.message);
    this.setState({
      show: true,
      cronJobHistoryMessages: message,
      titleModal: `${row.taskName} [${moment(row.startedDate).format(
        "MM/DD/YYYY hh:mm A"
      )}]`,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
      cronJobHistoryMessages: [],
      titleModal: undefined,
    });
  };

  statusColumnStyle = {
    paddingTop: "5px",
    paddingBottom: "7px",
    width: "100px",
    fontSize: 12,
  };

  historyDetailColumn = [
    {
      name: "Message",
      selector: "content",
      wrap: true,
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      center: true,
      sortable: false,
      cell: (row) => {
        return (
          <>
            <span
              style={this.statusColumnStyle}
              className={
                row.status === "Failed"
                  ? "label label-danger"
                  : "label label-success"
              }
            >
              {row.status}
            </span>
          </>
        );
      },
    },
    {
      name: "Completed/Failed At",
      selector: "createdAt",
      center: true,
      sortable: false,
      cell: (row) => {
        return row.createdAt
          ? moment(row.createdAt).format("MM/DD/YYYY hh:mm:ss A")
          : "";
      },
    },
  ];

  cronJobHistoryTableColumns = [
    {
      name: "Started Date",
      selector: "startedDate",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <>
            <span>
              {moment(row.startedDate).format("MM/DD/YYYY hh:mm:ss A")}
            </span>
          </>
        );
      },
    },
    {
      name: "Completed Date",
      selector: "completedDate",
      sortable: false,
      center: true,
      cell: (row) => {
        return (
          <>
            <span>
              {row.completedDate
                ? moment(row.completedDate).format("MM/DD/YYYY hh:mm:ss A")
                : ""}
            </span>
          </>
        );
      },
    },
    {
      name: "Result Status",
      selector: "status",
      sortable: false,
      center: true,
      grow: 1,
      cell: (row) => {
        return (
          <>
            <span
              style={this.statusColumnStyle}
              className={
                row.status === "Failed"
                  ? "label label-danger"
                  : "label label-success"
              }
            >
              {row.status}
            </span>
          </>
        );
      },
    },
    {
      name: "",
      center: true,
      grow: 0,
      cell: (row) => {
        return (
          <div
            title="View detail"
            onClick={() => {
              this.handleShow(row);
            }}
            className="btn btn-default"
            style={{ minWidth: "0", border: 0 }}
          >
            <i className="fa fa-info-circle fa-lg text-primary"></i>
          </div>
        );
      },
    },
  ];

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        className="job-history-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {this.state.titleModal ? this.state.titleModal : "Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            title=""
            noHeader={true}
            columns={this.historyDetailColumn}
            data={this.state.cronJobHistoryMessages}
            className="table table-striped mb-none mt-none"
            pagination={false}
          />
        </Modal.Body>
        <Modal.Footer style={{ marginTop: 0 }}>
          <Button variant="warning" onClick={this.handleClose}>
            <span className="fa fa-times-circle fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderCronJobHistoryTable = () => {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-md-12">
            <DataTable
              id="emp"
              noHeader
              columns={this.cronJobHistoryTableColumns}
              data={this.state.cronJobHistories}
              className="table table-bordered table-striped mb-none"
              progressPending={this.state.getCronJobHistoryLoading}
              progressComponent={<TableLoadingComponent />}
              noDataComponent={false}
              persistTableHead={true}
              pagination={true}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              paginationDefaultPage={this.state.currentPage}
              paginationPerPage={this.state.perPage}
              onChangeRowsPerPage={this.onPerPageChange}
              onChangePage={this.onPageChange}
            />
          </div>
        </div>
      </div>
    );
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">Type</label>
          <div className="col-sm-2">
            <MultiSelect
              isMulti={false}
              defaultOptions={[
                {
                  value: CRON_JOB_TYPE_HOURLY,
                  label: CRON_JOB_TYPE_HOURLY,
                },
                {
                  value: CRON_JOB_TYPE_DAILY,
                  label: CRON_JOB_TYPE_DAILY,
                },
              ]}
              closeMenuOnSelect={true}
              onChange={this.onTypeCronJobChange}
              value={this.state.data.typeCronJob}
            ></MultiSelect>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Step Size</label>
          <div className="col-md-2">
            <input
              min={1}
              max={100}
              name="stepSize"
              value={this.state.data.stepSize}
              onChange={this.handleChange}
              className="form-control"
              type="number"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Start At</label>
          <div className="col-md-2">
            <input
              name="startTime"
              value={this.state.data.startTime}
              onChange={this.onStartTimeChange}
              className="form-control"
              type="time"
            />
          </div>
          <label className="control-label pl-md">
            <span className="fa fa-info-circle"></span>&nbsp; Next run:{" "}
            {this.state.startInfo}
          </label>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">Description</label>
          <div className="col-sm-5">
            <textarea
              rows={4}
              name="description"
              className="form-control"
              placeholder="Description"
              value={this.state.data.description || ""}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const title = this.defaultTitle();
    return (
      <>
        {this.extendRender()}
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.state.urllist == "" || this.state.listtitle == "" ? (
                      ""
                    ) : (
                      <li>
                        <a href={this.state.urllist}>{this.state.listtitle}</a>
                      </li>
                    )}
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={this.handleSubmit}
                    id="form"
                    className="form-horizontal"
                  >
                    <section className="panel">
                      {this.state.isUseHeading ? (
                        <header className="panel-heading">
                          <h2 className="panel-title">
                            {this.state.sectionTitle == undefined
                              ? "Basic Information"
                              : this.state.sectionTitle}
                          </h2>
                        </header>
                      ) : (
                        ""
                      )}
                      {this.renderForm()}
                      {this.renderFooter()}
                    </section>
                  </form>
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                      showBtnClose={true}
                    ></Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.extraFooterRender()}
            </section>
          </div>
        </section>
      </>
    );
  };
}

export default App;
