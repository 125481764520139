import ListComponent from "../Base/ListComponent";
import { handleExportPdf } from "./export";
import { Button, Dropdown } from "react-bootstrap";
import CommonUtilities from "../Utilities/Common";
import { qualityAttributeNamesMapping } from "../Utilities/Object";
import ColumnOptions from "../Base/ColumnOptions";
import OptionDropdown from "../Base/OptionDropdown";
import {
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
} from "../Utilities/Constant";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { configDataExport } from "./configDataExport";
import AdjustmentsPopup from "./adjustmentsPopup";

class App extends ListComponent {
  constructor(props) {
    super();

    let cropYearId = props.match.params.id;
    let paymentId = props.match.params.paymentId;
    let growerId = props.match.params.growerId;
    let reportId = props.match.params.reportId;

    this.state.pluraltitle = "Payment Detail View";
    this.state.title = "Test";
    this.state.pagination = false;
    this.state.pageName = "Payment Detail View";

    this.state.items = [];
    this.state.itemsTaxFee = [];
    this.state.growerInfo = [];
    this.state.testType = [];
    this.state.itemsMergeCustomField = [];
    this.state.qualityAttributes = [];
    this.state.adjustments = [];
    this.state.showAdjustmentsPopup = false;

    this.state.childOverrideComponentDidMount = true;

    this.state.params = {
      currentTarget: {
        dataset: {
          cropyearid: cropYearId,
          paymentid: paymentId,
          growerid: growerId,
          reportid: reportId,
        },
      },
    };

    this.state.filter = [];
    this.state.filterLotNumber = "";
  }

  async componentDidMountChild() {
    this.getGrowerStatementData();
    this.loadColumnSettings();
  }

  defaultTitleSetting = () => {
    const length = this.getTotalItems();

    switch (true) {
      case length <= 0:
        return "Not found!";
      case length === 1:
        return `${length} ${this.state.title} found`;
      default:
        return `${length} ${this.state.title}s found`;
    }
  };

  getGrowerStatementData = async () => {
    handleExportPdf(this.state.params, this.proceedExport, this);
  };

  proceedExport(responseData, reportData, objThis) {
    reportData.map((item) => {
      const getItemsPaymentData = objThis.filterExcludeFieldName(
        item.itemsPayment,
        "Total",
        "lot"
      );
      const getItemsTaxFeeData = objThis.filterExcludeFieldName(
        item.itemsTaxFee,
        "Net Payment",
        "name"
      );

      let parseCustomFields = [];

      const mergeFields = getItemsPaymentData.map((item) => {
        if (item.customFields && item.customFields !== "") {
          parseCustomFields = JSON.parse(item.customFields);
        }

        const mergeAllCustomFieldToObj = parseCustomFields.reduce(
          (obj, item) => ({
            ...obj,
            [item.name]: item.value,
          }),
          {}
        );
        const mergeCustomFieldToPayment = Object.assign(
          item,
          mergeAllCustomFieldToObj
        );
        return mergeCustomFieldToPayment;
      });

      return objThis.setState(
        {
          filter: getItemsPaymentData,
          growerInfo: item.growerInfo,
          itemsTaxFee: getItemsTaxFeeData,
          testType: item.testType ? item.testType : [],
          qualityAttributes: responseData.qualityAttributes,
        },
        () => {
          const updatedOriginalPriceItems = [];
          mergeFields.forEach((itemPayment) => {
            let newPaymentItem = { ...itemPayment };
            let originalPrice = objThis.getOriginalPriceByTestType(itemPayment);
            newPaymentItem.originalPrice = originalPrice;
            updatedOriginalPriceItems.push(newPaymentItem);
          });
          objThis.setState({
            items: updatedOriginalPriceItems,
          });
        }
      );
    });
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      switch (this.state.testType.isDynamic) {
        case true: {
          if (this.isCustomFieldOfDynamicTest(x.selector)) {
            x.omit = !(this.state.hideColumns.indexOf(x.selector) !== -1);
          } else {
            x.omit =
              this.state.hideColumns.indexOf(x.selector) !== -1 ||
              x.omit === true;
          }
          break;
        }
        case false: {
          if (this.isQualityFieldOfStaticTest(x.selector)) {
            x.omit = !(this.state.hideColumns.indexOf(x.selector) !== -1);
          } else {
            x.omit =
              this.state.hideColumns.indexOf(x.selector) !== -1 ||
              x.omit === true;
          }
          break;
        }
        default:
      }
    });

    return defaultCols;
  };

  isQualityFieldOfStaticTest = (field) => {
    let result = false;
    for (let attribute in qualityAttributeNamesMapping) {
      if (attribute === field) {
        result = true;
        break;
      }
    }
    return result;
  };

  isCustomFieldOfDynamicTest = (fieldName) => {
    const customFields = this.getCustomFields();
    return customFields && customFields.length > 0
      ? customFields.some((field) => field.name === fieldName)
      : false;
  };

  filterExcludeFieldName = (array, fieldName, value) => {
    return array.filter((item) => item[value] !== fieldName);
  };

  extendButtonsBeforeColumnOptions = () => {
    return (
      <div className="panel-btn-group-item-position">{this.exportButton()}</div>
    );
  };

  closeAdjustmentsPopup = () => {
    this.setState({
      showAdjustmentsPopup: false,
      adjustments: [],
    });
  };

  extendRender = () => {
    return (
      <AdjustmentsPopup
        adjustments={this.state.adjustments}
        showAdjustmentsPopup={this.state.showAdjustmentsPopup}
        closeAdjustmentsPopup={this.closeAdjustmentsPopup}
      />
    );
  };

  exportButton = () => {
    return this.state.items.length > 0 ? (
      <Button
        variant="warning"
        onClick={(e) => this.exportExcel()}
        className="mb-sm"
      >
        <i className="fa fa-file-excel-o fa-lg"></i> Export
      </Button>
    ) : (
      ""
    );
  };

  exportExcel = async () => {
    const items = this.state.items;
    const customFields = this.getCustomFields();

    const parsedItems = [];
    if (items) {
      items.map((item) => {
        var dataAfterHandle = {};
        var dataContainer = {};

        dataAfterHandle = {};
        dataContainer = {
          data: item,
          growerInfo: this.state.growerInfo,
          itemsTaxFee: this.state.itemsTaxFee,
          customFields: customFields,
        };
        const getConfigDataExport = configDataExport(
          dataContainer,
          this.state.testType
        );

        getConfigDataExport
          .filter((item) => {
            return item.omit === undefined || item.omit === false;
          })
          .map((item) => (dataAfterHandle[item.name] = item.value));

        return parsedItems.push(dataAfterHandle);
      });

      //Export excel
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(parsedItems);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "PaymentDetailView.xlsx");
    }
    return parsedItems;
  };

  getCustomFields = () => {
    let getItem;
    let parseCustomFields;

    this.state.items.forEach((item) => {
      getItem = item;
    });

    if (getItem && getItem.customFields) {
      parseCustomFields = JSON.parse(getItem.customFields);
    }

    return parseCustomFields;
  };

  defaultColumns = () => {
    const customFields = this.getCustomFields();
    const { growerId, growerName } = this.state.growerInfo;
    const { testTypeId, testName, isDynamic } = this.state.testType;
    const urlApiContainer = {
      walnutTestList: process.env.REACT_APP_URL_WALNUT_TEST_LIST,
    };
    const { walnutTestList } = urlApiContainer;
    const getUrlApiContainer = `${walnutTestList}`;

    let columns = [
      {
        name: "LOT #",
        selector: "lot",
        cell: (row) => {
          return testTypeId === undefined ||
            testTypeId === null ||
            testTypeId === "" ? (
            row.lot
          ) : (
            <a
              href={`${getUrlApiContainer}/${row.id}?type=${testTypeId}&name=${testName}&dynamic=${isDynamic}`}
              title={row.lot}
            >
              <span>{row.lot}</span>
            </a>
          );
        },
        sortable: true,
        left: true,
        width: "150px",
      },
      {
        name: "Grower Name",
        selector: "growerName",
        cell: () => {
          return growerId === undefined ||
            growerId === null ||
            growerId === "" ? (
            growerName
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + growerId}
              title={growerName}
            >
              {growerName}
            </a>
          );
        },
        width: "250px",
        sortable: true,
        left: true,
      },
    ];

    if (customFields && customFields.length > 0) {
      customFields.map((f) => {
        const center = f.fileType === FIELD_TYPE_DROP_DOWN;
        const right =
          f.fileType === FIELD_TYPE_INTEGER ||
          f.fileType === FIELD_TYPE_PERCENT ||
          f.fileType === FIELD_TYPE_DECIMAL;
        return columns.push({
          name: f.name,
          selector: f.name,
          sortable: false,
          center: center,
          right: right,
        });
      });
    }

    if (
      this.state.testType.isDynamic === false &&
      this.state.qualityAttributes &&
      this.state.qualityAttributes.length > 0
    ) {
      this.state.qualityAttributes.forEach((attribute) => {
        columns.push({
          name: (
            <div style={{ textAlign: "center" }}>
              {qualityAttributeNamesMapping[attribute]}
            </div>
          ),
          selector: attribute,
          sortable: true,
          right: true,
          cell: (row) => {
            if (row[attribute] === null || row[attribute] === undefined)
              return "N/A";
            if (
              [
                "s4OtherVarietiesIncluded",
                "s4SeriousStain",
                "moisture",
                "s3EdibleYield",
              ].includes(attribute)
            ) {
              return parseFloat(row[attribute]).toFixed(1) + "%";
            } else {
              return row[attribute];
            }
          },
        });
      });
    }

    columns.push(
      {
        name: (
          <div className="title-datatable-container">
            <div className="main-title-datatable mb-sm">Payment</div>
            <div className="sub-title-datatable ml-md">
              <div className="sub-title-datatable-item">
                Payment {this.state.growerInfo.paymentNo}
              </div>
              <span>|</span>
              <div className="sub-title-datatable-item">
                YTD {this.state.growerInfo.paymentNo}
              </div>
            </div>
          </div>
        ),
        selector: "payment",
        cell: (row) => {
          return (
            <div className="sub-title-datatable">
              <div className="sub-title-datatable-item">{row.payment}</div>
              <div className="sub-title-datatable-item">{row.ytd}</div>
            </div>
          );
        },
        width: "300px",
        sortable: true,
        center: true,
      },
      {
        name: (
          <div style={{ textAlign: "center" }}>
            Original
            <br />
            Base Price
          </div>
        ),
        selector: "originalPrice",
        sortable: true,
        center: true,
        cell: (row) => {
          return <>{row.originalPrice}</>;
        },
      },
      {
        name: (
          <div style={{ textAlign: "center" }}>
            Adjusted
            <br />
            Base Price
          </div>
        ),
        selector: "lb",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.lb ? `$` + parseFloat(row.lb.replace(`$`, ``)) : "$0.00";
        },
      },
      {
        name: (
          <div style={{ textAlign: "center" }}>
            Quality
            <br />
            Adjustments
          </div>
        ),
        selector: "bonusDeduct",
        cell: (row) => {
          const bonusDeduct = parseFloat(row.bonusDeduct);
          return bonusDeduct < 0 ? (
            <span className="deduct-color">
              ${CommonUtilities.numberWithCommasNegative(bonusDeduct)}
            </span>
          ) : (
            <span className="bonus-color">
              ${CommonUtilities.numberWithCommas(bonusDeduct)}
            </span>
          );
        },
        sortable: true,
        center: true,
      }
    );

    this.state.itemsTaxFee.map((g) => {
      columns.push({
        name: (
          <div className="title-datatable-container">
            <div className="main-title-datatable mb-sm">{g.name}</div>
            <div className="sub-title-datatable ml-md">
              <div className="sub-title-datatable-item">
                Payment {this.state.growerInfo.paymentNo}
              </div>
              <span>|</span>
              <div className="sub-title-datatable-item">
                YTD {this.state.growerInfo.paymentNo}
              </div>
            </div>
          </div>
        ),
        selector: g.name,
        cell: () => {
          return (
            <div className="sub-title-datatable">
              <div className="sub-title-datatable-item">{g.payment}</div>
              <div className="sub-title-datatable-item">{g.ytd}</div>
            </div>
          );
        },
        sortable: true,
        center: true,
        width: "300px",
        omit: this.state.itemsTaxFee.length <= 0,
      });
    });

    columns.push({
      name: "",
      selector: "actions",
      sortable: false,
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle align="center" className="dropdown-toggle-custom">
            Actions
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              variant="link"
              onClick={() => {
                this.setState({
                  adjustments: row.adjustments,
                  showAdjustmentsPopup: true,
                });
              }}
            >
              <OptionDropdown
                icon="fa fa-list-alt"
                title="Applied Adjustments"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    });

    return columns;
  };

  getOriginalPriceByTestType = (itemPayment) => {
    let originalBasePrice = "";
    if (itemPayment.originalPrice && itemPayment.originalPrice.length > 0) {
      if (this.state.testType.multiPricesSameLot) {
        originalBasePrice = itemPayment.originalPrice
          .map((x) => `$` + x.valueInshell.toString())
          .join(" , ");
      } else if (this.state.testType.singlePricePerLot) {
        originalBasePrice =
          `$` + itemPayment.originalPrice[0].valueInshell.toString();
      } else {
        switch (itemPayment.originalPrice[0].primaryPrice) {
          case "Inshell": {
            originalBasePrice =
              `$` + itemPayment.originalPrice[0].valueInshell.toString();
            break;
          }
          case "Shelled": {
            originalBasePrice =
              `$` + itemPayment.originalPrice[0].valueShelled.toString();
            break;
          }
          case "Highest of Both": {
            const inshell = itemPayment.originalPrice[0].valueInshell;
            const shelled = itemPayment.originalPrice[0].valueShelled;
            if (inshell > shelled) {
              originalBasePrice = `$` + inshell.toString();
            } else {
              originalBasePrice = `$` + shelled.toString();
            }
            break;
          }
          default:
        }
      }
      return originalBasePrice;
    } else {
      return "N/A";
    }
  };

  columnOptions = () => {
    return (
      <ColumnOptions
        btnColumnOptions={this.state.btnColumnOptions}
        columnOptionOpening={this.columnOptionOpening}
        columnOptionClosed={this.columnOptionClosed}
        defaultColumns={this.defaultColumns()}
        hideColumns={this.state.hideColumns}
        columnOptionChanged={this.columnOptionChanged}
        columnOptionReset={this.columnOptionReset}
        testType={this.state.testType.isDynamic ? "dynamic" : "static"}
        includesQualityColumn={true}
        customFields={this.getCustomFields()}
      />
    );
  };

  handleReset = () => {
    this.getGrowerStatementData();
    this.setState({ filterLotNumber: "" });
  };

  handleSearch = () => {
    const getFilterInput = this.state.filterLotNumber.trim().toLowerCase();

    const filterData = this.state.filter.filter(({ lot }) =>
      lot.toLowerCase().includes(getFilterInput)
    );

    if (filterData.length <= 0 && this.state.filterLotNumber === "") {
      this.getGrowerStatementData();
    }
    this.setState({ items: filterData });
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterLotNumber"
                placeholder="Filter Lot Number"
                onChange={this.handleChange}
                maxLength={100}
                value={this.state.filterLotNumber}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
}
export default App;
