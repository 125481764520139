import React from "react";
import DataTable from "react-data-table-component";
import TabAttributes from "./TabAttributes";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data ? props.data.children : [],
    };
    this.bindData = this.bindData.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  bindData(data) {
    this.setState({
      data: data && data.length > 0 ? data[0].children : [],
    });
  }

  setColumns = () => {
    return [
      {
        name: "",
        selector: "varietyName",
        left: true,
        cell: (row) => {
          return <>&nbsp;&nbsp;&nbsp;&nbsp;{row.varietyName}</>;
        },
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        id="emp"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.data}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        expandableRows={true}
        expandableRowsComponent={
          <TabAttributes
            // handleDelete={this.props.handleDelete.bind(this)}
            // handleShow={this.props.handleShow}
            expandableRowExpanded={(row) => {
              // Collapse/expand all the rows
              return this.props.expandableRowExpanded;
            }}
          />
        }
        // conditionalRowStyles={this.conditionalRowStyles()}
        noTableHead={true}
        expandableRowExpanded={(row) => {
          // Collapse/expand all the rows
          return this.props.expandableRowExpanded;
        }}
      />
    );
  }
}
export default App;
