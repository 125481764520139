import {
  SET_TEST_TYPES,
  ADD_TEST_TYPE,
  REMOVE_TEST_TYPE,
  UPDATE_TEST_TYPE,
} from "../actions/types";

export const testTypeReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TEST_TYPES: {
      return action.payload;
    }
    case ADD_TEST_TYPE: {
      const testTypes = [...state];
      testTypes.push(action.payload);
      testTypes.sort((a, b) => a.name.localeCompare(b.name));
      return testTypes;
    }
    case REMOVE_TEST_TYPE: {
      const _id = action.payload;
      const testTypes = [...state].filter((testType) => testType._id !== _id);
      return testTypes;
    }
    case UPDATE_TEST_TYPE: {
      const testTypes = [...state];
      const index = testTypes.findIndex(
        (testType) => testType._id === action.payload._id
      );
      if (index !== -1) {
        testTypes[index] = action.payload;
      }
      return testTypes;
    }
    default: {
      return state;
    }
  }
};
