import React, { Component } from "react";
import SaveButton from "../Controls/SaveButton";
import { Button, Form, Modal } from "react-bootstrap";
import { METHOD_POST } from "../Utilities/Constant";

export default class RequestUpdatePopup extends Component {
  constructor(props) {
    super();
    this.state = {
      isShowed: false,
      requestUpdateLoading: false,
      growerTemp: {
        name: "",
        address: "",
        city: "",
        state: "",
        modifiedUserId: props.loggedUser.referenceUserId,
        modifiedDate: null,
        createdUserId: props.loggedUser.referenceUserId,
        createdDate: null,
      },
    };
  }

  componentDidMount = () => {
    this.props.onRef(this);
  };

  showRequestUpdatePopup = () => {
    this.setState({
      isShowed: true,
    });
  };

  hideRequestUpdatePopup = () => {
    this.setState({
      isShowed: false,
    });
  };

  showRequestUpdateLoading = () => {
    this.setState({
      requestUpdateLoading: true,
    });
  };

  hideRequestUpdateLoading = () => {
    this.setState({
      requestUpdateLoading: false,
    });
  };

  onChange = (e) => {
    this.setState({
      growerTemp: { ...this.state.growerTemp, [e.target.name]: e.target.value },
    });
  };

  resetGrowerTemp = () => {
    this.setState({
      growerTemp: {
        name: "",
        address: "",
        city: "",
        state: "",
        modifiedUserId: this.props.loggedUser._id,
        modifiedDate: null,
        createdUserId: this.props.loggedUser._id,
        createdDate: null,
      },
    });
  };

  requestUpdate = (e) => {
    e.preventDefault();
    if (
      !this.state.growerTemp.name.trim() &&
      !this.state.growerTemp.address.trim() &&
      !this.state.growerTemp.city.trim() &&
      !this.state.growerTemp.state.trim()
    ) {
      this.resetGrowerTemp();
      this.hideRequestUpdatePopup();
      return;
    }
    const requestOptions = {
      method: METHOD_POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        newGrowerTemp: {
          ...this.state.growerTemp,
          createdDate: new Date(),
          modifiedDate: new Date(),
        },
        currentGrower: {
          baseURL: this.props.GlobalConfig.REACT_APP_BASE_URL,
          name: this.props.currentGrower.name,
          address: this.props.currentGrower.address,
          city: this.props.currentGrower.city,
          state: this.props.currentGrower.state,
          _id: this.props.currentGrower._id,
          email: this.props.currentGrower.contactEmail,
          processorId: this.props.loggedUser.processorId,
        },
      }),
    };

    const that = this;
    that.showRequestUpdateLoading();
    fetch(
      process.env.REACT_APP_API_GROWER_URL + "/requestupdate",
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {
        if (responseData.result === "OK") {
          window.showAlert("", "Your request was successfully sent.", "");
          that.hideRequestUpdateLoading();
          that.resetGrowerTemp();
          that.hideRequestUpdatePopup();
        } else {
          window.showAlert("Error", responseData.message, "error");
          that.hideRequestUpdateLoading();
        }
      });
  };

  render() {
    return (
      <Modal
        show={this.state.isShowed}
        onHide={this.hideRequestUpdatePopup}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        className="bulk-apply-payments-modal"
        centered
      >
        <Form onSubmit={this.requestUpdate}>
          <Modal.Header>
            <Modal.Title>Request Update</Modal.Title>
          </Modal.Header>
          <Modal.Body className="request-update-body">
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="name">
                Grower Name
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder="Please enter your name"
                  value={this.state.growerTemp.name}
                  onChange={this.onChange}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="address">
                Address
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Please enter your address"
                  value={this.state.growerTemp.address}
                  onChange={this.onChange}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="city">
                City
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="Please enter your city"
                  value={this.state.growerTemp.city}
                  onChange={this.onChange}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="state">
                State
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  placeholder="Please enter your state"
                  value={this.state.growerTemp.state}
                  onChange={this.onChange}
                  maxLength={500}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="request-update-footer">
            <SaveButton
              loading={this.state.requestUpdateLoading}
              variant="primary"
              text="Send"
              icon={
                <i
                  className="fa fa-paper-plane"
                  aria-hidden="true"
                  style={{
                    marginRight: 2.5,
                    fontSize: 18,
                  }}
                ></i>
              }
            />
            <Button variant="warning" onClick={this.hideRequestUpdatePopup}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
