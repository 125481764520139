import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
var DateFormat = require("dateformat");

export const parseViewModel = (dataModel) => {
  return {
    _id: dataModel._id, //This is temporary ID using in GUID only.
    varietyId: dataModel.varietyId,
    paymentDate: dataModel.paymentDate,
    paymentPercent: dataModel.paymentPercent,
    varietyName: dataModel.varietyName,
  };
};

export const wizardColumns = (
  handleShowWithWizardMode,
  testType,
  loggedUser,
  removeRow
) => {
  return [
    {
      name: "Variety",
      selector: "varietyName",
      width: "150px",
      cell: (row, index) => (
        <a
          onClick={() => {
            handleShowWithWizardMode(row, index);
          }}
          href="# "
          title="Click to edit"
        >
          {row.varietyName}
        </a>
      ),
      omit: testType.paymentByVariety !== true,
    },
    {
      name: "Payment Date",
      selector: "paymentDate",
      sortable: true,
      center: true,
      cell: (row, index) => {
        return loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          DateFormat(row.paymentDate, "mm/dd/yyyy")
        ) : (
          <a
            onClick={() => handleShowWithWizardMode(row, index)}
            href="#"
            title="Click to edit"
          >
            {DateFormat(row.paymentDate, "mm/dd/yyyy")}
          </a>
        );
      },
      width: "200px",
    },
    {
      name: "Payment Percent",
      selector: "paymentPercent",
      sortable: true,
      right: true,
      cell: (row) =>
        CommonUtilities.numberWithCommas(
          (row.paymentPercent == null ||
          row.paymentPercent === undefined ||
          isNaN(row.paymentPercent)
            ? 0
            : parseFloat(row.paymentPercent)
          ).toFixed(1)
        ) + "%",
      width: "200px",
      omit: testType.basePriceByPayment === true,
    },
    {
      name: "",
      width: "100px",
      cell: (row, index) => (
        <div>
          {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <>
              <a
                title="Click to edit"
                href="# "
                onClick={() => {
                  handleShowWithWizardMode(row, index);
                }}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Click to remove"
                href="# "
                className="on-default remove-row"
                onClick={() => removeRow(index)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
            </>
          )}
        </div>
      ),
    },
  ];
};
