import { SET_HELP_CATEGORIES_LOADING, SET_TEST_TYPES_LOADING } from "./types";

export const setHelpCategoriesLoading = (data) => {
  return {
    type: SET_HELP_CATEGORIES_LOADING,
    payload: data,
  };
};

export const setTestTypesLoading = (data) => {
  return {
    type: SET_TEST_TYPES_LOADING,
    payload: data,
  };
};
