import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import OptionDropdown from "../Base/OptionDropdown";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import { Dropdown } from "react-bootstrap";
import { isArray } from "lodash";

var DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.children,
    };

    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleExportPdf = this.handleExportPdf.bind(this);
    this.handleExportDetailedPdf = this.handleExportDetailedPdf.bind(this);
    this.handleExportDetailedPdfNew = this.handleExportDetailedPdfNew.bind(this);
  }

  handleUpdateStatus = async (e) => {
    const cropYearId = e.currentTarget.dataset.cropyearid;
    const growerId = e.currentTarget.dataset.growerid;
    const paymentId = e.currentTarget.dataset.paymentid;
    const varietyId = e.currentTarget.dataset.varietyid ? e.currentTarget.dataset.varietyid : ``;
    const status = e.currentTarget.dataset.status;
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status,
        modifiedUserId: this.props.loggedUser._id,
        createdUserId: this.props.loggedUser._id,
        cropYearId: cropYearId,
        growerId: growerId,
        paymentId: paymentId,
        varietyId: varietyId,
      }),
    };

    const responseSubscription = await fetch(
      this.props.GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL +
      "updatestatus",
      requestOptions
    );
    const resultObject = await responseSubscription.json();

    if (resultObject && resultObject.result === "OK") {
      //Update status of selected row in table
      const newItems = this.state.items.map((u) =>
        u.growerId === growerId &&
          u.cropYearId === cropYearId &&
          u.paymentId === paymentId
          ? Object.assign(
            {},
            u,
            u.createdDate === undefined || u.createdDate === null
              ? {
                status: status,
                modifiedDate: resultObject.currentDate,
                createdDate: resultObject.currentDate,
                reportId: resultObject.data._id,
              }
              : {
                status: status,
                modifiedDate: resultObject.currentDate,
                reportId: resultObject.data._id,
              }
          )
          : u
      );

      window.showAlert("", "Payment status is updated successfully.", "");
      this.setState(
        {
          items: newItems,
        },
        () => {
          this.props.handleUpdateStatus(
            cropYearId,
            growerId,
            paymentId,
            status,
            resultObject
          );
        }
      );
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleExportPdf = (e) => {
    this.props.handleExportPdf(e);
  };

  handleExportDetailedPdf = (e) => {
    this.props.handleExportDetailedPdf(e);
  };

  handleExportDetailedPdfNew = (e) => {
    this.props.handleExportDetailedPdfNew(e);
  };

  formatStatus = (row) => {
    if (row.status === "Preview") {
      return (
        <span
          className="label label-primary"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    if (row.status === "Paid") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.status}
      </span>
    );
  };

  setColumns = () => {
    return [
      {
        name: "Variety Name",
        selector: "varietyName",
        sortable: true,
        width: "250px",
        omit: this.props.testType.paymentByVariety !== true
      },
      {
        name: "No",
        selector: "paymentNo",
        sortable: true,
        center: true,
        width: "45px",
      },
      {
        name: "Date",
        selector: "paymentDate",
        sortable: true,
        center: true,
        width: "100px",
        cell: (row) => {
          return parseInt(DateFormat(row.paymentDate, "yyyymmdd")) <
            this.state.currentDate && row.status !== "Paid" ? (
            <span
              className="label label-danger"
              style={{
                fontSize: "80%",
                paddingTop: "5px",
                paddingBottom: "7px",
                width: "70px",
              }}
            >
              {DateFormat(row.paymentDate, "mm/dd/yyyy")}
            </span>
          ) : (
            DateFormat(row.paymentDate, "mm/dd/yyyy")
          );
        },
      },
      {
        name: "Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        width: "100px",
        cell: (row) => {
          return (
            CommonUtilities.numberWithCommas(
              (row.paymentPercent == null ||
                row.paymentPercent === undefined ||
                isNaN(row.paymentPercent)
                ? 0
                : parseFloat(row.paymentPercent)
              ).toFixed(1)
            ) + "%"
          );
        },
      },
      {
        name: "Net Amount",
        selector: "netPayment",
        sortable: true,
        right: true,
        width: "200px",
        cell: (row) => {
          return row.netPayment < 0
            ? "$(" +
            CommonUtilities.numberWithCommas(
              parseFloat(Math.abs(row.netPayment)).toFixed(2)
            ) +
            ")"
            : "$" +
            CommonUtilities.numberWithCommas(
              parseFloat(row.netPayment).toFixed(2)
            );
        },
      },
      {
        name: "Gross Amount",
        selector: "payment",
        sortable: true,
        right: true,
        width: "200px",
        cell: (row) => {
          return row.payment < 0
            ? "$(" +
            CommonUtilities.numberWithCommas(
              parseFloat(Math.abs(row.payment)).toFixed(2)
            ) +
            ")"
            : "$" +
            CommonUtilities.numberWithCommas(
              parseFloat(row.payment).toFixed(2)
            );
        },
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        sortable: true,
        width: "100px",
        cell: (row) => {
          return this.formatStatus(row);
        },
      },
      {
        name: "Generated Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        width: "180px",
        cell: (row) => {
          return row.createdDate
            ? DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "",
        selector: "actions",
        sortable: false,
        center: true,
        allowOverflow: true,
        cell: (row) => {
          // When items length in table is smaller than 7, adjust action dropdown to overflow table and position always show down
          // When items length in table is greater than 7, action dropdown maybe show (up, down)
          const itemsLength = isArray(this.state.items)
            ? this.state.items.length
            : 0;
          const popperConfig = itemsLength < 7 && {
            strategy: "fixed",
          };
          return (
            <Dropdown>
              <Dropdown.Toggle
                align="center"
                className="dropdown-toggle-custom"
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu popperConfig={popperConfig}>
                {row.status === "Preview" ? (
                  this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                    <>
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-action="preview"
                        onClick={this.handleExportPdf.bind(this)}
                        title="Preview grower statement"
                      >
                        <OptionDropdown icon="eye" title="Preview" />
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-action="preview"
                        onClick={this.handleExportPdf.bind(this)}
                        title="Preview grower statement"
                      >
                        <OptionDropdown icon="eye" title="Preview" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-status="Unpaid"
                        title="Generate grower statement, status of statement to be unpaid as default."
                        onClick={this.handleUpdateStatus.bind(this)}
                      >
                        <OptionDropdown
                          icon="plus-square"
                          title="Generate Statement"
                        />
                      </Dropdown.Item>
                    </>
                  )
                ) : (
                  <>
                    {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                      ""
                    ) : row.status === "Unpaid" ? (
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-status="Paid"
                        title="Click here to update payment status."
                        onClick={this.handleUpdateStatus.bind(this)}
                      >
                        <OptionDropdown icon="chevron-down" title="Mark Paid" />
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-status="Unpaid"
                        title="Click here to revert payment status."
                        onClick={this.handleUpdateStatus.bind(this)}
                      >
                        <OptionDropdown icon="undo" title="Revert Unpaid" />
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      variant="link"
                      data-id={row.reportId}
                      data-growerid={row.growerId}
                      data-cropyearid={row.cropYearId}
                      data-paymentid={row.paymentId}
                      data-varietyid={row.varietyId}
                      data-action="print"
                      onClick={this.handleExportPdf.bind(this)}
                      title="Print Grower Statement"
                    >
                      <OptionDropdown
                        icon="print"
                        title="Print Grower Statement"
                      />
                    </Dropdown.Item>
                    {(row.status === "Paid" || row.status === "Unpaid") &&
                      this.props.isUseDetailedGrowerStatement === true &&
                      this.props.testType.isDynamic !== true ? (
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-status={row.status}
                        data-action="print"
                        title="Detailed Grower Statement"
                        onClick={this.handleExportDetailedPdf.bind()}
                      >
                        <OptionDropdown
                          icon="fa fa-list-alt"
                          title="Detailed Grower Statement"
                        />
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )}
                    {(row.status === "Paid" || row.status === "Unpaid") &&
                      this.props.isUseDetailedGrowerStatement === true &&
                      this.props.testType.isDynamic !== true ? (
                      <Dropdown.Item
                        variant="link"
                        data-id={row.reportId}
                        data-growerid={row.growerId}
                        data-cropyearid={row.cropYearId}
                        data-paymentid={row.paymentId}
                        data-varietyid={row.varietyId}
                        data-status={row.status}
                        data-action="print"
                        title="Detailed Grower Statement (New)"
                        onClick={this.handleExportDetailedPdfNew.bind()}
                      >
                        <OptionDropdown
                          icon="fa fa-list-alt"
                          title="Detailed Grower Statement (New)"
                        />
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )}
                    <Dropdown.Item
                      target="_blank"
                      title="Payment Detail View"
                      href={`
                          ${this.props.getUrlApiContainer}/${row.cropYearId}/${row.paymentId}/${row.growerId}
                        `}
                    >
                      <OptionDropdown
                        icon="fa fa-list-alt"
                        title="Payment Detail View"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      target="_blank "
                      title="Revision History"
                      href={
                        process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                        "/object/" +
                        row.reportId
                      }
                    >
                      <OptionDropdown icon="history" title="Revision History" />
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
