import React from "react";

const NutTypeGroup = ({
  name,
  nutTypeId,
  _id,
  section,
  color,
  size,
  sequence,
  handleOpenAddTestField,
  handleDeleteGroup,
  switchUpSequenceGroup,
  switchDownSequenceGroup,
  isSmallest,
  isLargest,
  index,
  editNutTypeGroup,
  bodyTestField,
}) => {
  return (
    <div className={`${size} panel-group-item`} key={index}>
      <div className="row panel-group-item-spacing panel-height-body">
        <section
          className="panel-custom panel-height-body"
          style={{ background: color }}
        >
          <header className="panel-custom " style={{ background: color }}>
            <div className="col-lg-12 test-type-group-heading-container">
              <div className="col-xs-6 col-sm-6 col-lg-6 test-type-group-heading-container">
                <div className="row test-type-group-heading-container">
                  <div className="panel-edit-group">
                    <div
                      onClick={() => editNutTypeGroup({ _id, section })}
                      className="panel-title panel-title-custom"
                      title={`Edit Group - ${name}`}
                    >
                      {name}
                    </div>
                    <div
                      className="panel-edit-group-button"
                      onClick={() => editNutTypeGroup({ _id, section })}
                      title={`Edit Group - ${name}`}
                    >
                      <i className={`fa fa-pencil-square-o fa-lg`}></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-6 col-sm-6 col-lg-6 test-type-group-heading-container">
                <div className="row test-type-group-heading-container">
                  <div className="panel-item-action panel-item-action-color">
                    <a
                      onClick={() => handleOpenAddTestField(section, _id)}
                      className="panel-heading-button"
                    >
                      <i className="fa fa-list-ul fa-lg"></i> Select Fields
                    </a>
                    {isSmallest && (
                      <div
                        className="panel-action-up-down-disable panel-action-cursor"
                        title="Move up group"
                      >
                        <i className="fa fa-caret-up fa-lg"></i>
                      </div>
                    )}

                    {!isSmallest && (
                      <div
                        className="panel-action-cursor"
                        onClick={() => switchDownSequenceGroup(index, section)}
                        title="Move up group"
                      >
                        <i className="fa fa-caret-up fa-lg"></i>
                      </div>
                    )}
                    {isLargest && (
                      <div
                        className="panel-action-up-down-disable ml-xs panel-action-cursor"
                        title="Move down group"
                      >
                        <i className="fa fa-caret-down fa-lg"></i>
                      </div>
                    )}
                    {!isLargest && (
                      <div
                        className="panelActionDown ml-xs panel-action-cursor"
                        title="Move down group"
                        onClick={() => switchUpSequenceGroup(index, section)}
                      >
                        <i className="fa fa-caret-down fa-lg"></i>
                      </div>
                    )}
                    <div
                      className="ml-lg panel-action-cursor"
                      title="Remove group"
                      onClick={() => handleDeleteGroup({ _id, section })}
                    >
                      <i className="fa fa-times fa-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div
            className="panel-body panel-body-custom"
            style={{
              boxShadow: "inset 0px 0px 0px 1px" + color + "52",
            }}
          >
            <fieldset>
              <div>{bodyTestField}</div>
            </fieldset>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NutTypeGroup;
