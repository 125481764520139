import {
  SET_HELP_CATEGORIES,
  ADD_HELP_CATEGORY,
  REMOVE_HELP_CATEGORY,
} from "../actions/types";

export const helpReducer = (state = [], action) => {
  switch (action.type) {
    case SET_HELP_CATEGORIES: {
      return action.payload;
    }
    case ADD_HELP_CATEGORY: {
      const categories = [...state];
      categories.push(action.payload);
      categories.sort((a, b) => a.name.localeCompare(b.name));
      return categories;
    }
    case REMOVE_HELP_CATEGORY: {
      const categories = [...state].filter(
        (category) => category.name !== action.payload
      );
      return categories;
    }
    default: {
      return state;
    }
  }
};
