import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

var DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.children,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  setColumns = () => {
    return [{
      name: "Variety",
      selector: "varietyName",
      sortable: true,
      cell: (row) => {
        return this.props.readOnly === true ||
          this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          row.varietyName
        ) : (
          <a
            onClick={() => this.props.handleShow(row)}
            href="#"
            title="Edit"
          >
            {row.varietyName}
          </a>
        );
      },
      omit: this.props.testType.paymentByVariety !== true,
    },
    {
      name: "Payment Date",
      selector: "paymentDate",
      sortable: true,
      center: true,
      cell: (row) => {
        return this.props.readOnly === true ||
          this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          DateFormat(row.paymentDate, "mm/dd/yyyy")
        ) : (
          <a onClick={() => this.props.handleShow(row)} href="#">
            {DateFormat(row.paymentDate, "mm/dd/yyyy")}
          </a>
        );
      },
      width: "200px",
    },
    {
      name: "Payment Percent",
      selector: "paymentPercent",
      sortable: true,
      right: true,
      cell: (row) =>
        CommonUtilities.numberWithCommas(
          (row.paymentPercent == null ||
            row.paymentPercent === undefined ||
            isNaN(row.paymentPercent)
            ? 0
            : parseFloat(row.paymentPercent)
          ).toFixed(1)
        ) + "%",
      width: "200px",
      omit: this.props.testType.basePriceByPayment === true
    },
    // {
    //   name: "Modified Date",
    //   selector: "modifiedDate",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => {
    //     return row.modifiedDate
    //       ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
    //       : "";
    //   },
    // },
    // {
    //   name: "Created Date",
    //   selector: "createdDate",
    //   sortable: true,
    //   center: true,
    //   cell: (row) => {
    //     return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
    //   },
    // },
    {
      omit: this.props.readOnly,
      name: "",
      cell: (row) => (
        <div>
          {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <>
              {" "}
              <a
                title="Edit payment setting"
                href="#"
                onClick={() => this.props.handleShow(row)}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Remove payment setting"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                data-growerid={row.growerId}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
            </>
          )}

          <a
            target="_blank"
            title="Revision history"
            href={
              process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
              "/object/" +
              row._id
            }
            className="on-default remove-row"
          >
            <i className="fa fa-history fa-lg"></i>
          </a>
        </div>
      ),
    },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
