import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
const { sortBy } = require("lodash");

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      items: this.composeData(props),
    };
  }

  composeData = (props) => {
    let viewData = [];
    if (props.data.data && props.data.data !== ``) {
      viewData = JSON.parse(props.data.data);
      if (viewData && viewData.length > 0) {
        if (props.testType.varietyOptional === true) {
          viewData = sortBy(viewData, [`attribute`], ["asc"]);
        } else {
          viewData = sortBy(
            viewData,
            [`varietyName`, `attribute`],
            ["asc", `asc`]
          );
        }
      }
    }

    return viewData;
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        width: "150px",
        omit: this.props.testType.varietyOptional === true,
      },
      {
        name: "Quality Attribute",
        selector: "attribute",
        sortable: true,
      },
      {
        name: "Applies To",
        selector: "applyTo",
        sortable: false,
        width: "90px",
        omit: this.props.testType.isDynamic,
      },
      {
        name: "From",
        selector: "min",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.min == null || row.min === undefined || isNaN(row.min)
              ? 0
              : parseFloat(row.min)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "To",
        selector: "max",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.max == null || row.max === undefined || isNaN(row.max)
              ? 0
              : parseFloat(row.max)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) => {
          return parseFloat(row.value) < 0 ? (
            <span className="deduct-color">
              ${CommonUtilities.numberWithCommasNegative(parseFloat(row.value))}
            </span>
          ) : (
            <span className="bonus-color">
              ${CommonUtilities.numberWithCommas(parseFloat(row.value))}
            </span>
          );
        },
      },
      {
        name: "Bonus or Deduction Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      },
    ];
  };

  render() {
    return (
      <DataTable
        key="subTableTemplate"
        id="subTableTemplate"
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
