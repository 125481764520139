import React from "react";
import Alert from "../Utilities/Alert";
import Textbox from "../FormControl/Textbox";

import SendTestEmailPopup from "./SendTestMailPopup";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      showMsg: false,
      config: {
        host: "",
        port: "",
        username: "",
        password: "",
      },
      alertStatus: null,
    };
  }

  componentDidMount() {
    this.querySmtpConfig();
  }

  querySmtpConfig = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_EMAIL_URL}/querysmtpconfig`
      );
      const response = await result.json();

      if (response.result === "OK") {
        this.setState({
          config: response.data,
        });
      } else {
        window.showAlert("Error", response.message, "error");
      }
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleShowResultMsg = () => {
    this.setState({
      showMsg: true,
    });
  };

  handleHideResultMsg = () => {
    this.setState({
      showMsg: false,
    });
  };

  handleSendTestMailStatus = (alertStatus) => {
    this.setState({
      alertStatus: alertStatus,
    });
  };

  render() {
    const { showMsg, config, alertStatus } = this.state;

    return (
      <>
        <div className="form-horizontal">
          <section className="panel">
            <div className="panel-body">
              {showMsg && (
                <Alert
                  message={alertStatus?.message}
                  type={alertStatus?.status}
                  show={true}
                ></Alert>
              )}
              <h4 className="mb-xl">Send Test Email Settings</h4>

              <Textbox
                type="text"
                label={"Host"}
                name="host"
                value={config.host}
                labelClass={3}
                readOnly
              />
              <Textbox
                type="number"
                label={"Port"}
                name="port"
                value={config.port}
                labelClass={3}
                readOnly
              />
              <Textbox
                type="email"
                label={"Username"}
                name="username"
                value={config.username}
                labelClass={3}
                readOnly
              />
              <Textbox
                type="password"
                label={"Password"}
                name="password"
                value={config.password}
                labelClass={3}
                readOnly
              />
            </div>
            <div className="panel-footer">
              <div className="row">
                <div className="col-md-9 col-md-offset-3">
                  <SendTestEmailPopup
                    handleShowResultMsg={this.handleShowResultMsg}
                    handleHideResultMsg={this.handleHideResultMsg}
                    handleSendTestMailStatus={this.handleSendTestMailStatus}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default App;
