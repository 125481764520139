import React, { Component } from "react";
import DataTable from "react-data-table-component";

export default class ChildTable extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.data.children,
    };
  }

  componentDidUpdate = (previousProps) => {
    if (
      JSON.stringify(previousProps.data.children) !==
      JSON.stringify(this.props.data.children)
    ) {
      this.setState({
        items: this.props.data.children,
      });
    }
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        noHeader={true}
        noTableHead={this.props.isAdmin !== true}
        columns={this.props.columns()}
        data={this.state.items}
      ></DataTable>
    );
  }
}
