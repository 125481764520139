import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import CommonUtilities from "../Utilities/Common";

export default class adjustmentsPopup extends Component {
  constructor(props) {
    super();
  }

  adjustmentColumns = () => {
    return [
      {
        name: "Apply To",
        selector: "applyTo",
        sortable: true,
      },
      {
        name: "Apply Type",
        selector: "applyType",
        sortable: true,
        width: "400px",
      },
      {
        name: "Attribute",
        selector: "attribute",
        sortable: true,
        width: "400px",
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        cell: (row) => {
          if (row.value) {
            return row.value < 0 ? (
              <span className="deduct-color">
                $
                {CommonUtilities.numberWithCommasNegative(
                  parseFloat(row.value)
                )}
              </span>
            ) : (
              <span className="bonus-color">
                ${CommonUtilities.numberWithCommas(parseFloat(row.value))}
              </span>
            );
          } else {
            return "N/A";
          }
        },
        width: "100px",
      },
    ];
  };

  render() {
    return (
      <Modal
        show={this.props.showAdjustmentsPopup}
        onHide={this.props.closeAdjustmentsPopup}
        animation={false}
        centered
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        className="adjustment-popup-table"
      >
        <Modal.Header>
          <Modal.Title>
            Adjustments
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={this.props.closeAdjustmentsPopup}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            highlightOnHover
            striped={true}
            pointerOnHover={false}
            id="adjustments"
            key="adjustments"
            title=""
            noHeader={true}
            columns={this.adjustmentColumns()}
            data={this.props.adjustments}
            className="table table-bordered table-striped mb-none"
          />
        </Modal.Body>
      </Modal>
    );
  }
}
