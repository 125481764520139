import React from "react";
import DataTable from "react-data-table-component";
import { Button, Form, Row, Modal } from "react-bootstrap";
import {
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
} from "../Utilities/Constant";
import CommonUtilities from "../Utilities/Common";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";

const DateFormat = require("dateformat");
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      cropYears: [],
      items: [],
      data: {
        _id: "",
        name: "",
        fileSize: 0,
        filePath: "",
        note: "",
        cropYearId: undefined,
        processorId: ``,
      },
      selectedFile64: "",
      isShow: false,
      readOnly: false,
      errorMessage: "",
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChangeTextFieldForm = this.handleChangeTextFieldForm.bind(this);
    this.changeCropYear = this.changeCropYear.bind(this);
  }

  async componentDidMount() {
    this.getItems();
    this.getCropYears();
  }

  async getItems() {
    if (this.props.objectId && this.props.objectId !== "") {
      const response = await fetch(
        `${GlobalConfig.REACT_APP_API_ATTACHMENT_URL}byobject/${this.props.objectType}/${this.props.objectId}/${this.props.processorId}`
      );
      const responseData = await response.json();
      if (responseData.result === "OK") {
        if (responseData.data && responseData.data.length > 0) {
          responseData.data.sort(
            CommonUtilities.getSortOrderDesc(`createdDate`)
          );
        }
        this.setState({
          items: responseData.data,
        });
      }
    }
  }

  async getCropYears() {
    const response = await fetch(
      `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}getall/${this.props.processorId}`
    );
    const responseData = await response.json();
    if (responseData.result === "OK") {
      let cropYears = [];
      if (responseData.data && responseData.data.length > 0) {
        cropYears = responseData.data.map((x) => {
          return {
            value: x._id,
            label: x.name,
          };
        });
      }
      this.setState({
        cropYears: cropYears,
      });
    }
  }

  newDataObject = () => {
    return {
      _id: "",
      name: "",
      fileSize: 0,
      note: "",
      cropYearId: undefined,
      processorId: ``,
    };
  };

  handleClosePopup = () => {
    this.setState({
      isShow: false,
      errorMessage: "",
    });
  };

  handleShowPopup = (data, readOnly) => {
    let cropYearName = ``;
    if (readOnly) {
      const selectedCropYear = this.state.cropYears.filter(
        (x) => x.value === data.cropYearId
      );
      if (selectedCropYear && selectedCropYear.length > 0) {
        cropYearName = selectedCropYear[0].label;
      }
    }
    this.setState({
      readOnly: readOnly,
      isShow: true,
      errorMessage: "",
      data: {
        ...this.state.data,
        _id: data._id,
        name: data.name,
        fileSize: data.fileSize,
        filePath: data.filePath,
        note: data.note,
        cropYearId: data.cropYearId,
        cropYearName: cropYearName,
        processorId: data.processorId,
      },
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    let { _id, name, processorId, fileSize, note, cropYearId } =
      this.state.data;

    const isCreate = !_id;

    let errorMessage = "";
    //Reset message
    this.setState({
      errorMessage: "",
    });

    if (isCreate) {
      if (
        this.state.selectedFile64 === undefined ||
        this.state.selectedFile64 === null ||
        this.state.selectedFile64 === ``
      ) {
        errorMessage = "Please upload a file to continue";
      }
    }

    if (errorMessage === ``) {
      if (
        cropYearId === undefined ||
        cropYearId === null ||
        cropYearId.trim() === ""
      ) {
        errorMessage = "Please select a crop year to continue";
      }
    }

    if (errorMessage !== "") {
      this.setState({ errorMessage: errorMessage });
      return;
    }

    const apiURL = isCreate
      ? `${GlobalConfig.REACT_APP_API_ATTACHMENT_URL}`
      : `${GlobalConfig.REACT_APP_API_ATTACHMENT_URL}/${_id}`;
    const method = isCreate ? "POST" : "PATCH";
    processorId =
      this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
      this.props.loggedUser.role === USER_ROLE_STAFF
        ? this.props.loggedUser.processorId
        : processorId;

    const requestBody = isCreate
      ? {
          _id,
          name,
          fileBase64: this.state.selectedFile64,
          fileSize,
          note,
          cropYearId,
          processorId: processorId,
          objectType: "Grower",
          objectId: this.props.objectId,
          createdUserId: this.props.loggedUser._id,
        }
      : {
          _id,
          note: note,
          cropYearId,
          modifiedUserId: this.props.loggedUser._id,
        };
    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(apiURL, requestOptions);
    const resultObject = await response.json();

    //If created successful
    if (resultObject.result === "OK") {
      window.showAlert("", "Save successful!", "");
      this.getItems();
      this.handleClosePopup();
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleDelete = (e) => {
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected file?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            try {
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  userId: this.props.loggedUser._id,
                  modifiedUserId: this.props.loggedUser._id,
                }),
              };
              const response = await fetch(
                GlobalConfig.REACT_APP_API_ATTACHMENT_URL + removedId,
                requestOptions
              );
              const resultObject = await response.json();
              if (resultObject && resultObject.result === "OK") {
                this.handleDeleteAfterDeletedOnServer(removedId);
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    //If deleted successful
    const newItems = this.state.items.filter((item) => {
      return item._id !== removedId;
    });
    this.setState({
      items: newItems,
    });
    window.showAlert("Success", "Removed successful!", "");
  };

  setColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          const url = `${GlobalConfig.REACT_APP_API_URL}${row.filePath}`;
          return (
            <a href={url} target="_blank">
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Size",
        selector: "fileSize",
        sortable: true,
        width: "80px",
      },
      {
        name: "Date Added",
        selector: "createdDate",
        sortable: true,
        center: true,
        width: "160px",
        cell: (row) => {
          return row.createdDate
            ? DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
      },
      {
        name: "Crop Year",
        selector: "cropYearName",
        sortable: true,
        width: "220px",
      },
      {
        name: "",
        width: "100px",
        cell: (row) => (
          <>
            {this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
            this.props.loggedUser.role === USER_ROLE_STAFF ? (
              <>
                <a
                  href="#"
                  onClick={() => this.handleShowPopup(row, false)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Click here to remove"
                  href="# "
                  className="on-default remove-row"
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
              </>
            ) : (
              <a
                href="#"
                onClick={() => this.handleShowPopup(row, true)}
                className="on-default edit-row"
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>
            )}
            {this.props.loggedUser.role === USER_ROLE_GROWER ? (
              ""
            ) : (
              <>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                  className="on-default remove-row"
                >
                  <i className="fa fa-history fa-lg"></i>
                </a>
              </>
            )}
          </>
        ),
        minWidth: "180px",
      },
    ];
  };

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    //Convert selected file to base64 and store in state
    var self = this;
    var reader = new FileReader();
    reader.onload = function (upload) {
      self.setState({
        selectedFile64: upload.target.result,
      });
    };
    reader.readAsDataURL(selectedFile);

    this.setState({
      data: Object.assign({}, this.state.data, {
        name: selectedFile.name,
        fileSize: CommonUtilities.formatBytes(selectedFile.size, 0),
      }),
    });
  };

  handleChangeTextFieldForm = (event) => {
    const { value } = event.target;
    this.setState({
      data: {
        ...this.state.data,
        note: value,
      },
    });
  };

  changeCropYear(cropYear) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        cropYearId: cropYear.value,
      }),
    });
  }

  render = () => {
    return (
      <>
        <fieldset>
          <div className="form-group">
            {this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
            this.props.loggedUser.role === USER_ROLE_STAFF ? (
              <a
                href="#"
                className="btn btn-primary panel-btn-group-item-position"
                onClick={() =>
                  this.handleShowPopup(this.newDataObject(), false)
                }
                style={{ marginBlock: "5px" }}
              >
                <i className="fa fa-upload fa-lg"></i> Upload
              </a>
            ) : (
              ""
            )}
            <Modal
              show={this.state.isShow}
              onHide={this.handleClosePopup}
              animation={false}
              backdropClassName="modal-backdrop foo-modal-backdrop in"
              centered
            >
              <Form onSubmit={this.handleSave} id="fromPopup">
                <Modal.Header>
                  <Modal.Title>
                    {this.state.data._id === ""
                      ? "Upload Contract"
                      : this.state.readOnly
                      ? "Contract Info"
                      : "Edit Contract"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.data ? (
                    <>
                      {this.state.data._id === "" ? (
                        <div className="form-group">
                          <label
                            className="col-md-3 control-label mt-xs pt-none"
                            htmlFor="photo"
                          >
                            Upload File <span className="required">(*)</span>
                          </label>
                          <div className="col-md-9">
                            <div
                              className="fileupload fileupload-new"
                              data-provides="fileupload"
                            >
                              <div className="input-append">
                                <div
                                  className="uneditable-input"
                                  style={{ width: "73%" }}
                                >
                                  <i className="fa fa-file fileupload-exists"></i>
                                  <span className="fileupload-preview"></span>
                                </div>
                                <span className="btn btn-default btn-file m-none mt-sm-ipad">
                                  <span
                                    className="fileupload-exists"
                                    title="Change"
                                  >
                                    <i
                                      className="fa fa-upload fa-lg"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="fileupload-new">
                                    <i
                                      className="fa fa-upload fa-lg"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    autoFocus={true}
                                    type="file"
                                    name="file"
                                    accept="application/pdf"
                                    onChange={this.handleFileChange}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="note"
                        >
                          Crop Year
                          {this.state.readOnly ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              <span className="required">(*)</span>
                            </>
                          )}
                        </label>
                        {this.state.readOnly ? (
                          <label
                            className="col-md-9 control-label"
                            style={{ wordBreak: "break-all" }}
                          >
                            {this.state.data.cropYearName}
                          </label>
                        ) : (
                          <div className="col-md-9">
                            <Select
                              onRef={(ref) => (this.cropYearId = ref)}
                              name="cropYearId"
                              onChange={this.changeCropYear}
                              placeholder={"Select crop year"}
                              value={this.state.cropYears.filter(
                                (x) => x.value === this.state.data.cropYearId
                              )}
                              options={this.state.cropYears}
                              isDisabled={this.state.readOnly}
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          className="col-md-3 control-label"
                          htmlFor="note"
                        >
                          Note
                        </label>
                        {this.state.readOnly ? (
                          <label
                            className="col-md-9 control-label"
                            style={{ wordBreak: "break-all" }}
                          >
                            {this.state.data.note}
                          </label>
                        ) : (
                          <div className="col-md-9">
                            <TextareaAutosize
                              type="text"
                              name="note"
                              maxRows={5}
                              minRows={3}
                              data-autoresize
                              className="form-control textarea-crop-year"
                              value={this.state.data.note}
                              onChange={this.handleChangeTextFieldForm}
                              maxLength={1000}
                            />
                          </div>
                        )}
                      </div>
                      {this.state.data._id !== "" ? (
                        <>
                          <div className="form-group">
                            <label className="col-md-3 control-label">
                              File Size
                            </label>
                            <label className="col-md-9 control-label">
                              {this.state.data.fileSize}
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="col-md-3 control-label">
                              File Name
                            </label>
                            <label
                              className="col-md-9 control-label"
                              style={{ wordBreak: "break-all" }}
                            >
                              <a
                                href={`${GlobalConfig.REACT_APP_API_URL}${this.state.data.filePath}`}
                                target="_blank"
                              >
                                {this.state.data.name}
                              </a>
                            </label>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Form.Group as={Row}>
                      <Form.Label column md="12">
                        Selected contract no longer exists. Please refresh and
                        try again.
                      </Form.Label>
                    </Form.Group>
                  )}
                  <div className="col-md-9 col-md-offset-3">
                    {!!this.state.errorMessage && (
                      <>
                        <Form.Label
                          column
                          md="12"
                          style={{ color: "red", paddingLeft: "0px" }}
                        >
                          {this.state.errorMessage}
                        </Form.Label>
                      </>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer-test-field">
                  {this.state.data &&
                  (this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
                    this.props.loggedUser.role === USER_ROLE_STAFF) ? (
                    <Button variant="success" type="submit">
                      <span className="fa fa-save fa-lg"></span> Save
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button variant="warning" onClick={this.handleClosePopup}>
                    <span className="fa fa-times fa-lg"></span> Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
            <DataTable
              highlightOnHover
              striped
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={false}
              noDataComponent="There is no contract"
            />
          </div>
        </fieldset>
      </>
    );
  };
}
export default App;
