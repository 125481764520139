import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();
var DateFormat = require("dateformat");

const defaultOptions = [
  { _id: "Active", name: "Active" },
  { _id: "Inactive,Not Activate", name: "Inactive" },
];

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}`;
    this.state.urlapidelete = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}`;
    this.state.urllist = process.env.REACT_APP_URL_PROCESSOR_LIST;
    this.state.urladd = process.env.REACT_APP_URL_PROCESSOR_ADD;
    this.state.title = "Processor";
    this.state.confirmDeleteMessage =
      "Related content of processor to be permanent removed. Are you sure to delete selected processor?";

    this.state.pageName = "Processor";
    this.state.filterStatus = "";
    this.state.filterProcessorCompanyName = "";
    this.state.filterContactName = "";
    this.state.filterEmail = "";

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.filterItems = this.filterItems.bind(this);
  }

  filterItems = async () => {
    const {
      filterProcessorCompanyName,
      filterStatus,
      filterContactName,
      filterEmail,
    } = this.state;

    const urlapi = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}?status=${filterStatus}&email=${filterEmail}&processorCompanyName=${filterProcessorCompanyName}&contactName=${filterContactName}`;

    this.setState({ urlapi }, () => this.getItems());
  };

  handleReset = () => {
    this.statusControl.reset();
    this.setState(
      {
        filterStatus: "",
        filterProcessorCompanyName: "",
        filterContactName: "",
        filterEmail: "",
      },
      () => this.filterItems()
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  defaultButtons = () => {
    return (
      <a href={this.state.urladd} className="btn btn-primary panel-btn-group-item-position">
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterProcessorCompanyName"
                placeholder="Filter by processor company name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterProcessorCompanyName}
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <FilterMultiSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.statusControl = ref)}
                placeholder="Filter by status"
                label="Select All"
                controlId="filterStatus"
                isUseDefaultData
                defaultData={defaultOptions}
              />
            </div>

            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterContactName"
                placeholder="Filter by contact name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterContactName}
              />
            </div>

            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterEmail"
                placeholder="Filter by email"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterEmail}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Processor Company Name",
        selector: "companyName",
        sortable: true,
        cell: (row) => {
          return loggedUser.referenceUserId === row._id ? (
            <a
              href={process.env.REACT_APP_URL_MY_PROFILE}
              title={row.companyName}
            >
              {row.companyName}
            </a>
          ) : (
            <a
              href={this.state.urllist + "/" + row._id}
              title={row.companyName}
            >
              {row.companyName}
            </a>
          );
        },
      },
      {
        name: "Contact Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        left: true,
      },
      {
        name: "Certification Prefix",
        selector: "certificatePrefix",
        center: true,
      },
      {
        name: "Certification Seed No",
        selector: "certificateSeed",
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return this.formatStatus(row);
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        width: "175px",
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT");
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <>
            {loggedUser.referenceUserId === row._id ? (
              <a
                href={process.env.REACT_APP_URL_MY_PROFILE}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            ) : (
              <a
                href={this.state.urllist + "/" + row._id}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            {loggedUser.referenceUserId === row._id ? (
              ""
            ) : (
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };

  formatStatus = (row) => {
    if (row.status == "Active") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "75px",
          }}
        >
          {row.status}
        </span>
      );
    }
    if (row.status == "Inactive" || row.status == "Not Activate") {
      return (
        <span
          className="label label-default"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "75px",
          }}
        >
          Inactive
        </span>
      );
    }
  };
}
export default App;
