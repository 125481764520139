import axios from "axios";

export const queryData = async (apiUrl) => {
  try {
    let res = await axios({
      url: apiUrl,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      if (res.data.result !== "OK") {
        console.log(res.message);
        return undefined;
      }
      return res.data.data;
    }
    console.log(`There is error while calling queryData, res.status is ${res.status}.`);
    return undefined;
  } catch (e) {
    console.log(e.message);
  }
};

export const queryDataExt = async (apiUrl, whereClause, selectFields, sortFields) => {
  try {
    let res = await axios({
      url: apiUrl,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        whereClause: whereClause,
        selectFields: selectFields,
        sortFields: sortFields
      }
    });
    if (res.status === 200) {
      if (res.data.result !== "OK") {
        console.log(res.message);
        return undefined;
      }
      return res.data.data;
    }
    console.log(`There is error while calling queryDataExt, res.status is ${res.status}.`);
    return undefined;
  } catch (e) {
    console.log(e.message);
  }
};