import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import DateFormat from "../Utilities/DateFormat";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import moment from "moment";
import CommonUtilities from "../Utilities/Common";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import SelectTiers from "../Tier/SelectTiers";

var DateFormatFormat = require("dateformat");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const startDate = new Date();
    const endDate = new Date();
    const nextYear = endDate.setFullYear(endDate.getFullYear() + 1);
    const start = moment(startDate).format("MM/DD/YYYY");
    const end = moment(nextYear).format("MM/DD/YYYY");

    this.state.totalMonthly = 0;
    this.state.numberOfTestsDeliveries = 0;
    this.state.isExportToPdf = false;
    this.state.isAllowExportApi = false;
    this.state.isAllowImportApi = false;
    this.state.isAllowGrowerPayment = false;
    this.state.isAllowDynamicTest = false;
    this.state.tierId = "";
    this.state.popupAction = "";
    this.state.selectedSubscriptionId = "";
    this.state.validFrom = start;
    this.state.validTo = end;
    this.state.billStatus = "";
    this.state.show = false;
    this.state.errorMessage = "";

    this.state.pluraltitle = "Subscription";
    this.state.title = "Subscription";
    this.state.urlapi =
      loggedUser.role === "Processors"
        ? GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "byprocessor/" +
        loggedUser.processorId
        : GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL;
    this.state.urllist = process.env.REACT_APP_URL_SUBSCRIPTION_LIST;
    this.state.pagination = loggedUser.role !== "Processors";

    this.state.filterSubscriptionStatus = "";
    this.state.filterStartDate = "";
    this.state.filterEndDate = "";

    this.state.countExpired = 0;
    this.state.countInUse = 0;
    this.state.countPending = 0;

    this.state.startDate = "";
    this.state.endDate = "";

    this.state.pageName = "Subscription";

    // Custom heading component
    this.state.useFilter = loggedUser.role === "Administrators" ? true : false;

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeDatePopup = this.handleChangeDatePopup.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleSubmitPopupPaid = this.handleSubmitPopupPaid.bind(this);
    this.handleSubmitPopupUnpaid = this.handleSubmitPopupUnpaid.bind(this);
    this.handleChangeTier = this.handleChangeTier.bind(this);
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      // {
      //   name: "Request Type",
      //   selector: "type",
      //   sortable: true,
      // },
      {
        name: "Tier",
        selector: "tierName",
        sortable: true,
        cell: (row) =>
          loggedUser.role === "Administrators" ? (
            <a
              href={process.env.REACT_APP_URL_TIER_LIST + "/" + row.tierId}
              title="Click here to view tier information"
              target="_blank"
            >
              {row.tierName}
            </a>
          ) : (
            <a
              href="#"
              className="on-default edit-row"
              data-id={row._id}
              title="Click here to view detail of tier."
              data-action="ViewTier"
              data-numberoftestsdeliveries={row.numberOfTestsDeliveries}
              data-totalmonthly={row.totalMonthly}
              data-isexporttopdf={row.isExportToPdf}
              data-isallowexportapi={row.isAllowExportApi}
              data-isallowimportapi={row.isAllowImportApi}
              data-isallowgrowerpayment={row.isAllowGrowerPayment}
              data-isallowdynamictest={row.isAllowDynamicTest}
              data-tierid={row.tierId}
              onClick={this.handleShow.bind(this)}
            >
              {row.tierName}
            </a>
          ),
      },
      {
        omit: loggedUser.role !== "Administrators",
        name: "Processor Name",
        selector: "processorName",
        sortable: true,
        cell: (row) => (
          <a
            href={
              process.env.REACT_APP_URL_PROCESSOR_LIST + "/" + row.processorId
            }
            title="Click here to view processor information"
            target="_blank"
          >
            {row.processorName}
          </a>
        ),
      },
      {
        omit: loggedUser.role !== "Administrators",
        name: "Contact Name",
        selector: "processorContactName",
        left: true,
        sortable: true,
      },
      {
        name: "Subscribed Date",
        selector: "subscribedDate",
        center: true,
        cell: (row) => {
          return row.subscribedDate ? (
            <DateFormat date={row.subscribedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Valid From",
        selector: "validFrom",
        center: true,
        cell: (row) => {
          return row.validFrom ? (
            <DateFormat date={row.validFrom} format="MM/DD/YYYY"></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Valid To",
        selector: "validTo",
        center: true,
        cell: (row) => {
          return row.validTo ? (
            <DateFormat date={row.validTo} format="MM/DD/YYYY"></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Status",
        selector: "subscriptionStatus",
        center: true,
        sortable: true,
        cell: (row) => {
          return this.formatSubscriptionStatus(row);
        },
      },
      {
        omit: loggedUser.role !== "Administrators",
        name: "",
        sortable: true,
        cell: (row) => {
          return row.subscriptionStatus !== "Pending" ? (
            <>
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row._id
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="#"
                className="on-default edit-row"
                data-id={row._id}
                title="Click here to renew license."
                data-action="Renew"
                data-row={row}
                data-validfrom={row.validFrom}
                data-validto={row.validTo}
                onClick={this.handleShow.bind(this)}
              >
                Renew
              </a>
              &nbsp;|&nbsp;
              <a
                href="#"
                className="on-default edit-row"
                data-id={row._id}
                title="Click here to change tier license."
                data-action="Change Tier"
                data-row={row}
                data-validfrom={row.validFrom}
                data-validto={row.validTo}
                onClick={this.handleShow.bind(this)}
              >
                Change Tier
              </a>
            </>
          ) : (
            <>
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row._id
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              {/* <a
                href="#"
                className="on-default edit-row"
                data-id={row._id}
                data-action="Approve"
                data-row={row}
                onClick={this.handleShow.bind(this)}
              >
                Approve
              </a>
              &nbsp; | &nbsp; */}
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            </>
          );
        },
      },
    ];
  };

  formatSubscriptionStatus = (row) => {
    if (row.subscriptionStatus === "In Use") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.subscriptionStatus}
        </span>
      );
    }
    if (row.subscriptionStatus === "Expired") {
      return (
        <span
          className="label label-default"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.subscriptionStatus}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.subscriptionStatus}
      </span>
    );
  };

  doSomethingAfterGetItems = async () => {
    this.updateCount();
  };

  doSomethingAfterDeletedItem = async () => {
    this.updateCount();
  };

  updateCount = async () => {
    let countExpired = 0;
    let countPending = 0;
    let countInUse = 0;

    if (this.state.items) {
      this.state.items.filter((u) => {
        if (u.subscriptionStatus === "Pending") {
          countPending++;
        }
      });
      this.setState({ countPending: countPending });

      this.state.items.filter((u) => {
        if (u.subscriptionStatus === "Expired") {
          countExpired++;
        }
      });
      this.setState({ countExpired: countExpired });

      this.state.items.filter((u) => {
        if (u.subscriptionStatus === "In Use") {
          countInUse++;
        }
      });
      this.setState({ countInUse: countInUse });
    } else {
      this.setState({
        countExpired: 0,
        countPending: 0,
        countInUse: 0,
      });
    }
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // check date < 10 to add leading zeros
    const getDate = date.getDate().toString();
    const dateCheck = getDate[1] ? getDate : "0" + getDate[0];

    // check month < 10 to add leading zeros
    const getMonth = date.getMonth() + 1;
    const monthString = getMonth.toString();
    const monthCheck = monthString[1] ? monthString : "0" + monthString[0];

    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return monthCheck + "/" + dateCheck + "/" + date.getFullYear();
    }
  };

  handleStartDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterStartDate: getDate,
        startDate: date,
      });
    } else {
      this.setState({
        filterStartDate: "",
        startDate: "",
      });
    }
  };

  handleEndDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterEndDate: getDate,
        endDate: date,
      });
    } else {
      this.setState({
        filterEndDate: "",
        endDate: "",
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      return;
    }
  }

  filterItems = () => {
    try {
      if (this.state.originalItems) {
        const startDate = this.state.filterStartDate;
        const endDate = this.state.filterEndDate;
        const filterSubscriptionStatus = this.state.filterSubscriptionStatus;

        if (
          (startDate == null || startDate === undefined || startDate === "") &&
          (endDate == null || endDate === undefined || endDate === "") &&
          (filterSubscriptionStatus == null ||
            filterSubscriptionStatus === undefined ||
            filterSubscriptionStatus === "")
        ) {
          this.setState({ items: this.state.originalItems }, function () {
            this.updateCount();
          });
        } else {
          const valueStart =
            startDate === ""
              ? 0
              : parseInt(DateFormatFormat(startDate, "yyyymmdd"));
          const valueEnd =
            endDate === ""
              ? Number.MAX_VALUE
              : parseInt(DateFormatFormat(endDate, "yyyymmdd"));
          const newItems = [];
          this.state.originalItems.filter((memItem) => {
            const valueCreatedDate = parseInt(
              DateFormatFormat(memItem.subscribedDate, "yyyymmdd")
            );
            if (
              valueCreatedDate >= valueStart &&
              valueCreatedDate <= valueEnd &&
              (filterSubscriptionStatus == null ||
                filterSubscriptionStatus === undefined ||
                filterSubscriptionStatus === "" ||
                filterSubscriptionStatus.indexOf(memItem.subscriptionStatus) !==
                -1)
            ) {
              newItems.push(memItem);
              return memItem;
            }
          });
          this.setState({ items: newItems }, function () {
            this.updateCount();
          });
        }
      }
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleReset = () => {
    this.setState(
      {
        filterSubscriptionStatus: "",
        filterStartDate: "",
        startDate: "",
        filterEndDate: "",
        endDate: "",
      },
      () => this.filterItems()
    );
  };

  renderSearch = () => {
    return loggedUser.role !== "Administrators" ? (
      ""
    ) : (
      <>
        <div className="col-xs-12 col-md-4 col-lg-3 inputDateTime select-render-search mb-sm">
          <select
            placeholder="-- Select Status --"
            name="filterSubscriptionStatus"
            className="form-control selectSubscription"
            value={this.state.filterSubscriptionStatus}
            onChange={this.handleChange}
          >
            <option value="">All Status</option>
            <option value="Pending">Pending</option>
            <option value="Expired">Expired</option>
            <option value="In Use">In Use</option>
          </select>
        </div>

        <div className="col-xs-12 col-md-8 col-lg-3 select-render-search mb-sm">
          <div className="inputDateTimeContainer">
            <div className="input-group inputDateTime">
              <span className="input-group-addon">
                <i className="fa fa-calendar"></i>
              </span>
              <DatePicker
                className="form-control inputRenderSearch"
                placeholderText="Start date"
                selected={this.state.startDate}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onBlur={this.handleOnBlur}
              />
            </div>
            <div className="input-group">
              <span className="input-group-addon">to</span>
              <DatePicker
                className="form-control inputRenderSearch"
                placeholderText="End date"
                selected={this.state.endDate}
                onChange={this.handleEndDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                onBlur={this.handleOnBlur}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-md-12 col-lg-3 select-render-search mb-sm">
          <div
            className="form-group labelStatusBills"
            style={{ marginRight: "10px" }}
          >
            {this.state.countExpired === 0 &&
              this.state.countInUse === 0 &&
              this.state.countPending === 0 ? (
              ""
            ) : (
              <label className="control-label">
                <strong>Status: </strong>
              </label>
            )}
            {this.state.countExpired <= 0 ? (
              ""
            ) : (
              <span
                className="label label-default"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {this.state.countExpired} Expired
              </span>
            )}{" "}
            {this.state.countInUse <= 0 ? (
              ""
            ) : (
              <span
                className="label label-success"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {this.state.countInUse} In Use
              </span>
            )}{" "}
            {this.state.countPending <= 0 ? (
              ""
            ) : (
              <span
                className="label label-warning"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {this.state.countPending} Pending
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  handleChangeSelectTier = (name, value) => {
    this.setState({ [name]: value });
  };

  renderPopupBodySwitch = () => {
    switch (this.state.popupAction) {
      case "Approve":
      case "Renew":
        return (
          <>
            {" "}
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Valid From
              </Form.Label>
              <Col sm="10">
                <div
                  className="input-daterange input-group"
                  data-plugin-datepicker
                >
                  <span className="input-group-addon">
                    <i className="fa fa-calendar"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="validFrom"
                    defaultValue={this.state.validFrom}
                    onChange={this.handleChangeDatePopup}
                    onFocus={this.handleChangeDatePopup}
                    maxLength={10}
                    readOnly={true}
                  ></input>
                  <span className="input-group-addon"> To </span>
                  <input
                    type="text"
                    className="form-control"
                    name="validTo"
                    defaultValue={this.state.validTo}
                    onChange={this.handleChangeDatePopup}
                    onFocus={this.handleChangeDatePopup}
                    maxLength={10}
                    readOnly={true}
                  ></input>
                </div>
              </Col>
            </Form.Group>
            <hr className="dotted short"></hr>
            <div className="col-sm-12">
              <div className="mb-lg">
                <span className="icon mr-sm">
                  <i
                    className="fa fa-question-circle fa-lg"
                    aria-hidden="true"
                  ></i>{" "}
                </span>{" "}
                Allow {this.state.popupAction.toLowerCase()} subscription in 1
                year.
              </div>
              <div className="mb-lg">
                <strong>
                  {this.state.popupAction} {"& Mark Paid"}
                </strong>
                : Allow {this.state.popupAction.toLowerCase()} and update
                payment status is paid in 1 year.
              </div>
              <div className="mb-lg">
                <strong>{this.state.popupAction}</strong>: Allow{" "}
                {this.state.popupAction.toLowerCase()} and leave payment status
                is unpaid in 1 year.
              </div>
            </div>
          </>
        );
      case "ViewTier":
        return (
          <>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Tests (Deliveries)
              </label>
              <div className="col-sm-4">
                <span>
                  <strong>{this.state.numberOfTestsDeliveries}</strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Total Monthly ($)
              </label>
              <div className="col-sm-4">
                <span>
                  <strong>
                    {"$" +
                      CommonUtilities.numberWithCommas(
                        parseFloat(
                          this.state.totalMonthly == null ||
                            this.state.totalMonthly === undefined ||
                            isNaN(this.state.totalMonthly)
                            ? 0
                            : this.state.totalMonthly
                        ).toFixed(1)
                      )}
                  </strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Allow Export to PDF
              </label>
              <div className="col-sm-4">
                <span>
                  <strong>{this.state.isExportToPdf ? "Yes" : "No"}</strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">Allow Export API</label>
              <div className="col-sm-4">
                <span>
                  <strong>
                    {this.state.isAllowExportApi == `true` ? "Yes" : "No"}
                  </strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">Allow Import API</label>
              <div className="col-sm-4">
                <span>
                  <strong>
                    {this.state.isAllowImportApi == `true` ? "Yes" : "No"}
                  </strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Allow Grower Payment
              </label>
              <div className="col-sm-4">
                <span>
                  <strong>
                    {this.state.isAllowGrowerPayment ? "Yes" : "No"}
                  </strong>
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Allow Dynamic Test
              </label>
              <div className="col-sm-4">
                <span>
                  <strong>
                    {this.state.isAllowDynamicTest == `true` ? "Yes" : "No"}
                  </strong>
                </span>
              </div>
            </div>
          </>
        );
      case "Change Tier":
        return (
          <>
            {" "}
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                New Tier
              </Form.Label>
              <Col sm="10">
                <SelectTiers
                  required={true}
                  onRef={(ref) => (this.tierId = ref)}
                  name="tierId"
                  value={this.state.tierId}
                  selectedIds={this.state.tierId}
                  handleChangeSelectGrower={this.handleChangeSelectTier}
                />
              </Col>
            </Form.Group>
          </>
        );
      default:
        return "";
    }
  };

  renderPopupFooterSwitch = () => {
    switch (this.state.popupAction) {
      case "Approve":
      case "Renew":
        return (
          <>
            <button
              className="btn btn-success"
              data-status="Paid"
              value="Paid"
              onClick={this.handleSubmitPopupPaid.bind(this)}
              type="button"
              title={
                "Choose this action to " +
                this.state.popupAction.toLowerCase() +
                " and update payment status is paid in next 12 months."
              }
            >
              <span className="fa fa-fa fa-chevron-down">
                {" "}
                {this.state.popupAction} {"& Mark Paid"}
              </span>
            </button>
            <button
              className="btn btn-primary"
              data-status="Unpaid"
              value="Unpaid"
              onClick={this.handleSubmitPopupUnpaid.bind(this)}
              type="button"
              title={
                "Choose this action to " +
                this.state.popupAction.toLowerCase() +
                " and payment status to be unpaid in next 12 months."
              }
            >
              <span className="fa fa-fa fa-chevron-down">
                {" "}
                {this.state.popupAction}
              </span>
            </button>
          </>
        );

      case "Change Tier":
        return (
          <>
            <Button
              variant="primary"
              onClick={this.handleChangeTier.bind(this)}
            >
              <span className="fa fa-save fa-lg"></span> Submit
            </Button>
          </>
        );
      default:
        return "";
    }
  };

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form>
          <Modal.Header closeButton={false}>
            <Modal.Title>
              {this.state.popupAction === "Approve" ||
                this.state.popupAction === "Renew" ||
                this.state.popupAction === "Change Tier"
                ? this.state.popupAction + " Subscription"
                : "Tier Information"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderPopupBodySwitch()}
            <Form.Group as={Row}>
              <Form.Label column sm="2"></Form.Label>
              {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                ""
              ) : (
                <Form.Label column sm="10" style={{ color: "red" }}>
                  {this.state.errorMessage}
                </Form.Label>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {this.renderPopupFooterSwitch()}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  handleShow = (e) => {
    const action = e.currentTarget.dataset.action;
    this.setState({
      show: true,
      errorMessage: "",
      selectedSubscriptionId: e.currentTarget.dataset.id,
      popupAction: action,
    });
    switch (action) {
      case "ViewTier":
        this.setState({
          numberOfTestsDeliveries:
            e.currentTarget.dataset.numberoftestsdeliveries,
          totalMonthly: e.currentTarget.dataset.totalmonthly,
          isExportToPdf: e.currentTarget.dataset.isexporttopdf,
          isAllowExportApi: e.currentTarget.dataset.isallowexportapi,
          isAllowImportApi: e.currentTarget.dataset.isallowimportapi,
          isAllowGrowerPayment: e.currentTarget.dataset.isallowgrowerpayment,
          isAllowDynamicTest: e.currentTarget.dataset.isallowdynamictest,
          tierId: e.currentTarget.dataset.tierid,
        });
        break;
      case "Renew":
        const startDate = new Date(e.currentTarget.dataset.validto);
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date(startDate);
        const nextYear = endDate.setFullYear(endDate.getFullYear() + 1);
        const start = moment(startDate).format("MM/DD/YYYY");
        const end = moment(nextYear).format("MM/DD/YYYY");

        this.setState({
          validFrom: start,
          validTo: end,
        });
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleChangeDatePopup = (event) => {
    if (event.target) {
      const id = event.target.name;
      this.setState({
        [id]: event.target.value,
      });
    }
  };

  handleSubmitPopupPaid(event) {
    event.preventDefault();
    this.setState(
      {
        errorMessage: "",
        billStatus: "Paid",
      },
      function () {
        if (this.state.popupAction === "Approve") {
          this.handleApprove();
        } else if (this.state.popupAction === "Renew") {
          this.handleRenew();
        }
      }
    );
  }

  handleSubmitPopupUnpaid(event) {
    event.preventDefault();
    this.setState(
      {
        errorMessage: "",
        billStatus: "Unpaid",
      },
      function () {
        if (this.state.popupAction === "Approve") {
          this.handleApprove();
        } else if (this.state.popupAction === "Renew") {
          this.handleRenew();
        }
      }
    );
  }

  handleChangeTier(event) {
    event.preventDefault();
    this.setState(
      {
        errorMessage: "",
      },
      function () {
        this.submitChangeTier();
      }
    );
  }

  async handleApprove() {
    const validFrom = this.state.validFrom;
    const validTo = this.state.validTo;
    if (
      validFrom == null ||
      validFrom === undefined ||
      validFrom === "" ||
      validTo == null ||
      validTo === undefined ||
      validTo === ""
    ) {
      this.setState({
        errorMessage: "Please enter valid dates then try again.",
      });
    } else {
      const selectedSubscriptionId = this.state.selectedSubscriptionId;
      if (
        selectedSubscriptionId == null ||
        selectedSubscriptionId === undefined ||
        selectedSubscriptionId === ""
      ) {
        this.setState({
          errorMessage:
            "Selected subscription may invalid. Please refresh and try again.",
        });
        return;
      }
      if (!moment(validTo).isValid() || !moment(validTo).isValid()) {
        this.setState({
          errorMessage: "Enter valid date. Format MM/dd/yyyy",
        });
        return;
      }
      if (moment(validTo).isSameOrBefore(validFrom)) {
        this.setState({
          errorMessage: "Valid To must be greater than Valid From.",
        });
        return;
      }

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          billStatus: this.state.billStatus,
          subscriptionStatus: "Approved",
          validFrom: validFrom,
          validTo: validTo,
          modifiedUserId: loggedUser._id,
        }),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "approve/" +
        this.state.selectedSubscriptionId,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.sendActiveEmailToProcessor(resultObject.data);

            window.showAlert("", "Subscription is approved successfully.", "");
            that.getItems();
            that.setState({ show: false });
          } else {
            that.setState({ errorMessage: resultObject.message });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  handleRenew = async () => {
    const validFrom = this.state.validFrom;
    const validTo = this.state.validTo;
    if (
      validFrom === null ||
      validFrom === undefined ||
      validFrom === "" ||
      validTo == null ||
      validTo === undefined ||
      validTo === ""
    ) {
      this.setState({
        errorMessage: "Please enter valid dates then try again.",
      });
    } else {
      const selectedSubscriptionId = this.state.selectedSubscriptionId;
      if (
        selectedSubscriptionId == null ||
        selectedSubscriptionId === undefined ||
        selectedSubscriptionId === ""
      ) {
        this.setState({
          errorMessage:
            "Selected subscription may invalid. Please refresh and try again.",
        });
        return;
      }
      if (!moment(validTo).isValid() || !moment(validTo).isValid()) {
        this.setState({
          errorMessage: "Enter valid date. Format MM/dd/yyyy",
        });
        return;
      }
      if (moment(validTo).isSameOrBefore(validFrom)) {
        this.setState({
          errorMessage: "Valid To must be greater than Valid From.",
        });
        return;
      }
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          billStatus: this.state.billStatus,
          subscriptionStatus: "Approved",
          validFrom: validFrom,
          validTo: validTo,
          modifiedUserId: loggedUser._id,
        }),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "renew/" +
        this.state.selectedSubscriptionId,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.sendRenewEmailToProcessor(resultObject.data);

            window.showAlert("", "Subscription is renew successfully.", "");
            that.getItems();
            that.setState({ show: false });
          } else {
            that.setState({ errorMessage: resultObject.message });
          }
        })
        .catch(function (error) {
          that.setState({ errorMessage: error });
        });
    }
  };

  submitChangeTier = async () => {
    const tierId = this.state.tierId;
    if (tierId === null || tierId === undefined || tierId === "") {
      this.setState({
        errorMessage: "Please select tier.",
      });
    } else {
      const selectedSubscriptionId = this.state.selectedSubscriptionId;
      if (
        selectedSubscriptionId == null ||
        selectedSubscriptionId === undefined ||
        selectedSubscriptionId === ""
      ) {
        this.setState({
          errorMessage:
            "Selected subscription is invalid. Please refresh and try again.",
        });
        return;
      }
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tierId: tierId,
          modifiedUserId: loggedUser._id,
        }),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "changetier/" +
        this.state.selectedSubscriptionId,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.sendRenewEmailToProcessor(resultObject.data);

            window.showAlert("", "Subscription is updated successfully.", "");
            that.getItems();
            that.setState({ show: false });
          } else {
            that.setState({ errorMessage: resultObject.message });
          }
        })
        .catch(function (error) {
          that.setState({ errorMessage: error });
        });
    }
  };

  sendActiveEmailToProcessor = async (data) => {
    const that = this;
    //Send credential access to created user's email
    fetch(
      GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL +
      "/name/EMAIL_TEMPLATE_NEW_PROCESSOR"
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        var bodyEmailTemplate = resultObject.data.message;
        bodyEmailTemplate = bodyEmailTemplate.replace("#email", data.email);
        bodyEmailTemplate = bodyEmailTemplate.replace("#name", data.name);
        bodyEmailTemplate = bodyEmailTemplate.replace(
          "#password",
          data.password
        );
        bodyEmailTemplate = bodyEmailTemplate.replace(
          "#url",
          process.env.REACT_APP_BASE_URL
        );

        //send mail credential
        const requestMailOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
            to: data.email,
            cc: resultObject.data.cc,
            bcc: resultObject.data.bcc,
            subject: resultObject.data.subject,
            body: bodyEmailTemplate,
          }),
        };
        fetch(GlobalConfig.REACT_APP_API_MAIL_URL, requestMailOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObjectSendMail) { })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  sendRenewEmailToProcessor = async (data) => {
    //Send credential access to created user's email
    fetch(
      GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL +
      "/name/EMAIL_TEMPLATE_RENEW_SUBSCRIPTION"
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        var bodyEmailTemplate = resultObject.data.message;
        bodyEmailTemplate = bodyEmailTemplate.replace("#email", data.email);
        bodyEmailTemplate = bodyEmailTemplate.replace("#name", data.name);
        bodyEmailTemplate = bodyEmailTemplate.replace(
          "#url",
          process.env.REACT_APP_BASE_URL
        );

        //send mail credential
        const requestMailOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
            to: data.email,
            cc: resultObject.data.cc,
            bcc: resultObject.data.bcc,
            subject: resultObject.data.subject,
            body: bodyEmailTemplate,
          }),
        };

        fetch(GlobalConfig.REACT_APP_API_MAIL_URL, requestMailOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObjectSendMail) { });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}
export default App;
