import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
const { sortBy } = require("lodash");

class App extends React.Component {
  constructor(props) {
    super();
    let labelValueInshell = ``;
    let labelValueShell = `Shelled`;
    if (props.testType.isDynamic) {
      if (props.testType.xpool === true) {
        labelValueInshell = "Regular Price";
        labelValueShell = "Alternate Price";
      } else {
        labelValueInshell = "Price";
      }
    } else {
      labelValueInshell = "Inshell";
    }
    this.state = {
      items: this.composeData(props),
      labelValueInshell: labelValueInshell,
      labelValueShell: labelValueShell,
    };
  }

  composeData = (props) => {
    let viewData = [];
    if (
      props.data.data &&
      props.data.data !== ``
    ) {
      viewData = JSON.parse(props.data.data);
      if (viewData && viewData.length > 0) {
        if (props.testType.varietyOptional === true) {
          viewData = sortBy(viewData, [`primaryPrice`], ["asc"]);
        } else {
          viewData = sortBy(viewData, [`varietyName`, `primaryPrice`], ["asc", `asc`]);
        }
      }
    }

    return viewData;
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.props.testType.varietyOptional,
      },
      {
        name: "Primary Price",
        selector: "primaryPrice",
        sortable: true,
        center: true,
        omit: this.props.testType.isDynamic,
      },
      {
        name: this.state.labelValueInshell,
        selector: "valueInshell",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            row.valueInshell == null ||
              row.valueInshell === undefined ||
              isNaN(row.valueInshell)
              ? 0
              : parseFloat(row.valueInshell)
          ),
        width: "160px",
      },
      {
        name: this.state.labelValueShell,
        selector: "valueShelled",
        sortable: true,
        right: true,
        cell: (row) => {
          return "$" + CommonUtilities.numberWithCommas(
            row.valueShelled == null ||
              row.valueShelled === undefined ||
              isNaN(row.valueShelled)
              ? 0
              : parseFloat(row.valueShelled)
          )
        },
        omit:
          this.props.testType.isDynamic && this.props.testType.xpool !== true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.props.testType.isDynamic,
      },
      {
        name: "Payment",
        selector: "paymentName",
        sortable: false,
        omit: !this.props.testType.basePriceByPayment,
      },
    ];
  };

  render() {
    return (
      <DataTable
        key="subTableTemplate"
        id="subTableTemplate"
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
