import React from "react";
import ListComponent from "../Base/ListComponent";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  CRON_JOB_STATUS_SCHEDULED,
  CRON_JOB_STATUS_STOPPED,
} from "../Utilities/Constant";

const RenderButton = (props) => {
  if (props.href) {
    return (
      <a
        {...props}
        title={props.title}
        href={props.href}
        style={{ minWidth: "0", border: 0 }}
        target={props.target}
        className="btn btn-sm btn-default on-default remove-row text-primary mr-none ml-none"
      >
        <i className={`fa ${props.icon} fa-lg`}></i>
      </a>
    );
  }
  return (
    <a
      {...props}
      title={props.title}
      onClick={props.onClick}
      style={{ minWidth: "0", border: 0 }}
      target={props.target}
      className="btn btn-sm btn-default on-default remove-row text-primary mr-none ml-none"
    >
      <i className={`fa ${props.icon} fa-lg`}></i>
    </a>
  );
};

class App extends ListComponent {
  constructor(props) {
    super();
    this.state.urlapi = process.env.REACT_APP_API_CRON_JOB_SETTING_URL;
    this.state.urllist = process.env.REACT_APP_URL_CRON_JOB_SETTING;
    this.state.urladd = process.env.REACT_APP_URL_CRON_JOB_SETTING + "/add";
    this.state.pageName = "Scheduled Tasks";
    this.state.title = "Scheduled Task";
    this.state.pageTitle = "Scheduled Tasks";
    this.state.pluraltitle = "Scheduled Tasks";
    this.state.labeladd = "Add";
    this.state.width = 0;
    this.state.height = 0;
    this.state.pagination = false;
    this.state.useFilter = false;
  }

  doSomthingBeforeGetItems = async () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  };

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  blockHandler(id, status) {
    this.updateScheduleHandler(id, "block", { status: status });
  }

  runHandler(id) {
    this.updateScheduleHandler(id, "run", undefined);
  }

  updateScheduleHandler(taskId, action, data) {
    const urlapi = `${this.state.urlapi}${action}/${taskId}`;
    fetch(urlapi, {
      method: action === "block" ? "PATCH" : "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: data ? JSON.stringify(data) : undefined,
    })
      .then((response) => response.json())
      .then((resultObject) => {
        if (resultObject && resultObject.result === "OK") {
          this.setState({
            alertshow: false,
          });
          if (action === "block") {
            window.showAlert("", "Task has been saved", "");
            this.updateTaskStatus(taskId, data.status);
          } else {
            window.showAlert("", "Task has been run immediately", "");
          }
        } else {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: resultObject.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      })
      .catch((exception) => {
        this.setState({
          alertshow: false,
        });
        this.setState({
          alertmsg: exception.message,
          alerttype: "error",
          alertshow: true,
        });
      });
  }

  updateTaskStatus(taskId, status) {
    const tasks = [...this.state.items];
    const index = tasks.findIndex((task) => task._id === taskId);
    if (index !== -1) {
      tasks[index].status = status;
      this.setState({
        items: tasks,
      });
    }
  }

  getNextTimeRun(setting) {
    let time = 60 * 60 * 1000;
    if (setting.typeCronJob === "Daily") {
      time = time * 24;
    }
    let from = new Date(setting.startDateTime);
    let to = new Date(
      from.setTime(from.getTime() + time * setting.stepSize * setting.timeRun)
    );
    return to;
  }

  defaultColumns = () => {
    const style = {
      paddingTop: "5px",
      paddingBottom: "7px",
      width: "100px",
      fontSize: 12,
    };
    return [
      {
        name: "Task",
        selector: "taskName",
        sortable: true,
        grow: 2,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id}>{row.taskName}</a>
        ),
      },
      {
        name: "Step Size",
        selector: "stepSize",
        sortable: false,
        center: true,
        grow: 0,
      },
      {
        name: "Type",
        selector: "typeCronJob",
        sortable: false,
        center: true,
        grow: 0,
      },
      {
        name: "Start At",
        selector: "cronStyle",
        sortable: false,
        center: true,
        wrap: true,
        cell: (row) => {
          const nextTime = this.getNextTimeRun(row);
          const next = new Date(nextTime);
          const startDateTime = new Date(row.startDateTime);
          let result = next;
          if (next < startDateTime) result = startDateTime;

          return moment(result).format("MM/DD/YYYY hh:mm A");
        },
      },
      {
        name: "Status",
        selector: "status",
        sortable: false,
        center: true,
        grow: 0,
        cell: (row) => {
          return (
            <>
              <div
                style={style}
                className={
                  row.status === CRON_JOB_STATUS_STOPPED
                    ? "label label-danger"
                    : "label label-success"
                }
              >
                {row.status}
              </div>
            </>
          );
        },
      },
    ];
  };

  actionColumnSm = {
    name: "",
    allowOverflow: true,
    center: true,
    grow: 0,
    cell: (row) => (
      <div className="btn-group">
        <a
          className="btn btn-default dropdown-toggle"
          style={{ minWidth: "0", border: 0 }}
          data-toggle="dropdown"
        >
          <i className="fa fa-ellipsis-v"></i>
        </a>
        <ul className="dropdown-menu dropdown-menu-right" role="menu">
          <li>
            {row.status === CRON_JOB_STATUS_STOPPED ? (
              <a
                name={row.status}
                onClick={() => {
                  this.blockHandler(row._id, CRON_JOB_STATUS_SCHEDULED);
                }}
              >
                Start Task
              </a>
            ) : (
              <a
                name={row.status}
                onClick={() => {
                  this.blockHandler(row._id, CRON_JOB_STATUS_STOPPED);
                }}
              >
                Stop Task
              </a>
            )}
          </li>
          {row.status !== CRON_JOB_STATUS_STOPPED ? (
            <li>
              <a
                onClick={() => {
                  this.runHandler(row._id);
                }}
              >
                Run Intermediately
              </a>
            </li>
          ) : (
            ""
          )}

          <li>
            <a
              href={`${process.env.REACT_APP_URL_CRON_JOB_HISTORY}/${row._id}`}
            >
              View History
            </a>
          </li>
          <li>
            <a href={this.state.urllist + "/" + row._id}>Edit</a>
          </li>
          <li>
            <a data-id={row._id} onClick={this.handleDelete.bind(this)}>
              Delete
            </a>
          </li>
        </ul>
      </div>
    ),
  };

  actionColumnMd = {
    name: "",
    right: true,
    wrap: true,
    show: false,
    grow: 1,
    cell: (row) => (
      <div>
        {row.status === CRON_JOB_STATUS_STOPPED ? (
          <RenderButton
            name="Running"
            title="Start"
            onClick={() => {
              this.blockHandler(row._id, CRON_JOB_STATUS_SCHEDULED);
            }}
            icon="fa-play"
          />
        ) : (
          <RenderButton
            name="Stopped"
            title="Stop"
            onClick={() => {
              this.blockHandler(row._id, CRON_JOB_STATUS_STOPPED);
            }}
            icon="fa-stop"
          />
        )}
        {row.status !== CRON_JOB_STATUS_STOPPED ? (
          <RenderButton
            title="Run immediately"
            data-id={row._id}
            onClick={() => {
              this.runHandler(row._id);
            }}
            icon="fa-refresh"
          />
        ) : (
          ""
        )}

        <RenderButton
          title="View history"
          href={`${process.env.REACT_APP_URL_CRON_JOB_HISTORY}/${row._id}`}
          icon="fa-history"
        />

        <RenderButton
          title="Edit"
          href={this.state.urllist + "/" + row._id}
          icon="fa-pencil-square-o"
        />
        <RenderButton
          data-id={row._id}
          title="Delete"
          onClick={this.handleDelete.bind(this)}
          icon="fa-trash-o"
        />
      </div>
    ),
  };

  filterItems = () => {};

  setColumns = () => {
    let columns = this.defaultColumns();
    columns.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });
    columns.push(this.actionColumnMd);
    return columns;
  };

  defaultTable = () => {
    return (
      <DataTable
        striped={this.striped()}
        pointerOnHover={true}
        id="emp"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={this.state.pagination}
        paginationPerPage={this.state.paginationPerPage}
        paginationRowsPerPageOptions={this.state.rowPerPageOption}
        conditionalRowStyles={this.conditionalRowStyles()}
        progressPending={this.state.progressPending}
        progressComponent={this.defaultProgressComponent()}
        onRowClicked={this.onRowClicked}
      />
    );
  };
}

export default App;
