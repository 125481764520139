import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { overrideLoadingStyle } from "./../Utilities/Styles";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    let loadingMessage = "";
    if (this.props.loadingMessage) {
      loadingMessage = this.props.loadingMessage;
    } else {
      const GlobalConfig = new window.globalConfig();
      loadingMessage = GlobalConfig.REACT_APP_PROCESS_LOADING_MESSAGE;
    }
    return (
      <div className="sc-hHLeRK kaLCam table table-bordered table-striped mb-none">
        <div className="sc-kgflAQ jwfoYh">
          <div className="sc-bczRLJ kSdddW rdt_Table" role="table">
            <div className="sc-bBrHrO kcvhQi">
              {this.props.hideLoadingIcon ? (
                <div style={{ padding: "24px" }}>{loadingMessage}</div>
              ) : (
                <>
                  <ClipLoader
                    loading={this.props.loading}
                    cssOverride={{
                      ...overrideLoadingStyle,
                      borderColor: "#515050 #515050 transparent",
                      width: 25,
                      height: 25,
                    }}
                  ></ClipLoader>
                  <div style={{ padding: "24px 0 24px 7px" }}>
                    {loadingMessage}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
