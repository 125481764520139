import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import Switch from "react-switch";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import CommonUtilities from "../Utilities/Common";
import SaveButton from "../Controls/SaveButton";
import ResetPassword from "../Base/ResetPassword";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Staffs",
      urllist: process.env.REACT_APP_URL_STAFF_LIST,
      urlapi: GlobalConfig.REACT_APP_API_STAFF_URL,
      data: {
        _id: props.match.params.id,
        name: "",
        contactEmail: "",
        processorId: "",
        status: "Active",
        allowNotifyImportResult: false,
        notifyImportResult: false,
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      errors: {
        email: "",
      },
      loading: parentState.loading,
    };

    this.handleNotifyImportResultChangeSwitch =
      this.handleNotifyImportResultChangeSwitch.bind(this);
  }

  handleNotifyImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        notifyImportResult: checked,
      }),
    });
  }

  doSomethingAfterGetItemById = () => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      }),
    });
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(
      this.state.data.contactEmail
    );
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return this.state.data.name;
  };

  render() {
    const title = "Edit staff - " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Edit staff - {this.getTitle()}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#overview" data-toggle="tab">
                          Staff Profile
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="overview" className="tab-pane active">
                        <form
                          className="form-horizontal form-bordered"
                          onSubmit={this.handleSubmit}
                          id="form"
                        >
                          <h4 className="mb-xlg">Staff Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Staff Name <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  name="name"
                                  autoFocus={true}
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="contactEmail"
                              >
                                Email <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="email"
                                  name="contactEmail"
                                  className="form-control"
                                  placeholder=""
                                  required={true}
                                  value={this.state.data.contactEmail}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="col-md-3 control-label">
                                Notify Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="notifyImportResult"
                                  onChange={
                                    this.handleNotifyImportResultChangeSwitch
                                  }
                                  checked={this.state.data.notifyImportResult}
                                  className="react-switch"
                                  disabled={
                                    !this.state.data.allowNotifyImportResult
                                  }
                                />
                              </div>
                            </div>
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Account</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="status"
                              >
                                {loggedUser.role == "Administrators"
                                  ? "Portal Access Status"
                                  : "Test Access Status"}
                              </label>
                              <div className="col-md-8">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>

                          <div className="panel-footer">
                            <fieldset>
                              <div className="form-group">
                                <div className="col-md-12 profile-panel-footer-wrapper">
                                  {this.state.data.status === "Active" &&
                                    this.state.data.contactEmail && (
                                      <ResetPassword
                                        email={this.state.data.contactEmail}
                                      />
                                    )}
                                  <SaveButton
                                    loading={this.state.loading}
                                    propClassName="ml-0"
                                  />
                                  <a
                                    href={this.state.urllist}
                                    title="Back to list"
                                    className="btn btn-primary ml-0"
                                  >
                                    <i className="fa fa-backward fa-lg"></i>{" "}
                                    Back
                                  </a>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
