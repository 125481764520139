const GlobalConfig = new window.globalConfig();

export const calculateFinishTime = (loggedUser) => {
  // Check if the Navigation Timing API is supported in the browser
  if (window.PerformanceObserver) {
    const perfObserver = new PerformanceObserver((observedEntries) => {
      const entries = observedEntries.getEntriesByType("navigation");
      for (const entry of entries) {
        if (entry.loadEventEnd > 0) {
          const loadTime = (entry.duration / 1000).toFixed(2);
          const { pathname, search } = window.location;
          const pageHref = pathname.concat(search);
          const startDate = new Date();
          const endDate = new Date();

          startDate.setHours(0, 0, 0);
          endDate.setHours(23, 59, 59);

          const logDataObj = {
            processorId: loggedUser.processorId,
            userId: loggedUser.referenceUserId,
            startDate: startDate,
            endDate: endDate,
            log: [
              {
                page: pageHref,
                loadTime: loadTime,
              },
            ],
          };
          logPagePerformance(logDataObj);
        }
      }
    });

    perfObserver.observe({
      type: "navigation",
      buffered: true,
    });
  } else {
    console.log("Navigation Timing API not supported in this browser.");
  }
};

const logPagePerformance = (payload) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    const url = GlobalConfig.REACT_APP_API_PAGE_PERFORMANCE_URL;

    fetch(url, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .catch(function (error) {
        console.log("Error on logging Page Performance Tracking: ", error);
      });
  } catch (err) {
    console.log(err.message);
  }
};
