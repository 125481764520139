import React, { Component } from "react";
import { connect } from "react-redux";
import { setHelpCategories } from "./../../redux/actions/helpActions";
import { setHelpCategoriesLoading } from "./../../redux/actions/loadingActions";
import ClipLoader from "react-spinners/ClipLoader";
import { overrideLoadingStyle } from "../Utilities/Styles";

const GlobalConfig = new window.globalConfig();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (
      // If user is standing on the page is help list. Let index.js (that page) invokes to get help categories instead of here.
      window.location.pathname === process.env.REACT_APP_URL_HELP_LIST ||
      window.location.pathname.includes(
        `${process.env.REACT_APP_URL_HELP_LIST}/category`
      )
    ) {
      return;
    }
    if (
      !this.props.categories ||
      !this.props.categories.length ||
      !this.props.categories.length > 0
    ) {
      this.fetchHelpCategories();
    }
  };

  fetchHelpCategories = () => {
    this.props.setHelpCategoriesLoading(true);
    fetch(GlobalConfig.REACT_APP_API_HELP_URL + "categories")
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.result === "OK" && responseData.data) {
          this.props.setHelpCategoriesLoading(false);
          this.props.setHelpCategories(responseData.data); // Save to redux
        }
      })
      .catch((exception) => {
        this.props.setHelpCategoriesLoading(false);
        console.log(exception);
      });
  };

  renderCategoryItem = (category) => {
    return (
      <li
        key={category}
        name="help-category"
        className={
          window.location.pathname.includes(encodeURI(category))
            ? "nav-active"
            : ""
        }
      >
        <a
          href={
            process.env.REACT_APP_URL_HELP_LIST +
            `/category/${encodeURI(category)}`
          }
          title={category}
        >
          <i className="fa fa-book" aria-hidden="true"></i>
          <span>{category}</span>
        </a>
      </li>
    );
  };

  navExpandHandler(e) {
    window.expandNavigation();
    const element = e.currentTarget;
    element.classList.toggle("nav-expanded");
  }

  render() {
    return (
      <li
        onClick={this.navExpandHandler}
        className={
          window.location.pathname.includes(process.env.REACT_APP_URL_HELP_LIST)
            ? "nav-parent nav-expanded"
            : "nav-parent"
        }
        name="helps"
      >
        <div
          title="Help Documentation"
          className="help-documentation-menu-item"
        >
          <a
            name="help-category-link"
            href={process.env.REACT_APP_URL_HELP_LIST}
            style={{
              textDecoration: "none",
              color:
                window.location.pathname.includes(
                  process.env.REACT_APP_URL_HELP_LIST
                ) &&
                !window.location.pathname.includes(
                  `${process.env.REACT_APP_URL_HELP_LIST}/category`
                )
                  ? "#1891E5"
                  : "",
            }}
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
            <span>Help Documentation</span>
          </a>
        </div>
        {this.props.helpCategoriesLoading && (
          <ul className="nav nav-children">
            <li>
              <a className="navigation-item-loading">
                <ClipLoader
                  cssOverride={overrideLoadingStyle}
                  loading={this.props.helpCategoriesLoading}
                />
                <div style={{ marginLeft: 7 }}>Loading...</div>
              </a>
            </li>
          </ul>
        )}
        {!this.props.helpCategoriesLoading &&
          this.props.categories.length > 0 && (
            <ul className="nav nav-children">
              {this.props.categories.map((category) =>
                this.renderCategoryItem(category.name)
              )}
            </ul>
          )}
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.helpCategories,
    helpCategoriesLoading: state.loading.helpCategoriesLoading,
  };
};

export default connect(mapStateToProps, {
  setHelpCategories,
  setHelpCategoriesLoading,
})(App);
