window.environmentConfig = {
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_ANALYTICS_API_URL: process.env.REACT_APP_ANALYTICS_API_URL,
};

exports.environmentConfig = window.environmentConfig;

window.globalConfig = function () {
  this.REACT_APP_BASE_URL = "https://pfgrading.parityfactory.com";
  this.REACT_APP_API_URL = "https://pfgradingapi.parityfactory.com:8099";
  this.REACT_APP_ANALYTICS_API_URL =
    "https://pfgradinganalytics.parityfactory.com:9003";
  this.REACT_APP_API_LABUSER_URL = `${this.REACT_APP_API_URL}/apilabuser/`;
  this.REACT_APP_API_SYSTEMCONFIG_URL = `${this.REACT_APP_API_URL}/apiconfig/`;
  this.REACT_APP_API_MAIL_URL = `${this.REACT_APP_API_URL}/apimail/`;
  this.REACT_APP_API_AUTH_URL = `${this.REACT_APP_API_URL}/apiauth`;
  this.REACT_APP_API_NUTTYPE_URL = `${this.REACT_APP_API_URL}/apinuttype/`;
  this.REACT_APP_API_VARIETY_URL = `${this.REACT_APP_API_URL}/apivariety/`;
  this.REACT_APP_API_CLASSIFICATION_URL = `${this.REACT_APP_API_URL}/apiclassification/`;
  this.REACT_APP_API_CLASSIFICATION_ITEM_URL = `${this.REACT_APP_API_URL}/apiclassificationitem/`;
  this.REACT_APP_API_ADMINISTRATOR_URL = `${this.REACT_APP_API_URL}/apiadministrator/`;
  this.REACT_APP_API_PROCESSOR_URL = `${this.REACT_APP_API_URL}/apiprocessor/`;
  this.REACT_APP_API_MEMBERSHIP_TYPE_URL = `${this.REACT_APP_API_URL}/apimembershiptype/`;
  this.REACT_APP_API_MAIL_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apimailtemplate/`;
  this.REACT_APP_API_WALNUT_TEST_URL = `${this.REACT_APP_API_URL}/apiwalnuttest/`;
  this.REACT_APP_API_HANDLER_URL = `${this.REACT_APP_API_URL}/apihandler/`;
  this.REACT_APP_API_GROWER_URL = `${this.REACT_APP_API_URL}/apigrower/`;
  this.REACT_APP_API_DASHBOARD_URL = `${this.REACT_APP_API_URL}/apidashboard/`;
  this.REACT_APP_API_INVITED_LAB_USER_URL = `${this.REACT_APP_API_URL}/apiinvitedlabuser/`;
  this.REACT_APP_API_TIER_URL = `${this.REACT_APP_API_URL}/apitier/`;
  this.REACT_APP_API_SUBSCRIPTION_URL = `${this.REACT_APP_API_URL}/apisubscription/`;
  this.REACT_APP_API_BILL_URL = `${this.REACT_APP_API_URL}/apibill/`;
  this.REACT_APP_API_CROPYEAR_URL = `${this.REACT_APP_API_URL}/apicropyear/`;
  this.REACT_APP_API_PAYMENTSETTING_URL = `${this.REACT_APP_API_URL}/apipaymentsetting/`;
  this.REACT_APP_API_PAYMENT_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apipaymenttemplate/`;
  this.REACT_APP_API_BASEPRICE_URL = `${this.REACT_APP_API_URL}/apibaseprice/`;
  this.REACT_APP_API_BASEPRICE_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apibasepricetemplate/`;
  this.REACT_APP_API_CASH_CONTRACT_PRICE_URL = `${this.REACT_APP_API_URL}/apicashcontractprice/`;
  this.REACT_APP_API_CASH_CONTRACT_PRICE_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apicashcontractpricetemplate/`;
  this.REACT_APP_API_BONUSDEDUCT_URL = `${this.REACT_APP_API_URL}/apibonusdeduct/`;
  this.REACT_APP_API_BONUSDEDUCT_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apibonusdeducttemplate/`;
  this.REACT_APP_API_TAXFEE_URL = `${this.REACT_APP_API_URL}/apitaxfee/`;
  this.REACT_APP_API_TAXFEE_TEMPLATE_URL = `${this.REACT_APP_API_URL}/apitaxfeetemplate/`;
  this.REACT_APP_API_AUDIT_URL = `${this.REACT_APP_API_URL}/apiaudit/`;
  this.REACT_APP_API_STAFF_URL = `${this.REACT_APP_API_URL}/apistaff/`;
  this.REACT_APP_API_GROWER_STATEMENT_URL = `${this.REACT_APP_API_URL}/apigrowerstatement/`;
  this.REACT_APP_API_GROWER_PAYMENT_REPORT_URL = `${this.REACT_APP_API_URL}/apigrowerpaymentreport/`;
  this.REACT_APP_API_GROWER_STATEMENT_DETAILED_URL = `${this.REACT_APP_API_URL}/apigrowerstatementdetailed/`;
  this.REACT_APP_API_ATTRIBUTE = `${this.REACT_APP_API_URL}/apiattribute/`;
  this.REACT_APP_API_USERSETTING_URL = `${this.REACT_APP_API_URL}/apiusersetting/`;
  this.REACT_APP_API_TEST_FIELD_URL = `${this.REACT_APP_API_URL}/apitestfield/`;
  this.REACT_APP_API_GROWER_STATEMENT_FIELD_URL = `${this.REACT_APP_API_URL}/apinuttypegrowerstatementfield/`;
  this.REACT_APP_API_STATICMESSAGE_URL = `${this.REACT_APP_API_URL}/apistaticmessage/`;
  this.REACT_APP_API_HELP_URL = `${this.REACT_APP_API_URL}/apihelp/`;
  this.REACT_APP_API_ATTACHMENT_URL = `${this.REACT_APP_API_URL}/apiattachment/`;
  this.REACT_APP_API_MESSAGE_URL = `${this.REACT_APP_API_URL}/apimessage/`;
  this.REACT_APP_API_NUT_TYPE_FIELD_URL = `${this.REACT_APP_API_URL}/apinuttypefield/`;
  this.REACT_APP_API_IMPORT_LOG_URL = `${this.REACT_APP_API_URL}/apiimportlog/`;
  this.REACT_APP_API_SYSTEM_LOG_URL = `${this.REACT_APP_API_URL}/apisystemlog/`;
  this.REACT_APP_API_TIME_ON_PAGE_URL = `${this.REACT_APP_ANALYTICS_API_URL}/apitimeused/`;
  this.REACT_APP_API_PAGE_PERFORMANCE_URL = `${this.REACT_APP_ANALYTICS_API_URL}/apipageperformance/`;

  this.REACT_APP_URL_MAILBOX_LIST = "/mail-box";

  this.REACT_APP_MAX_SIZE_WALNUT_TEST_IMAGE = 5000; //KB
  this.REACT_APP_LOGIN_EXPIRY = 720; //in minute (12 hours)
  this.REACT_APP_PAGE_SIZE = 20;
  this.REACT_APP_PAGE_SIZE_GROWER_STATEMENT = 20;
  this.REACT_APP_PAGE_SIZE_BASE_PRICE = 40;
  this.REACT_APP_PAGE_SIZE_ADJUSTMENTS = 20;
  this.REACT_APP_PAGE_SIZE_ADJUSTMENTS_REPORT = 200;
  this.REACT_APP_ROW_PER_PAGE_OPTION = [20, 40, 60, 80, 100];
  this.REACT_APP_HEADER_TITLE_SIZE = 30;
  this.REACT_APP_TAX_FEE_DECIMALS = 4;
  this.REACT_APP_TAX_FEE_DECIMAL_STEP = 0.0001;
  this.REACT_APP_ADJUSTMENT_DECIMALS = 4;
  this.REACT_APP_ADJUSTMENT_DECIMAL_STEP = 0.0001;
  this.REACT_APP_MSG_TAX_FEE_WARNING =
    "Please note! Changing, adding, or deleting any taxes or fees will modify those taxes and fees for the entire season.For example, deleting a tax or fee that has already been applied will result in that fee being removed even if some has been previously paid.Remaining payments, if any, will attempt to zero out those paid fees.";
  this.REACT_APP_MSG_CASH_CONTRACT_PRICE =
    'Contract Cash pricing only applies to In Shell. Contract Cash prices are a previously contracted cash price for In Shell that apply up to the amount of weight, in pounds, entered in the Amount box. Any pounds above this amount will get the default "Base Price" that has been set.<br/>Please note, Adjustments to are not applied to Contract Cash prices.';
  this.REACT_APP_MSG_EMPTY_SELECT_ROW =
    "Please select at least one row to proceed.";
  this.REACT_APP_MSG_TEST_RESULTS_FALL_OUTSIDE_PAYMENT_DATE_RANGES =
    "You have test results that fall outside the date of the last payment date. These tests will not be included in payments.";
  this.REACT_APP_MSG_CONTENT_NOT_EXIST =
    "You are trying access content does not exist longer. Please refresh and try again.";
  this.REACT_APP_MSG_EQUATION_NOTE =
    "Example: 1 – ( (Net Weight + XPool Weight) / XPool Percent)";
  this.REACT_APP_MSG_ONLY_ONE_SELECT =
    "Please select only one test to Bulk Update";
  this.REACT_APP_MSG_SWITCH_GROWER =
    "Please select a grower to switch. Once switched please re-login.";
  this.REACT_APP_MSG_SIGNUP_CONFIRM =
    "This request will register you as new processor on PF Grading. Would you really to continue?";
  this.REACT_APP_PROCESS_LOADING_MESSAGE = "Loading...";
  this.REACT_APP_CALCULATE_STATEMENT_IN_SERVER = true;
  this.REACT_APP_TRUNCATED_LENGTH_NUMBER = 90;
};
