import React from "react";
import AddComponent from "../../../Base/AddComponent";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import ReactHtmlParser from "react-html-parser";
import { getLoggedUser } from "../../../../context/auth";
import ReactJson from "react-json-view";
import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_TEXT_BOX,
  FIELD_TYPE_TEXT_SMALL,
} from "../../../Utilities/Constant";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();
class App extends AddComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    let sample = "";
    let profileUrl = "profile page";
    let testTypeUrl = "test type list";
    const testTypeId = props.match.params.id;
    if (
      loggedUser &&
      (loggedUser.role === "Lab Users" ||
        loggedUser.role === "Processors" ||
        loggedUser.role === "Staffs")
    ) {
      const processorId = loggedUser.processorId;
      const url = `${process.env.REACT_APP_API_EXTERNAL_URL}/test/create/${processorId}/${testTypeId}`;
      sample = `<p></p><strong>Sample:</strong> <a href=${url} target='_blank'>${url}</a>`;
      profileUrl = `<a href=${process.env.REACT_APP_URL_MY_PROFILE} target='_blank'>profile page</a>`;
      testTypeUrl = `<a href=${process.env.REACT_APP_URL_NUTTYPE_LIST} target='_blank'>test type list</a>`;
    }
    this.headerInfoArray = [];
    this.bodyInfoArray = [
      {
        title: "Description",
        content:
          "Push the tests that belongs to processor from PF Core.<br/>PF Grading will update if a test exists based on LOT as primary key otherwise will create new one.<br/>This API only supports paid accounts.",
      },
      {
        title: "API URL",
        content: `<strong>POST</strong> ${process.env.REACT_APP_API_EXTERNAL_URL}/test/create/{processorId}/{testTypeId}${sample}`,
      },
      {
        title: "Request Parameters",
        content: `<ul><li><strong>processorId</strong>: string<br/>The Processor ID which is retrieved under processor's ${profileUrl}.</li>
        <li><strong>testTypeId</strong>: string<br/>Test Type ID which is retrieved at ${testTypeUrl}.</li><ul>`,
      },
      { title: "Field Types", content: "" },
      { title: "Example Payloads", content: "" },
      { title: "Example Successful Response", content: "" },
      { title: "Example Error Response", content: "" },
    ];

    this.state.requestJSON = [];
    this.state.examplePayloads = [];
    this.state.testTypeId = testTypeId;
    this.state.testTypeName = ``;
    this.renderBodyTable = this.renderBodyTable.bind(this);
  }

  async componentDidMount() {
    const that = this;
    //Query dynamic fields belong to test type stand on
    fetch(
      GlobalConfig.REACT_APP_API_NUTTYPE_URL +
      `getSyncFieldsByTestType/` +
      this.state.testTypeId
    ).then(function (response) {
      return response.json();
    })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          const fields = [];
          const examplePayloads = [];
          if (resultObject.data && resultObject.data.length > 0) {
            let objExample = {};
            resultObject.data.map((x) => {
              objExample = JSON.parse(JSON.stringify(objExample));
              objExample[x.fieldName] = x.sample;
              if (!x.isDynamic) {
                if (x.type === `Free text`) {
                  fields.push({
                    [x.label]: {
                      fieldName: x.fieldName,
                      type: x.type,
                      maxLength: x.maxLength,
                    },
                  });
                } else if (x.type.indexOf(`Date`) !== -1) {
                  fields.push({
                    [x.label]: {
                      fieldName: x.fieldName,
                      type: x.type,
                    },
                  });
                }
                else {
                  fields.push({
                    [x.label]: {
                      fieldName: x.fieldName,
                      type: x.type,
                      min: x.min,
                      max: x.max,
                    },
                  });
                }
              } else {
                switch (x.type) {
                  case FIELD_TYPE_DROP_DOWN:
                    fields.push({
                      [x.label]: {
                        fieldName: x.fieldName,
                        type: x.type,
                        classification: x.classification,
                        maxLength: 100,
                      },
                    });
                    break;
                  case FIELD_TYPE_TEXT_BOX:
                  case FIELD_TYPE_TEXT_SMALL:
                    fields.push({
                      [x.label]: {
                        fieldName: x.fieldName,
                        type: `Free text`,
                        maxLength: x.maxLength ? x.maxLength : `Unlimited`,
                      },
                    });
                    break;
                  case FIELD_TYPE_INTEGER:
                  case FIELD_TYPE_PERCENT:
                  case FIELD_TYPE_DECIMAL:
                    fields.push({
                      [x.label]: {
                        fieldName: x.fieldName,
                        type: x.type,
                        min: x.min,
                        max: x.max,
                      },
                    });
                    break;
                }
              }
            });
            examplePayloads.push(objExample);
          }
          that.setState({
            requestJSON: fields,
            examplePayloads: examplePayloads,
            testTypeName: resultObject.testTypeName,
          });
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      }).catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  }

  renderBodyTable(bodyInfo, index) {
    const sampleJSONOk = {
      status: "OK",
      updatedCount: 1,
      createdCount: 0,
      errorCount: 1,
      result: [
        {
          lotNumber: "1111",
          result: "INVALID: variety is required or not exist.",
        },
        {
          lotNumber: "1112",
          result: "SUCCESS: Updated successful.",
        },
      ],
    };

    const sampleJSONError = {
      status: "ERROR",
      message: "Cast to date failed for value 2021-12-32",
    };
    switch (bodyInfo.title) {
      case `Example Successful Response`:
        return (
          <tr key={index}>
            <td
              style={{
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {" "}
              {bodyInfo.title}
            </td>
            <td>
              <ReactJson
                src={sampleJSONOk}
                theme="bright:inverted"
                name={false}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </td>
          </tr>
        );
      case `Example Error Response`:
        return (
          <tr key={index}>
            <td
              style={{
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {" "}
              {bodyInfo.title}
            </td>
            <td>
              <ReactJson
                src={sampleJSONError}
                theme="bright:inverted"
                name={false}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </td>
          </tr>
        );
      case `Field Types`:
        return (
          <tr key={index}>
            <td
              style={{
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {" "}
              {bodyInfo.title}
            </td>
            <td>
              <ReactJson
                src={this.state.requestJSON}
                theme="bright:inverted"
                name={false}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </td>
          </tr>
        );
      case `Example Payloads`:
        return (
          <tr key={index}>
            <td
              style={{
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {" "}
              {bodyInfo.title}
            </td>
            <td>
              <ReactJson
                src={this.state.examplePayloads}
                theme="bright:inverted"
                name={false}
                enableClipboard={true}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </td>
          </tr>
        );
      default:
        return (
          <tr key={index}>
            <td
              style={{
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {" "}
              {bodyInfo.title}
            </td>
            <td
              style={{
                textAlign: "left",
                fontSize: "15px",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {ReactHtmlParser(bodyInfo.content)}
            </td>
          </tr>
        );
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"Create Tests | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section
          className="body-sign"
          style={{ color: "white", paddingBottom: "50px" }}
        >
          <div className="container">
            <div
              className="row center"
              style={{
                color: "white",
                paddingTop: "50px",
              }}
            >
              <a href="/" className="logo pull-left">
                <img
                  src="/assets/images/logo.png"
                  height="54"
                  alt="ParityFactory Grading"
                />
              </a>
            </div>
            <div className="row center">
              <h1>ParityFactory API Reference</h1>
              <p></p>
              <h2>
                Create{" "}
                <strong style={{ color: "yellow" }}>
                  {this.state.testTypeName}
                </strong>{" "}
                API
              </h2>
            </div>
            <div className="row">
              <p></p>
            </div>
            <div className="row">
              <Table responsive bordered>
                {/* <thead>
                  <tr>
                    <th></th>
                    {this.headerInfoArray.map(this.renderHeaderTable)}
                  </tr>
                </thead> */}
                <tbody>{this.bodyInfoArray.map(this.renderBodyTable)}</tbody>
              </Table>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; ParityFactory All rights reserved |{" "}
              <a
                href="https://parityfactory.com/privacy-policy/"
                style={{ color: "white" }}
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </section>
      </div>
    );
  }
}
export default App;
