import React, { Component } from "react";

export default class content extends Component {
  constructor(props) {
    super();
    this.state = {
      help: null,
    };
  }

  async componentDidMount() {
    this.getHelp();
  }

  async getHelp() {
    const api =
      process.env.REACT_APP_API_HELP_URL + `/${this.props.match.params.id}`;
    const result = await fetch(api);
    const response = await result.json();
    if (response.result === "OK" && response.data) {
      this.setState({
        help: response.data,
      });
    } else {
      window.showAlert("ERROR", response.message, "error");
    }
  }

  render() {
    return (
      <div className="help-page">
        <div style={{ width: "100%", height: 158 }}></div>
        <div className="help-page-header">
          <div className="col-md-3 help-page-header-left">
            <img
              className="parity-logo"
              src="/assets/images/logo.png"
              alt="PF Grading Portal"
            />
          </div>
          <div className="col-md-9 help-page-header-right">
            <div className="help-page-header-right-top"></div>
            <div className="help-page-header-right-bottom help-page-menu"></div>
          </div>
        </div>
        <div className="help-detail-page-body">
          <div className="help-title">
            {this.state.help && this.state.help.title
              ? this.state.help.title
              : ""}
          </div>
          <div className="help-content">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  this.state.help && this.state.help.content
                    ? this.state.help.content
                    : "",
              }}
            ></p>
          </div>
        </div>
        <div className="help-detail-page-footer">
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 20,
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            © ParityFactory All rights reserved |{" "}
            <a href="https://parityfactory.com/privacy-policy/">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    );
  }
}
