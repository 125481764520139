import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { Button, Form, Row, Modal } from "react-bootstrap";
import DraggableModalDialog from "../Base/DraggableModalDialog";
import NumericInput from "../FormControl/NumericInput";
import NodeUuid from "node-uuid";

class App extends React.Component {
  constructor(props) {
    super();

    let ownerItems = [];
    if (props.value && props.value != "") {
      ownerItems = JSON.parse(props.value);
    }

    this.state = {
      name: props.name,
      items: ownerItems,

      //Using for popup add/edit
      data: {
        _id: "",
        firstName: "",
        lastName: "",
        ownership: 0,
      },
      isChanged: false,
      errorMessage: "",
      color: "red",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    //using for parent call method bindData outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method bindData outside
    this.props.onRef(undefined);
  }

  bindData(owners) {
    let ownerItems = [];
    if (owners && owners != "") {
      ownerItems = JSON.parse(owners);
    }
    this.setState({ items: ownerItems });
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState({
      data: Object.assign({}, this.state.data, {
        [event.target.name]: value,
      }),
    });
  }

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    this.setState({ data: convertedJSON }, function callBack() {
      this.setState({
        show: true,
        errorMessage: "",
        isChanged: false,
      });
    });
  };

  handleClose = () => {
    this.setState({ show: false });
    // if (this.state.isChanged) {
    //   //fire event to parent
    //   var value = JSON.stringify(this.state.items);
    //   this.props.handleTableOwnerChange(this.state.name, value);
    // }
  };

  handleSave = async (event) => {
    //Reset message
    this.setState({
      errorMessage: "",
    });

    let firstName = this.state.data.firstName;
    let lastName = this.state.data.lastName;
    const ownership = this.state.data.ownership;

    let errorMessage = "";
    //validate input
    if (firstName == null || firstName == undefined || firstName.trim() == "") {
      errorMessage = "Enter first name to continue.";
    } else {
      firstName = firstName.trim();
    }
    if (errorMessage == "") {
      if (lastName == null || lastName == undefined || lastName.trim() == "") {
        errorMessage = "Enter last name to continue.";
      } else {
        lastName = lastName.trim();
      }
    }
    if (errorMessage == "") {
      if (ownership == null || ownership == undefined || ownership <= 0) {
        errorMessage = "Ownership must be greater than zero.";
      }
    }

    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id == undefined ||
      this.state.data._id == ""
    ) {
      isEditMode = false;
    }
    //check duplicate first name & last name
    if (errorMessage == "") {
      let id = this.state.data._id;
      let firstNameCompare = firstName.toLowerCase();
      let lastNameCompare = lastName.toLowerCase();
      let tempItems = [];
      let items = Object.assign([], this.state.items);
      if (isEditMode != true) {
        tempItems = items.filter(function (item) {
          return (
            item.firstName.toLowerCase() === firstNameCompare &&
            item.lastName.toLowerCase() === lastNameCompare
          );
        });
      } else {
        tempItems = items.filter(function (item) {
          return (
            item.firstName.toLowerCase() === firstNameCompare &&
            item.lastName.toLowerCase() === lastNameCompare &&
            item._id !== id
          );
        });
      }
      if (tempItems && tempItems.length > 0) {
        errorMessage = "Owner already exists. Please try other name.";
      }
    }

    if (errorMessage != "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
    } else {
      if (isEditMode != true) {
        var convertedJSON = JSON.parse(JSON.stringify(this.state.data));
        convertedJSON._id = NodeUuid.v4();

        let items = Object.assign([], this.state.items);
        items.push(convertedJSON);
        this.setState({ items: items }, function callBack() {
          //fire event to parent
          var value = JSON.stringify(this.state.items);
          this.props.handleTableOwnerChange(this.state.name, value);
        });
      } else {
        //update selected item in this.state.items
        this.setState(
          {
            items: this.state.items.map((u) =>
              u._id === this.state.data._id
                ? Object.assign({}, u, {
                  firstName: firstName,
                  lastName: lastName,
                  ownership: ownership,
                })
                : u
            ),
          },
          function callBack() {
            //fire event to parent
            var value = JSON.stringify(this.state.items);
            this.props.handleTableOwnerChange(this.state.name, value);
          }
        );
      }

      let result = "OK";
      if (result === "OK") {
        this.setState({
          isChanged: true,
        });
        if (!isEditMode) {
          this.handResetForm();
        } else {
          this.setState({ show: false });
        }
      } else {
        // window.showAlert("Error", resultObject.message, "error");
      }
    }
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        firstName: "",
        lastName: "",
        ownership: 0,
      },
    });
  };

  extendRender = () => {
    return (
      <Modal
        dialogAs={DraggableModalDialog}
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.data._id == "" ? "Add Owner" : "Edit Owner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.data ? (
              <>
                <div className="form-group">
                  <label className="col-md-3 control-label" htmlFor="attribute">
                    First Name <span className="required">(*)</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      name="firstName"
                      autoFocus={true}
                      className="form-control"
                      placeholder="Enter name first name"
                      required
                      value={this.state.data.firstName}
                      onChange={this.handleChange}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-3 control-label" htmlFor="attribute">
                    Last Name <span className="required">(*)</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Enter name last name"
                      required
                      value={this.state.data.lastName}
                      onChange={this.handleChange}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <NumericInput
                    precision={1}
                    min={0}
                    max={100}
                    step={0.1}
                    label="Ownership"
                    name="ownership"
                    value={this.state.data.ownership}
                    handleChange={this.handleChange}
                    unit="%"
                    labelClass="3"
                    inputClass="5"
                    required={true}
                    labelrequired="(*)"
                  />
                </div>
              </>
            ) : (
              <Form.Group as={Row}>
                <Form.Label column md="12">
                  Selected crop year no longer exists. Please refresh and try
                  again.
                </Form.Label>
              </Form.Group>
            )}
            <div className="col-md-12">
              {this.state.errorMessage == null ||
                this.state.errorMessage == "" ||
                this.state.errorMessage == undefined ? (
                ""
              ) : (
                <>
                  <Form.Label
                    column
                    md="12"
                    style={{ color: this.state.color }}
                  >
                    {this.state.errorMessage}
                  </Form.Label>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.data ? (
              <Button
                variant="success"
                type="button"
                onClick={() => this.handleSave()}
              >
                <span className="fa fa-check fa-lg"></span> Submit
              </Button>
            ) : (
              ""
            )}
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  handleDelete = (removedId) => {
    this.setState(
      {
        items: this.state.items.filter(function (item) {
          return item._id !== removedId._id;
        }),
      },
      function callBack() {
        //fire event to parent
        var value = JSON.stringify(this.state.items);
        this.props.handleTableOwnerChange(this.state.name, value);
      }
    );
  };

  render() {
    return (
      <>
        {this.props.readOnly ? (
          ""
        ) : (
          <div className="form-group">
            <div className="col-md-2"></div>
            <div className="col-md-4 text-left">
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() =>
                    this.handleShow({
                      _id: "",
                      firstName: "",
                      lastName: "",
                      ownership: 0,
                    })
                  }
                  className="btn btn-primary btn-sm"
                >
                  Add Owner
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.items && this.state.items.length > 0 ? (
          this.props.displayTableOnly ? (
            <DataTable
              highlightOnHover
              striped
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={false}
            />
          ) : this.props.readOnly ? (
            <div className="form-group">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          )
        ) : (
          ""
        )}
        {this.extendRender()}
      </>
    );
  }

  setColumns = () => {
    return [
      {
        name: "Owner",
        selector: "",
        sortable: true,
        cell: (row) =>
          this.props.readOnly ? (
            <span
              style={{
                color:
                  this.props.displayTableOnly && this.props.color !== ""
                    ? this.props.color
                    : "",
              }}
            >
              {row.firstName} {row.lastName}
            </span>
          ) : (
            <a href="#" onClick={() => this.handleShow(row)} title="Edit">
              {row.firstName} {row.lastName}
            </a>
          ),
      },
      {
        name: "% Ownership",
        selector: "ownership",
        sortable: true,
        right: true,
        cell: (row) => (
          <span
            style={{
              color:
                this.props.displayTableOnly && this.props.color !== ""
                  ? this.props.color
                  : "",
            }}
          >
            {CommonUtilities.numberWithCommas(
              (row.ownership == null ||
                row.ownership == undefined ||
                isNaN(row.ownership)
                ? 0
                : parseFloat(row.ownership)
              ).toFixed(1)
            ) + "%"}
          </span>
        ),
      },
      {
        name: "",
        center: true,
        cell: (row) =>
          this.props.readOnly ? (
            ""
          ) : (
            <a
              href="#"
              title="Remove"
              onClick={() => this.handleDelete(row)}
              className="on-default remove-row"
              data-id={row._id}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
          ),
      },
    ];
  };
}

export default App;
