import React from "react";
import lodash from "lodash";
import dayjs from "dayjs";
import { isValid } from "date-fns";
import ListComponent from "../../Base/ListComponent";
import DataTable from "react-data-table-component";
import MultiSelect from "../../FormControl/MultiSelect";
import { SpaceStyled, RangePickerStyled } from "./style";
import { VALUE_QUERY_PARAM_IGNORE } from "../../Utilities/Constant";
const GlobalConfig = new window.globalConfig();
// const DateFormat = require("dateformat");

export default class App extends ListComponent {
  constructor(props) {
    super();
    const { startDate, endDate } = this.defaultFilterDateValue();
    this.state.useFilter = false;
    this.state.useColumnOptions = false;
    this.state.filterView = this.viewMode.summary;
    this.state.filterStartDate = startDate;
    this.state.filterEndDate = endDate;
    this.state.filterProcessorId = "";
    this.state.maximumFilterDate = 31;
    this.state.title = "Site Performance";
    this.state.pageName = "Site Performance";
    this.state.pluraltitle = "Site Performance";
    this.state.urlapi = this.getUrlApi();
  }

  viewMode = {
    summary: "Summary",
    detail: "Detail",
  };

  componentDidMount = () => {
    this.openFilterControl();
    this.setHeaderPadding();
  };

  componentDidUpdate = (_, previousState) => {
    if (
      previousState.filterView !== this.state.filterView ||
      previousState.filterProcessorId !== this.state.filterProcessorId
    ) {
      this.setHeaderPadding();
    }
    if (
      previousState.filterProcessorId === this.state.filterProcessorId &&
      JSON.stringify(previousState.filterStartDate) ===
        JSON.stringify(this.state.filterStartDate) &&
      JSON.stringify(previousState.filterEndDate) ===
        JSON.stringify(this.state.filterEndDate)
    ) {
      return;
    }
    this.getItems();
  };

  openFilterControl = () => {
    const searchPanel = document.getElementById("filterControl");
    searchPanel.classList.add("in");
  };

  setHeaderPadding = () => {
    const headerPanel = document.getElementsByClassName(
      "panel-heading-list-view"
    )[0];
    if (!headerPanel) {
      return;
    }
    headerPanel.style.padding =
      this.state.filterView === this.viewMode.detail &&
      this.state.filterProcessorId
        ? "10px"
        : "0px";
  };

  handleResponseData = (responseData) => {
    if (responseData.result !== "OK") {
      this.showError(responseData.message);
      return;
    }
    let data = [];
    if (responseData.data) {
      responseData.data.map((x) => {
        if (x.log) {
          let logs = JSON.parse(x.log);
          logs.map((log) => {
            log.createdDate = x.createdDate;
          });
          data = data.concat(logs);
        }
      });
    }
    this.setState({
      items: data,
      originalItems: data,
      progressPending: false,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
    });
  };

  getItems = () => {
    if (!this.state.filterProcessorId) {
      this.setState({
        items: [],
        filterView: this.viewMode.summary,
      });
      return;
    }
    let maximumDays = this.state.maximumFilterDate * 24 * 60 * 60 * 1000;
    let startDateMilisecond = this.state.filterStartDate.getTime();
    let endDateMilisecond = this.state.filterEndDate.getTime();
    if (endDateMilisecond - startDateMilisecond >= maximumDays) {
      window.showAlert(
        "Warning",
        `Unable to filter by date range is over ${this.state.maximumFilterDate} days`,
        "error"
      );
      this.setState({
        items: [],
      });
      return;
    }
    this.setState({
      progressPending: true,
    });
    const url = this.getUrlApi();
    fetch(url)
      .then((response) => response.json())
      .then((responseData) => this.handleResponseData(responseData))
      .catch((exception) => this.showError(exception.message));
  };

  getUrlApi = () => {
    return `${GlobalConfig.REACT_APP_API_PAGE_PERFORMANCE_URL}${this.state.filterProcessorId}/${VALUE_QUERY_PARAM_IGNORE}/${this.state.filterStartDate}/${this.state.filterEndDate}`;
  };

  setDataTableItems = () => {
    if (!this.state.filterView) {
      return [];
    }
    let items = [...this.state.items];
    if (this.state.filterView !== this.viewMode.summary) {
      items.sort((a, b) => {
        return new Date(b.createdDate) - new Date(a.createdDate);
      });
    } else {
      items = [];
      const pageGroups = lodash.groupBy(this.state.items, "page");
      for (let page in pageGroups) {
        items.push({
          page: page,
          loadTime: pageGroups[page].reduce(
            (sum, item) => sum + parseFloat(item.loadTime),
            0
          ),
        });
      }
      items.sort((a, b) => b.loadTime - a.loadTime);
    }
    return items;
  };

  showError = (error) => {
    this.setState({
      alertshow: false,
      progressPending: false,
      alertmsg: ``,
    });
    this.setState({
      alertmsg: error,
      alerttype: "error",
      alertshow: true,
    });
  };

  setColumns = () => {
    return [
      {
        name: "Page URL",
        cell: (row) => <a href={`${row.page}`}>{row.page}</a>,
      },
      {
        name: "Load Time (second)",
        center: true,
        width: "150px",
        wrap: false,
        cell: (row) => parseFloat(row.loadTime).toFixed(2),
      },
      // {
      //   name: "Date",
      //   selector: "createdDate",
      //   sortable: true,
      //   center: true,
      //   cell: (row) => {
      //     return row.createdDate
      //       ? DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT")
      //       : "";
      //   },
      //   width: "175px",
      //   omit: this.state.filterView !== this.viewMode.detail,
      // },
    ];
  };

  defaultTitleSetting = () => {
    if (
      this.state.filterView !== this.viewMode.detail ||
      !this.state.filterProcessorId
    ) {
      return;
    }
    const total = this.getTotalItems();
    let title = total > 1 ? "records" : "record";
    return `${total} ${title} found`;
  };

  defaultViewOptions = () => {
    return [
      {
        label: this.viewMode.summary,
        value: this.viewMode.summary,
      },
      {
        label: this.viewMode.detail,
        value: this.viewMode.detail,
      },
    ];
  };

  defaultFilterDateValue = () => {
    const date = new Date();
    const startDate = new Date(Date.parse(date));
    startDate.setDate(1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    const endDate = new Date(Date.parse(date));
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);
    return {
      startDate,
      endDate,
    };
  };

  defaultTable = () => {
    return (
      <DataTable
        className="table table-bordered table-striped mb-none time-report-chart-table"
        noHeader
        noDataComponent={
          <div style={{ padding: "24px 0 24px 7px" }}>
            {!this.state.filterProcessorId
              ? "Select a processor to view report"
              : "There is not enough data to display on report"}
          </div>
        }
        pointerOnHover
        highlightOnHover={this.highlightOnHover()}
        striped={this.striped()}
        columns={this.setColumns()}
        data={this.setDataTableItems()}
        progressPending={this.state.progressPending}
        progressComponent={this.defaultProgressComponent()}
      />
    );
  };

  onFilterDateChange = (date, dateField) => {
    if (!date) {
      return;
    }
    let hours = dateField === `filterStartDate` ? 0 : 23;
    let minutes = dateField === `filterStartDate` ? 0 : 59;
    let seconds = dateField === `filterStartDate` ? 0 : 59;
    let miliseconds = dateField === `filterStartDate` ? 0 : 999;
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(miliseconds);
    this.setState({
      [dateField]: date,
    });
  };

  onDateRangeChange = (dates, _) => {
    let startDate = new Date(dates[0]);
    let endDate = new Date(dates[1]);
    //Limit date range 31 days
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);
    this.setState({
      filterStartDate: startDate,
      filterEndDate: endDate,
    });
  };

  onFilterDateBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      return;
    }
  }

  onFilterProcessorChange = (selectedProcessor) => {
    this.setState({
      filterProcessorId: selectedProcessor ? selectedProcessor.value : "",
    });
  };

  onFilterViewChange = (selectedView) => {
    this.setState({
      filterView: selectedView.value,
    });
  };

  resetDateRange = () => {
    const { startDate, endDate } = this.defaultFilterDateValue();
    this.setState({
      filterStartDate: startDate,
      filterEndDate: endDate,
    });
  };

  renderRefresh1 = () => {
    return this.state.showRefresh1 &&
      this.state.filterView === this.viewMode.detail &&
      this.state.filterProcessorId ? (
      <div className="panel-actions panel-actions-list-view">
        <a
          href=" #"
          className="panel-btn-group-color"
          title="Refresh"
          onClick={() => this.handleRefresh()}
        >
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  renderSearch = () => {
    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <MultiSelect
              api={`${process.env.REACT_APP_API_PROCESSOR_URL}filterDataProcessor`}
              placeholder="Filter Processor"
              onChange={this.onFilterProcessorChange}
              value={this.state.filterProcessorId}
              isClearable
            ></MultiSelect>
          </div>

          {this.state.filterProcessorId && (
            <>
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <SpaceStyled direction="vertical" size={12}>
                  <RangePickerStyled
                    allowClear={false}
                    onChange={this.onDateRangeChange}
                    value={[
                      dayjs(this.state.filterStartDate),
                      dayjs(this.state.filterEndDate),
                    ]}
                  />
                </SpaceStyled>
              </div>
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <MultiSelect
                  defaultOptions={this.defaultViewOptions()}
                  placeholder="View Mode"
                  onChange={this.onFilterViewChange}
                  value={this.state.filterView}
                ></MultiSelect>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
}
