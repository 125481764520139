import React, { Component } from "react";
import Select from "react-select";

export default class SelectMultiGrowers extends Component {
  constructor(props) {
    super();
    this.state = {
      growers: props.externalSource !== true ? [] : props.source,
      selectedGrowers: [],
    };
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
    if (this.props.externalSource !== true) {
      this.getGrowers();
    }
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  async componentDidUpdate(previousProps) {
    if (previousProps.api !== this.props.api) {
      this.getGrowers();
    }
  }

  async getGrowers() {
    const result = await fetch(this.props.api);
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      const growers = response.data.map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
        };
      });
      this.setState({
        growers: growers,
      });
    }
  }

  onChangeGrowers = (growers) => {
    if (this.props.externalSource === true) {
      this.setState({
        value: growers,
      });
    }
    this.props.onChangeSelectedGrowers(growers);
  };

  setSelected(selectedIds) {
    let selectedGrowers = [];
    const dataSource = this.props.externalSource !== true ? this.state.growers : this.props.source;
    if (
      dataSource &&
      dataSource.length > 0 &&
      selectedIds &&
      selectedIds !== ``
    ) {
      selectedGrowers = dataSource.filter((x) => {
        return selectedIds.indexOf(x.value) !== -1;
      });
    }
    this.setState({ value: selectedGrowers });
  }

  render() {
    return (
      <Select
        className={
          this.props.withoutLabel === false ? "" : "multi-select-growers"
        }
        closeMenuOnSelect={false}
        closeMenuOnChange={false}
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Select growers"
        }
        isMulti
        options={this.props.externalSource !== true ? this.state.growers : this.props.source}
        value={
          this.props.externalSource !== true
            ? this.props.selectedGrowers
            : this.state.value
        }
        onChange={this.onChangeGrowers}
        isOptionDisabled={
          this.props.isOptionDisabled
            ? this.props.isOptionDisabled
            : () => false
        }
      ></Select>
    );
  }
}
