import React from "react";
import AddComponent from "../Base/AddComponent";
import moment from "moment";
import MultiSelect from "./../FormControl/MultiSelect";
import {
  CRON_JOB_TYPE_DAILY,
  CRON_JOB_TYPE_HOURLY,
} from "../Utilities/Constant";

class App extends AddComponent {
  constructor(props) {
    super();
    this.state.title = "Scheduled Task";
    this.state.listtitle = "Scheduled Task";
    this.state.urllist = process.env.REACT_APP_URL_CRON_JOB_SETTING;
    this.state.urlapi = process.env.REACT_APP_API_CRON_JOB_SETTING_URL;
    this.state.taskNameOptions = null;
    this.state.taskNameValue = null;
    this.state.typeCronJobValue = null;
    this.state.startInfo = null;
    this.state.taskNames = [];
    this.state.data = {
      taskName: "",
      typeCronJob: CRON_JOB_TYPE_HOURLY,
      stepSize: "1",
      startTime: "00:00",
      description: "",
      startDateTime: null,
    };
  }

  componentDidMount = () => {
    this.getTaskNameItems();
    this.setStartInfo(this.state.data.typeCronJob, this.state.data.startTime);
  };

  componentDidUpdate = (_, previousState) => {
    const currentState = this.state;
    if (
      previousState.data.startTime !== currentState.data.startTime ||
      previousState.data.typeCronJob !== currentState.data.typeCronJob
    ) {
      this.setStartInfo(
        currentState.data.typeCronJob,
        currentState.data.startTime
      );
    }
    // if user change data
    if (this.state.isChanged) {
      // warn user will lose the changes
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  };

  getTaskNameItems = () => {
    this.setState({
      alertshow: false,
    });
    fetch(`${this.state.urlapi}tasknames/unregistered`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.result === "OK") {
          const taskNames = responseData.data.map((taskName) => {
            return {
              label: taskName,
              value: taskName,
            };
          });
          const selectedTaskName =
            Array.isArray(taskNames) && taskNames.length
              ? taskNames[0].value
              : "";
          this.setState({
            taskNames: taskNames,
            data: {
              ...this.state.data,
              taskName: selectedTaskName,
            },
            alertshow: !selectedTaskName,
            alertmsg: !selectedTaskName
              ? "There are no unscheduled tasks. Each task can only have one schedule."
              : "",
            alerttype: !selectedTaskName ? "error" : "",
          });
        } else {
          this.setState({
            alertshow: true,
            alertmsg: responseData.message,
            alerttype: "error",
          });
        }
      })
      .catch((exception) => {
        this.setState({
          alertshow: true,
          alertmsg: exception.message,
          alerttype: "error",
        });
      });
  };

  onTaskNameChange = ({ value }) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, taskName: value },
    });
  };

  onTypeCronJobChange = ({ value }) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, typeCronJob: value },
    });
  };

  onStartTimeChange = (e) => {
    if (!e.target.value) {
      e.target.value = "00:00";
    }
    this.handleChange(e);
  };

  setStartInfo(type, startAt) {
    let datetime = new Date(
      `${moment(new Date()).format("YYYY-MM-DD").toString()} ${startAt}:00`
    );
    const now = new Date();
    if (datetime < now && type === CRON_JOB_TYPE_HOURLY) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === CRON_JOB_TYPE_DAILY) {
      datetime = new Date(datetime.getTime() + 86400000);
    }
    this.setState({
      data: { ...this.state.data, startDateTime: datetime },
      startInfo: moment(datetime).format("MM/DD/YYYY hh:mm A"),
    });
  }

  checkSomethingBeforeSave = () => {
    if (!this.state.data.taskName) {
      window.showAlert("Warning", "Please select an unscheduled task", "error");
      return false;
    }
    return true;
  };

  doSomethingAfterSaved = (_) => {
    this.handleReset();
  };

  handleReset = () => {
    this.setState({
      data: {
        taskName: "",
        typeCronJob: CRON_JOB_TYPE_HOURLY,
        stepSize: "1",
        startTime: "00:00",
        description: "",
        startDateTime: null,
      },
    });
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Task <span className="required">*</span>
          </label>
          <div className="col-sm-5">
            <MultiSelect
              isMulti={false}
              defaultOptions={this.state.taskNames}
              closeMenuOnSelect={true}
              placeholder={"Select an unscheduled task"}
              onChange={this.onTaskNameChange}
              value={this.state.data.taskName}
            ></MultiSelect>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">Type</label>
          <div className="col-sm-2">
            <MultiSelect
              isMulti={false}
              defaultOptions={[
                {
                  value: CRON_JOB_TYPE_HOURLY,
                  label: CRON_JOB_TYPE_HOURLY,
                },
                {
                  value: CRON_JOB_TYPE_DAILY,
                  label: CRON_JOB_TYPE_DAILY,
                },
              ]}
              closeMenuOnSelect={true}
              onChange={this.onTypeCronJobChange}
              value={this.state.data.typeCronJob}
            ></MultiSelect>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Step Size</label>
          <div className="col-md-2">
            <input
              required
              min={1}
              max={100}
              name="stepSize"
              defaultValue={1}
              value={this.state.data.stepSize}
              onChange={this.handleChange}
              className="form-control"
              type="number"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">Start At</label>
          <div className="col-md-2">
            <input
              name="startTime"
              value={this.state.data.startTime}
              onChange={this.onStartTimeChange}
              className="form-control"
              type="time"
            />
          </div>
          <label className="control-label pl-md">
            <span className="fa fa-info-circle"></span>&nbsp; Next run:{" "}
            {this.state.startInfo}
          </label>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">Description</label>
          <div className="col-sm-5">
            <textarea
              maxLength="1000"
              name="description"
              rows="4"
              className="form-control"
              placeholder="Description"
              value={this.state.data.description}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  };
}
export default App;
