import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import moment from "moment";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    this.state = {
      popupUrlApi: "",
      testType: {
        xpool: false,
        isDynamic: false,
        _id: "",
        basePriceByPayment: false,
        paymentPercentBased: true,
        multiPricesSameLot: false,
        singlePricePerLot: false,
        varietyOptional: false,
        taxFeeByMeta: false,
        paymentByVariety: false,
      },
    };
    loggedUser = getLoggedUser();
    this.handleChangeSelectGrower = this.handleChangeSelectGrower.bind(this);
    this.handleChangeListGrower = this.handleChangeListGrower.bind(this);
    this.handleApplyGrowers = this.handleApplyGrowers.bind(this);
  }

  async componentDidMount() {
    if (
      (loggedUser.titerInfo &&
        loggedUser.titerInfo.isAllowGrowerPayment === true) ||
      loggedUser.role === USER_ROLE_ADMINISTRATOR
    ) {
      this.queryTestType();
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
      });
    }
  }

  queryTestType = () => {
    const that = this;
    const isServerPaging = this.state.isServerPaging;
    fetch(
      `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}testTypeByCropYear/${this.props.match.params.id}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response && response.result === "OK") {
          that.setState(
            {
              testType: response.data,
            },
            () => {
              if (isServerPaging) {
                that.getItemsServerPaging(that.state.currentPage);
              } else {
                that.getItems();
              }
            }
          );
        } else {
          window.showAlert("Error", response.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions" style={{ top: "0px" }}>
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  extendBody = () => {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {loggedUser.role === "Processors" || loggedUser.role === "Staffs"
          ? this.state.extendBodyMessage
          : this.state.extendBodyMessage}
      </div>
    );
  };

  async updatePreviousParent(data, growerId) {
    let previousParent = undefined;
    const previousGrowerId = data.previousGrowerId;
    if (previousGrowerId !== growerId) {
      const previousParents = this.state.items.filter((parent) => {
        return (
          (parent.growerId === null || parent.growerId === undefined
            ? ""
            : parent.growerId.toString()) === previousGrowerId
        );
      });
      if (previousParents && previousParents.length > 0) {
        previousParent = previousParents[0];
        //remove child from parent
        previousParent.children = previousParent.children.filter((c) => {
          return c._id !== data._id;
        });

        if (
          previousParent.children === undefined ||
          previousParent.children === null ||
          previousParent.children.length <= 0
        ) {
          //remove parent
          const tempItems = this.state.items.filter(function (parent) {
            return (
              (parent.growerId === null || parent.growerId === undefined
                ? ""
                : parent.growerId.toString()) !==
              data.previousGrowerId.toString()
            );
          });
          const tempOriginalItems = this.state.originalItems.filter(function (
            parent
          ) {
            return (
              (parent.growerId === null || parent.growerId === undefined
                ? ""
                : parent.growerId.toString()) !==
              data.previousGrowerId.toString()
            );
          });

          this.setState({ items: [], originalItems: [] }, () => {
            this.setState({
              items: tempItems,
              originalItems: tempOriginalItems,
            });
          });
        } else {
          //Remember remove child from previousParent
          previousParent.children = previousParent.children.filter((el) => {
            return el._id !== data._id;
          });
          previousParent = this.doSomethingOnParent(previousParent);

          //update parent
          const tempItems = this.state.items.filter(function (parent) {
            return (parent.growerId === null || parent.growerId === undefined
              ? ""
              : parent.growerId.toString()) === growerId
              ? Object.assign({}, parent, previousParent)
              : parent;
          });
          const tempOriginalItems = this.state.originalItems.filter(function (
            parent
          ) {
            return (parent.growerId === null || parent.growerId === undefined
              ? ""
              : parent.growerId.toString()) === growerId
              ? Object.assign({}, parent, previousParent)
              : parent;
          });
          this.setState({ items: [], originalItems: [] }, () => {
            this.setState({
              items: tempItems,
              originalItems: tempOriginalItems,
            });
          });
        }
      }
    }
  }

  doSomethingOnParent(parent) {
    return parent;
  }

  updateNewParent(data, growerId) {
    //Find parent object, if not exist create new parent unless update
    //Find children of object, if not exist insert unless update
    const parentItem = this.state.items.filter((parent) => {
      return (
        (parent.growerId === null || parent.growerId === undefined
          ? ""
          : parent.growerId.toString()) === growerId
      );
    });

    //Exist parent

    if (parentItem && parentItem.length > 0) {
      let parentUpdate = parentItem[0];
      //If edit, child is exist
      if (this.state.isEditMode) {
        //find child and update. if exist then update unless insert
        const arrTemp = parentUpdate.children.filter((el) => {
          return el._id === data._id;
        });
        if (arrTemp === null || arrTemp === undefined || arrTemp.length <= 0) {
          //insert into new parent
          let convertedJSON = JSON.parse(JSON.stringify(data));
          convertedJSON.grower =
            parentUpdate.grower === "" ? "Default" : parentUpdate.grower;
          parentUpdate.children.push(convertedJSON);
        } else {
          //update
          parentUpdate.children = parentUpdate.children.map((el) =>
            el._id === data._id ? Object.assign({}, el, data) : el
          );
        }
      } else {
        //child is new
        let convertedJSON = JSON.parse(JSON.stringify(data));
        convertedJSON.grower =
          parentUpdate.grower === "" ? "Default" : parentUpdate.grower;
        parentUpdate.children.push(convertedJSON);
      }

      parentUpdate = this.doSomethingOnParent(parentUpdate);

      const tempItemsParent = this.state.items.filter(function (parent) {
        return (parent.growerId === null || parent.growerId === undefined
          ? ""
          : parent.growerId.toString()) === growerId
          ? Object.assign({}, parent, parentUpdate)
          : parent;
      });
      const tempOriginalItemsParent = this.state.originalItems.filter(function (
        parent
      ) {
        return (parent.growerId === null || parent.growerId === undefined
          ? ""
          : parent.growerId.toString()) === growerId
          ? Object.assign({}, parent, parentUpdate)
          : parent;
      });

      //update editing parent
      this.setState({ items: [], originalItems: [] }, () => {
        this.setState({
          items: tempItemsParent,
          originalItems: tempOriginalItemsParent,
        });
      });
    }
    //Not exist parent, create new parent
    else {
      const children = [];
      children.push(data);
      let newParent = {
        growerId: data.growerId,
        grower: data.grower === "" ? "Default" : data.grower,
        children: children,
      };
      newParent = this.doSomethingOnParent(newParent);

      const tempItems = [newParent, ...this.state.items];
      const tempOriginalItems = [newParent, ...this.state.originalItems];
      this.setState({ items: [], originalItems: [] }, () => {
        this.setState({
          items: tempItems,
          originalItems: tempOriginalItems,
        });
      });
    }
  }

  updateStateAfterClosePopup = (data) => {
    if (this.state.isChanged) {
      const growerId =
        data.growerId === null || data.growerId === undefined
          ? ""
          : data.growerId;
      //Update previous parent if exist
      this.updatePreviousParent(data, growerId).then(() => {
        this.updateNewParent(data, growerId);
      });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  refreshTable = () => {
    //Table does not refresh data updated on children rows so this helps refresh expending row (collapse then expend).
    this.setState({ expandableRows: false }, function callBack() {
      this.setState({ expandableRows: true });
    });
  };

  handleChangeView = () => {
    if (this.state.isServerPaging === true) {
      this.setState(
        {
          viewMode: this.state.viewMode === "view" ? "edit" : "view",
          currentPage: 1,
        },
        function callBack() {
          if (this.state.viewMode === "view") {
            this.getItemsServerPaging(this.state.currentPage).then(() => {
              this.refreshTable();
            });
          } else {
            this.setState({ isExpand: true });
          }
        }
      );
    } else {
      this.setState(
        {
          viewMode: this.state.viewMode === "view" ? "edit" : "view",
        },
        function callBack() {
          if (this.state.viewMode === "view") {
            this.getItems().then(() => {
              this.refreshTable();
            });
          } else {
            this.setState({ isExpand: true });
          }
        }
      );
    }
  };

  doSomethingBeforeDeleteItem = (e) => {
    //This state is using for remove row later
    this.setState({
      removedGrowerId:
        e.currentTarget.dataset.growerid === null ||
          e.currentTarget.dataset.growerid === undefined
          ? ""
          : e.currentTarget.dataset.growerid,
    });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    //Remove item under grower
    //Remove grower if there is no item under
    const removedGrowerId =
      this.state.removedGrowerId === null ||
        this.state.removedGrowerId === undefined
        ? ""
        : this.state.removedGrowerId.toString();

    const growerItems = this.state.items.filter(function (item) {
      return (
        (item.growerId === undefined || item.growerId === null
          ? ""
          : item.growerId.toString()) == removedGrowerId
      );
    });
    if (growerItems && growerItems.length > 0) {
      let parentUpdate = growerItems[0];
      //remove child under grower
      parentUpdate.children = parentUpdate.children.filter((el) => {
        return el._id !== removedId;
      });

      //if parent still exist child, update grower
      if (parentUpdate.children && parentUpdate.children.length > 0) {
        //update editing parent
        parentUpdate = this.doSomethingOnParent(parentUpdate);

        this.setState({
          items: this.state.items.map((parent) =>
            (parent.growerId === null || parent.growerId === undefined
              ? ""
              : parent.growerId.toString()) === removedGrowerId
              ? Object.assign({}, parent, parentUpdate)
              : parent
          ),
        });
        this.setState(
          {
            originalItems: this.state.originalItems.map((parent) =>
              (parent.growerId === null || parent.growerId === undefined
                ? ""
                : parent.growerId.toString()) === removedGrowerId
                ? Object.assign({}, parent, parentUpdate)
                : parent
            ),
          },
          function () {
            this.doSomethingAfterDeletedItem();
          }
        );
      }
      //there is no child under grower, remove grower from state
      else {
        this.setState({
          items: this.state.items.filter(function (item) {
            return (
              (item.growerId === null || item.growerId === undefined
                ? ""
                : item.growerId.toString()) !== removedGrowerId
            );
          }),
        });
        this.setState(
          {
            originalItems: this.state.originalItems.filter(function (item) {
              return (
                (item.growerId === null || item.growerId === undefined
                  ? ""
                  : item.growerId.toString()) !== removedGrowerId
              );
            }),
          },
          function () {
            this.doSomethingAfterDeletedItem();
          }
        );
      }
    }
    window.showAlert("Success", "Removed successful!", "");
  };

  doSomethingAfterDeletedItem = () => {
    this.refreshTable();
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // check date < 10 to add leading zeros
    const getDate = date.getDate().toString();
    const dateCheck = getDate[1] ? getDate : "0" + getDate[0];

    // check month < 10 to add leading zeros
    const getMonth = date.getMonth() + 1;
    const monthString = getMonth.toString();
    const monthCheck = monthString[1] ? monthString : "0" + monthString[0];

    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return monthCheck + "/" + dateCheck + "/" + date.getFullYear();
    }
  };

  handleDateChange(date) {
    const getDate = moment(date).format("L");
    const defaultDate = date;
    if (date) {
      this.setState({
        data: Object.assign({}, this.state.data, {
          paymentDate: getDate,
        }),
        initDate: defaultDate,
      });
    }
  }

  conditionalRowStyles = () => {
    return [
      {
        when: (row) => row.growerId === "",
        style: {
          backgroundColor: "#33ffcc",
          color: "black",
        },
      },
    ];
  };

  handleChangeSelectGrower = (name, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [name]: value,
      }),
    });
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a href={process.env.REACT_APP_URL_CROPYEAR_LIST} title="Back to list">
          Crop Years
        </a>
      </li>
    );
  };

  setColumns = () => {
    return [
      {
        name: "Default/Grower",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          const length =
            row.children === null || row.children === undefined
              ? 0
              : row.children.length;
          return row.growerId === "" ? (
            `Default (${length})`
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {`${row.grower} (${length})`}
            </a>
          );
        },
      },
    ];
  };

  handleChangeListGrower = () => {
    if (this.state.isServerPaging) {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.getItemsServerPaging(this.state.currentPage);
        }
      );
    } else {
      this.getItems();
    }
  };

  handleApplyGrowers = async () => {
    //open popup
    this.openPopupGrowers();
  };

  openPopupGrowers = async () => {
    try {
      const that = this;
      fetch(
        this.state.popupUrlApi +
        "growerIdsByParent/" +
        this.state.parentId +
        `/` +
        this.state.testType._id
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            that.listGrower.handleShow(resultObject.data);
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };
}
export default App;
