import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { Button, Form, Modal } from "react-bootstrap";
// import DraggableModalDialog from "../Base/DraggableModalDialog";
import NumericInput from "../FormControl/NumericInput";
import SelectConfig from "../Base/SelectConfig";
import NodeUuid from "node-uuid";
import FilterSelectControl from "../FormControl/FilterSelectControl";

class App extends React.Component {
  constructor(props) {
    super();

    let attributeItems = [];
    if (props.value && props.value !== "") {
      attributeItems = JSON.parse(props.value);
    }

    this.state = {
      name: props.name,
      items: attributeItems,

      //Using for popup add/edit
      data: {
        _id: "",
        attribute: "",
        value: 1,
        //only has value if dynamic test
        testFieldId: "",
      },
      isChanged: false,
      errorMessage: "",
      color: "red",
      equation: "",
      defaultLabel: "Choose one",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  async componentDidMount() {
    //using for parent call method bindData outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method bindData outside
    this.props.onRef(undefined);
  }

  bindData(items) {
    let attributeItems = [];
    if (items && items !== "") {
      attributeItems = JSON.parse(items);
    }
    this.setState({ items: attributeItems }, () => {
      this.updateEquation();
    });
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          [event.target.name]: value,
        }),
      },
      () => {
        this.updateEquation();
      }
    );
  }

  handleChangeSelectControl = (controlId, value) => {
    if (controlId === `attribute`) {
      if (this.props.isDynamic === true) {
        this.setState(
          {
            data: Object.assign({}, this.state.data, {
              [`attribute`]: value.label,
              [`testFieldId`]: value.value,
            }),
          },
          () => {
            this.updateEquation();
          }
        );
      } else {
        this.setState(
          {
            data: Object.assign({}, this.state.data, {
              [`attribute`]: value,
              [`testFieldId`]: ``,
            }),
          },
          () => {
            this.updateEquation();
          }
        );
      }
    } else {
      this.setState(
        {
          data: Object.assign({}, this.state.data, {
            [controlId]: value,
          }),
        },
        () => {
          this.updateEquation();
        }
      );
    }
  };

  updateEquation() {
    let equation = "";
    if (this.state.items && this.state.items.length > 0) {
      this.state.items.forEach((x) => {
        let e = "";
        if (x.value !== 1) {
          e = `(${x.value} * ${x.attribute})`;
        } else {
          e = `${x.attribute}`;
        }
        if (equation !== "") {
          equation += " + ";
        }
        equation += e;
      });
    }
    this.setState({
      equation: equation,
    });
  }

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    this.setState({ data: convertedJSON }, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
        },
        () => {
          if (this.attribute) {
            if (this.props.isDynamic === true) {
              this.attribute.setSelected(this.state.data.testFieldId);
            } else {
              this.attribute.setSelected(this.state.data.attribute);
            }
          }
        }
      );
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    //Reset message
    this.setState({
      errorMessage: "",
    });

    let attribute = this.state.data.attribute;
    const value = this.state.data.value;

    let errorMessage = "";
    //validate input
    if (
      attribute == null ||
      attribute === undefined ||
      attribute.trim() === "" ||
      attribute.trim() === this.state.defaultLabel
    ) {
      errorMessage = "Select base attribute to continue.";
    } else {
      attribute = attribute.trim();
    }
    if (errorMessage === "") {
      if (value == null || value === undefined || value <= 0) {
        errorMessage = "Attribute must be greater than zero.";
      }
    }

    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      isEditMode = false;
    }
    //check duplicate first name & last name
    if (errorMessage === "") {
      let id = this.state.data._id;
      let attributeCompare = attribute.toLowerCase();
      let tempItems = [];
      let items = Object.assign([], this.state.items);
      if (isEditMode !== true) {
        tempItems = items.filter(function (item) {
          return item.attribute.toLowerCase() === attributeCompare;
        });
      } else {
        tempItems = items.filter(function (item) {
          return (
            item.attribute.toLowerCase() === attributeCompare && item._id !== id
          );
        });
      }
      if (tempItems && tempItems.length > 0) {
        errorMessage =
          "Attribute already exists. Please select other attribute.";
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
    } else {
      if (isEditMode !== true) {
        var convertedJSON = JSON.parse(JSON.stringify(this.state.data));
        convertedJSON._id = NodeUuid.v4();
        let items = Object.assign([], this.state.items);
        items.push(convertedJSON);
        this.setState({ items: items }, function callBack() {
          //fire event to parent
          var value = JSON.stringify(this.state.items);
          this.props.handleTableAttributeChange(
            this.state.name,
            value,
            this.state.equation
          );
        });
      } else {
        //update selected item in this.state.items
        this.setState(
          {
            items: this.state.items.map((u) =>
              u._id === this.state.data._id
                ? Object.assign({}, u, {
                  attribute: attribute,
                  value: value,
                  testFieldId: this.state.data.testFieldId,
                })
                : u
            ),
          },
          function callBack() {
            //fire event to parent
            var value = JSON.stringify(this.state.items);
            this.props.handleTableAttributeChange(
              this.state.name,
              value,
              this.state.equation
            );
          }
        );
      }

      let result = "OK";
      if (result === "OK") {
        this.setState(
          {
            isChanged: true,
          },
          () => {
            this.updateEquation();
          }
        );
        if (!isEditMode) {
          this.handResetForm();
        } else {
          this.setState({ show: false });
        }
      } else {
        // window.showAlert("Error", resultObject.message, "error");
      }
    }
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        attribute: "",
        value: 1,
        testFieldId: "",
      },
    });
    if (this.attribute) {
      this.attribute.setSelected("");
    }
  };

  extendRender = () => {
    return (
      <Modal
        // dialogAs={DraggableModalDialog}
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.data._id === ""
                ? "Select Attribute"
                : "Select Attribute"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-3 control-label" htmlFor="attribute">
                Base Attribute<span className="required">(*)</span>
              </label>
              <div className="col-md-9">
                {this.props.isDynamic === true ? (
                  <FilterSelectControl
                    handleChange={this.handleChangeSelectControl}
                    onRef={(ref) => (this.attribute = ref)}
                    label={this.state.defaultLabel}
                    required={true}
                    value={this.state.data.attribute}
                    name="attribute"
                    controlId="attribute"
                    urlapilist={this.props.apiUrlDynamicAttribute}
                  />
                ) : (
                  <SelectConfig
                    processorId={this.props.processorId}
                    configType={process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT}
                    onlyBaseAttribute={true}
                    required={true}
                    onRef={(ref) => (this.attribute = ref)}
                    name="attribute"
                    controlId="attribute"
                    selectedIds={this.state.data.attribute}
                    handleChangeSelectControl={this.handleChangeSelectControl}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <NumericInput
                precision={1}
                min={0}
                max={100}
                step={0.1}
                label="Value"
                name="value"
                value={this.state.data.value}
                handleChange={this.handleChange}
                unit=""
                labelClass="3"
                inputClass="5"
                required={true}
                labelrequired="(*)"
              />
            </div>
            <div className="col-md-12">
              {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                ""
              ) : (
                <>
                  <Form.Label style={{ color: this.state.color }}>
                    {this.state.errorMessage}
                  </Form.Label>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {this.state.data ? (
                <Button
                  variant="success"
                  type="button"
                  onClick={() => this.handleSave()}
                >
                  <span className="fa fa-check fa-lg"></span> OK
                </Button>
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  handleDelete = (removedId) => {
    this.setState(
      {
        items: this.state.items.filter(function (item) {
          return item._id !== removedId._id;
        }),
      },
      function callBack() {
        //fire event to parent
        var value = JSON.stringify(this.state.items);
        this.props.handleTableAttributeChange(
          this.state.name,
          value,
          this.state.equation
        );
      }
    );
  };

  render() {
    return (
      <>
        {this.state.equation === undefined ||
          this.state.equation === null ||
          this.state.equation === "" ? (
          ""
        ) : (
          <div className="form-group">
            <label className="col-md-2 control-label"></label>
            <label className="col-md-10 control-label">
              Equation = <strong>{this.state.equation}</strong>
            </label>
          </div>
        )}
        {this.props.readOnly ? (
          ""
        ) : (
          <div className="form-group">
            <div className="col-md-2"></div>
            <div className="col-md-4 text-left">
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() =>
                    this.handleShow({
                      _id: "",
                      attribute: "",
                      value: 1,
                    })
                  }
                  className="btn btn-primary btn-sm"
                >
                  Select Base Attribute
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.items && this.state.items.length > 0 ? (
          this.props.displayTableOnly ? (
            <DataTable
              highlightOnHover
              striped
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={false}
            />
          ) : this.props.readOnly ? (
            <div className="form-group">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          )
        ) : (
          ""
        )}
        {this.extendRender()}
      </>
    );
  }

  setColumns = () => {
    return [
      {
        name: "Attribute",
        selector: "attribute",
        sortable: true,
        cell: (row) =>
          this.props.readOnly ? (
            <span
              style={{
                color:
                  this.props.displayTableOnly && this.props.color !== ""
                    ? this.props.color
                    : "",
              }}
            >
              {row.attribute}
            </span>
          ) : (
            <a href="#" onClick={() => this.handleShow(row)} title="Edit">
              {row.attribute}
            </a>
          ),
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        cell: (row) => (
          <span
            style={{
              color:
                this.props.displayTableOnly && this.props.color !== ""
                  ? this.props.color
                  : "",
            }}
          >
            {CommonUtilities.numberWithCommas(
              (row.value == null || row.value === undefined || isNaN(row.value)
                ? 0
                : parseFloat(row.value)
              ).toFixed(1)
            )}
          </span>
        ),
      },
      {
        name: "",
        center: true,
        cell: (row) =>
          this.props.readOnly ? (
            ""
          ) : (
            <a
              href="#"
              title="Remove"
              onClick={() => this.handleDelete(row)}
              className="on-default remove-row"
              data-id={row._id}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
          ),
      },
    ];
  };
}

export default App;
