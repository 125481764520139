import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>
            {"Thank You Signup Processor | " + process.env.REACT_APP_SITE_TITLE}
          </title>
        </Helmet>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left">
              <img
                src="/assets/images/logo.png"
                height="54"
                alt="ParityFactory Grading"
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Sign Up Processor
                </h2>
              </div>
              <div className="panel-body">
                <div className="text-center">
                  <h1>Thank You!</h1>
                </div>
                <div className="text-center">
                  <span>
                    Your submission has been recieved. Please check your inbox to activate your account.
                  </span>
                </div>
              </div>
            </div>

            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; ParityFactory All rights reserved |{" "}
                <a
                  href="https://parityfactory.com/privacy-policy/"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </section>
      </div>
    );
  }
}
export default App;
