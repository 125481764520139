import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import { Button, Form, Row, Modal } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import Switch from "react-switch";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
} from "../Utilities/Constant";
import String from "../Utilities/String";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const defaultOptions = [
  { _id: "true", name: "Yes" },
  { _id: "false", name: "No" },
];
class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.urlapidelete = GlobalConfig.REACT_APP_API_CLASSIFICATION_URL;
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_URL}all/all`;
    this.state.urllist = process.env.REACT_APP_URL_CLASSIFICATION_LIST;
    this.state.title = "Classification";
    this.state.filterEditable = "";
    this.state.filterProcessorId = "";
    this.state.filterName = "";
    this.state.isShowClassificationModal = false;
    this.state.show = false;
    this.state.isClassification = false;
    this.state.errorMessage = "";

    this.state.pageName = "Classification";
    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.state.urlClassificationItemAdd =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST + "/classification-items/";
    this.state.urlApiProcessor = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}`;

    this.state.urlApiProcessorFilter =
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + "filterDataProcessor";

    this.state.dataItem = {
      _id: "",
      parentId: "",
      // processorID pass to backend for create log audit
      processorId: loggedUser.processorId,
      name: "",
      createdUserId: loggedUser._id,
    };

    this.state.data = {
      _id: "",
      name: "",
      editable: true,
      processorId: loggedUser.processorId,
    };
    this.state.loading = false;
    this.state.addClassItemLoading = false;

    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApplyProcessor = this.handleApplyProcessor.bind(this);
    this.handleChangeEditableSwitch =
      this.handleChangeEditableSwitch.bind(this);
    this.handleChangeTextFieldForm = this.handleChangeTextFieldForm.bind(this);
    this.handleShowClassificationPopup =
      this.handleShowClassificationPopup.bind(this);
    this.handleCloseClassificationPopup =
      this.handleCloseClassificationPopup.bind(this);
    this.handleSaveClassification = this.handleSaveClassification.bind(this);
    this.handleChangeSelectControl4ClassificationPopup =
      this.handleChangeSelectControl4ClassificationPopup.bind(this);
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleChangeTextFieldForm = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
        },
      });
    }
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleChangeEditableSwitch = () => {
    this.setState({
      data: {
        ...this.state.data,
        editable: !this.state.data.editable,
      },
    });
  };

  handleChangeSelectControl4ClassificationPopup = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  filterItems = async () => {
    const { currentPage, perPage } = this.state;

    let filterEditable = !!this.state.filterEditable
      ? this.state.filterEditable
      : "all";

    let filterProcessorId = !!this.state.filterProcessorId
      ? this.state.filterProcessorId
      : "all";

    const urlapi = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_URL}${filterProcessorId}/${filterEditable}`;

    this.setState({ urlapi, currentPage: 1 }, () =>
      this.getItemsServerPaging(currentPage, perPage)
    );
  };

  handleReset = () => {
    this.processorControl?.reset();
    this.editableControl?.reset();
    this.setState(
      {
        filterEditable: "",
        filterProcessorId: "",
        filterName: "",
      },
      () => this.filterItems()
    );
  };

  getItemsServerPaging = async (page, size = this.state.perPage) => {
    const filterName = String.isEmpty(this.state.filterName)
      ? ""
      : encodeURIComponent(this.state.filterName.trim());
    let searchUrl =
      this.state.urlapi + `/${page}/${size}` + `?name=${filterName}`;
    //If loged in user is staff or processor
    if (
      loggedUser.role === USER_ROLE_STAFF ||
      loggedUser.role === USER_ROLE_PROCESSOR
    ) {
      searchUrl += `&byProcessorId=${loggedUser.processorId}`;
    }
    const that = this;
    that.setState({
      progressPending: true,
    });
    fetch(searchUrl)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            progressPending: false,
          },
          () => {
            that.doSomethingAfterGetItems(resultObject);
          }
        );
      })
      .catch(function (error) {
        that.setState({
          progressPending: false,
        });
        console.log(error);
      });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    window.showAlert("Success", "Removed successful!", "");
    //If updated successful
    this.setState(
      {
        currentPage: 1,
      },
      function () {
        this.doSomethingAfterDeletedItem();
        this.getItemsServerPaging(this.state.currentPage, this.state.perPage);
      }
    );
  };

  handleResetForm = () => {
    this.setState({
      dataItem: Object.assign({}, this.state.dataItem, {
        id: "",
        // processorID pass to backend for create log audit
        processorId: loggedUser.processorId ? loggedUser.processorId : "",
        name: "",
        createdUserId: loggedUser._id,
      }),
    });
  };

  handleChangeItem = (event) => {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      dataItem: Object.assign({}, this.state.dataItem, {
        [id]: value,
      }),
    });
  };

  handleShow = (data) => {
    this.setState({}, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
        },
        function callBack() {
          //Set selected dropdown
        }
      );
    });

    this.setState({
      dataItem: Object.assign({}, this.state.dataItem, {
        parentId: data._id,
      }),
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleApplyProcessor = (controlId, value) => {
    this.setState({
      dataItem: Object.assign({}, this.state.dataItem, {
        [controlId]: value,
      }),
    });
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  handleSave = async () => {
    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }

    let urlApi = GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL;

    this.setState(
      {
        dataItem: Object.assign({}, this.state.dataItem, {
          createdUserId: loggedUser._id,
        }),
        addClassItemLoading: true,
      },
      function callBack() {
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.dataItem),
          };
          const that = this;
          fetch(urlApi, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject && resultObject.result === "OK") {
                //reset form
                that.handleResetForm();

                if (that.state.isServerPaging === true) {
                  that.getItemsServerPaging(that.state.currentPage);
                } else {
                  that.getItems();
                }

                that.doSomethingAfterSaved(resultObject.data);

                if (
                  that.state.isShowSaveSuccessfulMessage == true ||
                  that.state.isShowSaveSuccessfulMessage == undefined ||
                  that.state.isShowSaveSuccessfulMessage == null
                ) {
                  window.showAlert("", "Save successful!", "");
                }
              }
              //If failed, show error
              else {
                that.doSomethingAfterErrorSaved(resultObject);
              }
              that.setState({ addClassItemLoading: false });
            })
            .catch(function (error) {
              window.showAlert("Error", error, "Error");
              that.setState({ addClassItemLoading: false });
            });
        } catch (err) {
          this.setState({ addClassItemLoading: false });
          window.showAlert("Error", err.message, "Error");
        }
      }
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterName"
                placeholder="Filter Name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterName}
              />
            </div>

            {loggedUser.role === "Administrators" && (
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorControl = ref)}
                  placeholder="Filter Processor"
                  label="Select All"
                  controlId="filterProcessorId"
                  urlapilist={this.state.urlApiProcessor}
                />
              </div>
            )}

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <FilterMultiSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.editableControl = ref)}
                placeholder="Filter Editable"
                label="Select All"
                controlId="filterEditable"
                isUseDefaultData
                defaultData={defaultOptions}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  defaultButtons = () => {
    return (
      <a
        href="#"
        className="btn btn-primary panel-btn-group-item-position"
        onClick={() => this.handleShowClassificationPopup()}
      >
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  handleCloseClassificationPopup = () => {
    this.setState({
      isShowClassificationModal: false,
      isClassification: false,
      errorMessage: "",
      data: {
        _id: "",
        name: "",
        editable: true,
      },
    });
  };

  handleShowClassificationPopup = (data) => {
    if (!data) {
      this.setState({
        isClassification: true,
        isShowClassificationModal: true,
        errorMessage: "",
      });
      return;
    }

    this.setState({
      isClassification: true,
      isShowClassificationModal: true,
      errorMessage: "",
      data: {
        ...this.state.data,
        name: data.name,
        editable: data.editable,
        _id: data._id,
        processorId: data.processorId,
      },
    });
  };

  handleSaveClassification = async (event) => {
    event.preventDefault();
    let { _id, name, editable, processorId } = this.state.data;

    let errorMessage = "";
    //Reset message
    this.setState({
      errorMessage: "",
    });

    if (!name && name.trim() === "") {
      errorMessage = "Please enter name to continue";
    }

    if (errorMessage !== "") {
      this.setState({ errorMessage: errorMessage });
      return;
    }

    const isCreate = !_id;

    const apiURL = isCreate
      ? `${GlobalConfig.REACT_APP_API_CLASSIFICATION_URL}`
      : `${GlobalConfig.REACT_APP_API_CLASSIFICATION_URL}/${_id}`;
    const method = isCreate ? "POST" : "PATCH";
    processorId =
      loggedUser.role === USER_ROLE_PROCESSOR ||
        loggedUser.role === USER_ROLE_STAFF
        ? loggedUser.processorId
        : processorId;
    const data = {
      name,
      editable,
      processorId: processorId,
      [isCreate ? "createdUserId" : "modifiedUserId"]: loggedUser._id,
    };

    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    const that = this;
    that.setState({ loading: true }, () => {
      fetch(apiURL, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If created successful
          if (resultObject.result === "OK") {
            window.showAlert("", "Save successful!", "");
            if (that.state.isServerPaging === true) {
              that.getItemsServerPaging(that.state.currentPage);
            } else {
              that.getItems();
            }
            that.handleCloseClassificationPopup();
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
          that.setState({ loading: false });
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
          that.setState({ loading: false });
        });
    });
  };

  permissionOnRow = (row) => {
    return (
      loggedUser.role === USER_ROLE_ADMINISTRATOR ||
      ((loggedUser.role === USER_ROLE_STAFF ||
        loggedUser.role === USER_ROLE_PROCESSOR) &&
        row.processorId === loggedUser.processorId &&
        row.createdUserId === loggedUser._id)
    );
  };

  extendRender = () => {
    if (!!this.state.isClassification) {
      return (
        <Modal
          show={this.state.isShowClassificationModal}
          onHide={this.handleCloseClassificationPopup}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSaveClassification} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {(this.state.data._id === ""
                  ? "Add Classification"
                  : "Edit Classification") +
                  (this.state.data.name !== ""
                    ? " - " + this.state.data.name
                    : "")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Classification <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <TextareaAutosize
                        type="text"
                        name="name"
                        maxRows={3}
                        minRows={1}
                        data-autoresize
                        autoFocus={true}
                        className="form-control textarea-crop-year"
                        placeholder="Enter name of classification"
                        required
                        value={this.state.data.name}
                        onChange={this.handleChangeTextFieldForm}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  {loggedUser.role === "Administrators" ? (
                    <>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Editable
                        </label>
                        <div className="col-sm-9">
                          <Switch
                            name="editable"
                            onChange={this.handleChangeEditableSwitch}
                            checked={this.state.data.editable}
                            className="react-switch"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Apply Processor
                        </label>
                        <div className="col-sm-9">
                          <FilterSelectControl
                            handleChangeSelectControl={
                              this.handleChangeSelectControl4ClassificationPopup
                            }
                            onRef={(ref) => (this.processorId = ref)}
                            placeholder="All Processors"
                            label="All Processors"
                            controlId="processorId"
                            value={this.state.data.processorId}
                            urlapilist={this.state.urlApiProcessorFilter}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected classification no longer exists. Please refresh and
                    try again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {!!this.state.errorMessage && (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: "red", paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-test-field flex-box-align-items-center">
              {this.state.data ? (
                <SaveButton loading={this.state.loading} />
              ) : (
                ""
              )}
              <Button
                variant="warning"
                onClick={this.handleCloseClassificationPopup}
              >
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    } else {
      return (
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={(e) => e.preventDefault()} id="fromPopup">
            <Modal.Header>
              <Modal.Title>Add {this.state.pageName} Items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label className="col-sm-3 control-label">
                  Name <span className="required">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="name"
                    autoFocus={true}
                    className="form-control"
                    placeholder=""
                    required
                    value={this.state.dataItem.name}
                    onChange={this.handleChangeItem}
                    maxLength={100}
                  />
                </div>
              </div>

              {loggedUser.role === "Administrators" ? (
                <div className="form-group">
                  <label className="col-sm-3 control-label">
                    Apply Processor
                  </label>
                  <div className="col-sm-9">
                    <FilterSelectControl
                      handleChangeSelectControl={this.handleApplyProcessor}
                      onRef={(ref) => (this.processorId = ref)}
                      label="All Processors"
                      controlId="processorId"
                      value={this.state.dataItem.processorId}
                      urlapilist={this.state.urlApiProcessorFilter}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer className="modal-footer-test-field">
              {this.state.dataItem ? (
                <SaveButton
                  type={"button"}
                  disabled={this.state.addClassItemLoading}
                  loading={this.state.addClassItemLoading}
                  onClick={this.handleSave}
                />
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  };

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) =>
          loggedUser.role === "Administrators" ||
            (row.processorId === loggedUser.processorId && row.editable) ? (
            <a
              href="#"
              title={row.name}
              onClick={() => this.handleShowClassificationPopup(row)}
            >
              {row.name}
            </a>
          ) : (
            row.name
          ),
      },
      {
        name: "Items",
        selector: "classification-items",
        cell: (row) => (
          <a
            href={this.state.urllist + "/classification-items/" + row._id + ""}
            title="Items"
          >
            Items{" "}
            {row.countClassificationItems > 0
              ? "(" + row.countClassificationItems + ")"
              : ""}
          </a>
        ),
      },
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
        omit: loggedUser.role !== "Administrators",
      },
      {
        name: "Editable",
        selector: "editable",
        sortable: false,
        center: true,
        cell: (row) => <div>{row.editable ? "Yes" : "No"}</div>,
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => (
          <div>
            {this.permissionOnRow(row) ? (
              <>
                <a
                  href="#"
                  onClick={() => this.handleShowClassificationPopup(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Click here to remove"
                  href="# "
                  className="on-default remove-row"
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                  className="on-default remove-row"
                >
                  <i className="fa fa-history fa-lg"></i>
                </a>
              </>
            ) : (
              ""
            )}
            &nbsp;&nbsp;
            {(loggedUser.role === "Administrators" || !!row.editable) && (
              <a
                href="# "
                className="on-default remove-row"
                data-id={row._id}
                onClick={() => this.handleShow(row)}
                style={{ cursor: "pointer" }}
                title="Click here to create new a classification item"
              >
                &nbsp;&nbsp;Add Item
              </a>
            )}
          </div>
        ),
        minWidth: "180px",
      },
    ];
  };
}

export default App;
