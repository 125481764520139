import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
const { sortBy } = require("lodash");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: this.composeData(props),
    };
  }

  composeData = (props) => {
    let viewData = [];
    if (
      props.data.data &&
      props.data.data !== ``
    ) {
      viewData = JSON.parse(props.data.data);
      if (viewData && viewData.length > 0) {
        viewData = sortBy(viewData, [`name`], ["asc"]);
      }
    }

    return viewData;
  };

  setColumns = () => {
    return [
      {
        name: "Rule Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.props.testType.taxFeeByMeta,
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        cell: (row) => {
          {
            return parseFloat(row.value) < 0 ? (
              <span style={{ color: "red" }}>
                ${CommonUtilities.numberWithCommasNegative(row.value)}
              </span>
            ) : (
              "$" + CommonUtilities.numberWithCommasNegative(row.value)
            );
          }
        },
      },
      {
        name: "Rule Type",
        selector: "type",
        sortable: true,
        center: true,
        cell: (row) => row.type,
      },
      {
        name: "Tax or Fee Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      }
    ];
  };

  render() {
    return (
      <DataTable
        key="subTableTemplate"
        id="subTableTemplate"
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
