const isMatch = require("date-fns").isMatch;
const moment = require("moment");
const { isArray } = require("lodash");

function numberWithCommas(n) {
  var parts = n.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function numberWithCommasExt(n) {
  const value =
    n == null || n == undefined || n == isNaN(n) ? 0 : parseFloat(n).toFixed(1);
  var parts = value.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return result;
}

function numberWithCommasNegative(n) {
  var parts = n.toString().split(".");
  const result =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "");
  return n < 0 ? "(" + result.replace("-", "") + ")" : result;
}

function getSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function getSortOrderDesc(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function isValidDate(date, needConvert) {
  try {
    if (date === null || date === undefined || date.trim() === "") {
      return false;
    }
    if (needConvert) {
      date = moment(date).format("MM/DD/YYYY");
    }
    var dateRegex =
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/((19|20)\d{2}|(29)\d{2})$/;
    if (!dateRegex.test(date)) {
      //validate enough number of MM/DD/YYYY
      return false;
    }
    if (!moment(date, "MM/DD/YYYY", true).isValid()) {
      return false;
    }
    if (isMatch(date, "MM/dd/yyyy") !== true) {
      //validate Feb, leap year
      return false;
    }
    return true;
  } catch (err) {
    console.log(err.message);
    return false;
  }
}

function isValidEmail(email) {
  if (email && email.trim() !== "") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    // if (!pattern.test(email)) {
    // return "Please enter valid email address.";
    // }
  }
  return "";
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function replaceAll(str, match, replacement) {
  return str.replace(new RegExp(escapeRegExp(match), "g"), () => replacement);
}

function updateCalculatedFieldQualityData(numberFields, f, Equation) {
  let validValue = 0;
  try {
    let defaultValue = f.defaultValue;
    if (numberFields && numberFields.length > 0) {
      numberFields.map((n) => {
        defaultValue = replaceAll(defaultValue, n.name, n.defaultValue);
      });
    }
    const calculatedValue = Equation.solve(defaultValue);
    console.log(
      `${f.name}--defaultValue=${defaultValue}---calculatedValue=${calculatedValue}`
    );
    if (isNaN(calculatedValue) || calculatedValue == `Infinity`) {
      const error = `Error on calculating calculated field "${f.name}". Equation=${f.defaultValue}`;
      console.log(error);
      window.showAlert(
        "Error",
        `${error}. Please double check equation setup on field setting.`,
        "error"
      );
    } else {
      validValue = calculatedValue;
    }
  } catch (err) {
    console.log(err);
    const error = `Error on calculating calculated field "${f.name}". Equation=${f.defaultValue}`;
    console.log(error);
    window.showAlert(
      "Error",
      `${error}. Please double check equation setup on field setting.`,
      "error"
    );
    validValue = 0;
  }
  return validValue;
}

function updateCalculatedField(numberFields, data, f, Equation) {
  let validValue = 0;
  try {
    let defaultValue = f.defaultValue;
    //Replace static field values
    defaultValue = replaceAll(defaultValue, `Net Weight`, data.netWeight);
    //If XPool, label Net Weight will change to Process Weight
    defaultValue = replaceAll(defaultValue, `Process Weight`, data.netWeight);
    defaultValue = replaceAll(defaultValue, `XPool Weight`, data.xpoolWeight);
    defaultValue = replaceAll(
      defaultValue,
      `XPool Percent`,
      isNaN(data.xpoolPercent) ? 0 : data.xpoolPercent / 100.0
    );
    defaultValue = replaceAll(
      defaultValue,
      `Regular Weight`,
      data.regularWeight
    );
    defaultValue = replaceAll(
      defaultValue,
      `Regular Percent`,
      isNaN(data.regularPercent) ? 0 : data.regularPercent / 100.0
    );
    //Replace dynamic field values
    if (numberFields && numberFields.length > 0) {
      numberFields.map((n) => {
        defaultValue = replaceAll(defaultValue, n.name, data[n._id]);
      });
    }
    const calculatedValue = Equation.solve(defaultValue);
    if (isNaN(calculatedValue) || calculatedValue == `Infinity`) {
      const error = `Error on calculating calculated field "${f.name}". Equation=${f.defaultValue}`;
      console.log(error);
      window.showAlert(
        "Error",
        `${error}. Please double check equation setup on field setting.`,
        "error"
      );
    } else {
      validValue = calculatedValue;
    }
  } catch (err) {
    console.log(err);
    const error = `Error on calculating calculated field "${f.name}". Equation=${f.defaultValue}`;
    console.log(error);
    window.showAlert(
      "Error",
      `${error}. Please double check equation setup on field setting.`,
      "error"
    );
    validValue = 0;
  }
  return validValue;
}

function handleCombineFields(selectedFields, allFields) {
  return selectedFields?.map((item) => {
    const newFields = allFields?.filter(({ _id }) => {
      return _id === item.testFieldId;
    });

    if (isArray(newFields) && newFields.length > 0) {
      return Object.assign({}, item, newFields[0]);
    }
  });
}

function formatBytes(bytes, decimals, binaryUnits) {
  if (bytes == 0) {
    return "0 Bytes";
  }
  var unitMultiple = binaryUnits ? 1024 : 1000;
  var unitNames =
    unitMultiple === 1024 // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
      ? ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      : ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  return (
    parseFloat(
      (bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)
    ) +
    " " +
    unitNames[unitChanges]
  );
}

function checkDuplicateMetaAttribute(
  testType,
  newCustomAttributes,
  duplicatedItems
) {
  let count = duplicatedItems ? duplicatedItems.length : 0;
  if (
    count > 0 &&
    (testType.multiPricesSameLot === true ||
      testType.singlePricePerLot === true ||
      testType.varietyOptional === true ||
      testType.taxFeeByMeta === true)
  ) {
    if (newCustomAttributes.length > 0) {
      duplicatedItems.map((x) => {
        let isDuplicatedAttribute = false;

        const arrCustomAttributes =
          x.customAttributes === undefined ||
          x.customAttributes === null ||
          x.customAttributes === ``
            ? []
            : JSON.parse(x.customAttributes);

        if (arrCustomAttributes && arrCustomAttributes.length > 0) {
          let countDuplicate = 0;
          newCustomAttributes.map((a) => {
            if (
              arrCustomAttributes.filter((k) => {
                return k.value == a.value && k.attribute == a.attribute;
              }).length > 0
            ) {
              countDuplicate++;
            }
          });
          if (
            countDuplicate === newCustomAttributes.length &&
            countDuplicate === arrCustomAttributes.length
          ) {
            isDuplicatedAttribute = true;
          }
        }
        if (isDuplicatedAttribute !== true) {
          count--;
        }
      });
    } else {
      duplicatedItems.map((x) => {
        let isDuplicatedAttribute = false;
        const arrCustomAttributes =
          x.customAttributes === undefined ||
          x.customAttributes === null ||
          x.customAttributes === ``
            ? []
            : JSON.parse(x.customAttributes);

        if (newCustomAttributes.length === arrCustomAttributes.length) {
          isDuplicatedAttribute = true;
        }
        if (isDuplicatedAttribute !== true) {
          count--;
        }
      });
    }
  }

  if (count > 0) {
    return `Setting duplicate. Please try with other.`;
  }

  return ``;
}

function extractGrowerInfo(loggedUser) {
  let processorId = "";
  let viewingGrowerId = "";
  if (loggedUser.role === "Growers") {
    if (loggedUser.isManager === true) {
      viewingGrowerId = loggedUser.viewingGrowerId;
      processorId = loggedUser.viewingProcessorId;
    } else {
      viewingGrowerId = loggedUser.referenceUserId;
      processorId = loggedUser.processorId;
    }
  } else {
    processorId = loggedUser.processorId;
  }
  return { processorId: processorId, viewingGrowerId: viewingGrowerId };
}

function calculateTotalPage(totalRows, pageSize) {
  if (pageSize >= totalRows) {
    return 1;
  }
  let pageNumber = parseInt(totalRows / pageSize);
  if (pageNumber * pageSize < totalRows) {
    pageNumber += 1;
  }
  return pageNumber;
}

function compareJson(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function checkOverlap(items, min, end) {
  if (items && items.length > 0) {
    let isOverlap = "";
    for (let i = 0; i < items.length; i++) {
      let s = items[i].min;
      let e = items[i].max;
      if (
        (end >= s && end <= e) ||
        (min >= s && min <= e) ||
        (min <= s && end >= e)
      ) {
        isOverlap = s + " - " + e;
        break;
      }
    }
    return isOverlap;
  }
  return "";
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

const isObject = (object) => {
  return object != null && typeof object === "object";
};

function extractTestDate(inputDate) {
  let testDate;
  if (!inputDate || inputDate.trim().length <= 0) {
    return { error: `Test Date is required`, testDate: `` };
  } else {
    try {
      const arrDate = inputDate.includes(`-`)
        ? inputDate.split(`-`)
        : inputDate.split(`/`);
      if (!arrDate || arrDate.length !== 3) {
        return {
          error: `Test Date ${inputDate} is invalid date format.`,
        };
      } else {
        //2023-01-31T16:59:59.000Z
        let year, month, date;
        if (inputDate.includes(`-`)) {
          year = parseInt(arrDate[0]);
          month = parseInt(arrDate[1]) - 1; //Note: January is 0, February is 1, and so on.
          date = parseInt(arrDate[2]);
        } else {
          //01/31/2023
          year = parseInt(arrDate[2]);
          month = parseInt(arrDate[0]) - 1; //Note: January is 0, February is 1, and so on.
          date = parseInt(arrDate[1]);
        }

        testDate = new Date(year, month, date, 12, 0, 0);
        const dateParsed = moment(testDate);
        if (!dateParsed.isValid()) {
          return {
            error: `Test Date ${inputDate} is invalid date format.`,
          };
        }
      }
    } catch (error) {
      return {
        error: `There is error while parsing Test Date with value ${inputDate}. ${error.message}`,
      };
    }
    return { testDate: testDate };
  }
}

function getTestDate4Edit(testDate) {
  try {
    const result = extractTestDate(testDate);
    if (result.error) {
      return new Date();
    }
    return result.testDate;
  } catch (err) {
    return new Date();
  }
}

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function checkValidEmailList(emailInput) {
  const emailList = emailInput.split(",").map((email) => email.trim());

  const validEmailList = [];
  const invalidEmailList = [];

  emailList.forEach((email) => {
    if (validateEmail(email)) {
      validEmailList.push(email);
    } else {
      invalidEmailList.push(email);
    }
  });

  return {
    validEmails: validEmailList,
    invalidEmails: invalidEmailList,
  };
}

const getStartOfDate = (date = undefined) => {
  const startOfDate = date && date instanceof Date ? date : new Date();
  startOfDate.setHours(0);
  startOfDate.setMinutes(0);
  startOfDate.setSeconds(0);
  startOfDate.setMilliseconds(0);
  return startOfDate;
};

const getEndOfDate = (date = undefined) => {
  const endOfDate = date && date instanceof Date ? date : new Date();
  endOfDate.setHours(23);
  endOfDate.setMinutes(59);
  endOfDate.setSeconds(59);
  endOfDate.setMilliseconds(999);
  return endOfDate;
};

exports.numberWithCommas = numberWithCommas;
exports.numberWithCommasExt = numberWithCommasExt;
exports.numberWithCommasNegative = numberWithCommasNegative;
exports.getSortOrder = getSortOrder;
exports.getSortOrderDesc = getSortOrderDesc;
exports.isValidDate = isValidDate;
exports.isValidEmail = isValidEmail;
exports.replaceAll = replaceAll;
exports.updateCalculatedFieldQualityData = updateCalculatedFieldQualityData;
exports.updateCalculatedField = updateCalculatedField;
exports.handleCombineFields = handleCombineFields;
exports.formatBytes = formatBytes;
exports.checkDuplicateMetaAttribute = checkDuplicateMetaAttribute;
exports.extractGrowerInfo = extractGrowerInfo;
exports.calculateTotalPage = calculateTotalPage;
exports.compareJson = compareJson;
exports.checkOverlap = checkOverlap;
exports.onlyUnique = onlyUnique;
exports.isDeepEqual = isDeepEqual;
exports.extractTestDate = extractTestDate;
exports.getTestDate4Edit = getTestDate4Edit;
exports.checkValidEmailList = checkValidEmailList;
exports.getStartOfDate = getStartOfDate;
exports.getEndOfDate = getEndOfDate;
