import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="option-dropdown-table-grower dropdown-option-color">
        <div className="icon-dropdown-table-grower">
          <i className={`fa fa-${this.props.icon} fa-lg`}></i>
        </div>
        <div className="title-dropdown-table-grower">{this.props.title}</div>
      </div>
    );
  }
}
export default App;
