import React from "react";
import { Redirect } from "react-router-dom";
import { getLoggedUser } from "../../context/auth";
import Permission from "../../Permission";
import Component from "./add";

let loggedUser = getLoggedUser();

class Page extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <Permission
        role={loggedUser.role}
        perform="test-fields"
        yes={() => <Component {...this.props} />}
        no={() => <Redirect to="/" />}
      />
    );
  }
}
export default Page;