import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
  }

  handleAlertByType = () => {
    switch (this.props.type) {
      case "success":
        return {
          className: "alert-message-success",
          title: this.props.title,
          icon: "check-circle",
        };
      case "error":
        return {
          className: "alert-message-error",
          title: this.props.title,
          icon: "times-circle",
        };

      default:
        return {
          className: "alert-message-info",
          title: this.props.title,
          icon: "exclamation-circle",
        };
    }
  };

  render() {
    const { className, title, icon } = this.handleAlertByType();

    return (
      <div className={`alert-message ${className}`}>
        <i className={`fa fa-${icon} alert-message-icon`} />
        <div className="alert-message-title">{title}</div>
      </div>
    );
  }
}
export default App;
