import React from "react";
import { getLoggedUser } from "../context/auth";
import HelpDocumentationMenu from "./Base/HelpDocumentationMenu";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { overrideLoadingStyle } from "./Utilities/Styles";

let loggedUser = {};

const ClassName = {
  1: ["nav-active"],
  2: ["nav-expanded", "nav-active"],
};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    //Handle active, expend menu parent based on selected menu item
    const activeRoute = this.props.navRoute;
    if (!activeRoute) return;
    try {
      let children = this.myRef.current.children;
      let arrChildren = Array.from(children);
      const className = ClassName[activeRoute.length];
      arrChildren.map((item) => {
        activeRoute.map((route, i) => {
          if (item.getAttribute("name") === route) {
            item.classList.toggle(className[i]);
          }
        });
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  navExpandHandler(e) {
    window.expandNavigation();
    const element = e.currentTarget;
    element.classList.toggle("nav-expanded");
  }

  render() {
    let pathName = window.location.pathname;
    return (
      <ul ref={this.myRef} className="nav nav-main">
        <li
          className="nav-parent"
          onClick={this.navExpandHandler}
          name="dashboard"
        >
          <a href="/">
            <i className="fa fa-home"></i>
            <span>Dashboards</span>
          </a>
          <ul className="nav nav-children">
            <li
              name="summary"
              className={
                pathName === "" || pathName === "/"
                  ? "nav-expanded nav-active"
                  : "nav-expanded"
              }
            >
              <a href="/" title="Summary">
                <i className="fa fa-signal fa-lg" aria-hidden="true"></i>
                <span>Summary</span>
              </a>
            </li>
            {loggedUser.titerInfo &&
            loggedUser.titerInfo.isAllowGrowerPayment === true ? (
              <li
                name="grower-payment-report"
                className={
                  pathName === process.env.REACT_APP_URL_GROWER_PAYMENT_REPORT
                    ? "nav-expanded nav-active"
                    : "nav-expanded"
                }
              >
                <a
                  href={process.env.REACT_APP_URL_GROWER_PAYMENT_REPORT}
                  title="Grower Payment Report"
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  <span>Grower Payment Report</span>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </li>

        <li
          className="nav-parent"
          onClick={this.navExpandHandler}
          name="grading-test"
        >
          <a>
            <i className="fa fa-file-code-o" aria-hidden="true"></i>
            <span>Grading & Tests</span>
          </a>
          {this.props.testTypesLoading && (
            <ul className="nav nav-children">
              <li>
                <a className="navigation-item-loading">
                  <ClipLoader
                    cssOverride={overrideLoadingStyle}
                    loading={this.props.testTypesLoading}
                  />
                  <div style={{ marginLeft: 7 }}>Loading...</div>
                </a>
              </li>
            </ul>
          )}
          {!this.props.testTypesLoading &&
          this.props.menuItemTestTypes &&
          this.props.menuItemTestTypes.length > 0 ? (
            <ul className="nav nav-children">
              {this.props.menuItemTestTypes.map((x) => {
                return (
                  <li
                    key={x._id}
                    className={
                      (pathName + window.location.search).indexOf(
                        "?type=" + x._id
                      ) !== -1
                        ? "nav-active"
                        : ""
                    }
                  >
                    <a
                      href={
                        process.env.REACT_APP_URL_WALNUT_TEST_LIST +
                        "?type=" +
                        x._id +
                        "&name=" +
                        x.name +
                        "&dynamic=" +
                        x.isDynamic
                      }
                      title={x.name}
                    >
                      <i className="fa fa-file-text-o" aria-hidden="true"></i>
                      <span>{x.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </li>
        <li
          className="nav-parent"
          onClick={this.navExpandHandler}
          name="grower-tools"
        >
          <a>
            <i className="fa fa-cogs" aria-hidden="true"></i>
            <span>Grower Tools</span>
          </a>
          <ul className="nav nav-children">
            {loggedUser.titerInfo &&
            loggedUser.titerInfo.isAllowGrowerPayment === true ? (
              <>
                <li
                  name="crop-years"
                  className={
                    pathName.startsWith(process.env.REACT_APP_URL_CROPYEAR_LIST)
                      ? "nav-expanded nav-active"
                      : "nav-expanded"
                  }
                >
                  <a
                    href={process.env.REACT_APP_URL_CROPYEAR_LIST}
                    title="Grower Payments"
                  >
                    <i className="fa fa-usd" aria-hidden="true"></i>
                    <span>Grower Payments</span>
                  </a>
                </li>
              </>
            ) : (
              ""
            )}

            <li
              name="growers"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_GROWER_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a href={process.env.REACT_APP_URL_GROWER_LIST} title="Growers">
                <i className="fa fa-users" aria-hidden="true"></i>
                <span>Manage Growers</span>
              </a>
            </li>

            {loggedUser.titerInfo &&
            loggedUser.titerInfo.isAllowGrowerPayment === true ? (
              <li
                name="attributes"
                className={
                  pathName.startsWith(process.env.REACT_APP_URL_ATTRIBUTE_LIST)
                    ? "nav-active"
                    : ""
                }
              >
                <a
                  href={process.env.REACT_APP_URL_ATTRIBUTE_LIST}
                  title="Manage Defects Attributes"
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>
                  <span>Manage Defects Attributes</span>
                </a>
              </li>
            ) : (
              ""
            )}
            <li
              name="revision-history"
              className={
                pathName.startsWith(
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST
                )
                  ? "nav-active"
                  : ""
              }
            >
              <a
                href={process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST}
                title="Grower Payments Audit Logs"
              >
                <i className="fa fa-history" aria-hidden="true"></i>
                <span>Grower Payments Audit Logs</span>
              </a>
            </li>
            {loggedUser.titerInfo &&
            loggedUser.titerInfo.isAllowDynamicTest === true ? (
              <>
                <li
                  name="test-types"
                  className={
                    pathName.startsWith(process.env.REACT_APP_URL_NUTTYPE_LIST)
                      ? "nav-active"
                      : ""
                  }
                >
                  <a
                    title="Test Types & Varieties"
                    href={process.env.REACT_APP_URL_NUTTYPE_LIST}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    <span>Test Types & Varieties</span>
                  </a>
                </li>
                <li
                  name="test-fields"
                  className={
                    pathName.startsWith(
                      process.env.REACT_APP_URL_TEST_FIELD_LIST
                    )
                      ? "nav-expanded nav-active"
                      : "nav-expanded"
                  }
                >
                  <a
                    href={process.env.REACT_APP_URL_TEST_FIELD_LIST}
                    title="Test Fields"
                  >
                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                    <span>Test Fields</span>
                  </a>
                </li>
                <li
                  name="classifications"
                  className={
                    pathName.startsWith(
                      process.env.REACT_APP_URL_CLASSIFICATION_LIST
                    )
                      ? "nav-expanded nav-active"
                      : "nav-expanded"
                  }
                >
                  <a
                    title="Classifications"
                    href={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    <span>Classifications</span>
                  </a>
                </li>
                <li
                  name="import-logs"
                  className={
                    pathName.startsWith(
                      process.env.REACT_APP_URL_IMPORT_LOG_LIST
                    )
                      ? "nav-expanded nav-active"
                      : "nav-expanded"
                  }
                >
                  <a
                    title="Import logs"
                    href={process.env.REACT_APP_URL_IMPORT_LOG_LIST}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    <span>Import Logs</span>
                  </a>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </li>
        <li className="nav-parent" onClick={this.navExpandHandler} name="users">
          <a>
            <i className="fa fa-users" aria-hidden="true"></i>
            <span>Users</span>
          </a>
          <ul className="nav nav-children">
            <li
              name="staffs"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_STAFF_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a href={process.env.REACT_APP_URL_STAFF_LIST} title="Staff">
                <i className="fa fa-user" aria-hidden="true"></i>
                <span>Staff</span>
              </a>
            </li>
            <li
              name="lab-users"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_LAB_USER_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a
                href={process.env.REACT_APP_URL_LAB_USER_LIST}
                title="Lab Users"
              >
                <i className="fa fa-user-md" aria-hidden="true"></i>
                <span>Lab Users</span>
              </a>
            </li>
            <li
              name="handlers"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_HANDLER_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a href={process.env.REACT_APP_URL_HANDLER_LIST} title="Handlers">
                <i className="fa fa-male" aria-hidden="true"></i>
                <span>Handlers</span>
              </a>
            </li>
          </ul>
        </li>
        <li
          className="nav-parent"
          onClick={this.navExpandHandler}
          name="account-settings"
        >
          <a>
            <i className="fa fa-cog" aria-hidden="true"></i>
            <span>Account Settings</span>
          </a>
          <ul className="nav nav-children">
            <li
              name="bill"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_BILL_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a
                href={process.env.REACT_APP_URL_BILL_LIST}
                title="Bill History"
              >
                <i className="fa fa-history" aria-hidden="true"></i>
                <span>Bill History</span>
              </a>
            </li>
            <li
              name="subscription"
              className={
                pathName.startsWith(process.env.REACT_APP_URL_SUBSCRIPTION_LIST)
                  ? "nav-active"
                  : ""
              }
            >
              <a
                href={process.env.REACT_APP_URL_SUBSCRIPTION_LIST}
                title="Manage Subscription"
              >
                <i className="fa fa-money" aria-hidden="true"></i>
                <span>Manage Subscription</span>
              </a>
            </li>
          </ul>
        </li>
        <HelpDocumentationMenu></HelpDocumentationMenu>
        <li
          name="mailbox"
          className={
            pathName.startsWith(
              this.props.GlobalConfig.REACT_APP_URL_MAILBOX_LIST
            )
              ? "nav-active"
              : ""
          }
        >
          <a
            title="Mailbox"
            href={this.props.GlobalConfig.REACT_APP_URL_MAILBOX_LIST}
          >
            {/* <span class="pull-right label label-primary">182</span> */}
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <span>Mailbox</span>
          </a>
        </li>
        {loggedUser.titerInfo &&
        loggedUser.titerInfo.isAllowExportApi === true ? (
          <li
            className={
              pathName.startsWith(process.env.REACT_APP_URL_DOCS_TEST_LIST)
                ? "nav-active"
                : ""
            }
          >
            <a
              href={process.env.REACT_APP_URL_DOCS_TEST_LIST}
              title="API Docs"
              target="_blank"
            >
              <i className="fa fa-question-circle" aria-hidden="true"></i>
              <span>API Docs</span>
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testTypesLoading: state.loading.testTypesLoading,
  };
};

export default connect(mapStateToProps)(App);
