import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import SaveButton from "../Controls/SaveButton";
import ResetPassword from "../Base/ResetPassword";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Lab Users",
      urllist: process.env.REACT_APP_URL_LAB_USER_LIST,
      urlapi:
        loggedUser.role == "Administrators"
          ? GlobalConfig.REACT_APP_API_LABUSER_URL
          : GlobalConfig.REACT_APP_API_LABUSER_URL + "itembyprocessor/",
      urlsubmitapi:
        (loggedUser.role == "Administrators"
          ? GlobalConfig.REACT_APP_API_LABUSER_URL
          : GlobalConfig.REACT_APP_API_INVITED_LAB_USER_URL) + "updateaccess/",
      imageMaxSize: parseInt(process.env.REACT_APP_MAX_SIZE_PROFILE_IMAGE),
      data: {
        _id: props.match.params.id,
        role: "",
        name: "",
        email: "",
        status: "",
        password: "",
        phone: "",
        companyName: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      loading: parentState.loading,
    };
  }

  getTitle = () => {
    return this.state.data.name == undefined || this.state.data.name == ""
      ? this.state.data.email
      : this.state.data.name;
  };

  render() {
    const title = "Lab User - " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Lab User - {this.getTitle()}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#overview" data-toggle="tab">
                          Lab User Profile
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="overview" className="tab-pane active">
                        <form
                          className="form-horizontal form-bordered"
                          onSubmit={this.handleSubmit}
                          id="form"
                        >
                          <h4 className="mb-xlg">Lab User Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Full Name
                              </label>
                              <div className="col-md-5">
                                <label className="control-label">
                                  {this.state.data.name}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <div className="col-md-5">
                                <label className="control-label">
                                  <a
                                    href={"mailto:" + this.state.data.email}
                                    title="Email"
                                  >
                                    {this.state.data.email}
                                  </a>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="phone"
                              >
                                Phone
                              </label>
                              <div className="col-md-5">
                                <label className="control-label">
                                  {this.state.data.phone}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="companyName"
                              >
                                Company Name
                              </label>
                              <div className="col-md-5">
                                <label className="control-label">
                                  {this.state.data.companyName}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="address"
                              >
                                Address
                              </label>
                              <div className="col-md-5">
                                <label className="control-label">
                                  {this.state.data.address}
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Account</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="status"
                              >
                                {loggedUser.role == "Administrators"
                                  ? "Portal Access Status"
                                  : "Test Access Status"}
                              </label>
                              <div className="col-md-8">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                >
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>
                          <div className="panel-footer">
                            <fieldset>
                              <div className="form-group">
                                <div className="col-md-12 profile-panel-footer-wrapper">
                                  {this.state.data.status === "Active" &&
                                    this.state.data.email && (
                                      <ResetPassword
                                        email={this.state.data.email}
                                      />
                                    )}
                                  <SaveButton
                                    loading={this.state.loading}
                                    propClassName="ml-0"
                                  />
                                  <a
                                    href={this.state.urllist}
                                    title="Back to list"
                                    className="btn btn-primary ml-0"
                                  >
                                    <i className="fa fa-backward fa-lg"></i>{" "}
                                    Back
                                  </a>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
