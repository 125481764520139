import React from "react";
import ListComponent from "../Base/ListComponent";
import CommonUtilities from "../Utilities/Common";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();

    this.state.urlapi = GlobalConfig.REACT_APP_API_TIER_URL;
    this.state.urllist = process.env.REACT_APP_URL_TIER_LIST;
    this.state.urladd = process.env.REACT_APP_URL_TIER_LIST + "-add";
    this.state.title = "Tier";
    this.state.pagination = false;

    this.state.pageName = "Tier";

    this.state.useFilter = false;
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Tier",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "# Processor Subscribed",
        selector: "totalProcessors",
        center: true,
        sortable: true,
      },
      {
        name: "Tests (Deliveries)",
        selector: "numberOfTestsDeliveries",
        center: true,
      },
      {
        name: "Allow Export to PDF",
        selector: "isExportToPdf",
        center: true,
        cell: (row) => (row.isExportToPdf === true ? "Yes" : "No"),
      },
      {
        name: "Allow Export API",
        selector: "isAllowExportApi",
        center: true,
        cell: (row) => (row.isAllowExportApi === true ? "Yes" : "No"),
      },
      {
        name: "Allow Import API",
        selector: "isAllowImportApi",
        center: true,
        cell: (row) => (row.isAllowImportApi === true ? "Yes" : "No"),
      },
      {
        name: "Allow Dynamic Test",
        selector: "isAllowDynamicTest",
        center: true,
        cell: (row) => (row.isAllowDynamicTest === true ? "Yes" : "No"),
      },
      {
        name: "Allow Grower Payment",
        selector: "isAllowGrowerPayment",
        center: true,
        cell: (row) => (row.isAllowGrowerPayment === true ? "Yes" : "No"),
      },
      {
        name: "Allow Dynamic Test",
        selector: "isAllowDynamicTest",
        center: true,
        cell: (row) => (row.isAllowDynamicTest === true ? "Yes" : "No"),
      },
      {
        name: "Total Monthly",
        selector: "totalMonthly",
        right: true,
        sortable: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.totalMonthly == null ||
              row.totalMonthly == undefined ||
              isNaN(row.totalMonthly)
              ? 0
              : row.totalMonthly
            ).toFixed(1)
          ),
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate ? (
      //       <DateFormat date={row.modifiedDate}></DateFormat>
      //     ) : (
      //       ""
      //     );
      //   },
      // },
      {
        name: "",
        cell: (row) => (
          <>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            {row.totalProcessors > 0 ? (
              ""
            ) : (
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };
}
export default App;
