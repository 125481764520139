import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class RequestUpdateGrower extends Component {
  constructor(props) {
    super();
    this.state = {
      responseMessage: "",
    };
  }

  componentDidMount() {
    const action = this.props.match.params.action;
    const growerTempId = this.props.match.params.growerTempId;
    const url = `${process.env.REACT_APP_API_URL}/apigrower/updateInformationByGrower/${action}/${growerTempId}`;
    fetch(url)
      .then((response) => response.json())
      .then((responseData) => {
        this.setState({
          responseMessage: responseData,
        });
      });
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>Request Update Grower</title>
        </Helmet>
        <section
          className="body-sign"
          style={{
            color: "white",
            paddingBottom: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
          }}
        >
          <div className="container">
            <div
              className="row center"
              style={{
                color: "white",
                paddingTop: "50px",
              }}
            >
              <a href="/" className="logo pull-left">
                <img
                  src="/assets/images/logo.png"
                  height="54"
                  alt="ParityFactory Grading"
                />
              </a>
            </div>
            <div className="row center">
              <h1>Request Update Grower Information</h1>
            </div>
            <div className="row">
              <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  marginTop: "20px",
                }}
              >
                {this.state.responseMessage}
              </p>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; ParityFactory All rights reserved |{" "}
              <a
                href="https://parityfactory.com/privacy-policy/"
                style={{ color: "white" }}
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </section>
      </div>
    );
  }
}
