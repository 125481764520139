import React from "react";
import CommonUtilities from "../Utilities/Common";
import DataTable from "react-data-table-component";
import { getLoggedUser } from "../../context/auth";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import { handleExportPdf } from "../GrowerStatement/export";
import ColumnOptionsMultitable from "../../Components/Base/ColumnOptionsMultitable";
import TableLoadingComponent from "./../Base/TableLoadingComponent";

var DateFormat = require("dateformat");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();
class App extends React.Component {
  constructor(props) {
    super();
    const currentDate = parseInt(DateFormat(new Date(), "yyyymmdd"));
    loggedUser = getLoggedUser();

    this.state = {
      items: [],
      originalItems: [],
      currentDate: currentDate,
      urlApiGrower:
        GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL +
        "growersbycropyear/" +
        props.cropYearId,
      filterGrower: "",
      cropYearId: props.cropYearId,
      paginationPerPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      rowPerPageOption: GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION,

      pageName: "Grower Payment Report",
      btnColumnOptions: false,
      hideColumns: [],
      dataCheck: false,
      userSettingUrlApi: GlobalConfig.REACT_APP_API_USERSETTING_URL,
      data: {
        pageName: "",
      },
    };
  }

  setCropYearId(cropYearId) {
    this.setState({ cropYearId: cropYearId });
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      function callBack() {
        this.filterItems();
      }
    );
  };

  filterItems = () => {
    if (this.state.originalItems) {
      const growerId = this.state.filterGrower;

      if (growerId == null || growerId == undefined || growerId == "") {
        this.setState({ items: this.state.originalItems });
        return;
      }

      const newItems = [];
      this.state.originalItems.filter(function (memItem) {
        if (
          growerId == null ||
          growerId == undefined ||
          growerId == "" ||
          memItem.growerId == growerId
        ) {
          newItems.push(memItem);
          return memItem;
        }
      });
      this.setState({ items: newItems });
    }
  };

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
    this.loadColumnSettings();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.cropYearId !== this.props.cropYearId) {
      this.setState({
        urlApiGrower: `${GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL}growersbycropyear/${this.props.cropYearId}`,
      });
    }
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  bindData(data, cropYearId) {
    this.setState({ items: data, originalItems: data, cropYearId: cropYearId });
    if (this.growerControl) {
      this.growerControl.setSelected("");
    }
  }

  formatStatus = (row) => {
    if (row.status == "Preview") {
      return (
        <span
          className="label label-primary"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    if (row.status == "Paid") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.status}
      </span>
    );
  };

  loadColumnSettings = () => {
    this.getItemByPageName(
      this.props.titlePage.replaceAll(" ", "") + loggedUser.referenceUserId,
      "table" +
      this.props.titlePage.replaceAll(" ", "") +
      loggedUser.referenceUserId,
      loggedUser.referenceUserId
    );
  };

  columnOptionOpening = () => {
    this.setState({ btnColumnOptions: true });
    this.getItemByPageName(
      this.props.titlePage.replaceAll(" ", "") + loggedUser.referenceUserId,
      "table" +
      this.props.titlePage.replaceAll(" ", "") +
      loggedUser.referenceUserId,
      loggedUser.referenceUserId
    );
  };

  columnOptionClosed = () => {
    this.setState({ btnColumnOptions: false });
  };

  columnOptionReset = () => {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          pageName: this.props.titlePage.replaceAll(" ", ""),
          value: "",
        }),
        hideColumns: [],
      },
      () => {
        try {
          const that = this;
          const requestOptions = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          fetch(
            this.state.userSettingUrlApi +
            this.props.titlePage.replaceAll(" ", "") +
            loggedUser.referenceUserId +
            "/" +
            "table" +
            this.props.titlePage.replaceAll(" ", "") +
            loggedUser.referenceUserId +
            "/" +
            loggedUser.referenceUserId,
            requestOptions
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If updated successful
              if (resultObject && resultObject.result === "OK") {
                that.setState({
                  alertshow: false,
                });
                window.showAlert("", "Save successful!", "");

                that.doSomethingAfterSaved(resultObject);
              }
              //If failed, show error
              else {
                that.setState({
                  alertshow: false,
                });
                that.setState({
                  alertmsg: resultObject.message,
                  alerttype: "error",
                  alertshow: true,
                });
              }
            })
            .catch(function (error) {
              that.setState({
                alertshow: false,
              });
              that.setState({
                alertmsg: error,
                alerttype: "error",
                alertshow: true,
              });
            });
        } catch (err) {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: err.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      }
    );
  };

  getItemByPageName = async (pageName, objectName, userId) => {
    this.setState({
      alertmsg: "Query data...",
      alerttype: "info",
      alertshow: true,
    });
    try {
      var url =
        this.state.userSettingUrlApi +
        pageName +
        "/" +
        objectName +
        "/" +
        userId;
      const that = this;
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result == "OK") {
            if (resultObject.data) {
              that.setState({
                data: Object.assign({}, that.state.data, {
                  value: resultObject.data.value.split(","),
                }),
                hideColumns: resultObject.data.value.split(","),
                dataCheck: true,
              });

              //Turn-off message
              that.setState({
                alertshow: false,
              });
            } else {
              //Turn-off message
              that.setState({
                alertshow: false,
              });
            }
          } else {
            that.setState({
              alertshow: false,
            });
            that.setState({
              alertmsg: resultObject.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  columnOptionChanged = (event) => {
    var index = this.state.hideColumns.indexOf(event.target.name);

    this.state.hideColumns.indexOf(event.target.name) !== -1
      ? this.state.hideColumns.splice(index, 1)
      : this.state.hideColumns.push(event.target.name);

    this.state.dataCheck === false
      ? this.setState(
        {
          data: Object.assign({}, this.state.data, {
            pageName:
              this.props.titlePage.replaceAll(" ", "") +
              loggedUser.referenceUserId,
            objectName:
              "table" +
              this.props.titlePage.replaceAll(" ", "") +
              loggedUser.referenceUserId,
            userId: loggedUser.referenceUserId,
            value: this.state.hideColumns.toString(),
          }),
          dataCheck: true,
        },
        () => {
          try {
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(this.state.data),
            };
            const that = this;
            fetch(this.state.userSettingUrlApi, requestOptions)
              .then(function (response) {
                return response.json();
              })
              .then(function (resultObject) {
                //If created successful
                if (resultObject && resultObject.result === "OK") {
                  that.doSomethingAfterSaved(resultObject.data);
                  if (
                    that.state.isShowSaveSuccessfulMessage == true ||
                    that.state.isShowSaveSuccessfulMessage === undefined ||
                    that.state.isShowSaveSuccessfulMessage == null
                  ) {
                    window.showAlert("", "Save successful!", "");
                  }
                }
                //If failed, show error
                else {
                  that.doSomethingAfterErrorSaved(resultObject);
                }
              })
              .catch(function (error) {
                window.showAlert("Error", error, "Error");
              });
          } catch (err) {
            window.showAlert("Error", err.message, "Error");
          }
        }
      )
      : this.setState(
        {
          data: Object.assign({}, this.state.data, {
            value: this.state.hideColumns.toString(),
          }),
        },
        () => {
          try {
            const requestOptions = {
              method: "PATCH",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(this.state.data),
            };
            const that = this;
            fetch(
              this.state.userSettingUrlApi +
              this.props.titlePage.replaceAll(" ", "") +
              loggedUser.referenceUserId +
              "/" +
              "table" +
              this.props.titlePage.replaceAll(" ", "") +
              loggedUser.referenceUserId +
              "/" +
              loggedUser.referenceUserId,
              requestOptions
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (resultObject) {
                //If updated successful
                if (resultObject && resultObject.result === "OK") {
                  that.setState({
                    alertshow: false,
                  });
                  window.showAlert("", "Save successful!", "");

                  that.doSomethingAfterSaved(resultObject);
                }
                //If failed, show error
                else {
                  that.setState({
                    alertshow: false,
                  });
                  that.setState({
                    alertmsg: resultObject.message,
                    alerttype: "error",
                    alertshow: true,
                  });
                }
              })
              .catch(function (error) {
                that.setState({
                  alertshow: false,
                });
                that.setState({
                  alertmsg: error,
                  alerttype: "error",
                  alertshow: true,
                });
              });
          } catch (err) {
            this.setState({
              alertshow: false,
            });
            this.setState({
              alertmsg: err.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        }
      );
  };

  doSomethingAfterSaved = (data) => { };

  doSomethingAfterErrorSaved(res) {
    window.showAlert("Error", res.message, "Error");
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Grower",
        selector: "growerName",
        sortable: true,
        cell: (row) => {
          return (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.growerName}
            >
              {row.growerName}
            </a>
          );
        },
      },
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.props.testType.paymentByVariety !== true
      },
      {
        name: "No",
        selector: "paymentNo",
        sortable: true,
        center: true,
      },
      {
        name: "Due Date",
        selector: "paymentDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.paymentDate, "mm/dd/yyyy");
        },
      },
      {
        name: "Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        cell: (row) => {
          return (
            CommonUtilities.numberWithCommas(
              (row.paymentPercent == null ||
                row.paymentPercent == undefined ||
                isNaN(row.paymentPercent)
                ? 0
                : parseFloat(row.paymentPercent)
              ).toFixed(1)
            ) + "%"
          );
        },
      },
      {
        name: "Amount",
        selector: "payment",
        sortable: true,
        right: true,
        cell: (row) => {
          return row.payment < 0
            ? "$(" +
            CommonUtilities.numberWithCommas(
              parseFloat(Math.abs(row.payment)).toFixed(2)
            ) +
            ")"
            : "$" +
            CommonUtilities.numberWithCommas(
              parseFloat(row.payment).toFixed(2)
            );
        },
      },
      {
        name: "",
        selector: "",
        center: true,
        cell: (row) => {
          return (
            <a
              href="# "
              variant="link"
              className="on-default remove-row"
              data-growerid={row.growerId}
              data-cropyearid={this.state.cropYearId}
              data-paymentid={row.paymentId}
              data-varietyid={row.varietyId}
              data-action="preview"
              onClick={handleExportPdf}
              title="View statement"
            >
              View Statement
            </a>
          );
        },
      },
    ];
  };

  render() {
    return (
      <>
        {this.state.originalItems && this.state.originalItems.length > 0 ? (
          <div className="tableWithColumnChooser row">
            <div className="col-md-12 col-lg-4 form-group selectCropYearDashboardContainer">
              <label
                className="control-label labelCropYearDashboard"
                htmlFor="filterGrower"
              >
                Grower
              </label>
              <div className="selectCropYearDashboard">
                <FilterSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.growerControl = ref)}
                  placeholder=""
                  label="Filter Grower"
                  controlId="filterGrower"
                  urlapilist={this.state.urlApiGrower}
                />
              </div>
            </div>

            <ColumnOptionsMultitable
              btnColumnOptions={this.state.btnColumnOptions}
              columnOptionOpening={this.columnOptionOpening}
              columnOptionClosed={this.columnOptionClosed}
              defaultColumns={this.defaultColumns()}
              titlePage={this.props.titlePage}
              hideColumns={this.state.hideColumns}
              columnOptionChanged={this.columnOptionChanged}
              columnOptionReset={this.columnOptionReset}
            />
          </div>
        ) : (
          ""
        )}

        <div className="contentDataTable">
          <DataTable
            highlightOnHover
            striped
            title=""
            noHeader={true}
            columns={this.setColumns()}
            data={this.state.items}
            className="table table-bordered table-striped mb-none"
            pagination={true}
            paginationPerPage={this.state.paginationPerPage}
            paginationRowsPerPageOptions={this.state.rowPerPageOption}
            noTableHead={false}
            progressPending={this.props.progressPending}
            progressComponent={
              <TableLoadingComponent
                loading={this.props.progressPending}
              ></TableLoadingComponent>
            }
          />
        </div>
      </>
    );
  }
}
export default App;
