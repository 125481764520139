import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";
import jwt_decode from "jwt-decode";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_PROCESSOR,
} from "./Components/Utilities/Constant";
import { getStartOfDate, getEndOfDate } from "./Components/Utilities/Common";

var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();

function PrivateRoute({
  component: Component,
  onAccess,
  roles,
  navRoute,
  ...rest
}) {
  let authTokensJSON;
  const { authTokens } = useAuth();
  let isExpired = false;
  const now = new Date();
  if (authTokens) {
    authTokensJSON = jwt_decode(authTokens);
    if (now.getTime() > authTokensJSON.expiry) {
      isExpired = true;
      localStorage.removeItem("tokens");
      localStorage.removeItem("isActive");
    } else {
      //refresh expiry
      const now = new Date();
      const expiry = new Date(
        now.getTime() +
          parseInt(GlobalConfig.REACT_APP_LOGIN_EXPIRY) * 60 * 1000
      ); // convert n minutes to milliseconds
      authTokensJSON.expiry = expiry.getTime();

      const token = jwt.sign(
        authTokensJSON,
        process.env.REACT_APP_TOKEN_SECRET
      );
      localStorage.setItem("tokens", JSON.stringify(token));
    }
  }

  const accessComponent = (props) => {
    if (
      typeof onAccess === `function` &&
      authTokensJSON.role !== USER_ROLE_ADMINISTRATOR
    ) {
      const { pathname, search } = window.location;
      const payload = {
        userId: authTokensJSON.referenceUserId,
        startOfDay: getStartOfDate(),
        endOfDay: getEndOfDate(),
        processorId:
          authTokensJSON.role !== USER_ROLE_PROCESSOR
            ? authTokensJSON.processorId
            : authTokensJSON.referenceUserId,
        log: [{ pageUrl: pathname + search }],
      };
      onAccess(payload);
    }
    return <Component {...props} navRoute={navRoute} />;
  };

  const render = (props) => {
    if (!authTokens || isExpired) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { referer: props.location } }}
        />
      );
    }
    return accessComponent(props);
  };

  return <Route {...rest} render={render} />;
}

export default PrivateRoute;
