import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import Switch from "react-switch";
import TableOwner from "./TableOwner";
import CommonUtilities from "../Utilities/Common";
import MultiSelectTestType from "./MultiSelectTestType";
import SaveButton from "../Controls/SaveButton";
import SelectMultiGrowers from "../Grower/SelectMultiGrowers";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { VALUE_QUERY_PARAM_IGNORE } from "./../Utilities/Constant";
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      title: "Grower",
      listtitle: "Growers",
      urllist: process.env.REACT_APP_URL_GROWER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_GROWER_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        contactName: "",
        contactEmail: "",
        address: "",
        city: "",
        state: "",
        region: "",
        note: "",
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
        processorId: loggedUser.processorId,
        isManager: false,
        isSendInvite: false,
        isMultiOwner: false,
        owners: "",
        ownGrowerIds: "",
        status: "Active",
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        testTypeId: "",
      },
      errors: {
        email: "",
      },
      loading: parentState.loading,
      selectedGrowers: [],
      growers: [],
    };
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.handleChangeSwitchIsManager =
      this.handleChangeSwitchIsManager.bind(this);
    this.handleChangeMultiOwnerSwitch =
      this.handleChangeMultiOwnerSwitch.bind(this);
    this.handleTableOwnerChange = this.handleTableOwnerChange.bind(this);
    this.onChangeSelectedGrowers = this.onChangeSelectedGrowers.bind(this);
  }

  componentDidUpdate = () => {
    this.getGrowers();
    // if user change data
    if (this.state.isChanged) {
      // warn user will lose the changes
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  };

  async getGrowers() {
    const result = await fetch(
      `${GlobalConfig.REACT_APP_API_GROWER_URL}byProcessorWithExclude/${this.state.data.processorId}`
    );
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      const growers = response.data.map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
        };
      });
      this.setState({
        growers: growers,
      });
    }
  }

  setTestTypeIds = (selectedTestTypes) => {
    const ids = selectedTestTypes
      ? selectedTestTypes.map(({ value }) => value).join(`,`)
      : "";
    this.setState({
      data: { ...this.state.data, testTypeId: ids },
    });
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        contactName: "",
        contactEmail: "",
        address: "",
        city: "",
        state: "",
        region: "",
        note: "",
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
        processorId: loggedUser.processorId,
        isManager: false,
        isSendInvite: false,
        isMultiOwner: false,
        owners: "",
        ownGrowerIds: "",
        status: "Active",
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        testTypeId: "",
      },
      errors: {
        email: "",
      },
    });
    //Reset select
    this.reset.resetSelect();
    //Reset owners
    if (this.owners) {
      this.owners.bindData("");
    }
  };

  getTitle = () => {
    return this.state.title;
  };

  handleChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isSendInvite: checked,
      }),
    });
  }

  handleChangeSwitchIsManager(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isManager: checked,
      }),
    });
  }

  handleChangeMultiOwnerSwitch(checked) {
    let owners = this.state.data.owners;
    if (checked != true) {
      //Set data table owners
      owners = "";
      //Set data table owners
      this.owners.bindData("");
    }
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isMultiOwner: checked,
        owners: owners,
      }),
    });
  }

  handleTableOwnerChange = (name, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [name]: value,
      }),
    });
  };

  onChangeSelectedGrowers(growers) {
    let growerIds = ``;
    if (growers && growers.length > 0) {
      growerIds = growers.map((x) => x.value).join(`,`);
    }
    this.setState({
      selectedGrowers: growers,
      data: Object.assign({}, this.state.data, {
        ownGrowerIds: growerIds,
      }),
    });
  }

  checkSomethingBeforeSave = () => {
    if (
      this.state.data.isSendInvite === true ||
      this.state.data.isManager === true
    ) {
      const isValidEmail = CommonUtilities.isValidEmail(
        this.state.data.contactEmail
      );
      this.setState({
        errors: Object.assign({}, this.state.errors, {
          email: isValidEmail,
        }),
      });
      if (isValidEmail && isValidEmail !== "") {
        this.email.focus();
        return false;
      }
    }
    if (this.state.data.isManager === true) {
      if (
        this.state.data.ownGrowerIds === null ||
        this.state.data.ownGrowerIds === undefined ||
        this.state.data.ownGrowerIds === ``
      ) {
        window.showAlert(
          "Error",
          "Please select at least one grower under Own Growers.",
          "error"
        );
        return false;
      }
    }

    if (this.state.data.isMultiOwner === true) {
      if (
        this.state.data.owners === undefined ||
        this.state.data.owners === null ||
        this.state.data.owners.trim() === ""
      ) {
        window.showAlert(
          "Error",
          "Owner cannot be empty. Please click on button <strong>Add Owner</strong> to continue.",
          "error"
        );
        return false;
      }

      let isValid = false;
      let ownerItems = JSON.parse(this.state.data.owners);
      if (ownerItems && ownerItems.length > 0) {
        let totalOwnership = 0;
        ownerItems.map((u) => {
          totalOwnership += u.ownership;
        });
        if (totalOwnership === 100) {
          isValid = true;
        }
      }
      if (!isValid) {
        window.showAlert(
          "Error",
          "Total ownership of owners must be 100%. Please click on button <strong>Add Owner</strong> to continue.",
          "error"
        );
        return false;
      }
      return true;
    }
    return true;
  };

  render() {
    const title = "Create new " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <Tabs
                    headerStyle={{ fontWeight: "bold", cursor: "pointer" }}
                    activeHeaderStyle={{ color: "#1891E5" }}
                    selected={0}
                  >
                    <Tab
                      headerClass="tab-pane"
                      activeHeaderClass="tab-pane active"
                      eventKey="overview"
                      label="Grower Information"
                    >
                      <form
                        className="form-horizontal form-bordered"
                        onSubmit={this.handleSubmit}
                        id="form"
                      >
                        <h4 className="mb-xlg">Grower Information</h4>
                        <fieldset>
                          <div className="form-group">
                            <label
                              className="col-md-2 control-label"
                              htmlFor="name"
                            >
                              Grower Name <span className="required">(*)</span>
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                name="name"
                                autoFocus={true}
                                className="form-control"
                                placeholder=""
                                required
                                value={this.state.data.name}
                                onChange={this.handleChange}
                                maxLength={100}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              Test Type Assign{" "}
                            </label>
                            <div className="col-md-4">
                              <MultiSelectTestType
                                urlapi={`${GlobalConfig.REACT_APP_API_NUTTYPE_URL}byprocessor/${loggedUser.processorId}/${VALUE_QUERY_PARAM_IGNORE}/-1/-1`}
                                setTestTypeIds={this.setTestTypeIds}
                                processorId={loggedUser.processorId}
                                onRef={(ref) => (this.reset = ref)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              Address{" "}
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                name="address"
                                className="form-control"
                                value={this.state.data.address}
                                onChange={this.handleChange}
                                maxLength={500}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              City{" "}
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                value={this.state.data.city}
                                onChange={this.handleChange}
                                maxLength={500}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              State{" "}
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                name="state"
                                className="form-control"
                                value={this.state.data.state}
                                onChange={this.handleChange}
                                maxLength={500}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              Region{" "}
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                name="region"
                                className="form-control"
                                value={this.state.data.region}
                                onChange={this.handleChange}
                                maxLength={500}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-md-2 control-label">
                              Note{" "}
                            </label>
                            <div className="col-md-4">
                              <textarea
                                name="note"
                                className="form-control"
                                value={this.state.data.note}
                                onChange={this.handleChange}
                                rows={5}
                                maxLength={2000}
                              ></textarea>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="control-label col-md-2">
                              Is Manager
                            </label>
                            <div className="col-md-4">
                              <Switch
                                name="isManager"
                                onChange={this.handleChangeSwitchIsManager}
                                checked={this.state.data.isManager}
                                className="react-switch"
                              />
                            </div>
                          </div>
                          {this.state.data.isManager === true ? (
                            <div className="form-group">
                              <label
                                className="col-md-2 control-label"
                                htmlFor="attribute"
                              >
                                Own Growers{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-10">
                                <SelectMultiGrowers
                                  externalSource={true}
                                  source={this.state.growers}
                                  selectedGrowers={this.state.selectedGrowers}
                                  onChangeSelectedGrowers={
                                    this.onChangeSelectedGrowers
                                  }
                                  placeholder="Select growers"
                                  withoutLabel={false}
                                  onRef={(ref) => (this.ownGrowerIds = ref)}
                                ></SelectMultiGrowers>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <label className="control-label col-md-2">
                              Invite Grower to see their data
                            </label>
                            <div className="col-md-10">
                              <Switch
                                name="isSendInvite"
                                onChange={this.handleChangeSwitch}
                                checked={this.state.data.isSendInvite}
                                className="react-switch"
                              />
                              <p>
                                You can add a grower without granting them
                                access to their test data. Or, if you select
                                “Invite”, they will be able to login and view
                                each test along with basic reports on their
                                test. They will be able to see only their own
                                data.
                              </p>
                            </div>
                          </div>
                          {this.state.data.isSendInvite === true ||
                          this.state.data.isManager === true ? (
                            <>
                              <div className="form-group">
                                <label
                                  className="col-md-2 control-label"
                                  htmlFor="contactName"
                                >
                                  Contact Name{" "}
                                  <span className="required">(*)</span>
                                </label>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    name="contactName"
                                    className="form-control"
                                    placeholder=""
                                    required
                                    value={this.state.data.contactName}
                                    onChange={this.handleChange}
                                    maxLength={100}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="col-md-2 control-label"
                                  htmlFor="contactEmail"
                                >
                                  Contact Email{" "}
                                  <span className="required">(*)</span>
                                </label>
                                <div className="col-md-8">
                                  <input
                                    type="email"
                                    name="contactEmail"
                                    className="form-control"
                                    placeholder=""
                                    required
                                    value={this.state.data.contactEmail}
                                    onChange={this.handleChange}
                                    maxLength={100}
                                    ref={(input) => {
                                      this.email = input;
                                    }}
                                  />
                                  {this.state.errors.email &&
                                  this.state.errors.email !== "" ? (
                                    <label style={{ color: "#d2322d" }}>
                                      {this.state.errors.email}
                                    </label>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="form-group">
                            <label className="control-label col-md-2">
                              Multiple Owners?
                            </label>
                            <div className="col-md-9">
                              <Switch
                                name="isMultiOwner"
                                onChange={this.handleChangeMultiOwnerSwitch}
                                checked={this.state.data.isMultiOwner}
                                className="react-switch"
                              />
                              {this.state.data.isMultiOwner ? (
                                <p>
                                  You need add owner and total percent of
                                  ownership must be 100%.
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {this.state.data.isMultiOwner ? (
                            <TableOwner
                              value={this.state.data.owners}
                              name="owners"
                              onRef={(ref) => (this.owners = ref)}
                              handleTableOwnerChange={
                                this.handleTableOwnerChange
                              }
                            />
                          ) : (
                            ""
                          )}
                        </fieldset>
                        {!this.state.data.isSendInvite ? (
                          ""
                        ) : (
                          <>
                            <hr className="dotted tall"></hr>
                            <h4 className="mb-xlg">Account</h4>
                            <fieldset className="mb-xl">
                              <div className="form-group">
                                <label
                                  className="col-md-2 control-label"
                                  htmlFor="status"
                                >
                                  {loggedUser.role == "Administrators"
                                    ? "Portal Access Status"
                                    : "Test Access Status"}
                                </label>
                                <div className="col-md-8">
                                  <select
                                    name="status"
                                    className="form-control"
                                    required
                                    value={this.state.data.status}
                                    onChange={this.handleChange}
                                  >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </fieldset>
                          </>
                        )}
                        <div className="panel-footer">
                          <fieldset>
                            <div className="form-group">
                              <div className="col-md-2"></div>
                              <div className="col-md-6">
                                <SaveButton loading={this.state.loading} />
                                <a
                                  href={this.state.urllist}
                                  title="Back to list"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-backward fa-lg"></i> Back
                                </a>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </form>
                    </Tab>
                  </Tabs>
                </div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
