import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
var DateFormat = require("dateformat");

export const parseViewModel = (dataModel) => {
  return {
    _id: dataModel._id, //This is temporary ID using in GUID only.
    name: dataModel.name,
    type: dataModel.type,
    applyType: dataModel.applyType,
    value: dataModel.value,
    customAttributes: dataModel.customAttributes,
  };
};

export const wizardColumns = (
  handleShowWithWizardMode,
  testType,
  loggedUser,
  removeRow
) => {
  return [
    {
      name: "Rule Name",
      selector: "name",
      cell: (row, index) => (
        <a
          onClick={() => {
            handleShowWithWizardMode(row, index);
          }}
          href="# "
          title="Click to edit"
        >
          {row.name}
        </a>
      ),
    },
    {
      name: "Meta Attribute",
      selector: "customAttributes",
      cell: (row) => {
        let customAttributesText = ``;
        if (row.customAttributes && row.customAttributes !== "") {
          const customAttributes = JSON.parse(row.customAttributes);
          if (customAttributes && customAttributes.length > 0) {
            customAttributes.map((x) => {
              if (customAttributesText !== ``) {
                customAttributesText += `, `;
              }
              customAttributesText += `${x.attribute} (${x.value})`;
            });
          }
        }
        return customAttributesText;
      },
      omit: !testType.taxFeeByMeta,
    },
    {
      name: "Value",
      selector: "value",
      sortable: true,
      right: true,
      cell: (row) => {
        {
          return parseFloat(row.value) < 0 ? (
            <span style={{ color: "red" }}>
              ${CommonUtilities.numberWithCommasNegative(row.value)}
            </span>
          ) : (
            "$" + CommonUtilities.numberWithCommasNegative(row.value)
          );
        }
      },
    },
    {
      name: "Rule Type",
      selector: "type",
      sortable: true,
      center: true,
      cell: (row) => row.type,
    },
    {
      name: "Tax or Fee Applied",
      selector: "applyType",
      sortable: true,
      center: true,
      cell: (row) => row.applyType,
    },
    {
      name: "",
      width: "100px",
      cell: (row, index) => (
        <div>
          {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <>
              <a
                title="Click to edit"
                href="# "
                onClick={() => {
                  handleShowWithWizardMode(row, index);
                }}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Click to remove"
                href="# "
                className="on-default remove-row"
                onClick={() => removeRow(index)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
            </>
          )}
        </div>
      ),
    },
  ];
};
