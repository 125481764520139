import React from "react";
import SelectConfig from "../Base/SelectConfig";
import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_CALCULATED,
  SECTION_QUALITY_DATA,
  SECTION_META_DATA,
} from "../Utilities/Constant";
export default class SelectConfigFieldType extends SelectConfig {
  constructor(props) {
    super(props);
  }

  filterTestFieldTypes = (section, fieldTypes) => {
    if (fieldTypes && fieldTypes.length > 0) {
      let filterTestFieldTypes = [];
      const numericTypes = [
        FIELD_TYPE_INTEGER,
        FIELD_TYPE_PERCENT,
        FIELD_TYPE_DECIMAL,
        FIELD_TYPE_CALCULATED,
      ];
      switch (section) {
        case SECTION_META_DATA:
          const notNumericFieldTypes = fieldTypes.filter(
            (f) => !numericTypes.includes(f._id) // get field Types which is not numeric
          );
          filterTestFieldTypes = notNumericFieldTypes;
          break;
        case SECTION_QUALITY_DATA:
          const numericFieldTypes = fieldTypes.filter(
            (f) => numericTypes.includes(f._id) // get field Types which is numeric
          );
          filterTestFieldTypes = numericFieldTypes;
          break;
        default:
          filterTestFieldTypes = fieldTypes;
      }
      return filterTestFieldTypes;
    } else {
      return [];
    }
  };

  render() {
    return (
      <select
        disabled={this.props.readOnly}
        name={this.state.controlId}
        className={`form-control select-config-size ${this.props.className}`}
        required={this.props.required}
        onChange={this.handleChangeSelect}
        value={this.state.value}
        style={this.props.style}
      >
        <option value="">
          {this.props.label ? this.props.label : "Choose one"}
        </option>
        {this.state.data
          ? this.props.isCalledFromPopup
            ? this.filterTestFieldTypes(
                this.props.currentSection,
                this.state.data
              ).map((item, key) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })
            : this.state.data.map((item, key) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })
          : ""}
      </select>
    );
  }
}
