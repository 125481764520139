import moment from "moment";
import lodash from "lodash";

import { queryDataExt } from "../BaseService/index";
import { getCropYear } from "../CropYear";
import { getProcessor } from "../Processor";
import { getTestType } from "../TestType";
import {
  getGrowerIdsExistPaymentSetting,
  getPaymentSettingsWithConditions,
  countPaymentSettingDefault,
} from "../PaymentSetting";
import { getGrowersWithConditions } from "../Grower";
import { getVarietiesWithConditions } from "../Variety";
import { getTestsWithConditions } from "../Test";
import { getNutTypeFieldsWithConditions } from "../NutTypeField";
import { getTestFieldsWithConditions } from "../TestField";
import { getNutTypeGrowerStatementFieldsWithConditions } from "../NutTypeGrowerStatementField";
import { getClassificationItemsWithConditions } from "../ClassificationItem";
import { getClassificationsWithConditions } from "../Classification";
import { growersByProcessor } from "../LabUser";
import { getTaxesFeesWithConditions } from "../TaxFee";
import { getBasePricesWithConditions } from "../BasePrice";
import { getCashContractPrices } from "../CashContractPrice";
import { getBonusDeducts } from "../BonusDeduct";
import { getAttributes } from "../Attribute";

import utilities from "../../Components/Utilities/Common";
const constant = require("../../Components/Utilities/Constant");
const GlobalConfig = new window.globalConfig();

export const queryGrowerStatementsByCropYear = async (
  cropYearId,
  growerIds,
  page,
  perPage
) => {
  try {
    page = page - 1;
    const queryGrowerIds = growerIds;
    let total = 0;
    const parsedItems = [];
    const cropYear = await getCropYear(cropYearId);
    if (cropYear === undefined) {
      return {
        result: "ERROR",
        message: `Crop year no longer exist! Please check setting on crop year.`,
      };
    }
    const processor = await getProcessor(cropYear.processorId);
    //   .select("isContractCashPrice");
    if (processor === undefined) {
      return {
        result: "ERROR",
        message: `Processor no longer exist! Please refresh and try again.`,
      };
    }
    let testType = await getTestType(cropYear.nutTypeId);
    if (testType === undefined) {
      return {
        result: "ERROR",
        message: `Test type no longer exist! Please check setting on crop year.`,
      };
    }
    if (testType.isInUse !== true) {
      return {
        result: "ERROR",
        message: `Test type unpublished! Please check setting on test type.`,
      };
    }

    const totalPaymentSettingDefault = await countPaymentSettingDefault(
      cropYearId
    );

    let whereClauseGrower = {
      processorId: cropYear.processorId,
      $or: [
        { testTypeId: { $regex: cropYear.nutTypeId, $options: "i" } },
        { testTypeId: { $in: [``, undefined, null] } },
      ],
    };
    if (queryGrowerIds && queryGrowerIds !== `` && queryGrowerIds !== `all`) {
      whereClauseGrower = JSON.parse(JSON.stringify(whereClauseGrower));
      whereClauseGrower._id = { $in: queryGrowerIds.split(`,`) };
    } else {
      //Get paymentSettings to check if a grower have least one or not
      const growerIdsExistPaymentSetting =
        await getGrowerIdsExistPaymentSetting(cropYearId);
      //If there is default setting > without filter grower
      if (totalPaymentSettingDefault === 0) {
        whereClauseGrower = JSON.parse(JSON.stringify(whereClauseGrower));
        whereClauseGrower._id = { $in: growerIdsExistPaymentSetting };
      }
    }

    let growerItems = await getGrowersWithConditions(
      whereClauseGrower,
      "name owners",
      "name"
    );

    const varietyItems = await getVarietiesWithConditions(
      {
        parentId: cropYear.nutTypeId,
      },
      "name",
      ""
    );

    const paymentSettingItems = await getPaymentSettingsWithConditions(
      {
        parentId: cropYearId,
      },
      "",
      ""
    );

    ///////////////////////////////////////////////////////////////////////////
    //Query tests by crop year
    const startDate = new Date(cropYear.startDate);
    const endDate = new Date(cropYear.endDate);

    const startDateFilter = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    ).setHours(0, 0, 0);

    const endDateFilter = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    ).setHours(23, 59, 59);

    const selectFieldsTest =
      testType.isDynamic === true
        ? `${constant.EXCLUDE_SYSTEM_FIELDS} ${constant.EXCLUDE_WALNUT_FIELDS} -photo`
        : `${constant.EXCLUDE_SYSTEM_FIELDS} -photo -customFields`;
    let walnutTestItemsByCropYear = await getTestsWithConditions(
      {
        processorId: cropYear.processorId,
        nutTypeId: cropYear.nutTypeId,
        testDate: {
          $gte: startDateFilter,
          $lte: endDateFilter,
        },
      },
      selectFieldsTest,
      ""
    );

    if (
      walnutTestItemsByCropYear === null ||
      walnutTestItemsByCropYear.length <= 0
    ) {
      return {
        result: "INFO",
        message: `There is no test in this crop year.`,
      };
    }

    let excludedGrowersObj = composeListInvalidExcludedGrowers(
      testType,
      varietyItems,
      walnutTestItemsByCropYear,
      growerItems,
      paymentSettingItems
    );
    const excludedGrowers = excludedGrowersObj.excludedGrowers;
    const uniqueVarietyIds =
      testType.paymentByVariety === true
        ? excludedGrowersObj.uniqueVarietyIds
        : [``];

    growerItems = growerItems.filter((g) => {
      return (
        excludedGrowers.some(
          (e) => e.growerId.toString() === g._id.toString()
        ) === false
      );
    });

    total = growerItems ? growerItems.length : 0;

    if (growerItems && growerItems.length > 0) {
      growerItems = growerItems.slice(perPage * page, perPage * (page + 1));
    }
    if (
      growerItems === null ||
      growerItems === undefined ||
      growerItems.length <= 0
    ) {
      return {
        result: "INFO",
        message: `Not found any grower to calculate. Please check list of growers assigned to test type.`,
      };
    }

    let labUsers = [];
    if (testType.isDynamic == true) {
      testType = await composeGroweStatementFields(testType);
      if (walnutTestItemsByCropYear && walnutTestItemsByCropYear.length > 0) {
        walnutTestItemsByCropYear = await buildCustomFields(
          walnutTestItemsByCropYear,
          testType
        );
      }
      labUsers = await growersByProcessor(cropYear.processorId);
    }
    ///////////////////////////////////////////////////////////////////////////

    let priceFields = await queryPriceFields(testType, cropYear.processorId);

    const growerStatementByCropYear = await getGrowerStatementsWithConditions(
      {
        cropYearId: cropYearId,
      },
      constant.EXCLUDE_SYSTEM_FIELDS,
      ``
    );

    if (growerStatementByCropYear && growerStatementByCropYear.length > 0) {
      growerStatementByCropYear.sort(utilities.getSortOrderDesc("createdDate"));
    }

    if (growerItems && growerItems.length > 0) {
      const taxFeeItemsByCropYear = await getTaxesFeesWithConditions(
        {
          parentId: cropYearId,
        },
        ``,
        `name`
      );

      let basePriceByCropYear = await getBasePricesWithConditions(
        {
          parentId: cropYearId,
        },
        ``,
        ``
      );

      let cashContractPriceByCropYear = [];
      if (processor.isContractCashPrice === true) {
        cashContractPriceByCropYear = await getCashContractPrices(
          {
            parentId: cropYearId,
          },
          ``,
          ``
        );
      }

      let bonusDeductByCropYear = await getBonusDeducts(
        {
          parentId: cropYearId,
        },
        ``,
        `attribute`
      );

      //default tax & fee
      let taxFeeItemsDefault = [];
      if (taxFeeItemsByCropYear && taxFeeItemsByCropYear.length > 0) {
        taxFeeItemsDefault = taxFeeItemsByCropYear.filter((pg) => {
          return (
            pg.growerId === null ||
            pg.growerId === undefined ||
            pg.growerId.toString() === ""
          );
        });
        if (taxFeeItemsDefault && taxFeeItemsDefault.length > 0) {
          taxFeeItemsDefault.sort(utilities.getSortOrder("name"));
        }
      }
      //default base price
      let basePriceDefaultItems = [];
      if (basePriceByCropYear && basePriceByCropYear.length > 0) {
        basePriceDefaultItems = basePriceByCropYear.filter((pg) => {
          return (
            pg.growerId === null ||
            pg.growerId === undefined ||
            pg.growerId.toString() === ""
          );
        });
      }

      //default cash contract price
      let cashContractPriceDefaultItems = [];
      if (
        cashContractPriceByCropYear &&
        cashContractPriceByCropYear.length > 0
      ) {
        cashContractPriceDefaultItems = cashContractPriceByCropYear.filter(
          (x) => {
            return (
              x.growerId === null ||
              x.growerId === undefined ||
              x.growerId.toString() === ""
            );
          }
        );
      }

      //custom attributes by processor
      const attributeItems = await getAttributes(
        {
          processorId: cropYear.processorId,
          nutTypeId: testType._id,
        },
        ``,
        ``
      );
      const attributeItemsByProcessor = [];
      if (attributeItems && attributeItems.length > 0) {
        attributeItems.map((u) => {
          attributeItemsByProcessor.push({
            name: `Defect: ${u.name}`,
            formulas:
              u.formulas === null ||
              u.formulas === undefined ||
              u.formulas === ""
                ? []
                : JSON.parse(u.formulas),
          });
        });
      }

      //default bonus & deduct
      let bonusDeductDefaultItems = [];
      if (bonusDeductByCropYear && bonusDeductByCropYear.length > 0) {
        bonusDeductDefaultItems = bonusDeductByCropYear.filter((pg) => {
          return (
            pg.growerId === null ||
            pg.growerId === undefined ||
            pg.growerId.toString() === ""
          );
        });
        if (bonusDeductDefaultItems && bonusDeductDefaultItems.length > 0) {
          bonusDeductDefaultItems.sort(utilities.getSortOrder("attribute"));
        }
      }

      //default payment settings
      let paymentSettingDefaultItems = paymentSettingItems.filter((x) => {
        return (
          x.growerId === null ||
          x.growerId === undefined ||
          x.growerId.toString() === ""
        );
      });

      growerItems.map((g) => {
        uniqueVarietyIds.map((varietyId) => {
          let varietyName = ``;
          if (testType.paymentByVariety === true && varietyId != ``) {
            const tempVariety = varietyItems.filter((v) => {
              return v._id.toString() == varietyId;
            });
            if (tempVariety && tempVariety.length > 0) {
              varietyName = tempVariety[0].name;
            }
          }

          let walnutTestItemsByCropYearByVarietyId = walnutTestItemsByCropYear;
          let paymentsByVarietyId = paymentSettingItems;
          let paymentSettingDefaultItemsByVarietyId =
            paymentSettingDefaultItems;
          if (testType.paymentByVariety === true && varietyId != ``) {
            paymentSettingDefaultItemsByVarietyId =
              paymentSettingDefaultItemsByVarietyId.filter((x) => {
                return (
                  (x.varietyId ? x.varietyId.toString() : ``) === varietyId
                );
              });
            paymentsByVarietyId = paymentsByVarietyId.filter((x) => {
              return (x.varietyId ? x.varietyId.toString() : ``) === varietyId;
            });

            walnutTestItemsByCropYearByVarietyId =
              walnutTestItemsByCropYearByVarietyId.filter((x) => {
                return (
                  (x.varitySubmitted ? x.varitySubmitted.toString() : ``) ===
                  varietyId
                );
              });
          }

          let totalPercentPaymentSettingsDefault = 0;
          if (
            paymentSettingDefaultItemsByVarietyId &&
            paymentSettingDefaultItemsByVarietyId.length > 0
          ) {
            paymentSettingDefaultItemsByVarietyId.map((x) => {
              totalPercentPaymentSettingsDefault =
                Math.round(
                  (totalPercentPaymentSettingsDefault + x.paymentPercent) * 100
                ) / 100;
            });
          }

          calculate(
            g,
            walnutTestItemsByCropYearByVarietyId,
            paymentsByVarietyId,
            paymentSettingDefaultItemsByVarietyId,
            totalPercentPaymentSettingsDefault,
            varietyItems,
            taxFeeItemsByCropYear,
            taxFeeItemsDefault,
            basePriceByCropYear,
            basePriceDefaultItems,
            cashContractPriceByCropYear,
            cashContractPriceDefaultItems,
            bonusDeductByCropYear,
            bonusDeductDefaultItems,
            attributeItemsByProcessor,
            growerStatementByCropYear,
            testType,
            labUsers,
            priceFields,
            cropYearId,
            varietyName,
            varietyId,
            parsedItems
          );
        });
      });
    }

    return {
      result: "OK",
      cropYear: {
        name: cropYear.name,
        startDate: moment(cropYear.startDate).format("MM/DD/YYYY"),
        endDate: moment(cropYear.endDate).format("MM/DD/YYYY"),
      },
      growers: growerItems,
      totalCount: total,
      data: parsedItems,
    };
  } catch (err) {
    console.log(err.message);
    return { result: "ERROR", message: err.message };
  }
};

function calculate(
  g,
  walnutTestItemsByCropYear,
  paymentSettingItems,
  paymentSettingDefaultItems,
  totalPercentPaymentSettingsDefault,
  varietyItems,
  taxFeeItemsByCropYear,
  taxFeeItemsDefault,
  basePriceByCropYear,
  basePriceDefaultItems,
  cashContractPriceByCropYear,
  cashContractPriceDefaultItems,
  bonusDeductByCropYear,
  bonusDeductDefaultItems,
  attributeItemsByProcessor,
  growerStatementByCropYear,
  testType,
  labUsers,
  priceFields,
  cropYearId,
  varietyName,
  varietyId,
  parsedItems
) {
  const growerId = g._id.toString();
  const calculatedPayments = calculatePayments(
    g,
    walnutTestItemsByCropYear,
    paymentSettingItems,
    paymentSettingDefaultItems,
    varietyItems,
    taxFeeItemsByCropYear,
    taxFeeItemsDefault,
    basePriceByCropYear,
    basePriceDefaultItems,
    cashContractPriceByCropYear,
    cashContractPriceDefaultItems,
    bonusDeductByCropYear,
    bonusDeductDefaultItems,
    attributeItemsByProcessor,
    growerStatementByCropYear,
    testType,
    labUsers,
    priceFields
  );

  let totalPercentPaymentSettings = 0;
  //Only reponse grower that has payment settings 100%.
  let isValidPaymentSetting = false;
  //Find custom payment settings first, if not exist then get from default setting
  let paymentSettingByGrowerItems = paymentSettingItems.filter((pg) => {
    if (
      (pg.growerId === null || pg.growerId === undefined
        ? ""
        : pg.growerId.toString()) === growerId
    ) {
      totalPercentPaymentSettings =
        Math.round((totalPercentPaymentSettings + pg.paymentPercent) * 100) /
        100;
      return true;
    }
    return false;
  });
  if (
    paymentSettingByGrowerItems === null ||
    paymentSettingByGrowerItems === undefined ||
    paymentSettingByGrowerItems.length <= 0
  ) {
    paymentSettingByGrowerItems = paymentSettingDefaultItems;
    isValidPaymentSetting = totalPercentPaymentSettingsDefault === 100;
  } else {
    isValidPaymentSetting = totalPercentPaymentSettings === 100;
  }

  if (
    isValidPaymentSetting &&
    paymentSettingByGrowerItems &&
    paymentSettingByGrowerItems.length > 0
  ) {
    let paymentNo = 1;

    paymentSettingByGrowerItems.sort(utilities.getSortOrder("paymentDate"));

    paymentSettingByGrowerItems.map((p) => {
      //exclude any growers without any test in crop year
      const walnutTestItemsByGrower = walnutTestItemsByCropYear.filter((w) => {
        return w.growerName === growerId;
      });
      if (walnutTestItemsByGrower && walnutTestItemsByGrower.length > 0) {
        let reportId = "";
        let modifiedDate = null;
        let createdDate = null;
        let status = "Preview";
        let paidStatement = "";
        let tempGrower = growerStatementByCropYear.filter((u) => {
          return (
            u.paymentId !== undefined &&
            u.paymentId !== null &&
            u.paymentId.toString() === p._id.toString() &&
            u.growerId !== undefined &&
            u.growerId !== null &&
            u.growerId.toString() === growerId
          );
        });
        if (varietyId && varietyId !== ``) {
          tempGrower = tempGrower.filter((u) => {
            return (
              (u.varietyId === undefined || u.varietyId === null
                ? ``
                : u.varietyId.toString()) === varietyId
            );
          });
        }
        if (tempGrower && tempGrower.length > 0) {
          reportId = tempGrower[0]._id;
          modifiedDate = tempGrower[0].modifiedDate;
          createdDate = tempGrower[0].createdDate;
          status = tempGrower[0].status;
          paidStatement = tempGrower[0].paidStatement;
        }

        let netPayment = 0;
        let payment = 0; //gross payment - payment after tax & fee
        let paymentPercent = 0;
        let paymentDate = p.paymentDate;
        if (status !== "Paid") {
          paymentPercent = p.paymentPercent;
          // paymentDate = p.paymentDate;
          if (calculatedPayments && calculatedPayments.length > 0) {
            const temp = calculatedPayments.find((x) => {
              return x.paymentId === p._id;
            });
            if (temp) {
              payment = temp.payment;
              netPayment = temp.netPayment;
            }
          }
        } else {
          if (paidStatement && paidStatement !== ``) {
            const paidStatementJSON = JSON.parse(paidStatement);
            if (paidStatementJSON) {
              if (paidStatementJSON.itemsTaxFee) {
                const temp = paidStatementJSON.itemsTaxFee.find((a) => {
                  return a.name === `Net Payment`;
                });
                if (temp) {
                  //temp.payment = "$90,549.50" or "($90,549.50)"
                  if (temp.payment.indexOf(`(`) !== -1) {
                    payment =
                      -1 *
                      parseFloat(
                        temp.payment
                          .replace(`(`, ``)
                          .replace(`)`, ``)
                          .replace(`$`, ``)
                          .replace(`,`, ``)
                      );
                  } else {
                    payment = parseFloat(
                      temp.payment.replace(`$`, ``).replace(`,`, ``)
                    );
                  }
                }
              }
              if (paidStatementJSON.itemsPayment) {
                const temp = paidStatementJSON.itemsPayment.find((a) => {
                  return a.lot === `Total`;
                });
                if (temp) {
                  //temp.payment = "$90,549.50" or "($90,549.50)"
                  if (temp.payment.indexOf(`(`) !== -1) {
                    netPayment =
                      -1 *
                      parseFloat(
                        temp.payment
                          .replace(`(`, ``)
                          .replace(`)`, ``)
                          .replace(`$`, ``)
                          .replace(`,`, ``)
                      );
                  } else {
                    netPayment = parseFloat(
                      temp.payment.replace(`$`, ``).replace(`,`, ``)
                    );
                  }
                }
              }
              if (paidStatementJSON.growerInfo) {
                paymentPercent = parseFloat(
                  paidStatementJSON.growerInfo.paymentPercent
                );
              }
            }
          }
        }

        parsedItems.push({
          reportId: reportId,
          modifiedDate: modifiedDate,
          createdDate: createdDate,
          status: status,
          cropYearId: cropYearId,
          paymentId: p._id,
          paymentDate: paymentDate,
          paymentDateCompare: paymentDate,
          paymentPercent: paymentPercent,
          paymentNo: paymentNo,
          payment: payment,
          netPayment: netPayment,
          growerId: g._id,
          growerName: g.name,
          owners: g.owners,
          varietyName: varietyName,
          varietyId: varietyId,
        });
      }
      paymentNo++;
    });
  }
}

function calculatePayments(
  growerItem,
  walnutTestsByCropYear,
  paymentSettingsByCropYear,
  paymentSettingsDefault,
  varietyItems,
  taxFeeItemsByCropYear,
  taxFeeItemsDefault,
  basePriceByCropYear,
  basePriceDefaultItems,
  cashContractPriceByCropYear,
  cashContractPriceDefaultItems,
  bonusDeductByCropYear,
  bonusDeductDefaultItems,
  attributeItemsByProcessor,
  growerStatementByCropYear,
  testType,
  labUsers,
  priceFields
) {
  const growerId = growerItem._id.toString();
  const walnutTestByGrower = walnutTestsByCropYear.filter((x) => {
    return x.growerName === growerId;
  });
  //Query payment settings
  let paymentSettingItems = paymentSettingsByCropYear.filter((x) => {
    return x.growerId == growerId;
  });
  if (
    paymentSettingItems === null ||
    paymentSettingItems === undefined ||
    paymentSettingItems.length <= 0
  ) {
    //There is no custom payment setting
    //Get default setting
    paymentSettingItems = paymentSettingsDefault;
  }
  if (
    paymentSettingItems === null ||
    paymentSettingItems === undefined ||
    paymentSettingItems.length <= 0
  ) {
    // console.log(
    //   "There is no payment settings to export! Please complete setup payment and try again."
    // );
    return [];
  }
  paymentSettingItems.sort(utilities.getSortOrder("paymentDate"));
  //If there is no walnut test by grower response error message.
  if (
    walnutTestByGrower === undefined ||
    walnutTestByGrower === null ||
    walnutTestByGrower.length <= 0
  ) {
    // console.log("There is no test for grower " + growerItem.name + ".");
    return [];
  }

  //Filter tax & fee for grower
  let taxFeeItemsByGrower = taxFeeItemsByCropYear.filter((x) => {
    return x.growerId == growerId;
  });
  //If tax & fee does not exist for grower then get from default.
  if (taxFeeItemsDefault && taxFeeItemsDefault.length > 0) {
    taxFeeItemsDefault.map((x) => {
      const tempArr = taxFeeItemsByGrower.filter((y) => {
        return (
          y.name.toLowerCase() === x.name.toLowerCase() &&
          y.type.toLowerCase() === x.type.toLowerCase()
        );
      });
      if (tempArr === null || tempArr === undefined || tempArr.length <= 0) {
        taxFeeItemsByGrower.push(x);
      }
    });
  }
  if (taxFeeItemsByGrower && taxFeeItemsByGrower.length > 0) {
    taxFeeItemsByGrower.sort(utilities.getSortOrder("name"));
  }

  let basePriceItemsByGrower = filterBasePrices(
    basePriceByCropYear,
    basePriceDefaultItems,
    testType,
    growerId
  );

  //Filter cash contract price for grower
  let cashContractPriceItemsByGrower = cashContractPriceByCropYear.filter(
    (x) => {
      return x.growerId == growerId;
    }
  );
  //If cash contract price does not exist for grower then get from default.
  if (
    cashContractPriceDefaultItems &&
    cashContractPriceDefaultItems.length > 0
  ) {
    cashContractPriceDefaultItems.map((x) => {
      const tempArr = cashContractPriceItemsByGrower.filter((y) => {
        return (
          (y.varietyId === null || y.varietyId === undefined
            ? ""
            : y.varietyId.toString()) ===
          (x.varietyId === null || x.varietyId === undefined
            ? ""
            : x.varietyId.toString())
        );
      });
      if (tempArr === null || tempArr === undefined || tempArr.length <= 0) {
        cashContractPriceItemsByGrower.push(x);
      }
    });
  }

  //Filter bonus & default for grower
  let bonusDeductItemsByGrower = bonusDeductByCropYear.filter((x) => {
    return x.growerId == growerId;
  });

  //If bonus & default does not exist for grower then get from default.
  if (bonusDeductDefaultItems && bonusDeductDefaultItems.length > 0) {
    bonusDeductDefaultItems.map((x) => {
      let tempArr = [];
      if (testType.varietyOptional === true) {
        tempArr = bonusDeductItemsByGrower.filter((y) => {
          return (
            (y.attribute === null || y.attribute === undefined
              ? ""
              : y.attribute.toString()) ===
              (x.attribute === null || x.attribute === undefined
                ? ""
                : x.attribute.toString()) &&
            x.min === y.min &&
            x.max === y.max
          );
        });
      } else {
        tempArr = bonusDeductItemsByGrower.filter((y) => {
          return (
            (y.varietyId === null || y.varietyId === undefined
              ? ""
              : y.varietyId.toString()) ===
              (x.varietyId === null || x.varietyId === undefined
                ? ""
                : x.varietyId.toString()) &&
            (y.attribute === null || y.attribute === undefined
              ? ""
              : y.attribute.toString()) ===
              (x.attribute === null || x.attribute === undefined
                ? ""
                : x.attribute.toString()) &&
            x.min === y.min &&
            x.max === y.max
          );
        });
      }
      if (tempArr === null || tempArr === undefined || tempArr.length <= 0) {
        bonusDeductItemsByGrower.push(x);
      }
    });
  }
  if (bonusDeductItemsByGrower && bonusDeductItemsByGrower.length > 0) {
    bonusDeductItemsByGrower.sort(utilities.getSortOrder("attribute"));
    bonusDeductItemsByGrower = removeInvalidBonusDeduct(
      bonusDeductItemsByGrower,
      attributeItemsByProcessor
    );
  }

  //Loop payment setting to create data for export
  const parsedItems = [];
  const previousWalnutTestCalculated = [];
  const previousTaxFeeCalculated = [];
  let paymentNo = 0;
  const countPayments = paymentSettingItems.length;
  let allItemsTaxFee = [];

  paymentSettingItems.map((p) => {
    //Find paid statement first
    let paidStatementObject = findPaidStatement(
      growerStatementByCropYear,
      p._id.toString(),
      growerId,
      `Paid`
    );
    // let paidStatementObject;
    // let tempGrowerStatementByPayment = [];
    // if (growerStatementByCropYear) {
    //   tempGrowerStatementByPayment = growerStatementByCropYear.filter((u) => {
    //     return (
    //       (u.paymentId === undefined || u.paymentId === null
    //         ? ""
    //         : u.paymentId.toString()) === p._id.toString() &&
    //       (u.growerId === undefined || u.growerId === null
    //         ? ""
    //         : u.growerId.toString()) === growerId
    //     );
    //   });
    // }
    // if (
    //   tempGrowerStatementByPayment &&
    //   tempGrowerStatementByPayment.length > 0
    // ) {
    //   const paidStatement = tempGrowerStatementByPayment[0].paidStatement;
    //   //If statement generated and status is paid. Load from database instead re-calculate.
    //   if (
    //     paidStatement !== null &&
    //     paidStatement !== undefined &&
    //     paidStatement !== ""
    //   ) {
    //     paidStatementObject = JSON.parse(paidStatement);
    //   }
    // }
    const paymentPercent = p.paymentPercent / 100.0;
    const paymentDate = parseInt(moment(p.paymentDate).format("yyyyMMDD"));
    //In a payment, query tests those were testDate <= payment date
    const walnutTestItemsByPayment = walnutTestByGrower.filter((x) => {
      const testDate = parseInt(moment(x.testDate).format("yyyyMMDD"));
      return testDate <= paymentDate;
    });

    const itemsPayment = [];
    const itemsPayment4TaxFee = [];
    let itemsTaxFee = [];
    let paymentsByPriceFields = [];

    let isFullCashContractPrice = false;
    let itemsPaymentBreak = [];
    let cashContractPriceSumItems = [];
    let totalLbs = 0;
    let varietyName = "";
    let totalPayment = 0;
    let totalYtd = 0;
    let basePriceByVariety = 0;
    let valueInshell = 0;
    let valueShelled = 0;

    let bonusDeductByVariety = 0;
    let totalPaymentPercent = 0;

    for (let i = paymentNo; i >= 0; i--) {
      totalPaymentPercent =
        Math.round(
          (totalPaymentPercent + paymentSettingItems[i].paymentPercent) * 100
        ) / 100;
    }
    totalPaymentPercent = totalPaymentPercent / 100.0;

    cashContractPriceSumItems = calculateCashContractPriceSumItems(
      walnutTestItemsByPayment,
      basePriceItemsByGrower,
      cashContractPriceItemsByGrower
    );

    walnutTestItemsByPayment.map((w) => {
      const varitySubmitted =
        w.varitySubmitted === null || w.varitySubmitted === undefined
          ? ""
          : w.varitySubmitted.toString();
      const varietyItemTemp = varietyItems.filter((v) => {
        return v._id.toString() === varitySubmitted;
      });
      if (varietyItemTemp && varietyItemTemp.length > 0) {
        varietyName = varietyItemTemp[0].name;
      }
      //find base price by variety
      const valuesBasePrice = GetValuesBasePrice(
        basePriceItemsByGrower,
        varitySubmitted,
        testType,
        w,
        p._id.toString(),
        priceFields
      );
      let applyTo = valuesBasePrice.applyTo;
      let isInshell = valuesBasePrice.isInshell;
      let isShelled = valuesBasePrice.isShelled;
      basePriceByVariety = valuesBasePrice.basePriceByVariety;
      valueInshell = valuesBasePrice.valueInshell;
      valueShelled = valuesBasePrice.valueShelled;

      //find bonus & deduct
      bonusDeductByVariety = findBonusDeductValue(
        bonusDeductItemsByGrower,
        w,
        applyTo,
        attributeItemsByProcessor,
        testType,
        paymentNo
      );

      let lbs = w.netWeight;
      let lb = 0;
      if (testType.paymentPercentBased === true) {
        lb = basePriceByVariety + bonusDeductByVariety;
      } else if (testType.singlePricePerLot === true) {
        lb = findPrice4SinglePricePerLot(valuesBasePrice, w, testType);
        basePriceByVariety = lb;
      } else {
        lb = basePriceByVariety;
      }
      let payment = 0;
      let lbsBreak = 0;
      let amountDue = 0;

      if (isFullCashContractPrice === false) {
        //Apply logic cash contract price - Ref https://dev.azure.com/ParityFactory/Parity4/_workitems/edit/6410/
        const totalNetWeightByVarietyTemp = cashContractPriceSumItems.filter(
          (k) => {
            return k.varitySubmitted === varitySubmitted;
          }
        );
        if (
          totalNetWeightByVarietyTemp &&
          totalNetWeightByVarietyTemp.length > 0
        ) {
          const sumLbsByVariety = lodash.sumBy(itemsPayment, (o) => {
            //lb!=`` to exclude raw Total
            return o.varitySubmitted === varitySubmitted && o.lb !== ``
              ? parseFloat(o.lbs)
              : 0;
          });
          const tempCashPrice = JSON.parse(
            JSON.stringify(totalNetWeightByVarietyTemp[0])
          );
          if (sumLbsByVariety + lbs <= tempCashPrice.amount) {
            //Use Cash price
            lb = tempCashPrice.valueCashPrice;
            if (sumLbsByVariety + lbs == tempCashPrice.amount) {
              isFullCashContractPrice = true;
            }
          } else {
            //break down 2 prices
            lbsBreak = sumLbsByVariety + lbs - tempCashPrice.amount;
            lbs = lbs - lbsBreak;
            //Use Cash price
            lb = tempCashPrice.valueCashPrice;
            //Use Pool price
            itemsPaymentBreak.push({
              lbs: lbsBreak,
              lb: basePriceByVariety + bonusDeductByVariety,
              payment:
                lbsBreak *
                (basePriceByVariety + bonusDeductByVariety) *
                totalPaymentPercent,
              ytd:
                lbsBreak *
                (basePriceByVariety + bonusDeductByVariety) *
                totalPaymentPercent,
            });
            isFullCashContractPrice = true;
          }
        }
      }
      if (testType.paymentPercentBased === true) {
        //If dynamic test, first equation should be used
        if (testType.isDynamic || isInshell) {
          if (testType.xpool === true) {
            amountDue =
              w.netWeight * w.regularPercent * valueInshell +
              w.netWeight * w.xpoolPercent * valueShelled;
            payment = amountDue * totalPaymentPercent;
          } else {
            payment = lbs * lb * totalPaymentPercent;
          }
        } else if (isShelled) {
          payment = lbs * lb * (w.s3EdibleYield / 100.0) * totalPaymentPercent;
        }
      } else if (testType.multiPricesSameLot === true) {
        const resultMultiPayments = calculatePaymentByMultiPricesSameLot(
          valuesBasePrice,
          lbs,
          w,
          paymentPercent,
          priceFields
        );
        payment = resultMultiPayments.totalPayment;
        resultMultiPayments.paymentsByPriceFields.map((t) => {
          paymentsByPriceFields.push(t);
        });
      } else if (testType.basePriceByPayment === true) {
        payment = lbs * basePriceByVariety + lbs * bonusDeductByVariety;
      } else if (testType.singlePricePerLot === true) {
        payment = lbs * lb;
      } else {
        payment =
          lbs * basePriceByVariety * (p.paymentPercent / 100.0) +
          lbs * bonusDeductByVariety;
      }

      let ytd = payment;

      //If payment from 1 onward, need to minus previous payment
      if (paymentNo > 0) {
        const previousTests = previousWalnutTestCalculated.filter((pw) => {
          return pw.paymentNo === paymentNo - 1 && w._id === pw._id;
        });
        if (previousTests && previousTests.length > 0) {
          let previousYtd = previousTests[0].ytd;
          //If previous is paid payment, we must get payment and ytd from paid statement
          if (
            previousTests[0].paidStatementObject &&
            previousTests[0].paidStatementObject.itemsPayment
          ) {
            const previousLot =
              previousTests[0].paidStatementObject.itemsPayment.filter((x) => {
                return x.id == w._id.toString();
              });
            if (previousLot && previousLot.length > 0) {
              //previousLot[0].payment = "$90,549.50" or "($90,549.50)"
              //previousLot[0].ytd = "$90,549.50" or "($90,549.50)"
              // if (previousLot[0].payment.indexOf(`(`) !== -1) {
              //   previousPayment =
              //     -1 *
              //     parseFloat(
              //       previousLot[0].payment
              //         .replace(`(`, ``)
              //         .replace(`)`, ``)
              //         .replace(`$`, ``)
              //         .replace(`,`, ``)
              //     );
              // } else {
              //   previousPayment = parseFloat(
              //     previousLot[0].payment.replace(`$`, ``).replace(`,`, ``)
              //   );
              // }
              if (previousLot[0].ytd.indexOf(`(`) !== -1) {
                previousYtd =
                  -1 *
                  parseFloat(
                    previousLot[0].ytd
                      .replace(`(`, ``)
                      .replace(`)`, ``)
                      .replace(`$`, ``)
                      .replace(`,`, ``)
                  );
              } else {
                previousYtd = parseFloat(
                  previousLot[0].ytd.replace(`$`, ``).replace(`,`, ``)
                );
              }
            }
          }
          if (testType.paymentPercentBased === true) {
            payment = payment - previousYtd;
          }
          ytd = payment + previousYtd;
        }

        //If new tests are created after one or more payments are already made, during the next payment the total payments need to catch up
        /*
        This logic is removed by https://dev.azure.com/ParityFactory/Parity4/_workitems/edit/10039
        */
        // if (previousPaidStatementObject) {
        //   let previousLot = undefined;
        //   if (previousPaidStatementObject.itemsPayment) {
        //     previousLot = previousPaidStatementObject.itemsPayment.filter(
        //       (x) => {
        //         return x.id == w._id.toString();
        //       }
        //     );
        //   }
        //   if (
        //     previousLot === undefined ||
        //     previousLot === null ||
        //     previousLot.length <= 0
        //   ) {
        //     let previousPayment = 0;
        //     if (testType.paymentPercentBased === true) {
        //       if (testType.isDynamic || isInshell) {
        //         previousPayment = lbs * lb * (previousPaymentPercent / 100.0);
        //       } else if (isShelled) {
        //         previousPayment =
        //           lbs *
        //           lb *
        //           (w.s3EdibleYield / 100.0) *
        //           (previousPaymentPercent / 100.0);
        //       }
        //     } else if (testType.multiPricesSameLot === true) {
        //       const resultMultiPayments = calculatePaymentByMultiPricesSameLot(
        //         valuesBasePrice,
        //         lbs,
        //         w,
        //         paymentPercent,
        //         priceFields
        //       );
        //       previousPayment = resultMultiPayments.totalPayment;
        //     } else if (testType.basePriceByPayment === true) {
        //       previousPayment =
        //         lbs * basePriceByVariety + lbs * bonusDeductByVariety;
        //     } else if (testType.singlePricePerLot === true) {
        //       previousPayment = lbs * lb;
        //     } else {
        //       previousPayment =
        //         lbs * basePriceByVariety * (previousPaymentPercent / 100.0) +
        //         lbs * bonusDeductByVariety;
        //     }

        //     payment = payment + previousPayment;
        //   }
        // }
      }

      totalLbs += lbs;
      totalPayment += payment;
      totalYtd += ytd;

      let itemPayment = {
        id: w._id.toString(),
        lot: w.lotNumber,
        date: moment(w.testDate).format("MM/DD/YYYY"),
        variety: varietyName,
        varitySubmitted: varitySubmitted,
        lbs: parseFloat(lbs).toFixed(2),
        lb: "$" + utilities.numberWithCommas(parseFloat(lb)),
        payment:
          payment < 0
            ? `$(${utilities.numberWithCommas(
                parseFloat(Math.abs(payment)).toFixed(2)
              )})`
            : `$${utilities.numberWithCommas(parseFloat(payment).toFixed(2))}`,
        ytd:
          ytd < 0
            ? `$(${utilities.numberWithCommas(
                parseFloat(Math.abs(ytd)).toFixed(2)
              )})`
            : `$${utilities.numberWithCommas(parseFloat(ytd).toFixed(2))}`,
      };
      itemPayment = JSON.parse(JSON.stringify(itemPayment));
      if (testType.isDynamic === true) {
        itemPayment = composeSelectedFields(
          testType,
          w,
          itemPayment,
          false,
          labUsers,
          varietyItems
        );
        if (testType.xpool === true) {
          itemPayment.amountDue =
            "$" + utilities.numberWithCommas(parseFloat(amountDue).toFixed(2));
          itemPayment.regularPercent =
            utilities.numberWithCommas(
              parseFloat(w.regularPercent).toFixed(2)
            ) + "%";
          itemPayment.xpoolPercent =
            utilities.numberWithCommas(parseFloat(w.xpoolPercent).toFixed(2)) +
            "%";
          itemPayment.xpoolWeight = utilities.numberWithCommas(
            parseFloat(w.xpoolWeight).toFixed(2)
          );
          itemPayment.regularWeight = utilities.numberWithCommas(
            parseFloat(w.regularWeight).toFixed(2)
          );
          itemPayment.regularPrice = utilities.numberWithCommas(
            parseFloat(valueInshell).toFixed(2)
          );
          itemPayment.alternatePrice = utilities.numberWithCommas(
            parseFloat(valueShelled).toFixed(2)
          );
        }
      } else {
        itemPayment.edibleYield = parseFloat(w.s3EdibleYield).toFixed(1) + "%";
      }

      itemsPayment.push(itemPayment);

      itemsPayment4TaxFee.push({
        id: w._id.toString(),
        payment: payment,
      });

      if (itemsPaymentBreak && itemsPaymentBreak.length > 0) {
        const itemPaymentBreak = itemsPaymentBreak[0];
        totalLbs += itemPaymentBreak.lbs;
        totalPayment += itemPaymentBreak.payment;
        totalYtd += itemPaymentBreak.ytd;
        let itemPaymentObj = {
          id: w._id.toString(),
          lot: w.lotNumber,
          date: moment(w.testDate).format("MM/DD/YYYY"),
          variety: varietyName,
          lbs: parseFloat(itemPaymentBreak.lbs).toFixed(2),
          lb: "$" + utilities.numberWithCommas(parseFloat(itemPaymentBreak.lb)),
          payment:
            itemPaymentBreak.payment < 0
              ? `$(${utilities.numberWithCommas(
                  parseFloat(Math.abs(itemPaymentBreak.payment)).toFixed(2)
                )})`
              : `$${utilities.numberWithCommas(
                  parseFloat(itemPaymentBreak.payment).toFixed(2)
                )}`,
          ytd:
            itemPaymentBreak.ytd < 0
              ? `$(${utilities.numberWithCommas(
                  parseFloat(Math.abs(itemPaymentBreak.ytd)).toFixed(2)
                )})`
              : `$${utilities.numberWithCommas(
                  parseFloat(itemPaymentBreak.ytd).toFixed(2)
                )}`,
        };
        itemPaymentObj = JSON.parse(JSON.stringify(itemPaymentObj));
        if (testType.isDynamic === true) {
          itemPaymentObj = composeSelectedFields(
            testType,
            w,
            itemPaymentObj,
            false,
            labUsers,
            varietyItems
          );

          if (testType.xpool === true) {
            itemPaymentObj.amountDue =
              "$" +
              utilities.numberWithCommas(parseFloat(amountDue).toFixed(2));
            itemPaymentObj.regularPercent =
              utilities.numberWithCommas(
                parseFloat(w.regularPercent).toFixed(2)
              ) + "%";
            itemPaymentObj.xpoolPercent =
              utilities.numberWithCommas(
                parseFloat(w.xpoolPercent).toFixed(2)
              ) + "%";
            itemPaymentObj.xpoolWeight = utilities.numberWithCommas(
              parseFloat(w.xpoolWeight).toFixed(2)
            );
            itemPaymentObj.regularWeight = utilities.numberWithCommas(
              parseFloat(w.regularWeight).toFixed(2)
            );
            itemPaymentObj.regularPrice = utilities.numberWithCommas(
              parseFloat(valueInshell).toFixed(2)
            );
            itemPaymentObj.alternatePrice = utilities.numberWithCommas(
              parseFloat(valueShelled).toFixed(2)
            );
          }
        } else {
          itemPaymentObj.edibleYield =
            parseFloat(w.s3EdibleYield).toFixed(1) + "%";
        }

        itemsPayment.push(itemPaymentObj);

        itemsPayment4TaxFee.push({
          id: w._id.toString(),
          payment: itemPaymentBreak.payment,
        });

        itemsPaymentBreak = []; //Reset to avoid adding next time
      }

      previousWalnutTestCalculated.push({
        paymentNo: paymentNo,
        _id: w._id,
        payment: payment,
        ytd: ytd,
        paidStatementObject: paidStatementObject,
      });
    });
    //Insert total row at last
    let itemPaymentLast = {
      id: ``,
      lot: "Total",
      date: "",
      variety: "",
      lbs: utilities.numberWithCommas(parseFloat(totalLbs).toFixed(2)),
      lb: "",
      payment:
        "$" + utilities.numberWithCommas(parseFloat(totalPayment).toFixed(2)),
      ytd: "$" + utilities.numberWithCommas(parseFloat(totalYtd).toFixed(2)),
    };
    itemPaymentLast = JSON.parse(JSON.stringify(itemPaymentLast));
    if (testType.isDynamic === true) {
      itemPaymentLast = composeSelectedFields(
        testType,
        undefined,
        itemPaymentLast,
        true,
        labUsers,
        varietyItems
      );
    } else {
      itemPaymentLast.edibleYield = "";
    }

    itemsPayment.push(itemPaymentLast);

    const objCalculateTaxFee = calculateListTaxFee(
      previousWalnutTestCalculated,
      taxFeeItemsByGrower,
      paymentNo,
      testType,
      totalLbs,
      countPayments,
      totalPaymentPercent,
      walnutTestItemsByPayment,
      itemsPayment4TaxFee,
      priceFields,
      paymentsByPriceFields,
      allItemsTaxFee
    );
    itemsTaxFee = objCalculateTaxFee.itemsTaxFee;
    let totalPaymentTaxFee = objCalculateTaxFee.totalPaymentTaxFee;
    let totalYtdTaxFee = objCalculateTaxFee.totalYtdTaxFee;

    //Insert total row at last
    let totalPaymentNetPayment = totalPayment - totalPaymentTaxFee;
    if (totalPaymentNetPayment > 0) {
      totalPaymentNetPayment =
        Math.floor((totalPayment - totalPaymentTaxFee) * 10000) / 10000.0;
    } else {
      totalPaymentNetPayment =
        Math.ceil((totalPayment - totalPaymentTaxFee) * 10000) / 10000.0;
    }

    const growerInfo = {
      growerName: growerItem.name,
      paymentNo: paymentNo + 1,
      paymentDate: moment(p.paymentDate).format("MM/DD/YYYY"),
      paymentPercent: p.paymentPercent,
      payment: totalPaymentNetPayment,
      netPayment: totalPayment,
      paymentId: p._id,
    };
    parsedItems.push(growerInfo);

    allItemsTaxFee.push({
      paymentNo: paymentNo,
      itemsTaxFee: itemsTaxFee,
    });
    paymentNo++;
    itemsTaxFee = [];
  });
  return parsedItems;
}

function filterBasePrices(
  basePriceByCropYear,
  basePriceDefaultItems,
  testType,
  growerId
) {
  //Filter base price for grower
  let basePriceItemsByGrower = [];
  if (basePriceByCropYear && basePriceByCropYear.length > 0) {
    basePriceItemsByGrower = basePriceByCropYear.filter((x) => {
      return (
        (x.growerId === null || x.growerId === undefined
          ? ""
          : x.growerId.toString()) === growerId
      );
    });
  }
  //If base price does not exist for grower then get from default.
  if (basePriceDefaultItems && basePriceDefaultItems.length > 0) {
    //Filter both payment and meta attribute
    if (
      testType.basePriceByPayment === true &&
      testType.singlePricePerLot === true
    ) {
      basePriceDefaultItems.map((x) => {
        const defaultCustomAttributes =
          x.customAttributes === null ||
          x.customAttributes === undefined ||
          x.customAttributes == ``
            ? []
            : JSON.parse(x.customAttributes);

        let tempArr = basePriceItemsByGrower.filter((y) => {
          let isDuplicatedMetaAttribute = false;

          const compareVariety =
            testType.varietyOptional !== true
              ? (y.varietyId === null || y.varietyId === undefined
                  ? ""
                  : y.varietyId.toString()) ===
                (x.varietyId === null || x.varietyId === undefined
                  ? ""
                  : x.varietyId.toString())
              : false;

          const comparePayment =
            (y.paymentId === null || y.paymentId === undefined
              ? ""
              : y.paymentId.toString()) ===
            (x.paymentId === null || x.paymentId === undefined
              ? ""
              : x.paymentId.toString());

          const growerCustomAttributes =
            y.customAttributes === null ||
            y.customAttributes === undefined ||
            y.customAttributes == ``
              ? []
              : JSON.parse(y.customAttributes);

          if (
            growerCustomAttributes.length == defaultCustomAttributes.length &&
            growerCustomAttributes.length == 0
          ) {
            isDuplicatedMetaAttribute = true;
          } else {
            let countDuplicate = 0;
            growerCustomAttributes.map((a) => {
              if (
                defaultCustomAttributes.filter((b) => {
                  return a.attribute == b.attribute && a.value == b.value;
                }).length > 0
              ) {
                countDuplicate++;
              }
            });
            if (
              countDuplicate == growerCustomAttributes.length &&
              countDuplicate == defaultCustomAttributes.length
            ) {
              isDuplicatedMetaAttribute = true;
            }
          }

          return (
            compareVariety == true &&
            comparePayment == true &&
            isDuplicatedMetaAttribute == true
          );
        });

        if (tempArr.length <= 0) {
          basePriceItemsByGrower.push(x);
        }
      });
    } else if (testType.basePriceByPayment === true) {
      basePriceDefaultItems.map((x) => {
        let tempArr = basePriceItemsByGrower.filter((y) => {
          if (testType.varietyOptional === true) {
            return (
              (y.paymentId === null || y.paymentId === undefined
                ? ""
                : y.paymentId.toString()) ===
              (x.paymentId === null || x.paymentId === undefined
                ? ""
                : x.paymentId.toString())
            );
          } else {
            return (
              (y.varietyId === null || y.varietyId === undefined
                ? ""
                : y.varietyId.toString()) ===
                (x.varietyId === null || x.varietyId === undefined
                  ? ""
                  : x.varietyId.toString()) &&
              (y.paymentId === null || y.paymentId === undefined
                ? ""
                : y.paymentId.toString()) ===
                (x.paymentId === null || x.paymentId === undefined
                  ? ""
                  : x.paymentId.toString())
            );
          }
        });
        if (tempArr === null || tempArr === undefined || tempArr.length <= 0) {
          basePriceItemsByGrower.push(x);
        }
      });
    } else {
      basePriceDefaultItems.map((x) => {
        const defaultCustomAttributes =
          x.customAttributes === null ||
          x.customAttributes === undefined ||
          x.customAttributes == ``
            ? []
            : JSON.parse(x.customAttributes);

        const tempArr = basePriceItemsByGrower.filter((y) => {
          const compareVariety =
            testType.varietyOptional === true
              ? false
              : (y.varietyId === null || y.varietyId === undefined
                  ? ""
                  : y.varietyId.toString()) ===
                (x.varietyId === null || x.varietyId === undefined
                  ? ""
                  : x.varietyId.toString());

          //Need compare meta attribute
          if (
            testType.multiPricesSameLot === true ||
            testType.singlePricePerLot === true
          ) {
            if (compareVariety && y.customAttributes == x.customAttributes) {
              return true;
            }

            let isDuplicatedMetaAttribute = false;

            const growerCustomAttributes =
              y.customAttributes === null ||
              y.customAttributes === undefined ||
              y.customAttributes == ``
                ? []
                : JSON.parse(y.customAttributes);

            if (
              growerCustomAttributes.length == defaultCustomAttributes.length &&
              growerCustomAttributes.length == 0
            ) {
              isDuplicatedMetaAttribute = true;
            } else {
              let countDuplicate = 0;
              growerCustomAttributes.map((a) => {
                if (
                  defaultCustomAttributes.filter((b) => {
                    return a.attribute == b.attribute && a.value == b.value;
                  }).length > 0
                ) {
                  countDuplicate++;
                }
              });
              if (
                countDuplicate === growerCustomAttributes.length &&
                countDuplicate === defaultCustomAttributes.length
              ) {
                isDuplicatedMetaAttribute = true;
              }
            }

            return compareVariety && isDuplicatedMetaAttribute;
          } else {
            return compareVariety;
          }
        });
        if (tempArr === null || tempArr === undefined || tempArr.length <= 0) {
          basePriceItemsByGrower.push(x);
        }
      });
    }
  }

  return basePriceItemsByGrower;
}

function removeInvalidBonusDeduct(bonusDeductItems, attributeItemsByProcessor) {
  if (
    bonusDeductItems === undefined ||
    bonusDeductItems === null ||
    bonusDeductItems.length <= 0
  ) {
    return [];
  }
  //If setting contains total, ignore individual
  const arrDefectTotal = bonusDeductItems.filter((x) => {
    return (
      x.attribute ===
      process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT_DEFECT_TOTAL
    );
  });
  const arrSeriousTotal = bonusDeductItems.filter((x) => {
    return (
      x.attribute ===
      process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT_SERIOUS_TOTAL
    );
  });
  const existDefectTotal = arrDefectTotal && arrDefectTotal.length > 0;
  const existSeriousTotal = arrSeriousTotal && arrSeriousTotal.length > 0;

  const excludeAttributesByCustom = [];
  if (attributeItemsByProcessor && attributeItemsByProcessor.length > 0) {
    attributeItemsByProcessor.map((c) => {
      const temp = bonusDeductItems.filter((x) => {
        return x.attribute === c.name; //Ex: "Defect: Custom 1"
      });
      if (temp && temp.length > 0 && c.formulas && c.formulas.length > 0) {
        c.formulas.map((f) => {
          excludeAttributesByCustom.push(f.attribute);
        });
      }
    });
  }

  const validItems = [];
  bonusDeductItems.map((x) => {
    if (
      x.attribute === "Defect: Adhering Hull" ||
      x.attribute === "Defect: Broken" ||
      x.attribute === "Defect: Splits"
      // || x.attribute === "Moisture %"
    ) {
      if (!existDefectTotal) {
        addValidItem(excludeAttributesByCustom, validItems, x);
      }
    } else if (
      x.attribute === "Serious: Insect" ||
      x.attribute === "Serious: Rancid" ||
      x.attribute === "Serious: Mold"
    ) {
      if (!existSeriousTotal) {
        addValidItem(excludeAttributesByCustom, validItems, x);
      }
    } else {
      addValidItem(excludeAttributesByCustom, validItems, x);
    }
  });

  return validItems;
}

function addValidItem(excludeAttributesByCustom, validItems, x) {
  if (
    excludeAttributesByCustom === undefined ||
    excludeAttributesByCustom === null ||
    excludeAttributesByCustom.length <= 0
  ) {
    validItems.push(x);
  } else {
    const temp = excludeAttributesByCustom.filter((c) => {
      return c === x.attribute;
    });
    if (temp.length <= 0) {
      validItems.push(x);
    }
  }
}

function findBonusDeductValue(
  bonusDeductItems,
  test,
  applyTo,
  attributeItemsByProcessor,
  testType,
  paymentNo,
  bonusDeductsAreApplied = []
) {
  if (testType.isDynamic && test.overridePrice && test.overridePrice > 0) {
    return 0;
  }
  if (
    bonusDeductItems === null ||
    bonusDeductItems === undefined ||
    bonusDeductItems.length <= 0
  ) {
    return 0;
  }

  let totalFieldValue = 0;
  let bonusDeductItemsTemp = [];
  const bonusDeductItemsOrigin =
    testType.varietyOptional === true
      ? bonusDeductItems
      : bonusDeductItems.filter((b) => {
          return (
            (b.varietyId === null || b.varietyId === undefined
              ? ""
              : b.varietyId.toString()) ===
            (test.varitySubmitted === null || test.varitySubmitted === undefined
              ? ""
              : test.varitySubmitted.toString())
          );
        });

  if (bonusDeductItemsOrigin && bonusDeductItemsOrigin.length > 0) {
    if (applyTo !== ``) {
      bonusDeductItemsTemp = bonusDeductItemsOrigin.filter((x) => {
        return x.applyTo === applyTo || x.applyTo === `Any`;
      });
    } else {
      bonusDeductItemsTemp = bonusDeductItemsOrigin.filter((x) => {
        return x.applyTo != `Inshell` && x.applyTo != `Shelled`;
      });
    }
  }

  if (bonusDeductItemsTemp && bonusDeductItemsTemp.length > 0) {
    bonusDeductItemsTemp.forEach((bonusDeduct) => {
      bonusDeductsAreApplied.push(bonusDeduct);
    });
    bonusDeductItemsTemp.map((b) => {
      let fieldValue = 0;
      if (
        paymentNo !== undefined &&
        paymentNo > 0 &&
        b.applyType === constant.TAXFEE_APPLY_TYPE_FIRST
      ) {
        fieldValue = 0;
      } else {
        if (testType.isDynamic) {
          let existInCustomField = false;
          const customFields =
            test.customFields && test.customFields !== ""
              ? JSON.parse(test.customFields)
              : [];
          if (customFields && customFields.length > 0) {
            const field = customFields.filter((f) => {
              return f.name == b.attribute;
            });
            if (field && field.length > 0) {
              fieldValue = field[0].value;
              existInCustomField = true;
            }
          }
          if (existInCustomField !== true) {
            //lookup in custom attributes of processor
            fieldValue = findBonusDeductValueFromCustomAttributes(
              b,
              test,
              attributeItemsByProcessor,
              testType
            );
          }
        } else {
          switch (b.attribute) {
            case "Color: Amber":
              fieldValue = test.s2Amber;
              break;
            case "Color: Black":
              fieldValue = test.s2Black;
              break;
            case "Color: Light Amber":
              fieldValue = test.s2LightAmber;
              break;
            case "Color: Light":
              fieldValue = test.s2Light;
              break;

            case "Defect: Adhering Hull":
              fieldValue = test.s1AdheringHull;
              break;
            case "Defect: Broken":
              fieldValue = test.s1Broken;
              break;
            case "Defect: Splits​":
              fieldValue = test.s1Splits;
              break;
            case "Moisture %":
              fieldValue = test.moisture;
              break;
            case process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT_DEFECT_TOTAL:
              {
                fieldValue =
                  test.s1AdheringHull + test.s1Broken + test.s1Splits;
              }
              break;

            case "Serious: Blows":
              fieldValue = test.s2Blows;
              break;
            case "Serious: Insect":
              fieldValue = test.s2Insect;
              break;
            case "Serious: Mold":
              fieldValue = test.s2Mold;
              break;
            case "Serious: Other":
              fieldValue = test.s2Other;
              break;
            case "Serious: Rancid":
              fieldValue = test.s2Rancid;
              break;
            case "Serious: Shrivel":
              fieldValue = test.s2Shrivel;
              break;
            case process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT_SERIOUS_TOTAL:
              fieldValue = test.s2Insect + test.s2Rancid + test.s2Mold;
              break;

            case "Size: Baby":
              fieldValue = test.s1Baby;
              break;
            case "Size: Jumbo":
              fieldValue = test.s1Jumbo;
              break;
            case "Size: Large":
              fieldValue = test.s1Large;
              break;
            case "Size: Medium":
              fieldValue = test.s1Medium;
              break;
            case "Size: Pee Wee​":
              fieldValue = test.s1PeeWee;
              break;

            //lookup in custom attributes of processor
            default:
              {
                fieldValue = findBonusDeductValueFromCustomAttributes(
                  b,
                  test,
                  attributeItemsByProcessor,
                  testType
                );
              }
              break;
          }
        }
        if (fieldValue >= b.min && fieldValue <= b.max) {
          totalFieldValue += b.value;
        }
      }
    });
  }

  return totalFieldValue;
}

function findBonusDeductValueFromCustomAttributes(
  b,
  walnutTest,
  attributeItemsByProcessor,
  testType
) {
  let totalFieldValue = 0;
  if (attributeItemsByProcessor && attributeItemsByProcessor.length > 0) {
    const temp = attributeItemsByProcessor.filter((c) => {
      return c.name === b.attribute;
    });
    if (
      temp &&
      temp.length > 0 &&
      temp[0].formulas &&
      temp[0].formulas.length > 0
    ) {
      if (testType.isDynamic) {
        temp[0].formulas.map((f) => {
          let fieldValue = 0;
          const customFields =
            walnutTest.customFields && walnutTest.customFields !== ""
              ? JSON.parse(walnutTest.customFields)
              : [];
          if (customFields && customFields.length > 0) {
            const field = customFields.filter((f) => {
              return f.name == f.attribute;
            });
            if (field && field.length > 0) {
              fieldValue = field[0].value;
            }
          }
          fieldValue = fieldValue * f.value;
          totalFieldValue = totalFieldValue + fieldValue;
        });
      } else {
        temp[0].formulas.map((f) => {
          let fieldValue = 0;
          switch (f.attribute) {
            case "Color: Amber":
              fieldValue = walnutTest.s2Amber;
              break;
            case "Color: Black":
              fieldValue = walnutTest.s2Black;
              break;
            case "Color: Light Amber":
              fieldValue = walnutTest.s2LightAmber;
              break;
            case "Color: Light":
              fieldValue = walnutTest.s2Light;
              break;

            case "Defect: Adhering Hull":
              fieldValue = walnutTest.s1AdheringHull;
              break;
            case "Defect: Broken":
              fieldValue = walnutTest.s1Broken;
              break;
            case "Defect: Splits​":
              fieldValue = walnutTest.s1Splits;
              break;
            case "Moisture %":
              fieldValue = walnutTest.moisture;
              break;

            case "Serious: Blows":
              fieldValue = walnutTest.s2Blows;
              break;
            case "Serious: Insect":
              fieldValue = walnutTest.s2Insect;
              break;
            case "Serious: Mold":
              fieldValue = walnutTest.s2Mold;
              break;
            case "Serious: Other":
              fieldValue = walnutTest.s2Other;
              break;
            case "Serious: Rancid":
              fieldValue = walnutTest.s2Rancid;
              break;
            case "Serious: Shrivel":
              fieldValue = walnutTest.s2Shrivel;
              break;

            case "Size: Baby":
              fieldValue = walnutTest.s1Baby;
              break;
            case "Size: Jumbo":
              fieldValue = walnutTest.s1Jumbo;
              break;
            case "Size: Large":
              fieldValue = walnutTest.s1Large;
              break;
            case "Size: Medium":
              fieldValue = walnutTest.s1Medium;
              break;
            case "Size: Pee Wee​":
              fieldValue = walnutTest.s1PeeWee;
              break;
            default:
              fieldValue = 0;
              break;
          }
          fieldValue = fieldValue * f.value;
          totalFieldValue = totalFieldValue + fieldValue;
        });
      }
    }
  }

  return totalFieldValue;
}

function composeListInvalidExcludedGrowers(
  testType,
  varietyItems,
  testItemsByCropYear,
  growerItems,
  paymentSettingItems
) {
  let excludedGrowers = [];
  let uniqueVarietyIds = [];
  //If payment settings by variety

  if (testType.paymentByVariety === true) {
    uniqueVarietyIds = lodash.uniqBy(
      paymentSettingItems.map((x) =>
        x.varietyId ? x.varietyId.toString() : ``
      ),
      (a) => a
    );
    uniqueVarietyIds.map((varietyId) => {
      let varietyName = ``;
      const tempVariety = varietyItems.filter((v) => {
        return v._id == varietyId;
      });
      if (tempVariety && tempVariety.length > 0) {
        varietyName = tempVariety[0].name;
      }
      const paymentsByVarietyId = paymentSettingItems.filter((x) => {
        return (x.varietyId ? x.varietyId.toString() : ``) === varietyId;
      });
      const temp = composeInvalidExcludedGrowers(
        paymentsByVarietyId,
        testItemsByCropYear,
        growerItems
      );
      if (temp && temp.length > 0) {
        temp.map((x) => {
          const model = JSON.parse(JSON.stringify(x));
          model.varietyName = varietyName;
          excludedGrowers.push(model);
        });
      }
    });
  } else {
    excludedGrowers = composeInvalidExcludedGrowers(
      paymentSettingItems,
      testItemsByCropYear,
      growerItems
    );
  }

  return {
    excludedGrowers: excludedGrowers,
    uniqueVarietyIds: uniqueVarietyIds,
  };
}

function composeInvalidExcludedGrowers(
  paymentSettingItems,
  testItemsByCropYear,
  growerItems
) {
  const excludedGrowers = [];
  //default payment settings
  let totalPercentPaymentSettingsDefault = 0;
  const paymentSettingDefaultItems = paymentSettingItems.filter((pg) => {
    return (
      pg.growerId === null ||
      pg.growerId === undefined ||
      pg.growerId.toString() === ""
    );
  });

  if (paymentSettingDefaultItems && paymentSettingDefaultItems.length > 0) {
    paymentSettingDefaultItems.map((x) => {
      totalPercentPaymentSettingsDefault =
        Math.round(
          (totalPercentPaymentSettingsDefault + x.paymentPercent) * 100
        ) / 100;
    });
  }

  growerItems.map((g) => {
    const growerId = g._id.toString();
    //exclude any growers without any test in crop year
    const walnutTestItemsByGrower = testItemsByCropYear.filter((w) => {
      return w.growerName === growerId;
    });
    if (
      walnutTestItemsByGrower === undefined ||
      walnutTestItemsByGrower === null ||
      walnutTestItemsByGrower.length <= 0
    ) {
      const temp = excludedGrowers.filter((x) => {
        return x.growerId === g._id;
      });
      if (temp === undefined || temp === null || temp.length <= 0) {
        excludedGrowers.push({
          growerId: g._id,
          growerName: g.name,
          error: "There is no test",
        });
      }
    } else {
      //Continue check valid payment percent
      let totalPercentPaymentSettings = 0;
      //Only reponse grower that has payment settings 100%.
      let isValidPaymentSetting = false;
      //Find custom payment settings first, if not exist then get from default setting
      let paymentSettingByGrowerItems = paymentSettingItems.filter((pg) => {
        if (
          (pg.growerId === null || pg.growerId === undefined
            ? ""
            : pg.growerId.toString()) === growerId
        ) {
          totalPercentPaymentSettings =
            Math.round(
              (totalPercentPaymentSettings + pg.paymentPercent) * 100
            ) / 100;
          return true;
        }
        return false;
      });

      if (
        paymentSettingByGrowerItems === null ||
        paymentSettingByGrowerItems === undefined ||
        paymentSettingByGrowerItems.length <= 0
      ) {
        paymentSettingByGrowerItems = paymentSettingDefaultItems;
        isValidPaymentSetting = totalPercentPaymentSettingsDefault === 100;
      } else {
        isValidPaymentSetting = totalPercentPaymentSettings === 100;
      }

      if (
        isValidPaymentSetting !== true ||
        paymentSettingByGrowerItems === undefined ||
        paymentSettingByGrowerItems === null ||
        paymentSettingByGrowerItems.length <= 0
      ) {
        const temp = excludedGrowers.filter((x) => {
          return x.growerId === g._id;
        });
        if (temp === undefined || temp === null || temp.length <= 0) {
          excludedGrowers.push({
            growerId: g._id,
            growerName: g.name,
            error: "Invalid payment setting.",
          });
        }
      }
    }
  });

  return excludedGrowers;
}

async function composeGroweStatementFields(testType) {
  testType = JSON.parse(JSON.stringify(testType));
  const fields = [];
  const selectedFields = [];

  const nutTypeFields = await getNutTypeFieldsWithConditions(
    {
      nutTypeId: testType._id,
    },
    "section testFieldId",
    ""
  );
  if (nutTypeFields && nutTypeFields.length > 0) {
    const testFieldIds = nutTypeFields.map((x) => x.testFieldId);
    const testFields = await getTestFieldsWithConditions(
      {
        _id: { $in: testFieldIds },
        status: "Active",
      },
      "",
      ""
    );
    if (testFields && testFields.length > 0) {
      nutTypeFields.map((x) => {
        let name = ``;
        const temp = testFields.filter((f) => {
          return f._id == x.testFieldId;
        });
        if (temp && temp.length > 0) {
          name = `${temp[0].name}`;
          if (name && name !== ``) {
            x = JSON.parse(JSON.stringify(x));
            x.name = name;
            x.isDynamicField = true;
            x.fieldType = temp[0].fieldType;
            fields.push(x);
          }
        }
      });
    }
  }
  //add more static fields
  composeStaticFields(testType, fields);
  fields.sort(utilities.getSortOrder("name"));
  testType.fields = fields;

  //selected fields
  const growerStatementFields =
    await getNutTypeGrowerStatementFieldsWithConditions(
      {
        nutTypeId: testType._id,
      },
      "",
      "sequence"
    );
  if (growerStatementFields && growerStatementFields.length > 0) {
    growerStatementFields.map((x) => {
      let name = ``;
      const temp = fields.filter((f) => {
        return f.testFieldId == x.testFieldId;
      });
      if (temp && temp.length > 0) {
        name = temp[0].name;
        x = JSON.parse(JSON.stringify(x));
        x.name = name;
        x.fieldType = temp[0].fieldType;
        selectedFields.push(x);
      }
    });
  }
  testType.selectedFields = selectedFields;

  testType.fields = testType.fields.filter(
    (elem) =>
      !testType.selectedFields.find(
        ({ testFieldId }) => elem.testFieldId === testFieldId
      ) && elem.testFieldId
  );

  return testType;
}

function composeStaticFields(nutType, fields) {
  fields.push({
    _id: "certificateNo",
    name: "Cert Number",
    isDynamicField: false,
    testFieldId: "certificateNo",
    section: "Header",
  });
  if (nutType.fieldNameHide !== true) {
    fields.push({
      _id: "fieldName",
      name: "Field Name",
      isDynamicField: false,
      testFieldId: "fieldName",
      section: "Header",
    });
  }
  fields.push({
    _id: "varitySubmitted",
    name: "Variety",
    isDynamicField: false,
    testFieldId: "varitySubmitted",
    section: "Header",
  });
  if (nutType.typeNumberOfContainersHide !== true) {
    fields.push({
      _id: "typeNumberOfContainers",
      name: "Type of Containers",
      isDynamicField: false,
      testFieldId: "typeNumberOfContainers",
      section: "Header",
    });
  }
  if (nutType.dfaInspectorHide !== true) {
    fields.push({
      _id: "dfaInspector",
      name: "Inspector/Tested By",
      isDynamicField: false,
      testFieldId: "dfaInspector",
      section: "Header",
    });
  }
}

async function buildCustomFields(tests, testType) {
  const nutTypeItems = [];
  const nutTypeFields = await getNutTypeFieldsWithConditions(
    {
      nutTypeId: testType._id,
    },
    constant.EXCLUDE_SYSTEM_FIELDS,
    ""
  );

  //Query list of dynamic fields, only apply for test type dynamic
  let classificationItems = [];
  let fields = [];
  let idQualityDataFields = [];
  if (nutTypeFields && nutTypeFields.length > 0) {
    if (testType.multiTestsPerLot === true) {
      idQualityDataFields = nutTypeFields
        .filter((x) => {
          return x.section == constant.SECTION_QUALITY_DATA;
        })
        .map((k) => k.testFieldId);
    }

    const testFieldIds = nutTypeFields.map((k) => k.testFieldId);

    fields = await getTestFieldsWithConditions(
      {
        status: `Active`,
        _id: { $in: testFieldIds },
      },
      `name fieldType precision testClassificationId`,
      ``
    );
  }

  nutTypeItems.push({
    _id: testType._id,
    name: testType.name,
    isDynamic: true,
    xpool: testType.xpool,
    basePriceByPayment: testType.basePriceByPayment,
    paymentPercentBased: testType.paymentPercentBased,
    multiPricesSameLot: testType.multiPricesSameLot,
    singlePricePerLot: testType.singlePricePerLot,
    singlePricePerLotField: testType.singlePricePerLotField,
    multiTestsPerLot: testType.multiTestsPerLot,
    fields: fields,
  });

  if (fields && fields.length > 0) {
    const classificationFields = fields.filter((x) => {
      return x.fieldType === constant.FIELD_TYPE_DROP_DOWN;
    });
    if (classificationFields && classificationFields.length > 0) {
      let classificationIds = classificationFields.map(
        (x) => x.testClassificationId
      );
      classificationIds = classificationIds.filter(utilities.onlyUnique);
      classificationItems = await getClassificationItemsWithConditions(
        {
          parentId: { $in: classificationIds },
        },
        ``,
        ``
      );
    }
  }
  const newTests = [];
  tests.map((test) => {
    newTests.push(
      composeCustomFields4Display(
        test,
        test,
        fields,
        classificationItems,
        testType,
        idQualityDataFields
      )
    );
  });
  return newTests;
}

function composeCustomFields4Display(
  test,
  viewModel,
  testFields,
  classificationItems,
  testType,
  idQualityDataFields
) {
  //If dynamic test > compose dynamic fields
  if (
    testFields &&
    testFields.length > 0 &&
    test.customFields &&
    test.customFields !== ``
  ) {
    const customFieldsDisplay = [];
    const customFields = JSON.parse(test.customFields);
    let qualityDataFields = [];
    if (
      testType.multiTestsPerLot === true &&
      test.qualityData &&
      test.qualityData !== ``
    ) {
      qualityDataFields = JSON.parse(test.qualityData);
    }
    testFields.map((f) => {
      let value = undefined;
      if (customFields && customFields.length > 0) {
        let tempCustomField = undefined;
        //In case "Multiple Tests Per LOT", query test field from quality data array instead of customFields
        if (
          testType.multiTestsPerLot === true &&
          idQualityDataFields.includes(f._id.toString())
        ) {
          tempCustomField = qualityDataFields.filter((c) => {
            return c.type === testType.multiTestGrowerPaymentType;
          });
          if (tempCustomField && tempCustomField.length > 0) {
            value = tempCustomField[0][f._id.toString()];
          }
        } else {
          tempCustomField = customFields.filter((c) => {
            return c._id === f._id.toString();
          });
          if (tempCustomField && tempCustomField.length > 0) {
            value = tempCustomField[0].value;
          }
        }

        switch (f.fieldType) {
          case constant.FIELD_TYPE_DROP_DOWN:
            if (
              value &&
              value !== `` &&
              classificationItems &&
              classificationItems.length > 0
            ) {
              //Query data from classification item
              const tempClassification = classificationItems.filter((c) => {
                return c._id == value;
              });
              if (tempClassification && tempClassification.length > 0) {
                value = tempClassification[0].name;
              }
            }
            break;
        }
      }
      if (value === null || value === undefined) {
        value =
          f.fieldType === constant.FIELD_TYPE_DROP_DOWN ||
          f.fieldType === constant.FIELD_TYPE_TEXT_BOX ||
          f.fieldType === constant.FIELD_TYPE_TEXT_SMALL
            ? ``
            : 0;
      }
      customFieldsDisplay.push({
        name: f.name,
        value: value,
      });
    });
    viewModel = JSON.parse(JSON.stringify(viewModel));
    viewModel.customFields = JSON.stringify(customFieldsDisplay);
  }
  return viewModel;
}

async function queryPriceFields(testType, processorId) {
  let fieldNames =
    testType.multiPricesSameLot === true
      ? testType.multiplePriceField
      : testType.singlePricePerLotField;
  if (fieldNames === undefined || fieldNames === null || fieldNames === ``) {
    return [];
  }

  let priceFields = [];
  //Query items classification with name in list fieldNames
  const arrFields = fieldNames.split(",");
  if (testType.singlePricePerLot === true) {
    return arrFields;
  }
  const classifications = await getClassificationsWithConditions(
    {
      name: { $in: arrFields },
      processorId: { $in: [processorId, "", null, undefined] },
    },
    ``,
    ``
  );

  if (classifications && classifications.length > 0) {
    let classificationIds = classifications.map((x) => x._id);
    const classificationItems = await getClassificationItemsWithConditions(
      {
        parentId: { $in: classificationIds },
      },
      ``,
      ``
    );
    if (classificationItems) {
      classificationItems.map((ci) => {
        priceFields.push(ci.name);
      });
    }

    //some of field in arrFields not really dropdown field
    let classificationNames = classifications.map((x) => x.name);
    arrFields.map((f) => {
      if (
        classificationNames.includes(f) !== true &&
        priceFields.includes(f) !== true
      ) {
        priceFields.push(f);
      }
    });
  } else {
    // arrFields are not dropdown field
    priceFields = arrFields;
  }

  return priceFields;
}

function findPaidStatement(
  growerStatementByCropYear,
  paymentSettingId,
  growerId,
  status
) {
  if (
    growerStatementByCropYear === undefined ||
    growerStatementByCropYear === null
  ) {
    return undefined;
  }
  const tempGrowerStatementByPayment = growerStatementByCropYear.filter((u) => {
    return (
      (u.paymentId === undefined || u.paymentId === null
        ? ""
        : u.paymentId.toString()) === paymentSettingId &&
      (u.growerId === undefined || u.growerId === null
        ? ""
        : u.growerId.toString()) === growerId
    );
  });
  if (tempGrowerStatementByPayment && tempGrowerStatementByPayment.length > 0) {
    const paidStatement = tempGrowerStatementByPayment[0].paidStatement;
    //If statement generated and status is paid. Load from database instead re-calculate.
    if (
      paidStatement !== null &&
      paidStatement !== undefined &&
      paidStatement !== ""
    ) {
      if (status) {
        if (status === tempGrowerStatementByPayment[0].status) {
          return JSON.parse(paidStatement);
        }
        return undefined;
      }
      return JSON.parse(paidStatement);
    }
  }
  return undefined;
}

function calculateCashContractPriceSumItems(
  walnutTestItemsByPayment,
  basePriceItemsByGrower,
  cashContractPriceItemsByGrower
) {
  let cashContractPriceSumItems = [];

  //distinct varitySubmitted and sum netWeight to determine any varitySubmitted has cash price
  const uniqueWalnutTests = lodash.uniqBy(
    walnutTestItemsByPayment.map((w) => w.varitySubmitted),
    (a) => a
  );
  if (uniqueWalnutTests && uniqueWalnutTests.length > 0) {
    uniqueWalnutTests.map((x) => {
      const walnutTestItemsByPayment1 = walnutTestItemsByPayment.filter((w) => {
        return x === w.varitySubmitted;
      });
      let sumInshell = 0;
      if (walnutTestItemsByPayment1 && walnutTestItemsByPayment1.length > 0) {
        sumInshell = lodash.sumBy(walnutTestItemsByPayment1, (o) => {
          return o.netWeight;
        });
      }
      if (sumInshell > 0) {
        const cashContractPriceItemsTemp =
          cashContractPriceItemsByGrower.filter((b) => {
            return (
              (b.varietyId === null || b.varietyId === undefined
                ? ""
                : b.varietyId.toString()) === x
            );
          });
        if (
          cashContractPriceItemsTemp &&
          cashContractPriceItemsTemp.length > 0
        ) {
          if (sumInshell >= cashContractPriceItemsTemp[0].amount) {
            cashContractPriceSumItems.push({
              varitySubmitted: x,
              total: sumInshell,
              amount: cashContractPriceItemsTemp[0].amount,
              valueCashPrice: cashContractPriceItemsTemp[0].valueCashPrice,
            });
          }
        }
      }
    });
  }

  return cashContractPriceSumItems;
}

function GetValuesBasePrice(
  basePriceItemsByGrower,
  varitySubmitted,
  testType,
  test,
  paymentSettingId,
  priceFields
) {
  let applyTo = ``;
  let isInshell = false;
  let isShelled = false;
  let basePriceByVariety = 0;
  let valueInshell = 0;
  let valueShelled = 0;
  let basePriceByMetaAttributes = [];
  let originalPrice = [];

  if (testType.isDynamic && test.overridePrice && test.overridePrice > 0) {
    isInshell = true;
    basePriceByVariety = test.overridePrice;
    valueInshell = test.overridePrice;
    valueShelled = test.overridePrice;
  } else {
    if (basePriceItemsByGrower && basePriceItemsByGrower.length > 0) {
      let basePriceItemsTemp =
        testType.varietyOptional === true
          ? basePriceItemsByGrower
          : basePriceItemsByGrower.filter((b) => {
              return (
                (b.varietyId === null || b.varietyId === undefined
                  ? ""
                  : b.varietyId.toString()) === varitySubmitted
              );
            });

      //If test type setup base price by payment, continue filter by payment
      if (
        basePriceItemsTemp &&
        basePriceItemsTemp.length > 0 &&
        testType.basePriceByPayment === true
      ) {
        basePriceItemsTemp = basePriceItemsTemp.filter((b) => {
          return (
            (b.paymentId === null || b.paymentId === undefined
              ? ""
              : b.paymentId.toString()) === paymentSettingId
          );
        });
      }
      //If test type setup base price by multiple prices by meta attribute same LOT, continue filter by meta attribute
      if (
        basePriceItemsTemp &&
        basePriceItemsTemp.length > 0 &&
        (testType.multiPricesSameLot === true ||
          testType.singlePricePerLot === true)
      ) {
        if (priceFields) {
          const testCustomFields =
            test.customFields && test.customFields != ``
              ? JSON.parse(test.customFields)
              : [];

          const basePriceField =
            testType.multiPricesSameLot === true
              ? testType.basePriceField
              : testType.singlePricePerLotField;
          const basePriceFields = basePriceField
            ? basePriceField.trim().split(`,`)
            : [];

          if (testType.singlePricePerLot === true) {
            let basePrice = 0;
            basePriceItemsTemp.map((b) => {
              let countExistAttribute = 0;
              let customAttributesLength = 0;
              if (b.customAttributes) {
                const customAttributesJSON = b.customAttributes;
                if (customAttributesJSON && customAttributesJSON !== ``) {
                  const customAttributes = JSON.parse(customAttributesJSON);
                  customAttributesLength = customAttributes.length;
                  if (customAttributes && customAttributes.length > 0) {
                    priceFields.map((x) => {
                      const testFieldValue = testCustomFields.filter((t) => {
                        return t.name == x;
                      });
                      let testValue;
                      if (testFieldValue && testFieldValue.length > 0) {
                        testValue = testFieldValue[0].value;

                        let arrTemp = customAttributes.filter((c) => {
                          return c.value == testValue && c.attribute == x;
                        });
                        if (arrTemp && arrTemp.length > 0) {
                          countExistAttribute++;
                        }
                      }
                    });
                  }
                }
              }

              if (
                countExistAttribute === priceFields.length &&
                countExistAttribute === customAttributesLength
              ) {
                basePrice = b.valueInshell;
                basePriceByMetaAttributes.push(basePrice);
                originalPrice.push(b);

                return {
                  applyTo: applyTo,
                  isInshell: true,
                  isShelled: isShelled,
                  basePriceByVariety: basePriceByVariety,
                  basePriceByMetaAttributes: basePriceByMetaAttributes,
                  valueInshell: valueInshell,
                  valueShelled: valueShelled,
                  originalPrice: originalPrice,
                };
              }
            });

            const returnPrice = {
              applyTo: applyTo,
              isInshell: true,
              isShelled: isShelled,
              basePriceByVariety: basePriceByVariety,
              basePriceByMetaAttributes: basePriceByMetaAttributes,
              valueInshell: valueInshell,
              valueShelled: valueShelled,
              originalPrice: originalPrice,
            };

            return returnPrice;
          } else {
            priceFields.map((x) => {
              let basePrice = 0;
              let existMainAttribute = false;
              let existAttribute = true;
              basePriceItemsTemp.map((b) => {
                if (b.customAttributes) {
                  /*
                Sample value for customAttributes
                [
                  {
                      "_id": "0f5de3a2-ed19-4848-9db6-994840bb6fb4",
                      "attribute": "Size",
                      "value": "Culls",
                      "testFieldId": "62b134d3b406d70b3475e52d",
                      "extraValue": "62b1344ab406d70b3475e52b"
                  },
                  {
                      "_id": "209641aa-8f6f-4c61-9065-0489d331eaa2",
                      "attribute": "Simple Text",
                      "value": "Test",
                      "testFieldId": "62397c2da8c60c53a8cf3266",
                      "extraValue": ""
                  }
                ]
                */
                  const customAttributesJSON = b.customAttributes;
                  if (customAttributesJSON && customAttributesJSON !== ``) {
                    const customAttributes = JSON.parse(customAttributesJSON);
                    if (customAttributes && customAttributes.length > 0) {
                      let arrTempMain = customAttributes.filter((c) => {
                        return (
                          c.value == x &&
                          c.attribute == testType.multiplePriceField
                        );
                      });
                      existMainAttribute =
                        arrTempMain && arrTempMain.length > 0;

                      let arrTemp = customAttributes.filter((c) => {
                        return c.attribute != testType.multiplePriceField;
                      });
                      if (arrTemp.length > 0) {
                        if (basePriceFields.length > 0) {
                          existAttribute = false;
                          let countExistAttribute = 0;
                          basePriceFields.map((f) => {
                            const testFieldValue = testCustomFields.filter(
                              (t) => {
                                return t.name == f;
                              }
                            );
                            if (testFieldValue && testFieldValue.length > 0) {
                              const testValue = testFieldValue[0].value;
                              if (
                                arrTemp.filter((c) => {
                                  return (
                                    c.value == testValue && c.attribute == f
                                  );
                                }).length > 0
                              ) {
                                countExistAttribute++;
                              }
                            }
                          });

                          if (countExistAttribute == basePriceFields.length) {
                            existAttribute = true;
                          }
                        }
                      }
                    }
                  }
                  if (existMainAttribute && existAttribute) {
                    basePrice = b.valueInshell;
                    originalPrice.push(b);
                  }
                }
              });

              basePriceByMetaAttributes.push({
                name: x,
                value: basePrice,
              });
            });
          }

          const returnPrice = {
            applyTo: applyTo,
            isInshell: true,
            isShelled: isShelled,
            basePriceByVariety: basePriceByVariety,
            basePriceByMetaAttributes: basePriceByMetaAttributes,
            valueInshell: valueInshell,
            valueShelled: valueShelled,
            originalPrice: originalPrice,
          };

          return returnPrice;
        }
      }

      if (basePriceItemsTemp && basePriceItemsTemp.length > 0) {
        if (testType.isDynamic) {
          isInshell = true;
          basePriceByVariety = basePriceItemsTemp[0].valueInshell;
          if (testType.xpool === true) {
            valueInshell = basePriceItemsTemp[0].valueInshell;
            valueShelled = basePriceItemsTemp[0].valueShelled;
          }
        } else {
          if (basePriceItemsTemp[0].primaryPrice === "Inshell") {
            applyTo = `Inshell`;
            isInshell = true;
            basePriceByVariety = basePriceItemsTemp[0].valueInshell;
          } else if (basePriceItemsTemp[0].primaryPrice === "Shelled") {
            applyTo = `Shelled`;
            isShelled = true;
            basePriceByVariety = basePriceItemsTemp[0].valueShelled;
          } else if (basePriceItemsTemp[0].primaryPrice === "Highest of Both") {
            if (
              basePriceItemsTemp[0].valueInshell >
              basePriceItemsTemp[0].valueShelled
            ) {
              applyTo = `Inshell`;
              isInshell = true;
              basePriceByVariety = basePriceItemsTemp[0].valueInshell;
            } else if (
              basePriceItemsTemp[0].valueInshell <
              basePriceItemsTemp[0].valueShelled
            ) {
              applyTo = `Shelled`;
              isShelled = true;
              basePriceByVariety = basePriceItemsTemp[0].valueShelled;
            } else {
              isShelled = true;
              basePriceByVariety = basePriceItemsTemp[0].valueShelled;
            }
          }
        }
        originalPrice = basePriceItemsTemp;
      }
    }
  }

  return {
    applyTo: applyTo,
    isInshell: isInshell,
    isShelled: isShelled,
    basePriceByVariety: basePriceByVariety,
    basePriceByMetaAttributes: basePriceByMetaAttributes,
    valueInshell: valueInshell,
    valueShelled: valueShelled,
    originalPrice: originalPrice,
  };
}

function findPrice4SinglePricePerLot(valuesBasePrice, test, testType) {
  /*
  Sample value for valuesBasePrice
    {
    applyTo: '',
    isInshell: true,
    isShelled: false,
    basePriceByVariety: 0,
    basePriceByMetaAttributes: [11.9],
    valueInshell: 0,
    valueShelled: 0
  }
  */
  const basePriceByMetaAttributes = valuesBasePrice.basePriceByMetaAttributes;
  let price = 0.0;
  if (basePriceByMetaAttributes && basePriceByMetaAttributes.length > 0) {
    price = basePriceByMetaAttributes[0];
  }

  return price;
}

function calculatePaymentByMultiPricesSameLot(
  valuesBasePrice,
  lbs,
  test,
  paymentPercent,
  priceFields
) {
  /*
  Sample value for basePriceByMetaAttributes
  [
    { name: 'Regular (2 1/2 to 3 1/2)', value: 7 },
    { name: 'Over size (Over 3 1/2)', value: 9 },
    { name: 'Undersize (under 2 1/2)', value: 6 },
    { name: 'Culls', value: 5 }
  ]*/
  const basePriceByMetaAttributes = valuesBasePrice.basePriceByMetaAttributes;
  let totalPayment = 0;
  let paymentsByPriceFields = [];
  const customFields =
    test.customFields && test.customFields !== ""
      ? JSON.parse(test.customFields)
      : [];
  if (customFields && customFields.length > 0) {
    /*
    Example priceFields
    Regular (2 1/2 to 3 1/2),Over size (Over 3 1/2),Undersize (under 2 1/2),Culls
    */
    const filterFields = priceFields;
    const fields = customFields.filter((f) => {
      return filterFields.includes(f.name);
    });
    if (fields && fields.length > 0) {
      fields.map((f) => {
        let price = 0.0;
        let payment = 0.0;
        if (basePriceByMetaAttributes && basePriceByMetaAttributes.length > 0) {
          const temp = basePriceByMetaAttributes.filter((c) => {
            return c.name == f.name;
          });
          if (temp && temp.length > 0) {
            price = temp[0].value;
          }
        }
        let percent = 0.0;
        if (f.value && f.value !== ``) {
          try {
            //f.value is percent so need to devide 100 first
            percent = parseFloat(f.value) / 100.0;
            payment = percent * price * lbs;
            totalPayment += isNaN(payment) ? 0.0 : payment;
          } catch {}
        }
        paymentsByPriceFields.push({
          testId: test._id.toString(),
          field: f.name,
          percent: percent,
          payment: payment,
        });
      });
      totalPayment = totalPayment * paymentPercent;
    }
  }

  return {
    totalPayment: totalPayment,
    paymentsByPriceFields: paymentsByPriceFields,
  };
}

function composeSelectedFields(
  testType,
  test,
  itemPayment,
  onlyPushEmptyValue,
  labUsers,
  varieties
) {
  if (testType.selectedFields && testType.selectedFields.length > 0) {
    if (onlyPushEmptyValue) {
      testType.selectedFields.map((f) => {
        itemPayment[f.name] = "";
      });
    } else {
      testType.selectedFields.map((f) => {
        let fieldValue;
        if (f.isDynamicField == `true`) {
          const customFields =
            test.customFields && test.customFields !== ""
              ? JSON.parse(test.customFields)
              : [];
          if (customFields && customFields.length > 0) {
            const temp = customFields.filter((x) => {
              return x.name == f.name;
            });
            if (temp && temp.length > 0) {
              if (f.fieldType === constant.FIELD_TYPE_PERCENT) {
                fieldValue = `${temp[0].value}%`;
              }
              // if (f.fieldType === constant.FIELD_TYPE_DROP_DOWN) {
              //   fieldValue = `FIELD_TYPE_DROP_DOWN`;
              // }
              else {
                fieldValue = temp[0].value;
              }
            }
          }
        } else {
          switch (f.testFieldId) {
            case "varitySubmitted":
              const variety = varieties.filter((e) => {
                return e._id == test[f.testFieldId];
              });
              if (variety && variety.length > 0) {
                fieldValue = variety[0].name;
              }
              break;
            case "dfaInspector":
              const labUser = labUsers.filter((e) => {
                return e._id == test[f.testFieldId];
              });
              if (labUser && labUser.length > 0) {
                fieldValue = labUser[0].displayName;
              }
              break;
            default:
              fieldValue = test[f.testFieldId];
              break;
          }
        }
        itemPayment[f.name] = fieldValue;
      });
    }
  }

  if (test) {
    itemPayment.customFields = test.customFields;
  }
  return itemPayment;
}

function calculateListTaxFee(
  previousWalnutTestCalculated,
  taxFeeItemsByGrower,
  paymentNo,
  testType,
  totalLbs,
  countPayments,
  totalPaymentPercent,
  walnutTestItemsByPayment,
  itemsPayment4TaxFee,
  priceFields,
  paymentsByPriceFields,
  allItemsTaxFee
) {
  const itemsTaxFee = [];
  let totalPaymentTaxFee = 0;
  let totalYtdTaxFee = 0;
  if (taxFeeItemsByGrower && taxFeeItemsByGrower.length > 0) {
    const previousTaxFeeCalculated = [];
    taxFeeItemsByGrower.map((taxFee) => {
      //If payment mark paid
      const paymentObject = previousWalnutTestCalculated.find((e) => {
        return e.paymentNo == paymentNo && e.paidStatementObject !== undefined;
      });
      let isIgnoreRecalculate = false;
      const taxFeeName = taxFee.name.toLowerCase().trim();
      //Payment mark paid
      if (paymentObject) {
        isIgnoreRecalculate = true;
        if (
          paymentObject.paidStatementObject.itemsTaxFee &&
          paymentObject.paidStatementObject.itemsTaxFee.length > 0
        ) {
          const previousTaxFee =
            paymentObject.paidStatementObject.itemsTaxFee.find((x) => {
              return x.name.toLowerCase().trim() === taxFeeName;
            });
          if (previousTaxFee) {
            const paymentTaxFee = parseFloat(
              previousTaxFee.payment
                .replace(`(`, ``)
                .replace(`)`, ``)
                .replace(`$`, ``)
                .replace(`,`, ``)
            );
            const ytdTaxFee = parseFloat(
              previousTaxFee.ytd
                .replace(`(`, ``)
                .replace(`)`, ``)
                .replace(`$`, ``)
                .replace(`,`, ``)
            );

            previousTaxFeeCalculated.push({
              paymentNo: paymentNo,
              _id: taxFee._id,
              payment: Math.abs(paymentTaxFee),
              ytd: Math.abs(ytdTaxFee),
            });
          }
        }
      } else if (
        taxFee.applyType === constant.TAXFEE_APPLY_TYPE_NEXT_AVAILABLE &&
        paymentNo > 0 &&
        allItemsTaxFee &&
        allItemsTaxFee.length > 0
      ) {
        const previousTaxFee = allItemsTaxFee.find((e) => {
          return (
            e.paymentNo < paymentNo &&
            e.itemsTaxFee &&
            e.itemsTaxFee.length > 0 &&
            e.itemsTaxFee.some((x) => {
              return x.name.toLowerCase().trim() === taxFeeName;
            })
          );
        });
        if (previousTaxFee) {
          const itemsTaxFee = previousTaxFee.itemsTaxFee.find((x) => {
            return x.name.toLowerCase().trim() === taxFeeName;
          });
          const payment = parseFloat(
            itemsTaxFee.payment
              .replace(`(`, ``)
              .replace(`)`, ``)
              .replace(`$`, ``)
              .replace(`,`, ``)
          );
          if (payment > 0) {
            isIgnoreRecalculate = true;
          }
        }
      }
      if (isIgnoreRecalculate !== true) {
        const objTaxFee = calculateTaxFee(
          paymentNo,
          taxFee,
          testType,
          totalLbs,
          countPayments,
          totalPaymentPercent,
          walnutTestItemsByPayment,
          itemsPayment4TaxFee,
          priceFields,
          paymentsByPriceFields
        );
        let paymentTaxFee = objTaxFee.paymentTaxFee;
        let ytdTaxFee = objTaxFee.ytdTaxFee;

        ytdTaxFee = paymentTaxFee;
        //If payment from 1 onward, need to minus previous payment
        if (paymentNo > 0) {
          //Find previous payment
          const previousPaymentObject = previousWalnutTestCalculated.find(
            (e) => {
              return (
                e.paymentNo == paymentNo - 1 &&
                e.paidStatementObject !== undefined
              );
            }
          );
          //If previous payment mark paid
          if (
            previousPaymentObject &&
            previousPaymentObject.paidStatementObject &&
            previousPaymentObject.paidStatementObject.itemsTaxFee
          ) {
            const existTaxFeeInPaidPayment =
              previousPaymentObject.paidStatementObject.itemsTaxFee.find(
                (t) => {
                  return t.name.toLowerCase().trim() == taxFeeName;
                }
              );
            //If tax & fee existed
            if (existTaxFeeInPaidPayment) {
              const minusYtd = parseFloat(
                existTaxFeeInPaidPayment.ytd
                  .replace(`(`, ``)
                  .replace(`)`, ``)
                  .replace(`$`, ``)
                  .replace(`,`, ``)
              );
              if (taxFee.applyType === constant.TAXFEE_APPLY_TYPE_NA) {
                paymentTaxFee = paymentTaxFee - minusYtd;
              }
              ytdTaxFee = paymentTaxFee + minusYtd;
            }
            previousTaxFeeCalculated.push({
              paymentNo: paymentNo,
              _id: taxFee._id,
              payment: Math.abs(paymentTaxFee),
              ytd: Math.abs(ytdTaxFee),
            });
          } else {
            const previousTaxFee = allItemsTaxFee.find((e) => {
              return (
                e.paymentNo === paymentNo - 1 &&
                e.itemsTaxFee &&
                e.itemsTaxFee.length > 0 &&
                e.itemsTaxFee.some((x) => {
                  return x.name.toLowerCase().trim() === taxFeeName;
                })
              );
            });
            if (previousTaxFee) {
              const itemsTaxFee = previousTaxFee.itemsTaxFee.find((x) => {
                return x.name.toLowerCase().trim() === taxFeeName;
              });
              const previousYtd = parseFloat(
                itemsTaxFee.ytd
                  .replace(`(`, ``)
                  .replace(`)`, ``)
                  .replace(`$`, ``)
                  .replace(`,`, ``)
              );

              if (taxFee.applyType === constant.TAXFEE_APPLY_TYPE_NA) {
                paymentTaxFee = paymentTaxFee - previousYtd;
              }
              ytdTaxFee = paymentTaxFee + previousYtd;
            }
          }
        }
        totalPaymentTaxFee += paymentTaxFee;
        totalYtdTaxFee += ytdTaxFee;
        itemsTaxFee.push({
          name: taxFee.name,
          payment:
            paymentTaxFee < 0
              ? "($" +
                utilities.numberWithCommas(
                  parseFloat(Math.abs(paymentTaxFee)).toFixed(2)
                ) +
                ")"
              : "$" +
                utilities.numberWithCommas(
                  parseFloat(paymentTaxFee).toFixed(2)
                ),
          ytd:
            ytdTaxFee < 0
              ? "($" +
                utilities.numberWithCommas(
                  parseFloat(Math.abs(ytdTaxFee)).toFixed(2)
                ) +
                ")"
              : "$" +
                utilities.numberWithCommas(parseFloat(ytdTaxFee).toFixed(2)),
        });
        previousTaxFeeCalculated.push({
          paymentNo: paymentNo,
          _id: taxFee._id,
          payment: Math.abs(paymentTaxFee),
          ytd: Math.abs(ytdTaxFee),
        });
      }
    });
  }

  return {
    totalPaymentTaxFee: totalPaymentTaxFee,
    totalYtdTaxFee: totalYtdTaxFee,
    itemsTaxFee: itemsTaxFee,
  };
}

function calculateTaxFee(
  paymentNo,
  taxFee,
  testType,
  totalLbs,
  countPayments,
  totalPaymentPercent,
  tests,
  itemsPayment4TaxFee,
  priceFields,
  paymentsByPriceFields
) {
  /*
  Sample priceFields
  [
    'Regular (2 1/2 to 3 1/2)',
    'Over size (Over 3 1/2)',
    'Undersize (under 2 1/2)',
    'Culls'
  ]
  Sample paymentsByPriceFields
  [
    { field: 'Regular (2 1/2 to 3 1/2)', payment: 61.499999999999986 },
    { field: 'Over size (Over 3 1/2)', payment: 31.000000000000007 },
    { field: 'Undersize (under 2 1/2)', payment: 42.00000000000001 },
    { field: 'Culls', payment: 5.500000000000001 }
  ]
  */
  let paymentTaxFee = 0.0;
  let ytdTaxFee = 0.0;
  let totalPayment = 0.0;
  //taxFee.value { _bsontype: 'Double', value: 40 }
  const value =
    typeof taxFee.value === `object` && taxFee.value._bsontype === `Double`
      ? taxFee.value.valueOf()
      : taxFee.value;
  //Do not calculate tax or fee
  if (taxFee.applyType === constant.TAXFEE_APPLY_TYPE_FIRST && paymentNo > 0) {
    paymentTaxFee = 0;
    ytdTaxFee = 0;
  } else {
    let existAttribute = false;
    let itemsPayment4TaxFeeFiltered = itemsPayment4TaxFee;
    if (testType.taxFeeByMeta === true) {
      if (testType.multiPricesSameLot === true) {
        //Recalculate totalLbs based on meta attribute between test and tax & fee
        priceFields.map((priceField) => {
          // let existAttributeByPriceField = false;
          let taxFeeMetaAttributes =
            taxFee.customAttributes && taxFee.customAttributes !== ""
              ? JSON.parse(taxFee.customAttributes)
              : [];
          if (
            taxFeeMetaAttributes.filter((a) => {
              return (
                a.attribute === testType.multiplePriceField &&
                a.value === priceField
              );
            }).length > 0
          ) {
            const lengthTaxFeeMetaAttributes = taxFeeMetaAttributes.length;
            tests.map((test) => {
              let existAttributeByPriceField = false;
              const testCustomFieldsAll =
                test.customFields && test.customFields !== ""
                  ? JSON.parse(test.customFields)
                  : [];
              const testCustomFieldsExcludeMultiplePriceFieldName =
                testCustomFieldsAll.filter((k) => {
                  return k.name !== testType.multiplePriceField;
                });

              if (
                testCustomFieldsExcludeMultiplePriceFieldName &&
                testCustomFieldsExcludeMultiplePriceFieldName.length > 0
              ) {
                if (lengthTaxFeeMetaAttributes > 0) {
                  let count = 0;
                  testCustomFieldsExcludeMultiplePriceFieldName.map((f) => {
                    const temp = taxFeeMetaAttributes.filter((c) => {
                      return c.value == f.value && f.name === c.attribute;
                    });
                    if (temp && temp.length > 0) {
                      count++;
                    }
                  });
                  if (count === lengthTaxFeeMetaAttributes - 1) {
                    existAttributeByPriceField = true;
                  }
                }

                if (existAttributeByPriceField === true) {
                  let percent = 0.0;
                  const temp = paymentsByPriceFields.filter((p) => {
                    return (
                      p.field === priceField && p.testId === test._id.toString()
                    );
                  });
                  if (temp && temp.length > 0) {
                    percent = temp[0].percent;
                    totalPayment = temp[0].payment;
                    totalLbs = test.netWeight * percent;
                    if (percent > 0) {
                      if (taxFee.type === constant.TAXFEE_TYPE_PER_POUND) {
                        if (testType.paymentPercentBased === true) {
                          switch (taxFee.applyType) {
                            case constant.TAXFEE_APPLY_TYPE_NEXT_AVAILABLE:
                              {
                                paymentTaxFee += value * totalLbs;
                              }
                              break;
                            default:
                              {
                                paymentTaxFee +=
                                  value * totalPaymentPercent * totalLbs;
                              }
                              break;
                          }
                        } else {
                          switch (taxFee.applyType) {
                            case constant.TAXFEE_APPLY_TYPE_FIRST:
                              {
                                paymentTaxFee += value * totalLbs;
                              }
                              break;
                            case constant.TAXFEE_APPLY_TYPE_ACROSS:
                              {
                                paymentTaxFee +=
                                  (value * totalLbs) / (countPayments * 1.0);
                              }
                              break;
                            default:
                              {
                                paymentTaxFee += value * totalLbs;
                              }
                              break;
                          }
                        }
                      } else if (
                        taxFee.type === constant.TAXFEE_TYPE_ONE_TIME_FEE
                      ) {
                        if (testType.paymentPercentBased === true) {
                          switch (taxFee.applyType) {
                            case constant.TAXFEE_APPLY_TYPE_NEXT_AVAILABLE:
                              {
                                paymentTaxFee += value;
                              }
                              break;
                            default:
                              {
                                paymentTaxFee += value * totalPaymentPercent;
                              }
                              break;
                          }
                        } else {
                          switch (taxFee.applyType) {
                            case constant.TAXFEE_APPLY_TYPE_FIRST:
                              {
                                paymentTaxFee += value;
                              }
                              break;
                            case constant.TAXFEE_APPLY_TYPE_ACROSS:
                              {
                                paymentTaxFee += value / (countPayments * 1.0);
                              }
                              break;
                            default:
                              {
                                paymentTaxFee += value * totalPaymentPercent;
                              }
                              break;
                          }
                        }
                      } else if (
                        taxFee.type === constant.TAXFEE_TYPE_PER_DOLLAR
                      ) {
                        switch (taxFee.applyType) {
                          case constant.TAXFEE_APPLY_TYPE_FIRST:
                            {
                              paymentTaxFee += value * totalPayment;
                            }
                            break;
                          case constant.TAXFEE_APPLY_TYPE_ACROSS:
                            {
                              paymentTaxFee +=
                                (value * totalPayment) / (countPayments * 1.0);
                            }
                            break;
                          default:
                            {
                              paymentTaxFee += value * totalPayment;
                            }
                            break;
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        });

        return {
          paymentTaxFee: paymentTaxFee,
          ytdTaxFee: ytdTaxFee,
        };
      } else {
        const includeTestIds = [];
        //Recalculate totalLbs based on meta attribute between test and tax & fee
        let newTotalLbs = 0.0;
        /*Sample value for customAttributes
      [
        {
           "_id": "6f01f87a-5dcb-4825-acdc-ce36a3780a3a",
           "attribute": "Brand",
           "value": "Sample brand 1",
           "testFieldId": "625e503f50a4c95d64a99471",
           "extraValue": "625e4f5e50a4c95d64a99463"
        }
     ]
     */
        const taxFeeMetaAttributes =
          taxFee.customAttributes && taxFee.customAttributes !== ""
            ? JSON.parse(taxFee.customAttributes)
            : [];
        const lengthTaxFeeMetaAttributes = taxFeeMetaAttributes.length;
        tests.map((test) => {
          /*
        Sample value for customFields
        [
          {
              "name": "Brand",
              "value": "Sample brand 1"
          },
          {
              "name": "Region",
              "value": "Sample region 1"
          },
        ]
        */
          const testCustomFields =
            test.customFields && test.customFields !== ""
              ? JSON.parse(test.customFields)
              : [];

          if (testCustomFields && testCustomFields.length > 0) {
            if (lengthTaxFeeMetaAttributes > 0) {
              testCustomFields.map((f) => {
                const temp = taxFeeMetaAttributes.filter((c) => {
                  return c.value == f.value && f.name === c.attribute;
                });
                if (temp && temp.length > 0) {
                  existAttribute = true;
                }
              });
            } else {
              existAttribute = true;
            }
            if (existAttribute === true) {
              newTotalLbs += test.netWeight;
              includeTestIds.push(test._id.toString());
            }
          } else if (lengthTaxFeeMetaAttributes === 0) {
            existAttribute = true;
            newTotalLbs += test.netWeight;
            includeTestIds.push(test._id.toString());
          }
        });
        totalLbs = newTotalLbs;

        if (taxFee.type === constant.TAXFEE_TYPE_PER_DOLLAR) {
          itemsPayment4TaxFeeFiltered = itemsPayment4TaxFee.filter((x) => {
            return includeTestIds.includes(x.id);
          });
        }
      }
    }

    if (
      testType.taxFeeByMeta !== true ||
      (testType.taxFeeByMeta === true && existAttribute === true)
    ) {
      if (taxFee.type === constant.TAXFEE_TYPE_PER_POUND) {
        if (testType.paymentPercentBased === true) {
          switch (taxFee.applyType) {
            case constant.TAXFEE_APPLY_TYPE_NEXT_AVAILABLE:
              {
                paymentTaxFee = value * totalLbs;
              }
              break;
            default:
              {
                paymentTaxFee = value * totalPaymentPercent * totalLbs;
              }
              break;
          }
        } else {
          switch (taxFee.applyType) {
            case constant.TAXFEE_APPLY_TYPE_FIRST:
              {
                paymentTaxFee = value * totalLbs;
              }
              break;
            case constant.TAXFEE_APPLY_TYPE_ACROSS:
              {
                paymentTaxFee = (value * totalLbs) / (countPayments * 1.0);
              }
              break;
            default: {
              paymentTaxFee = value * totalLbs;
            }
          }
        }
      } else if (taxFee.type === constant.TAXFEE_TYPE_ONE_TIME_FEE) {
        if (testType.paymentPercentBased === true) {
          switch (taxFee.applyType) {
            case constant.TAXFEE_APPLY_TYPE_NEXT_AVAILABLE:
              {
                paymentTaxFee = value;
              }
              break;
            default:
              {
                paymentTaxFee = value * totalPaymentPercent;
              }
              break;
          }
        } else {
          switch (taxFee.applyType) {
            case constant.TAXFEE_APPLY_TYPE_FIRST:
              {
                paymentTaxFee = value;
              }
              break;
            case constant.TAXFEE_APPLY_TYPE_ACROSS:
              {
                paymentTaxFee = value / (countPayments * 1.0);
              }
              break;
            default: {
              paymentTaxFee = value;
            }
          }
        }
      } else if (taxFee.type === constant.TAXFEE_TYPE_PER_DOLLAR) {
        if (testType.multiPricesSameLot !== true) {
          totalPayment = lodash.sumBy(itemsPayment4TaxFeeFiltered, `payment`);
        }
        switch (taxFee.applyType) {
          case constant.TAXFEE_APPLY_TYPE_FIRST:
            {
              paymentTaxFee = value * totalPayment;
            }
            break;
          case constant.TAXFEE_APPLY_TYPE_ACROSS:
            {
              paymentTaxFee = (value * totalPayment) / (countPayments * 1.0);
            }
            break;
          default: {
            paymentTaxFee = value * totalPayment;
          }
        }
      }
    }
  }

  return {
    paymentTaxFee: paymentTaxFee,
    ytdTaxFee: ytdTaxFee,
  };
}

const getGrowerStatementsWithConditions = async (
  whereClause,
  selectFields,
  sortFields
) => {
  return await queryDataExt(
    `${GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL}getWithConditions`,
    whereClause,
    selectFields,
    sortFields
  );
};
