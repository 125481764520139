import React from "react";
import AddComponent from "../../../Base/AddComponent";
import { Helmet } from "react-helmet";
import Table from 'react-bootstrap/Table';
import ReactHtmlParser from "react-html-parser";
import { getLoggedUser } from "../../../../context/auth";
import ReactJson from 'react-json-view';

let loggedUser = {};
class App extends AddComponent {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    let sample = "";
    let profileUrl = "profile page";
    if (loggedUser && (loggedUser.role === "Lab Users" || loggedUser.role === "Processors" || loggedUser.role === "Staffs")) {
      const processorId = loggedUser.processorId
      const url = `${process.env.REACT_APP_API_EXTERNAL_URL}/walnuttests/list/${processorId}`
      sample = `<p></p><strong>Sample:</strong> <a href=${url} target='_blank'>${url}</a>`;
      profileUrl = `<a href=${process.env.REACT_APP_URL_MY_PROFILE} target='_blank'>profile page</a>`
    }
    this.headerInfoArray = [
    ];
    this.bodyInfoArray = [
      { title: 'Description', content: 'Query all the tests that belongs to processor. This API only supports paid accounts.' },
      { title: 'API URL', content: `<strong>POST</strong> ${process.env.REACT_APP_API_EXTERNAL_URL}/walnuttests/list/{processorId}${sample}` },
      { title: 'Request Parameters', content: `<ul><li><strong>processorId</strong>: string<br/>The Processor ID which is retrieved under processor's ${profileUrl}.</li><ul>` },
      { title: 'Query Parameters', content: '<ul><li><p><strong>page</strong>: string<br/>The page number of the current page in the returned records.</p></li>         <li><p><strong>testDateFrom</strong>: string<br/>Filter created date. Format: YYYY-MM-DD. Example: 2020-12-17</p></li>         <li><p><strong>testDateTo</strong>: string<br/>Filter created date. Format: YYYY-MM-DD. Example: 2020-12-31</p></li><ul>' },
      // { title: 'Responses', content: "<p>Statuses: </p><ul><li><p><strong>OK</strong>: List of meeting objects returned.<br/></p><p>Example </p><p><img alt='' src='/assets/images/api-reference-walnuttest-list.PNG' style='width:800px'/></p></li>         <li><p><strong>ERROR</strong>: Processor {processorId} not exist or not belong to this account.<br/></p><p>Example </p><p><img alt='' src='/assets/images/api-reference-walnuttest-list-error.PNG'/></p></li><ul>" },
      { title: 'Example Responses Test Returned', content: "" },
      { title: 'Example Responses Error', content: "" },
    ];
  }

  renderBodyTable(bodyInfo, index) {
    const sampleJSONOk = {
      "status": "OK",
      "totalPages": 1,
      "currentPage": 1,
      "data": [
        {
          "id": "6154e4cac744cd191c5162b3",
          "certificateNo": "CFF-1117",
          "testDate": "09/29/2021 11:59 PM",
          "inspectionAddress": "1640 Highway 45",
          "handlerName": "Zachary Mauro",
          "lotNumber": "331",
          "growerName": "Clint And Gail Moffitt Trust ",
          "varietySubmitted": "Vina",
          "wcNumber": "331",
          "typeNumberOfContainers": "Bulk",
          "netWeight": 0,
          "sampleDrawnByDfa": "No",
          "sampleSubmittedByHandler": "Maria Cervantes",
          "dfaInspector": "Receiving",
          "employeeNo": "MC",
          "photo": "",
          "netwt100InshellNuts": 995.2,
          "jumbo": 59,
          "large": 8,
          "medium": 14,
          "baby": 16,
          "peeWee": 0,
          "splits": 0,
          "broken": 3,
          "adheringHull": 0,
          "insect": 0,
          "rancid": 0,
          "mold": 0,
          "shrivel": 10,
          "other": 0,
          "blows": 0,
          "light": 45,
          "lightAmber": 44,
          "amber": 1,
          "black": 0,
          "edibleWeight": 432.2,
          "defectWeight": 10.7,
          "totalKernelWeight": 442.9,
          "edibleYield": 43.4,
          "otherVarietiesIncluded": 0,
          "seriousStain": 0,
          "moisture": 5.6,
          "remarks": "Sample remark.",
          "nutTypeName": "Walnut",
          "customFields": [
            {
              "name": "Field Name 1",
              "value": 10.99
            },
            {
              "name": "Field Name 2",
              "value": 200.89
            },
            {
              "name": "Field Name 3",
              "value": "Dummy test"
            },
            {
              "name": "Field Name 4",
              "value": 100
            },
          ]
        }
      ]
    };

    const sampleJSONError = {
      "status": "ERROR",
      "message": "Cast to date failed for value 2021-12-32"
    };
    return (
      bodyInfo.title === `Example Responses Test Returned` ?
        <tr key={index}><td style={{
          fontSize: "15px",
          paddingTop: 20,
          paddingBottom: 20,
        }} > {bodyInfo.title}</td><td><ReactJson src={sampleJSONOk} theme="bright:inverted" name={false} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /></td></tr> :
        bodyInfo.title === `Example Responses Error` ?
          <tr key={index}><td style={{
            fontSize: "15px",
            paddingTop: 20,
            paddingBottom: 20,
          }} > {bodyInfo.title}</td><td><ReactJson src={sampleJSONError} theme="bright:inverted" name={false} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /></td></tr> :
          <tr key={index}>
            <td style={{
              fontSize: "15px",
              paddingTop: 20,
              paddingBottom: 20,
            }} > {bodyInfo.title}</td>
            <td style={{
              textAlign: "left",
              fontSize: "15px",
              paddingTop: 20,
              paddingBottom: 20,
            }}>{ReactHtmlParser(bodyInfo.content)}</td>
          </tr >
    )
  }



  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"List Tests | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section
          className="body-sign"
          style={{ color: "white", paddingBottom: "50px" }}
        >
          <div className="container">
            <div
              className="row center"
              style={{
                color: "white",
                paddingTop: "50px",
              }}
            >
              <a href="/" className="logo pull-left">
                <img
                  src="/assets/images/logo.png"
                  height="54"
                  alt="ParityFactory Grading"
                />
              </a>
            </div>
            <div className="row center">
              <h1>ParityFactory API Reference</h1>
              <p></p>
              <h2>Test List API</h2>
            </div>
            <div className="row">
              <p>
              </p>
            </div>
            <div className="row">
              <Table responsive bordered>
                {/* <thead>
                  <tr>
                    <th></th>
                    {this.headerInfoArray.map(this.renderHeaderTable)}
                  </tr>
                </thead> */}
                <tbody>
                  {this.bodyInfoArray.map(this.renderBodyTable)}
                </tbody>
              </Table>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; ParityFactory All rights reserved |{" "}
              <a
                href="https://parityfactory.com/privacy-policy/"
                style={{ color: "white" }}
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </section>
      </div>
    );
  }
}
export default App;
