import React from "react";
import DataTable from "react-data-table-component";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

const DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.children,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  truncateText = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  setColumns = () => {
    return [
      {
        name: "Text",
        selector: "text",
        sortable: false,
        cell: (row) => {
          const text = this.truncateText(row.text, 100)
          return this.props.readOnly === true ||
            this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            text
          ) : (
            <a
              onClick={() => this.props.handleShow(row)}
              href="#"
              title="Edit"
            >
              {text}
            </a>
          );
        },
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(new Date(row.modifiedDate), "mm/dd/yyyy hh:MM TT")
            : "";
        },
        width: "160px"
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
        width: "160px"
      },
      {
        name: "",
        cell: (row) => (
          <div>
            {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
              ""
            ) : (
              <>
                <a
                  title="Edit"
                  href="#"
                  onClick={() => this.props.handleShow(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Remove"
                  href="#"
                  className="on-default remove-row"
                  data-id={row._id}
                  data-growerid={row.growerId}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
              </>
            )}

            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
        width: "100px"
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
