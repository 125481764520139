import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { renderFieldByType } from "../TestField/TestFieldUtilities";
import Switch from "react-switch";
import CommonUtilities from "../Utilities/Common";
import { ROW_TYPE_DATA } from "../Utilities/Constant";
import Equation from "equations";
import _ from "lodash";
const GlobalConfig = new window.globalConfig();

const QualityDataPopup = (props) => {
  const [show, setShow] = useState(false);
  const [fieldsToCalculate, setFieldToCalculate] = useState([]);
  const [temp, setTemp] = useState({ type: ROW_TYPE_DATA });

  const {
    fields,
    calculateSummary,
    composeMinMaxQualityData,
    loggedUser,
    setQualityData,
    isAdd,
    index,
    editData,
    qualityData,
    setAllowCalculate,
    allowCalculate,
    calculatedFields,
    numberFields,
    maxOfRows,
  } = props;

  useEffect(() => numberFields && setFieldToCalculate([...numberFields]), []);
  useEffect(() => {
    if (show && qualityData) {
      if (editData) {
        //init for edit
        setTemp(editData);
        initFieldsToCalculate(editData);
      } else {
        //init for add
        initTemp(fields);
        updateCalculatedFields(fieldsToCalculate);
      }
    } else {
      //After close popup
      setFieldToCalculate([...numberFields]);
    }
  }, [show, qualityData, fields]);

  const initTemp = (fields) => {
    fields?.forEach(({ testFieldId, defaultValue }) => {
      setTemp((previousTemp) => {
        return {
          ...previousTemp,
          [testFieldId]: Number(defaultValue),
        };
      });
    });
  };

  const resetTemp = () => {
    setTemp({ type: ROW_TYPE_DATA });
  };

  const initFieldsToCalculate = (editData) => {
    if (numberFields.length > 0) {
      const arrayWithoutReference = [...numberFields];
      const newFieldsToCalculate = arrayWithoutReference.map((element) => {
        let updateDefaultValue = {
          ...element,
          defaultValue: editData[element._id],
        };
        let newFieldWithoutReference = updateDefaultValue;
        return newFieldWithoutReference;
      });

      setFieldToCalculate(newFieldsToCalculate);
    }
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleFieldsForSelect = () => {
    return fields?.map((field, _) => {
      const defaultValue = editData?.[field.testFieldId] ?? field.defaultValue;
      return {
        ...field,
        defaultValue,
      };
    });
  };

  const handleSavePopup = (event) => {
    if (isAdd) {
      handleSaveForAdd(event);
      return;
    }
    handleSaveForEdit(event);
  };

  const handleSaveForEdit = (event) => {
    //Replace
    const field = qualityData?.[index];
    const newField = {
      ...field,
      ...temp,
    };
    const newQualityData = [
      ...qualityData.filter((field) => field.type === ROW_TYPE_DATA),
    ];
    newQualityData[index] = newField;
    //Get min and max
    composeMinMaxQualityData(newQualityData, fields);
    //Calculate
    calculateSummary(newQualityData, fields);
    //set new
    setQualityData({
      qualityData: newQualityData,
    });
    event.preventDefault();
    event.stopPropagation();
    resetTemp();
    setShow(false);
  };

  const handleSaveForAdd = (event) => {
    //Add new
    const newQualityData = [
      ...qualityData.filter((field) => field.type === ROW_TYPE_DATA),
    ];
    newQualityData.push(temp);
    //Get min and max
    composeMinMaxQualityData(newQualityData, fields);
    //Calculate
    calculateSummary(newQualityData, fields);
    //Set new
    setQualityData({
      qualityData: newQualityData,
    });
    event.preventDefault();
    event.stopPropagation();
    resetTemp();
    setShow(false);
  };

  const handleClose = () => {
    resetTemp();
    setShow(false);
  };

  const checkFieldToCalculate = (_id) => {
    return fieldsToCalculate.some((element) => element._id === _id);
  };

  const updateFieldValueToCalculate = (_id, value) => {
    const newFieldsToCalculate = fieldsToCalculate.map((field) => {
      if (field._id === _id) {
        return { ...field, defaultValue: value.toString() };
      }
      return field;
    });
    updateCalculatedFields(newFieldsToCalculate);
    setFieldToCalculate(newFieldsToCalculate);
  };

  const handleChangeTextField = async (e) => {
    const { name, value, type, checked } = e.target;
    let _idField = name;
    const textFieldValue = type === "checkbox" ? checked : value;
    setTemp((previousTemp) => {
      return {
        ...previousTemp,
        [_idField]: textFieldValue,
      };
    });
    if (checkFieldToCalculate(_idField)) {
      updateFieldValueToCalculate(_idField, value);
    }
  };

  const updateCalculatedFields = (newFieldsToCalculate) => {
    if (calculatedFields && calculatedFields.length > 0) {
      let updatedObjects = {};
      calculatedFields.map((f) => {
        const calculatedValue =
          CommonUtilities.updateCalculatedFieldQualityData(
            newFieldsToCalculate,
            f,
            Equation
          );
        updatedObjects = JSON.parse(JSON.stringify(updatedObjects));
        updatedObjects[[f._id]] = calculatedValue;
      });
      setTemp((previousTemp) =>
        Object.assign({}, previousTemp, updatedObjects)
      );
    }
  };

  const handleChangeTestFieldSelectControl = (controlId, value) => {
    setTemp({
      ...temp,
      [controlId]: value,
    });
  };

  const allowCalculateChange = (checked) => {
    setAllowCalculate(checked);
  };

  const getNumberOfRows = (qualityData) => {
    if (!qualityData || !qualityData.length || !qualityData.length > 0)
      return 0;
    return qualityData.filter((row) => row.type === ROW_TYPE_DATA).length;
  };

  return (
    <>
      {isAdd && (
        <div className="multi-quality-data-control">
          {getNumberOfRows(qualityData) >= maxOfRows ? (
            <></>
          ) : (
            <Button
              // style={{marginBottom : '0 !important'}}
              variant="primary"
              onClick={handleShow}
              title="Add Test Field Value"
              className="panel-btn-group-item-position add-new-row-in-quality-data-btn"
            >
              <i className="fa fa-plus fa-lg"></i> Add
            </Button>
          )}
        </div>
      )}
      {!isAdd && (
        <a
          style={{ cursor: "pointer" }}
          title="Click here to edit"
          onClick={handleShow}
        >
          <span>
            <i className="fa fa-pencil-square-o fa-lg" />
          </span>
        </a>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        className="test-fields-popup"
      >
        <Form onSubmit={handleSavePopup}>
          <Modal.Header>
            <Modal.Title>{isAdd ? "Add" : "Edit"} Quality Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="number-input-custom">
              {handleFieldsForSelect()?.map((_, index) => (
                <React.Fragment key={index}>
                  {renderFieldByType(
                    _,
                    temp?.[_?.testFieldId],
                    loggedUser,
                    GlobalConfig,
                    handleChangeTextField,
                    handleChangeTestFieldSelectControl
                  )}
                </React.Fragment>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="update-override-price-footer">
            <div className="text-center">
              {isAdd ? (
                <Button variant="success" type="submit">
                  <i className="fa fa-plus fa-lg"></i>
                  <span></span> Add
                </Button>
              ) : (
                <Button variant="success" type="submit">
                  <i
                    className="fa fa-check"
                    aria-hidden="true"
                    style={{ fontSize: 17 }}
                  ></i>
                  <span></span> Done
                </Button>
              )}
              <Button variant="warning" type="button" onClick={handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default QualityDataPopup;
