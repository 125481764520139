const qualityAttributeNamesMapping = {
  s1NetWeight: "Net wt. 100 Inshell Nuts",
  s1Jumbo: "Jumbo",
  s1Large: "Large",
  s1Medium: "Medium",
  s1Baby: "Baby",
  s1PeeWee: "Pee Wee",
  s1Splits: "Splits",
  s1Broken: "Broken",
  s1AdheringHull: "Adhering Hull",
  s2Insect: "Insect",
  s2Rancid: "Rancid",
  s2Mold: "Mold",
  s2Shrivel: "Shriel",
  s2Other: "Other",
  s2Blows: "Blows",
  s2Light: "Light",
  s2LightAmber: "Light Amber",
  s2Amber: "Amber",
  s2Black: "Black",
  s3Weight: "Net wt. 100 Inshell Nuts",
  s3EdibleWeight: "Edible Weight",
  s3DefectWeight: "Defect Weight",
  s3TotalKernelWeight: "Total Kernel Weight",
  s3EdibleYield: "Edible Yield",
  s4OtherVarietiesIncluded: "Other Varieties Included",
  s4SeriousStain: "Serious Stain",
  moisture: "Moisture",
};

exports.qualityAttributeNamesMapping = qualityAttributeNamesMapping;
