import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = ({
  csvData,
  fileName,
  buttonLabel,
  buttonClassName,
  className,
  getCsvData = null,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // function fitToColumn(arrayOfArray) {
  //   // get maximum character of each column
  //   return arrayOfArray.map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i].toString().length)) }));
  // }

  function formatCells(ws, range, fmt) {
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        // if (!cell || cell.t !== 'n') {
        //     //   continue; // only format numeric cells
        //     // }
        cell.z = fmt;
      }
    }
  }

  const exportToCSV = async (csvData, fileName) => {
    window.showAlert(
      "Info",
      `Exporting...the file is ready download once done.`,
      "info"
    );
    if (getCsvData) {
      csvData = await getCsvData();
    }
    // if (csvData && csvData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(csvData);
    // ws['!cols'] = fitToColumn(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    /* new format */
    const fmtPercent = "##0.0%";
    var fmt = "$#,##0.00";
    /* change cell format of range B2:D4 */
    var rangeAmount = { s: { r: 1, c: 4 }, e: { r: csvData.length, c: 5 } };
    formatCells(ws, rangeAmount, fmt);
    var rangeOwnerAmount = {
      s: { r: 1, c: 10 },
      e: { r: csvData.length, c: 10 },
    };
    formatCells(ws, rangeOwnerAmount, fmt);
    var rangePercent = { s: { r: 1, c: 3 }, e: { r: csvData.length, c: 3 } };
    var rangeOwnership = { s: { r: 1, c: 9 }, e: { r: csvData.length, c: 9 } };
    formatCells(ws, rangePercent, fmtPercent);
    formatCells(ws, rangeOwnership, fmtPercent);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  if (buttonLabel === "") {
    buttonLabel = "Export excel";
  }
  return csvData && csvData.length > 0 ? (
    <a
      className={buttonClassName}
      href="# "
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      <div className={`option-dropdown-table-grower ${className}`}>
        <div className="icon-dropdown-table-grower">
          <i className={`fa fa-file-excel-o fa-lg`}></i>
        </div>
        <div className="title-dropdown-table-grower">{buttonLabel}</div>
      </div>
    </a>
  ) : (
    ""
  );
};

export default ExportExcel;
