import React from "react";
// import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { Dropdown } from "react-bootstrap";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import Switch from "react-switch";
import DataTable from "react-data-table-component";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import OptionDropdown from "../Base/OptionDropdown";
import ModalPopup from "../Base/ModalPopup";
import { isArray } from "lodash";
import { VALUE_QUERY_PARAM_IGNORE } from "../Utilities/Constant";
import String from "../Utilities/String";
import { connect } from "react-redux";
import { removeTestType } from "./../../redux/actions/testTypeActions";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    let urlapi = "";
    let urlApiProcessorFilter = "";
    let urlApiDefault = GlobalConfig.REACT_APP_API_NUTTYPE_URL;

    const filterNutTypeName = "";
    const filterProcessorName = "";

    let filterNutTypeName4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterProcessorName4Api = "all";

    if (loggedUser.role === "Administrators") {
      urlapi =
        GlobalConfig.REACT_APP_API_NUTTYPE_URL +
        "byadmin" +
        "/" +
        filterNutTypeName4Api +
        "/" +
        filterProcessorName4Api;
      urlApiProcessorFilter =
        GlobalConfig.REACT_APP_API_PROCESSOR_URL + "filterDataProcessor";
    } else if (
      loggedUser.role === "Processors" ||
      loggedUser.role === "Staffs"
    ) {
      urlapi =
        GlobalConfig.REACT_APP_API_NUTTYPE_URL +
        "byprocessor" +
        "/" +
        loggedUser.processorId +
        "/" +
        filterNutTypeName4Api;
    } else {
      urlapi = GlobalConfig.REACT_APP_API_NUTTYPE_URL;
    }

    this.state.urlapi = urlapi;
    this.state.urllist = process.env.REACT_APP_URL_NUTTYPE_LIST;
    this.state.urladd = process.env.REACT_APP_URL_NUTTYPE_LIST + "-add";
    this.state.urlApiProcessorFilter = urlApiProcessorFilter;
    this.state.urlApiDefault = urlApiDefault;

    this.state.filterNutTypeName = filterNutTypeName;
    this.state.filterProcessorName = filterProcessorName;

    this.state.data = {
      _id: "",
      name: "",
      processorId:
        loggedUser.role === "Processors" || loggedUser.role === "Staffs"
          ? loggedUser.processorId
          : "",
      isSystem:
        loggedUser.role === "Processors" || loggedUser.role === "Staffs"
          ? false
          : true,
      isInUse: false,
      isDynamic: false,

      createdUserId: loggedUser._id,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    };

    this.state.dataItem = {
      _id: "",
      parentId: "",
      name: "",
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };

    this.state.title = "Test Type";

    this.state.pageName = "Test Type";
    //"/test-types/variety-add/"
    this.state.urlvarietyadd = process.env.REACT_APP_URL_VARIETY_ADD + "/";
    this.state.urlapidelete = GlobalConfig.REACT_APP_API_NUTTYPE_URL;

    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;
    this.state.modal = "";

    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleIsDynamicChangeSwitch =
      this.handleIsDynamicChangeSwitch.bind(this);
    this.handleApplyProcessor = this.handleApplyProcessor.bind(this);
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();
    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  handleChangeName = (event) => {
    if (event.target) {
      const data = event.target.value;
      const id = event.target.name;

      this.setState(
        {
          [id]: data,
        },
        loggedUser.role === "Administrators" ||
          loggedUser.role === "Processors" ||
          loggedUser.role === "Staffs" ||
          loggedUser.role === "Lab Users" ||
          loggedUser.role === "Growers"
          ? function callBack() {
            return;
          }
          : function callBack() {
            this.filterItems();
          }
      );
    }
  };

  handleChangeTestType = (event) => {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.state.modal === "Variety"
      ? this.setState({
        dataItem: Object.assign({}, this.state.dataItem, {
          [id]: value,
        }),
      })
      : this.setState({
        data: Object.assign({}, this.state.data, {
          [id]: value,
        }),
      });
  };

  handleSave = async (e) => {
    e.preventDefault();

    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          createdUserId: loggedUser._id,
          isDynamic:
            loggedUser.role === "Processors" || loggedUser.role === "Staffs"
              ? true
              : this.state.data.isDynamic,
        }),
        loading: true,
      },
      function callBack() {
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          const that = this;
          fetch(this.state.urlApiDefault, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject && resultObject.result === "OK") {
                //reset form
                that.handleResetForm();

                if (that.state.isServerPaging === true) {
                  that.getItemsServerPaging(that.state.currentPage);
                } else {
                  that.getItems();
                }

                that.doSomethingAfterSaved(resultObject.data);

                resultObject.data.isDynamic === true
                  ? that.props.history.push(
                    "/test-types/" + resultObject.data._id
                  )
                  : that.props.history.push("/test-types");

                if (
                  that.state.isShowSaveSuccessfulMessage === true ||
                  that.state.isShowSaveSuccessfulMessage === undefined ||
                  that.state.isShowSaveSuccessfulMessage === null
                ) {
                  window.showAlert("", "Save successful!", "");
                }
              }
              //If failed, show error
              else {
                that.doSomethingAfterErrorSaved(resultObject);
              }
              that.setState({ loading: false });
            })
            .catch(function (error) {
              window.showAlert("Error", error, "Error");
              that.setState({ loading: false });
            });
        } catch (err) {
          window.showAlert("Error", err.message, "Error");
          this.setState({ loading: false });
        }
      }
    );
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    window.showAlert("Success", "Removed successful!", "");
    //If updated successful
    this.setState(
      {
        currentPage: 1,
      },
      function () {
        this.doSomethingAfterDeletedItem();
        this.getItemsServerPaging(this.state.currentPage);
      }
    );
    this.updateTestTypesMenu(removedId);
  };

  updateTestTypesMenu = (testTypeId) => {
    if (
      this.props.testTypes &&
      this.props.testTypes.length &&
      this.props.testTypes.length > 0
    ) {
      this.props.removeTestType(testTypeId);
    }
  };

  handleSaveVariety = async (e) => {
    e.preventDefault();

    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }

    let urlApi = GlobalConfig.REACT_APP_API_VARIETY_URL;

    this.setState(
      {
        dataItem: Object.assign({}, this.state.dataItem, {
          createdUserId: loggedUser._id,
        }),
      },
      async function callBack() {
        try {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.dataItem),
          };
          const that = this;
          fetch(urlApi, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject && resultObject.result === "OK") {
                //reset form
                that.handleResetForm();

                if (that.state.isServerPaging === true) {
                  that.getItemsServerPaging(that.state.currentPage);
                } else {
                  that.getItems();
                }

                that.doSomethingAfterSaved(resultObject.data);

                if (
                  that.state.isShowSaveSuccessfulMessage == true ||
                  that.state.isShowSaveSuccessfulMessage === undefined ||
                  that.state.isShowSaveSuccessfulMessage == null
                ) {
                  window.showAlert("", "Save successful!", "");
                }
              }
              //If failed, show error
              else {
                that.doSomethingAfterErrorSaved(resultObject);
              }
            })
            .catch(function (error) {
              window.showAlert("Error", error, "Error");
            });
        } catch (err) {
          window.showAlert("Error", err.message, "Error");
        }
      }
    );
  };

  handleResetForm = () => {
    this.state.modal === "Variety"
      ? this.setState({
        dataItem: Object.assign({}, this.state.dataItem, {
          id: "",
          name: "",
          createdUserId: loggedUser._id,
        }),
      })
      : this.setState({
        data: {
          _id: "",
          name: "",
          processorId: "",
          isSystem:
            loggedUser.role === "Processors" || loggedUser.role === "Staffs"
              ? false
              : true,
          isInUse: false,

          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      loggedUser.role === "Administrators" ||
        loggedUser.role === "Processors" ||
        loggedUser.role === "Staffs" ||
        loggedUser.role === "Lab Users" ||
        loggedUser.role === "Growers"
        ? function callBack() {
          return;
        }
        : function callBack() {
          this.filterItems();
        }
    );
  };

  filterItems = async () => {
    let urlapi = "";
    let filterNutTypeName = String.isEmpty(this.state.filterNutTypeName)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterNutTypeName.trim());

    let filterProcessorName =
      this.state.filterProcessorName && this.state.filterProcessorName !== ""
        ? this.state.filterProcessorName
        : "all";

    if (loggedUser.role === "Administrators") {
      urlapi =
        GlobalConfig.REACT_APP_API_NUTTYPE_URL +
        "byadmin" +
        "/" +
        filterNutTypeName +
        "/" +
        filterProcessorName;
    } else if (
      loggedUser.role === "Processors" ||
      loggedUser.role === "Staffs"
    ) {
      urlapi =
        GlobalConfig.REACT_APP_API_NUTTYPE_URL +
        "byprocessor" +
        "/" +
        loggedUser.processorId +
        "/" +
        filterNutTypeName;
    }

    this.setState({ urlapi: urlapi, currentPage: 1 }, () => {
      this.getItemsServerPaging(this.state.currentPage, this.state.perPage);
    });
  };

  handleReset = () => {
    this.processorControl?.reset();
    this.setState(
      {
        filterNutTypeName: "",
        filterProcessorName: "",
      },
      () => this.filterItems()
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterNutTypeName"
                placeholder="Filter Name"
                onChange={this.handleChangeName}
                maxLength={100}
                value={this.state.filterNutTypeName}
              />
            </div>
            {loggedUser.role === "Administrators" ? (
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorControl = ref)}
                  placeholder="Filter Processor"
                  label="Select All"
                  controlId="filterProcessorName"
                  urlapilist={this.state.urlApiProcessorFilter}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };

  defaultButtons = () => {
    if (
      this.state.excludeDefaultButtons === true ||
      this.state.urladd === null ||
      this.state.urladd === undefined ||
      this.state.urladd === ""
    ) {
      return "";
    }
    return (
      <a
        onClick={() => this.handleShow()}
        className="panel-btn-group-item-position btn btn-primary"
      >
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  handleShow = () => {
    this.setState({}, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          modal: "Test Types",
        },
        function callBack() {
          //Set selected dropdown
          //this.status.setSelected(data.status);
        }
      );
    });
  };

  handleShowVariety = (data) => {
    this.setState({}, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          modal: "Variety",
        },
        function callBack() {
          //Set selected dropdown
        }
      );
    });

    this.setState({
      dataItem: Object.assign({}, this.state.dataItem, {
        parentId: data._id,
      }),
    });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.handleResetForm();
  };

  handleIsDynamicChangeSwitch(value) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        isDynamic: value,
      }),
    });
  }

  handleApplyProcessor = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  extendRender = () => {
    switch (this.state.modal) {
      case "Test Types":
        return (
          <ModalPopup
            show={this.state.show}
            handleClose={this.handleClose}
            handleSave={this.handleSave}
            data={this.state.data}
            title="Add Test Type"
            loading={this.state.loading}
          >
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Test Name <span className="required">*</span>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder=""
                  required
                  value={this.state.data.name}
                  onChange={this.handleChangeTestType}
                  maxLength={100}
                />
              </div>
            </div>
            {loggedUser.role === "Administrators" ? (
              <div className="form-group">
                {this.state.data.isDynamic}
                <label className="col-sm-3 control-label">Dynamic Test</label>
                <div className="col-sm-9">
                  <Switch
                    name="isDynamic"
                    onChange={this.handleIsDynamicChangeSwitch}
                    checked={!!this.state.data.isDynamic}
                    className="react-switch"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {loggedUser.role === "Administrators" ? (
              <div className="form-group">
                <label className="col-sm-3 control-label">
                  Apply Processor
                </label>
                <div className="col-sm-9">
                  <FilterSelectControl
                    handleChangeSelectControl={this.handleApplyProcessor}
                    onRef={(ref) => (this.processorId = ref)}
                    placeholder="All Processors"
                    label="All Processors"
                    controlId="processorId"
                    value={this.state.data.processorId}
                    urlapilist={this.state.urlApiProcessorFilter}
                    required={this.state.data.isDynamic === true}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </ModalPopup>
        );
      case "Variety":
        return (
          <ModalPopup
            show={this.state.show}
            handleClose={this.handleClose}
            handleSave={this.handleSaveVariety}
            data={this.state.dataItem}
            title="Add Variety Item"
          >
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Name <span className="required">*</span>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder=""
                  required
                  value={this.state.dataItem.name}
                  onChange={this.handleChangeTestType}
                  maxLength={100}
                />
              </div>
            </div>
          </ModalPopup>
        );
      default:
        return "";
    }
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  handleCopy = async (data) => {
    const requestData = {
      _id: data._id,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };

    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_NUTTYPE_URL + "copy/" + data._id,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If copied successful
          if (resultObject && resultObject.result === "OK") {
            window.showAlert("", "Copy successful!", "");
            //reset form
            that.filterItems();
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "Error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          if (loggedUser.role === "Administrators" || row.isDynamic === true) {
            return (
              <a href={this.state.urllist + "/" + row._id} title={row.name}>
                {row.name}
              </a>
            );
          } else {
            return row.name;
          }
        },
      },
      {
        name: "Varieties",
        selector: "varieties",
        cell: (row) => (
          <a
            href={
              this.state.urllist +
              "/varieties/" +
              row._id +
              "?isDynamic=" +
              row.isDynamic
            }
            title="Varieties"
          >
            Varieties{" "}
            {row.countVarieties > 0 ? "(" + row.countVarieties + ")" : ""}
          </a>
        ),
      },
      {
        name: "",
        selector: "",
        width: "180px",
        cell: (row) =>
          row.isDynamic === true ? (
            <a
              href={this.state.urllist + "/grower-statement/" + row._id + ""}
              title="Grower Statement Design"
            >
              Grower Statement Design
            </a>
          ) : (
            ""
          ),
      },
      {
        name: "Status",
        selector: "isInUse",
        sortable: true,
        center: true,
        width: "90px",
        cell: (row) => {
          let className = row.isInUse
            ? "label label-success"
            : "label label-default";
          return (
            <span
              className={className}
              style={{
                paddingTop: "5px",
                paddingBottom: "7px",
                width: "55px",
              }}
            >
              {row.isInUse ? "Published" : "Unpublish"}
            </span>
          );
        },
      },
      {
        name: "Is System",
        selector: "isSystem",
        sortable: false,
        center: true,
        width: "100px",
        cell: (row) => (row.isSystem ? "Yes" : "No"),
        // omit: loggedUser.role !== "Administrators",
      },
      {
        name: "Dynamic Test",
        selector: "isDynamic",
        sortable: false,
        center: true,
        width: "120px",
        cell: (row) => (row.isDynamic ? "Yes" : "No"),
      },
      // {
      //   name: "",
      //   selector: "",
      //   sortable: true,
      //   cell: (row) => {
      //     return (
      //       <div>
      //         {row.testField?.sort(CommonUtilities.getSortOrder("name")).map(({ _id, name }, index) => (
      //           <a
      //             href={`${process.env.REACT_APP_URL_TEST_FIELD_LIST}?testType=${row._id}`}
      //             key={index}
      //           >
      //             {name}
      //             {index !== row.testField.length - 1 ? <>,&nbsp;</> : ""}
      //           </a>
      //         ))}
      //       </div>
      //     )
      //   }
      // },
      {
        name: "Apply Processor",
        selector: "processorName",
        sortable: false,
        center: true,
        omit: loggedUser.role === "Processors" || loggedUser.role === "Staffs",
      },
      {
        name: "",
        selector: "",
        width: "60px",
        cell: (row) => (
          <a
            href={
              process.env.REACT_APP_URL_WALNUT_TEST_LIST +
              "?type=" +
              row._id +
              "&name=" +
              row.name +
              "&dynamic=" +
              row.isDynamic
            }
            title={row.name}
          >
            Tests
          </a>
        ),
      },
      {
        name: "",
        selector: "",
        sortable: false,
        width: "120px",
        cell: (row) =>
          !row.isDynamic || loggedUser.titerInfo.isAllowImportApi !== true ? (
            ""
          ) : (
            <a
              href={process.env.REACT_APP_URL_DOCS_TEST_CREATE + "/" + row._id}
              title="Create API doc"
              target="_blank"
            >
              Create API Doc
            </a>
          ),
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate ? (
      //       <DateFormat date={row.modifiedDate}></DateFormat>
      //     ) : (
      //       ""
      //     );
      //   },
      // },
      // {
      //   name: "Created Date",
      //   selector: "createdDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return <DateFormat date={row.createdDate}></DateFormat>;
      //   },
      // },
      {
        name: "",
        sortable: false,
        center: true,
        width: "110px",
        allowOverflow: true,
        cell: (row) => {
          // When items length in table is smaller than 7, adjust action dropdown to overflow table and position always show down
          // When items length in table is greater than 7, action dropdown maybe show (up, down)
          const itemsLength = isArray(this.state.items)
            ? this.state.items.length
            : 0;
          const popperConfig = itemsLength < 7 && {
            strategy: "fixed",
          };
          return loggedUser.role !== "Administrators" &&
            row.isDynamic !== true ? (
            ""
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                align="center"
                className="dropdown-toggle-custom"
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu popperConfig={popperConfig}>
                {row.processorId !== loggedUser.processorId &&
                  loggedUser.role !== "Administrators" ? (
                  ""
                ) : (
                  <>
                    <Dropdown.Item
                      href={this.state.urllist + "/" + row._id}
                      title="Click here to edit"
                    >
                      <OptionDropdown icon="pencil-square-o" title="Edit" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      title="Clone"
                      onClick={() => this.handleCopy(row)}
                    >
                      <OptionDropdown icon="copy" title="Clone" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      title="Click here to remove"
                      data-id={row._id}
                      onClick={this.handleDelete.bind(this)}
                    >
                      <OptionDropdown icon="trash-o" title="Remove" />
                    </Dropdown.Item>
                  </>
                )}
                <>
                  <Dropdown.Item
                    target="_blank"
                    title="Revision history"
                    href={
                      process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                      "/object/" +
                      row._id
                    }
                  >
                    <OptionDropdown icon="history" title="Revision" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-id={row._id}
                    href="# "
                    onClick={() => this.handleShowVariety(row)}
                    title="Click here to create new a variety"
                  >
                    <OptionDropdown icon="plus" title="Add Variety" />
                  </Dropdown.Item>
                </>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ];
  };

  defaultTable = () => {
    return this.state.urlapi === null || this.state.urlapi === undefined ? (
      ""
    ) : this.state.isServerPaging === true ? (
      <>
        <DataTable
          highlightOnHover
          striped={this.striped()}
          pointerOnHover={false}
          id="emp"
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          conditionalRowStyles={this.conditionalRowStyles()}
          pagination={true}
          paginationPerPage={this.state.paginationPerPage}
          paginationServer={true}
          paginationTotalRows={this.state.totalRows}
          paginationRowsPerPageOptions={this.state.rowPerPageOption}
          paginationDefaultPage={this.state.currentPage}
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          progressPending={this.state.progressPending}
          progressComponent={this.defaultProgressComponent()}
        />
      </>
    ) : (
      <>
        {this.columnOptions()}

        <DataTable
          highlightOnHover
          striped={this.striped()}
          pointerOnHover={false}
          id="emp"
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          pagination={this.state.pagination}
          paginationPerPage={this.state.paginationPerPage}
          paginationRowsPerPageOptions={this.state.rowPerPageOption}
          // subHeader={this.state.subHeader}
          // subHeaderComponent={
          //   this.renderHeader()
          // }
          conditionalRowStyles={this.conditionalRowStyles()}
          progressPending={this.state.progressPending}
          progressComponent={this.defaultProgressComponent()}
        />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    testTypes: state.testTypes,
  };
};

export default connect(mapStateToProps, { removeTestType })(App);
