export const SET_TEST_TYPES = "SET_TEST_TYPES";
export const ADD_TEST_TYPE = "ADD_TEST_TYPE";
export const REMOVE_TEST_TYPE = "REMOVE_TEST_TYPE";
export const UPDATE_TEST_TYPE = "UPDATE_TEST_TYPE";

export const SET_HELP_CATEGORIES = "SET_HELP_CATEGORIES";
export const ADD_HELP_CATEGORY = "ADD_HELP_CATEGORY";
export const REMOVE_HELP_CATEGORY = "REMOVE_HELP_CATEGORY";

export const SET_HELP_CATEGORIES_LOADING = "SET_HELP_CATEGORIES_LOADING";
export const SET_TEST_TYPES_LOADING = "SET_TEST_TYPES_LOADING";

export const USER_LOGOUT = "USER_LOGOUT";
