/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ModalPopup from "./ModalPopup";
import { recoveryAccount } from "../../Services/Auth";
import { getMailTemplateByName } from "../../Services/MailTemplate";
import { checkUserActive } from "../../Services/Account";
import { getGrowersWithConditions } from "../../Services/Grower";
import moment from "moment";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isShowModal: false,
      recoveryEmailContent: "",
      errors: {
        recoveryEmailContent: "",
      },
      isShowResetPasswordBtn: false,
    };
  }

  async checkGrowerInviteReceived(contactEmail) {
    const grower = await getGrowersWithConditions(
      {
        contactEmail,
        isSendInvite: true,
      },
      "name",
      "name"
    );

    return !!grower?.[0];
  }

  async componentDidMount() {
    const { email, isGrowerProfilePage } = this.props;
    const isUserActive = await checkUserActive(email);

    if (isGrowerProfilePage) {
      const isGrowerInviteReceived = await this.checkGrowerInviteReceived(
        email
      );
      await this.setState({
        isShowResetPasswordBtn: isUserActive && isGrowerInviteReceived,
      });
    }

    await this.setState({
      isShowResetPasswordBtn: isUserActive,
    });
  }

  handleCloseRecoveryPopup = () => {
    this.setState({
      isShowModal: false,
      recoveryEmailContent: "",
    });
  };

  generateRecoveryEmailContent = async () => {
    const { email } = this.props;
    try {
      const res = await recoveryAccount(email);
      const { token, timeExpired, name } = res;
      const mailTemplate = await getMailTemplateByName(
        "EMAIL_TEMPLATE_RECOVERY_PASSWORD"
      );
      const baseURL = window.location.origin;
      const timeExpiredMoment = moment(new Date(timeExpired)).format(
        "MM/DD/YYYY HH:mm:ss"
      );

      const recoveryEmailContent = this.replaceTemplateVariables({
        token,
        email,
        name,
        timeExpired: timeExpiredMoment,
        mailTemplate,
        baseURL,
      });

      this.setState({
        recoveryEmailContent,
      });
    } catch (ex) {
      this.setState({
        errors: {
          recoveryEmailContent: ex.message,
        },
      });
    }
  };

  handleClickResetPasswordBtn = async () => {
    this.setState({
      isShowModal: true,
    });
    await this.generateRecoveryEmailContent();
  };

  replaceTemplateVariables = ({
    token,
    email,
    name = "",
    timeExpired,
    mailTemplate,
    baseURL,
  }) => {
    return mailTemplate?.message
      ?.replace("#email", email)
      ?.replace("#name", name)
      ?.replace("#timeExpired", timeExpired)
      ?.replace(
        "#url",
        `<a href="${baseURL}/reset-password?token=${token}&timeExpired=${timeExpired}">link</a>`
      );
  };

  render() {
    const { isShowModal, recoveryEmailContent, errors } = this.state;
    return (
      <>
        {this.state.isShowResetPasswordBtn && (
          <a
            title="Reset password"
            className="btn btn-primary m-0"
            onClick={this.handleClickResetPasswordBtn}
          >
            <i className="fa fa-key fa-lg"></i> Reset Password
          </a>
        )}
        <ModalPopup
          show={isShowModal}
          handleClose={this.handleCloseRecoveryPopup}
          title="Recovery Email Content"
          hint="Please copy email above and share for user to complete reset
          password."
        >
          {errors.recoveryEmailContent ? (
            <div style={{ color: "red" }}>{errors.recoveryEmailContent}</div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: recoveryEmailContent,
              }}
            />
          )}
        </ModalPopup>
      </>
    );
  }
}

export default App;
