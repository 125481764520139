import React from "react";
import Select from "react-select";
export default class DashboardSelect extends React.Component {
  constructor(props) {
    super();
    //Init state
    this.state = {
      currentProcessor: props.currentProcessor,
      currentCropYearOfProcessor: props.currentCropYearOfProcessor,
    };
    //Bind
    this.changeProcessor = this.changeProcessor.bind(this);
    this.changeCropYear = this.changeCropYear.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.currentProcessor !== this.props.currentProcessor) {
      this.setState({
        currentProcessor: this.props.currentProcessor,
      });
    }
    if (
      previousProps.currentCropYearOfProcessor !==
      this.props.currentCropYearOfProcessor
    ) {
      this.setState({
        currentCropYearOfProcessor: this.props.currentCropYearOfProcessor,
      });
    }
  }

  changeProcessor(processor) {
    this.setState({
      currentProcessor: processor,
    });
    this.props.changeProcessor(processor);
  }

  changeCropYear(cropYear) {
    this.setState({
      currentCropYearOfProcessor: cropYear,
    });
    this.props.changeCropYear(cropYear);
  }

  render() {
    return (
      <div className="dashboard-select-panel">
        <div className="row">
          <div className="col-md-6">
            <div className="col-md-12">
              {!this.props.hideProcessor ? (
                <div
                  className="col-lg-6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "5px 0",
                  }}
                >
                  <div style={{ width: "25%" }}>Processors</div>
                  <Select
                    className="dashboard-select"
                    name="filterProcessor"
                    onChange={this.changeProcessor}
                    placeholder={"Filter Processor"}
                    value={this.state.currentProcessor}
                    options={this.props.processors}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "5px 0",
                }}
              >
                <div style={{ width: "25%" }}>Crop Years</div>
                <Select
                  className="dashboard-select"
                  name="filterCropYear"
                  onChange={this.changeCropYear}
                  placeholder={"Filter crop year"}
                  value={this.state.currentCropYearOfProcessor}
                  options={this.props.cropYearsOfProcessor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
