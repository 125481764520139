//https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3
import React, { Component } from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const selectAllOption = {
  value: "selectAll",
  label: "All Variety",
  isSelected: false,
  checked: false,
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default class Example extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controlId: props.controlId,
      optionSelected: null,
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.isSelectAllSelected = this.isSelectAllSelected.bind(this);
    this.isOptionSelected = this.isOptionSelected.bind(this);
  }

  isSelectAllSelected = () => {
    return this.state.optionSelected?.length === this.props.options.length;
  };

  isOptionSelected = (_option) => {
    return this.isSelectAllSelected();
  };

  getOptions = () =>
    this.props.showSelectAll
      ? [selectAllOption, ...this.props.options]
      : this.props.options;

  handleChangeSelect = (selected, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    // When select all is selected
    if (action === "select-option" && option.value === selectAllOption.value) {
      let value = "";
      this.props.options.forEach((option) => {
        if (value !== "") {
          value += ",";
        }
        value += option._id;
      });

      this.setState({
        optionSelected: this.props.options,
      });
      this.props.handleChangeSelectControl(this.state.controlId, value);
      return;
    }

    // When select all is unselected
    if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      let value = "";
      this.setState({
        optionSelected: null,
      });
      this.props.handleChangeSelectControl(this.state.controlId, value);
      return;
    }

    let value = "";
    if (selected && selected.length > 0) {
      selected.forEach((x) => {
        if (value !== "") {
          value += ",";
        }
        value += x._id;
      });
    }
    this.setState({
      optionSelected: selected,
    });
    this.props.handleChangeSelectControl(this.state.controlId, value);
  };
  render() {
    return (
      <ReactSelect
        name={this.state.controlId}
        options={this.getOptions()}
        isOptionSelected={this.isOptionSelected}
        isMulti={this.props.isMulti}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={this.handleChangeSelect}
        allowSelectAll={true}
        value={this.state.optionSelected}
        className="optionListContainer"
      />
    );
  }
}
