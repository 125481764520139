import { qualityAttributeNamesMapping } from "../Utilities/Object";

export const configDataExport = (dataContainer, testType) => {
  const { data, growerInfo, itemsTaxFee, customFields } = dataContainer;

  let columns = [
    {
      name: "LOT #",
      value: data.lot,
    },
    {
      name: "Grower Name",
      value: growerInfo.growerName,
    },
  ];

  if (testType.isDynamic && customFields && customFields.length > 0) {
    customFields.map((f) => {
      return columns.push({
        name: f.name,
        value: data[f.name],
      });
    });
  }

  if (testType.isDynamic === false) {
    for (let attribute in qualityAttributeNamesMapping) {
      columns.push({
        name: qualityAttributeNamesMapping[attribute],
        value:
          data[attribute] === undefined ||
            data[attribute] === null ||
            data[attribute].toString() === ""
            ? "N/A"
            : data[attribute],
      });
    }
  }

  columns.push(
    {
      name: `Payment ${growerInfo.paymentNo}`,
      value: `${data.payment}`,
    },
    {
      name: `YTD ${growerInfo.paymentNo}`,
      value: `${data.payment}`,
    },
    {
      name: "Original Base Price",
      value: data.originalPrice,
    },
    {
      name: "Adjusted Base Price",
      value: data.lb,
    },
    {
      name: "Quality Adjustments",
      value: data.bonusDeduct ? data.bonusDeduct : "0.00",
    }
  );

  itemsTaxFee.map((g) => {
    return columns.push(
      {
        name: `${g.name} Payment ${growerInfo.paymentNo}`,
        value: `${data.payment}`,
      },
      {
        name: `${g.name} YTD ${growerInfo.paymentNo}`,
        value: `${data.ytd}`,
      }
    );
  });

  return columns;
};
