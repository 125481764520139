import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import TableAttribute from "./TableAttribute";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import String from "../Utilities/String";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();
var DateFormat = require("dateformat");

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state.urlapidelete = GlobalConfig.REACT_APP_API_ATTRIBUTE;
    this.state.urlapi =
      loggedUser.role === "Administrators"
        ? GlobalConfig.REACT_APP_API_ATTRIBUTE
        : GlobalConfig.REACT_APP_API_ATTRIBUTE +
        "byprocessor/" +
        loggedUser.processorId;
    this.state.urllist = process.env.REACT_APP_URL_ATTRIBUTE_LIST;
    this.state.urladd =
      loggedUser.role === "Processors"
        ? process.env.REACT_APP_URL_ATTRIBUTE_LIST + "-add"
        : "";
    this.state.pluraltitle = "Defects Attributes";
    this.state.title = "Defects Attribute";
    this.state.labeladd = "Defects Attribute";
    this.state.pagination = false;
    this.state.excludeDefaultButtons = true;

    // Using for search filter
    this.state.filterAttribute = "";
    this.state.filterTestType = "";

    //Using for popup add/edit
    this.state.data = {
      _id: "",
      name: "",
      formulas: "",
      equation: "",
      showEquationWithName: false,
      createdUserId: loggedUser._id,
      nutTypeId: ``,
      processorId: loggedUser.processorId,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    };
    this.state.isEditMode = true;
    this.state.showRefresh2 = true;
    this.state.childOverrideComponentDidMount = true;

    this.state.pageName = "Defects Attribute";

    //Using for popup add/edit
    this.state.isChanged = false;
    this.state.errorMessage = "";
    this.state.color = "red";
    this.state.errorEmptyAttribute =
      "Please select at least one base attribue to continue.";

    //Determine if select test type dynamic
    this.state.isDynamic = false;
    this.state.apiUrlTestType = `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}/allPublishedTestTypesByProcessor/${loggedUser.processorId}`;

    this.state.loading = false;

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleTableAttributeChange =
      this.handleTableAttributeChange.bind(this);

    this.handleChangeSwitchShowEquationWithName =
      this.handleChangeSwitchShowEquationWithName.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
  }

  handleTableAttributeChange = (name, value, equation) => {
    if (
      value !== undefined &&
      value !== null &&
      value.length > 0 &&
      this.state.errorMessage === this.state.errorEmptyAttribute
    ) {
      this.setState({
        errorMessage: "",
      });
    }
    this.setState({
      data: Object.assign({}, this.state.data, {
        [name]: value,
        equation: equation,
      }),
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    if (controlId === "filterTestType") {
      this.setState({
        [controlId]: value,
      });
      return;
    }
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
        }),
      },
      () => {
        let isDynamic = false;
        if (
          controlId === "nutTypeId" &&
          this.state.testTypes &&
          this.state.testTypes.length > 0
        ) {
          const temp = this.state.testTypes.filter((x) => {
            return x._id == value;
          });
          if (temp && temp.length > 0) {
            isDynamic = temp[0].isDynamic;
          }
        }
        this.setState({ isDynamic: isDynamic });
      }
    );
  };

  handleChangeSwitchShowEquationWithName(checked) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        showEquationWithName: checked,
      }),
    });
  }

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  renderSearch = () => {
    return (
      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <input
              type="text"
              className="form-control inputRenderSearch inputFilter"
              placeholder="Filter by attribute"
              name="filterAttribute"
              value={this.state.filterAttribute}
              onChange={this.handleChangeTextField}
              maxLength={100}
            />
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <FilterMultiSelectControl
              handleChangeSelectControl={this.handleChangeSelectControl}
              onRef={(ref) => (this.testTypeControl = ref)}
              placeholder="Filter Test Type"
              label="Select All"
              controlId="filterTestType"
              urlapilist={this.state.filterControlUrls?.apiUrlTestTypeFilter}
            />
          </div>
        </div>
      </div>
    );
  };

  filterItems = () => {
    const { filterAttribute, filterTestType } = this.state;
    const filterName = String.isEmpty(filterAttribute)
      ? ""
      : encodeURIComponent(filterAttribute.trim());

    const query = `?name=${filterName}&nutTypeId=${filterTestType}`;
    const urlapi =
      loggedUser.role === "Administrators"
        ? `${GlobalConfig.REACT_APP_API_ATTRIBUTE}${query}`
        : `${GlobalConfig.REACT_APP_API_ATTRIBUTE}byprocessor/${loggedUser.processorId}${query}`;
    this.setState({ urlapi }, () => this.getItems());
  };

  handleReset = () => {
    this.testTypeControl.reset();
    this.setState(
      {
        filterAttribute: "",
        filterTestType: "",
      },
      () => this.filterItems()
    );
  };

  async componentDidMountChild() {
    if (
      loggedUser.titerInfo &&
      loggedUser.titerInfo.isAllowGrowerPayment === true
    ) {
      this.queryTestTypes();
      this.getItems();
      this.loadColumnSettings();
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
      });
    }
  }

  queryTestTypes = async () => {
    const that = this;
    fetch(this.state.apiUrlTestType)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState({ testTypes: resultObject.data });
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions" style={{ top: "0px" }}>
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  extendRenderBeforeTable = () => {
    return this.state.show !== true ? (
      ""
    ) : (
      <Form onSubmit={this.handleSave} id="fromPopup">
        <Modal.Header>
          <Modal.Title>
            {(this.state.data._id === ""
              ? "Add Custom Attribute"
              : "Edit Custom Attribute") +
              (this.state.data.name !== "" ? " - " + this.state.data.name : "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.data ? (
            <>
              <div className="form-group">
                <label className="col-md-2 control-label" htmlFor="nutTypeId">
                  Test Type <span className="required">(*)</span>
                </label>
                <div className="col-md-4">
                  <FilterSelectControl
                    handleChangeSelectControl={this.handleChangeSelectControl}
                    onRef={(ref) => (this.nutTypeId = ref)}
                    label="Choose one"
                    required
                    value={this.state.data.nutTypeId}
                    controlId="nutTypeId"
                    urlapilist={this.state.apiUrlTestType}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-2 control-label" htmlFor="attribute">
                  Custom Attribute Name <span className="required">(*)</span>
                </label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="name"
                    autoFocus={true}
                    className="form-control"
                    placeholder="Enter name of custom attribute"
                    required
                    value={this.state.data.name}
                    onChange={this.handleChangeObjectData}
                    maxLength={100}
                  />
                </div>
              </div>
              {/* <div className="form-group">
                <label className="col-md-2 control-label">
                  Combine Equation follow Name
                </label>
                <div className="col-md-4">
                  <Switch
                    name="showEquationWithName"
                    onChange={this.handleChangeSwitchShowEquationWithName}
                    checked={this.state.data.showEquationWithName}
                    className="react-switch"
                  />
                  <p>Ex: Custom Total (Insect + Rancid + Mold)</p>
                </div>
              </div> */}
              <div className="form-group">
                <TableAttribute
                  name="formulas"
                  onRef={(ref) => (this.formulas = ref)}
                  handleTableAttributeChange={this.handleTableAttributeChange}
                  processorId={loggedUser.processorId}
                  value={this.state.data.formulas}
                  testTypeId={this.state.data.nutTypeId}
                  isDynamic={this.state.isDynamic}
                  apiUrlDynamicAttribute={
                    GlobalConfig.REACT_APP_API_NUTTYPE_URL +
                    "getNameQualityDataFieldsByTestType/" +
                    this.state.data.nutTypeId +
                    "/true"
                  }
                  readOnly={
                    this.state.data.nutTypeId === undefined ||
                    this.state.data.nutTypeId === null ||
                    this.state.data.nutTypeId === ""
                  }
                />
              </div>
            </>
          ) : (
            <Form.Group as={Row}>
              <Form.Label column md="12">
                Selected attribute no longer exists. Please refresh and try
                again.
              </Form.Label>
            </Form.Group>
          )}
          {this.state.errorMessage == null ||
            this.state.errorMessage === "" ||
            this.state.errorMessage === undefined ? (
            ""
          ) : (
            <div className="form-group">
              <label className="col-md-2 control-label"></label>
              <label
                className="col-md-4 control-label"
                style={{ color: this.state.color }}
              >
                {this.state.errorMessage}
              </label>
            </div>
          )}

          <div className="form-group">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              {this.state.data ? (
                <SaveButton loading={this.state.loading} />
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Form>
    );
  };

  extendBody = () => {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {loggedUser.role === "Processors" || loggedUser.role === "Staffs"
          ? this.state.extendBodyMessage
          : this.state.extendBodyMessage}
      </div>
    );
  };

  doSomethingBeforeDeleteItem = (e) => {
    this.handleClose();
  };

  renderEquation = (row) => {
    let equation = "";
    if (
      row &&
      row.formulas !== undefined &&
      row.formulas !== null &&
      row.formulas !== ""
    ) {
      const items = JSON.parse(row.formulas);
      if (items && items.length > 0) {
        items.forEach((x) => {
          let e = "";
          if (x.value !== 1) {
            e = `(${x.value} * ${x.attribute})`;
          } else {
            e = `${x.attribute}`;
          }
          if (equation !== "") {
            equation += " + ";
          }
          equation += e;
        });
      }
    }
    return equation;
  };

  striped = () => {
    return false;
  };

  conditionalRowStyles = () => {
    return [
      {
        when: (row) =>
          this.state.data !== null &&
          this.state.data !== undefined &&
          row._id === this.state.data._id,
        style: {
          backgroundColor: "#ffdd99",
        },
      },
    ];
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Custom Attribute",
        selector: "name",
        sortable: true,
        left: true,
        width: "200px",
        cell: (row) => (
          <a
            href="#"
            onClick={() => this.handleShow(row)}
            title="Edit attribute"
          >
            {row.name}
            {this.state.data === undefined ||
              this.state.data === null ||
              row._id !== this.state.data._id
              ? ""
              : " (Editing)"}
          </a>
        ),
      },
      {
        name: "Equation",
        selector: "equation",
        sortable: false,
        left: true,
        cell: (row) => {
          return this.renderEquation(row);
        },
      },
      {
        name: "Test Type",
        selector: "testTypeName",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <a
              href={
                process.env.REACT_APP_URL_NUTTYPE_LIST + "/" + row.nutTypeId
              }
              title={row.testTypeName}
            >
              {row.testTypeName}
            </a>
          );
        },
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        width: "175px",
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT")
            : "";
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        width: "175px",
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT");
        },
      },
      {
        name: "Actions",
        center: true,
        width: "100px",
        cell: (row) => (
          <>
            <a
              title="Edit attribute"
              href="#"
              onClick={() => this.handleShow(row)}
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              title="Remove attribute"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };

  defaultButtons = () => {
    return (
      <>
        <a
          href="#"
          onClick={() =>
            this.handleShow({
              _id: "",
              name: "",
              formulas: "",
              equation: "",
              showEquationWithName: false,
              processorId: loggedUser.processorId,
              nutTypeId: "",
              isDynamic: false,
              createdUserId: loggedUser._id,
              createdDate: new Date(),
              modifiedUserId: "",
              modifiedDate: null,
            })
          }
          className="btn btn-primary panel-btn-group-item-position"
        >
          <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
        </a>
      </>
    );
  };

  handleSetDefault = async (data) => {
    let urlApi =
      GlobalConfig.REACT_APP_API_ATTRIBUTE + "setdefault/" + data._id;

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: data._id,
        status: data.status,
        modifiedUserId: loggedUser._id,
      }),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          //Loop all items on grid and update isDefault accordingly
          data.isDefault = true;
          that.setState({
            items: that.state.items.map((el) =>
              el._id === data._id ? Object.assign({}, el, data) : el
            ),
          });
          that.setState({
            originalItems: that.state.originalItems.map((el) =>
              el._id === data._id ? Object.assign({}, el, data) : el
            ),
          });

          if (that.state.items && that.state.items.length) {
            const itemsCopy = [...that.state.items];
            itemsCopy.map((el) => {
              if (
                el.status === "Published" &&
                el.isDefault &&
                el._id !== data._id
              ) {
                el.isDefault = false;
              }
            });
            that.setState({
              items: itemsCopy,
            });
          }
          if (that.state.originalItems && that.state.originalItems.length) {
            const originalItemsCopy = [...that.state.originalItems];
            originalItemsCopy.map((el) => {
              if (
                el.status === "Published" &&
                el.isDefault &&
                el._id !== data._id
              ) {
                el.isDefault = false;
              }
            });
            that.setState({
              originalItems: originalItemsCopy,
            });
          }

          window.showAlert("", "Updated successfully!", "");
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  handleShow = (data) => {
    let convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }
    this.setState({ data: convertedJSON }, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isEditMode: isEditMode,
          isDynamic: data.isDynamic,
        },
        () => {
          //Set data table attributes
          this.formulas.bindData(this.state.data.formulas);
        }
      );
    });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.setState({
      data: {
        _id: "",
        name: "",
        formulas: "",
        equation: "",
        showEquationWithName: false,
        processorId: loggedUser.processorId,
        nutTypeId: ``,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
    if (this.nutTypeId) {
      this.nutTypeId.setSelected(``);
    }
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
    });

    const nutTypeId = this.state.data.nutTypeId;
    const name = this.state.data.name;
    const formulas = this.state.data.formulas;

    let errorMessage = "";
    if (
      nutTypeId == null ||
      nutTypeId === undefined ||
      nutTypeId.trim() === ""
    ) {
      errorMessage = "Please select test type to continue.";
    } else if (name == null || name === undefined || name.trim() === "") {
      errorMessage = "Please enter name to continue.";
    }

    if (errorMessage === "") {
      if (
        formulas == null ||
        formulas === undefined ||
        formulas.trim() === "" ||
        JSON.parse(formulas).length === 0
      ) {
        errorMessage = this.state.errorEmptyAttribute;
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
    } else {
      //Using for edit
      let urlApi = GlobalConfig.REACT_APP_API_ATTRIBUTE + this.state.data._id;
      let method = "PATCH";
      if (
        this.state.data._id == null ||
        this.state.data._id === undefined ||
        this.state.data._id === ""
      ) {
        //Using for create new
        method = "POST";
        urlApi = GlobalConfig.REACT_APP_API_ATTRIBUTE;
      }

      const requestOptions = {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.data),
      };

      const that = this;
      this.setState(
        {
          loading: true,
        },
        () => {
          fetch(urlApi, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject.result === "OK") {
                window.showAlert("", "Save successful!", "");
                that.setState(
                  {
                    isChanged: true,
                  },
                  function callBack() {
                    that.updateStateAfterClosePopup(resultObject.data);
                  }
                );
                if (!that.state.isEditMode) {
                  that.handResetForm();
                }
              } else {
                window.showAlert("Error", resultObject.message, "error");
              }
              that.setState({ loading: false });
            })
            .catch(function (error) {
              window.showAlert("Error", error, "error");
              that.setState({ loading: false });
            });
        }
      );
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          name: "",
          formulas: "",
          equation: "",
          showEquationWithName: false,
          processorId: loggedUser.processorId,
          nutTypeId: ``,
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        //Set data table attributes
        this.formulas.bindData(this.state.data.formulas);
      }
    );
    if (this.nutTypeId) {
      this.nutTypeId.setSelected(``);
    }
  };

  handleCopy = async (data) => {
    const requestData = {
      _id: data._id,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };

    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };

      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_ATTRIBUTE + "copy/" + data._id,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If copied successful
          if (resultObject && resultObject.result === "OK") {
            //reset form
            that.getItems();
            window.showAlert("", "Copy successful!", "");
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "Error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  updateFilterControlUrls = () => {
    this.setState({
      filterControlUrls: {
        apiUrlTestTypeFilter: this.state.apiUrlTestType,
      },
    });
  };
}
export default App;
