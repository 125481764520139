import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

// var DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.children,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  setColumns = () => {
    return [
      {
        name: "Rule Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          return this.props.readOnly === true ||
            this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            row.name
          ) : (
            <a
              onClick={() => this.props.handleShow(row)}
              href="#"
              title="Edit tax & fee"
            >
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.props.testType.taxFeeByMeta,
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        cell: (row) => {
          {
            return parseFloat(row.value) < 0 ? (
              <span style={{ color: "red" }}>
                ${CommonUtilities.numberWithCommasNegative(row.value)}
              </span>
            ) : (
              "$" + CommonUtilities.numberWithCommasNegative(row.value)
            );
          }
        },
      },
      {
        name: "Rule Type",
        selector: "type",
        sortable: true,
        center: true,
        cell: (row) => row.type,
      },
      {
        name: "Tax or Fee Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: true,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate
      //       ? DateFormat(new Date(row.modifiedDate), "mm/dd/yyyy hh:MM TT")
      //       : "";
      //   },
      // },
      // {
      //   name: "Created Date",
      //   selector: "createdDate",
      //   sortable: true,
      //   center: true,
      //   cell: (row) => {
      //     return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
      //   },
      // },
      {
        omit: this.props.readOnly,
        name: "",
        cell: (row) => (
          <div>
            {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
              ""
            ) : (
              <>
                <a
                  title="Edit tax & fee"
                  href="#"
                  onClick={() => this.props.handleShow(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Remove tax & fee"
                  href="#"
                  className="on-default remove-row"
                  data-id={row._id}
                  data-growerid={row.growerId}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
              </>
            )}

            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
