import React from "react";
import DataTable from "react-data-table-component";
import { Button, Form, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import SaveButton from "../Controls/SaveButton";
import {
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
} from "../Utilities/Constant";
import CommonUtilities from "../Utilities/Common";
import { confirmAlert } from "react-confirm-alert";
import SelectMultiGrowers from "../Grower/SelectMultiGrowers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { isValid } from "date-fns";
import NodeUuid from "node-uuid";
import SubTableTemplate from "./SubTableTemplate";
import MultiSelectVarieties from "../TestType/MultiSelectVarieties";
import Popover from "../Base/Popover";
const { sortBy } = require("lodash");
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      varieties: [],
      items: [],
      isShow: false,
      readOnly: false,
      errorMessage: "",
      showAddPaymentsModal: false,
      isAdd: true,
      saveLoading: false,
      data: {
        _id: "",
        parentId: props.cropYearId,
        name: "",
        payments: "",
      },
      payments: [],
      //State using for popup add/edit payment setting
      payment: {
        _id: "",
        paymentDate: "",
        paymentPercent: 0,
        varietyId: "",
      },
      selectedGrowers: [],
      paymentVarietyError: "Variety is required.",
      paymentPercentError: "Payment percent must be greater than zero.",
      paymentExistError: "already exist. Please try other date.",
      isExpand: true,
      growers: [],
      messageApply: `Override payments under template with selected growers. This means if there is any existing payments under Payment Settings they will be reset. Ensure template settings saved before processing.`,
    };
    this.handleChange = this.handleChange.bind(this);
    this.hidePaymentPopup = this.hidePaymentPopup.bind(this);
    this.onChangePaymentDate = this.onChangePaymentDate.bind(this);
    this.onChangePaymentPercent = this.onChangePaymentPercent.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.handleEditPayment = this.handleEditPayment.bind(this);
    this.onChangeSelectedGrowers = this.onChangeSelectedGrowers.bind(this);
    this.openAddPaymentPopup = this.openAddPaymentPopup.bind(this);
    this.openEditPaymentPopup = this.openEditPaymentPopup.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  async getItems() {
    if (this.props.testType._id !== ``) {
      const response = await fetch(
        `${GlobalConfig.REACT_APP_API_PAYMENT_TEMPLATE_URL}parent/${this.props.cropYearId}/${this.props.testType._id}`
      );
      const resultObject = await response.json();
      if (resultObject.result === "OK") {
        this.setState(
          {
            items: resultObject.data,
            varieties: resultObject.varieties,
            isExpand: false,
          },
          () => {
            this.setState({ isExpand: true });
          }
        );
      } else {
        window.showAlert("Error", resultObject.message, "error");
      }
    }
  }

  newDataObject = () => {
    return {
      _id: "",
      parentId: this.props.cropYearId,
      name: "",
      payments: "",
    };
  };

  handleClosePopup = () => {
    this.setState({
      isShow: false,
      errorMessage: "",
    });
  };

  handleShowPopup = (data, readOnly) => {
    let payments = [];
    if (data.payments && data.payments !== ``) {
      const paymentsOrigin = JSON.parse(data.payments);
      if (paymentsOrigin && paymentsOrigin.length > 0) {
        if (this.props.testType.paymentByVariety === true) {
          paymentsOrigin.map((x) => {
            let varietyName = ``;
            if (x.varietyId && x.varietyId !== ``) {
              const temp = this.state.varieties.find(
                (v) => v._id == x.varietyId
              );
              if (temp) {
                varietyName = temp.name;
              }
            }
            x = JSON.parse(JSON.stringify(x));
            x.varietyName = varietyName;
            payments.push(x);
          });
          if (payments.length > 0) {
            payments = sortBy(
              payments,
              ["varietyId", `paymentDate`],
              ["asc", "asc"]
            );
          }
        } else {
          payments = paymentsOrigin;
          if (payments.length > 0) {
            payments = sortBy(payments, [`paymentDate`], ["asc"]);
          }
        }
      }
    }

    this.setState(
      {
        readOnly: readOnly,
        isShow: true,
        errorMessage: "",
        data: {
          ...this.state.data,
          _id: data._id,
          name: data.name,
          parentId: data.parentId,
          payments: data.payments,
          growerIds: data.growerIds,
        },
        payments: payments,
        selectedGrowers: [],
      },
      () => {
        this.growerIds.setSelected(data.growerIds);
      }
    );
  };

  handleSave = async (event) => {
    event.preventDefault();
    let { _id, name, parentId, growerIds } = this.state.data;
    const isCreate = !_id;

    let errorMessage = "";
    //Reset message
    this.setState({
      errorMessage: "",
    });

    if (errorMessage === ``) {
      if (name === undefined || name === null || name.trim() === "") {
        errorMessage = "Please enter name to continue";
      }
    }

    let payments = "";
    if (errorMessage === ``) {
      if (this.state.payments && this.state.payments.length > 0) {
        payments = JSON.stringify(this.state.payments);
      }
      if (
        payments === undefined ||
        payments === null ||
        payments.trim() === ""
      ) {
        errorMessage = "Please enter at least one payment setting to continue";
      }
    }

    if (errorMessage !== "") {
      this.setState({ errorMessage: errorMessage });
      return;
    }

    const apiURL = isCreate
      ? `${GlobalConfig.REACT_APP_API_PAYMENT_TEMPLATE_URL}`
      : `${GlobalConfig.REACT_APP_API_PAYMENT_TEMPLATE_URL}/${_id}`;
    const method = isCreate ? "POST" : "PATCH";

    const requestBody = isCreate
      ? {
        _id,
        name,
        parentId,
        payments,
        growerIds,
        createdUserId: this.props.loggedUser._id,
      }
      : {
        _id,
        name,
        parentId,
        payments,
        growerIds,
        modifiedUserId: this.props.loggedUser._id,
      };
    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(apiURL, requestOptions);
    const resultObject = await response.json();

    //If created successful
    if (resultObject.result === "OK") {
      window.showAlert("", "Save successful!", "");
      this.getItems();
      this.setState({
        isChanged: false,
        data: Object.assign({}, this.state.data, {
          _id: resultObject.data._id,
        }),
      });
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleButtonApply = async (event) => {
    event.preventDefault();
    this.handleApply(this.state.data._id);
  };

  handleApply = async (templateId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: templateId,
        createdUserId: this.props.loggedUser._id,
      }),
    };

    const response = await fetch(
      `${GlobalConfig.REACT_APP_API_PAYMENT_TEMPLATE_URL}apply/${templateId}`,
      requestOptions
    );
    const resultObject = await response.json();
    if (resultObject.result === "OK") {
      window.showAlert("", resultObject.message, "");
      this.props.refreshMainTab();
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleDelete = (e) => {
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected template?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            try {
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  userId: this.props.loggedUser._id,
                  modifiedUserId: this.props.loggedUser._id,
                }),
              };
              const response = await fetch(
                GlobalConfig.REACT_APP_API_PAYMENT_TEMPLATE_URL + removedId,
                requestOptions
              );
              const resultObject = await response.json();
              if (resultObject && resultObject.result === "OK") {
                this.handleDeleteAfterDeletedOnServer(removedId);
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    //If deleted successful
    const newItems = this.state.items.filter((item) => {
      return item._id !== removedId;
    });
    this.setState({
      items: newItems,
    });
    window.showAlert("Success", "Removed successful!", "");
  };

  setColumns = () => {
    return [
      {
        name: "Template Name",
        selector: "name",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <a
              href="#"
              title="Click to edit"
              onClick={() => this.handleShowPopup(row, false)}
            >
              {row.name}
            </a>
          );
        },
      },
      {
        name: "",
        width: "130px",
        cell: (row) => (
          <>
            {this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
              this.props.loggedUser.role === USER_ROLE_STAFF ? (
              <>
                <a
                  href="#"
                  onClick={() => this.handleShowPopup(row, false)}
                  className="on-default edit-row"
                  title="Click to edit"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Click here to remove"
                  href="# "
                  className="on-default remove-row"
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  href="#"
                  onClick={() => this.handleApply(row._id)}
                  className="on-default edit-row"
                  title={this.state.messageApply}
                >
                  <i className="fa fa-check fa-lg"></i>
                </a>
              </>
            ) : (
              <a
                href="#"
                onClick={() => this.handleShowPopup(row, true)}
                className="on-default edit-row"
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>
            )}
            {this.props.loggedUser.role === USER_ROLE_GROWER ? (
              ""
            ) : (
              <>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                  className="on-default remove-row"
                >
                  <i className="fa fa-history fa-lg"></i>
                </a>
              </>
            )}
          </>
        ),
        minWidth: "180px",
      },
    ];
  };

  hidePaymentPopup() {
    this.setState({
      showAddPaymentsModal: false,
    });
  }

  openAddPaymentPopup() {
    this.resetPayment();
    this.setState(
      {
        isAdd: true,
      },
      () => {
        this.showPaymentPopup();
      }
    );
  }

  openEditPaymentPopup(editingPayment, index) {
    this.setState(
      {
        isAdd: false,
        payment: editingPayment,
        editingPaymentIndex: index,
      },
      () => {
        this.showPaymentPopup();
      }
    );
  }

  showPaymentPopup() {
    this.setState(
      {
        showAddPaymentsModal: true,
      },
      () => {
        //Set selected dropdown
        if (
          this.state.payment.varietyId &&
          this.state.payment.varietyId !== ``
        ) {
          if (this.varietyId) {
            this.varietyId.setSelected(this.state.payment.varietyId);
          }
        }
      }
    );
  }

  resetPayment() {
    this.setState({
      payment: {
        _id: "",
        paymentDate: "",
        paymentPercent: 0,
        varietyId: "",
      },
    });
  }

  handleChange = (event) => {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  };

  onChangePaymentDate(date) {
    const formatDate = moment(date).format("L");
    if (date) {
      this.setState({
        payment: { ...this.state.payment, paymentDate: formatDate },
      });
    }
  }

  handleChangeSelectControl = (controlId, value, name) => {
    this.setState({
      payment: Object.assign({}, this.state.payment, {
        [controlId]: value,
        varietyName: name,
      }),
    });
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Payment Date is required.", "error");
    }
  }

  convertDateToNumber(date) {
    const momentTypeDate = moment(date, "MM/DD/YYYY");
    return new Date(
      momentTypeDate.year(),
      momentTypeDate.month(),
      momentTypeDate.date()
    ).setHours(23, 59, 59);
  }

  onChangePaymentPercent(e) {
    if (e.target) {
      this.setState({
        payment: { ...this.state.payment, paymentPercent: e.target.value },
      });
    }
  }

  handleAddPayment() {
    if (this.varietyId) {
      if (
        this.state.payment.varietyId === null ||
        this.state.payment.varietyId === undefined ||
        this.state.payment.varietyId.trim() === ``
      ) {
        window.showAlert("ERROR", this.state.paymentVarietyError, "error");
        return;
      }
    }
    if (this.state.payment.paymentPercent === 0) {
      window.showAlert("ERROR", this.state.paymentPercentError, "error");
      return;
    }
    let newPayments = [...this.state.payments];
    let newPayment = this.state.payment;
    if (newPayment.paymentDate === "") {
      newPayment.paymentDate = moment(new Date()).format("L");
    }

    //Check payment date is exist or not
    let duplicatePayments = this.varietyId
      ? newPayments.some(
        (payment) =>
          payment.paymentDate === newPayment.paymentDate &&
          payment.varietyId === newPayment.varietyId
      )
      : newPayments.some(
        (payment) => payment.paymentDate === newPayment.paymentDate
      );
    if (duplicatePayments) {
      window.showAlert(
        "ERROR",
        newPayment.paymentDate + " " + this.state.paymentExistError,
        "error"
      );
      return;
    }
    newPayment = JSON.parse(JSON.stringify(newPayment));
    newPayment._id = NodeUuid.v4();
    newPayments.push(newPayment);
    this.setState(
      {
        payments: newPayments,
      },
      () => {
        this.resetPayment();
        this.hidePaymentPopup();
      }
    );
  }

  handleEditPayment() {
    if (this.varietyId) {
      if (
        this.state.payment.varietyId === null ||
        this.state.payment.varietyId === undefined ||
        this.state.payment.varietyId.trim() === ``
      ) {
        window.showAlert("ERROR", this.state.paymentVarietyError, "error");
        return;
      }
    }
    if (this.state.payment.paymentPercent === 0) {
      window.showAlert("ERROR", this.state.paymentPercentError, "error");
      return;
    }

    let newPayments = [...this.state.payments];
    let newPayment = this.state.payment;
    //Check payment date is exist or not
    let duplicatePayments = this.varietyId
      ? newPayments.some(
        (payment) =>
          payment.paymentDate === newPayment.paymentDate &&
          payment._id !== newPayment._id &&
          payment.varietyId === newPayment.varietyId
      )
      : newPayments.some(
        (payment) =>
          payment.paymentDate === newPayment.paymentDate &&
          payment._id !== newPayment._id
      );
    if (duplicatePayments) {
      window.showAlert(
        "ERROR",
        `${newPayment.paymentDate} ${this.state.paymentExistError}`,
        "error"
      );
      return;
    }

    if (
      this.state.editingPaymentIndex !== null &&
      this.state.editingPaymentIndex !== undefined
    ) {
      newPayments[this.state.editingPaymentIndex] = this.state.payment;
      this.setState(
        {
          payments: newPayments,
        },
        () => this.hidePaymentPopup()
      );
    }
  }

  handleRemovePayment(index) {
    let newPayments = [...this.state.payments].filter((_, i) => i !== index);
    this.setState({
      payments: newPayments,
    });
  }

  onChangeSelectedGrowers(growers) {
    let growerIds = ``;
    if (growers && growers.length > 0) {
      growerIds = growers.map((x) => x.value).join(`,`);
    }
    this.setState({
      selectedGrowers: growers,
      data: Object.assign({}, this.state.data, {
        growerIds: growerIds,
      }),
    });
  }

  async getGrowers() {
    const result = await fetch(
      `${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersByProcessorAndTestType/${this.props.loggedUser.referenceUserId}/${this.props.testTypeId}`
    );
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      const growers = response.data.map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
        };
      });
      this.setState({
        growers: growers,
      });
    }
  }

  getPaymentColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.props.testType.paymentByVariety !== true,
        width: "200px",
      },
      {
        name: "Payment Date",
        center: true,
        cell: (payment) => moment(payment.paymentDate).format("L"),
      },
      {
        name: "Payment Percent",
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.paymentPercent == null ||
              row.paymentPercent === undefined ||
              isNaN(row.paymentPercent)
              ? 0
              : parseFloat(row.paymentPercent)
            ).toFixed(1)
          ) + "%",
        width: "140px",
        omit: this.props.testType.basePriceByPayment === true,
      },
      {
        name: "",
        right: true,
        cell: (payment, index) => (
          <div className="test-type-action-table" style={{ color: "blue" }}>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.openEditPaymentPopup(payment, index)}
            >
              <span>
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </span>
            </div>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.handleRemovePayment(index)}
            >
              <span>
                <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        ),
        width: "50px",
      },
    ];
  };

  render = () => {
    return (
      <fieldset>
        <div className="form-group">
          <a
            href="#"
            className="btn btn-primary panel-btn-group-item-position"
            onClick={() => this.handleShowPopup(this.newDataObject(), false)}
            style={{ marginBottom: "5px" }}
          >
            <i className="fa fa-plus fa-lg"></i> Template
          </a>
          <Modal
            show={this.state.isShow}
            onHide={this.handleClosePopup}
            animation={false}
            backdropClassName="modal-backdrop foo-modal-backdrop in"
            className="bulk-apply-payments-modal"
            centered
          >
            <Form onSubmit={this.handleSave} id="fromPopupTemplate">
              <Modal.Header>
                <Modal.Title>Template</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bulk-apply-body">
                <div className="form-group" style={{ marginRight: "-15px" }}>
                  <label className="col-md-3 control-label" htmlFor="name">
                    Template Name <span className="required">(*)</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      name="name"
                      className="form-control"
                      value={this.state.data.name}
                      onChange={this.handleChange}
                      maxLength={100}
                      required={true}
                      autoFocus
                    />
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ marginTop: "-10px", marginRight: "-15px" }}
                >
                  <label
                    className="col-md-3 control-label"
                    htmlFor="selectedGrowers"
                  >
                    Apply Growers
                  </label>
                  <div className="col-md-9">
                    <SelectMultiGrowers
                      externalSource={true}
                      source={this.state.growers}
                      selectedGrowers={this.state.selectedGrowers}
                      onChangeSelectedGrowers={this.onChangeSelectedGrowers}
                      placeholder="Select growers"
                      withoutLabel={false}
                      onRef={(ref) => (this.growerIds = ref)}
                    ></SelectMultiGrowers>
                  </div>
                </div>
                {this.state.payments.length > 0 && (
                  <DataTable
                    key="paymentTable"
                    id="paymentTable"
                    style={{ marginTop: "-15px", marginRight: "-15px" }}
                    columns={this.getPaymentColumns()}
                    noHeader={true}
                    data={this.state.payments}
                  ></DataTable>
                )}
                <div className="col-md-9 col-md-offset-3">
                  {!!this.state.errorMessage && (
                    <>
                      <Form.Label
                        column
                        md="12"
                        style={{ color: "red", paddingLeft: "0px" }}
                      >
                        {this.state.errorMessage}
                      </Form.Label>
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="bulk-apply-payments-modal-footer">
                <Button variant="primary" onClick={this.openAddPaymentPopup}>
                  <i className="fa fa-plus fa-lg"></i> Add Payment
                </Button>
                <SaveButton
                  text={"Save"}
                  type={"submit"}
                  icon={
                    <span
                      className="fa fa-floppy-o fa-lg"
                      style={{ marginRight: 3 }}
                    ></span>
                  }
                  disabled={this.state.saveLoading === false ? false : true}
                  loading={this.state.saveLoading}
                  onClick={this.handleSave}
                />
                {this.state.data._id && this.state.data._id !== `` ? (
                  <>
                    <button
                      type="button"
                      onClick={this.handleButtonApply}
                      className="btn btn-success"
                      disabled={this.state.saveLoading === false ? false : true}
                      loading={this.state.saveLoading}
                    >
                      <div className="popover-wrapper">
                        <span className="fa fa-check fa-lg"></span> Apply&nbsp;
                        <Popover
                          trigger={["hover", "focus"]}
                          className="popover-custom"
                          placement="top"
                          content={this.state.messageApply}
                          isUseDefaultOverlay
                        >
                          <i
                            className="fa fa-info-circle panel-heading-info-icon fa-lg"
                            aria-hidden="true"
                          />
                        </Popover>
                      </div>
                    </button>
                  </>
                ) : (
                  ""
                )}
                <Button
                  style={{ margin: "0 5px" }}
                  variant="warning"
                  onClick={this.handleClosePopup}
                >
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Form
            onSubmit={
              this.state.isAdd ? this.handleAddPayment : this.handleEditPayment
            }
            id="fromPopupPaymentSetting"
          >
            <Modal
              show={this.state.showAddPaymentsModal}
              onHide={this.hidePaymentPopup}
              animation={false}
              backdropClassName="modal-backdrop foo-modal-backdrop in"
              className="add-payments-modal"
              centered
            >
              <Modal.Header>
                <Modal.Title>Payment Setting</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.props.testType.paymentByVariety === true ? (
                  <div className="form-group">
                    <label
                      className="col-md-4 control-label"
                      htmlFor="attribute"
                    >
                      Variety <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <MultiSelectVarieties
                        isMulti={false}
                        showSelectAll={false}
                        required={true}
                        onRef={(ref) => (this.varietyId = ref)}
                        name="varietyId"
                        controlId="varietyId"
                        selectedIds={this.state.payment.varietyId}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        urlApi={
                          GlobalConfig.REACT_APP_API_VARIETY_URL +
                          "byParentId/" +
                          this.props.testType._id
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <label className="col-md-4 control-label" htmlFor="attribute">
                    Payment Date <span className="required">(*)</span>
                  </label>
                  <div className="col-md-8">
                    <div
                      className="input-daterange input-group"
                      style={{ width: "100%" }}
                    >
                      <span className="input-group-addon">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <DatePicker
                        className="form-control"
                        placeholderText="Enter datetime"
                        selected={
                          this.state.payment.paymentDate
                            ? new Date(this.state.payment.paymentDate)
                            : new Date()
                        }
                        onChange={this.onChangePaymentDate}
                        onBlur={this.handleOnBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <NumericInput
                    precision={1}
                    min={0}
                    max={100}
                    step={0.1}
                    label="Payment Percent"
                    name="paymentPercent"
                    value={this.state.payment.paymentPercent}
                    handleChange={this.onChangePaymentPercent}
                    unit="%"
                    labelClass="4"
                    inputClass="8"
                    required={true}
                    labelrequired="{*}"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="add-payments-modal-footer">
                {this.state.isAdd ? (
                  <Button
                    style={{ margin: "0 5px" }}
                    variant="success"
                    type="submit"
                    onClick={this.handleAddPayment}
                  >
                    <span className="fa fa-check fa-lg"></span> Submit
                  </Button>
                ) : (
                  <Button
                    style={{ margin: "0 5px" }}
                    variant="success"
                    type="submit"
                    onClick={this.handleEditPayment}
                  >
                    <span className="fa fa-check fa-lg"></span> Submit
                  </Button>
                )}
                <Button
                  style={{ margin: "0 5px" }}
                  variant="warning"
                  onClick={this.hidePaymentPopup}
                >
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          <DataTable
            key="templateTable"
            id="templateTable"
            highlightOnHover
            striped
            noHeader={true}
            columns={this.setColumns()}
            data={this.state.items}
            className="table table-bordered table-striped mb-none"
            pagination={false}
            noDataComponent="There is no template"
            expandableRows={true}
            expandableRowsComponent={
              <SubTableTemplate
                expandableRowExpanded={(row) => {
                  return this.state.isExpand;
                }}
                loggedUser={this.props.loggedUser}
                testType={this.props.testType}
                varieties={this.state.varieties}
                paymentByVariety={this.props.testType.paymentByVariety}
              />
            }
            expandableRowExpanded={(row) => {
              return this.state.isExpand;
            }}
          ></DataTable>
        </div>
      </fieldset>
    );
  };
}
export default App;
