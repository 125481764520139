import axios from "axios";

// Check if the user with email is active or not
export const checkUserActive = async (email) => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/apiaccount/check-active/${email}`,
      method: "GET",
    });

    if (res.data.result === "OK") {
      return res.data.isActive;
    }

    const errorMessage = res.data.message;
    throw new Error(errorMessage);
  } catch (ex) {
    throw ex;
  }
};
