import React, { useEffect, useState } from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      listtitle: "Varieties",
      urllist: process.env.REACT_APP_URL_NUTTYPE_LIST,
      urlapi: GlobalConfig.REACT_APP_API_VARIETY_URL,
      data: {
        _id: props.match.params.id,
        name: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
    };
  }

  doSomethingAfterGetItemById = () => {
    this.setState({
      urllist:
        process.env.REACT_APP_URL_NUTTYPE_LIST +
        "/varieties/" +
        this.state.data.parentId,
    });
  };

  getTitle = () => {
    return this.state.data.name;
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Name <span className="required">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="name"
              autoFocus={true}
              className="form-control"
              placeholder=""
              required
              value={this.state.data.name}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>
        </div>
      </div>
    );
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a href={process.env.REACT_APP_URL_NUTTYPE_LIST} title="Back to list">
          Test Types
        </a>
      </li>
    );
  };
}
export default App;
