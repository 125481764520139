import React from "react";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      controlId: props.controlId,
      urlapi: GlobalConfig.REACT_APP_API_MEMBERSHIP_TYPE_URL,
      data: [],
    };
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const that = this;
      fetch(this.state.urlapi).then(function (response) {
        return response.json();
      })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK" && resultObject.data) {
            const parsedItems = [];
            resultObject.data.map((item) => {
              parsedItems.push({
                _id: item._id,
                name: item.name,
                checked: false,
              });
            });
            that.setState({
              data: parsedItems,
            });
          }
        }).catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds === null ||
          selectedIds === undefined ||
          selectedIds.indexOf(item._id) === -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleCheckChieldElement = (event) => {
    let updatedData = this.state.data;
    updatedData.forEach((item) => {
      if (item._id === event.target.value) item.checked = event.target.checked;
    });
    this.setState({ data: updatedData });

    let selectedIds = "";
    this.state.data.forEach((item) => {
      if (item.checked === true) {
        if (selectedIds != "") {
          selectedIds += ",";
        }
        selectedIds += item._id;
      }
    });
    //fire event and call method in parent to update state of parent
    this.props.handleCheckboxUpdateState(this.state.controlId, selectedIds);
  };

  render() {
    return (
      <ul className="widget-todo-list">
        {this.state.data
          ? this.state.data.map((item, key) => {
            return (
              <li key={item._id}>
                <div className="checkbox-custom checkbox-default">
                  {item.checked === true ? (
                    <input
                      type="checkbox"
                      key={item._id}
                      id={item._id}
                      className="todo-check"
                      value={item._id}
                      name={item._id}
                      checked
                      onChange={this.handleCheckChieldElement}
                    ></input>
                  ) : (
                    <input
                      type="checkbox"
                      key={item._id}
                      id={item._id}
                      className="todo-check"
                      value={item._id}
                      name={item._id}
                      onChange={this.handleCheckChieldElement}
                    ></input>
                  )}

                  <label className="todo-label" htmlFor={item._id}>
                    <span>{item.name}</span>
                  </label>
                </div>
              </li>
            );
          })
          : ""}
      </ul>
    );
  }
}
export default App;
