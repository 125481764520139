import React from "react";
import { Table } from "react-bootstrap";
import CommonUtilities from "../Utilities/Common";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: props.data,
      title: props.title,
      attributeFilter: props.attributeFilter,
      items: [],
    };
    this.bindData = this.bindData.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  bindData(data) {
    this.setState({
      items: data,
    });
    // console.log("call from table attribute");
  }

  render() {
    return (
      <div className="row">
        {this.state.items.map((u, key) => {
          return (
            <div
              className="col-md-1"
              style={{
                width: u.attribute.indexOf("Total") !== -1 ? "20%" : "10%",
              }}
            >
              <Table responsive striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <strong>
                        {u.attribute
                          .toString()
                          .replace(this.state.attributeFilter, "")}
                      </strong>
                    </td>
                  </tr>
                  {u.children.map((c) => {
                    return (
                      <tr key={c.percent}>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {c.percent}% &nbsp;&nbsp;&nbsp;
                          {parseFloat(c.value) < 0 ? (
                            <span className="deduct-color">
                              $
                              {CommonUtilities.numberWithCommasNegative(
                                parseFloat(c.value).toFixed(2)
                              )}
                            </span>
                          ) : (
                            <span className="bonus-color">
                              $
                              {CommonUtilities.numberWithCommas(
                                parseFloat(c.value).toFixed(2)
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          );
        })}
      </div>
    );
  }
}
export default App;
