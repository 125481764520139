import React from "react";
import TextTruncate from "react-text-truncate"; // recommend
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Modal } from "react-bootstrap";
import {
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
  VALUE_QUERY_PARAM_IGNORE,
  METHOD_PATCH,
} from "../Utilities/Constant";
const moment = require("moment");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    let urlapi = "";
    if (loggedUser.role === USER_ROLE_GROWER) {
      urlapi = `${GlobalConfig.REACT_APP_API_MESSAGE_URL}bygrower/${loggedUser.referenceUserId}/${VALUE_QUERY_PARAM_IGNORE}`;
    } else if (
      loggedUser.role === USER_ROLE_STAFF ||
      loggedUser.role === USER_ROLE_PROCESSOR
    ) {
      urlapi = `${GlobalConfig.REACT_APP_API_MESSAGE_URL}byprocessor/${loggedUser.processorId}`;
    }
    this.state.urlapi = urlapi;
    this.state.urllist = process.env.REACT_APP_URL_MAILBOX_LIST;
    this.state.title = "Message";

    this.state.pageName = "Message";
    this.state.show = false;
    this.state.message = "";
    this.state.useFilter = false;
    this.state.isServerPaging = true;
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Message",
        selector: "message",
        cell: (row) => {
          return (
            <a
              href="#"
              onClick={() => {
                this.onRowClicked(row);
              }}
              style={{
                fontWeight:
                  row.isUnread &&
                  loggedUser.role === USER_ROLE_GROWER &&
                  "bold",
              }}
            >
              <TextTruncate
                line={1}
                element="span"
                truncateText="…"
                text={row.message}
              />
            </a>
          );
        },
      },
      {
        name: "Sent By",
        selector: "processorName",
        width: "200px",
        cell: (row) => {
          return (
            <span
              style={{
                fontWeight:
                  row.status === "New" && loggedUser.role === USER_ROLE_GROWER
                    ? "bold"
                    : "",
              }}
            >
              {row.processorName}
            </span>
          );
        },
        omit: loggedUser.role !== USER_ROLE_GROWER,
      },
      {
        name: "Sent To",
        selector: "growerName",
        width: "300px",
        omit: loggedUser.role === USER_ROLE_GROWER,
      },
      {
        name: loggedUser.role === USER_ROLE_GROWER ? "Received On" : "Sent On",
        selector: "createdDate",
        center: true,
        cell: (row) => {
          const currentDate = moment(new Date()).format("MM/DD/YYYY");
          const date = moment(row.createdDate).format("MM/DD/YYYY hh:mm A");
          return (
            <span
              style={{
                fontWeight:
                  row.status === "New" && loggedUser.role === USER_ROLE_GROWER
                    ? "bold"
                    : "",
              }}
            >
              {date.replace(currentDate, "")}
            </span>
          );
        },
        width: "165px",
      },
    ];
  };

  markAsRead = (messageId) => {
    const url = `${GlobalConfig.REACT_APP_API_MESSAGE_URL}markasread/${messageId}/${loggedUser.referenceUserId}`;
    const requestOptions = {
      method: METHOD_PATCH,
      headers: { "Content-Type": "application/json" },
    };
    fetch(url, requestOptions).catch((exception) => {
      console.log(exception);
    });
    this.setState({
      items: [...this.state.items].map((message) => {
        if (message._id === messageId) {
          message.isUnread = false;
        }
        return message;
      }),
    });
  };

  onRowClicked = (row) => {
    this.setState({ show: true, data: row });
    //If message is unread let mark as read once grower reads it
    if (row.isUnread && loggedUser.role === USER_ROLE_GROWER) {
      this.markAsRead(row._id);
    }
  };

  striped = () => {
    return false;
  };

  highlightOnHover = () => {
    return false;
  };

  extendRender = () => {
    const currentDate = moment(new Date()).format("MM/DD/YYYY");
    const date = moment(this.state.data.createdDate)
      .format("MM/DD/YYYY hh:mm A")
      .replace(currentDate, "");
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <>
            <div className="mailbox-email-container">
              <div className="mailbox-email-screen">
                <div className="panel">
                  {loggedUser.role === USER_ROLE_GROWER ? (
                    <div className="panel-heading">
                      {/* <div className="panel-actions">
                        <a href="#" className="fa fa-caret-down"></a>
                        <a href="#" className="fa fa-mail-reply"></a>
                        <a href="#" className="fa fa-mail-reply-all"></a>
                        <a href="#" className="fa fa-star-o"></a>
                      </div> */}
                      <p className="panel-title">
                        {this.state.data.processorName}{" "}
                        <i className="fa fa-angle-right fa-fw"></i> You
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="panel-body">
                    <p>{this.state.data.message}</p>
                    {loggedUser.role !== USER_ROLE_GROWER ? (
                      <>
                        <hr></hr>
                        <p>Sent To: {this.state.data.growerName}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="panel-footer">
                    <p className="m-none">
                      <small>{date}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: "center", marginTop: "-20px" }}>
          <Button variant="warning" onClick={this.handleClose}>
            <span className="fa fa-times fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}
export default App;
