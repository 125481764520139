import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="columnChooser col-md-12 btnOpenColummChooserMultitable">
        <div
          className={
            this.props.btnColumnOptions === true
              ? "btnOpenColumnChooserContainer btnOpenColumnChooserContainerHide"
              : "btnOpenColumnChooserContainer"
          }
        >
          <button
            className="btn btn-column-chooser panel-btn-group-color"
            onClick={this.props.columnOptionOpening}
            title="Column Options"
          >
            <i className="fa fa-list fa-lg"></i>
          </button>
        </div>

        <div
          className={
            this.props.btnColumnOptions
              ? "columnChooserContainer spacingColumnChooserMultitable"
              : "columnChooserContainer spacingColumnChooserMultitable columnChooserContainerHide"
          }
        >
          <div className="columnChooserContainerTitle">
            <div>Column Options</div>

            <i
              className="fa fa-times fa-lg btnCloseColumnChooser"
              onClick={this.props.columnOptionClosed}
            ></i>
          </div>

          <div className="columnChooserItemWrapper">
            {this.props.defaultColumns.map((item, index) => {
              return item.omit !== true &&
                item.name !== "" &&
                item.name !== "Actions" && (
                <div className="columnChooserItem"
                  key={index}
                >
                  <input
                    className="columnChooserItemCheckbox"
                    type="checkbox"
                    id={item.name + this.props.titlePage}
                    name={item.selector}
                    checked={
                      this.props.hideColumns.indexOf(item.selector) === -1
                    }
                    onChange={this.props.columnOptionChanged}
                  ></input>
                  <label
                    className="columnChooserItemLabel"
                    htmlFor={item.name + this.props.titlePage}
                  >
                    {item.name}
                  </label>
                </div>
              ) 
            })}
          </div>

          <div className="columnChooserFooter">
            <button
              className="btn btn-primary"
              onClick={this.props.columnOptionReset}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
