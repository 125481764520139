import React from "react";
import DataTable from "react-data-table-component";
import { Button, Form, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import NodeUuid from "node-uuid";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import Textbox from "../FormControl/Textbox";
import Textarea from "../FormControl/Textarea";
import Popover from "../Base/Popover";

import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_TEXT_BOX,
  FIELD_TYPE_TEXT_SMALL,
} from "../Utilities/Constant";

class App extends React.Component {
  constructor(props) {
    super();

    let attributeItems = [];
    if (props.value && props.value !== "") {
      attributeItems = JSON.parse(props.value);
    }

    this.state = {
      name: props.name,
      items: attributeItems,

      //Using for popup add/edit
      data: {
        _id: "",
        attribute: "",
        value: ``,
        //only has value if dynamic test
        testFieldId: "",
        extraValue: "", //Store classification item id
      },
      isChanged: false,
      errorMessage: "",
      color: "red",

      metaAttributes: [],
      selectedMetaAttribute: undefined,
      emptyClass: props.emptyClass ? props.emptyClass : `col-md-3`,
      tableClass: props.tableClass ? props.tableClass : `col-md-9`,
      objectName: props.objectName ? props.objectName : `Base Price`
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  async componentDidMount() {
    //using for parent call method bindData outside
    this.props.onRef(this);
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method bindData outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const that = this;
      fetch(this.props.apiUrlDynamicAttribute)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK" && resultObject.data) {
            that.setState({ metaAttributes: resultObject.data });
          }
        }).catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  reset() {
    this.bindData(``)
  }

  bindData(items) {
    let attributeItems = [];
    if (items && items !== "") {
      attributeItems = JSON.parse(items);
    }
    this.setState({ items: attributeItems });
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState({
      data: Object.assign({}, this.state.data, {
        value: value,
      }),
    });
  }

  handleChangeSelectControl = (controlId, value) => {
    if (controlId === `attribute`) {
      let selectedMetaAttribute = undefined;
      const metaAttributes = this.state.metaAttributes.filter((x) => {
        return x._id === value.value;
      });
      if (metaAttributes && metaAttributes.length > 0) {
        selectedMetaAttribute = metaAttributes[0];
      }
      this.setState({
        data: Object.assign({}, this.state.data, {
          [`attribute`]: value.label,
          [`testFieldId`]: value.value,
          value: "",
          extraValue: "",
        }),
        selectedMetaAttribute: selectedMetaAttribute,
      });
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          value: value.label === `Choose one` ? `` : value.label,
          extraValue: value.label === `Choose one` ? `` : value.value,
        }),
      });
    }
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));

    let selectedMetaAttribute = undefined;
    const metaAttributes = this.state.metaAttributes.filter((x) => {
      return x._id === convertedJSON.testFieldId;
    });
    if (metaAttributes && metaAttributes.length > 0) {
      selectedMetaAttribute = metaAttributes[0];
    }
    // this.setState({
    //   selectedMetaAttribute: selectedMetaAttribute,
    // });

    this.setState({ data: convertedJSON }, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          selectedMetaAttribute: selectedMetaAttribute,
        },
        () => {
          if (this.attribute) {
            this.attribute.setSelected(this.state.data.testFieldId);
          }
        }
      );
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    //Reset message
    this.setState({
      errorMessage: "",
    });

    let attribute = this.state.data.attribute;
    const value = this.state.data.value;

    let errorMessage = "";
    //validate input
    if (
      attribute == null ||
      attribute === undefined ||
      attribute.trim() === ""
    ) {
      errorMessage = "Select meta attribute to continue.";
    } else {
      attribute = attribute.trim();
    }
    if (errorMessage === "") {
      if (value == null || value === undefined || value === "") {
        errorMessage = "Please enter value.";
      }
    }

    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      isEditMode = false;
    }
    //check duplicate first name & last name
    if (errorMessage === "") {
      let id = this.state.data._id;
      let attributeCompare = attribute.toLowerCase();
      let tempItems = [];
      let items = Object.assign([], this.state.items);
      if (isEditMode !== true) {
        tempItems = items.filter(function (item) {
          return item.attribute.toLowerCase() === attributeCompare;
        });
      } else {
        tempItems = items.filter(function (item) {
          return (
            item.attribute.toLowerCase() === attributeCompare && item._id !== id
          );
        });
      }
      if (tempItems && tempItems.length > 0) {
        errorMessage =
          "Attribute already exists. Please select other attribute.";
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
    } else {
      if (isEditMode !== true) {
        var convertedJSON = JSON.parse(JSON.stringify(this.state.data));
        convertedJSON._id = NodeUuid.v4();
        let items = Object.assign([], this.state.items);
        items.push(convertedJSON);
        this.setState({ items: items }, function callBack() {
          //fire event to parent
          var value = JSON.stringify(this.state.items);
          this.props.handleTableAttributeChange(this.state.name, value);
        });
      } else {
        //update selected item in this.state.items
        this.setState(
          {
            items: this.state.items.map((u) =>
              u._id === this.state.data._id
                ? Object.assign({}, u, {
                  attribute: attribute,
                  value: value,
                  testFieldId: this.state.data.testFieldId,
                  extraValue: this.state.data.extraValue,
                })
                : u
            ),
          },
          function callBack() {
            //fire event to parent
            var value = JSON.stringify(this.state.items);
            this.props.handleTableAttributeChange(this.state.name, value);
          }
        );
      }

      let result = "OK";
      if (result === "OK") {
        this.setState({
          isChanged: true,
        });
        if (!isEditMode) {
          this.handResetForm();
        } else {
          this.setState({ show: false });
        }
      } else {
        // window.showAlert("Error", resultObject.message, "error");
      }
    }
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        attribute: "",
        value: ``,
        //only has value if dynamic test
        testFieldId: "",
        extraValue: "", //Store classification item id
      },
    });
    if (this.attribute) {
      this.attribute.setSelected("");
    }
  };

  renderFieldByType = (field) => {
    const displayRequired = false;
    field.name = "Value";
    field.isMandatory = true;
    switch (field.fieldType) {
      case FIELD_TYPE_TEXT_SMALL:
        return (
          <Textbox
            type="text"
            classProps="form-group-spacing"
            label={field.name}
            name="value"
            value={this.state.data.value}
            handleChange={this.handleChange}
            maxLength={field.maxLength}
            required={field.isMandatory}
            displayRequired={displayRequired}
            placeholder={field.name}
          />
        );
      case FIELD_TYPE_TEXT_BOX:
        return (
          <Textarea
            rows={5}
            type="text"
            classProps="form-group-spacing"
            label={field.name}
            name="value"
            value={this.state.data.value}
            handleChange={this.handleChange}
            maxLength={field.maxLength}
            required={field.isMandatory}
            displayRequired={displayRequired}
            placeholder={field.name}
          />
        );
      case FIELD_TYPE_DROP_DOWN:
        const url = `${this.props.GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${field.testClassificationId}`;
        return (
          <div className="form-group">
            <label className="col-md-4 control-label" htmlFor="value">
              {field.name} <span className="required">(*)</span>
            </label>
            <div className="col-md-8">
              <FilterSelectControl
                handleChange={this.handleChangeSelectControl}
                onRef={(ref) => (this.value = ref)}
                label="Choose one"
                required={true}
                value={this.state.data.extraValue}
                name="value"
                controlId="value"
                urlapilist={url}
              />
            </div>
          </div>
        );
      case FIELD_TYPE_DECIMAL:
        return (
          <NumericInput
            classProps="form-group-spacing"
            min={field.min}
            max={field.max}
            precision={field.precision}
            step={field.step}
            label={field.name}
            name="value"
            value={this.state.data.value}
            handleChange={this.handleChange}
            required={field.isMandatory}
            displayRequired={displayRequired}
            labelrequired={field.isMandatory ? "(*)" : undefined}
            unit={field.unit}
          />
        );
      case FIELD_TYPE_PERCENT:
        return (
          <NumericInput
            classProps="form-group-spacing"
            label={field.name}
            min={field.min}
            max={field.max}
            precision={field.precision}
            step={field.step}
            name="value"
            value={this.state.data.value}
            handleChange={this.handleChange}
            required={field.isMandatory}
            displayRequired={displayRequired}
            labelrequired={field.isMandatory ? "(*)" : undefined}
            unit={field.unit ? field.unit : "%"}
          />
        );
      case FIELD_TYPE_INTEGER:
        return (
          <NumericInput
            classProps="form-group-spacing"
            min={field.min}
            max={field.max}
            precision={0}
            step={field.step}
            label={field.name}
            name="value"
            value={this.state.data.value}
            handleChange={this.handleChange}
            required={field.isMandatory}
            displayRequired={displayRequired}
            labelrequired={field.isMandatory ? "(*)" : undefined}
            unit={field.unit}
          />
        );
      default:
        return <></>;
    }
  };

  extendRender = () => {
    return (
      <Modal
        // dialogAs={DraggableModalDialog}
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.data._id === ""
                ? "Select Attribute"
                : "Select Attribute"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-4 control-label" htmlFor="attribute">
                Meta Attribute
                <Popover
                  trigger={["hover", "focus"]}
                  className="popover-custom"
                  placement="top"
                  content={'Test fields under the "Meta Data" heading are those available for ' + this.state.objectName + ' setting.'}
                  isUseDefaultOverlay
                >
                  <i
                    className="fa fa-info-circle panel-heading-info-icon mr-xs"
                    aria-hidden="true"
                    style={{
                      color: "#777",
                    }}
                  />
                </Popover>
                <span className="required">(*)</span>
              </label>
              <div className="col-md-8">
                <FilterSelectControl
                  handleChange={this.handleChangeSelectControl}
                  onRef={(ref) => (this.attribute = ref)}
                  label="Choose one"
                  required={true}
                  value={this.state.data.attribute}
                  name="attribute"
                  controlId="attribute"
                  urlapilist={this.props.apiUrlDynamicAttribute}
                />
              </div>
            </div>
            <div className="form-group">
              {this.state.selectedMetaAttribute
                ? this.renderFieldByType(this.state.selectedMetaAttribute)
                : ""}
            </div>
            <div className="col-md-12">
              {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                ""
              ) : (
                <>
                  <Form.Label style={{ color: this.state.color }}>
                    {this.state.errorMessage}
                  </Form.Label>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {this.state.data ? (
                <Button
                  variant="success"
                  type="button"
                  onClick={() => this.handleSave()}
                >
                  <span className="fa fa-check fa-lg"></span> OK
                </Button>
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  handleDelete = (removedId) => {
    this.setState(
      {
        items: this.state.items.filter(function (item) {
          return item._id !== removedId._id;
        }),
      },
      function callBack() {
        //fire event to parent
        var value = JSON.stringify(this.state.items);
        this.props.handleTableAttributeChange(this.state.name, value);
      }
    );
  };

  render() {
    return (
      <>
        {this.props.readOnly ? (
          ""
        ) : (
          <div className="form-group">
            <div className={this.state.emptyClass}></div>
            <div className="col-md-4 text-left">
              <div className="btn-group">
                <button
                  type="button"
                  onClick={() =>
                    this.handleShow({
                      _id: "",
                      attribute: "",
                      value: ``,
                      //only has value if dynamic test
                      testFieldId: "",
                      extraValue: "", //Store classification item id
                    })
                  }
                  className="btn btn-primary btn-sm"
                >
                  <div className="popover-wrapper">
                    Select Meta Attribute
                    <Popover
                      trigger={["hover", "focus"]}
                      className="popover-custom"
                      placement="top"
                      content={'Test fields under the "Meta Data" heading are available for ' + this.state.objectName + ' setting.'}
                      isUseDefaultOverlay
                    >
                      <i
                        className="fa fa-info-circle panel-heading-info-icon"
                        aria-hidden="true"
                      />
                    </Popover>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.items && this.state.items.length > 0 ? (
          this.props.displayTableOnly ? (
            <DataTable
              highlightOnHover
              striped
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={false}
            />
          ) : this.props.readOnly ? (
            <div className="form-group">
              <div className={this.state.emptyClass}></div>
              <div className="col-md-8">
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div className={this.state.emptyClass}></div>
              <div className={this.state.tableClass}>
                <DataTable
                  highlightOnHover
                  striped
                  title=""
                  noHeader={true}
                  columns={this.setColumns()}
                  data={this.state.items}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                />
              </div>
            </div>
          )
        ) : (
          ""
        )}
        {this.extendRender()}
      </>
    );
  }

  setColumns = () => {
    return [
      {
        name: "Attribute",
        selector: "attribute",
        sortable: true,
        cell: (row) =>
          this.props.readOnly ? (
            <span
              style={{
                color:
                  this.props.displayTableOnly && this.props.color !== ""
                    ? this.props.color
                    : "",
              }}
            >
              {row.attribute}
            </span>
          ) : (
            <a href="#" onClick={() => this.handleShow(row)} title="Edit">
              {row.attribute}
            </a>
          ),
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
      },
      {
        name: "",
        center: true,
        cell: (row) =>
          this.props.readOnly ? (
            ""
          ) : (
            <a
              href="#"
              title="Remove"
              onClick={() => this.handleDelete(row)}
              className="on-default remove-row"
              data-id={row._id}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
          ),
        width: "20px",
      },
    ];
  };
}

export default App;
