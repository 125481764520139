import React from "react";
import EditComponent from "../Base/EditComponent";
import TableOwner from "../Grower/TableOwner";
import ReactJson from "react-json-view";

const GlobalConfig = new window.globalConfig();
var DateFormat = require("dateformat");

class App extends EditComponent {
  constructor(props) {
    super();
    this.state = {
      listtitle: "Revision History",
      urllist: process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST,
      urlapi: GlobalConfig.REACT_APP_API_AUDIT_URL,
      isUseHeading: false,
      data: {
        _id: props.match.params.id,
        processorId: "",
        objectId: "",
        ip: "",
        message: "",
        action: "",
        type: "",
        before: "",
        after: "",
        createdUserId: "",
        createdDate: new Date(),
      },
      before: {},
      after: {},
    };
  }

  handleTableOwnerChange = (name, value) => {};

  defaultTitle = () => {
    if (this.state.data.action === "Login") {
      return "Revision History";
    }
    return "Revision History of " + this.state.data.type;
  };

  renderFooter = () => {
    return "";
  };

  doSomethingAfterGetItemById = () => {
    if (
      this.state.data.before !== undefined &&
      this.state.data.before !== null &&
      this.state.data.before !== ""
    ) {
      this.setState({
        before: JSON.parse(this.state.data.before),
      });
    }
    if (
      this.state.data.action === "Update" ||
      this.state.data.action === "Approve" ||
      this.state.data.action === "Waiting Approval"
    ) {
      if (
        this.state.data.after !== undefined &&
        this.state.data.after !== null &&
        this.state.data.after !== ""
      ) {
        this.setState({
          after: JSON.parse(this.state.data.after),
        });
      }
    }
  };

  renderDefault = (data, key, isAfter) => {
    return (
      <label
        className="col-md-8 control-label"
        style={{
          textAlign: "left",
          color:
            isAfter &&
            (this.state.data.action === "Update" ||
              this.state.data.action === "Approve" ||
              this.state.data.action === "Waiting Approval") &&
            this.state.after[key] !== this.state.before[key]
              ? "red"
              : "",
          wordWrap: "break-word",
        }}
      >
        {data[key] !== undefined &&
        data[key] !== null &&
        data[key].toString().indexOf("http") !== -1 &&
        this.state.data.type !== "Help" ? (
          <a href={data[key]} target="_blank">
            View
          </a>
        ) : (
          data[key]
        )}
      </label>
    );
  };

  renderField = (data, key, isAfter) => {
    switch (this.state.data.type) {
      case "Grower": {
        if (key !== "Owners") {
          return (
            <label
              className="col-md-8 control-label"
              style={{
                textAlign: "left",
                color:
                  isAfter &&
                  (this.state.data.action === "Update" ||
                    this.state.data.action === "Approve" ||
                    this.state.data.action === "Waiting Approval") &&
                  this.state.after[key] !== this.state.before[key]
                    ? "red"
                    : "",
                wordWrap: "break-word",
              }}
            >
              {data[key]}
            </label>
          );
        }
        return (
          <div className="col-md-8">
            <TableOwner
              value={data[key]}
              name="owners"
              onRef={(ref) => (this.owners = ref)}
              handleTableOwnerChange={this.handleTableOwnerChange}
              readOnly={true}
              displayTableOnly={true}
              color={
                isAfter &&
                (this.state.data.action === "Update" ||
                  this.state.data.action === "Approve" ||
                  this.state.data.action === "Waiting Approval") &&
                this.state.after[key] !== this.state.before[key]
                  ? "red"
                  : ""
              }
            />
          </div>
        );
      }
      case "Mail Template": {
        if (key === "Message") {
          return (
            <div
              style={{
                textAlign: "left",
                color:
                  isAfter &&
                  (this.state.data.action === "Update" ||
                    this.state.data.action === "Approve" ||
                    this.state.data.action === "Waiting Approval") &&
                  this.state.after[key] !== this.state.before[key]
                    ? "red"
                    : "",
              }}
              className="col-md-8"
              dangerouslySetInnerHTML={{ __html: data[key] }}
            />
          );
        }
        return this.renderDefault(data, key, isAfter);
      }
      case "Payment Template":
      case "Tax & Fee Template":
      case "Base Price Template":
      case "Cash Contract Price Template":
      case "Bonus & Deduct Template":
      case "Tax & Fee":
      case "Base Price":
      case "Test":
      case "Grower Statement": {
        if (
          key === "Data" ||
          key === "customFields" ||
          key === `Payments` ||
          key === `Meta Attributes` ||
          key === `Statement`
        ) {
          return (
            <div
              style={{
                textAlign: "left",
                color:
                  isAfter &&
                  (this.state.data.action === "Update" ||
                    this.state.data.action === "Approve" ||
                    this.state.data.action === "Waiting Approval") &&
                  this.state.after[key] !== this.state.before[key]
                    ? "red"
                    : "",
              }}
              className="col-md-8"
            >
              {data[key] && data[key].trim() !== `` ? (
                <ReactJson
                  style={{ wordBreak: "break-all" }}
                  src={JSON.parse(data[key])}
                  theme="apathy"
                  name={false}
                  enableClipboard={true}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  collapsed={true}
                />
              ) : (
                ""
              )}
            </div>
          );
        }
        return this.renderDefault(data, key, isAfter);
      }
      default:
        return this.renderDefault(data, key, isAfter);
    }
  };

  renderBody(data, isAfter) {
    return (
      <div className="panel-body">
        <fieldset>
          {Object.keys(data).map((key, i) => (
            <div className="form-group" key={i}>
              <label className="col-md-4 control-label">
                <strong>{key}</strong>
              </label>
              {this.renderField(data, key, isAfter)}
            </div>
          ))}
        </fieldset>
      </div>
    );
  }

  renderSwitch() {
    switch (this.state.data.action) {
      case "Update":
      case "Approve":
      case "Waiting Approval":
        return (
          <>
            <section
              className="panel-default col-md-6"
              style={{
                borderStyle: "solid",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderWidth: "thin",
                paddingLeft: "0px",
                paddingRight: "0px",
                marginLeft: "0px",
              }}
            >
              <header className="panel-heading" style={{ textAlign: "center" }}>
                <h2 className="panel-title">Previous</h2>
              </header>
              {this.renderBody(this.state.before, false)}
            </section>

            <section
              className="panel-info col-md-6"
              style={{
                borderStyle: "solid",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderWidth: "thin",
                paddingLeft: "0px",
                paddingRight: "0px",
                marginLeft: "0px",
              }}
            >
              <header className="panel-heading" style={{ textAlign: "center" }}>
                <h2 className="panel-title">New</h2>
              </header>
              {this.renderBody(this.state.after, true)}
            </section>
          </>
        );
      case "Delete":
      case "Create":
      case "Copy":
      case "Import":
      case "Import (Error)":
      case "Invite":
      case "Remove Invite":
      case "Signup":
        return (
          <section
            className={
              this.state.data.action === "Delete" ||
              this.state.data.action === "Remove Invite"
                ? "panel-default col-md-6 col-md-offset-3"
                : "panel-info col-md-6 col-md-offset-3"
            }
            style={{
              borderStyle: "solid",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderWidth: "thin",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <header className="panel-heading" style={{ textAlign: "center" }}>
              <h2 className="panel-title">
                {this.state.data.action === "Delete" ||
                this.state.data.action === "Remove Invite"
                  ? this.state.data.type +
                    (this.state.data.action === "Delete"
                      ? " Removed"
                      : " Removed Invite")
                  : this.state.data.action !== "Invite"
                  ? "New " + this.state.data.type
                  : this.state.data.type + " Invited"}
              </h2>
            </header>
            {this.renderBody(this.state.before, false)}
          </section>
        );
      case "Print":
        return (
          <section
            className="panel-info col-md-6 col-md-offset-3"
            style={{
              borderStyle: "solid",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              borderWidth: "thin",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <header className="panel-heading" style={{ textAlign: "center" }}>
              <h2 className="panel-title">{"Print Details"}</h2>
            </header>
            <div className="panel-body">
              <fieldset>
                {Object.keys(this.state.before).map((key, i) => (
                  <div className="form-group" key={i}>
                    <label className="col-md-4 control-label">
                      <strong>{key}:</strong>
                    </label>
                    <label
                      className="col-md-8 control-label"
                      style={{ textAlign: "left", wordWrap: "break-word" }}
                    >
                      {this.state.before[key] !== undefined &&
                      this.state.before[key] !== undefined &&
                      this.state.before[key].indexOf("http") !== -1 ? (
                        <a href={this.state.before[key]} target="_blank">
                          Open
                        </a>
                      ) : (
                        this.state.before[key]
                      )}
                    </label>
                  </div>
                ))}
              </fieldset>
            </div>
          </section>
        );
      default:
        return "";
    }
  }

  renderForm = () => {
    return this.state.data ? (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-md-2 control-label">
            <strong>Action:</strong>
          </label>
          <div className="col-md-4">
            <label className="control-label">{this.state.data.action}</label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            <strong>Action by:</strong>
          </label>
          <div className="col-md-4">
            <label className="control-label">
              {this.state.data.createdUser}
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            <strong>Action on:</strong>
          </label>
          <div className="col-md-4">
            <label className="control-label">
              {DateFormat(
                this.state.data.createdDate,
                "hh:MM:ss TT, mm/dd/yyyy"
              )}
            </label>
          </div>
        </div>
        <hr></hr>
        <div className="form-group">{this.renderSwitch()}</div>
      </div>
    ) : (
      ""
    );
  };
}
export default App;
