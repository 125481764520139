import React, { Component } from "react";
import MultiSelect from "./../FormControl/MultiSelect";
import SaveButton from "../Controls/SaveButton";
import { Button, Modal } from "react-bootstrap";
import { METHOD_POST } from "../Utilities/Constant";
import { confirmAlert } from "react-confirm-alert";

export default class CopyAdjustmentsPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      showCopyAdjustments: false,
      copiedAdjustmentsCropYears: [],
      sourceCropYearId: "",
      destinationCropYearId: "",
      copyAdjustmentsLoading: false,
      selectedTestTypeId: "",
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  showCopyAdjustmentsPopup = (sourceCropYearId = "") => {
    this.fetchCropYearsToCopyAdjustments(sourceCropYearId);
  };

  hideCopyAdjustmentsPopup = () => {
    this.setState({
      showCopyAdjustments: false,
    });
    this.reset();
  };

  filterByTestTypeId = (cropYears, testTypeId) => {
    if (!testTypeId) {
      return cropYears.map((cropYear) => {
        return this.convertToSelectItem(cropYear);
      });
    } else {
      return cropYears
        .filter((cropYear) => cropYear.nutTypeId === testTypeId)
        .map((cropYear) => {
          return this.convertToSelectItem(cropYear);
        });
    }
  };

  convertToSelectItem = (cropYear) => {
    return {
      label: cropYear.name,
      value: cropYear._id,
    };
  };

  fetchCropYearsToCopyAdjustments = (sourceCropYearId) => {
    if (this.state.copiedAdjustmentsCropYears.length === 0) {
      const url =
        this.props.GlobalConfig.REACT_APP_API_CROPYEAR_URL +
        `simplebyprocessor/${this.props.loggedUser.processorId}`;
      fetch(url)
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.result === "OK") {
            this.setState({
              copiedAdjustmentsCropYears: responseData.data,
              selectedTestTypeId: this.getTestTypeIdByCropYearId(
                responseData.data,
                sourceCropYearId
              ),
            });
          } else {
            window.alert("Error", responseData.message, "error");
          }
        });
    } else {
      const testTypeId = this.getTestTypeIdByCropYearId(
        this.state.copiedAdjustmentsCropYears,
        sourceCropYearId
      );
      this.setState({
        selectedTestTypeId: testTypeId,
      });
    }
    this.setState({
      showCopyAdjustments: true,
      sourceCropYearId: sourceCropYearId,
    });
  };

  getTestTypeIdByCropYearId = (cropYears, cropYearId) => {
    const cropYear = cropYears.find((cropYear) => cropYear._id === cropYearId);
    return cropYear ? cropYear.nutTypeId : "";
  };

  onChangeSourceCropYear = (sourceCropYear) => {
    if (!sourceCropYear) {
      this.reset();
    } else {
      const testTypeId = this.state.copiedAdjustmentsCropYears.find(
        (cropYear) => cropYear._id === sourceCropYear.value
      )?.nutTypeId;
      this.setState({
        sourceCropYearId: sourceCropYear.value,
        selectedTestTypeId: testTypeId,
      });
    }
  };

  onChangeDestinationCropYear = (destinationCropyear) => {
    if (!destinationCropyear) {
      this.reset();
    } else {
      const testTypeId = this.state.copiedAdjustmentsCropYears.find(
        (cropYear) => cropYear._id === destinationCropyear.value
      )?.nutTypeId;
      this.setState({
        destinationCropYearId: destinationCropyear.value,
        selectedTestTypeId: testTypeId,
      });
    }
  };

  onClickCopy = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-popup-custom">
            <div className="confirm-popup-custom-title">Confirm</div>
            <p className="confirm-popup-custom-message">
              Adjustments of destination to be reset and new adjustments from
              source to be override. Please confirm to proceed.
            </p>
            <div className="confirm-popup-custom-footer">
              <Button
                variant="primary"
                onClick={() => {
                  onClose();
                  this.copyAdjustments();
                }}
              >
                <i className="fa fa-check" aria-hidden="true"></i> Yes
              </Button>
              <Button variant="warning" button-type="cancel" onClick={onClose}>
                <i className="fa fa-times" aria-hidden="true"></i> Cancel
              </Button>
            </div>
          </div>
        );
      },
      onClickOutside: () => {
        this.setState({
          showCopyAdjustments: true,
        });
      },
    });
  };

  copyAdjustments = () => {
    const errors = this.validateBeforeCopyAdjustments();
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        window.showAlert("Warning", error, "error");
      });
      return;
    }
    const url =
      this.props.GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL +
      `copyyear/${this.state.sourceCropYearId}/${this.state.destinationCropYearId}`;
    const requestOptions = {
      method: METHOD_POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        createdUserId: this.props.loggedUser._id,
        processorId: this.props.loggedUser.processorId,
      }),
    };
    this.setState(
      {
        copyAdjustmentsLoading: true,
      },
      () => {
        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.result === "OK") {
              window.showAlert("", responseData.message, "");
              this.setState({
                copyAdjustmentsLoading: false,
              });
              this.reset();
            } else {
              window.showAlert("Error", responseData.message, "error");
              this.setState({
                copyAdjustmentsLoading: false,
              });
            }
          });
      }
    );
  };

  validateBeforeCopyAdjustments = () => {
    const errors = [];
    if (!this.state.sourceCropYearId || !this.state.sourceCropYearId.trim()) {
      errors.push("Please select a source crop year.");
    }
    if (
      !this.state.destinationCropYearId ||
      !this.state.destinationCropYearId.trim()
    ) {
      errors.push("Please select a destination crop year.");
    }
    if (this.state.sourceCropYearId === this.state.destinationCropYearId) {
      errors.push("The source and destination crop year must be different.");
    }
    return errors;
  };

  reset = () => {
    this.setState({
      sourceCropYearId: "",
      destinationCropYearId: "",
      selectedTestTypeId: "",
    });
  };

  render() {
    return (
      <Modal
        show={this.state.showCopyAdjustments}
        onHide={this.hideCopyAdjustmentsPopup}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        className="copy-adjustments-popup"
      >
        <Modal.Header>
          <Modal.Title>Copy Adjustments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="col-md-3 control-label">Source</label>
            <div className="col-md-9">
              <MultiSelect
                isMulti={false}
                defaultOptions={this.filterByTestTypeId(
                  this.state.copiedAdjustmentsCropYears,
                  this.state.selectedTestTypeId
                )}
                closeMenuOnSelect={true}
                placeholder={"Select a source crop year"}
                onChange={this.onChangeSourceCropYear}
                value={this.state.sourceCropYearId}
                isClearable
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-3 control-label">Destination</label>
            <div className="col-md-9">
              <MultiSelect
                isMulti={false}
                defaultOptions={this.filterByTestTypeId(
                  this.state.copiedAdjustmentsCropYears,
                  this.state.selectedTestTypeId
                )}
                closeMenuOnSelect={true}
                placeholder={"Select a destination crop year"}
                onChange={this.onChangeDestinationCropYear}
                value={this.state.destinationCropYearId}
                isClearable
              ></MultiSelect>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="copy-adjustments-popup-footer">
          <SaveButton
            loading={this.state.copyAdjustmentsLoading}
            text="Copy"
            textMarginLeft={5}
            disabled={
              !this.state.sourceCropYearId ||
              !this.state.destinationCropYearId ||
              this.state.sourceCropYearId === this.state.destinationCropYearId
            }
            icon={
              <i
                className="fa fa-files-o"
                aria-hidden="true"
                style={{ fontSize: 20 }}
              ></i>
            }
            onClick={this.onClickCopy}
          />
          <Button variant="warning" onClick={this.hideCopyAdjustmentsPopup}>
            <span className="fa fa-times fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
