import React, { useEffect, useState } from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      title: "Handler",
      listtitle: "Handlers",
      urllist: process.env.REACT_APP_URL_HANDLER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_HANDLER_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        createdUserId: loggedUser._id,
        processorId: loggedUser.processorId,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      loading: parentState.loading,
    };
  }

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        createdUserId: loggedUser._id,
        processorId: loggedUser.processorId,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Name <span className="required">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="name"
              autoFocus={true}
              className="form-control"
              placeholder=""
              required
              value={this.state.data.name}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>
        </div>
      </div>
    );
  };
}
export default App;
