import React from "react";
import Switch from "react-switch";
class App extends React.Component {
  renderCheckboxSync() {
    return (
      <div className={`checkbox-sync-container ${this.props.className}`}>
        <div className="switch-control-container">
          <div className="label-switch-control">{this.props.title}</div>
          <div className="btn-switch-control ml-xs">
            <Switch
              id={this.props.nameSync}
              onChange={this.props.onChange}
              checked={this.props.valueSync}
              className="react-switch"
              disabled={this.props.disabled}
            />
          </div>
        </div>
        {this.props.valueSync ? (
          <div className="checkbox-sync">
            <label className="checkbox-sync-label">
              Column <span className="required">(*)</span>
            </label>

            <input
              type="text"
              name={this.props.nameCoreField}
              autoFocus={true}
              className="form-control"
              placeholder="Enter column name"
              required
              value={this.props.valueCoreField}
              onChange={this.props.handleChange}
              maxLength={100}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        {!this.props.isHide && this.props.noContainer && (
          <>{this.renderCheckboxSync()}</>
        )}
        {!this.props.isHide && !this.props.noContainer && (
          <div className="col-md-8 col-lg-6">{this.renderCheckboxSync()}</div>
        )}
      </>
    );
  }
}
export default App;
