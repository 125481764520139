import React from "react";
import Textbox from "../FormControl/Textbox";
import Textarea from "../FormControl/Textarea";
import SelectControl from "../FormControl/SelectControl";
import NumericInput from "../FormControl/NumericInput";

import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_TEXT_BOX,
  FIELD_TYPE_TEXT_SMALL,
  FIELD_TYPE_CALCULATED,
} from "../Utilities/Constant";
import { isNumber } from "lodash";

export const renderFieldByType = (
  field,
  defaultValue,
  loggedUser,
  GlobalConfig,
  handleChange,
  handleChangeSelectControl,
  displayRequired,
  readOnly = false,
) => {
  switch (field.fieldType) {
    case FIELD_TYPE_TEXT_SMALL:
      return (
        <Textbox
          type="text"
          classProps="form-group-spacing"
          label={field.name}
          name={field.testFieldId}
          value={defaultValue}
          handleChange={handleChange}
          maxLength={field.maxLength}
          required={field.isMandatory}
          displayRequired={displayRequired}
          placeholder={field.name}
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_TEXT_BOX:
      return (
        <Textarea
          rows={5}
          type="text"
          classProps="form-group-spacing"
          label={field.name}
          name={field.testFieldId}
          value={defaultValue}
          handleChange={handleChange}
          maxLength={field.maxLength}
          required={field.isMandatory}
          displayRequired={displayRequired}
          placeholder={field.name}
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_DROP_DOWN:
      const url = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${field.testClassificationId}`;
      return (
        <SelectControl
          classProps="form-group-spacing"
          handleChangeSelectControl={handleChangeSelectControl}
          // onRef={(ref) => (this[field._id] = ref)}
          onRef={(ref) => {}}
          placeholder={field.name}
          label={field.name}
          popupTitle={field.name}
          controlId={field.testFieldId}
          value={defaultValue ? defaultValue : field.defaultValue}
          required={field.isMandatory}
          displayRequired={displayRequired}
          maxLength={200}
          processorId={loggedUser.processorId}
          urlapi={url}
          urlapilist={url}
          isHideAddButton={true}
          textField="name"
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_DECIMAL:
      return (
        <NumericInput
          classProps="form-group-spacing"
          min={field.min}
          max={field.max}
          precision={field.precision}
          step={field.step}
          label={field.name}
          name={field.testFieldId}
          value={defaultValue}
          handleChange={handleChange}
          required={field.isMandatory}
          displayRequired={displayRequired}
          labelrequired={field.isMandatory ? "(*)" : undefined}
          unit={field.unit}
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_PERCENT:
      return (
        <NumericInput
          classProps="form-group-spacing"
          label={field.name}
          min={field.min}
          max={field.max}
          precision={field.precision}
          step={field.step}
          name={field.testFieldId}
          value={defaultValue}
          handleChange={handleChange}
          required={field.isMandatory}
          displayRequired={displayRequired}
          labelrequired={field.isMandatory ? "(*)" : undefined}
          unit={field.unit ? field.unit : "%"}
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_INTEGER:
      return (
        <NumericInput
          classProps="form-group-spacing"
          min={field.min}
          max={field.max}
          precision={0}
          step={field.step}
          label={field.name}
          name={field.testFieldId}
          value={defaultValue}
          handleChange={handleChange}
          required={field.isMandatory}
          displayRequired={displayRequired}
          labelrequired={field.isMandatory ? "(*)" : undefined}
          unit={field.unit}
          readOnly={readOnly}
        />
      );
    case FIELD_TYPE_CALCULATED:
      return (
        <Textbox
          type="text"
          classProps="form-group-spacing"
          popupTitle="Test"
          label={field.name}
          name={field.testFieldId}
          value={defaultValue ? (isNumber(defaultValue) ? defaultValue : 0) : 0}
          equation={field.defaultValue}
          detailUrl={`${process.env.REACT_APP_URL_TEST_FIELD_LIST}/${field.testFieldId}`}
          handleChange={handleChange}
          maxLength={field.maxLength}
          required={field.isMandatory}
          displayRequired={displayRequired}
          placeholder={field.defaultValue}
          readOnly={true}
        />
      );
    default:
      return <></>;
  }
};

export default renderFieldByType;
