import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

var DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.children,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        cell: (row) => {
          return this.props.readOnly === true ||
            this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            row.varietyName
          ) : (
            <a
              onClick={() => this.props.handleShow(row)}
              href="#"
              title="Edit cash contract price"
            >
              {row.varietyName}
            </a>
          );
        },
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.amount == null || row.amount === undefined || isNaN(row.amount)
              ? 0
              : parseFloat(row.amount)
            ).toFixed(2)
          ) + ` lbs`,
      },
      {
        name: "Cash Price",
        selector: "valueCashPrice",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueCashPrice == null ||
              row.valueCashPrice === undefined ||
              isNaN(row.valueCashPrice)
              ? 0
              : parseFloat(row.valueCashPrice)
            ).toFixed(2)
          ),
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: true,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate
      //       ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
      //       : "";
      //   },
      // },
      // {
      //   name: "Created Date",
      //   selector: "createdDate",
      //   sortable: true,
      //   center: true,
      //   cell: (row) => {
      //     return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
      //   },
      // },
      {
        omit: this.props.readOnly,
        name: "",
        cell: (row) => (
          <div>
            {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
              ""
            ) : (
              <>
                <a
                  title="Edit cash contract price"
                  href="#"
                  onClick={() => this.props.handleShow(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Remove cash contract price"
                  href="#"
                  className="on-default remove-row"
                  data-id={row._id}
                  data-growerid={row.growerId}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
              </>
            )}

            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  render() {
    return (
      <>
        <DataTable
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={
            this.state.isContractCashPrice === false
              ? this.state.items.filter(
                (item) => item.primaryPrice !== "Contract Cash Price"
              )
              : this.state.items
          }
          className="table table-bordered table-striped mb-none"
          pagination={false}
          noDataComponent=""
          noTableHead={false}
        />
      </>
    );
  }
}
export default App;
