import React from "react";
import DataTable from "react-data-table-component";
import { Button, Form, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import SaveButton from "../Controls/SaveButton";
import {
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
} from "../Utilities/Constant";
import CommonUtilities from "../Utilities/Common";
import { confirmAlert } from "react-confirm-alert";
import SelectMultiGrowers from "../Grower/SelectMultiGrowers";
import SelectConfig from "../Base/SelectConfig";
import NodeUuid from "node-uuid";
import SubTableTemplate from "./SubTableTemplate";
import TableAttribute from "../BasePrice/TableAttribute";
import Popover from "../Base/Popover";
import MultiSelectVarieties from "../TestType/MultiSelectVarieties";
import SelectControl from "../FormControl/SelectControl";
const { sortBy } = require("lodash");
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: [],
      isShow: false,
      readOnly: false,
      showSubModal: false,
      isAdd: true,
      saveLoading: false,
      data: {
        _id: "",
        parentId: props.cropYearId,
        name: "",
        data: "",
      },
      children: [],
      //State using for sub popup
      dataPopup: {
        _id: "",
        name: "",
        type: props.testType.isDynamic ? "Highest of Both" : "",
        applyType: ``,
        value: 0,
        customAttributes: "",
        varietyId: "",
        paymentId: "",
        varietyName: [],
      },
      previousApplyType: "",
      previousType: "",
      selectedGrowers: [],
      isExpand: true,
      growers: [],
      messageApply: `Override base price under template with selected growers. This means if there is any existing base price under Base Prices they will be reset. Ensure template base price saved before processing.`,
      errorMessage: "",
      subErrorMessage: "",
      errorEmptyAttribute:
        "Please select at least one base attribue to continue.",
    };
    this.handleChange = this.handleChange.bind(this);
    this.hideSubPopup = this.hideSubPopup.bind(this);
    this.handleAddSubPopup = this.handleAddSubPopup.bind(this);
    this.handleEditSubPopup = this.handleEditSubPopup.bind(this);
    this.onChangeSelectedGrowers = this.onChangeSelectedGrowers.bind(this);
    this.openAddSubPopup = this.openAddSubPopup.bind(this);
    this.openEditSubPopup = this.openEditSubPopup.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleTableAttributeChange =
      this.handleTableAttributeChange.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  async getItems() {
    if (this.props.testType._id !== ``) {
      const response = await fetch(
        `${GlobalConfig.REACT_APP_API_BASEPRICE_TEMPLATE_URL}parent/${this.props.cropYearId}/${this.props.testType._id}`
      );
      const resultObject = await response.json();
      if (resultObject.result === "OK") {
        this.setState(
          {
            items: resultObject.data,
            isExpand: false,
          },
          () => {
            this.setState({ isExpand: true });
          }
        );
      } else {
        window.showAlert("Error", resultObject.message, "error");
      }
    }
  }

  labelValueInshell = () => {
    if (this.props.testType.isDynamic) {
      if (this.props.testType.xpool === true) {
        return "Regular Price";
      }
      return "Price";
    }
    return "Inshell";
  };

  labelValueShell = () => {
    if (this.props.testType.isDynamic) {
      if (this.props.testType.xpool === true) {
        return "Alternate Price";
      }
    }
    return `Shelled`;
  };

  newDataObject = () => {
    return {
      _id: "",
      parentId: this.props.cropYearId,
      name: "",
      data: "",
    };
  };

  handleClosePopup = () => {
    this.setState({
      isShow: false,
      errorMessage: "",
    });
  };

  handleShowPopup = (data, readOnly) => {
    let children = [];
    if (data.data && data.data !== ``) {
      const childrenOrigin = JSON.parse(data.data);
      if (childrenOrigin && childrenOrigin.length > 0) {
        if (this.props.testType.paymentByVariety === true) {
          childrenOrigin.map((x) => {
            x = JSON.parse(JSON.stringify(x));
            children.push(x);
          });
          if (children.length > 0) {
            children = sortBy(children, ["name"], ["asc"]);
          }
        } else {
          children = childrenOrigin;
          if (children.length > 0) {
            children = sortBy(children, [`name`], ["asc"]);
          }
        }
      }
    }

    this.setState(
      {
        readOnly: readOnly,
        isShow: true,
        errorMessage: "",
        data: {
          ...this.state.data,
          _id: data._id,
          name: data.name,
          parentId: data.parentId,
          data: data.data,
          growerIds: data.growerIds,
        },
        children: children,
        selectedGrowers: [],
      },
      () => {
        this.growerIds.setSelected(data.growerIds);
      }
    );
  };

  handleSave = async (event) => {
    event.preventDefault();
    let { _id, name, parentId, growerIds } = this.state.data;
    const isCreate = !_id;

    let errorMessage = "";
    //Reset message
    this.setState({
      errorMessage: "",
    });

    if (errorMessage === ``) {
      if (name === undefined || name === null || name.trim() === "") {
        errorMessage = "Please enter name to continue";
      }
    }

    let children = "";
    if (errorMessage === ``) {
      if (this.state.children && this.state.children.length > 0) {
        children = JSON.stringify(this.state.children);
      }
      if (
        children === undefined ||
        children === null ||
        children.trim() === ""
      ) {
        errorMessage = "Please enter at least one base price to continue";
      }
    }

    if (errorMessage !== "") {
      this.setState({ errorMessage: errorMessage });
      return;
    }

    const apiURL = isCreate
      ? `${GlobalConfig.REACT_APP_API_BASEPRICE_TEMPLATE_URL}`
      : `${GlobalConfig.REACT_APP_API_BASEPRICE_TEMPLATE_URL}/${_id}`;
    const method = isCreate ? "POST" : "PATCH";

    const requestBody = isCreate
      ? {
        _id,
        name,
        parentId,
        data: children,
        growerIds,
        createdUserId: this.props.loggedUser._id,
      }
      : {
        _id,
        name,
        parentId,
        data: children,
        growerIds,
        modifiedUserId: this.props.loggedUser._id,
      };
    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(apiURL, requestOptions);
    const resultObject = await response.json();

    //If created successful
    if (resultObject.result === "OK") {
      window.showAlert("", "Save successful!", "");
      this.getItems();
      this.setState({
        isChanged: false,
        data: Object.assign({}, this.state.data, {
          _id: resultObject.data._id,
        }),
      });
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleButtonApply = async (event) => {
    event.preventDefault();
    this.handleApply(this.state.data._id);
  };

  handleApply = async (templateId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: templateId,
        createdUserId: this.props.loggedUser._id,
      }),
    };

    const response = await fetch(
      `${GlobalConfig.REACT_APP_API_BASEPRICE_TEMPLATE_URL}apply/${templateId}`,
      requestOptions
    );
    const resultObject = await response.json();
    if (resultObject.result === "OK") {
      window.showAlert("", resultObject.message, "");
      this.props.refreshMainTab();
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  handleDelete = (e) => {
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected template?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            try {
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  userId: this.props.loggedUser._id,
                  modifiedUserId: this.props.loggedUser._id,
                }),
              };
              const response = await fetch(
                GlobalConfig.REACT_APP_API_BASEPRICE_TEMPLATE_URL + removedId,
                requestOptions
              );
              const resultObject = await response.json();
              if (resultObject && resultObject.result === "OK") {
                this.handleDeleteAfterDeletedOnServer(removedId);
              } else {
                //If failed, show error
                window.showAlert("Error", resultObject.message, "error");
              }
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    //If deleted successful
    const newItems = this.state.items.filter((item) => {
      return item._id !== removedId;
    });
    this.setState({
      items: newItems,
    });
    window.showAlert("Success", "Removed successful!", "");
  };

  setColumns = () => {
    return [
      {
        name: "Template Name",
        selector: "name",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <a
              href="#"
              title="Click to edit"
              onClick={() => this.handleShowPopup(row, false)}
            >
              {row.name}
            </a>
          );
        },
      },
      {
        name: "",
        width: "130px",
        cell: (row) => (
          <>
            {this.props.loggedUser.role === USER_ROLE_PROCESSOR ||
              this.props.loggedUser.role === USER_ROLE_STAFF ? (
              <>
                <a
                  href="#"
                  onClick={() => this.handleShowPopup(row, false)}
                  className="on-default edit-row"
                  title="Click to edit"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Click here to remove"
                  href="# "
                  className="on-default remove-row"
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  href="#"
                  onClick={() => this.handleApply(row._id)}
                  className="on-default edit-row"
                  title={this.state.messageApply}
                >
                  <i className="fa fa-check fa-lg"></i>
                </a>
              </>
            ) : (
              <a
                href="#"
                onClick={() => this.handleShowPopup(row, true)}
                className="on-default edit-row"
              >
                <i className="fa fa-eye fa-lg"></i>
              </a>
            )}
            {this.props.loggedUser.role === USER_ROLE_GROWER ? (
              ""
            ) : (
              <>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                  className="on-default remove-row"
                >
                  <i className="fa fa-history fa-lg"></i>
                </a>
              </>
            )}
          </>
        ),
        minWidth: "180px",
      },
    ];
  };

  hideSubPopup() {
    this.setState({
      showSubModal: false,
    });
  }

  openAddSubPopup() {
    this.resetDataPopup();
    this.setState(
      {
        isAdd: true,
      },
      () => {
        this.showSubPopup();
      }
    );
  }

  openEditSubPopup(editingData, index) {
    this.setState(
      {
        isAdd: false,
        dataPopup: editingData,
        editingIndex: index,
      },
      () => {
        this.showSubPopup();
      }
    );
  }

  showSubPopup() {
    this.setState(
      {
        showSubModal: true,
      },
      () => {
        //Set selected dropdown
        if (this.varietyId) {
          this.varietyId.setSelected(this.state.dataPopup.varietyId);
        }
        if (this.primaryPrice) {
          this.primaryPrice.setSelected(this.state.dataPopup.primaryPrice);
        }
        if (this.props.testType.basePriceByPayment) {
          this.paymentId.setSelected(this.state.dataPopup.paymentId);
        }
      }
    );
  }

  resetDataPopup() {
    this.setState({
      dataPopup: {
        _id: "",
        varietyId: "",
        paymentId: "",
        valueInshell: 0,
        valueShelled: 0,
        primaryPrice: this.props.testType.isDynamic ? "Highest of Both" : "",
        customAttributes: "",
      },
    });
  }

  handleChange = (event) => {
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  };

  handleChangeSelectControl = (controlId, value, selectedName) => {
    if (controlId === `varietyId`) {
      this.setState({
        dataPopup: Object.assign({}, this.state.dataPopup, {
          [controlId]: value,
          varietyName: selectedName,
        }),
      });
    } else {
      this.setState({
        dataPopup: Object.assign({}, this.state.dataPopup, {
          [controlId]: value,
        }),
      });
    }
  };

  handleChangeObjectData = (event) => {
    if (event.target) {
      const data = event.target.value;
      const id = event.target.name;
      this.setState({
        dataPopup: Object.assign({}, this.state.dataPopup, {
          [id]: data,
        }),
      });
    }
  };

  handleTableAttributeChange = (name, value) => {
    if (
      value !== undefined &&
      value !== null &&
      value.length > 0 &&
      this.state.subErrorMessage === this.state.errorEmptyAttribute
    ) {
      this.setState({
        subErrorMessage: "",
      });
    }
    this.setState({
      dataPopup: Object.assign({}, this.state.dataPopup, {
        [name]: value,
      }),
    });
  };

  validateSubModal = () => {
    this.setState({
      subErrorMessage: ``,
    });

    const varietyId = this.state.dataPopup.varietyId;
    let errorMessage = "";
    if (
      this.props.testType.varietyOptional !== true &&
      (varietyId == null || varietyId === undefined || varietyId.trim() === "")
    ) {
      errorMessage = "Select variety to continue.";
    }
    if (errorMessage === "") {
      if (this.props.testType.isDynamic !== true) {
        const primaryPrice = this.state.dataPopup.primaryPrice;
        if (
          primaryPrice == null ||
          primaryPrice === undefined ||
          primaryPrice.trim() === ""
        ) {
          errorMessage = "Select primary price to continue.";
        } else {
          if (this.state.dataPopup.primaryPrice === "Inshell") {
            if (this.state.dataPopup.valueInshell <= 0) {
              errorMessage = "Inshell must be greater than zero.";
            }
          } else if (this.state.dataPopup.primaryPrice === "Shelled") {
            if (this.state.dataPopup.valueShelled <= 0) {
              errorMessage = "Shelled must be greater than zero.";
            }
          } else if (this.state.dataPopup.primaryPrice === "Highest of Both") {
            if (
              this.state.dataPopup.valueShelled <= 0 ||
              this.state.dataPopup.valueInshell <= 0
            ) {
              errorMessage = "Inshell and Shelled must be greater than zero.";
            }
          }
        }
      } else {
        if (this.props.testType.xpool === true) {
          if (this.state.dataPopup.valueInshell <= 0) {
            errorMessage = "Regular Price must be greater than zero.";
          } else if (this.state.dataPopup.valueShelled <= 0) {
            errorMessage = "Alternate Price must be greater than zero.";
          }
        } else {
          if (this.state.dataPopup.valueInshell <= 0) {
            errorMessage = "Price must be greater than zero.";
          }
        }
      }
    }
    if (errorMessage !== "") {
      this.setState({
        subErrorMessage: errorMessage,
      });
      return false;
    }
    return true;
  };

  validateDuplicateSubModal = (excludeId) => {
    let newChildren = [...this.state.children];
    let duplicatedItems = newChildren;
    let newSubData = this.state.dataPopup;
    const paymentId = newSubData.paymentId;
    const varietyId = newSubData.varietyId;
    const newCustomAttributes =
      newSubData.customAttributes === undefined ||
        newSubData.customAttributes === null ||
        newSubData.customAttributes === ``
        ? []
        : JSON.parse(newSubData.customAttributes);

    //Check duplicate
    if (duplicatedItems && duplicatedItems.length > 0) {
      if (excludeId !== ``) {
        duplicatedItems = duplicatedItems.filter((c) => c._id !== excludeId);
      }
      if (this.props.testType.varietyOptional !== true) {
        duplicatedItems = duplicatedItems.filter(
          (c) => c.varietyId === varietyId
        );
      }
      if (
        paymentId &&
        paymentId !== `` &&
        this.props.testType.basePriceByPayment === true
      ) {
        duplicatedItems = duplicatedItems.filter(
          (c) => c.paymentId === paymentId
        );
      }

      const errorMessage = CommonUtilities.checkDuplicateMetaAttribute(
        this.props.testType,
        newCustomAttributes,
        duplicatedItems
      );

      if (errorMessage !== ``) {
        window.showAlert("ERROR", errorMessage, "error");
        return false;
      }
    }
    return true;
  };

  handleAddSubPopup() {
    if (this.validateSubModal() === false) {
      return;
    }

    if (this.validateDuplicateSubModal(``) === false) {
      return;
    }

    let newChildren = [...this.state.children];
    const varietyId = this.state.dataPopup.varietyId;
    if (varietyId && varietyId !== ``) {
      const varietyNames = this.state.dataPopup.varietyName;
      const arrVarietyId = varietyId.split(`,`);
      if (
        arrVarietyId &&
        arrVarietyId.length > 0 &&
        varietyNames &&
        varietyNames.length === arrVarietyId.length
      ) {
        let index = 0;
        arrVarietyId.map((varietyId) => {
          let newSubData = this.state.dataPopup;
          newSubData = JSON.parse(JSON.stringify(newSubData));
          newSubData._id = NodeUuid.v4();
          newSubData.varietyId = varietyId;
          newSubData.varietyName = varietyNames[index++];
          newChildren.push(newSubData);
        });
      }
    } else {
      let newSubData = this.state.dataPopup;
      newSubData = JSON.parse(JSON.stringify(newSubData));
      newSubData._id = NodeUuid.v4();
      newChildren.push(newSubData);
    }
    this.setState(
      {
        children: newChildren,
      },
      () => {
        this.resetDataPopup();
        this.hideSubPopup();
      }
    );
  }

  handleEditSubPopup() {
    if (this.validateSubModal() === false) {
      return;
    }

    if (this.validateDuplicateSubModal(this.state.dataPopup._id) === false) {
      return;
    }

    let newChildren = [...this.state.children];
    if (
      this.state.editingIndex !== null &&
      this.state.editingIndex !== undefined
    ) {
      newChildren[this.state.editingIndex] = this.state.dataPopup;
      this.setState(
        {
          children: newChildren,
        },
        () => this.hideSubPopup()
      );
    }
  }

  handleRemoveSubPopup(index) {
    let newChildren = [...this.state.children].filter((_, i) => i !== index);
    this.setState({
      children: newChildren,
    });
  }

  onChangeSelectedGrowers(growers) {
    let growerIds = ``;
    if (growers && growers.length > 0) {
      growerIds = growers.map((x) => x.value).join(`,`);
    }
    this.setState({
      selectedGrowers: growers,
      data: Object.assign({}, this.state.data, {
        growerIds: growerIds,
      }),
    });
  }

  async getGrowers() {
    const result = await fetch(
      `${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersByProcessorAndTestType/${this.props.loggedUser.referenceUserId}/${this.props.testTypeId}`
    );
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      const growers = response.data.map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
        };
      });
      this.setState({
        growers: growers,
      });
    }
  }

  subColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.props.testType.varietyOptional,
      },
      {
        name: "Primary Price",
        selector: "primaryPrice",
        sortable: true,
        center: true,
        omit: this.props.testType.isDynamic,
      },
      {
        name: this.labelValueInshell(),
        selector: "valueInshell",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            row.valueInshell == null ||
              row.valueInshell === undefined ||
              isNaN(row.valueInshell)
              ? 0
              : parseFloat(row.valueInshell)
          ),
        width: "160px",
      },
      {
        name: this.labelValueShell(),
        selector: "valueShelled",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            row.valueShelled == null ||
              row.valueShelled === undefined ||
              isNaN(row.valueShelled)
              ? 0
              : parseFloat(row.valueShelled)
          ),
        omit:
          this.props.testType.isDynamic && this.props.testType.xpool !== true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.props.testType.isDynamic,
      },
      {
        name: "Payment",
        selector: "paymentName",
        sortable: false,
        omit: !this.props.testType.basePriceByPayment,
      },
      {
        name: "",
        right: true,
        cell: (row, index) => (
          <div className="test-type-action-table" style={{ color: "blue" }}>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.openEditSubPopup(row, index)}
            >
              <span>
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </span>
            </div>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.handleRemoveSubPopup(index)}
            >
              <span>
                <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        ),
        width: "70px",
      },
    ];
  };

  render = () => {
    return (
      <fieldset>
        <div className="form-group">
          <a
            href="#"
            className="btn btn-primary panel-btn-group-item-position"
            onClick={() => this.handleShowPopup(this.newDataObject(), false)}
            style={{ marginBottom: "5px" }}
          >
            <i className="fa fa-plus fa-lg"></i> Template
          </a>
          <Modal
            show={this.state.isShow}
            onHide={this.handleClosePopup}
            animation={false}
            backdropClassName="modal-backdrop foo-modal-backdrop in"
            className="bulk-apply-payments-modal"
            centered
            // scrollable
            size="lg"
          >
            <Form onSubmit={this.handleSave} id="fromPopupTemplate">
              <Modal.Header>
                <Modal.Title>Template</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bulk-apply-body">
                <div className="form-group" style={{ marginRight: "-15px" }}>
                  <label className="col-md-3 control-label" htmlFor="name">
                    Template Name <span className="required">(*)</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      name="name"
                      className="form-control"
                      value={this.state.data.name}
                      onChange={this.handleChange}
                      maxLength={100}
                      required={true}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ marginTop: "-10px", marginRight: "-15px" }}
                >
                  <label
                    className="col-md-3 control-label"
                    htmlFor="selectedGrowers"
                  >
                    Apply Growers
                  </label>
                  <div className="col-md-9">
                    <SelectMultiGrowers
                      externalSource={true}
                      source={this.state.growers}
                      selectedGrowers={this.state.selectedGrowers}
                      onChangeSelectedGrowers={this.onChangeSelectedGrowers}
                      placeholder="Select growers"
                      withoutLabel={false}
                      onRef={(ref) => (this.growerIds = ref)}
                    ></SelectMultiGrowers>
                  </div>
                </div>
                {this.state.children.length > 0 && (
                  <DataTable
                    key="subTable"
                    id="subTable"
                    style={{ marginTop: "-15px", marginRight: "-15px" }}
                    columns={this.subColumns()}
                    noHeader={true}
                    data={this.state.children}
                  ></DataTable>
                )}
                <div className="col-md-9 col-md-offset-3">
                  {!!this.state.errorMessage && (
                    <>
                      <Form.Label
                        column
                        md="12"
                        style={{ color: "red", paddingLeft: "0px" }}
                      >
                        {this.state.errorMessage}
                      </Form.Label>
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="bulk-apply-payments-modal-footer">
                <Button variant="primary" onClick={this.openAddSubPopup}>
                  <i className="fa fa-plus fa-lg"></i> Add Base Price
                </Button>
                <SaveButton
                  text={"Save"}
                  type={"submit"}
                  icon={
                    <span
                      className="fa fa-floppy-o fa-lg"
                      style={{ marginRight: 3 }}
                    ></span>
                  }
                  disabled={this.state.saveLoading === false ? false : true}
                  loading={this.state.saveLoading}
                  onClick={this.handleSave}
                />
                {this.state.data._id && this.state.data._id !== `` ? (
                  <>
                    <button
                      type="button"
                      onClick={this.handleButtonApply}
                      className="btn btn-success"
                      disabled={this.state.saveLoading === false ? false : true}
                    >
                      <div className="popover-wrapper">
                        <span className="fa fa-check fa-lg"></span> Apply&nbsp;
                        <Popover
                          trigger={["hover", "focus"]}
                          className="popover-custom"
                          placement="top"
                          content={this.state.messageApply}
                          isUseDefaultOverlay
                        >
                          <i
                            className="fa fa-info-circle panel-heading-info-icon fa-lg"
                            aria-hidden="true"
                          />
                        </Popover>
                      </div>
                    </button>
                  </>
                ) : (
                  ""
                )}
                <Button
                  style={{ margin: "0 5px" }}
                  variant="warning"
                  onClick={this.handleClosePopup}
                >
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Form
            onSubmit={
              this.state.isAdd
                ? this.handleAddSubPopup
                : this.handleEditSubPopup
            }
            id="fromSubPopup"
          >
            <Modal
              show={this.state.showSubModal}
              onHide={this.hideSubPopup}
              animation={false}
              backdropClassName="modal-backdrop foo-modal-backdrop in"
              centered
            >
              <Modal.Header>
                <Modal.Title>Base Price</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.props.testType.varietyOptional !== true ? (
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Variety <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <MultiSelectVarieties
                        isMulti={
                          this.state.dataPopup._id == null ||
                          this.state.dataPopup._id === undefined ||
                          this.state.dataPopup._id === ""
                        }
                        showSelectAll
                        required={true}
                        onRef={(ref) => (this.varietyId = ref)}
                        name="varietyId"
                        controlId="varietyId"
                        selectedIds={this.state.dataPopup.varietyId}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        urlApi={
                          GlobalConfig.REACT_APP_API_VARIETY_URL +
                          "byParentId/" +
                          this.props.testType._id
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.props.testType.basePriceByPayment ? (
                  <div className="form-group">
                    <SelectControl
                      labelClass="3"
                      inputClass="9"
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.paymentId = ref)}
                      placeholder="Payment"
                      label="Payment"
                      controlId="paymentId"
                      value={this.state.dataPopup.paymentId}
                      required
                      urlapilist={`${GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}bycropyear/${this.props.cropYearId}/true`}
                      isHideAddButton={true}
                    />
                  </div>
                ) : (
                  ""
                )}
                {this.props.testType.isDynamic ? (
                  ""
                ) : (
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Primary Price <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <SelectConfig
                        configType="Primary Price"
                        required={true}
                        onRef={(ref) => (this.primaryPrice = ref)}
                        name="primaryPrice"
                        controlId="primaryPrice"
                        selectedIds={this.state.dataPopup.primaryPrice}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <NumericInput
                    precision={4}
                    min={0}
                    max={999999999}
                    step={0.0001}
                    label={
                      this.props.testType.isDynamic
                        ? this.props.testType.xpool === true
                          ? "Regular Price"
                          : "Price"
                        : "Inshell"
                    }
                    name="valueInshell"
                    value={this.state.dataPopup.valueInshell}
                    handleChange={this.handleChangeObjectData}
                    unit="$"
                    labelClass="3"
                    inputClass="5"
                    required={true}
                    labelrequired="(*)"
                  />
                </div>

                {this.props.testType.isDynamic ? (
                  this.props.testType.xpool === true ? (
                    <div className="form-group">
                      <NumericInput
                        precision={4}
                        min={0}
                        max={999999999}
                        step={0.0001}
                        label="Alternate Price"
                        name="valueShelled"
                        value={this.state.dataPopup.valueShelled}
                        handleChange={this.handleChangeObjectData}
                        unit="$"
                        labelClass="3"
                        inputClass="5"
                        required={true}
                        labelrequired="(*)"
                      />
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="form-group">
                    <NumericInput
                      precision={4}
                      min={0}
                      max={999999999}
                      step={0.0001}
                      label="Shelled"
                      name="valueShelled"
                      value={this.state.dataPopup.valueShelled}
                      handleChange={this.handleChangeObjectData}
                      unit="$"
                      labelClass="3"
                      inputClass="5"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>
                )}
                {this.props.testType.isDynamic ? (
                  <div className="form-group">
                    <TableAttribute
                      name="customAttributes"
                      onRef={(ref) => (this.customAttributes = ref)}
                      handleTableAttributeChange={
                        this.handleTableAttributeChange
                      }
                      processorId={this.props.loggedUser.processorId}
                      value={this.state.dataPopup.customAttributes}
                      testTypeId={this.props.testType._id}
                      isDynamic={this.props.testType.isDynamic}
                      apiUrlDynamicAttribute={
                        GlobalConfig.REACT_APP_API_NUTTYPE_URL +
                        "getMetaDataFieldsByTestType/" +
                        this.props.testType._id
                      }
                      readOnly={
                        this.props.testType._id === undefined ||
                        this.props.testType._id === null ||
                        this.props.testType._id === ""
                      }
                      loggedUser={this.props.loggedUser}
                      GlobalConfig={GlobalConfig}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-9 col-md-offset-3">
                  {!!this.state.subErrorMessage && (
                    <Form.Label
                      column
                      md="12"
                      style={{ color: "red", paddingLeft: "0px" }}
                    >
                      {this.state.subErrorMessage}
                    </Form.Label>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="add-payments-modal-footer">
                {this.state.isAdd ? (
                  <Button
                    style={{ margin: "0 5px" }}
                    variant="success"
                    type="submit"
                    onClick={this.handleAddSubPopup}
                  >
                    <span className="fa fa-check fa-lg"></span> Submit
                  </Button>
                ) : (
                  <Button
                    style={{ margin: "0 5px" }}
                    variant="success"
                    type="submit"
                    onClick={this.handleEditSubPopup}
                  >
                    <span className="fa fa-check fa-lg"></span> Submit
                  </Button>
                )}
                <Button
                  style={{ margin: "0 5px" }}
                  variant="warning"
                  onClick={this.hideSubPopup}
                >
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          <DataTable
            key="templateTable"
            id="templateTable"
            highlightOnHover
            striped
            noHeader={true}
            columns={this.setColumns()}
            data={this.state.items}
            className="table table-bordered table-striped mb-none"
            pagination={false}
            noDataComponent="There is no template"
            expandableRows={true}
            expandableRowsComponent={
              <SubTableTemplate
                expandableRowExpanded={(row) => {
                  return this.state.isExpand;
                }}
                loggedUser={this.props.loggedUser}
                testType={this.props.testType}
                varieties={this.state.varieties}
                paymentByVariety={this.props.testType.paymentByVariety}
              />
            }
            expandableRowExpanded={(row) => {
              return this.state.isExpand;
            }}
          ></DataTable>
        </div>
      </fieldset>
    );
  };
}
export default App;
