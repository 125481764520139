import React from "react";
import AddComponent from "../Base/AddComponent";
import { Helmet } from "react-helmet";
import Table from 'react-bootstrap/Table';

class App extends AddComponent {
  constructor(props) {
    super(props);
    this.headerInfoArray = [
      { name: 'Essential (Free)', price: "0", dueDate: 'Forever', isChecked: false },
      { name: 'Growth', price: "300", dueDate: 'Processor/Month', isChecked: false },
      { name: 'Enterprise', price: "500", dueDate: 'Processor/Month', isChecked: false },
    ];
    this.bodyInfoArray = [
      { title: 'Tests (Deliveries) per Season', free: 'Up to 300', standard: 'Up to 1,000', premium: 'Up to 4,000' },
      { title: 'API to export test data', free: 'No', standard: 'Yes', premium: 'Yes' },
      { title: 'Test Grower Payments', free: 'No', standard: 'Yes', premium: 'Yes' },
    ];
  }

  renderHeaderTable(headerInfo, index) {
    return (
      <th key={index}>
        <Table borderless>
          <tbody>
            <tr>
              <td style={{
                textAlign: "center",
                fontWeight: "normal",
                fontSize: "20px"
              }}>
                {headerInfo.name}
              </td>
            </tr>
            <tr>
              <td>
                <div className="row">
                  <div className="col-xs-3">
                  </div>
                  <a
                    href={process.env.REACT_APP_URL_SIGNUP + "?tier=" + headerInfo.name}
                    className={(() => {
                      switch (headerInfo.isChecked) {
                        case true: return "btn btn-primary hidden-xs col-xs-6";
                        case false: return "btn btn-default hidden-xs col-xs-6";
                        default: return "btn btn-default hidden-xs col-xs-6";
                      }
                    })()}
                  >
                    Register
                  </a>
                  <div className="col-xs-3">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{
                textAlign: "center",
                fontSize: "20px"
              }}>
                ${headerInfo.price}
              </td>
            </tr>
            <tr>
              <td style={{
                textAlign: "center",
                fontWeight: "normal"
              }}>
                {headerInfo.dueDate}
              </td>
            </tr>
          </tbody>
        </Table>

      </th>
    )
  }

  renderBodyTable(bodyInfo, index) {
    return (
      <tr key={index}>
        <td style={{
          paddingTop: 20,
          paddingBottom: 20,
        }} > {bodyInfo.title}</td>
        <td style={{
          textAlign: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}>{bodyInfo.free}</td>
        <td style={{
          textAlign: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}>{bodyInfo.standard}</td>
        <td style={{
          textAlign: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}>{bodyInfo.premium}</td>
      </tr>
    )
  }



  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"Plans and Pricing | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section
          className="body-sign"
          style={{ color: "white", paddingBottom: "50px" }}
        >
          <div className="container">
            <div
              className="row center"
              style={{
                color: "white",
                paddingTop: "50px",
              }}
            >
              <a href="/" className="logo pull-left">
                <img
                  src="/assets/images/logo.png"
                  height="54"
                  alt="ParityFactory Grading"
                />
              </a>
            </div>
            <div className="row center">
              <h1>PLANS AND PRICING</h1>
              <p>
                <br></br>
              </p>
            </div>
            <div className="row">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th></th>
                    {this.headerInfoArray.map(this.renderHeaderTable)}
                  </tr>
                </thead>
                <tbody>
                  {this.bodyInfoArray.map(this.renderBodyTable)}
                </tbody>
              </Table>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            <span style={{ color: "white" }}>
              &copy; ParityFactory All rights reserved |{" "}
              <a
                href="https://parityfactory.com/privacy-policy/"
                style={{ color: "white" }}
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </section>
      </div>
    );
  }
}
export default App;
