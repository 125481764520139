import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
const { sortBy } = require("lodash");
const DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: this.composePayments(props),
    };
  }

  composePayments = (props) => {
    let payments = [];
    const varieties = props.varieties;
    if (
      props.data.payments &&
      props.data.payments !== `` &&
      varieties &&
      varieties.length > 0
    ) {
      const paymentsOrigin = JSON.parse(props.data.payments);
      if (paymentsOrigin && paymentsOrigin.length > 0) {
        if (props.testType.paymentByVariety === true) {
          paymentsOrigin.map((x) => {
            let varietyName = ``;
            if (x.varietyId && x.varietyId !== ``) {
              const temp = varieties.find((v) => v._id == x.varietyId);
              if (temp) {
                varietyName = temp.name;
              }
            }
            x = JSON.parse(JSON.stringify(x));
            x.varietyName = varietyName;
            payments.push(x);
          });
          if (payments.length > 0) {
            payments = sortBy(
              payments,
              ["varietyId", `paymentDate`],
              ["asc", "asc"]
            );
          }
        } else {
          payments = paymentsOrigin;
          if (payments.length > 0) {
            payments = sortBy(payments, [`paymentDate`], ["asc"]);
          }
        }
      }
    }
    return payments;
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        omit: this.props.testType.paymentByVariety !== true,
        width: "300px",
      },
      {
        name: "Payment Date",
        selector: "paymentDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.paymentDate, "mm/dd/yyyy");
        },
        width: "200px",
      },
      {
        name: "Payment Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.paymentPercent == null ||
            row.paymentPercent === undefined ||
            isNaN(row.paymentPercent)
              ? 0
              : parseFloat(row.paymentPercent)
            ).toFixed(1)
          ) + "%",
        width: "200px",
        omit: this.props.testType.basePriceByPayment === true,
      },
    ];
  };

  render() {
    return (
      <DataTable
        key="subPaymentTable"
        id="subPaymentTable"
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
