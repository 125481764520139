import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import NumericInput from "../FormControl/NumericInput";
import DataTable from "react-data-table-component";
import DateFormat from "../Utilities/DateFormat";
import Switch from "react-switch";
import { Tabs, Tab } from "react-bootstrap-tabs";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      loading: parentState.loading,
      sectionTitle: "Tier Information",
      listtitle: "Tiers",
      urllist: process.env.REACT_APP_URL_TIER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_TIER_URL,
      urlsubmitapi: GlobalConfig.REACT_APP_API_TIER_URL,
      data: {
        _id: props.match.params.id,
        name: "",
        numberOfTestsDeliveries: 0,
        isExportToPdf: false,
        isAllowGrowerPayment: false,
        isAllowExportApi: false,
        isAllowImportApi: false,
        isAllowDynamicTest: false,
        totalMonthly: 0,
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      countUnpaid: 0,
      countPaid: 0,
      countExpired: 0,
      countInUse: 0,
      countPending: 0,
      processorItems: [
        {
          processorName: "Sample processor A",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2020",
          expiredDate: "31 Dec 2020",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Valid",
        },
        {
          processorName: "Sample processor A",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Paid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor C",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Paid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor D",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Paid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor E",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor F",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor G",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor H",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor I",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor K",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Paid",
          subscriptionStatus: "Expired",
        },
        {
          processorName: "Sample processor L",
          email: "sample@gmail.com",
          subscribedDate: "01 Jan 2019",
          expiredDate: "31 Dec 2019",
          paymentStatus: "Unpaid",
          subscriptionStatus: "Expired",
        },
      ],
      paginationPerPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      rowPerPageOption: GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION,
    };
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeSwitchAllowExport =
      this.handleChangeSwitchAllowExport.bind(this);
    this.handleChangeSwitchAllowGrowePayment =
      this.handleChangeSwitchAllowGrowePayment.bind(this);
    this.handleChangeSwitchAllowExportApi =
      this.handleChangeSwitchAllowExportApi.bind(this);
    this.handleChangeSwitchAllowImportApi =
      this.handleChangeSwitchAllowImportApi.bind(this);
    this.handleChangeSwitchAllowDynamicTest =
      this.handleChangeSwitchAllowDynamicTest.bind(this);
  }

  handleChangeSwitchAllowExport(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isExportToPdf: checked,
      }),
    });
  }

  handleChangeSwitchAllowGrowePayment(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowGrowerPayment: checked,
      }),
    });
  }

  handleChangeSwitchAllowExportApi(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowExportApi: checked,
      }),
    });
  }

  handleChangeSwitchAllowImportApi(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowImportApi: checked,
      }),
    });
  }

  handleChangeSwitchAllowDynamicTest(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowDynamicTest: checked,
      }),
    });
  }

  handleChangeNumber(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        ["numberOfTestsDeliveries"]: value,
      }),
    });
  }

  getTitle = () => {
    return this.state.data.name;
  };

  renderFooter = () => {
    return this.state.isHideFooter ? (
      ""
    ) : (
      <footer className="panel-footer">
        <div className="row">
          <div className="col-sm-4 col-sm-offset-2">
            <SaveButton loading={this.state.loading} />
            {this.state.urllist === null ||
            this.state.urllist === undefined ||
            this.state.urllist === "" ? (
              ""
            ) : (
              <a
                href={this.state.urllist}
                title="Back to list"
                className="btn btn-primary"
              >
                <i className="fa fa-backward fa-lg"></i> Back
              </a>
            )}
            {this.extendButtons()}
          </div>
        </div>
      </footer>
    );
  };

  doSomethingAfterGetItemById = async () => {
    const that = this;
    fetch(
      GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "bytier/" +
        this.state.data._id
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK" && resultObject.data) {
          that.setState(
            {
              processorItems: resultObject.data,
            },
            function () {
              that.updateCount();
            }
          );
        } else {
          that.setState(
            {
              processorItems: [],
            },
            function () {
              that.updateCount();
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateCount = async () => {
    let countUnpaid = 0;
    let countExpired = 0;
    let countPending = 0;
    let countInUse = 0;

    if (this.state.processorItems) {
      this.state.processorItems.filter(function (u) {
        if (u.paymentStatus == "Unpaid") {
          countUnpaid++;
        }
      });
      this.setState({ countUnpaid: countUnpaid });
      this.setState({
        countPaid: this.state.processorItems.length - countUnpaid,
      });

      this.state.processorItems.filter(function (u) {
        if (u.subscriptionStatus == "Pending") {
          countPending++;
        }
      });
      this.setState({ countPending: countPending });

      this.state.processorItems.filter(function (u) {
        if (u.subscriptionStatus == "Expired") {
          countExpired++;
        }
      });
      this.setState({ countExpired: countExpired });

      this.state.processorItems.filter(function (u) {
        if (u.subscriptionStatus == "In Use") {
          countInUse++;
        }
      });
      this.setState({ countInUse: countInUse });
    } else {
      this.setState({
        countUnpaid: countUnpaid,
        countPaid: 0,
        countExpired: 0,
        countPending: 0,
        countInUse: 0,
      });
    }
  };

  setColumns = () => {
    return [
      {
        name: "Processor Name",
        selector: "processorName",
        sortable: true,
        cell: (row) => (
          <a
            href={
              process.env.REACT_APP_URL_PROCESSOR_LIST + "/" + row.processorId
            }
            title={row.processorName}
          >
            {row.processorName}
          </a>
        ),
      },
      // {
      //   name: "Email",
      //   selector: "processorEmail",
      //   sortable: true,
      // },
      {
        name: "Subscribed Date",
        selector: "subscribedDate",
        center: true,
        sortable: true,
        cell: (row) => {
          return row.subscribedDate ? (
            <DateFormat date={row.subscribedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Valid From",
        selector: "validFrom",
        center: true,
        cell: (row) => {
          return row.validFrom ? (
            <DateFormat date={row.validFrom} format="MM/DD/YYYY"></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Valid To",
        selector: "validTo",
        center: true,
        cell: (row) => {
          return row.validTo ? (
            <DateFormat date={row.validTo} format="MM/DD/YYYY"></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Status",
        selector: "subscriptionStatus",
        center: true,
        cell: (row) => {
          return this.formatSubscriptionStatus(row);
        },
      },
      // {
      //   name: "",
      //   sortable: true,
      //   cell: (row) => {
      //     return row.subscriptionStatus != "Pending" ? (
      //       <a
      //         href="#"
      //         className="on-default edit-row"
      //         data-id={row._id}
      //         title="Click here to renew license."
      //         data-action="Renew"
      //         // onClick={this.handleShow.bind(this)}
      //       >
      //         Renew
      //       </a>
      //     ) : (
      //       <a
      //         href="#"
      //         className="on-default edit-row"
      //         data-id={row._id}
      //         data-action="Approved"
      //         // onClick={this.handleShow.bind(this)}
      //       >
      //         Approve
      //       </a>
      //     );
      //   },
      // },
    ];
  };

  formatSubscriptionStatus = (row) => {
    if (row.subscriptionStatus == "In Use") {
      return (
        <span
          className="label label-success"
          style={{ fontSize: "80%", paddingTop: "5px", paddingBottom: "7px" }}
        >
          {row.subscriptionStatus}
        </span>
      );
    }
    if (row.subscriptionStatus == "Expired") {
      return (
        <span
          className="label label-default"
          style={{ fontSize: "80%", paddingTop: "5px", paddingBottom: "7px" }}
        >
          {row.subscriptionStatus}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{ fontSize: "80%", paddingTop: "5px", paddingBottom: "7px" }}
      >
        {row.subscriptionStatus}
      </span>
    );
  };

  handleSelect(key) {
    this.setState({ isHideFooter: key === 1 });
  }

  renderForm = () => {
    return (
      <div className="panel-body" style={{ padding: "0px" }}>
        <Tabs
          // style={{ border: "2px solid green" }}
          headerStyle={{ fontWeight: "bold", cursor: "pointer" }}
          activeHeaderStyle={{ color: "#1891E5" }}
          // contentStyle={{ backgroundColor: "lightgoldenrodyellow" }}
          selected={0}
          onSelect={(k) => this.handleSelect(k)}
        >
          <Tab
            headerClass="tab-pane"
            activeHeaderClass="tab-pane active"
            eventKey="overview"
            label="Tier Information"
          >
            <div className="form-group">
              <label className="col-sm-2 control-label">
                Tier <span className="required">*</span>
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder=""
                  required
                  value={this.state.data.name}
                  onChange={this.handleChange}
                  maxLength={100}
                />
              </div>
            </div>
            <NumericInput
              precision={0}
              min={0}
              max={999999999}
              step={1}
              label="Tests (Deliveries)"
              name="numberOfTestsDeliveries"
              value={this.state.data.numberOfTestsDeliveries}
              handleChange={this.handleChange}
              labelClass="2"
              inputClass="4"
              required={false}
              labelrequired=""
            />
            <div className="form-group">
              <div className="col-md-6">
                <NumericInput
                  precision={1}
                  min={0}
                  max={999999999}
                  step={1}
                  label="Total Monthly ($)"
                  name="totalMonthly"
                  value={this.state.data.totalMonthly}
                  handleChange={this.handleChange}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                Allow Export to PDF
              </label>
              <div className="col-md-4">
                <Switch
                  name="isExportToPdf"
                  onChange={this.handleChangeSwitchAllowExport}
                  checked={this.state.data.isExportToPdf}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">Allow Export API</label>
              <div className="col-md-10">
                <Switch
                  name="isAllowExportApi"
                  onChange={this.handleChangeSwitchAllowExportApi}
                  checked={this.state.data.isAllowExportApi}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">Allow Import API</label>
              <div className="col-md-10">
                <Switch
                  name="isAllowImportApi"
                  onChange={this.handleChangeSwitchAllowImportApi}
                  checked={this.state.data.isAllowImportApi}
                  className="react-switch"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                Allow Grower Payment
              </label>
              <div className="col-md-10">
                <Switch
                  name="isAllowGrowerPayment"
                  onChange={this.handleChangeSwitchAllowGrowePayment}
                  checked={this.state.data.isAllowGrowerPayment}
                  className="react-switch"
                />
                <p>
                  Allow/disallow processor (include staffs, lab users belong to)
                  access grower payment feature.
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                Allow Dynamic Test
              </label>
              <div className="col-md-10">
                <Switch
                  name="isAllowDynamicTest"
                  onChange={this.handleChangeSwitchAllowDynamicTest}
                  checked={this.state.data.isAllowDynamicTest}
                  className="react-switch"
                />
              </div>
            </div>
          </Tab>
          <Tab
            headerClass="tab-pane"
            activeHeaderClass="tab-pane active"
            eventKey="processors"
            label="Processors Subscribed"
          >
            <DataTable
              id="tableProcessor"
              title={
                <div className="col-sm-12" style={{ marginLeft: "15px" }}>
                  <div className="row form-inline">
                    <div className="form-group">
                      <h2 className="panel-title">
                        {this.state.processorItems.length} processors subscribed
                      </h2>
                    </div>
                  </div>
                  <div className="row form-inline">
                    <div className="form-group" style={{ fontSize: "13px" }}>
                      <label className="control-label">
                        <strong>Status: </strong>
                      </label>
                      {this.state.countExpired <= 0 ? (
                        ""
                      ) : (
                        <span
                          className="label label-default"
                          style={{ fontSize: "13px" }}
                        >
                          {this.state.countExpired} Expired
                        </span>
                      )}{" "}
                      {this.state.countInUse <= 0 ? (
                        ""
                      ) : (
                        <span
                          className="label label-success"
                          style={{ fontSize: "13px" }}
                        >
                          {this.state.countInUse} In Use
                        </span>
                      )}{" "}
                      {this.state.countPending <= 0 ? (
                        ""
                      ) : (
                        <span
                          className="label label-warning"
                          style={{ fontSize: "13px" }}
                        >
                          {this.state.countPending} Pending
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              }
              noHeader={false}
              columns={this.setColumns()}
              data={this.state.processorItems}
              className="table table-bordered table-striped mb-none"
              pagination={true}
              paginationPerPage={this.state.paginationPerPage}
              paginationRowsPerPageOptions={this.state.rowPerPageOption}
              style={{ margin: "0px" }}
            />
          </Tab>
        </Tabs>
      </div>
    );
  };
}
export default App;
