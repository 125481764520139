export const overrideLoadingStyle = {
  width: 20,
  height: 20,
  borderRadius: "100%",
  borderWidth: 3,
  borderStyle: "solid",
  //   borderColor: "rgb(0, 0, 0) rgb(0, 0, 0) transparent",
  borderColor: "#FFFFFF #FFFFFF transparent",
  borderImage: "initial",
  display: "inline-block",
  animation:
    "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
};
