import React from "react";
import Select from "react-select";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isSetCheckedFromSource: props.isSetCheckedFromSource,
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      placeholder: props.placeholder,
      label: props.label,
      value: props.value,
      multidata: props.multidata,
      name: "",
      data: [],
      errorMessage: "",
      optionSelected: null,
      isLoading : false
    };
    this.selectRef = null;
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.urlapilist !== this.props.urlapilist) {
      this.setState(
        {
          urlapilist: this.props.urlapilist,
        },
        () => this.getItems()
      );
    }
  }

  getItems = async () => {
    try {
      if (
        this.state.urlapilist === undefined ||
        this.state.urlapilist == null ||
        this.state.urlapilist === ""
      ) {
        return;
      }
      const that = this;
      that.setState({
        isLoading : true
      });
      fetch(this.state.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            let selectedValue = "";
            let selectedName = "";

            const arrayConvert = resultObject.data.map((str, index) => ({
              _id: str,
              name: str,
            }));

            if (resultObject.data) {
              if (that.state.isSetCheckedFromSource === true) {
                arrayConvert.map((item) => {
                  if (item.checked === true) {
                    selectedValue = item._id;
                    selectedName = item.name;
                  }
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked: item.checked,
                  });
                });
              } else {
                arrayConvert.map((item) => {
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked:
                      that.state.value == null ||
                        that.state.value === undefined ||
                        that.state.value.indexOf(item._id) === -1
                        ? false
                        : true,
                  });
                });
              }
            }

            that.setState(
              {
                isLoading : false,
                data: parsedItems,
                optionSelected: parsedItems
                  .filter((item) => item.checked === true)
                  .map((item) => ({
                    value: item._id,
                    label: item.name,
                  })),
              },
              function callBack() {
                if (that.props.isSetCheckedFromSource === true) {
                  //fire event select item
                  that.setState({
                    value: selectedValue,
                    label: selectedName,
                  });
                  that.props.handleChangeSelectControl(
                    that.state.controlId,
                    selectedValue,
                    selectedName
                  );
                }
              }
            );
          }
        })
        .catch(function (error) {
          that.setState({
            isLoading : false
          });
          console.log(error);
        });
    } catch (err) {
      this.setState({
        isLoading : false
      });
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    const valueItem = event;
    const arrayItem = valueItem.map((item) => {
      return item.value;
    });
    const convert = arrayItem.toString();
    this.setState({ multidata: convert, optionSelected: event });
    this.props.handleChangeSelectControl(this.state.controlId, convert);
  }

  setSelected(value) {
    this.setState({ value: value }, () => this.getItems());
  }

  reload(urlapilist) {
    this.setState(
      {
        urlapilist: urlapilist,
      },
      () => {
        this.getItems();
      }
    );
  }

  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          block: "nearest",
        });
      }
    }, 15);
  };

  reset = () => {
    this.selectRef.select.clearValue();
  };

  render() {
    return (
      <>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          closeMenuOnSelect={false}
          isMulti
          name={this.state.controlId}
          onChange={this.handleChangeSelect}
          placeholder={this.state.placeholder}
          value={this.state.optionSelected}
          classNamePrefix={"MyDropdown"}
          onMenuOpen={this.onMenuOpen}
          options={
            this.state.data
              ? this.state.data.map((item) => {
                return {
                  value: item._id,
                  label: item.name,
                };
              })
              : ""
          }
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}
export default App;
