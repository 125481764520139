import React from "react";
import { Modal } from "react-bootstrap";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      currentTab: 0,
      startPosition: 0,
      endPosition: 0,
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }

  componentDidMount() {
    const { wizardData } = this.props;

    this.setState({
      endPosition: wizardData.length - 1,
    });
  }

  handleNext = () => {
    const { currentTab } = this.state;
    const { wizardData } = this.props;

    const nextData = wizardData.find((item) => item.sequence > currentTab);

    if (currentTab < this.state.endPosition && !nextData.disabled) {
      this.setState({ currentTab: currentTab + 1 });
    }

    this.props.onValidate();
  };

  handlePrevious = () => {
    const { currentTab } = this.state;
    if (currentTab > this.state.startPosition) {
      this.setState({ currentTab: currentTab - 1 });
    }
  };

  handleTabChange = (index, isDisable) => {
    if (!isDisable) {
      this.setState({ currentTab: index });
    }
  };

  handleClose = () => {
    this.props.onHide();
    this.setState({ currentTab: this.state.startPosition });
  };

  hanldeFinish = () => {
    this.props.onFinish();
    this.setState({ currentTab: this.state.startPosition });
  };

  render() {
    const { currentTab } = this.state;
    const { wizardData } = this.props;

    const currentData = wizardData.find((item) => item.sequence > currentTab);

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        className="modal-custom"
      >
        <div className="row">
          <div className="col-lg-12">
            <section className="panel form-wizard m-none">
              <header className="panel-heading">
                <h2 className="panel-title">
                  Clone Default Settings{" "}
                  {` > ${this.props.selectedGrower.name}`}
                </h2>
              </header>
              <div className="panel-body panel-body-nopadding">
                <div className="wizard-tabs">
                  <ul className="wizard-steps wizard-tab-container">
                    {wizardData.map((item) => {
                      return (
                        <li
                          className={
                            currentTab === item.sequence
                              ? "active wizard-tab-item"
                              : "wizard-tab-item"
                          }
                          key={item.tabId}
                          onClick={item.onClick}
                        >
                          <a
                            href={item.tabId}
                            className={`text-center ${item.disabled ? "wizard-tab-disabled" : ""
                              }`}
                            onClick={() =>
                              this.handleTabChange(item.sequence, item.disabled)
                            }
                          >
                            <span className="badge">{item.sequence + 1}</span>
                            {" " + item.tabTitle}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <form className="form-horizontal">
                  <div className="tab-content">
                    {wizardData.map((item) => {
                      return (
                        <div
                          key={item.tabId}
                          id={item.tabId}
                          className={
                            currentTab === item.sequence
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                        >
                          {item.content}
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
              <div className="panel-footer">
                <ul className="pager m-none">
                  <li
                    className={
                      currentTab === this.state.startPosition
                        ? "hidden"
                        : "previous user-select-none"
                    }
                  >
                    <a href="# " onClick={this.handlePrevious}>
                      <i className="fa fa-angle-left"></i> Previous
                    </a>
                  </li>

                  <li
                    className={
                      currentTab === this.state.endPosition
                        ? "finish pull-right user-select-none"
                        : "hidden"
                    }
                  >
                    <a href="# " onClick={this.hanldeFinish}>
                      Confirm
                    </a>
                  </li>

                  <li
                    className={
                      currentTab === this.state.endPosition
                        ? "hidden"
                        : `next user-select-none ${currentData.disabled ? "disabled" : ""
                        }`
                    }
                  >
                    <a href="# " onClick={this.handleNext}>
                      Next <i className="fa fa-angle-right"></i>
                    </a>
                  </li>

                  <li
                    className={"pull-right mr-sm user-select-none"}
                    onClick={this.handleClose}
                  >
                    <a href="# ">Cancel</a>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    );
  }
}
export default App;
