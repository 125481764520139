import React from "react";
import { getLoggedUser } from "../../context/auth";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import ListComponentPayment from "../Base/ListComponentPayment";
import { Button, Form, Row, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import Switch from "react-switch";
import SelectConfig from "../Base/SelectConfig";
import SubTable from "../BonusDeduct/SubTable";
import SelectGrowers from "../Grower/SelectGrowers";
import MultiSelectVarieties from "../TestType/MultiSelectVarieties";
import ListGrower from "../Grower/ListGrower";
import CommonUtilities from "../Utilities/Common";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import Popover from "../Base/Popover";
import SaveButton from "../Controls/SaveButton";
import Template from "./Template";
import Export from "./Export";
import { parseViewModel, wizardColumns } from "./WizardHandler";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state = {
      popupUrlApi: GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL,
      parentId: props.match.params.id,
      urlapi: `${GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}/parent/${props.match.params.id}`,
      urlapidelete: `${GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}`,
      urllist:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BONUSDEDUCT_LIST +
        "/" +
        props.match.params.id,
      urledit:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BONUSDEDUCT_LIST +
        "-edit/",
      urladd:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BONUSDEDUCT_LIST +
        "-add/" +
        props.match.params.id,
      title: "Bonus & Deduct",
      pluraltitle: "Bonuses & Deducts",
      labeladd: "Bonus & Deduct",
      expandableRows: true,
      viewMode: "edit",
      isExpand: false,
      isUseHeading: false,
      cropYearName: "",
      viewData: [],
      progressPending: false,

      //State using for popup add/edit bonus & deduct
      data: {
        _id: "",
        parentId: props.match.params.id,
        growerId: "",
        varietyId: "",
        attribute: null,
        applyTo: null,
        min: 0,
        max: 0,
        value: 0,
        applyType: "",
        isDeduct: false,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      previousGrowerId: "",
      previousAttribute: "",
      previousApplyTo: "",
      previousVarietyId: "",
      previousApplyType: "",
      defaulApplyType: `All in First Payment`,
      isChanged: false,
      errorMessage: "",
      color: "red",
      isEditMode: true,
      //This state is using for remove row later
      removedGrowerId: "",
      //itemsByCropYear is using for calculate total
      itemsByCropYear: [],
      totalByVariety: 0,
      showRefresh2: true,
      valueDecimals: parseInt(GlobalConfig.REACT_APP_ADJUSTMENT_DECIMALS),
      valueDecimalStep: parseFloat(
        GlobalConfig.REACT_APP_ADJUSTMENT_DECIMAL_STEP
      ),

      testType: {
        xpool: false,
        isDynamic: false,
        _id: "",
        basePriceByPayment: false,
        paymentPercentBased: true,
        multiPricesSameLot: false,
        varietyOptional: false,
      },
      saveLoading: false,
      //This state used for Wizard mode
      wizardMode: false,
      isWizardEdit: false,
    };

    //server paging
    this.state.isExpand = false;
    this.state.expandableRows = true;
    this.state.pagination = true;
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE_ADJUSTMENTS);
    this.state.paginationPerPage = parseInt(
      GlobalConfig.REACT_APP_PAGE_SIZE_ADJUSTMENTS
    );
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleChangeMin = this.handleChangeMin.bind(this);
    this.handleChangeMax = this.handleChangeMax.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
  }

  handleDeleteAfterDeletedOnServer = (removedId) => {
    this.doSomethingAfterDeletedItem();
    this.handleReloadDefaultSettingTable();
  };

  handleChangeSwitch(checked) {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          isDeduct: checked,
        }),
      },
      () => {
        this.updateTotal();
      }
    );
  }

  handleChangeSelectControl = (controlId, value, label) => {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
        }),
      },
      () => {
        if (controlId === "attribute" || controlId === "varietyId") {
          this.updateTotal();
        }
      }
    );
  };

  handleChangeMin(value) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        ["min"]: value,
      }),
    });
  }

  handleChangeMax(value) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        ["max"]: value,
      }),
    });
  }

  handleChangeValue(value) {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          ["value"]: value,
        }),
      },
      () => {
        this.updateTotal();
      }
    );
  }

  extendRender = () => {
    return (
      <>
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={true}
          baseApiUrl={GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}
          showCheckDuplicate={true}
          handleShowWithWizardMode={this.handleShowWithWizardMode}
          handleDelete={this.handleDelete.bind(this)}
          loggedUser={loggedUser}
          isWizard={true}
          changeWizardMode={this.changeWizardMode}
          parseViewModel={parseViewModel}
          columns={wizardColumns(
            (row, index) => {
              if (this.listGrower) {
                this.listGrower.setEditedIndex(index);
              }
              this.handleShowWithWizardMode(row);
            },
            this.state.testType,
            loggedUser,
            (index) => {
              if (this.listGrower) {
                this.listGrower.removeRow(index);
              }
            }
          )}
        />
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in edit-popup-on-wizard"
          centered
          dialogClassName="medium-modal"
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              {this.state.wizardMode ? (
                <Modal.Title>
                  {`${this.state.isWizardEdit ? "Edit " : "Add "}${
                    this.state.title
                  }`}
                </Modal.Title>
              ) : (
                <Modal.Title>
                  {this.state.data._id === ""
                    ? "Add " + this.state.title
                    : "Edit " + this.state.title}{" "}
                  {this.state.cropYearName !== ""
                    ? " - " + this.state.cropYearName
                    : ""}
                </Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  {!this.state.wizardMode && (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="attribute"
                      >
                        Default/Grower <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <SelectGrowers
                          required={false}
                          onRef={(ref) => (this.growerId = ref)}
                          name="growerId"
                          value={this.state.data.growerId}
                          selectedIds={this.state.data.growerId}
                          handleChangeSelectGrower={
                            this.handleChangeSelectGrowerCustom
                          }
                          testTypeId={this.state.testType._id}
                          cropYearId={this.state.parentId}
                          checkExistPaymentSetting={true}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.testType.varietyOptional !== true ? (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="varietyId"
                      >
                        Variety <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <MultiSelectVarieties
                          isMulti={
                            (!this.state.wizardMode && !this.state.data._id) ||
                            (this.state.wizardMode && !this.state.isWizardEdit)
                          }
                          showSelectAll
                          required={true}
                          onRef={(ref) => (this.varietyId = ref)}
                          name="varietyId"
                          controlId="varietyId"
                          selectedIds={this.state.data.varietyId}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                          urlApi={
                            GlobalConfig.REACT_APP_API_VARIETY_URL +
                            "byParentId/" +
                            this.state.testType._id
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      className="col-md-4 control-label"
                      htmlFor="attribute"
                    >
                      Bonus or Deduction Applied{" "}
                      <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <SelectConfig
                        configType="Tax or Fee Applied"
                        required={true}
                        onRef={(ref) => (this.applyType = ref)}
                        name="applyType"
                        controlId="applyType"
                        selectedIds={this.state.data.applyType}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-4 control-label"
                      htmlFor="attribute"
                    >
                      Quality Attribute
                      <Popover
                        trigger={["hover", "focus"]}
                        className="popover-custom"
                        placement="top"
                        content='Test Fields under "Quality Data" are available for setting Bonuses & Deductions. Note, Quality Attributes fields can be aggregated into a new field using Grower Tools > Manage Defects Attributes'
                        isUseDefaultOverlay
                      >
                        <i
                          className="fa fa-info-circle panel-heading-info-icon mr-xs"
                          aria-hidden="true"
                          style={{
                            color: "#777",
                          }}
                        />
                      </Popover>
                      <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      {this.state.testType.isDynamic === true ? (
                        <FilterSelectControl
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                          onRef={(ref) => (this.attribute = ref)}
                          label="Choose one"
                          required={true}
                          value={this.state.data.attribute}
                          name="attribute"
                          controlId="attribute"
                          urlapilist={
                            GlobalConfig.REACT_APP_API_NUTTYPE_URL +
                            "getNameQualityDataFieldsByTestType/" +
                            this.state.testType._id +
                            "/false"
                          }
                        />
                      ) : (
                        <SelectConfig
                          configType={
                            process.env.REACT_APP_CONFIG_NAME_BONUS_DEDUCT
                          }
                          processorId={loggedUser.processorId}
                          required={true}
                          onRef={(ref) => (this.attribute = ref)}
                          name="attribute"
                          controlId="attribute"
                          selectedIds={this.state.data.attribute}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                        />
                      )}
                    </div>
                  </div>
                  {this.state.testType.isDynamic === true ? (
                    ""
                  ) : (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="applyTo"
                      >
                        Applies To <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <SelectConfig
                          configType="Bonus & Deduct Apply To"
                          required={true}
                          onRef={(ref) => (this.applyTo = ref)}
                          name="applyTo"
                          controlId="applyTo"
                          selectedIds={this.state.data.applyTo}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="col-md-4 control-label">
                      % Total Net Weight <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <div className="input-group mb-md">
                        <NumericInput
                          precision={2}
                          min={0}
                          max={100}
                          step={0.1}
                          label=""
                          name="min"
                          value={this.state.data.min}
                          handleChange={this.handleChangeObjectData}
                          unit=""
                          labelClass="4"
                          inputClass="8"
                          required={true}
                          labelrequired=""
                        />
                        <span className="input-group-addon">to</span>
                        <NumericInput
                          precision={2}
                          min={0}
                          max={100}
                          step={0.1}
                          label=""
                          name="max"
                          value={this.state.data.max}
                          handleChange={this.handleChangeObjectData}
                          unit=""
                          labelClass="4"
                          inputClass="8"
                          required={true}
                          labelrequired=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <NumericInput
                      precision={this.state.valueDecimals}
                      min={-100}
                      max={100}
                      step={this.state.valueDecimalStep}
                      label="Value"
                      name="value"
                      value={this.state.data.value}
                      handleChange={this.handleChangeObjectData}
                      unit="$"
                      labelClass="4"
                      inputClass="8"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Is a Deduction
                      <br />
                      (Negative Value)
                    </label>
                    <div className="col-md-8">
                      <Switch
                        name="isDeduct"
                        onChange={this.handleChangeSwitch}
                        checked={this.state.data.isDeduct}
                        className="react-switch"
                      />
                    </div>
                  </div>
                  {this.state.data._id == null ||
                  this.state.data._id === undefined ||
                  this.state.data._id === "" ? (
                    ""
                  ) : (
                    <div className="form-group">
                      <label className="col-md-4 control-label" htmlFor="total">
                        Total Value
                      </label>
                      <div className="col-md-8">
                        <span>
                          {this.state.totalByVariety < 0
                            ? "(" +
                              CommonUtilities.numberWithCommas(
                                parseFloat(
                                  Math.abs(this.state.totalByVariety)
                                ).toFixed(2)
                              ) +
                              ")"
                            : CommonUtilities.numberWithCommas(
                                parseFloat(this.state.totalByVariety).toFixed(2)
                              )}
                        </span>
                        <p>
                          Total value of group attribute by grower and variety.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected bonus & deduct no longer exists. Please refresh and
                    try again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-center">
              {this.state.data ? (
                <SaveButton loading={this.state.saveLoading} />
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };

  handleChangeSelectGrowerCustom = (name, value) => {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          [name]: value,
        }),
      },
      () => {
        this.updateTotal();
      }
    );
  };

  updateTotal() {
    let totalByVariety = this.calculateTotal(
      this.state.data,
      this.state.itemsByCropYear,
      this.state.data._id
    );
    if (this.state.data.isDeduct) {
      totalByVariety = totalByVariety - this.state.data.value;
    } else {
      totalByVariety = totalByVariety + this.state.data.value;
    }
    this.setState({
      totalByVariety: totalByVariety,
    });
  }

  calculateTotal(data, items, excludeId) {
    let prefixAttribute = "";
    const tempArr = data.attribute.split(":");
    if (tempArr && tempArr.length > 0) {
      prefixAttribute = tempArr[0] + ": ";
    }
    let totalByVariety = 0;
    const growerId = data.growerId ? data.growerId.toString() : "";
    const varietyId = data.varietyId ? data.varietyId.toString() : "";
    items.map((x) => {
      if (
        (x.growerId ? x.growerId.toString() : "") === growerId &&
        (x.varietyId ? x.varietyId.toString() : "") === varietyId &&
        x.attribute.startsWith(prefixAttribute)
      ) {
        if (excludeId) {
          if (x._id !== excludeId) {
            totalByVariety += x.value;
          }
        } else {
          totalByVariety += x.value;
        }
      }
    });
    return totalByVariety;
  }

  getAllItemsByCropYear = async (data) => {
    const that = this;
    let totalByVariety = 0;
    fetch(
      GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL +
        "getallitemsbycropyear/" +
        this.state.parentId
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          if (data && data._id !== "") {
            totalByVariety = that.calculateTotal(
              data,
              resultObject.data,
              undefined
            );
          }
          that.setState({
            itemsByCropYear: resultObject.data,
            totalByVariety: totalByVariety,
          });
        } else {
          that.setState({
            itemsByCropYear: [],
            totalByVariety: totalByVariety,
          });
        }
      })
      .catch(function (error) {
        that.setState({
          itemsByCropYear: [],
          totalByVariety: totalByVariety,
        });
      });
  };

  handleShow = (data) => {
    this.getAllItemsByCropYear(data).then(() => {
      var convertedJSON = JSON.parse(JSON.stringify(data));
      let isEditMode = true;
      if (data._id === "") {
        convertedJSON.createdUserId = loggedUser._id;
        isEditMode = false;
      } else {
        convertedJSON.modifiedUserId = loggedUser._id;
      }
      //Dynamic attribute based on value
      convertedJSON.isDeduct = data.value < 0;
      if (data.value < 0) {
        convertedJSON.value = convertedJSON.value * -1;
      }
      this.setState({ data: convertedJSON }, function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
            isEditMode: isEditMode,
          },
          function callBack() {
            //Set selected dropdown
            this.attribute.setSelected(this.state.data.attribute);
            if (this.applyTo) {
              this.applyTo.setSelected(this.state.data.applyTo);
            }
            if (this.varietyId) {
              this.varietyId.setSelected(this.state.data.varietyId);
            }
            this.applyType.setSelected(this.state.data.applyType);
          }
        );
      });
    });
  };

  handleShowWithWizardMode = (bonusDeduct = null) => {
    const data = bonusDeduct
      ? bonusDeduct
      : {
          varietyId: "",
          attribute: "",
          applyTo: "",
          applyType: this.state.defaulApplyType,
          min: 0,
          max: 0,
          value: 0,
          isDeduct: false,
        };
    var convertedJSON = JSON.parse(JSON.stringify(data));
    //Dynamic attribute based on value
    convertedJSON.isDeduct = data.value < 0;
    if (data.value < 0) {
      convertedJSON.value = convertedJSON.value * -1;
    }

    this.setState(
      {
        data: convertedJSON,
        show: true,
        errorMessage: "",
        isWizardEdit: bonusDeduct ? true : false,
      },
      () => {
        if (this.state.isWizardEdit) {
          if (this.varietyId) {
            this.varietyId.setSelected(this.state.data.varietyId);
          }
          if (this.attribute) {
            this.attribute.setSelected(this.state.data.attribute);
          }
          if (this.applyTo) {
            this.applyTo.setSelected(this.state.data.applyTo);
          }
          if (this.applyType) {
            this.applyType.setSelected(this.state.data.applyType);
          }
        } else {
          if (this.applyType) {
            this.applyType.setSelected(this.state.defaulApplyType);
          }
        }
      }
    );
  };

  handleReloadDefaultSettingTable = () => {
    this.listGrower.getDefaultSettings();
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
    });

    const varietyId = this.state.data.varietyId;
    const attribute = this.state.data.attribute;
    const applyType = this.state.data.applyType;
    const applyTo =
      this.state.testType.isDynamic === true ? "Any" : this.state.data.applyTo;
    const min = this.state.data.min;
    const max = this.state.data.max;
    let errorMessage = "";
    if (
      this.state.testType.varietyOptional !== true &&
      (varietyId === null || varietyId === undefined || varietyId.trim() === "")
    ) {
      errorMessage = "Select variety to continue.";
    } else if (
      applyType == null ||
      applyType === undefined ||
      applyType === ""
    ) {
      errorMessage = "Please select a Bonus or Deduction Applied to continue.";
    } else if (
      attribute == null ||
      attribute === undefined ||
      attribute === ""
    ) {
      errorMessage = "Please select Quality Attribute to continue.";
    } else if (applyTo == null || applyTo === undefined || applyTo === "") {
      errorMessage = "Please select Applies To to continue.";
    }
    if (errorMessage === "" && min > max) {
      errorMessage = "% Total Net Weight, To must be greater than From.";
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
      return;
    }
    if (this.state.wizardMode) {
      this.saveWizard(varietyId, attribute, applyType, applyTo, min, max);
    } else {
      this.save(varietyId, attribute, applyType, applyTo, min, max);
    }
  };

  save = (varietyId, attribute, applyType, applyTo, min, max) => {
    this.setState({
      saveLoading: true,
    });
    let previousGrowerId = this.state.data.growerId;
    let previousAttribute = this.state.data.attribute;
    let previousApplyTo = this.state.data.applyTo;
    let previousVarietyId = this.state.data.varietyId;
    let previousApplyType = this.state.data.applyType;
    //Using for edit
    let urlApi =
      GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL + this.state.data._id;
    let method = "PATCH";
    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL;
      isEditMode = false;
    }
    let dataCloned = Object.assign({}, this.state.data, {});
    dataCloned.testType = this.state.testType;
    if (this.state.data.isDeduct) {
      dataCloned = Object.assign({}, dataCloned, {
        ["value"]: dataCloned.value * -1,
      });
    }
    if (this.state.testType.isDynamic === true) {
      dataCloned.applyTo = `Any`;
    }
    if (this.state.testType.varietyOptional === true) {
      dataCloned.varietyId = ``;
    }

    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataCloned),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        //If created successful
        if (resultObject.result === "OK") {
          window.showAlert("", "Save successful!", "");
          that.setState(
            {
              isChanged: true,
              previousGrowerId: previousGrowerId,
              previousAttribute: previousAttribute,
              previousApplyTo: previousApplyTo,
              previousVarietyId: previousVarietyId,
              previousApplyType: previousApplyType,
              saveLoading: false,
              currentPage: 1,
            },
            function callBack() {
              that.getItemsServerPaging(that.state.currentPage);
              this.handleReloadDefaultSettingTable();
              if (!isEditMode) {
                that.handResetForm();
              }
            }
          );
        } else {
          window.showAlert("Error", resultObject.message, "error");
          that.setState({ saveLoading: false });
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
        that.setState({ saveLoading: false });
      });
  };

  saveWizard = (varietyId, attribute, applyType, applyTo, min, max) => {
    if (this.state.isWizardEdit) {
      let bonusDeduct = {
        ...this.state.data,
      };
      if (!this.state.testType.varietyOptional) {
        const variety = this.varietyId.state.data.find(
          (variety) => variety._id === this.state.data.varietyId
        );
        const varietyName = variety ? variety.name : "";
        bonusDeduct.varietyName = varietyName;
      }
      const bonusDeducts = this.listGrower.state.defaultSettings.filter(
        (_, index) => index !== this.listGrower.state.editedIndex
      );
      let arrVarietyId = !!bonusDeduct.varietyId
        ? bonusDeduct.varietyId.split(",")
        : [];
      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        bonusDeductItems: bonusDeducts,
        bonusDeductRequest: {
          attribute: bonusDeduct.attribute,
          applyTo: bonusDeduct.applyTo,
        },
      });
      const isOverlap = CommonUtilities.checkOverlap(itemsByParent, min, max);
      if (isOverlap !== "") {
        window.showAlert(
          "Error",
          "Setting values is overlapped. Please try with other values.",
          "error"
        );
        return;
      }
      if (bonusDeduct.isDeduct) {
        bonusDeduct = Object.assign({}, bonusDeduct, {
          ["value"]: bonusDeduct.value * -1,
        });
      }
      if (this.state.testType.isDynamic === true) {
        bonusDeduct.applyTo = `Any`;
      }
      if (this.state.testType.varietyOptional === true) {
        bonusDeduct.varietyId = ``;
      }
      this.listGrower.editRow(bonusDeduct);
      this.setState({
        show: false,
      });
    } else {
      //Add new setting from popup copy wizard
      let {
        applyTo,
        applyType,
        attribute,
        min,
        max,
        value,
        varietyId,
        isDeduct,
      } = this.state.data;
      if (this.state.testType.isDynamic === true) {
        applyTo = `Any`;
      }
      let arrVarietyId = !!varietyId ? varietyId.split(",") : [];
      const bonusDeductItems = this.listGrower.state.defaultSettings;

      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        bonusDeductItems,
        bonusDeductRequest: {
          attribute,
          applyTo,
        },
      });
      const isOverlap = CommonUtilities.checkOverlap(itemsByParent, min, max);
      if (isOverlap !== "") {
        window.showAlert(
          "Error",
          "Setting values is overlapped. Please try with other values.",
          "error"
        );
        return;
      }
      if (
        arrVarietyId === undefined ||
        arrVarietyId === null ||
        arrVarietyId.length <= 0
      ) {
        arrVarietyId = [{ undefined }];
      }
      if (isDeduct) {
        value = value * -1;
      }
      const bonusDeductList = arrVarietyId.map((varietyId) => {
        let varietyName = "";
        if (!this.state.testType.varietyOptional) {
          const variety = this.varietyId.state.data.find(
            (variety) => variety._id === varietyId
          );
          varietyName = variety ? variety.name : "";
        }
        if (this.state.testType.varietyOptional === true) {
          varietyId = ``;
        }
        let newObj = {
          applyTo: applyTo,
          applyType: applyType,
          attribute: attribute,
          min: min,
          max: max,
          value: value,
          varietyId: varietyId,
          varietyName: varietyName,
        };
        if (this.state.testType.varietyOptional !== true) {
          newObj = JSON.parse(JSON.stringify(newObj));
          newObj.varietyId = varietyId;
        }
        return newObj;
      });
      this.listGrower.appendRows(bonusDeductList);
      this.setState({
        show: false,
      });
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          isDeduct: false,
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          varietyId: this.state.previousVarietyId,
          attribute: this.state.previousAttribute,
          applyTo: this.state.previousApplyTo,
          applyType: this.state.previousApplyType,
          min: 0,
          max: 0,
          value: 0,
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      () => {
        //Set selected dropdown
        this.attribute.setSelected(this.state.previousAttribute);
        if (this.applyTo) {
          this.applyTo.setSelected(this.state.previousApplyTo);
        }
        this.growerId.setSelected(this.state.previousGrowerId);
        if (this.varietyId) {
          this.varietyId.setSelected(this.state.previousVarietyId);
        }
        this.applyType.setSelected(this.state.previousApplyType);
        //Reload to query new item just created
        this.getAllItemsByCropYear(undefined);
      }
    );
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  doSomethingAfterGetItems = (responseData) => {
    if (responseData.cropYear) {
      const cropYearName =
        responseData.cropYear.name +
        " (" +
        responseData.cropYear.startDate +
        "-" +
        responseData.cropYear.endDate +
        ")";

      const handleTruncate = this.truncateTitle(
        cropYearName,
        this.setTitleLength()
      );

      this.setState({
        pluraltitle: "Bonuses & Deducts - Crop Year " + handleTruncate,
        cropYearName: cropYearName,
      });
    }

    this.setState(
      {
        viewData: responseData.viewData,
        growers: [],
      },
      function callBack() {
        this.refreshTable();
      }
    );
  };

  setColumns = () => {
    return [
      {
        name: "Default/Grower",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.growerId === "" ? (
            `Default`
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {`${row.grower}`}
            </a>
          );
        },
      },
    ];
  };

  defaultTable = () => {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs tabs-primary">
          <li className="active">
            <a href="#Default" data-toggle="tab">
              Adjustments
            </a>
          </li>
          <li>
            <a
              href="#Template"
              data-toggle="tab"
              onClick={() => {
                if (this.state.isLoadedTabTemplate !== true) {
                  this.template.getItems();
                  this.template.getGrowers();
                  this.setState({
                    isLoadedTabTemplate: true,
                  });
                }
              }}
            >
              Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="Default"
            className="tab-pane active"
            style={{ margin: "-10px" }}
          >
            <DataTable
              highlightOnHover
              striped
              id="emp"
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              expandableRows={this.state.expandableRows}
              expandableRowsComponent={
                <SubTable
                  handleDelete={this.handleDelete.bind(this)}
                  handleShow={this.handleShow}
                  expandableRowExpanded={(row) => {
                    return this.state.isExpand;
                  }}
                  testType={this.state.testType}
                  loggedUser={loggedUser}
                  cropYearId={this.state.parentId}
                  GlobalConfig={GlobalConfig}
                  rowPerPageOption={this.state.rowPerPageOption}
                />
              }
              conditionalRowStyles={this.conditionalRowStyles()}
              noTableHead={true}
              expandableRowExpanded={(row) => {
                return this.state.isExpand;
              }}
              pagination={true}
              paginationPerPage={this.state.paginationPerPage}
              paginationServer={true}
              paginationTotalRows={this.state.totalRows}
              paginationRowsPerPageOptions={this.state.rowPerPageOption}
              paginationDefaultPage={this.state.currentPage}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              progressPending={this.state.progressPending}
              progressComponent={this.defaultProgressComponent()}
            />
          </div>
          <div id="Template" className="tab-pane" style={{ margin: "-10px" }}>
            <Template
              onRef={(ref) => (this.template = ref)}
              cropYearId={this.props.match.params.id}
              loggedUser={loggedUser}
              processorId={loggedUser.processorId}
              testTypeId={this.state.testType._id}
              testType={this.state.testType}
              valueDecimals={parseInt(
                GlobalConfig.REACT_APP_ADJUSTMENT_DECIMALS
              )}
              valueDecimalStep={parseFloat(
                GlobalConfig.REACT_APP_ADJUSTMENT_DECIMAL_STEP
              )}
              refreshMainTab={this.refreshMainTab}
            />
          </div>
        </div>
      </div>
    );
  };

  refreshMainTab = () => {
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.getItemsServerPaging(this.state.currentPage);
      }
    );
  };

  extendButtons = () => {
    return (
      <>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>

        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <Button
              variant="primary"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  growerId: "",
                  varietyId: "",
                  attribute: "",
                  applyTo: "",
                  applyType: this.state.defaulApplyType,
                  min: 0,
                  max: 0,
                  value: 0,
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              type="button"
              className="mb-sm"
            >
              <span className="fa fa-plus fa-lg"></span> {this.state.labeladd}
            </Button>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
            <Export
              growers={this.state.items}
              cropYearName={this.state.cropYearName}
              cropYearId={this.state.parentId}
              totalRows={this.state.totalRows}
              pageSize={this.state.perPage}
            ></Export>
          </>
        )}
      </>
    );
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions" style={{ top: "0px" }}>
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  changeWizardMode = (toggle) => {
    this.setState({
      wizardMode: toggle,
    });
  };

  filterItems = ({
    testType,
    varietyIds,
    bonusDeductItems,
    bonusDeductRequest,
  }) => {
    let itemsByParent = [];
    if (bonusDeductItems && bonusDeductItems.length > 0) {
      if (testType.varietyOptional !== true) {
        itemsByParent = bonusDeductItems.filter(
          ({ varietyId = "", attribute, applyTo }) => {
            return (
              // Instead loop through all the varietyIds for checking if the varietyId is in the list
              // We can use include method to check if the varietyId is in the list
              varietyIds?.includes(varietyId?.toString()) &&
              attribute === bonusDeductRequest.attribute &&
              applyTo === bonusDeductRequest.applyTo
            );
          }
        );
      } else {
        itemsByParent = bonusDeductItems.filter(({ attribute, applyTo }) => {
          return (
            // Instead loop through all the varietyIds for checking if the varietyId is in the list
            // We can use include method to check if the varietyId is in the list
            attribute === bonusDeductRequest.attribute &&
            applyTo === bonusDeductRequest.applyTo
          );
        });
      }
    }

    return itemsByParent;
  };

  render() {
    const title =
      this.state.pluraltitle != ""
        ? this.state.pluraltitle
        : this.state.title + "s";

    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                {this.renderRefresh1()}
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              {this.state.useExtendBody == null ||
              this.state.useExtendBody == undefined ||
              this.state.useExtendBody != true ? (
                <section className="panel">
                  <div className="panel-body">
                    <div
                      className="row"
                      style={{ paddingBottom: "10px", paddingLeft: "5px" }}
                    >
                      <div className="col-sm-12">
                        <div className="row form-inline">
                          <div className="form-group">
                            {this.extendButtons()}
                            {this.renderSearch()}
                          </div>
                        </div>
                        {this.renderRefresh2()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        {this.defaultTable()}
                      </div>
                    </div>

                    {this.state.alertshow === true ? (
                      <Alert
                        message={this.state.alertmsg}
                        type={this.state.alerttype}
                        show={this.state.alertshow}
                      ></Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              ) : (
                this.extendBody()
              )}
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
}
export default App;
