import React, { useEffect, useState } from "react";
import NumericInputReact from "react-numeric-input";
const GlobalConfig = new window.globalConfig();

const TypeContainer = (props) => {
  const {
    typeNumberOfContainers,
    handleChangeNumberContainers,
    handleFocus,
    handleChangeSelectTypeContainers,
    numberOfContainers,
    className = "",
    isAllowEdit = true,
    processorId,
  } = props;

  const TYPE_CONTAINER_API_URL = processorId
    ? `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parentByName/${processorId}/Type of Container`
    : `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parentByName/Type of Container`;

  const [typeContainerList, setTypeContainerList] = useState([]);

  useEffect(() => {
    getTypeContainerList(TYPE_CONTAINER_API_URL);
  }, [TYPE_CONTAINER_API_URL]);

  const getTypeContainerList = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    if (data && data.data) {
      setTypeContainerList(data.data);
    }
  };

  return (
    <>
      <div className={`input-group ${className}`}>
        <select
          readOnly={!isAllowEdit}
          name="typeNumberOfContainers"
          className="form-control type-containers-border select-type-container-size"
          required={isAllowEdit}
          onChange={handleChangeSelectTypeContainers}
          value={typeNumberOfContainers ?? ""}
        >
          {typeContainerList?.map((typeContainer, index) => (
            <option key={index} value={typeContainer.name}>
              {typeContainer.name}
            </option>
          ))}
        </select>
        {typeNumberOfContainers === "Bin" && (
          <>
            <span className="input-group-addon">Number</span>
            <NumericInputReact
              readOnly={!isAllowEdit}
              required={typeNumberOfContainers === "Bin"}
              inputMode="numeric"
              strict
              noStyle
              title={"Max is 999999999"}
              className="form-control number-container-border"
              precision={0}
              min={0}
              max={999999999}
              step={1}
              name="numberOfContainers"
              value={numberOfContainers}
              onChange={handleChangeNumberContainers}
              onFocus={handleFocus}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TypeContainer;
