import React from "react";
import String from "../Utilities/String";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: {},
      loading: false,
      expand: true,
    };
  }

  async componentDidMount() {
    this.getItem();
  }

  async componentDidUpdate(previousProps) {
    if (
      previousProps.currentProcessorId !== this.props.currentProcessorId ||
      previousProps.currentCropYearId !== this.props.currentCropYearId
    ) {
      this.getItem();
    }
  }

  async getItem() {
    this.setState({ loading: true });
    let urlApi = GlobalConfig.REACT_APP_API_DASHBOARD_URL + "summaryadmin";

    urlApi +=
      "/" +
      (String.isEmpty(this.props.currentProcessorId)
        ? "all"
        : this.props.currentProcessorId);
    urlApi +=
      "/" +
      (String.isEmpty(this.props.currentCropYearId)
        ? "all"
        : this.props.currentCropYearId);

    const that = this;
    fetch(urlApi)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({ data: resultObject.data, loading: false });
      })
      .catch(function (error) {
        that.setState({ loading: false });
        console.log(error);
      });
  }

  onChangeExpandToggle = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  render() {
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a
              href="javascript:void(0);"
              className={`fa fa-caret-${this.state.expand ? "up" : "down"}`}
              onClick={this.onChangeExpandToggle}
            ></a>
          </div>
          <h2 className="panel-title">Summary</h2>
          {/* <p className="panel-subtitle">
            If crop year not select display from 08/01/{this.state.currentYear}{" "}
            to 07/31/{this.state.currentYear + 1}.
          </p> */}
        </header>
        <div
          className="panel-body report-panel-body"
          style={{
            maxHeight: this.state.expand ? 500 : 0,
          }}
        >
          <hr className="dotted short"></hr>
          <div className="col-md-12 col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <section className="panel panel-featured-left panel-featured-tertiary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Processors</h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalProcessor}
                              </strong>
                            )}
                          </div>
                        </div>
                        {this.state.loading === true ? (
                          ""
                        ) : this.state.data.totalProcessor <= 0 ? (
                          ""
                        ) : (
                          <div className="summary-footer">
                            <a
                              className="text-muted text-uppercase"
                              href={process.env.REACT_APP_URL_PROCESSOR_LIST}
                            >
                              <span className="text-primary">View All</span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-6 col-lg-3">
                <section className="panel panel-featured-left panel-featured-primary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-primary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Lab Users</h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalLabUser}
                              </strong>
                            )}
                          </div>
                        </div>
                        {this.state.loading === true ? (
                          ""
                        ) : this.state.data.totalLabUser <= 0 ? (
                          ""
                        ) : (
                          <div className="summary-footer">
                            <a
                              className="text-muted text-uppercase"
                              href={process.env.REACT_APP_URL_LAB_USER_LIST}
                            >
                              <span className="text-primary">View All</span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-6 col-lg-3">
                <section className="panel panel-featured-left panel-featured-secondary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-secondary">
                          <i className="fa fa-file-text-o"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">
                            {this.props.currentCropYearName &&
                            this.props.currentCropYearName !== ""
                              ? "Total Tests"
                              : "Total Tests (All Crop Year)"}
                          </h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalTestByCropYear}
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-6 col-lg-3">
                <section className="panel panel-featured-left panel-featured-quartenary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-quartenary">
                          <i className="fa fa-sign-in"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Today's Visitors</h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalVisitorToday}
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default App;
