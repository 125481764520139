import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Modal } from "react-bootstrap";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const query = new URLSearchParams(props.location.search);
    let isDynamic = query.get("isDynamic");
    this.state.parentId = props.match.params.id;
    this.state.urlapi = GlobalConfig.REACT_APP_API_VARIETY_URL;
    this.state.urllist =
      process.env.REACT_APP_URL_NUTTYPE_LIST +
      "/varieties/" +
      props.match.params.id;
    this.state.urledit =
      process.env.REACT_APP_URL_NUTTYPE_LIST + "/variety-edit/";
    this.state.urladd =
      process.env.REACT_APP_URL_NUTTYPE_LIST +
      "/variety-add/" +
      props.match.params.id;
    this.state.title = "Variety";
    this.state.pluraltitle = "Varieties";

    this.state.pageName = "Variety";
    this.state.isDynamic = isDynamic;
    this.state.data = {
      _id: "",
      parentId: props.match.params.id,
      name: "",
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };
    this.state.loading = false;
    this.state.useFilter = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  getItems = async () => {
    const that = this;
    fetch(this.state.urlapi + "/parent/" + this.state.parentId)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({ items: resultObject.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleResetForm = () => {
    this.setState({
      data: {
        id: "",
        parentId: this.state.parentId,
        // processorID pass to backend for create log audit
        name: "",
        createdUserId: loggedUser._id,
      },
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleShow = (data) => {
    if (data) {
      let convertedJSON = JSON.parse(JSON.stringify(data));
      if (data._id === "") {
        convertedJSON.createdUserId = loggedUser._id;
      } else {
        convertedJSON.modifiedUserId = loggedUser._id;
      }

      this.setState({ data: convertedJSON }, function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
          },
          function callBack() {
            //Set selected dropdown
          }
        );
      });
    } else {
      this.setState({}, function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
          },
          function callBack() {
            //Set selected dropdown
          }
        );
      });

      this.setState({
        data: Object.assign({}, this.state.data, {
          _id: "",
          processorId: loggedUser.processorId,
          name: "",
          createdUserId: loggedUser._id,
        }),
      });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  defaultButtons = () => {
    return (
      <a
        onClick={() => this.handleShow()}
        className="btn btn-primary panel-btn-group-item-position"
      >
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  handleSave = async (e) => {
    e.preventDefault();

    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }

    let urlApi = GlobalConfig.REACT_APP_API_VARIETY_URL + this.state.data._id;
    let method = "PATCH";
    if (
      this.state.data._id === null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_VARIETY_URL;
    }

    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          createdUserId: loggedUser._id,
        }),
        loading: true,
      },
      function callBack() {
        try {
          const requestOptions = {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };

          const that = this;
          fetch(urlApi, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject && resultObject.result === "OK") {
                //reset form
                if (method === "POST") {
                  that.handleResetForm();
                }

                if (that.state.isServerPaging === true) {
                  that.getItemsServerPaging(that.state.currentPage);
                } else {
                  that.getItems();
                }

                that.doSomethingAfterSaved(resultObject.data);

                if (
                  that.state.isShowSaveSuccessfulMessage == true ||
                  that.state.isShowSaveSuccessfulMessage === undefined ||
                  that.state.isShowSaveSuccessfulMessage == null
                ) {
                  window.showAlert("", "Save successful!", "");
                }
              }
              //If failed, show error
              else {
                that.doSomethingAfterErrorSaved(resultObject);
              }
              that.setState({ loading: false });
            })
            .catch(function (error) {
              window.showAlert("Error", error, "Error");
              that.setState({ loading: false });
            });
        } catch (err) {
          window.showAlert("Error", err.message, "Error");
          this.setState({ loading: false });
        }
      }
    );
  };

  extendRender = () => {
    return (
      <Modal
        // dialogAs={DraggableModalDialog}
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form onSubmit={this.handleSave} id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.data._id === "" || this.state.data._id === undefined
                ? "Add " + this.state.pageName
                : "Edit " +
                this.state.pageName +
                (this.state.data.name !== undefined
                  ? " - " + this.state.data.name
                  : "")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Name <span className="required">*</span>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder=""
                  required
                  value={this.state.data.name}
                  onChange={this.handleChangeObjectData}
                  maxLength={100}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer-test-field">
            {this.state.data ? (
              <SaveButton form={"fromPopup"} loading={this.state.loading} />
            ) : (
              ""
            )}
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          if (
            loggedUser.role === "Administrators" ||
            this.state.isDynamic === true
          ) {
            return (
              <a
                href="# "
                onClick={() => this.handleShow(row)}
                title={row.name}
              >
                {row.name}
              </a>
            );
          } else {
            return row.name;
          }
        },
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <a
              href="# "
              onClick={() => this.handleShow(row)}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
        omit:
          this.state.isDynamic.toString() !== "true" &&
          loggedUser.role !== "Administrators",
      },
    ];
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a href={process.env.REACT_APP_URL_NUTTYPE_LIST} title="Back to list">
          Test Types
        </a>
      </li>
    );
  };

  extendButtons = () => {
    return (
      <a
        href={process.env.REACT_APP_URL_NUTTYPE_LIST}
        title="Back Test Types"
        className="btn btn-primary panel-btn-group-item-position"
      >
        <i className="fa fa-backward fa-lg"></i> Back Test Types
      </a>
    );
  };
}
export default App;
