import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import TwoLabels from "./TwoLabels";
import Column from "./Column";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
} from "../Utilities/Constant";
import { ReactSortable } from "react-sortablejs";

const GlobalConfig = new window.globalConfig();
let loggedUser = {};

const defaultFirstColumn = [
  {
    name: "LOT",
  },
  {
    name: "Date",
  },
];
const defaultFirstColumnXPool = [
  {
    name: "Raw Lot #",
  },
  {
    name: "Date",
  },
];

const defaultSecondColumn = [
  {
    name: "LBS.",
  },
  {
    name: "$/LB.",
  },
  {
    name: "Payment",
    // description: "= (Payment %) * (Base Price + Adjustments) * (Weight)",
  },
  {
    name: "YTD",
  },
];

const defaultSecondColumnMultiPrices = [
  {
    name: "LBS.",
  },
  {
    name: "Payment",
    // description: "= (Payment %) * (Base Price + Adjustments) * (Weight)",
  },
  {
    name: "YTD",
  },
];

const defaultSecondColumnXPool = [
  {
    name: "Process Weight",
  },
  {
    name: "Regular Pool %",
  },
  {
    name: "Regular Pool Weight",
  },
  {
    name: "Price Per Pound",
  },
  {
    name: "XPool %",
  },
  {
    name: "XPool Weight",
  },
  {
    name: "Price Per Pound",
  },
  {
    name: "Amount Due",
    description:
      "= (Process Weight * Regular Pool % * Price Per Pound) + (Process Weight * XPool % * Price Per Pound)",
  },
  {
    name: "Payment",
    description: "= (Payment %) * Amount Due",
  },
  {
    name: "YTD",
  },
];

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state = {
      listtitle: "Test Types",
      urllist: process.env.REACT_APP_URL_NUTTYPE_LIST,
      urlapi: GlobalConfig.REACT_APP_API_GROWER_STATEMENT_FIELD_URL,
      errorMessage: "",
      isChanged: false,
      data: {
        _id: props.match.params.id,
        name: "",
        fields: [],
        selectedFields: [],
        xpool: false,
        multiPricesSameLot: false,
      },
      empty: "",
    };
  }

  componentDidUpdate = () => {
    // if user dragged and dropped the field
    if (this.state.isChanged) {
      // warn user will lose the changes
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  };

  defaultTitle = () => {
    return "Grower Statement Design - " + this.getTitle();
  };

  getTitle = () => {
    return this.state.data.name;
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row footer-button-group-container">
          {this.state.data.processorId !== loggedUser.processorId &&
          loggedUser.role !== USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <button
              type="submit"
              className="btn btn-success"
              style={{ marginLeft: "0px" }}
            >
              <i className="fa fa-save fa-lg"></i> Save
            </button>
          )}
          {this.state.urllist === null ||
          this.state.urllist === undefined ||
          this.state.urllist === "" ? (
            ""
          ) : (
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          )}
          {this.extendButtons()}
        </div>
      </footer>
    );
  };

  handleSelectFields(newState) {
    let combineSelectedField = [];

    newState.map((item) => {
      return combineSelectedField.push({
        _id: item._id,
        nutTypeId: this.state.data._id,
        nutTypeFieldId: item._id,
        isDynamicField: item.isDynamicField,
        testFieldId: item.testFieldId,
        sequence: item.sequence,
        name: item.name,
        displayName: item.displayName,
      });
    });

    this.setState({
      data: Object.assign({}, this.state.data, {
        selectedFields: combineSelectedField,
      }),
    });
  }

  handleRemoveField = (id) => {
    const selectedFields = this.state.data.selectedFields.filter((x) => {
      return x._id !== id;
    });

    const revertField = this.state.data.selectedFields.filter((x) => {
      return x._id === id;
    });
    this.setState({
      data: Object.assign({}, this.state.data, {
        selectedFields: selectedFields,
        fields: [revertField[0], ...this.state.data.fields],
      }),
      empty: "",
      isChanged: true,
    });
  };

  handleChangeEmptyDnd = () => {
    if (this.state.data.selectedFields.length > 0) {
      this.setState({
        empty: "",
        isChanged: true,
      });
    } else {
      this.setState({
        empty: "-disable",
        isChanged: true,
      });
    }
  };

  handleUnchooseDnd = () => {
    this.setState({
      empty: "",
      isChanged: true,
    });
  };

  hanldeRemoveAll = () => {
    const revertField = this.state.data.selectedFields;
    this.setState({
      data: Object.assign({}, this.state.data, {
        selectedFields: [],
        fields: revertField.concat(this.state.data.fields),
      }),
      empty: "",
      isChanged: true,
    });
  };

  hanldeSelectAll = () => {
    let combineSelectedField = [];

    this.state.data.fields.map((item) => {
      return combineSelectedField.push({
        _id: item._id,
        nutTypeId: this.state.data._id,
        nutTypeFieldId: item._id,
        isDynamicField: item.isDynamicField,
        testFieldId: item.testFieldId,
        sequence: item.sequence,
        name: item.name,
        displayName: item.displayName,
      });
    });

    this.setState({
      data: Object.assign({}, this.state.data, {
        selectedFields:
          this.state.data.selectedFields.concat(combineSelectedField),
        fields: [],
      }),
      isChanged: true,
    });
  };

  renderSecondDefaultColumns = () => {
    let columns = [];
    if (this.state.data.xpool === true) {
      columns = defaultSecondColumnXPool;
    }
    if (this.state.data.multiPricesSameLot === true) {
      columns = defaultSecondColumnMultiPrices;
    } else {
      columns = defaultSecondColumn;
    }
    return columns.map((item, index) => (
      <Column
        key={index}
        id={item._id}
        name={item.name}
        description={item.description}
        disableRemove={true}
        defaultData={true}
        disableMove={true}
      />
    ));
  };

  renderForm = () => {
    return (
      <div className="panel-body panel-body-spacing">
        {this.state.data.isDynamic ? (
          <>
            <div className="col-lg-12 mt-sm">
              <div className="row">
                <section className="panel-header-color">
                  <header className="panel-heading-custom panelNutType panel-heading">
                    <h2 className="panel-title panel-title-main">Header</h2>
                    <div className="panel-group-action"></div>
                  </header>
                  <div id="header" className="panel-collapse collapse in">
                    <div className="panel-body panel-body-success panel-body-spacing">
                      <fieldset>
                        <TwoLabels
                          label1="Grower Account:"
                          label2="Display name of grower"
                        />
                        <TwoLabels
                          label1="Payment No:"
                          label2="Display no of payment"
                        />
                        <TwoLabels
                          label1="Payment Date:"
                          label2="Display due date of payment"
                        />
                        <TwoLabels
                          label1="Payment %:"
                          label2="Display payment percent of payment"
                        />
                        {/* Total Percent: display total of current and previous percent */}
                      </fieldset>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div>
                  <div className="col-md-6 mt-sm">
                    <div className="row pr-xs">
                      <section className="panel-column-display-color">
                        <header className="panel-heading-custom panel-nut-type panel-heading">
                          <h2 className="panel-title panel-title-main">
                            Columns Display
                            {this.state.data.selectedFields.length > 0
                              ? ` (${this.state.data.selectedFields.length})`
                              : ""}
                          </h2>
                        </header>
                        <div className="panel-collapse collapse in">
                          <div
                            className="panel-body panel-body-custom panel-body-spacing"
                            style={{
                              boxShadow: "inset 0px 0px 0px 1px #45b69c52",
                              color: "white",
                            }}
                          >
                            <div className="panel-dnd-default-item">
                              {(this.state.data.xpool === true
                                ? defaultFirstColumnXPool
                                : defaultFirstColumn
                              ).map((item, index) => (
                                <Column
                                  key={index}
                                  id={item._id}
                                  name={item.name}
                                  disableRemove={true}
                                  defaultData={true}
                                  disableMove={true}
                                />
                              ))}
                            </div>
                            <ReactSortable
                              list={this.state.data.selectedFields}
                              setList={(newState) =>
                                this.handleSelectFields(newState)
                              }
                              group="shared"
                              animation={200}
                              delay={2}
                              className="react-dnd-container"
                              onChange={this.handleChangeEmptyDnd}
                            >
                              {this.state.data.selectedFields.length > 0 ? (
                                this.state.data.selectedFields.map(
                                  (item, index) => (
                                    <Column
                                      key={index}
                                      id={item._id}
                                      color="#4453f9"
                                      name={item.name}
                                      handleRemoveField={this.handleRemoveField}
                                    />
                                  )
                                )
                              ) : (
                                <div
                                  className={`panel-dnd-empty${this.state.empty}`}
                                >
                                  Drop fields here!
                                </div>
                              )}
                            </ReactSortable>

                            <div className="panel-dnd-default-item">
                              {this.renderSecondDefaultColumns()}
                            </div>
                          </div>
                        </div>
                      </section>
                      <div className="row panel-dnd-btn-group">
                        {this.state.data.selectedFields.length <= 0 ? (
                          <div className="btn-disable">
                            <button
                              type="button"
                              onClick={this.hanldeRemoveAll}
                              className="btn btn-remove-all-display-color ml-none mt-xs"
                              disabled
                            >
                              Remove All
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={this.hanldeRemoveAll}
                            className="btn btn-remove-all-display-color ml-none mt-xs"
                          >
                            Remove All
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-sm">
                    <div className="row pl-xs">
                      <section className="panel-fields-color">
                        <header className="panel-heading-custom panel-nut-type panel-heading">
                          <h2 className="panel-title panel-title-main">
                            Fields
                            {this.state.data.fields.length > 0
                              ? ` (${this.state.data.fields.length})`
                              : ""}
                          </h2>
                        </header>
                        <div className="panel-collapse collapse in">
                          <div
                            className="panel-body panel-body-custom panel-body-spacing"
                            style={{
                              boxShadow: "inset 0px 0px 0px 1px #7783a352",
                              color: "white",
                            }}
                          >
                            <ReactSortable
                              list={this.state.data.fields}
                              setList={(newState) =>
                                this.setState({
                                  data: Object.assign({}, this.state.data, {
                                    fields: newState,
                                  }),
                                })
                              }
                              group={{
                                name: "shared",
                                put: false,
                              }}
                              animation={200}
                              delay={2}
                              sort={false}
                              className="react-dnd-container-main"
                              onUnchoose={this.handleUnchooseDnd}
                            >
                              {this.state.data.fields.length > 0 ? (
                                this.state.data.fields.map((item, index) => (
                                  <Column
                                    key={index}
                                    id={item._id}
                                    color="#4453f9"
                                    name={item.name}
                                    disableRemove={true}
                                  />
                                ))
                              ) : (
                                <div className="panel-dnd-empty">
                                  {this.state.data.fields.length <= 0 &&
                                  this.state.data.selectedFields.length <= 0 ? (
                                    <>
                                      <div>Not found any field!</div>
                                      <div className="mt-sm">
                                        Please setup test type then try again!
                                      </div>
                                    </>
                                  ) : (
                                    "All fields selected."
                                  )}
                                </div>
                              )}
                            </ReactSortable>
                          </div>
                        </div>
                      </section>
                      <div className="row panel-dnd-btn-group">
                        {this.state.data.fields.length <= 0 ? (
                          <div className="btn-disable">
                            <button
                              type="button"
                              onClick={this.hanldeSelectAll}
                              className="btn btn-select-all-display-color ml-none mt-xs"
                              disabled
                            >
                              Select All
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={this.hanldeSelectAll}
                            className="btn btn-select-all-display-color ml-none mt-xs"
                          >
                            Select All
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };
}
export default App;
