import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import AddFormTestField from "./form";
import SaveButton from "../Controls/SaveButton";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
    };
  }

  handleSubmit = async (e) => {
    await this.refFormTestField.handleSubmit(e);
  };

  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleCloseAllPopup}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Add Field</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => this.handleSubmit(e)}
              id="formTestField"
              className="form-horizontal"
            >
              <AddFormTestField
                handleCloseForm={this.props.handleClosePopupCreateTestField}
                onRef={(ref) => (this.refFormTestField = ref)}
                isCalledFromPopup={true}
                currentSection={this.props.currentSection}
                setLoading={this.setLoading}
              />
            </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer-center">
            <SaveButton form={"formTestField"} loading={this.state.loading} />

            <Button
              variant="warning"
              onClick={this.props.handleClosePopupCreateTestField}
            >
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
export default App;
