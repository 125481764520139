import React from "react";
import Select from "react-select";
import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_CALCULATED,
  SECTION_QUALITY_DATA,
  SECTION_META_DATA,
} from "../Utilities/Constant";
import FilterMultiSelectControlFromSystemSetting from "../FormControl/FilterMultiSelectControlFromSystemSetting";
export default class App extends FilterMultiSelectControlFromSystemSetting {
  constructor(props) {
    super(props);
  }

  filterTestFieldTypes = (section, fieldTypes) => {
    if (fieldTypes && fieldTypes.length > 0) {
      let filterTestFieldTypes = [];
      const numericTypes = [
        FIELD_TYPE_INTEGER,
        FIELD_TYPE_DECIMAL,
        FIELD_TYPE_CALCULATED,
        FIELD_TYPE_PERCENT,
      ];
      switch (section) {
        case SECTION_META_DATA:
          const notNumericFieldTypes = fieldTypes.filter(
            (f) => !numericTypes.includes(f._id) // get field Types which is not numeric
          );
          filterTestFieldTypes = notNumericFieldTypes;
          break;
        case SECTION_QUALITY_DATA:
          const numericFieldTypes = fieldTypes.filter(
            (f) => numericTypes.includes(f._id) // get field Types which is numeric
          );
          filterTestFieldTypes = numericFieldTypes;
          break;
        default:
          filterTestFieldTypes = fieldTypes;
      }
      return filterTestFieldTypes;
    } else {
      return [];
    }
  };

  render() {
    return (
      <>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          closeMenuOnSelect={false}
          isMulti
          name={this.state.controlId}
          onChange={this.handleChangeSelect}
          placeholder={this.state.placeholder}
          value={this.state.optionSelected}
          classNamePrefix={"MyDropdown"}
          onMenuOpen={this.onMenuOpen}
          options={
            this.state.data
              ? this.filterTestFieldTypes(
                  this.props.currentSection,
                  this.state.data
                ).map((item) => {
                  return {
                    value: item._id,
                    label: item.name,
                  };
                })
              : ""
          }
        />
      </>
    );
  }
}
