import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import DateFormat from "../Utilities/DateFormat";
import CommonUtilities from "../Utilities/Common";
import { Button, Form, Modal } from "react-bootstrap";
import ExportExcel from "../Utilities/ExportExcel";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";

var DateFormatFormat = require("dateformat");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state.pluraltitle =
      loggedUser.role != "Processors" ? "Bills" : "Bill History";
    this.state.title =
      loggedUser.role != "Processors" ? "Bill" : "Bill History";
    this.state.urlapi =
      loggedUser.role == "Processors"
        ? GlobalConfig.REACT_APP_API_BILL_URL +
        "byprocessor/" +
        loggedUser.processorId
        : GlobalConfig.REACT_APP_API_BILL_URL;
    this.state.urllist = process.env.REACT_APP_URL_BILL_LIST;

    this.state.filterBillStatus = "";
    this.state.filterStartDate = "";
    this.state.filterEndDate = ""; //moment(new Date()).format("MM/DD/YYYY");
    this.state.filterProcessor = "";
    this.state.dataProcessors = [];

    this.state.countPaid = 0;
    this.state.countUnpaid = 0;
    this.state.totalPaid = 0;
    this.state.totalUnpaid = 0;

    this.state.startDate = "";
    this.state.endDate = "";

    this.state.selectedId = "";
    this.state.status = "";

    this.state.pageName = "Bill";

    this.state.useFilter = loggedUser.role === "Administrators" ? true : false;
    this.state.childOverrideComponentDidMount = true;

    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Due Date",
        selector: "date",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.date ? (
            <DateFormat date={row.date} format="MM/DD/YYYY"></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Billing Period",
        selector: "billPeriod",
        center: true,
        sortable: true,
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.amount == null || row.amount == undefined || isNaN(row.amount)
              ? 0
              : row.amount
            ).toFixed(1)
          ),
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        sortable: true,
        cell: (row) => {
          return this.formatStatus(row);
        },
      },
      {
        name: "Tier",
        selector: "tierName",
        sortable: true,
        cell: (row) =>
          loggedUser.role == "Administrators" ? (
            <a
              href={process.env.REACT_APP_URL_TIER_LIST + "/" + row.tierId}
              title="Click here to view tier information"
              target="_blank"
            >
              {row.tierName}
            </a>
          ) : (
            <a
              href="#"
              className="on-default edit-row"
              data-id={row._id}
              title="Click here to view detail of tier."
              data-action="ViewTier"
              data-numberoftestsdeliveries={row.numberOfTestsDeliveries}
              data-totalmonthly={row.totalMonthly}
              data-isexporttopdf={row.isExportToPdf}
              onClick={this.handleShow.bind(this)}
            >
              {row.tierName}
            </a>
          ),
      },
      {
        omit: loggedUser.role != "Administrators",
        name: "Processor Name",
        selector: "processorName",
        sortable: true,
        cell: (row) => (
          <a
            href={
              process.env.REACT_APP_URL_PROCESSOR_LIST + "/" + row.processorId
            }
            title="Click here to view processor information"
            target="_blank"
          >
            {row.processorName}
          </a>
        ),
      },
      {
        omit: loggedUser.role != "Administrators",
        name: "Contact Name",
        selector: "processorContactName",
        left: true,
        sortable: true,
      },
      {
        omit: loggedUser.role != "Administrators",
        name: "Contact Email",
        selector: "processorEmail",
        left: true,
        sortable: true,
      },
      {
        omit: loggedUser.role != "Administrators",
        name: "",
        sortable: true,
        cell: (row) => {
          return row.status == "Unpaid" ? (
            <>
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row._id
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="#"
                className="on-default edit-row"
                data-id={row._id}
                data-status="Paid"
                title="Click here to update payment status."
                onClick={this.handleUpdateStatus.bind(this)}
              >
                Mark Paid
              </a>
            </>
          ) : (
            <>
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row._id
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                href="#"
                className="on-default edit-row"
                data-id={row._id}
                data-status="Unpaid"
                title="Click here to update payment status."
                onClick={this.handleUpdateStatus.bind(this)}
              >
                Revert Unpaid
              </a>
            </>
          );
        },
      },
    ];
  };

  formatStatus = (row) => {
    if (row.status == "Paid") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.status}
      </span>
    );
  };

  renderSearch = () => {
    return loggedUser.role != "Administrators" ? (
      <>
        {this.state.originalItems && this.state.originalItems.length > 0 ? (
          <div className="form-group">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">
                  <strong>Total: </strong>
                </label>
                {this.state.totalPaid < 0 ? (
                  ""
                ) : (
                  <span
                    className="label label-primary"
                    style={{ marginLeft: "10px", fontSize: "13px" }}
                  >
                    $
                    {CommonUtilities.numberWithCommas(
                      parseFloat(
                        this.state.totalPaid == null ||
                          this.state.totalPaid == undefined ||
                          this.state.totalPaid == "" ||
                          isNaN(this.state.totalPaid)
                          ? 0
                          : this.state.totalPaid
                      ).toFixed(1)
                    )}{" "}
                    Paid
                  </span>
                )}{" "}
                {this.state.totalUnpaid <= 0 ? (
                  ""
                ) : (
                  <span
                    className="label label-danger"
                    style={{ marginLeft: "10px", fontSize: "13px" }}
                  >
                    $
                    {CommonUtilities.numberWithCommas(
                      parseFloat(
                        this.state.totalUnpaid == null ||
                          this.state.totalUnpaid == undefined ||
                          this.state.totalUnpaid == "" ||
                          isNaN(this.state.totalUnpaid)
                          ? 0
                          : this.state.totalUnpaid
                      ).toFixed(1)
                    )}{" "}
                    Unpaid
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    ) : (
      <>
        <div className="col-md-6 col-lg-3 select-render-search">
          <select
            placeholder="-- Select Status --"
            name="filterBillStatus"
            className="form-control select-config-size"
            value={this.state.filterBillStatus}
            onChange={this.handleChange}
          >
            <option value="">All Status</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
          </select>
        </div>

        <div className="col-md-6 col-lg-3 select-render-search">
          <select
            placeholder="-- Select Processor --"
            name="filterProcessor"
            className="form-control select-config-size"
            value={this.state.filterProcessor}
            onChange={this.handleChange}
          >
            <option value="">All Processors</option>
            {this.state.dataProcessors
              ? this.state.dataProcessors.map((item, key) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })
              : ""}
          </select>
        </div>

        <div className="col-md-12 col-lg-3 select-render-search">
          <div className="inputDateTimeContainer">
            <div className="input-group inputDateTime">
              <span className="input-group-addon">
                <i className="fa fa-calendar"></i>
              </span>
              <DatePicker
                className="form-control inputRenderSearch"
                placeholderText="Start date"
                selected={this.state.startDate}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onBlur={this.handleOnBlur}
              />
            </div>
            <div className="input-group">
              <span className="input-group-addon">to</span>
              <DatePicker
                className="form-control inputRenderSearch"
                placeholderText="End date"
                selected={this.state.endDate}
                onChange={this.handleEndDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                onBlur={this.handleOnBlur}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-3 select-render-search">
          <div className="mb-sm">
            {this.state.countPaid === 0 && this.state.countUnpaid === 0 ? (
              ""
            ) : (
              <label className="m-none">
                <strong>Status: </strong>
              </label>
            )}
            {this.state.countPaid <= 0 ? (
              ""
            ) : (
              <span
                className="label label-success"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {this.state.countPaid} Paid
              </span>
            )}{" "}
            {this.state.countUnpaid <= 0 ? (
              ""
            ) : (
              <span
                className="label label-warning"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {this.state.countUnpaid} Unpaid
              </span>
            )}
          </div>
          <div className="mb-sm">
            <label className="m-none">
              <strong>Total: </strong>
            </label>
            {this.state.totalPaid < 0 ? (
              ""
            ) : (
              <span
                className="label label-primary"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                $
                {CommonUtilities.numberWithCommas(
                  parseFloat(
                    this.state.totalPaid == null ||
                      this.state.totalPaid == undefined ||
                      this.state.totalPaid == "" ||
                      isNaN(this.state.totalPaid)
                      ? 0
                      : this.state.totalPaid
                  ).toFixed(1)
                )}{" "}
                Paid
              </span>
            )}{" "}
            {this.state.totalUnpaid < 0 ? (
              ""
            ) : (
              <span
                className="label label-danger"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                $
                {CommonUtilities.numberWithCommas(
                  parseFloat(
                    this.state.totalUnpaid == null ||
                      this.state.totalUnpaid == undefined ||
                      this.state.totalUnpaid == "" ||
                      isNaN(this.state.totalUnpaid)
                      ? 0
                      : this.state.totalUnpaid
                  ).toFixed(1)
                )}{" "}
                Unpaid
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // check date < 10 to add leading zeros
    const getDate = date.getDate().toString();
    const dateCheck = getDate[1] ? getDate : "0" + getDate[0];

    // check month < 10 to add leading zeros
    const getMonth = date.getMonth() + 1;
    const monthString = getMonth.toString();
    const monthCheck = monthString[1] ? monthString : "0" + monthString[0];

    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return monthCheck + "/" + dateCheck + "/" + date.getFullYear();
    }
  };

  handleStartDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterStartDate: getDate,
        startDate: date,
      });
    } else {
      this.setState({
        filterStartDate: "",
        startDate: "",
      });
    }
  };

  handleEndDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterEndDate: getDate,
        endDate: date,
      });
    } else {
      this.setState({
        filterEndDate: "",
        endDate: "",
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      return;
    }
  }

  filterItems = () => {
    try {
      if (this.state.originalItems) {
        const startDate = this.state.filterStartDate;
        const endDate = this.state.filterEndDate;
        const filterBillStatus = this.state.filterBillStatus;
        const filterProcessor = this.state.filterProcessor;

        if (
          (startDate == null || startDate == undefined || startDate == "") &&
          (endDate == null || endDate == undefined || endDate == "") &&
          (filterBillStatus == null ||
            filterBillStatus == undefined ||
            filterBillStatus == "") &&
          (filterProcessor == null ||
            filterProcessor == undefined ||
            filterProcessor == "")
        ) {
          this.setState({ items: this.state.originalItems }, function () {
            this.updateCount();
          });
        } else {
          const valueStart =
            startDate == ""
              ? 0
              : parseInt(DateFormatFormat(startDate, "yyyymmdd"));
          const valueEnd =
            endDate == ""
              ? Number.MAX_VALUE
              : parseInt(DateFormatFormat(endDate, "yyyymmdd"));
          const newItems = [];
          this.state.originalItems.filter(function (memItem) {
            const valueDueDate = parseInt(
              DateFormatFormat(memItem.date, "yyyymmdd")
            );
            if (
              valueDueDate >= valueStart &&
              valueDueDate <= valueEnd &&
              (filterBillStatus == null ||
                filterBillStatus == undefined ||
                filterBillStatus == "" ||
                filterBillStatus.indexOf(memItem.status) != -1) &&
              (filterProcessor == null ||
                filterProcessor == undefined ||
                filterProcessor == "" ||
                filterProcessor == memItem.processorId)
            ) {
              newItems.push(memItem);
              return memItem;
            }
          });
          this.setState({ items: newItems }, function () {
            this.updateCount();
          });
        }
      }
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleReset = () => {
    this.setState(
      {
        filterBillStatus: "",
        filterStartDate: "",
        startDate: "",
        filterEndDate: "",
        endDate: "",
        filterProcessor: "",
      },
      () => this.filterItems()
    );
  };

  async componentDidMountChild() {
    this.getItems();
    this.getProcessors();
    this.loadColumnSettings();
  }

  doSomethingAfterGetItems = async () => {
    this.filterItems();
    this.updateCount();
  };

  getProcessors = () => {
    const that = this;
    fetch(GlobalConfig.REACT_APP_API_PROCESSOR_URL)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({
          dataProcessors: resultObject.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateCount = async () => {
    let countPaid = 0;
    let countUnpaid = 0;
    let totalPaid = 0;
    let totalUnpaid = 0;

    if (this.state.items) {
      this.state.items.filter(function (u) {
        if (u.status == "Paid") {
          countPaid++;
          totalPaid += u.amount;
        }
      });

      this.state.items.filter(function (u) {
        if (u.status == "Unpaid") {
          countUnpaid++;
          totalUnpaid += u.amount;
        }
      });

      this.setState({
        countPaid: countPaid,
        countUnpaid: countUnpaid,
        totalPaid: totalPaid,
        totalUnpaid: totalUnpaid,
      });
    } else {
      this.setState({
        countPaid: 0,
        countUnpaid: 0,
        totalPaid: 0,
        totalUnpaid: 0,
      });
    }
  };

  handleUpdateStatus = (e) => {
    const selectedId = e.currentTarget.dataset.id;
    const status = e.currentTarget.dataset.status;

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status,
        modifiedUserId: loggedUser._id,
      }),
    };
    const that = this;
    fetch(
      GlobalConfig.REACT_APP_API_BILL_URL + "updatestatus/" + selectedId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          window.showAlert("", "Payment status is updated successfully.", "");
          that.getItems();
          that.setState({ show: false });
        } else {
          that.setState({ errorMessage: resultObject.message });
        }
      })
      .catch(function (error) {
        that.setState({ errorMessage: error });
      });
  };

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form>
          <Modal.Header closeButton={false}>
            <Modal.Title>Tier Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  Tests (Deliveries)
                </label>
                <div className="col-sm-4">
                  <span>
                    <strong>{this.state.numberOfTestsDeliveries}</strong>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">Total Monthly</label>
                <div className="col-sm-4">
                  <span>
                    <strong>
                      $
                      {CommonUtilities.numberWithCommas(
                        parseFloat(
                          this.state.totalMonthly == null ||
                            this.state.totalMonthly == undefined ||
                            this.state.totalMonthly == "" ||
                            isNaN(this.state.totalMonthly)
                            ? 0
                            : this.state.totalMonthly
                        ).toFixed(1)
                      )}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  Allow Export to PDF
                </label>
                <div className="col-sm-4">
                  <span>
                    <strong>{this.state.isExportToPdf ? "Yes" : "No"}</strong>
                  </span>
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-ban"> Close</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  defaultButtons = () => {
    return loggedUser.role === "Administrators" ? (
      <div className="panel-btn-group-item-position">
        <ExportExcel
          csvData={this.getItemsForExport()}
          fileName="Bills"
          buttonLabel="Export"
          buttonClassName="btn btn-warning "
        />
      </div>
    ) : (
      ""
    );
  };

  getItemsForExport = () => {
    const parsedItems = [];
    if (this.state.items) {
      this.state.items.map((item) => {
        parsedItems.push({
          "Due Date": moment(item.date).format("MM/DD/YYYY"),
          "Billing Period": item.billPeriod,
          Amount:
            "$" +
            CommonUtilities.numberWithCommas(
              (item.amount == null ||
                item.amount == undefined ||
                isNaN(item.amount)
                ? 0
                : item.amount
              ).toFixed(1)
            ),
          Status: item.status,
          Tier: item.tierName,
          "Processor Name": item.processorName,
          "Contact Name": item.processorContactName,
          "Contact Email": item.processorEmail,
        });
      });
    }
    return parsedItems;
  };

  handleShow = (e) => {
    const action = e.currentTarget.dataset.action;
    this.setState({
      numberOfTestsDeliveries: e.currentTarget.dataset.numberoftestsdeliveries,
      totalMonthly: e.currentTarget.dataset.totalmonthly,
      isExportToPdf: e.currentTarget.dataset.isexporttopdf,
      show: true,
      errorMessage: "",
      selectedSubscriptionId: e.currentTarget.dataset.id,
      popupAction: action,
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
}
export default App;
