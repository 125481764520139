import { Button, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import { useState, useEffect } from "react";
import React from "react";
import SelectControl from "../FormControl/SelectControl";
import CommonUtilities from "../Utilities/Common";
import { renderFieldByType } from "../TestField/TestFieldUtilities";
import MultiQualityData from "./MultiQualityData";
import String from "../Utilities/String";
import {
  SECTION_META_DATA,
  SECTION_QUALITY_DATA,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_CALCULATED,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  METHOD_PATCH,
} from "../Utilities/Constant";
import _ from "lodash";
import Equation from "equations";
import { getLoggedUser } from "./../../context/auth";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const BulkUpdateTestsPopup = (props) => {
  const numberTypes = [
    FIELD_TYPE_DECIMAL,
    FIELD_TYPE_INTEGER,
    FIELD_TYPE_PERCENT,
  ];
  loggedUser = getLoggedUser();
  const {
    selectedRows,
    filterItems,
    setClearSelectedRows,
    setSelectedRows,
    testTypeId,
    processorId,
    isShowOverridePrice,
    existBulkUpdateField,
  } = props;
  //States
  const [show, setShow] = useState(false);
  const [overridePrice, setOverridePrice] = useState(0);
  const [tempField, setTempField] = useState(null);
  const [allowUpdateTempField, setUpdateRules] = useState(null);
  const [calculatedFields, setCalculatedFields] = useState([]);
  const [numberFields, setNumberFields] = useState([]);
  const [fieldsToCalculate, setFieldsToCalculate] = useState([]);
  const [qualityData, setQualityData] = useState([]);
  const [allowUpdateOverridePrice, setAllowUpdateOverridePrice] =
    useState(false);
  const [combineFields, setCombineFields] = useState([]);
  const [testTypeSetting, setTestTypeSetting] = useState({});
  const [loading, setLoading] = useState(false);
  const [isExistAllowBulkUpdateField, setIsExistAllowBulkUpdateField] =
    useState(false);
  const [
    isExistAllowBulkUpdateAndIsCalculatedField,
    setIsExistAllowBulkUpdateAndIsCalculatedField,
  ] = useState(false);

  useEffect(() => {
    if (!String.isEmpty(processorId) && !String.isEmpty(testTypeId)) {
      getTestTypeSetting();
    }
  }, [processorId, testTypeId]);

  useEffect(() => {
    if (
      testTypeSetting &&
      testTypeSetting.allFields &&
      testTypeSetting.allFields.length > 0 &&
      testTypeSetting.selectedFields &&
      testTypeSetting.selectedFields.length > 0
    ) {
      const combineFields = CommonUtilities.handleCombineFields(
        testTypeSetting.selectedFields,
        testTypeSetting.allFields
      );
      setCombineFields(combineFields);
      //If test type is multi tests per lot,then tempField includes fields in metadata only
      if (testTypeSetting.multiTestsPerLot === true) {
        initTempFieldWithoutFieldsInQualityData(combineFields); // Init temp field for updating
      } else {
        initTempField(); // Init temp field for updating
      }
    }
  }, [testTypeSetting]);

  useEffect(() => {
    if (
      show &&
      testTypeSetting &&
      testTypeSetting.multiTestsPerLot === false &&
      isExistAllowBulkUpdateField === true &&
      isExistAllowBulkUpdateAndIsCalculatedField === true
    ) {
      updateCalculatedFields(numberFields);
      setFieldsToCalculate(numberFields);
    }
  }, [
    show,
    isExistAllowBulkUpdateField,
    isExistAllowBulkUpdateAndIsCalculatedField,
  ]);

  useEffect(() => {
    if (testTypeSetting) {
      onlyFields(FIELD_TYPE_CALCULATED); // Init calculated fields
      onlyFields("NUMBER"); // Init number fields
    }
  }, [testTypeSetting]);

  useEffect(() => {
    setIsExistAllowBulkUpdateField(
      checkExistAllowBulkUpdateField(combineFields)
    );
    setIsExistAllowBulkUpdateAndIsCalculatedField(
      checkExistAllowBulkUpdateAndIsCalculatedField(combineFields)
    );
  }, [combineFields]);

  const getTestTypeSetting = async () => {
    fetch(
      `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}getByProcessorAndId/${processorId}/${testTypeId}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        setTestTypeSetting(resultObject.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const initTempField = () => {
    testTypeSetting?.allFields.map((field) => {
      if (field.allowBulkUpdate) {
        setTempField((previousField) => {
          return {
            ...previousField,
            [field._id]: field.defaultValue,
          };
        });
        setUpdateRules((previousField) => {
          return {
            ...previousField,
            [field._id]: false,
          };
        });
      }
    });
  };

  const initTempFieldWithoutFieldsInQualityData = (combineFields) => {
    const onlyMetaDataFields = combineFields.filter(
      (field) => field.section !== SECTION_QUALITY_DATA
    );
    onlyMetaDataFields.map((field) => {
      if (field.allowBulkUpdate) {
        setTempField((previousField) => {
          return {
            ...previousField,
            [field._id]: field.defaultValue,
          };
        });
        setUpdateRules((previousField) => {
          return {
            ...previousField,
            [field._id]: false,
          };
        });
      }
    });
  };

  const onlyFields = (fieldType) => {
    if (
      testTypeSetting &&
      testTypeSetting.allFields &&
      testTypeSetting.allFields.length > 0
    ) {
      switch (fieldType) {
        case FIELD_TYPE_CALCULATED: {
          const calculatedFields = testTypeSetting.allFields.filter(
            (field) => field.fieldType === FIELD_TYPE_CALCULATED
          );
          setCalculatedFields(calculatedFields);
          break;
        }
        case "NUMBER": {
          const numberFields = testTypeSetting.allFields.filter(
            (field) =>
              field.fieldType === FIELD_TYPE_INTEGER ||
              field.fieldType === FIELD_TYPE_DECIMAL ||
              field.fieldType === FIELD_TYPE_PERCENT
          );
          setNumberFields(numberFields);
          break;
        }
        default:
      }
    }
  };

  const isFieldToCalculate = (_idField) => {
    if (testTypeSetting) {
      const afterFilter = testTypeSetting.allFields.filter(
        (field) =>
          field._id === _idField && numberTypes.includes(field.fieldType)
      );
      if (afterFilter.length > 0) return true;
      else return false;
    }
  };
  //Use to decide render section or not, then decide to render suitable popup
  const checkExistAllowBulkUpdateField = (fields) => {
    if (!fields.length > 0) return false;
    return fields.some((field) => field.allowBulkUpdate === true);
  };
  //Use to render section that includes allow bulk update field
  const checkSectionExistAllowBulkUpdateField = (fields, section) => {
    return fields.some(
      (field) => field.section === section && field.allowBulkUpdate === true
    );
  };

  const calculate = (_idField, newValue) => {
    // Prepare a new fields with new value for calculate
    const newFieldsToCalculate = fieldsToCalculate.map((field) => {
      let newField;
      if (field._id === _idField) {
        newField = {
          ...field,
          defaultValue: newValue,
        };
      } else {
        newField = field;
      }
      return newField;
    });
    //Calculate
    updateCalculatedFields(newFieldsToCalculate);
    setFieldsToCalculate(newFieldsToCalculate);
  };

  const updateCalculatedFields = (newFieldsToCalculate) => {
    if (calculatedFields && calculatedFields.length > 0) {
      let updatedObjects = {};
      calculatedFields.map((f) => {
        const calculatedValue =
          CommonUtilities.updateCalculatedFieldQualityData(
            newFieldsToCalculate,
            f,
            Equation
          );
        updatedObjects = JSON.parse(JSON.stringify(updatedObjects));
        updatedObjects[[f._id]] = calculatedValue;
      });
      setTempField((previousTemp) =>
        Object.assign({}, previousTemp, updatedObjects)
      );
    }
  };

  const getStringCustomFields = () => {
    let stringCustomFields = "";
    if (tempField) {
      const arrayPropertiesOfTemField = Object.keys(tempField)
        .map((property) => {
          return property;
        })
        .filter(
          (tempFieldProperty) =>
            allowUpdateTempField[tempFieldProperty] === true
        ); // We use filter to remove fields that aren't allowed to bulk update
      stringCustomFields = arrayPropertiesOfTemField
        .map((property) => property)
        .join(`,`);
    }
    return stringCustomFields;
  };

  const initDataToSend = () => {
    const ids = selectedRows.map(({ _id }) => _id).join(`,`);
    let object = {};
    //If test type setting is multi tests per lot.
    //Then customFields property only includes id of fields in metadata as same as properties of tempField.
    //Else customFields property includes all of id of fields in (meta and quality).
    object = {
      ids: ids,
      processorId: loggedUser.processorId,
      createdUserId: loggedUser._id,
    };
    if (isExistAllowBulkUpdateField) {
      object = { ...object, customFields: getStringCustomFields() };
    }
    if (testTypeSetting.multiTestsPerLot && qualityData.length > 0) {
      object = { ...object, qualityData: JSON.stringify(qualityData) };
    }
    if (allowUpdateOverridePrice === true || onlyUpdateOverridePrice()) {
      object = { ...object, overridePrice: overridePrice };
    }
    //Remove property which is not allowed to bulk update from temp field
    let cloneTempField = { ...tempField };
    for (let attribute in allowUpdateTempField) {
      if (allowUpdateTempField[attribute] === false)
        delete cloneTempField[attribute];
    }
    //After remove, then change all of fields to string
    for (let attribute in cloneTempField) {
      cloneTempField[attribute] = cloneTempField[attribute].toString();
    }
    const data = Object.assign({}, object, cloneTempField);
    return data;
  };

  const updateQualityData = (a) => {
    setQualityData(a.qualityData);
  };

  const handleSubmit = async () => {
    const valid = validateBeforeSubmit();
    if (valid.result === false) {
      window.showAlert("Info", valid.messages[0], "info");
      return;
    }
    const requestOptions = {
      method: METHOD_PATCH,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(initDataToSend()),
    };
    const url = `${GlobalConfig.REACT_APP_API_WALNUT_TEST_URL}updateOverridePriceAndCustomFields`;
    setLoading(true);
    fetch(url, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          window.showAlert("", "Update successful!", "");
          filterItems();
          // close modal and reset state
          handleCloseModal();
          setClearSelectedRows(true);
          setSelectedRows([]);
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
        setLoading(false);
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
        setLoading(false);
      });
  };

  const validateBeforeSubmit = () => {
    let messages = [];
    let result = true;
    if (onlyUpdateOverridePrice()) {
      if (overridePrice === 0) {
        messages.push("Override Price must be greater than 0 !");
      }
    } else {
      switch (testTypeSetting.multiTestsPerLot) {
        case true:
          if (
            qualityData.length <= 0 &&
            existEnabledField() === false &&
            allowUpdateOverridePrice === false
          ) {
            messages.push(
              "Please select at least one field or add new quality data to update."
            );
          }
          if (allowUpdateOverridePrice === true && overridePrice === 0) {
            messages.push("Override Price must be greater than 0 !");
          }
          break;
        case false:
          if (
            existEnabledField() === false &&
            allowUpdateOverridePrice === false
          ) {
            messages.push("Please select at least one field to update.");
          }
          if (allowUpdateOverridePrice === true && overridePrice === 0) {
            messages.push("Override Price must be greater than 0.");
          }
          break;
        default:
          messages.push("Update failed. Can't determine multi test per lot.");
          break;
      }
    }
    if (messages.length > 0) {
      result = false;
    }
    return { result: result, messages: messages };
  };

  const checkExistAllowBulkUpdateAndIsCalculatedField = () => {
    return combineFields.some(
      (field) =>
        field.fieldType === FIELD_TYPE_CALCULATED && field.allowBulkUpdate
    );
  };

  const onlyUpdateOverridePrice = () => {
    return (
      isShowOverridePrice === true && isExistAllowBulkUpdateField === false
    );
  };

  const existEnabledField = () => {
    let isExist = false;
    for (let property in allowUpdateTempField) {
      if (allowUpdateTempField[property] === true) {
        isExist = true;
        break;
      }
    }
    return isExist;
  };

  const handleChangeOverridePrice = (e) => {
    setOverridePrice(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checked" ? checked : value;
    const _idField = name;
    setTempField((previousField) => {
      return {
        ...previousField,
        [_idField]: newValue,
      };
    });
    if (isFieldToCalculate(_idField)) {
      calculate(_idField, newValue);
    }
  };

  const handleChangeSelectControl = (controlId, value) => {
    setTempField((previousTemp) => {
      return { ...previousTemp, [controlId]: value };
    });
  };

  const handleOnBlur = (e) => {};

  const handleCloseModal = () => {
    //Reset
    setShow(false);
    if (testTypeSetting && testTypeSetting.multiTestsPerLot === true) {
      initTempFieldWithoutFieldsInQualityData(combineFields);
    } else {
      initTempField();
    }
    setOverridePrice(0);
    setAllowUpdateOverridePrice(false);
    setQualityData([]);
  };

  const handleCloseModalWithoutResetData = () => {
    if (testTypeSetting && testTypeSetting.multiTestsPerLot === false) {
      handleCloseModal();
      return;
    }
    setShow(false);
  };

  const handleOpenModal = () => {
    setShow(true);
  };

  const formatSectionForCollapse = (section) => {
    const formatSection = section.toLowerCase().replaceAll(" ", "");
    return formatSection;
  };

  const checkQualityMultiTests = (section) => {
    const { multiTestsPerLot } = testTypeSetting;
    const isQualityDataSection = section === SECTION_QUALITY_DATA;
    return multiTestsPerLot && isQualityDataSection;
  };

  const handleGroupSelectedField = (array) => {
    const grouped = _.groupBy(array, (arrayItem) =>
      arrayItem.nutTypeGroupId !== ""
        ? arrayItem.nutTypeGroupId
        : arrayItem.section
    );

    return grouped;
  };

  const onlyAllowBulkUpdateFields = (fields) => {
    if (fields && fields.length > 0) {
      const bulkUpdateFields = fields.filter((field) => field.allowBulkUpdate);
      return bulkUpdateFields;
    } else {
      return [];
    }
  };

  const renderFieldDropdown = (field, defaultValue) => {
    const url = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${field.testClassificationId}`;
    return (
      <SelectControl
        handleChangeSelectControl={handleChangeSelectControl}
        onRef={(ref) => {}}
        placeholder={field.name}
        label={field.name}
        popupTitle={field.name}
        controlId={field.testFieldId}
        value={defaultValue}
        required={field.isMandatory}
        maxLength={200}
        processorId={loggedUser.processorId}
        urlapi={url}
        urlapilist={url}
        isHideAddButton={true}
        textField="name"
        readOnly={!allowUpdateTempField[field.testFieldId]}
      />
    );
  };

  const renderFields = (section, groupId) => {
    const selectedFields = handleGroupSelectedField(combineFields);
    const selectedFieldsBulkUpdate = onlyAllowBulkUpdateFields(
      selectedFields?.[groupId ? groupId : section]
    );
    return selectedFieldsBulkUpdate.map((x, index) => {
      if (groupId === `` || groupId === undefined || groupId === null) {
        return (
          <React.Fragment key={index}>
            <div className="col-md-12 col-lg-6 form-group-spacing popup-bulk-update-field-group flex-box-align-items-center">
              <div className="col-lg-11 col-xs-11 bulk-update-field">
                {x.fieldType === FIELD_TYPE_DROP_DOWN
                  ? renderFieldDropdown(
                      x,
                      tempField ? tempField[x.testFieldId] : x.defaultValue
                    )
                  : renderFieldByType(
                      x,
                      tempField ? tempField[x.testFieldId] : x.defaultValue,
                      loggedUser,
                      GlobalConfig,
                      handleChange,
                      handleChangeSelectControl,
                      null,
                      !allowUpdateTempField[x.testFieldId]
                    )}
              </div>
              <div className="col-lg-1 bulk-update-test-field-checkbox">
                <input
                  className="input-allow-bulk-update-field"
                  type="checkbox"
                  checked={allowUpdateTempField[x.testFieldId]}
                  onClick={(e) => {
                    const checked = e.target.checked;
                    setUpdateRules((previousUpdateRules) => {
                      return {
                        ...previousUpdateRules,
                        [x.testFieldId]: checked,
                      };
                    });
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={index}>
          <div className="flex-box-align-items-center">
            <div className="col-lg-11 col-xs-11 form-group-spacing column-no-padding">
              {x.fieldType === FIELD_TYPE_DROP_DOWN
                ? renderFieldDropdown(
                    x,
                    tempField ? tempField[x._id] : x.defaultValue
                  )
                : renderFieldByType(
                    x,
                    tempField ? tempField[x._id] : x.defaultValue,
                    loggedUser,
                    GlobalConfig,
                    handleChange,
                    handleChangeSelectControl,
                    null,
                    !allowUpdateTempField[x.testFieldId]
                  )}
            </div>
            <div className="col-lg-1 bulk-update-test-field-checkbox">
              <input
                className="input-allow-bulk-update-field"
                type="checkbox"
                checked={allowUpdateTempField[x.testFieldId]}
                onClick={(e) => {
                  const checked = e.target.checked;
                  setUpdateRules((previousUpdateRules) => {
                    return {
                      ...previousUpdateRules,
                      [x.testFieldId]: checked,
                    };
                  });
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  const renderSections = () => {
    const sections = [SECTION_META_DATA, SECTION_QUALITY_DATA];
    return sections.map((section, index) => {
      if (
        checkSectionExistAllowBulkUpdateField(combineFields, section) ||
        (testTypeSetting.multiTestsPerLot === true &&
          section === SECTION_QUALITY_DATA)
      ) {
        {
          const groups = testTypeSetting
            ? section === SECTION_META_DATA
              ? testTypeSetting.groupMetaData
              : testTypeSetting.groupQualityData
            : [];
          return (
            <React.Fragment key={index}>
              <div
                className="col-md-12"
                style={{
                  margin: `10px 0 0 0`,
                }}
              >
                <div className="row">
                  <section
                    className={
                      section === SECTION_META_DATA
                        ? "panel-warning"
                        : "panel-primary"
                    }
                  >
                    <header
                      className="panel-heading-custom panel-nut-type panel-heading"
                      style={{ border: "none" }}
                    >
                      <h2 className="panel-title panel-title-main">
                        {section}
                      </h2>
                      <a
                        data-toggle="collapse"
                        href={`#header-${formatSectionForCollapse(section)}`}
                        aria-expanded="true"
                        className="icon-collapse"
                      >
                        <i className="fa fa-chevron-up fa-lg"></i>
                        <i className="fa fa-chevron-down fa-lg"></i>
                      </a>
                    </header>
                    <div
                      id={`header-${formatSectionForCollapse(section)}`}
                      className="panel-collapse collapse in"
                    >
                      {/* if quality data section and multi tests per lot is true, then render fields with table layout */}
                      {checkQualityMultiTests(section) && (
                        <div
                          className="multi-quality-data-border"
                          style={{
                            boxShadow:
                              section === SECTION_QUALITY_DATA
                                ? "#44a6eb 0 0 0 1px inset"
                                : "",
                            paddingBottom:
                              qualityData && qualityData.length > 0 ? 10 : 0,
                          }}
                        >
                          <MultiQualityData
                            calculatedFields={calculatedFields}
                            numberFields={numberFields}
                            testTypeSetting={testTypeSetting}
                            qualityData={qualityData}
                            section={section}
                            setQualityData={updateQualityData}
                            loggedUser={loggedUser}
                            inPopup={true}
                            handleCloseModalWithoutResetData={
                              handleCloseModalWithoutResetData
                            }
                            handleOpenModal={handleOpenModal}
                          />
                        </div>
                      )}
                      {!checkQualityMultiTests(section) && (
                        <div
                          className={`${
                            section === SECTION_META_DATA
                              ? "panel-body-warning"
                              : "panel-body-primary"
                          } panel-body panel-body-spacing border-panel`}
                          style={{
                            boxShadow:
                              section === SECTION_META_DATA
                                ? "#edb164 0 0 0 1px inset"
                                : "#44a6eb 0 0 0 1px inset",
                          }}
                        >
                          <div className="row">
                            <fieldset> {renderFields(section, ``)}</fieldset>
                          </div>

                          <div className="panel-group-container">
                            {groups &&
                              groups.length > 0 &&
                              groups.map((g, index) => {
                                const findIndex = combineFields.findIndex(
                                  (field) =>
                                    field.nutTypeGroupId &&
                                    field.nutTypeGroupId === g._id &&
                                    field.allowBulkUpdate
                                );
                                if (findIndex !== -1) {
                                  return (
                                    <div
                                      className={`${g.size} panel-group-item`}
                                      key={index}
                                    >
                                      <div className="row panel-group-item-spacing panel-height-body">
                                        <section
                                          className="panel-custom panel-height-body"
                                          style={{
                                            background: g.color,
                                          }}
                                        >
                                          <header
                                            className="panel-custom panel-heading-custom panel-nut-type"
                                            style={{
                                              background: g.color,
                                            }}
                                          >
                                            <h2 className="panel-title panel-title-custom">
                                              {g.name}
                                            </h2>
                                          </header>
                                          <div
                                            className="panel-body panel-body-custom"
                                            style={{
                                              boxShadow:
                                                "inset 0px 0px 0px 1px" +
                                                g.color +
                                                "52",
                                            }}
                                          >
                                            <fieldset>
                                              <div>
                                                {renderFields(section, g._id)}
                                              </div>
                                            </fieldset>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </React.Fragment>
          );
        }
        return <></>;
      }
    });
  };

  return (
    <>
      {selectedRows &&
      selectedRows.length > 0 &&
      (isExistAllowBulkUpdateField || isShowOverridePrice === true) ? (
        <Button variant="primary" onClick={handleOpenModal} className="mb-sm">
          <i className="fa fa-pencil fa-lg"></i> Bulk Update
        </Button>
      ) : (
        ""
      )}
      <Modal
        show={show}
        onHide={handleCloseModalWithoutResetData}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
        className={
          checkExistAllowBulkUpdateField(combineFields)
            ? "modal-bulk-update"
            : ""
        }
      >
        <Modal.Header style={{ padding: "10px" }}>
          <Modal.Title>Bulk Update</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            padding: `${
              isShowOverridePrice === true ? `10px` : "0px"
            } 10px 10px 10px`,
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isShowOverridePrice === true &&
            (isExistAllowBulkUpdateField ? (
              <div className="row new-override-price-panel">
                <div className="form-group col-md-12 col-lg-6 flex-box-align-items-center">
                  <div className="col-lg-11 col-xs-11">
                    <NumericInput
                      precision={2}
                      inputClass={8}
                      labelClass={4}
                      min={0}
                      max={999999999}
                      step={0.1}
                      label="New Override Price"
                      name="overridePrice"
                      handleChange={handleChangeOverridePrice}
                      displayRequired={true}
                      handleOnBlur={handleOnBlur}
                      hasCustomHandleOnBlur
                      value={overridePrice}
                      classProps="new-override-label popup-bulk-update-field-group"
                      readOnly={!allowUpdateOverridePrice}
                    />
                  </div>
                  <div className="col-lg-1 bulk-update-test-field-checkbox">
                    <input
                      className="input-allow-bulk-update-field"
                      type="checkbox"
                      checked={allowUpdateOverridePrice}
                      onClick={(e) => {
                        setAllowUpdateOverridePrice(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <NumericInput
                precision={2}
                inputClass={8}
                labelClass={4}
                min={0}
                max={999999999}
                step={0.1}
                label="New Override Price"
                name="overridePrice"
                handleChange={handleChangeOverridePrice}
                displayRequired={true}
                handleOnBlur={handleOnBlur}
                hasCustomHandleOnBlur
                value={overridePrice}
                classProps="new-override-label popup-bulk-update-field-group"
              />
            ))}
          {isExistAllowBulkUpdateField && renderSections()}
        </Modal.Body>

        <Modal.Footer
          style={{
            marginTop: 0,
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <SaveButton
            loading={loading}
            icon={<span className="fa fa-check fa-lg"></span>}
            onClick={handleSubmit}
          />
          <Button
            style={{ margin: "0 5px" }}
            variant="warning"
            onClick={handleCloseModalWithoutResetData}
          >
            <span className="fa fa-times fa-lg"></span> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkUpdateTestsPopup;
