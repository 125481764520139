import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

export const parseViewModel = (dataModel) => {
  return {
    _id: dataModel._id, //This is temporary ID using in GUID only.
    varietyId: dataModel.varietyId,
    paymentId: dataModel.paymentId,
    paymentName: dataModel.paymentName,
    valueInshell: dataModel.valueInshell,
    valueShelled: dataModel.valueShelled,
    primaryPrice: dataModel.primaryPrice,
    customAttributes: dataModel.customAttributes,
    varietyName: dataModel.varietyName,
  };
};

export const wizardColumns = (
  handleShowWithWizardMode,
  testType,
  loggedUser,
  removeRow
) => {
  let labelValueInshell = ``;
  let labelValueShell = `Shelled`;
  if (testType.isDynamic) {
    if (testType.xpool === true) {
      labelValueInshell = "Regular Price";
      labelValueShell = "Alternate Price";
    } else {
      labelValueInshell = "Price";
    }
  } else {
    labelValueInshell = "Inshell";
  }
  return [
    {
      name: "Variety",
      selector: "varietyName",
      width: "150px",
      cell: (row, index) => (
        <a
          onClick={() => {
            handleShowWithWizardMode(row, index);
          }}
          href="# "
          title="Click to edit"
        >
          {row.varietyName}
        </a>
      ),
      omit: testType.varietyOptional,
    },
    {
      name: "Primary Price",
      selector: "primaryPrice",
      sortable: true,
      center: true,
      omit: testType.isDynamic,
    },
    {
      name: labelValueInshell,
      selector: "valueInshell",
      sortable: true,
      right: true,
      cell: (row) =>
        "$" +
        CommonUtilities.numberWithCommas(
          (row.valueInshell == null ||
            row.valueInshell === undefined ||
            isNaN(row.valueInshell)
            ? 0
            : parseFloat(row.valueInshell)
          )
        ),
      width: "160px",
    },
    {
      name: labelValueShell,
      selector: "valueShelled",
      sortable: true,
      right: true,
      cell: (row) =>
        "$" +
        CommonUtilities.numberWithCommas(
          (row.valueShelled == null ||
            row.valueShelled === undefined ||
            isNaN(row.valueShelled)
            ? 0
            : parseFloat(row.valueShelled)
          )
        ),
      omit:
        testType.isDynamic && testType.xpool !== true,
    },
    {
      name: "Meta Attribute",
      selector: "customAttributes",
      sortable: false,
      cell: (row) => {
        let customAttributesText = ``;
        if (row.customAttributes && row.customAttributes !== "") {
          const customAttributes = JSON.parse(row.customAttributes);
          if (customAttributes && customAttributes.length > 0) {
            customAttributes.map((x) => {
              if (customAttributesText !== ``) {
                customAttributesText += `, `;
              }
              customAttributesText += `${x.attribute} (${x.value})`;
            });
          }
        }
        return customAttributesText;
      },
      omit: !testType.isDynamic,
    },
    {
      name: "Payment",
      selector: "paymentName",
      sortable: false,
      omit: !testType.basePriceByPayment,
    },
    {
      name: "",
      width: "100px",
      cell: (row, index) => (
        <div>
          {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <>
              <a
                title="Click to edit"
                href="# "
                onClick={() => {
                  handleShowWithWizardMode(row, index);
                }}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Click to remove"
                href="# "
                className="on-default remove-row"
                onClick={() => removeRow(index)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
            </>
          )}
        </div>
      ),
    },
  ];
};
