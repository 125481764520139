import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_PROCESSOR,
  USER_ROLE_STAFF,
} from "./../Utilities/Constant";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    let urlapi = "";
    if (
      loggedUser.role === USER_ROLE_PROCESSOR ||
      loggedUser.role === USER_ROLE_STAFF
    ) {
      urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}${loggedUser.processorId}/true`;
      this.state.useFilter = false;
    } else {
      urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}all/false`;
      this.state.useFilter = true;
    }

    this.state.urladd = "";
    this.state.urlapi = urlapi;
    this.state.title = "Import Logs";
    this.state.pluraltitle = "Import Logs";
    this.state.pageName = "Import Log";

    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Processor",
        selector: "processor",
        sortable: true,
        left: true,
        omit: loggedUser.role !== USER_ROLE_ADMINISTRATOR,
        cell: (row) => {
          return (
            <a
              href={`${process.env.REACT_APP_URL_PROCESSOR_LIST}/${row.processorId}`}
              title={row.processor}
            >
              {row.processor}
            </a>
          );
        },
      },
      {
        name: "Test Type",
        selector: "testType",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <a
              href={`${process.env.REACT_APP_URL_NUTTYPE_LIST}/${row.testTypeId}`}
              title={row.testType}
            >
              {row.testType}
            </a>
          );
        },
      },
      {
        name: "Date",
        selector: "date",
        sortable: true,
        center: true,
      },
      {
        name: "New Records",
        selector: "newCount",
        sortable: true,
        right: true,
      },
      {
        name: "Updated Records",
        selector: "updateCount",
        sortable: true,
        right: true,
      },
      {
        name: "Error Records",
        selector: "errorCount",
        sortable: true,
        right: true,
        cell: (row) => {
          return row.errorCount > 0 ? (
            <a
              href={`${process.env.REACT_APP_URL_IMPORT_LOG_LIST}/error/${row._id}`}
              title={row.errorCount}
            >
              {row.errorCount} {row.errorCount > 1 ? `Errors` : `Error`}
            </a>
          ) : (
            row.errorCount
          );
        },
      },
    ];
  };

  updateFilterControlUrls = () => {
    this.setState({
      filterControlUrls: {
        urlApiProcessorFilter:
          GlobalConfig.REACT_APP_API_PROCESSOR_URL + "filterDataProcessor",
      },
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      () => {
        this.filterItems();
      }
    );
  };

  filterItems = () => {
    let urlapi = "";
    if (
      loggedUser.role === USER_ROLE_PROCESSOR ||
      loggedUser.role === USER_ROLE_STAFF
    ) {
      urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}${loggedUser.processorId}/true`;
    } else {
      if (this.state.filterProcessorId && this.state.filterProcessorId !== ``) {
        urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}${this.state.filterProcessorId}/false`;
      } else {
        urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}all/false`;
      }
    }
    this.setState(
      {
        urlapi: urlapi,
      },
      () => {
        this.getItemsServerPaging(this.state.currentPage);
      }
    );
  };

  handleReset = () => {
    this.processorControl?.reset();
    this.setState(
      {
        filterProcessorId: "",
      },
      () => {
        this.filterItems();
      }
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            {loggedUser.role === "Administrators" ? (
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorControl = ref)}
                  placeholder="Filter Processor"
                  label="Select All"
                  controlId="filterProcessorId"
                  urlapilist={
                    this.state.filterControlUrls?.urlApiProcessorFilter
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };
}
export default App;
