import {
  SET_HELP_CATEGORIES,
  ADD_HELP_CATEGORY,
  REMOVE_HELP_CATEGORY,
} from "./types";

export const setHelpCategories = (data) => {
  return {
    type: SET_HELP_CATEGORIES,
    payload: data,
  };
};

export const addHelpCategory = (data) => {
  return {
    type: ADD_HELP_CATEGORY,
    payload: data,
  };
};

export const removeHelpCategory = (data) => {
  return {
    type: REMOVE_HELP_CATEGORY,
    payload: data,
  };
};
