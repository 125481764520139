import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};

class App extends React.Component {
  constructor(props) {
    super();
  loggedUser = getLoggedUser();
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>{"Page Not Found | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            {loggedUser == undefined || loggedUser == null || loggedUser.role == undefined || loggedUser.role == null || loggedUser.role == "" ? "" : <Navigation navRoute={this.props.navRoute} location={this.props.location}></Navigation>}

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Page Not Found</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>Page Not Found</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="body-error error-inside">
                <div className="center-error">
                  <div className="row" style={{ color: "white" }}>
                    <div className="col-md-8">
                      <div className="main-error mb-xlg">
                        <h2 className="error-code text-white text-center text-semibold m-none">
                          404 <i className="fa fa-file"></i>
                        </h2>
                        <p className="error-explanation text-center">
                          We're sorry, but the page you were looking for doesn't
                          exist
                      </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h4 className="text">Here are some useful links</h4>
                      <ul className="nav nav-list">
                        <li>
                          <a href="/" style={{ backgroundColor: "transparent" }}><i className="fa fa-caret-right text-white"></i> <span style={{ color: "white" }}>Dashboard</span></a>
                        </li>
                        <li>
                          <a href={process.env.REACT_APP_URL_WALNUT_TEST_LIST} style={{ backgroundColor: "transparent" }}><i className="fa fa-caret-right text-white"></i> <span style={{ color: "white" }}>Tests</span></a>
                        </li>
                        {loggedUser == undefined || loggedUser == null || loggedUser.role == undefined || loggedUser.role == null || loggedUser.role == "" ?
                          <li>
                            <a href="/login" style={{ backgroundColor: "transparent" }}><i className="fa fa-caret-right text-white"></i> <span style={{ color: "white" }}>Login</span></a>
                          </li> : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </section>
      </div>
    );
  }
}
export default App;
