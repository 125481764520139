function isEmpty(value) {
  let isEmpty = false;
  if (value === null || value === undefined || value === "") {
    isEmpty = true;
  } else {
    if (value.trim() === "") {
      isEmpty = true;
    }
  }
  return isEmpty;
}

function isString(value) {
  const type = typeof value;
  return type === "string";
}

exports.isEmpty = isEmpty;
exports.isString = isString;
