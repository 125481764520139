import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import TableProgressComponent from "../Base/TableProgressComponent";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      items: [],
      urlapi: `${props.GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}children/${props.cropYearId}/${props.data.growerId}`,
      totalRows: 0,
      currentPage: 1,
      perPage: parseInt(props.GlobalConfig.REACT_APP_PAGE_SIZE_ADJUSTMENTS),
      paginationPerPage: parseInt(
        props.GlobalConfig.REACT_APP_PAGE_SIZE_ADJUSTMENTS
      ),
      progressPending: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    //Query children
    this.getItemsServerPaging(this.state.currentPage);
  }

  getItemsServerPaging = async (page, size = this.state.perPage) => {
    const that = this;
    that.setState(
      {
        progressPending: true,
      },
      () => {
        try {
          fetch(`${this.state.urlapi}/${page}/${size}`)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              if (
                resultObject &&
                resultObject.result === "OK" &&
                resultObject.data
              ) {
                that.setState({
                  items: resultObject.data,
                  totalRows: resultObject.totalCount,
                  progressPending: false,
                });
              }
            })
            .catch(function (error) {
              window.showAlert("Error", error, "error");
              that.setState({
                progressPending: false,
              });
            });
        } catch (err) {
          console.log(err.message);
          that.setState({
            progressPending: false,
          });
        }
      }
    );
  };

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItemsServerPaging(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    this.getItemsServerPaging(page);
    this.setState({ currentPage: page });
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        width: "150px",
        cell: (row) => (
          <a
            onClick={() => this.props.handleShow(row)}
            href="#"
            title="Edit bonus & deduct"
          >
            {row.varietyName}
          </a>
        ),
        omit: this.props.testType.varietyOptional === true,
      },
      {
        name: "Quality Attribute",
        selector: "attribute",
        sortable: true,
        cell: (row) => {
          return this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            row.attribute
          ) : (
            <a
              onClick={() => this.props.handleShow(row)}
              href="#"
              title="Edit bonus & deduct"
            >
              {row.attribute}
            </a>
          );
        },
      },
      {
        name: "Applies To",
        selector: "applyTo",
        sortable: false,
        width: "90px",
        omit: this.props.testType.isDynamic,
      },
      {
        name: "From",
        selector: "min",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.min == null || row.min === undefined || isNaN(row.min)
              ? 0
              : parseFloat(row.min)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "To",
        selector: "max",
        sortable: true,
        right: true,
        width: "85px",
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.max == null || row.max === undefined || isNaN(row.max)
              ? 0
              : parseFloat(row.max)
            ).toFixed(2)
          ) + "%",
      },
      {
        name: "Value",
        selector: "value",
        sortable: true,
        right: true,
        width: "95px",
        cell: (row) => {
          return parseFloat(row.value) < 0 ? (
            <span className="deduct-color">
              ${CommonUtilities.numberWithCommasNegative(parseFloat(row.value))}
            </span>
          ) : (
            <span className="bonus-color">
              ${CommonUtilities.numberWithCommas(parseFloat(row.value))}
            </span>
          );
        },
      },
      {
        name: "Bonus or Deduction Applied",
        selector: "applyType",
        sortable: true,
        center: true,
        cell: (row) => row.applyType,
      },
      {
        name: "",
        width: "100px",
        cell: (row) => (
          <div>
            {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
              ""
            ) : (
              <>
                <a
                  title="Edit base price"
                  href="#"
                  onClick={() => this.props.handleShow(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Remove base price"
                  href="#"
                  className="on-default remove-row"
                  data-id={row._id}
                  data-growerid={row.growerId}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
              </>
            )}

            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        noDataComponent=""
        noTableHead={false}
        pagination={true}
        paginationPerPage={this.state.paginationPerPage}
        paginationServer={true}
        paginationTotalRows={this.state.totalRows}
        paginationRowsPerPageOptions={this.props.rowPerPageOption}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        progressPending={this.state.progressPending}
        progressComponent={<TableProgressComponent></TableProgressComponent>}
      />
    );
  }
}
export default App;
