import React from "react";
import CommonUtilities from "../Utilities/Common";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      data: [],
      loading: false,
      expand: true,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.currentProcessorId !== this.props.currentProcessorId ||
      previousProps.currentCropYearId !== this.props.currentCropYearId ||
      previousProps.currentGrowerId !== this.props.currentGrowerId
    ) {
      this.getData();
    }
  }

  getData = async () => {
    this.setState({ loading: true });
    let parameters = "processorId=" + this.props.currentProcessorId;
    parameters += "&growerId=" + this.props.currentGrowerId;
    parameters += "&cropYearId=" + this.props.currentCropYearId;
    const urlApi =
      GlobalConfig.REACT_APP_API_DASHBOARD_URL + "testaverage?" + parameters;
    const that = this;
    fetch(urlApi)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({
          data: resultObject.data,
          loading: false,
        });
      })
      .catch(function (error) {
        that.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  onChangeExpandToggle = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  render() {
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a
              href="javascript:void(0);"
              className={`fa fa-caret-${this.state.expand ? "up" : "down"}`}
              onClick={this.onChangeExpandToggle}
            ></a>
          </div>
          <h2 className="panel-title">Test Average</h2>
        </header>
        <div
          className="panel-body report-panel-body"
          style={{
            maxHeight: this.state.expand ? 500 : 0,
          }}
        >
          <hr className="dotted short"></hr>
          {this.state.loading === true ? (
            <span>Loading...</span>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped mb-none">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>AVG Net Weight</th>
                    <th>AVG % Edible Yield</th>
                    <th>AVG % Defect</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((u, key) => {
                    return (
                      <tr key={key}>
                        <td>{u.date}</td>
                        <td>
                          {CommonUtilities.numberWithCommas(
                            parseFloat(u.avgNetWeight).toFixed(1)
                          )}
                        </td>
                        <td>
                          {CommonUtilities.numberWithCommas(
                            parseFloat(u.avgEdibleYield).toFixed(1)
                          )}
                          %
                        </td>
                        <td>
                          {CommonUtilities.numberWithCommas(
                            parseFloat(u.avgDefect).toFixed(1)
                          )}
                          %
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    );
  }
}
export default App;
