import React from "react";

class Page extends React.Component {

  render() {
    return <div className="form-group form-group-spacing">
      <div className="col-md-8 col-lg-6">
        <div className="form-group">
          <label className="col-md-4 control-label input-header-spacing">
            {this.props.label1}
          </label>
          <div className="col-md-8">
            <label className="control-label input-header-spacing">
              {this.props.label2}
            </label>
          </div>
        </div>
      </div>
    </div>;
  }
}
export default Page;