// Using for growers, inspectors (lab users) under Test
import React from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      labelClass:
        "col-md-" +
        (props.labelClass === null || props.labelClass === undefined
          ? 4
          : props.labelClass) +
        " control-label",
      inputClass:
        "col-md-" +
        (props.inputClass === null || props.inputClass === undefined
          ? 8
          : props.inputClass),
      controlId: props.controlId,
      urlapi: props.urlapi,
      urlapilist: props.urlapilist,
      processorId: props.processorId,
      value: props.value,
      name: "",
      data: [],
      errorMessage: "",
      isHideAddButton:
        props.isHideAddButton == null || props.isHideAddButton == undefined
          ? false
          : props.isHideAddButton,
      textField:
        props.textField == null || props.textField == undefined
          ? "name"
          : props.textField,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value !== prevProps.value) {
      this.getItems();
    }
  }

  getItems = async () => {
    try {
      if (
        this.props.urlapilist === undefined ||
        this.props.urlapilist == null ||
        this.props.urlapilist === ""
      ) {
        return;
      }
      const that = this;
      fetch(this.props.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            if (resultObject.data) {
              resultObject.data.map((item) => {
                parsedItems.push({
                  _id: item._id,
                  name: item[that.state.textField],
                  checked:
                    that.props.value == null ||
                      that.props.value === undefined ||
                      that.props.value.indexOf(item._id) === -1
                      ? false
                      : true,
                });
              });
            }
            that.setState({
              data: parsedItems,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    if (
      this.state.data == null ||
      this.state.data === undefined ||
      this.state.data.length <= 0
    ) {
      this.getItems();
    }
    this.setState({ value: selectedIds });
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds == null ||
          selectedIds === undefined ||
          selectedIds.indexOf(item._id) === -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const name = this.state.name;
    if (name == null || name === undefined || name === "") {
      // this.refs.name.focus();
      this.setState({ errorMessage: "Please enter name then try again." });
    } else {
      let updatedData = this.state.data;
      let isExist = false;
      if (updatedData) {
        updatedData.filter(function (memItem) {
          if (memItem.name == name) {
            isExist = true;
            return memItem;
          }
        });
      }
      if (isExist) {
        // this.refs.name.focus();
        this.setState({
          errorMessage: "Name already exists. Please try another name.",
        });
      } else {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: name,
            processorId: this.state.processorId,
          }),
        };
        const that = this;
        fetch(this.state.urlapi, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObject) {
            //If created successful
            if (resultObject && resultObject.result === "OK") {
              if (that.state.data) {
                let updatedData = that.state.data;
                updatedData.forEach((item) => {
                  item.checked = false;
                });
                updatedData = [
                  {
                    _id: resultObject.data._id,
                    name: resultObject.data.name,
                    checked: true,
                  },
                ].concat(updatedData);
                that.setState({
                  data: updatedData,
                  value: resultObject.data._id,
                });
              } else {
                const updatedData = [
                  {
                    _id: resultObject.data._id,
                    name: resultObject.data.name,
                    checked: true,
                  },
                ];
                that.setState({
                  data: updatedData,
                  value: resultObject.data._id,
                });
              }
              that.setState({ show: false });

              //fire event and call method in parent to update state of parent
              that.props.handleChangeSelectControl(
                that.state.controlId,
                that.state.value
              );
            } else {
              that.setState({
                errorMessage: resultObject.message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  handleShow = () => {
    this.setState({ show: true, errorMessage: "", value: "" });
  };

  handleChangeSelect(event) {
    // var id = event.target.name;    
    const value = event.target.value;
    let selectedName = ``;
    if (this.state.data && this.state.data.length > 0) {
      const temp = this.state.data.find((x) => {
        return x._id === value;
      })
      if (temp) {
        selectedName = temp.name;
      }
    }
    this.setState({ value: value });
    this.props.handleChangeSelectControl(this.state.controlId, value, selectedName);
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  render() {
    const myModelStyle = {
      background: "none",
    };
    return (
      <div
        className={
          this.props.classProps
            ? this.props.classProps + " form-group"
            : "form-group"
        }
      >
        <label className={this.state.labelClass} htmlFor={this.props.name}>
          {this.props.label}
          {this.props.required ? (
            <>
              {" "}
              <span className="required">(*)</span>
            </>
          ) : (
            ""
          )}
        </label>
        <div className={this.state.inputClass}>
          {this.props.readOnly ? (
            <select
              disabled={this.props.readOnly}
              name={this.state.controlId}
              className="form-control"
              required={
                this.props.required && this.props.displayRequired !== true
              }
              onChange={this.handleChangeSelect}
              value={this.state.value}
            >
              <option value="" hidden>
                Choose one
              </option>
              {this.state.data
                ? this.state.data.map((item, key) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })
                : ""}
            </select>
          ) : this.state.isHideAddButton ? (
            <select
              disabled={this.props.readOnly}
              name={this.state.controlId}
              className="form-control"
              required={
                this.props.required && this.props.displayRequired !== true
              }
              onChange={this.handleChangeSelect}
              value={this.props.value ? this.props.value : this.state.value}
            >
              <option value="" hidden>
                Choose one
              </option>
              {this.state.data
                ? this.state.data.map((item, key) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })
                : ""}
            </select>
          ) : (
            <div className="input-group mb-md">
              <select
                disabled={this.props.readOnly}
                name={this.state.controlId}
                className="form-control"
                required={
                  this.props.required && this.props.displayRequired !== true
                }
                onChange={this.handleChangeSelect}
                value={this.state.value}
              >
                <option value="" hidden>
                  Choose one
                </option>
                {this.state.data
                  ? this.state.data.map((item, key) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    );
                  })
                  : ""}
              </select>
              {this.state.urlapilist == undefined ||
                this.state.urlapilist == null ||
                this.state.urlapilist == "" ? (
                ""
              ) : (
                <div className="input-group-btn">
                  <Button variant="primary" onClick={this.handleShow}>
                    Add
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form>
            <Modal.Header>
              <Modal.Title>{this.props.popupTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    autoFocus={true}
                    type="text"
                    onChange={this.handleChangeName}
                    value={this.state.text}
                    name="name"
                    placeholder={this.props.placeholder}
                    maxLength={this.props.maxLength}
                    required={this.props.required}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2"></Form.Label>
                {this.state.errorMessage == null ||
                  this.state.errorMessage == "" ||
                  this.state.errorMessage == undefined ? (
                  ""
                ) : (
                  <Form.Label column sm="10" style={{ color: "red" }}>
                    {this.state.errorMessage}
                  </Form.Label>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={this.handleSave} type="submit">
                <span className="fa fa-save fa-lg"></span> Save
              </Button>
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default App;
