import React, { useEffect, useState } from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import JoditEditor from "jodit-react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";

let loggedUser = {};
var validator = require("email-validator");
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      title: "Mail Template",
      listtitle: "Mail Templates",
      urllist: process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST,
      urlapi: GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        token: "",
        subject: "",
        message: "",
        cc: "",
        bcc: "",
        processorId: "",
        clonedId: "",
        role: loggedUser.role,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      testEmail: "",
      show: false,
      errorMessage: "",
      loading: parentState.loading,
    };
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSendMail = this.handleSendMail.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeTestEmail = this.handleChangeTestEmail.bind(this);
  }

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        token: "",
        subject: "",
        message: "",
        cc: "",
        bcc: "",
        role: loggedUser.role,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
  };

  handleChangeMessage(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        message: value,
      }),
    });
  }

  handleChangeTestEmail(event) {
    this.setState({ testEmail: event.target.value });
  }

  handleShow = () => {
    if (
      this.state.data.message == undefined ||
      this.state.data.message == null ||
      this.state.data.message.trim() == "" ||
      this.state.data.message.trim() == "&nbsp;"
    ) {
      window.showAlert(
        "Error",
        "Please enter Message then try again.",
        "error"
      );
    } else {
      this.setState({ show: true, errorMessage: "" });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSendMail = async (event) => {
    event.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const testEmail = this.state.testEmail;
    if (testEmail == null || testEmail == undefined || testEmail == "") {
      // this.refs.name.focus();
      this.setState({ errorMessage: "Please enter email then try again." });
    } else {
      if (validator.validate(testEmail) == false) {
        this.setState({
          errorMessage:
            "Please enter correct email. Example: john@paritycorp.com",
        });
        return;
      }
      var bodyEmailTemplate = this.state.data.message;
      //send test mail
      const requestMailOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
          to: this.state.testEmail,
          cc: "",
          bcc: "",
          subject: this.state.data.subject,
          body: bodyEmailTemplate,
        }),
      };
      const that = this;
      fetch(GlobalConfig.REACT_APP_API_MAIL_URL, requestMailOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            window.showAlert(
              "",
              "A test mail sent to " + that.state.testEmail + ".",
              ""
            );
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    }
  };

  extendButtons = () => {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={this.handleShow}
      >
        <i className="fa fa-send fa-lg"></i> Send Test Mail
      </button>
    );
  };

  renderForm = () => {
    return (
      <>
        <div className="panel-body">
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Name <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="name"
                autoFocus={true}
                className="form-control"
                placeholder=""
                required
                value={this.state.data.name}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">Tokens</label>
            <div className="col-sm-9">
              <input
                type="text"
                name="token"
                className="form-control"
                placeholder=""
                value={this.state.data.token}
                onChange={this.handleChange}
                maxLength={500}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Subject <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder=""
                value={this.state.data.subject}
                onChange={this.handleChange}
                maxLength={500}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Message <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <JoditEditor
                name="message"
                value={this.state.data.message}
                maxLength={5000}
                onChange={this.handleChangeMessage}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">
              CC (separate by comma)
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="cc"
                className="form-control"
                placeholder="e.g. john@gmail.com,david@hotmail.com"
                value={this.state.data.cc}
                onChange={this.handleChange}
                maxLength={200}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">
              BCC (separate by comma)
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="bcc"
                className="form-control"
                placeholder="e.g. john@gmail.com,david@hotmail.com"
                value={this.state.data.bcc}
                onChange={this.handleChange}
                maxLength={200}
              />
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form>
            <Modal.Header closeButton={false}>
              <Modal.Title>Test Mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Send Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    autoFocus={true}
                    type="email"
                    onChange={this.handleChangeTestEmail}
                    value={this.state.testEmail}
                    name="testEmail"
                    maxLength="30"
                    required={true}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2"></Form.Label>
                {this.state.errorMessage == null ||
                  this.state.errorMessage == "" ||
                  this.state.errorMessage == undefined ? (
                  ""
                ) : (
                  <Form.Label column sm="10" style={{ color: "red" }}>
                    {this.state.errorMessage}
                  </Form.Label>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={this.handleSendMail}
                type="submit"
              >
                <span className="fa fa-send"> Send</span>
              </Button>
              <Button variant="info" onClick={this.handleClose}>
                <span className="fa fa-ban"> Close</span>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };
}
export default App;
