import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser, isActive } from "../../context/auth";
// import FileBase64 from "react-file-base64";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import axios from "axios";
import NumericInput from "../FormControl/NumericInput";
import Switch from "react-switch";
import CommonUtilities from "../Utilities/Common";
import SelectConfig from "../Base/SelectConfig";
import SaveButton from "../Controls/SaveButton";

var jwt = require("jsonwebtoken");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "",
      urllist: "",
      urlapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL,
      urlsubmitapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL + "profile/",
      imageMaxSize: parseInt(process.env.REACT_APP_MAX_SIZE_PROFILE_IMAGE),
      defaultCompanyPhoto: process.env.REACT_APP_DEFAULT_COMPANY_PHOTO,
      readOnlyCertificateNo: false,
      isProcessorActive: false,
      imageFieldName: "avatar",
      imageFieldExtension: "avatarExtension",
      data: {
        _id: loggedUser.referenceUserId,
        role: "",
        name: "",
        avatar: "",
        avatarExtension: "",
        isRectangleLogo: false,
        address: "",
        businessName: "",
        businessAddress: "",
        phoneNumber: "",
        dayOfDelayRelease: 0,
        isContractCashPrice: false,
        isUseDetailedGrowerStatement: false,
        isAllowImportApi: false,
        allowNotifyImportResult: false,
        notifyImportResult: false,
        companyName: "",
        certificateNo: "",
        certificatePrefix: "",
        certificateSeed: "",
        email: "",
        status: "",
        newPassword: "",
        currentPassword: "",
        releaseType: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      errors: {
        email: "",
      },
      isAllowImportApi: false,
      loading: parentState.loading,
    };
    this.handleContractCashPriceChangeSwitch =
      this.handleContractCashPriceChangeSwitch.bind(this);
    this.handleUseDetailedGrowerStatementChangeSwitch =
      this.handleUseDetailedGrowerStatementChangeSwitch.bind(this);
    this.handleIsAllowImportApiChangeSwitch =
      this.handleIsAllowImportApiChangeSwitch.bind(this);
    this.handleChangeSwitchIsRectangleLogo =
      this.handleChangeSwitchIsRectangleLogo.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleNotifyImportResultChangeSwitch =
      this.handleNotifyImportResultChangeSwitch.bind(this);
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleInvalid(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (value == null || value == undefined || value.trim() == "") {
      event.target.setCustomValidity(
        "Please fill out this field. It is required data for creating tree nut tests."
      );
    } else {
      event.target.setCustomValidity("");
    }
  }

  handleInput(event) {
    event.target.setCustomValidity("");
  }

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  doSomethingAfterGetItemById = async () => {
    this.releaseType.setSelected(this.state.data.releaseType);

    const isProcessorActive = isActive();
    this.setState(
      { isProcessorActive: isProcessorActive },
      function callBack() {
        if (
          loggedUser &&
          loggedUser.role === "Processors" &&
          this.state.isProcessorActive === true &&
          this.state.data.certificatePrefix != null &&
          this.state.data.certificatePrefix !== undefined &&
          this.state.data.certificatePrefix.trim() !== "" &&
          this.state.data.certificateSeed != null &&
          this.state.data.certificateSeed !== undefined
        ) {
          this.setState({ readOnlyCertificateNo: true });
        }
        if (
          loggedUser &&
          loggedUser.role === "Processors" &&
          this.state.isProcessorActive === false
        ) {
          localStorage.setItem("isActive", true);
        }

        if (
          this.state.data.avatar != null &&
          this.state.data.avatar !== undefined &&
          this.state.data.avatar !== "" &&
          this.state.data.avatar !== this.state.defaultAvatar
        ) {
          const arrTemp = this.state.data.avatar.split(".");
          const fileExtension = arrTemp[arrTemp.length - 1];
          this.setState({
            data: Object.assign({}, this.state.data, {
              ["avatar"]:
                process.env.REACT_APP_API_URL + this.state.data.avatar,
              ["avatarExtension"]: fileExtension,
            }),
          });
        }
      }
    );
    const that = this;
    fetch(
      `${GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL}validateAttributeTier/${loggedUser.referenceUserId}/isAllowImportApi`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState({
            isAllowImportApi: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTitle = () => {
    return "My Profile";
  };

  handleContractCashPriceChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isContractCashPrice: checked,
      }),
    });
  }

  handleUseDetailedGrowerStatementChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isUseDetailedGrowerStatement: checked,
      }),
    });
  }

  handleIsAllowImportApiChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowImportApi: checked,
      }),
    });
  }

  handleNotifyImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        notifyImportResult: checked,
      }),
    });
  }

  handleChangeSwitchIsRectangleLogo(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isRectangleLogo: checked,
      }),
    });
  }

  doSomethingAfterSaved = (resultObject) => {
    this.setState({ readOnlyCertificateNo: true });
    if (
      loggedUser.role == "Processors" &&
      this.state.isProcessorActive == false
    ) {
      axios
        .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/queryprofilebytoken", {
          id: this.state.data._id,
        })
        .then((result) => {
          if (result.status === 200) {
            let avatar = result.data.data.avatar;
            if (
              avatar != undefined &&
              avatar != "undefined" &&
              avatar != "" &&
              avatar != process.env.REACT_APP_DEFAULT_COMPANY_PHOTO &&
              avatar != process.env.REACT_APP_DEFAULT_AVATAR_PHOTO
            ) {
              avatar = process.env.REACT_APP_API_URL + avatar;
              result.data.data.avatar = avatar;
            }
            const token = jwt.sign(
              result.data.data,
              process.env.REACT_APP_TOKEN_SECRET
            );
            // localStorage.clear();
            localStorage.setItem("isActive", result.data.data.isActive);
            localStorage.setItem("tokens", JSON.stringify(token));

            setTimeout(() => {
              window.location.href =
                process.env.REACT_APP_URL_LAB_USER_LIST + "?startInvite=true";
            }, 4000);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };

  handleRemoveImage = () => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        ["avatar"]: "",
      }),
    });
  };

  render() {
    const title = "My Profile";
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#Profile" data-toggle="tab">
                          Profile & Account
                        </a>
                      </li>
                    </ul>
                    <form
                      className="form-horizontal form-bordered"
                      onSubmit={this.handleSubmit}
                      id="form"
                    >
                      <div className="tab-content">
                        <div id="Profile" className="tab-pane active">
                          <h4 className="mb-xlg">Processor Information</h4>
                          <fieldset>
                            {loggedUser.titerInfo &&
                            loggedUser.titerInfo.isAllowExportApi === true ? (
                              <div className="form-group">
                                <label className="col-md-4 control-label">
                                  Processor ID
                                </label>
                                <div className="col-md-8">
                                  <label className="control-label">
                                    {this.state.data._id}
                                  </label>
                                  <br></br>
                                  <label>
                                    Using for{" "}
                                    <a
                                      href={
                                        process.env.REACT_APP_URL_DOCS_TEST_LIST
                                      }
                                    >
                                      API Export
                                    </a>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="name"
                              >
                                Contact Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="150"
                                  type="text"
                                  // ref="name"
                                  name="name"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                  onInvalid={this.handleInvalid}
                                  onInput={this.handleInput}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="email"
                              >
                                Email <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.email}
                                  onChange={this.handleChange}
                                  onInvalid={this.handleInvalid}
                                  onInput={this.handleInput}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="companyName"
                              >
                                Processor Company Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="100"
                                  type="text"
                                  name="companyName"
                                  className="form-control"
                                  required
                                  value={this.state.data.companyName}
                                  onChange={this.handleChange}
                                  onInvalid={this.handleInvalid}
                                  onInput={this.handleInput}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="certificatePrefix"
                              >
                                Certification Prefix{" "}
                                {this.state.readOnlyCertificateNo ? (
                                  ""
                                ) : (
                                  <span className="required">(*)</span>
                                )}
                              </label>
                              <div className="col-md-8">
                                <input
                                  readOnly={this.state.readOnlyCertificateNo}
                                  maxLength="50"
                                  type="text"
                                  name="certificatePrefix"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.certificatePrefix}
                                  onChange={this.handleChange}
                                  onInvalid={this.handleInvalid}
                                  onInput={this.handleInput}
                                />
                                <label>
                                  Warning, Certification Prefix. cannot be
                                  changed once entered
                                </label>
                              </div>
                            </div>
                            {this.state.readOnlyCertificateNo ? (
                              <NumericInput
                                precision={0}
                                min={0}
                                max={999999999}
                                step={1}
                                label="Certification Seed No"
                                name="certificateSeed"
                                value={this.state.data.certificateSeed}
                                handleChange={this.handleChange}
                                readOnly={true}
                                note="Warning, Certification Seed No. cannot be changed once entered"
                              />
                            ) : (
                              <NumericInput
                                precision={0}
                                min={0}
                                max={999999999}
                                step={1}
                                label="Certification Seed No"
                                name="certificateSeed"
                                value={this.state.data.certificateSeed}
                                handleChange={this.handleChange}
                                require={true}
                                labelrequired=" (*)"
                                note="Warning, Certification Seed No. cannot be changed once entered"
                              />
                            )}
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Sample Certificate No
                              </label>
                              <label
                                className="control-label"
                                style={{ paddingLeft: "15px" }}
                              >
                                {this.state.data.certificatePrefix}
                                {this.state.data.certificateSeed}
                              </label>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="address"
                              >
                                Processor Address{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="address"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.address}
                                  onChange={this.handleChange}
                                  onInvalid={this.handleInvalid}
                                  onInput={this.handleInput}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessName"
                              >
                                Business Name
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="businessName"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessName}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessAddress"
                              >
                                Business Address
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="300"
                                  type="text"
                                  name="businessAddress"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessAddress}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="phoneNumber"
                              >
                                Phone Number
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.phoneNumber}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Is Rectangle Logo
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isRectangleLogo"
                                  onChange={
                                    this.handleChangeSwitchIsRectangleLogo
                                  }
                                  checked={this.state.data.isRectangleLogo}
                                  className="react-switch"
                                />
                                <p>
                                  If rectangle logo please upload logo (250x90)
                                  or circle (225x225)
                                </p>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="col-md-12 p-none">
                                <label
                                  className="col-md-4 control-label mt-xs pt-none"
                                  htmlFor="avatar"
                                >
                                  Upload Company Logo
                                </label>
                                <div className="col-md-7">
                                  <div
                                    className="fileupload fileupload-new"
                                    data-provides="fileupload"
                                  >
                                    <div className="input-append">
                                      <div className="uneditable-input">
                                        <i className="fa fa-file fileupload-exists"></i>
                                        <span className="fileupload-preview"></span>
                                      </div>
                                      <span className="btn btn-default btn-file m-none mt-sm-ipad">
                                        <span
                                          className="fileupload-exists"
                                          title="Change"
                                        >
                                          <i
                                            className="fa fa-camera fa-lg"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <span className="fileupload-new">
                                          <i
                                            className="fa fa-camera fa-lg"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <input
                                          type="file"
                                          name="avatar"
                                          onChange={this.handleChange4Image}
                                        />
                                      </span>
                                      <a
                                        href="#"
                                        className="btn btn-default fileupload-exists m-none mt-sm-ipad"
                                        data-dismiss="fileupload"
                                        title="Remove"
                                        onClick={this.handleRemoveImage}
                                      >
                                        <span>
                                          <i
                                            className="fa fa-trash-o fa-lg"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                  <label>
                                    Logo is using for export grower statement.
                                  </label>
                                </div>
                              </div>
                            </div>
                            {this.state.data.avatar === null ||
                            this.state.data.avatar === "" ||
                            this.state.data.avatar === undefined ? (
                              ""
                            ) : (
                              <div className="form-group">
                                <div className="col-md-12">
                                  <div className="col-md-4"></div>
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    value="Remove Logo"
                                    onClick={this.handleRemoveImage}
                                  ></input>
                                  &nbsp;&nbsp;&nbsp;
                                  {this.state.data.avatar.indexOf(
                                    "data:image"
                                  ) == -1 ? (
                                    <a
                                      href={this.state.data.avatar}
                                      target="_blank"
                                      title="Logo"
                                      rel="noreferrer"
                                    >
                                      View Logo
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="releaseType"
                              >
                                Release Test Data to Growers
                              </label>
                              <div className="col-md-8">
                                <SelectConfig
                                  configType={
                                    process.env
                                      .REACT_APP_CONFIG_NAME_RELEASE_TYPE
                                  }
                                  required={true}
                                  onRef={(ref) => (this.releaseType = ref)}
                                  name="releaseType"
                                  controlId="releaseType"
                                  selectedIds={this.state.data.releaseType}
                                  handleChangeSelectControl={
                                    this.handleChangeSelectControl
                                  }
                                ></SelectConfig>
                              </div>
                            </div>
                            {this.state.data.releaseType ===
                            `After a [n] day delay` ? (
                              <NumericInput
                                precision={0}
                                min={0}
                                max={1000}
                                step={1}
                                label="Grower Data Delay"
                                name="dayOfDelayRelease"
                                value={this.state.data.dayOfDelayRelease}
                                handleChange={this.handleChange}
                                note="Number of days to delay release Test data to Growers"
                              />
                            ) : (
                              <></>
                            )}
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Contract Cash Price
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isContractCashPrice"
                                  onChange={
                                    this.handleContractCashPriceChangeSwitch
                                  }
                                  checked={this.state.data.isContractCashPrice}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Use Detailed Grower Statement
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isUseDetailedGrowerStatement"
                                  onChange={
                                    this
                                      .handleUseDetailedGrowerStatementChangeSwitch
                                  }
                                  checked={
                                    this.state.data.isUseDetailedGrowerStatement
                                  }
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            {this.state.isAllowImportApi === true ? (
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  Allow Import API
                                </label>
                                <div className="col-md-8">
                                  <Switch
                                    name="isAllowImportApi"
                                    onChange={
                                      this.handleIsAllowImportApiChangeSwitch
                                    }
                                    checked={this.state.data.isAllowImportApi}
                                    className="react-switch"
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.data.allowNotifyImportResult && (
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  Notify Import Result
                                </label>
                                <div className="col-md-8">
                                  <Switch
                                    name="notifyImportResult"
                                    onChange={
                                      this.handleNotifyImportResultChangeSwitch
                                    }
                                    checked={this.state.data.notifyImportResult}
                                    className="react-switch"
                                  />
                                </div>
                              </div>
                            )}
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Change Password</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="currentPassword"
                              >
                                Current Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="currentPassword"
                                  maxLength={100}
                                  value={this.state.data.currentPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="newPassword"
                              >
                                New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="newPassword"
                                  maxLength={100}
                                  value={this.state.data.newPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="newPasswordRepeat"
                              >
                                Repeat New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="newPasswordRepeat"
                                  maxLength={100}
                                  value={
                                    this.state.data.newPasswordRepeat ?? ""
                                  }
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="panel-footer">
                        <div className="row">
                          <div className="col-md-8 col-md-offset-4">
                            <SaveButton
                              text={
                                loggedUser &&
                                loggedUser.role == "Processors" &&
                                this.state.isProcessorActive == false
                                  ? "Save & Continue"
                                  : "Save"
                              }
                              loading={this.state.loading}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
