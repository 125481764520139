import React from "react";
import ListComponentPayment from "../Base/ListComponentPayment";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import DataTable from "react-data-table-component";
import SubTable from "../BasePrice/SubTable";
import SelectGrowers from "../Grower/SelectGrowers";
import SelectConfig from "../Base/SelectConfig";
import ListGrower from "../Grower/ListGrower";
import TableAttribute from "./TableAttribute";
import MultiSelectVarieties from "../TestType/MultiSelectVarieties";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import SelectControl from "../FormControl/SelectControl";
import TestTypePopup from "./TestTypePopup";
import LoadingScreen from "../Base/LoadingScreen";
import Template from "./Template";
import NodeUuid from "node-uuid";
import { parseViewModel, wizardColumns } from "./WizardHandler";
import CommonUtilities from "../Utilities/Common";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state = {
      popupUrlApi: GlobalConfig.REACT_APP_API_BASEPRICE_URL,
      parentId: props.match.params.id,
      urlapidelete: GlobalConfig.REACT_APP_API_BASEPRICE_URL,
      urlapi: `${GlobalConfig.REACT_APP_API_BASEPRICE_URL}parent/${props.match.params.id}`,
      urllist:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BASEPRICE_LIST +
        "/" +
        props.match.params.id,
      urledit:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BASEPRICE_LIST +
        "-edit/",
      urladd:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_BASEPRICE_LIST +
        "-add/" +
        props.match.params.id,
      pluraltitle: "Base Prices",
      title: "Base Price",
      labeladd: "Base Price",
      pagination: false,
      excludeDefaultButtons: true,
      expandableRows: true,
      viewMode: "edit",
      isExpand: false,
      isUseHeading: false,
      cropYearName: "",
      progressPending: false,

      //Using for popup add/edit
      data: {
        _id: "",
        parentId: props.match.params.id,
        growerId: "",
        varietyId: "",
        paymentId: "",
        valueInshell: 0,
        valueShelled: 0,
        primaryPrice: this.state.testType.isDynamic ? "Highest of Both" : "",
        customAttributes: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        //virtual field
        paymentName: "",
      },
      previousGrowerId: "",
      previousPrimaryPrice: "",
      previousVarietyId: "",
      previousPaymentId: "",
      isChanged: false,
      errorMessage: "",
      color: "red",
      isEditMode: true,
      //This state is using for remove row later
      removedGrowerId: "",
      invalidVarietyNames: [],
      showError: false,
      nameVarieties: "",
      showRefresh2: true,
      testType: {
        xpool: false,
        isDynamic: false,
        _id: "",
        basePriceByPayment: false,
        paymentPercentBased: true,
        multiPricesSameLot: false,
        varietyOptional: false,
      },
      saveLoading: 0,
      //This state used for Wizard mode
      wizardMode: false,
      isWizardEdit: false,
    };

    //server paging
    this.state.isExpand = false;
    this.state.expandableRows = true;
    this.state.pagination = true;
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE_BASE_PRICE);
    this.state.paginationPerPage = parseInt(
      GlobalConfig.REACT_APP_PAGE_SIZE_BASE_PRICE
    );
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleTableAttributeChange =
      this.handleTableAttributeChange.bind(this);
  }

  async componentDidMount() {
    if (
      (loggedUser.titerInfo &&
        loggedUser.titerInfo.isAllowGrowerPayment === true) ||
      loggedUser.role === USER_ROLE_ADMINISTRATOR
    ) {
      this.getItemsServerPaging(this.state.currentPage);
      this.queryTestType();
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
      });
    }
  }

  handleTableAttributeChange = (name, value) => {
    if (
      value !== undefined &&
      value !== null &&
      value.length > 0 &&
      this.state.errorMessage === this.state.errorEmptyAttribute
    ) {
      this.setState({
        errorMessage: "",
      });
    }
    this.setState({
      data: Object.assign({}, this.state.data, {
        [name]: value,
      }),
    });
  };

  handleChangeSelectControl = (controlId, value, selectedName) => {
    if (controlId === `paymentId`) {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
          paymentName: selectedName,
        }),
      });
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
        }),
      });
    }
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  doSomethingAfterGetItems = (responseData) => {
    if (responseData.cropYear) {
      const cropYearName =
        responseData.cropYear.name +
        " (" +
        responseData.cropYear.startDate +
        "-" +
        responseData.cropYear.endDate +
        ")";

      const handleTruncate = this.truncateTitle(
        cropYearName,
        this.setTitleLength()
      );

      this.setState({
        pluraltitle: `Base Prices - ${handleTruncate}`,
        cropYearName: cropYearName,
      });
    }

    this.refreshTable();
  };

  setColumnsInvalidPopup = () => {
    return [
      {
        name: "Variety",
        selector: "name",
        sortable: true,
        left: true,
      },
      {
        name: "Reason",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          return "Missing base price";
        },
      },
    ];
  };

  defaultTable = () => {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs tabs-primary">
          <li className="active">
            <a href="#Default" data-toggle="tab">
              Base Prices
            </a>
          </li>
          <li>
            <a
              href="#Template"
              data-toggle="tab"
              onClick={() => {
                if (this.state.isLoadedTabTemplate !== true) {
                  this.template.getItems();
                  this.template.getGrowers();
                  this.setState({
                    isLoadedTabTemplate: true,
                  });
                }
              }}
            >
              Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="Default"
            className="tab-pane active"
            style={{ margin: "-10px" }}
          >
            <DataTable
              id="emp"
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              expandableRows={this.state.expandableRows}
              expandableRowsComponent={
                <SubTable
                  handleDelete={this.handleDelete.bind(this)}
                  handleShow={this.handleShow}
                  expandableRowExpanded={(row) => {
                    return this.state.isExpand;
                  }}
                  testType={this.state.testType}
                  loggedUser={loggedUser}
                />
              }
              conditionalRowStyles={this.conditionalRowStyles()}
              noTableHead={true}
              expandableRowExpanded={(row) => {
                return this.state.isExpand;
              }}
              pagination={true}
              paginationPerPage={this.state.paginationPerPage}
              paginationServer={true}
              paginationTotalRows={this.state.totalRows}
              paginationRowsPerPageOptions={this.state.rowPerPageOption}
              paginationDefaultPage={this.state.currentPage}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              progressPending={this.state.progressPending}
              progressComponent={this.defaultProgressComponent()}
            ></DataTable>
          </div>
          <div id="Template" className="tab-pane" style={{ margin: "-10px" }}>
            <Template
              onRef={(ref) => (this.template = ref)}
              cropYearId={this.props.match.params.id}
              loggedUser={loggedUser}
              processorId={loggedUser.processorId}
              testTypeId={this.state.testType._id}
              testType={this.state.testType}
              valueDecimals={this.state.valueDecimals}
              valueDecimalStep={this.state.valueDecimalStep}
              refreshMainTab={this.refreshMainTab}
            />
          </div>
        </div>
      </div>
    );
  };

  refreshMainTab = () => {
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.getItemsServerPaging(this.state.currentPage);
      }
    );
  };

  conditionalRowStyles = () => {
    return [
      {
        when: (row) => row.growerId === "",
        style: {
          backgroundColor: "#33ffcc",
          color: "black",
        },
      },
      {
        when: (row) =>
          row.children === null ||
          row.children === undefined ||
          row.children.length <= 0,
        style: {
          backgroundColor: "#ffdd99",
        },
      },
    ];
  };

  extendButtons = () => {
    return (
      <>
        {this.state.testType.isDynamic && (
          <TestTypePopup testType={this.state.testType}></TestTypePopup>
        )}
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>
        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <a
              href="#"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  growerId: "",
                  varietyId: "",
                  paymentId: "",
                  valueInshell: 0,
                  valueShelled: 0,
                  primaryPrice: this.state.testType.isDynamic
                    ? "Highest of Both"
                    : "",
                  customAttributes: "",
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              className="btn btn-primary mb-sm"
            >
              <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
            </a>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
            {this.state.testType.varietyOptional !== true ? (
              <button
                type="button"
                onClick={() => this.handleValidate()}
                title="Check base prices."
                className="btn btn-warning mb-sm"
              >
                <i className="fa fa-bolt fa-lg"></i> Validate
              </button>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  };

  extendRender = () => {
    const isAddScreen = this.state.data._id === "";

    return (
      <>
        {this.state.testType.varietyOptional !== true ? (
          <Modal
            show={this.state.showError}
            onHide={this.handleCloseError}
            animation={false}
            backdropClassName="modal-backdrop foo-modal-backdrop in"
            centered
          >
            <Modal.Header>
              <Modal.Title>Invalid Setting</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ margin: "-15px", marginBottom: "-30px" }}>
              <div className="alert alert-success" role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="alert-heading">Why invalid?</h4>
                <p>
                  <ul>
                    <li>
                      Base price must be set for varieties those using in tests.
                    </li>
                    <li>
                      {this.state.nameVarieties &&
                      this.state.nameVarieties.indexOf(", ") !== -1
                        ? "Varieties are "
                        : "Variety is "}
                      using for tests includes{" "}
                      <strong>{this.state.nameVarieties}</strong>.
                    </li>
                  </ul>
                </p>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  position: "relative",
                  maxHeight: "500px",
                }}
              >
                <DataTable
                  highlightOnHover
                  striped
                  noHeader={true}
                  columns={this.setColumnsInvalidPopup()}
                  data={this.state.invalidVarietyNames}
                  className="table table-bordered table-striped mb-none"
                  pagination={false}
                ></DataTable>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-center">
                <Button variant="warning" onClick={this.handleCloseError}>
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={true}
          isWizard={true}
          loggedUser={loggedUser}
          baseApiUrl={GlobalConfig.REACT_APP_API_BASEPRICE_URL}
          handleShowWithWizardMode={this.handleShowWithWizardMode}
          changeWizardMode={this.changeWizardMode}
          parseViewModel={parseViewModel}
          columns={wizardColumns(
            (row, index) => {
              if (this.listGrower) {
                this.listGrower.setEditedIndex(index);
              }
              this.handleShowWithWizardMode(row);
            },
            this.state.testType,
            loggedUser,
            (index) => {
              if (this.listGrower) {
                this.listGrower.removeRow(index);
              }
            }
          )}
        />
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {isAddScreen ? "Add Base Price" : "Edit Base Price"}{" "}
                {this.state.cropYearName !== ""
                  ? " - " + this.state.cropYearName
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  {!this.state.wizardMode && (
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="attribute"
                      >
                        Default/Grower <span className="required">(*)</span>
                      </label>
                      <div className="col-md-9">
                        <SelectGrowers
                          required={false}
                          onRef={(ref) => (this.growerId = ref)}
                          name="growerId"
                          value={this.state.data.growerId}
                          selectedIds={this.state.data.growerId}
                          handleChangeSelectGrower={
                            this.handleChangeSelectGrower
                          }
                          testTypeId={this.state.testType._id}
                          cropYearId={this.state.parentId}
                          checkExistPaymentSetting={true}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.testType.varietyOptional !== true ? (
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="attribute"
                      >
                        Variety <span className="required">(*)</span>
                      </label>
                      <div className="col-md-9">
                        <MultiSelectVarieties
                          isMulti={
                            (this.state.wizardMode === false &&
                              (this.state.data._id == null ||
                                this.state.data._id === undefined ||
                                this.state.data._id === "")) ||
                            (this.state.wizardMode && !this.state.isWizardEdit)
                          }
                          showSelectAll
                          required={true}
                          onRef={(ref) => (this.varietyId = ref)}
                          name="varietyId"
                          controlId="varietyId"
                          selectedIds={this.state.data.varietyId}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                          urlApi={
                            GlobalConfig.REACT_APP_API_VARIETY_URL +
                            "byParentId/" +
                            this.state.testType._id
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.testType.basePriceByPayment ? (
                    <div className="form-group">
                      <SelectControl
                        labelClass="3"
                        inputClass="9"
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        onRef={(ref) => (this.paymentId = ref)}
                        placeholder="Payment"
                        label="Payment"
                        controlId="paymentId"
                        value={this.state.data.paymentId}
                        required
                        urlapilist={`${GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}bycropyear/${this.props.match.params.id}/true`}
                        isHideAddButton={true}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.testType.isDynamic ? (
                    ""
                  ) : (
                    <div className="form-group">
                      <label
                        className="col-md-3 control-label"
                        htmlFor="attribute"
                      >
                        Primary Price <span className="required">(*)</span>
                      </label>
                      <div className="col-md-9">
                        <SelectConfig
                          configType="Primary Price"
                          required={true}
                          onRef={(ref) => (this.primaryPrice = ref)}
                          name="primaryPrice"
                          controlId="primaryPrice"
                          selectedIds={this.state.data.primaryPrice}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <NumericInput
                      precision={4}
                      min={0}
                      max={999999999}
                      step={0.0001}
                      label={
                        this.state.testType.isDynamic
                          ? this.state.testType.xpool === true
                            ? "Regular Price"
                            : "Price"
                          : "Inshell"
                      }
                      name="valueInshell"
                      value={this.state.data.valueInshell}
                      handleChange={this.handleChangeObjectData}
                      unit="$"
                      labelClass="3"
                      inputClass="5"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>

                  {this.state.testType.isDynamic ? (
                    this.state.testType.xpool === true ? (
                      <div className="form-group">
                        <NumericInput
                          precision={4}
                          min={0}
                          max={999999999}
                          step={0.0001}
                          label="Alternate Price"
                          name="valueShelled"
                          value={this.state.data.valueShelled}
                          handleChange={this.handleChangeObjectData}
                          unit="$"
                          labelClass="3"
                          inputClass="5"
                          required={true}
                          labelrequired="(*)"
                        />
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="form-group">
                      <NumericInput
                        precision={4}
                        min={0}
                        max={999999999}
                        step={0.0001}
                        label="Shelled"
                        name="valueShelled"
                        value={this.state.data.valueShelled}
                        handleChange={this.handleChangeObjectData}
                        unit="$"
                        labelClass="3"
                        inputClass="5"
                        required={true}
                        labelrequired="(*)"
                      />
                    </div>
                  )}
                  {this.state.testType.isDynamic ? (
                    <div className="form-group">
                      <TableAttribute
                        name="customAttributes"
                        onRef={(ref) => (this.customAttributes = ref)}
                        handleTableAttributeChange={
                          this.handleTableAttributeChange
                        }
                        processorId={loggedUser.processorId}
                        value={this.state.data.customAttributes}
                        testTypeId={this.state.testType._id}
                        isDynamic={this.state.testType.isDynamic}
                        apiUrlDynamicAttribute={
                          GlobalConfig.REACT_APP_API_NUTTYPE_URL +
                          "getMetaDataFieldsByTestType/" +
                          this.state.testType._id
                        }
                        readOnly={
                          this.state.testType._id === undefined ||
                          this.state.testType._id === null ||
                          this.state.testType._id === ""
                        }
                        loggedUser={loggedUser}
                        GlobalConfig={GlobalConfig}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected crop year no longer exists. Please refresh and try
                    again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-center">
              {this.state.data ? (
                <Button
                  variant="success"
                  type="submit"
                  disabled={this.state.saveLoading}
                >
                  <span className="fa fa-save fa-lg"></span> Save
                </Button>
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>

          <LoadingScreen
            loading={this.state.saveLoading}
            bgColor="#00000060"
            spinnerColor="#0275d8"
            textColor="#0275d8"
            text={isAddScreen ? "Adding..." : "Updating..."}
          />
        </Modal>
      </>
    );
  };

  changeWizardMode = (toggle) => {
    this.setState({
      wizardMode: toggle,
    });
  };

  filterItems = ({ testType, varietyIds, existItems, compareObj }) => {
    const newCustomAttributes =
      compareObj.customAttributes === undefined ||
      compareObj.customAttributes === null ||
      compareObj.customAttributes === ``
        ? []
        : JSON.parse(compareObj.customAttributes);

    let items = existItems;
    if (items && items.length > 0) {
      if (testType.varietyOptional !== true) {
        items = items.filter(({ varietyId = "" }) => {
          return varietyIds?.includes(varietyId?.toString());
        });
      }
      if (testType.basePriceByPayment === true) {
        items = items.filter(({ paymentId = "" }) => {
          return compareObj.paymentId === paymentId;
        });
      }
    }
    if (items && items.length > 0) {
      const errorMessage = CommonUtilities.checkDuplicateMetaAttribute(
        testType,
        newCustomAttributes,
        items
      );
      if (errorMessage !== ``) {
        return existItems;
      } else {
        return [];
      }
    }

    return items;
  };

  handleShowWithWizardMode = (data) => {
    let isWizardEdit = data ? true : false;
    const dataModel = data
      ? data
      : {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          varietyId: this.state.previousVarietyId,
          paymentId: this.state.previousPaymentId,
          valueInshell: 0,
          valueShelled: 0,
          primaryPrice: this.state.previousPrimaryPrice,
          customAttributes: "",
        };
    var convertedJSON = JSON.parse(JSON.stringify(dataModel));
    this.setState({ data: convertedJSON }, () => {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isWizardEdit: isWizardEdit,
        },
        () => {
          //Set selected dropdown
          if (this.varietyId) {
            this.varietyId.setSelected(convertedJSON.varietyId);
          }
          if (this.primaryPrice) {
            this.primaryPrice.setSelected(convertedJSON.primaryPrice);
          }
          if (this.state.testType.basePriceByPayment) {
            this.paymentId.setSelected(convertedJSON.paymentId);
          }
        }
      );
    });
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }

    this.setState(
      { data: convertedJSON, wizardMode: false },
      function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
            isEditMode: isEditMode,
          },
          function callBack() {
            //Set selected dropdown
            if (this.varietyId) {
              this.varietyId.setSelected(data.varietyId);
            }
            if (this.primaryPrice) {
              this.primaryPrice.setSelected(data.primaryPrice);
            }
            if (this.state.testType.basePriceByPayment) {
              this.paymentId.setSelected(data.paymentId);
            }
          }
        );
      }
    );
  };

  handleSave = async (event) => {
    event.preventDefault();
    // set saveLoading to true
    this.setState({ saveLoading: 1 });
    //Reset message
    this.setState({
      errorMessage: "",
    });

    const varietyId = this.state.data.varietyId;
    let errorMessage = "";

    if (
      this.state.testType.varietyOptional !== true &&
      (varietyId == null || varietyId === undefined || varietyId.trim() === "")
    ) {
      errorMessage = "Select variety to continue.";
    }
    if (errorMessage === "") {
      if (this.state.testType.isDynamic !== true) {
        const primaryPrice = this.state.data.primaryPrice;
        if (
          primaryPrice == null ||
          primaryPrice === undefined ||
          primaryPrice.trim() === ""
        ) {
          errorMessage = "Select primary price to continue.";
        } else {
          if (this.state.data.primaryPrice === "Inshell") {
            if (this.state.data.valueInshell <= 0) {
              errorMessage = "Inshell must be greater than zero.";
            }
          } else if (this.state.data.primaryPrice === "Shelled") {
            if (this.state.data.valueShelled <= 0) {
              errorMessage = "Shelled must be greater than zero.";
            }
          } else if (this.state.data.primaryPrice === "Highest of Both") {
            if (
              this.state.data.valueShelled <= 0 ||
              this.state.data.valueInshell <= 0
            ) {
              errorMessage = "Inshell and Shelled must be greater than zero.";
            }
          }
        }
      } else {
        if (this.state.testType.xpool === true) {
          if (this.state.data.valueInshell <= 0) {
            errorMessage = "Regular Price must be greater than zero.";
          } else if (this.state.data.valueShelled <= 0) {
            errorMessage = "Alternate Price must be greater than zero.";
          }
        } else {
          if (this.state.data.valueInshell <= 0) {
            errorMessage = "Price must be greater than zero.";
          }
        }
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
        saveLoading: 0,
      });
      return;
    }
    try {
      if (this.state.wizardMode) {
        this.saveWizard();
      } else {
        this.save();
      }
    } catch (error) {
      window.showAlert("Error", error, "error");
      this.setState({ saveLoading: 0 });
    }
  };

  save = async () => {
    let previousGrowerId = this.state.data.growerId;
    let previousPrimaryPrice = this.state.data.primaryPrice;
    let previousVarietyId = this.state.data.varietyId;
    let previousPaymentId = this.state.data.paymentId;
    //Using for edit
    let urlApi = GlobalConfig.REACT_APP_API_BASEPRICE_URL + this.state.data._id;
    let method = "PATCH";
    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_BASEPRICE_URL;
      isEditMode = false;
    }
    let requestData = { ...this.state.data };
    requestData = JSON.parse(JSON.stringify(requestData));
    requestData.testType = this.state.testType;
    if (this.state.testType.basePriceByPayment !== true) {
      requestData.paymentId = ``;
    }
    if (this.state.testType.varietyOptional === true) {
      requestData.varietyId = ``;
    }

    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        //If created successful
        if (resultObject.result === "OK") {
          window.showAlert("", "Save successful!", "");
          that.setState(
            {
              isChanged: true,
              saveLoading: 0,
              previousGrowerId: previousGrowerId,
              previousPrimaryPrice: previousPrimaryPrice,
              previousVarietyId: previousVarietyId,
              previousPaymentId: previousPaymentId,
            },
            () => {
              that.getItemsServerPaging(that.state.currentPage);
              if (!isEditMode) {
                that.handResetForm();
              }
            }
          );
        } else {
          window.showAlert("Error", resultObject.message, "error");
          that.setState({ saveLoading: 0 });
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
        that.setState({ saveLoading: 0 });
      });
  };

  saveWizard = () => {
    if (this.state.isWizardEdit) {
      let dataObj = {
        ...this.state.data,
      };
      if (this.state.testType.varietyOptional !== true) {
        const variety = this.varietyId.state.data.find(
          (variety) => variety._id === this.state.data.varietyId
        );
        const varietyName = variety ? variety.name : "";
        dataObj.varietyName = varietyName;
      }
      const existItems = this.listGrower.state.defaultSettings.filter(
        (_, index) => {
          return index !== this.listGrower.state.editedIndex;
        }
      );
      let arrVarietyId = !!dataObj.varietyId
        ? dataObj.varietyId.split(",")
        : [];

      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        existItems: existItems,
        compareObj: {
          paymentId: dataObj.paymentId,
          customAttributes: dataObj.customAttributes,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: 0,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other date.",
          "error"
        );
        return;
      }
      if (this.state.testType.varietyOptional === true) {
        dataObj.varietyId = ``;
      }
      this.listGrower.editRow(dataObj);
      this.setState({
        show: false,
        saveLoading: 0,
      });
    } else {
      //Add new setting from popup copy wizard
      let {
        paymentId,
        paymentName,
        valueInshell,
        valueShelled,
        primaryPrice,
        customAttributes,
        varietyId,
      } = this.state.data;
      let arrVarietyId = !!varietyId ? varietyId.split(",") : [];
      const existItems = this.listGrower.state.defaultSettings;

      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        existItems,
        compareObj: {
          paymentId,
          customAttributes,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: 0,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other values.",
          "error"
        );
        return;
      }
      if (
        arrVarietyId === undefined ||
        arrVarietyId === null ||
        arrVarietyId.length <= 0
      ) {
        arrVarietyId = [{ undefined }];
      }
      const newItemList = arrVarietyId.map((varietyId) => {
        let varietyName = "";
        if (this.state.testType.varietyOptional !== true) {
          const variety = this.varietyId.state.data.find(
            (variety) => variety._id === varietyId
          );
          varietyName = variety ? variety.name : "";
        } else {
          varietyId = ``;
        }
        // tuong;
        let newObj = {
          _id: NodeUuid.v4(),
          paymentId: paymentId,
          paymentName: paymentName,
          valueInshell: valueInshell,
          valueShelled: valueShelled,
          primaryPrice: primaryPrice,
          customAttributes: customAttributes,
          varietyId: varietyId,
          varietyName: varietyName,
        };
        if (this.state.testType.varietyOptional !== true) {
          newObj = JSON.parse(JSON.stringify(newObj));
          newObj.varietyId = varietyId;
        }
        return newObj;
      });
      this.listGrower.appendRows(newItemList);
      this.setState({
        show: false,
        saveLoading: 0,
      });
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          varietyId: this.state.previousVarietyId,
          paymentId: this.state.previousPaymentId,
          valueInshell: 0,
          valueShelled: 0,
          primaryPrice: this.state.previousPrimaryPrice,
          customAttributes: "",
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        //Set selected dropdown
        if (this.varietyId) {
          this.varietyId.setSelected(this.state.previousVarietyId);
        }
        if (this.primaryPrice) {
          this.primaryPrice.setSelected(this.state.previousPrimaryPrice);
        }
        this.growerId.setSelected(this.state.previousGrowerId);
        if (this.state.testType.basePriceByPayment) {
          this.paymentId.setSelected(this.state.previousPaymentId);
        }
        if (this.customAttributes) {
          this.customAttributes.reset();
        }
      }
    );
  };

  handleCloseError = () => {
    this.setState({ showError: false });
  };

  handleValidate = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          parentId: this.state.parentId,
        }),
      };
      const that = this;
      await fetch(
        GlobalConfig.REACT_APP_API_BASEPRICE_URL + "validate/",
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            that.setState({ nameVarieties: resultObject.nameVarieties });
            if (
              resultObject.invalidVarietyNames &&
              resultObject.invalidVarietyNames !== ""
            ) {
              that.setState({
                showError: true,
                invalidVarietyNames: resultObject.invalidVarietyNames,
              });
            } else {
              window.showAlert("", "Base prices is valid.", "");
            }
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };
}
export default App;
