import React from "react";
import AddComponent from "../Base/AddComponent";
import AddFormTestField from "./form";
import SaveButton from "../Controls/SaveButton";

class App extends AddComponent {
  constructor(props) {
    super();

    this.state = {
      title: "Test Field",
      listtitle: "Test Fields",
      urllist: process.env.REACT_APP_URL_TEST_FIELD_LIST,
      sectionTitle: "Field Setting",
      loading: false,
    };
  }

  // Prevent handleSubmit has been called at AddComponent
  handleSubmit = (e) => {
    this.refFormTestField.handleSubmit(e);
  };

  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="col-lg-6">
          <div className="form-horizontal">
            <AddFormTestField
              onRef={(ref) => (this.refFormTestField = ref)}
              setLoading={this.setLoading}
            />
          </div>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row">
          <div className="col-sm-9 col-sm-offset-3">
            <SaveButton
              type="submit"
              form={"form"}
              loading={this.state.loading}
            />
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          </div>
        </div>
      </footer>
    );
  };
}
export default App;
