export const TEST_STATUS_NEW = "New";
export const TEST_STATUS_APPROVED = "Approved";
export const TEST_STATUS_WAITING_APPROVAL = "Waiting Approval";

export const RELEASE_TYPE_NEVER = "Never";
export const RELEASE_TYPE_AFTER_APPROVED = "After a Test has been Approved";
export const RELEASE_TYPE_IMMEDIATELY = "Immediately";
export const RELEASE_TYPE_AFTER_N_DAY = "After a [n] day delay";

export const FIELD_TYPE_INTEGER = "Integer";
export const FIELD_TYPE_PERCENT = "Percent";
export const FIELD_TYPE_DECIMAL = "Decimal";
export const FIELD_TYPE_DROP_DOWN = "Drop Down";
export const FIELD_TYPE_TEXT_BOX = "Text Box (Multiple Line)";
export const FIELD_TYPE_TEXT_SMALL = "Text Small (Single Line)";
export const FIELD_TYPE_CALCULATED = "Calculated";

export const SECTION_META_DATA = "Meta Data";
export const SECTION_QUALITY_DATA = "Quality Data";

export const USER_ROLE_ADMINISTRATOR = "Administrators";
export const USER_ROLE_STAFF = "Staffs";
export const USER_ROLE_PROCESSOR = "Processors";
export const USER_ROLE_LAB_USER = "Lab Users";
export const USER_ROLE_GROWER = "Growers";

export const METHOD_PATCH = "PATCH";
export const METHOD_DELETE = "DELETE";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_GET = "GET";

export const ROW_TYPE_TOTAL = "Total";
export const ROW_TYPE_AVERAGE = "Average";
export const ROW_TYPE_MIN = "Min";
export const ROW_TYPE_MAX = "Max";
export const ROW_TYPE_DATA = "Data";

export const TEST_TYPE_WALNUT = "Walnut";

export const VALUE_QUERY_PARAM_IGNORE = "__";

export const EXCLUDE_SYSTEM_FIELDS = "-createdUserId -modifiedUserId -modifiedDate -createdDate";
export const EXCLUDE_WALNUT_FIELDS = "-s1NetWeight -s1Jumbo -s1Large -s1Medium -s1Baby -s1PeeWee -s1Splits -s1Broken -s1AdheringHull -s2Insect -s2Rancid -s2Mold -s2Shrivel -s2Other -s2Blows -s2Light -s2LightAmber -s2Amber -s2Black -s3Weight -s3EdibleWeight -s3DefectWeight -s3TotalKernelWeight -s3EdibleYield -s4OtherVarietiesIncluded -s4SeriousStain -moisture -s4Remarks";

export const TAXFEE_APPLY_TYPE_FIRST = "All in First Payment";
export const TAXFEE_APPLY_TYPE_ACROSS = "Evenly Across Payments";
export const TAXFEE_APPLY_TYPE_NA = "N/A";
export const TAXFEE_APPLY_TYPE_NEXT_AVAILABLE = "All in Next Available Payment";

export const TAXFEE_TYPE_PER_POUND = "Per Pound";
export const TAXFEE_TYPE_ONE_TIME_FEE = "One Time Fee";
export const TAXFEE_TYPE_PER_DOLLAR = "Per Dollar";

export const EMAIL_SETTING_EMAIL_ADDRESS_NOTE = "You can enter multiple addresses separated by comma"

export const CRON_JOB_STATUS_SCHEDULED = "Scheduled";
export const CRON_JOB_STATUS_RUNNING = "Running";
export const CRON_JOB_STATUS_STOPPED = "Stopped";
export const CRON_JOB_TYPE_HOURLY = "Hourly";
export const CRON_JOB_TYPE_DAILY = "Daily";