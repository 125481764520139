import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import NumericInput from "../FormControl/NumericInput";
import Switch from "react-switch";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      sectionTitle: "Tier Information",
      title: "Tier",
      listtitle: "Tiers",
      urllist: process.env.REACT_APP_URL_TIER_LIST,
      urlapi: GlobalConfig.REACT_APP_API_TIER_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        numberOfTestsDeliveries: 0,
        isExportToPdf: false,
        isAllowGrowerPayment: false,
        isAllowExportApi: false,
        isAllowImportApi: false,
        isAllowDynamicTest: false,
        totalMonthly: 0,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      loading: parentState.loading,
    };
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeSwitchAllowExport =
      this.handleChangeSwitchAllowExport.bind(this);
    this.handleChangeSwitchAllowGrowePayment =
      this.handleChangeSwitchAllowGrowePayment.bind(this);
    this.handleChangeSwitchAllowExportApi =
      this.handleChangeSwitchAllowExportApi.bind(this);
    this.handleChangeSwitchAllowImportApi =
      this.handleChangeSwitchAllowImportApi.bind(this);
    this.handleChangeSwitchAllowDynamicTest =
      this.handleChangeSwitchAllowDynamicTest.bind(this);
  }

  handleChangeSwitchAllowExport(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isExportToPdf: checked,
      }),
    });
  }

  handleChangeSwitchAllowGrowePayment(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowGrowerPayment: checked,
      }),
    });
  }

  handleChangeSwitchAllowExportApi(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowExportApi: checked,
      }),
    });
  }

  handleChangeSwitchAllowImportApi(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowImportApi: checked,
      }),
    });
  }

  handleChangeSwitchAllowDynamicTest(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isAllowDynamicTest: checked,
      }),
    });
  }

  handleChangeNumber(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        ["numberOfTestsDeliveries"]: value,
      }),
    });
  }

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        numberOfTestsDeliveries: 0,
        isExportToPdf: false,
        isAllowGrowerPayment: false,
        totalMonthly: 0,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row">
          <div className="col-md-4 col-md-offset-2">
            <SaveButton loading={this.state.loading} />
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          </div>
        </div>
      </footer>
    );
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-md-2 control-label">
            Tier <span className="required">*</span>
          </label>
          <div className="col-md-4">
            <input
              type="text"
              name="name"
              autoFocus={true}
              className="form-control"
              placeholder=""
              required
              value={this.state.data.name}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>
        </div>
        <NumericInput
          precision={0}
          min={0}
          max={999999999}
          step={1}
          label="Tests (Deliveries)"
          name="numberOfTestsDeliveries"
          value={this.state.data.numberOfTestsDeliveries}
          handleChange={this.handleChange}
          labelClass="2"
          inputClass="4"
          required={false}
          labelrequired=""
        />
        <div className="form-group">
          <div className="col-md-6">
            <NumericInput
              precision={1}
              min={0}
              max={999999999}
              step={1}
              label="Total Monthly ($)"
              name="totalMonthly"
              value={this.state.data.totalMonthly}
              handleChange={this.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">Allow Export to PDF</label>
          <div className="col-md-4">
            <Switch
              name="isExportToPdf"
              onChange={this.handleChangeSwitchAllowExport}
              checked={this.state.data.isExportToPdf}
              className="react-switch"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">Allow Export API</label>
          <div className="col-md-10">
            <Switch
              name="isAllowExportApi"
              onChange={this.handleChangeSwitchAllowExportApi}
              checked={this.state.data.isAllowExportApi}
              className="react-switch"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">Allow Import API</label>
          <div className="col-md-10">
            <Switch
              name="isAllowImportApi"
              onChange={this.handleChangeSwitchAllowImportApi}
              checked={this.state.data.isAllowImportApi}
              className="react-switch"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">Allow Grower Payment</label>
          <div className="col-md-10">
            <Switch
              name="isAllowGrowerPayment"
              onChange={this.handleChangeSwitchAllowGrowePayment}
              checked={this.state.data.isAllowGrowerPayment}
              className="react-switch"
            />
            <p>
              Allow/disallow processor (include staffs, lab users belong to)
              access grower payment feature.
            </p>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">Allow Dynamic Test</label>
          <div className="col-md-10">
            <Switch
              name="isAllowDynamicTest"
              onChange={this.handleChangeSwitchAllowDynamicTest}
              checked={this.state.data.isAllowDynamicTest}
              className="react-switch"
            />
          </div>
        </div>
      </div>
    );
  };
}
export default App;
