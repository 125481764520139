import React, { Component } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ExportUtilities from "../Utilities/ExportUtilities";
import CommonUtilities from "../Utilities/Common";
import SaveButton from "../Controls/SaveButton";

const GlobalConfig = new window.globalConfig();

export default class Export extends Component {
  constructor(props) {
    super();
    this.state = {
      exportLoading: false,
      pageSizeAdjustment: GlobalConfig.REACT_APP_PAGE_SIZE_ADJUSTMENTS_REPORT,
    };
  }

  exportExcel = async () => {
    try {
      this.setState({
        exportLoading: true,
      });
      window.showAlert(
        "Info",
        `Exporting...the file is ready download once done.`,
        "info"
      );
      const exportData = await this.getAdjustmentsToExport();
      const exportAdjustments = exportData.adjustments.map((adjustment) => {
        return {
          cropYear: this.props.cropYearName,
          growerName: this.getGrowerNameByGrowerId(
            adjustment.growerId,
            exportData.totalGrowers
          ),
          varietyName: adjustment.varietyName,
          attribute: adjustment.attribute,
          applyTo: adjustment.applyTo,
          min: parseFloat(adjustment.min / 100.0),
          max: parseFloat(adjustment.max / 100.0),
          value: adjustment.value,
          applyType: adjustment.applyType,
        };
      });
      const columnHeader = [
        [
          "Crop Year",
          "Grower Name",
          "Variety",
          "Quality Attribute",
          "Applies To",
          "From",
          "To",
          "Value",
          "Bonus or Deduction Applied",
        ],
      ];
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(exportAdjustments, {
        skipHeader: true,
        origin: "A2",
      });

      var fmtDollar = "$0.0000";
      const fmtPercent = "##0.00%";
      var rangeFromAndTo = {
        s: { r: 1, c: 5 },
        e: { r: exportAdjustments.length, c: 6 },
      };
      var rangeValue = {
        s: { r: 1, c: 7 },
        e: { r: exportAdjustments.length, c: 7 },
      };

      ExportUtilities.formatCells(ws, rangeFromAndTo, fmtPercent);
      ExportUtilities.formatCells(ws, rangeValue, fmtDollar);
      XLSX.utils.sheet_add_aoa(ws, columnHeader);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Adjustments.xlsx");
      this.setState({
        exportLoading: false,
      });
    } catch (exception) {
      this.setState({
        exportLoading: false,
      });
      window.showAlert("Error", `Can not export data. ${exception}`, "error");
    }
  };

  getAdjustmentsToExport = async () => {
    const getGrowersUrl = `${GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}/parent/${this.props.cropYearId}`;
    let totalGrowers = [];
    let adjustments = [];
    let pageNumber = CommonUtilities.calculateTotalPage(
      this.props.totalRows,
      this.props.pageSize
    );
    for (let page = 1; page <= pageNumber; page++) {
      const response = await fetch(
        `${getGrowersUrl}/${page}/${this.props.pageSize}`
      );
      const responseData = await response.json();
      if (
        responseData.result === "OK" &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        const growers = responseData.data;
        totalGrowers = totalGrowers.concat(responseData.data);
        for (let i = 0; i < growers.length; i++) {
          let page = 1;
          let totalPages = 1;
          while (page <= totalPages) {
            const response = await fetch(
              `${GlobalConfig.REACT_APP_API_BONUSDEDUCT_URL}children/${this.props.cropYearId}/${growers[i].growerId}/${page}/${this.state.pageSizeAdjustment}`
            );
            const responseData = await response.json();
            if (
              responseData.result === "OK" &&
              responseData.data &&
              responseData.data.length > 0
            ) {
              adjustments = adjustments.concat(responseData.data);
            }
            if (page === 1) {
              totalPages = CommonUtilities.calculateTotalPage(
                responseData.totalCount,
                this.state.pageSizeAdjustment
              );
            }
            page++;
          }
        }
      }
    }
    return {
      totalGrowers: totalGrowers,
      adjustments: adjustments,
    };
  };

  getGrowerNameByGrowerId = (growerId, growers) => {
    if (!growerId) {
      return "Default";
    } else {
      const grower = growers.filter((grower) => grower.growerId === growerId);
      return grower[0].grower;
    }
  };

  render() {
    return (
      <>
        <SaveButton
          variant="warning"
          loading={this.state.exportLoading}
          type="button"
          onClick={this.exportExcel}
          icon={
            <i
              className="fa fa-file-excel-o fa-lg"
              style={{ marginRight: 4 }}
            ></i>
          }
          propClassName="mb-sm"
          text="Export"
        ></SaveButton>
      </>
    );
  }
}
