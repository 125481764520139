import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { isNumber } from "lodash";
import _ from "lodash";
import {
  FIELD_TYPE_CALCULATED,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_TEXT_BOX,
  FIELD_TYPE_TEXT_SMALL,
  SECTION_QUALITY_DATA,
  ROW_TYPE_DATA,
  ROW_TYPE_MIN,
  ROW_TYPE_MAX,
  ROW_TYPE_TOTAL,
  ROW_TYPE_AVERAGE,
} from "../Utilities/Constant";
import { handleCombineFields } from "../Utilities/Common";
import QualityDataPopup from "./QualityDataPopup";
import { useState } from "react";

const MultiQualityData = (props) => {
  const {
    testTypeSetting,
    qualityData,
    section,
    setQualityData,
    classificationItems,
    loggedUser,
    calculatedFields,
    numberFields,
    inPopup,
    handleCloseModalWithoutResetData,
    handleOpenModal,
  } = props;
  const { selectedFields, allFields } = testTypeSetting;
  const [allowCalculate, setAllowCalculate] = useState(true);

  const handleCloneRow = (index) => {
    //Add new
    const newField = qualityData[index];
    const newQualityData = [
      ...qualityData.filter((field) => field.type === ROW_TYPE_DATA),
    ];
    newQualityData.push(newField);
    //Compose min and max
    composeMinMaxQualityData(newQualityData, getFields());
    //Calculate
    calculateSummary(newQualityData, getFields());
    //Set new
    setQualityData({
      qualityData: newQualityData,
    });
  };

  const getFields = () => {
    const combineFields = handleCombineFields(selectedFields, allFields);
    return combineFields?.filter((_) => _.section === section);
  };

  const handleRemoveRow = (index) => {
    //If this component is in Popup, we need to close modal to see confirm alert before remove any row
    if (inPopup && handleCloseModalWithoutResetData && handleOpenModal) {
      handleCloseModalWithoutResetData();
    }
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this test?",
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            //Remove
            const newQualityData = [
              ...qualityData.filter(
                (_, i) => i !== index && _.type === ROW_TYPE_DATA
              ),
            ];
            if (newQualityData.length !== 0) {
              //Compose min and max
              composeMinMaxQualityData(newQualityData, getFields());
              //Calculate
              calculateSummary(newQualityData, getFields());
            }
            setQualityData({
              qualityData: newQualityData,
            });
            if (
              inPopup &&
              handleCloseModalWithoutResetData &&
              handleOpenModal
            ) {
              handleOpenModal();
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
      afterClose: () => {
        if (inPopup && handleCloseModalWithoutResetData && handleOpenModal) {
          handleOpenModal();
        }
      },
    });
  };

  const handleFieldColumns = (fields) => {
    const isTextField = (fieldType) =>
      fieldType === FIELD_TYPE_DROP_DOWN ||
      fieldType === FIELD_TYPE_TEXT_BOX ||
      fieldType === FIELD_TYPE_TEXT_SMALL;

    const isNumberField = (fieldType) =>
      fieldType === FIELD_TYPE_INTEGER ||
      fieldType === FIELD_TYPE_PERCENT ||
      fieldType === FIELD_TYPE_DECIMAL ||
      fieldType === FIELD_TYPE_CALCULATED;

    return fields
      ?.map(({ name, testFieldId, fieldType, precision, defaultValue }) => {
        return {
          name,
          left: isTextField(fieldType),
          right: isNumberField(fieldType),
          selector: (row) => {
            const value = row?.[testFieldId];
            const isFieldTypeDropDown = fieldType === FIELD_TYPE_DROP_DOWN;
            const isPercent = fieldType === FIELD_TYPE_PERCENT;
            const isFieldHasPrecision =
              fieldType === FIELD_TYPE_INTEGER ||
              fieldType === FIELD_TYPE_PERCENT ||
              fieldType === FIELD_TYPE_DECIMAL;
            const isPrecisionExist = isNumber(precision) && precision >= 0;
            if (value === null || value === undefined) {
              const emptyValue = defaultValue
                ? displayValueWithPrecision(
                    isNumber(defaultValue)
                      ? defaultValue
                      : Number(defaultValue),
                    precision,
                    fieldType,
                    row.type
                  )
                : displayValueWithPrecision(0, precision, fieldType, row.type);
              return isPercent ? emptyValue + "%" : emptyValue;
            }
            if (isFieldHasPrecision && isPrecisionExist) {
              const precisionValue = displayValueWithPrecision(
                isNumber(value) ? value : Number(value),
                precision,
                fieldType,
                row.type
              );
              return isPercent ? precisionValue + "%" : precisionValue;
            }
            if (isFieldTypeDropDown) {
              const classificationName = classificationItems.find(
                ({ _id }) => _id === value
              )?.name;
              return classificationName;
            }
            if (value === defaultValue && fieldType === FIELD_TYPE_CALCULATED)
              return 0;
            return value;
          },
        };
      })
      .concat([
        {
          name: "",
          center: true,
          width: "120px",
          selector: (row, index) => {
            if (row.type === ROW_TYPE_DATA)
              return (
                <div className="test-type-action-table">
                  <QualityDataPopup
                    isAdd={false}
                    index={index}
                    fields={fields}
                    calculateSummary={calculateSummary}
                    composeMinMaxQualityData={composeMinMaxQualityData}
                    editData={row}
                    setQualityData={setQualityData}
                    qualityData={qualityData}
                    loggedUser={loggedUser}
                    calculatedFields={calculatedFields}
                    numberFields={numberFields}
                    maxOfRows={
                      testTypeSetting
                        ? testTypeSetting.multiTestNumberSamples
                        : 0
                    }
                  />
                  {qualityData.filter((row) => row.type === ROW_TYPE_DATA)
                    .length < testTypeSetting.multiTestNumberSamples ? (
                    <a
                      style={{ cursor: "pointer" }}
                      title="Click here to clone"
                      onClick={() => handleCloneRow(index)}
                    >
                      <span>
                        <i className="fa fa-copy fa-lg" aria-hidden="true"></i>
                      </span>
                    </a>
                  ) : (
                    <></>
                  )}
                  <a
                    style={{ cursor: "pointer" }}
                    title="Click here to remove"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <span>
                      <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                    </span>
                  </a>
                </div>
              );
          },
        },
      ]);
  };

  const conditionToCalculate = (field) => {
    let condition = [
      FIELD_TYPE_INTEGER,
      FIELD_TYPE_DECIMAL,
      FIELD_TYPE_PERCENT,
      FIELD_TYPE_CALCULATED,
    ];
    return condition.includes(field.fieldType);
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.type === ROW_TYPE_TOTAL || row.type === ROW_TYPE_AVERAGE,
      style: {
        backgroundColor: "#ffdd99",
        color: "Black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#ffdd99",
          color: "Black",
          border: "none",
        },
      },
    },
  ];

  const calculateSummary = (array, fields) => {
    const onlyDataRows = array.filter((row) => row.type === ROW_TYPE_DATA);
    let total = {
      type: ROW_TYPE_TOTAL,
    };
    let avg = {
      type: ROW_TYPE_AVERAGE,
    };
    if (fields !== null) {
      let attributes = [];
      onlyDataRows.map((item) => {
        const itemToArray = Object.entries(item)
          .map(([key, val]) => {
            let convertToNumber = Number(val);
            if (val !== null && convertToNumber !== NaN) {
              return { idTestField: key, value: convertToNumber };
            }
          })
          .filter((attribute) => attribute !== undefined);
        attributes = [...attributes, ...itemToArray];
      });
      const dataAfterGroupBy = _.groupBy(attributes, "idTestField");
      // Create total and avg
      fields.map((field) => {
        if (
          dataAfterGroupBy[field.testFieldId] &&
          conditionToCalculate(field)
        ) {
          let sum = dataAfterGroupBy[field.testFieldId].reduce(
            (total, currentValue) => {
              return total + currentValue.value;
            },
            0
          );
          //calculate
          total[field.testFieldId] = sum;
          avg[field.testFieldId] = parseFloat(
            parseFloat(sum / onlyDataRows.length).toFixed(
              field.precision ? field.precision : 1
            )
          );
        } else {
          let valueNotCalculate = 0;
          valueNotCalculate = conditionToCalculate(field)
            ? field.precision
              ? parseFloat(valueNotCalculate.toFixed(field.precision))
              : 0
            : "";
          total[field.testFieldId] = valueNotCalculate;
          avg[field.testFieldId] = valueNotCalculate;
        }
      });
      array.push(avg);
      array.push(total);
    }
  };

  const composeMinMaxQualityData = (qualityData, selectedFields) => {
    if (
      !selectedFields ||
      !selectedFields.length ||
      !selectedFields.length > 0 ||
      !qualityData ||
      !qualityData.length ||
      !qualityData.length > 0
    )
      return;

    const fieldsInQualityData = selectedFields.filter(
      (field) => field.section === SECTION_QUALITY_DATA
    );

    const qualityDataWithoutCalculatedRows = qualityData.filter(
      (row) => row.type === ROW_TYPE_DATA
    );

    if (
      !fieldsInQualityData.length > 0 ||
      !qualityDataWithoutCalculatedRows.length > 0
    ) {
      return;
    }

    let min = {
      type: ROW_TYPE_MIN,
    };
    let max = {
      type: ROW_TYPE_MAX,
    };
    fieldsInQualityData.forEach((field) => {
      let values = [];
      qualityDataWithoutCalculatedRows.forEach((row) => {
        if (isOneOfAttributesOfRow(row, field.testFieldId)) {
          values.push(Number(row[field.testFieldId]));
        }
      });
      if (values.length > 0) {
        if (
          field.fieldType !== FIELD_TYPE_CALCULATED &&
          values.length !== qualityDataWithoutCalculatedRows.length
        )
          values.push(Number(field.defaultValue));
        min = { ...min, [field.testFieldId]: Math.min(...values) };
        max = { ...max, [field.testFieldId]: Math.max(...values) };
      } else {
        min = {
          ...min,
          [field.testFieldId]: field.defaultValue
            ? Number(field.defaultValue)
            : 0,
        };
        max = {
          ...max,
          [field.testFieldId]: field.defaultValue
            ? Number(field.defaultValue)
            : 0,
        };
      }
    });
    qualityData.push(min);
    qualityData.push(max);
  };

  const isOneOfAttributesOfRow = (row, testFieldId) => {
    const attributes = Object.keys(row);
    return attributes.includes(testFieldId);
  };

  const displayValueWithPrecision = (value, precision, fieldType, rowType) => {
    if (
      (fieldType === FIELD_TYPE_INTEGER || precision === 0) &&
      rowType === ROW_TYPE_AVERAGE
    )
      return value.toFixed(1);
    return Number(value).toFixed(precision);
  };

  if (allFields && allFields.length > 0) {
    const columns = handleFieldColumns(getFields());
    let resultRows = [];
    if (allowCalculate) {
      columns.unshift({
        name: "",
        center: true,
        width: "120px",
        selector: (row, index) => (
          <div key={index} style={{ fontWeight: 700 }}>
            {row.type !== ROW_TYPE_DATA ? row.type : ""}
          </div>
        ),
      });
      const customFieldsMultipleWithTotalAndAvg = qualityData.filter(
        (field) => field.type === ROW_TYPE_DATA
      );
      composeMinMaxQualityData(
        customFieldsMultipleWithTotalAndAvg,
        getFields()
      );
      calculateSummary(customFieldsMultipleWithTotalAndAvg, getFields());
      resultRows = customFieldsMultipleWithTotalAndAvg;
    } else {
      const customFieldsMultipleWithoutTotalAndAvg = qualityData.filter(
        (field) => field.type === ROW_TYPE_DATA
      );
      resultRows = customFieldsMultipleWithoutTotalAndAvg;
    }

    return (
      <div>
        <QualityDataPopup
          isAdd={true}
          fields={getFields()}
          calculateSummary={calculateSummary}
          composeMinMaxQualityData={composeMinMaxQualityData}
          setQualityData={setQualityData}
          qualityData={qualityData}
          loggedUser={loggedUser}
          setAllowCalculate={setAllowCalculate}
          allowCalculate={allowCalculate}
          calculatedFields={calculatedFields}
          numberFields={numberFields}
          maxOfRows={
            testTypeSetting ? testTypeSetting.multiTestNumberSamples : 0
          }
        />
        {qualityData?.length > 0 && (
          <DataTable
            style={{ marginBottom: 0 }}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            striped
            noHeader
            default
            columns={columns}
            data={resultRows}
            className="table table-bordered table-striped"
          />
        )}
      </div>
    );
  }
  return <></>;
};

export default MultiQualityData;
