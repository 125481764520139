import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import AuditTypeSelect from "./AuditTypeSelect";
import AuditActionSelect from "./AuditActionSelect";
import CommonUtilities from "../Utilities/Common";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_PROCESSOR,
  USER_ROLE_STAFF,
} from "./../Utilities/Constant";

var DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    let urlapi = "";
    let extendFilter = "/all";
    const objectId = props.match.params.id;
    if (
      window.location.pathname.startsWith(
        process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/object"
      ) &&
      objectId !== null &&
      objectId !== null &&
      objectId !== ""
    ) {
      extendFilter = "/" + objectId;
    }
    if (loggedUser.role === "Processors") {
      urlapi =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byprocessor/" +
        loggedUser.processorId +
        extendFilter;
    } else if (loggedUser.role === "Staffs") {
      urlapi =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byprocessor/" +
        loggedUser.processorId +
        extendFilter;
    } else if (loggedUser.role === "Administrators") {
      urlapi = GlobalConfig.REACT_APP_API_AUDIT_URL + "byadmin" + extendFilter;
    }

    this.state.urlapidelete = GlobalConfig.REACT_APP_API_AUDIT_URL;
    this.state.urladd = "";
    this.state.urlapi = urlapi;
    this.state.urllist = process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST;
    this.state.title = "Audit Logs";
    this.state.pluraltitle = "Audit Logs";
    this.state.filterUser = "";
    this.state.filterAction = "";
    this.state.filterType = "";
    this.state.filterStartDate = "";
    this.state.filterEndDate = "";
    this.state.isHideFilter = extendFilter !== "" && extendFilter !== "/all";
    this.state.pagination = true;
    this.state.showRefresh2 = true;
    this.state.objectId = objectId;

    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.state.startDate = "";
    this.state.endDate = "";

    this.state.pageName = "Audit Log";
    this.state.childOverrideComponentDidMount = true;
  }

  async componentDidMountChild() {
    if (loggedUser.role == "Administrators") {
      this.getItemsServerPaging(this.state.currentPage);
      this.loadColumnSettings();
      return;
    }
    const that = this;
    fetch(GlobalConfig.REACT_APP_API_PROCESSOR_URL + loggedUser.processorId)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (
          resultObject != null &&
          resultObject.result === "OK" &&
          resultObject.data
        ) {
          if (
            loggedUser.role == "Processors" ||
            loggedUser.role == "Lab Users" ||
            loggedUser.role == "Staffs"
          ) {
            that.getItemsServerPaging(that.state.currentPage);
            that.loadColumnSettings();
          } else {
            that.setState({
              useExtendBody: true,
            });
          }
        } else {
          window.showAlert(
            "Error",
            "Processor does not exist longer. Please switch to other processor and try again.",
            "error"
          );
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Version",
        selector: "version",
        sortable: false,
        center: true,
        cell: (row) => (
          <a
            target="_blank"
            href={this.state.urllist + "/" + row._id}
            className="on-default edit-row"
            title="Click here to view detail"
          >
            Version {row.version}
          </a>
        ),
        omit: this.state.isHideFilter === false,
      },
      {
        name: "Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM:ss TT");
        },
        minWidth: "160px",
      },
      {
        name: "User",
        selector: "createdUser",
        sortable: false,
        left: true,
        cell: (row) => row.createdUser,
      },
      {
        name: "Action",
        selector: "action",
        sortable: false,
        center: true,
        cell: (row) => {
          return (
            <span
              style={{
                color:
                  row.action === "Delete" || row.action === "Remove Invite"
                    ? "red"
                    : row.action === "Create" ||
                      row.action === "Copy" ||
                      row.action === "Invite"
                    ? "green"
                    : "",
                // fontWeight: "bold",
              }}
            >
              {row.action}
            </span>
          );
        },
      },
      {
        name: "Type",
        selector: "type",
        sortable: false,
        center: true,
        omit: this.state.isHideFilter,
      },
      {
        name: "",
        cell: (row) => (
          <a
            target="_blank"
            href={this.state.urllist + "/" + row._id}
            className="on-default edit-row"
            title="Click here to view detail"
          >
            Detail
          </a>
        ),
      },
    ];
  };

  defaultTitle = () => {
    if (
      this.state.items &&
      this.state.items.length > 0 &&
      this.state.isHideFilter
    ) {
      return "Revision history of " + this.state.items[0].type;
    }
    return "Audit Logs ";
  };

  extendButtons = () => {
    return "";
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleStartDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterStartDate: getDate,
        startDate: date,
      });
    } else {
      this.setState({
        filterStartDate: "",
        startDate: "",
      });
    }
  };

  handleEndDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        filterEndDate: getDate,
        endDate: date,
      });
    } else {
      this.setState({
        filterEndDate: "",
        endDate: "",
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      return;
    }
  }

  filterItems = () => {
    const startDate =
      this.state.filterStartDate && this.state.filterStartDate !== ""
        ? this.state.filterStartDate
        : "all";
    const endDate =
      this.state.filterEndDate && this.state.filterEndDate !== ""
        ? this.state.filterEndDate
        : "all";

    if (
      (startDate != null &&
        startDate != undefined &&
        startDate != "" &&
        startDate != "all" &&
        !CommonUtilities.isValidDate(startDate)) ||
      (endDate != null &&
        endDate != undefined &&
        endDate != "" &&
        endDate != "all" &&
        !CommonUtilities.isValidDate(endDate))
    ) {
      return;
    }

    let sDate = undefined;
    let eDate = undefined;
    if (startDate !== "all") {
      sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
    }
    if (endDate !== "all") {
      eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
    }
    const startDateFilter = startDate !== "all" ? sDate : startDate;
    const endDateFilter = endDate !== "all" ? eDate : endDate;

    const createdUserId =
      this.state.filterUser && this.state.filterUser !== ""
        ? this.state.filterUser
        : "all";
    const filterAction =
      this.state.filterAction && this.state.filterAction !== ""
        ? this.state.filterAction
        : "all";
    const filterType =
      this.state.filterType && this.state.filterType !== ""
        ? this.state.filterType
        : "all";

    let urlapi = "";
    let extendFilter = "/all";
    const objectId = this.state.objectId;
    if (
      window.location.pathname.startsWith(
        process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST + "/object"
      ) &&
      objectId !== null &&
      objectId !== null &&
      objectId !== ""
    ) {
      extendFilter = "/" + objectId;
    }
    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      urlapi =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byprocessor/" +
        loggedUser.processorId +
        extendFilter +
        "/" +
        createdUserId +
        "/" +
        filterType +
        "/" +
        filterAction +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter;
    } else if (loggedUser.role === "Administrators") {
      urlapi =
        GlobalConfig.REACT_APP_API_AUDIT_URL +
        "byadmin" +
        extendFilter +
        "/" +
        createdUserId +
        "/" +
        filterType +
        "/" +
        filterAction +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter;
    }

    this.setState({ urlapi: urlapi, currentPage: 1 }, () => {
      this.getItemsServerPaging(this.state.currentPage, this.state.perPage);
    });
  };

  handleReset = () => {
    this.growerControl?.reset();
    this.filterType?.reset();
    this.filterAction?.reset();
    this.setState(
      {
        filterUser: "",
        filterAction: "",
        filterType: "",
        filterStartDate: "",
        startDate: "",
        filterEndDate: "",
        endDate: "",
      },
      () => this.filterItems()
    );
  };

  renderSearch = () => {
    return (
      <>
        {(loggedUser.role !== "Administrators" &&
          loggedUser.role !== "Processors" &&
          loggedUser.role !== "Staffs" &&
          loggedUser.role !== "Lab Users") ||
        this.state.isHideFilter ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-12 selectAuditLogsContainer">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <FilterSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.growerControl = ref)}
                placeholder=""
                label="Filter Users"
                controlId="filterUser"
                urlapilist={this.state.filterControlUrls?.urlApiUsersFilter}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search select-config-container mb-sm">
              <AuditTypeSelect
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.filterType = ref)}
                placeholder=""
                label="Filter Types"
                name="filterType"
                userRole={loggedUser.role}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search select-config-container mb-sm">
              <AuditActionSelect
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.filterAction = ref)}
                placeholder=""
                label="Filter Actions"
                name="filterAction"
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <div className="inputDateTimeContainer">
                <div className="input-group inputDateTime">
                  <span className="input-group-addon">
                    <i className="fa fa-calendar"></i>
                  </span>
                  <DatePicker
                    className="form-control inputRenderSearch"
                    placeholderText="Start date"
                    selected={this.state.startDate}
                    onChange={this.handleStartDate}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onBlur={this.handleOnBlur}
                  />
                </div>
                <div className="input-group">
                  <span className="input-group-addon">to</span>
                  <DatePicker
                    className="form-control inputRenderSearch"
                    placeholderText="End date"
                    selected={this.state.endDate}
                    onChange={this.handleEndDate}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                    onBlur={this.handleOnBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div
        className="panel-actions panelActionsAuditLogs"
        style={{ top: "0px" }}
      >
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  updateFilterControlUrls = () => {
    let urlApiUsersFilter = "";
    switch (loggedUser.role) {
      case USER_ROLE_ADMINISTRATOR: {
        urlApiUsersFilter =
          GlobalConfig.REACT_APP_API_AUDIT_URL + "users/" + loggedUser._id;
        break;
      }
      case USER_ROLE_PROCESSOR: {
        urlApiUsersFilter =
          GlobalConfig.REACT_APP_API_AUDIT_URL +
          "usersbyprocessor/" +
          loggedUser.processorId;
        break;
      }
      case USER_ROLE_STAFF: {
        urlApiUsersFilter =
          GlobalConfig.REACT_APP_API_AUDIT_URL +
          "usersbyprocessor/" +
          loggedUser.processorId +
          "/" +
          loggedUser._id;
        break;
      }
      default:
    }
    this.setState({
      filterControlUrls: {
        urlApiUsersFilter: urlApiUsersFilter,
      },
    });
  };
}
export default App;
