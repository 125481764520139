import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

export const parseViewModel = (dataModel) => {
  return {
    varietyId: dataModel.varietyId,
    attribute: dataModel.attribute,
    applyTo: dataModel.applyTo,
    applyType: dataModel.applyType,
    min: dataModel.min,
    max: dataModel.max,
    value: dataModel.value,
    varietyName: dataModel.varietyName,
    isDeduct: dataModel.value < 0 ? true : false,
  };
};

export const wizardColumns = (
  handleShowWithWizardMode,
  testType,
  loggedUser,
  removeRow
) => {
  return [
    {
      name: "Variety",
      selector: "varietyName",
      width: "150px",
      cell: (row, index) => (
        <a
          onClick={() => {
            handleShowWithWizardMode(row, index);
          }}
          href="# "
          title="Edit bonus & deduct"
        >
          {row.varietyName}
        </a>
      ),
      omit: testType.varietyOptional === true,
    },
    {
      name: "Quality Attribute",
      selector: "attribute",
      sortable: true,
      cell: (row, index) => {
        return loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          row.attribute
        ) : (
          <a
            onClick={() => {
              handleShowWithWizardMode(row, index);
            }}
            href="# "
            title="Edit bonus & deduct"
          >
            {row.attribute}
          </a>
        );
      },
    },
    {
      name: "Applies To",
      selector: "applyTo",
      sortable: false,
      width: "90px",
      omit: testType.isDynamic,
    },
    {
      name: "From",
      selector: "min",
      sortable: true,
      right: true,
      width: "85px",
      cell: (row) =>
        CommonUtilities.numberWithCommas(
          (row.min == null || row.min === undefined || isNaN(row.min)
            ? 0
            : parseFloat(row.min)
          ).toFixed(2)
        ) + "%",
    },
    {
      name: "To",
      selector: "max",
      sortable: true,
      right: true,
      width: "85px",
      cell: (row) =>
        CommonUtilities.numberWithCommas(
          (row.max == null || row.max === undefined || isNaN(row.max)
            ? 0
            : parseFloat(row.max)
          ).toFixed(2)
        ) + "%",
    },
    {
      name: "Value",
      selector: "value",
      sortable: true,
      right: true,
      width: "95px",
      cell: (row) => {
        return parseFloat(row.value) < 0 ? (
          <span className="deduct-color">
            ${CommonUtilities.numberWithCommasNegative(parseFloat(row.value))}
          </span>
        ) : (
          <span className="bonus-color">
            ${CommonUtilities.numberWithCommas(parseFloat(row.value))}
          </span>
        );
      },
    },
    {
      name: "Bonus or Deduction Applied",
      selector: "applyType",
      sortable: true,
      center: true,
      cell: (row) => row.applyType,
    },
    {
      name: "",
      width: "100px",
      cell: (row, index) => (
        <div>
          {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            ""
          ) : (
            <>
              <a
                title="Click to edit"
                href="# "
                onClick={() => {
                  handleShowWithWizardMode(row, index);
                }}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Click to remove"
                href="# "
                className="on-default remove-row"
                onClick={() => removeRow(index)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
            </>
          )}
        </div>
      ),
    },
  ];
};
