import React from "react";

class App extends React.Component {
  render() {
    return (
      <div className="sc-hHLeRK kaLCam table table-bordered table-striped mb-none">
        <div className="sc-kgflAQ jwfoYh">
          <div className="sc-bczRLJ kSdddW rdt_Table" role="table">
            <div className="sc-bBrHrO kcvhQi">
              <div style={{ padding: "24px" }}>Loading...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
