import ListComponent from "../Base/ListComponent";
import moment from "moment";
import MultiSelect from "../FormControl/MultiSelect";
import DatePicker from "react-datepicker";
import { Modal, Button } from "react-bootstrap";

const GlobalConfig = new window.globalConfig();

export default class App extends ListComponent {
  constructor(props) {
    super();
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_SYSTEM_LOG_URL}`;
    this.state.title = "System Log";
    this.state.pageName = "System Log";
    this.state.show = false;
    this.state.systemLogDetail = undefined;

    const currentDateTime = this.getCurrentDateTime();
    this.state.filterObject = {
      message: "",
      types: [],
      from: currentDateTime.from,
      to: currentDateTime.to,
    };
    this.state.selectedTypes = "";
  }

  getCurrentDateTime = () => {
    const from = new Date();
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    from.setMilliseconds(0);

    const to = new Date();
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    to.setMilliseconds(999);

    return {
      from,
      to,
    };
  };

  getItems = async () => {
    this.setState({
      progressPending: true,
    });
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.filterObject),
      };
      const request = await fetch(this.state.urlapi, requestOptions);
      const responseData = await request.json();
      if (responseData && responseData.result === "OK") {
        this.setState(
          { items: responseData.data, originalItems: responseData.data },
          () => {
            this.doSomethingAfterGetItems(responseData);
          }
        );
        this.setState({
          progressPending: false,
        });
      } else {
        this.setState(
          {
            alertshow: false,
            progressPending: false,
            alertmsg: ``,
          },
          () => {
            this.setState({
              alertmsg: responseData.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        );
      }
    } catch (err) {
      this.setState({
        alertshow: false,
        progressPending: false,
        alertmsg: ``,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  defaultColumns = () => {
    return [
      {
        name: "Type",
        selector: "level",
        width: "100px",
        cell: (row) => {
          const style = {
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
            fontSize: 12,
          };
          const level = row.level
            ? row.level.charAt(0).toUpperCase() + row.level.slice(1)
            : "";
          if (row.level === "info") {
            return (
              <div className="label label-info" style={style}>
                {level}
              </div>
            );
          }
          if (row.level === "error") {
            return (
              <div className="label label-danger" style={style}>
                {level}
              </div>
            );
          }
        },
      },
      {
        name: "Logged At",
        selector: "loggedAt",
        width: "250px",
        cell: (row) => <>{moment(row.loggedAt).format("MM-DD-YYYY hh:mm A")}</>,
      },
      {
        name: "Message",
        cell: (row) => {
          if (
            row.message?.length > GlobalConfig.REACT_APP_TRUNCATED_LENGTH_NUMBER
          ) {
            return `${this.truncateMessage(row.message)}...`;
          } else {
            return row.message;
          }
        },
      },
      {
        name: "Path",
        selector: "path",
      },
    ];
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  onChange = (e) => {
    this.setState({
      filterObject: {
        ...this.state.filterObject,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeType = (e) => {
    const types = e.map((type) => type.value);
    this.setState({
      filterObject: {
        ...this.state.filterObject,
        types: types,
      },
      selectedTypes: types.join(","),
    });
  };

  onDateChange = (datetime, type) => {
    this.setState({
      filterObject: { ...this.state.filterObject, [type]: datetime },
    });
  };

  onRowClicked = (row) => {
    this.setState({
      show: true,
      systemLogDetail: row,
    });
  };

  handleSearch = () => {
    this.getItems();
  };

  handleReset = () => {
    const currentDateTime = this.getCurrentDateTime();
    this.setState(
      {
        filterObject: {
          message: "",
          types: [],
          from: currentDateTime.from,
          to: currentDateTime.to,
        },
      },
      () => this.getItems()
    );
  };

  renderSearch = () => {
    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <input
              type="text"
              className="form-control inputRenderSearch inputFilter"
              name="message"
              placeholder="Filter Message"
              value={this.state.filterObject.message}
              onChange={this.onChange}
              maxLength={100}
            />
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <MultiSelect
              placeholder="Filter Type"
              closeMenuOnSelect={false}
              isMulti={true}
              value={this.state.selectedTypes}
              onChange={this.onChangeType}
              defaultOptions={[
                {
                  label: "Error",
                  value: "error",
                },
                {
                  label: "Info",
                  value: "info",
                },
              ]}
            ></MultiSelect>
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <div className="inputDateTimeContainer">
              <div
                className="input-group inputDateTime"
                style={{ width: "100%" }}
              >
                <span className="input-group-addon">
                  <i className="fa fa-calendar"></i>
                </span>
                <DatePicker
                  className="form-control inputRenderSearch dateInputWidth"
                  placeholderText="Start Date"
                  selected={this.state.filterObject.from}
                  showTimeSelect
                  dateFormat="MM-dd-yyyy hh:mm aa"
                  onChange={(datetime) => {
                    this.onDateChange(datetime, "from");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <div className="inputDateTimeContainer">
              <div className="input-group" style={{ width: "100%" }}>
                <span className="input-group-addon">to</span>
                <DatePicker
                  className="form-control inputRenderSearch dateInputWidth"
                  placeholderText="End Date"
                  selected={this.state.filterObject.to}
                  showTimeSelect
                  dateFormat="MM-dd-yyyy hh:mm aa"
                  onChange={(datetime) => {
                    this.onDateChange(datetime, "to");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleClose = () => {
    this.setState({
      show: false,
      systemLogDetail: undefined,
    });
  };

  truncateMessage = (message) => {
    if (typeof message === "string") {
      return message.substring(
        0,
        GlobalConfig.REACT_APP_TRUNCATED_LENGTH_NUMBER
      );
    } else {
      return "";
    }
  };

  extendRender = () => {
    return (
      <>
        <Modal
          show={this.state.systemLogDetail}
          className="system-log-detail-modal"
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          onHide={this.handleClose}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.systemLogDetail &&
                moment(this.state.systemLogDetail.loggedAt).format(
                  "YYYY-MM-DD hh:mm A"
                )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.systemLogDetail?.message}</Modal.Body>
          <Modal.Footer
            className="modal-footer-center"
            style={{ marginTop: 0 }}
          >
            <Button
              style={{ marginLeft: 0 }}
              variant="warning"
              onClick={this.handleClose}
            >
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
}
