import React from "react";

import Layout from "./layout";
import SendTestEmailForm from "./SendTestMailForm";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      title: "Email Settings",
    };
  }

  render() {
    const { title } = this.state;
    const { navRoute, location } = this.props;

    return (
      <>
        <Layout title={title} navRoute={navRoute} location={location}>
          <SendTestEmailForm />
        </Layout>
      </>
    );
  }
}
export default App;
