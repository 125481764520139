import React from "react";
import { Modal, Button } from "react-bootstrap";
import CommonUtilities from "../Utilities/Common";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
    };
  }

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  renderBodyPopup = () => {
    return (
      <ul>
        {this.props.testField.nutType
          .sort(CommonUtilities.getSortOrder("name"))
          .map((testType) => (
            <li style={{ listStyleType: "none" }} key={testType._id}>
              <a
                href={
                  process.env.REACT_APP_URL_NUTTYPE_LIST + "/" + testType._id
                }
              >
                {testType.name}
              </a>
            </li>
          ))}
      </ul>
    );
  };

  renderButton = () => {
    return (
      <div
        style={{ color: "#0099E6", cursor: "pointer" }}
        onClick={this.handleShow}
      >
        Test Types{" (" + this.props.testField.nutType.length + ")"}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderButton()}
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>{`List Test Types using ${this.props.testField.name}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderBodyPopup()}</Modal.Body>
          <Modal.Footer className="modal-footer-test-field">
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default App;
