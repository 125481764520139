import styled from "styled-components";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

export const SpaceStyled = styled(Space)`
  width: 100%;
`;

export const RangePickerStyled = styled(RangePicker)`
  width: 100%;
  padding: 7px 11px 7px !important;
`;