import React from "react";
import { Redirect } from "react-router-dom";
import { getLoggedUser } from "../../context/auth";
import Permission from "../../Permission";
import Component from "./add";
import ComponentDynamic from "./addDynamic";

let loggedUser = getLoggedUser();

class Page extends React.Component {
  constructor(props) {
    super();
    const query = new URLSearchParams(props.location.search);
    const isDynamic = query.get("dynamic");
    this.state = { dynamic: isDynamic };
  }

  render() {
    return this.state.dynamic === true || this.state.dynamic === "true" ?
      <Permission
        role={loggedUser.role}
        perform="tests"
        yes={() => <ComponentDynamic {...this.props} />}
        no={() => <Redirect to="/" />}
      /> : <Permission
        role={loggedUser.role}
        perform="tests"
        yes={() => <Component {...this.props} />}
        no={() => <Redirect to="/" />}
      />
  }
}
export default Page;