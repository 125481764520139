const XLSX = require("xlsx");

function formatCells(ws, range, fmt) {
  for (var R = range.s.r; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
      cell.z = fmt;
    }
  }
}

exports.formatCells = formatCells;
