import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import SelectConfig from "../Base/SelectConfig";
import Switch from "react-switch";
import NumericInput from "react-numeric-input";
import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_TEXT_BOX,
  FIELD_TYPE_TEXT_SMALL,
  FIELD_TYPE_CALCULATED,
} from "../Utilities/Constant";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Test Fields",
      urllist: process.env.REACT_APP_URL_TEST_FIELD_LIST,
      urlapi: GlobalConfig.REACT_APP_API_TEST_FIELD_URL,
      urlApiProcessorFilter:
        GlobalConfig.REACT_APP_API_PROCESSOR_URL + `filterDataProcessor`,
      urlApiStatusFilter:
        GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL + "likename/Test Status",
      urlApiClassificationItem: ``,
      data: this.initialObject(props),
      loading: parentState.loading,
    };
    this.handleChangeSwitchIsMandatory =
      this.handleChangeSwitchIsMandatory.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeSwitchIsSync = this.handleChangeSwitchIsSync.bind(this);
  }

  initialObject = (props) => {
    return {
      _id: props.match.params.id,
      name: "",
      processorId: loggedUser.processorId,
      status: "Active",
      fieldType: "",
      defaultValue: "",
      testClassificationId: "",
      isMandatory: false,
      isSync: false,
      pfCoreField: "",
      min: 0,
      max: 0,
      precision: 0,
      step: 0,
      maxLength: 100,
      unit: ``,
      createdUserId: "",
      createdDate: null,
      modifiedUserId: loggedUser._id,
      modifiedDate: new Date(),
      allowBulkUpdate: false,
    };
  };

  getClassificationUrl = () => {
    const processorId =
      loggedUser.role === "Administrators"
        ? this.state.data.processorId
        : loggedUser.processorId;
    return `${
      GlobalConfig.REACT_APP_API_CLASSIFICATION_URL +
      `byprocessor/` +
      processorId
    }`;
  };

  getTitle = () => {
    return this.state.data.name;
  };

  handleChangeSwitchIsMandatory(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isMandatory: checked,
      }),
    });
  }

  handleChangeSwitchIsSync(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isSync: checked,
      }),
    });
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
        }),
      },
      () => {
        if (controlId === `testClassificationId`) {
          const url =
            value === null || value === undefined || value === ``
              ? ``
              : `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${value}`;
          this.setState(
            {
              urlApiClassificationItem: url,
            },
            () => {
              //reload data for classification item dropdown
              if (this.defaultValue) {
                this.defaultValue.reload(url);
              }
            }
          );
        }

        if (controlId === `fieldType`) {
          const defaultValues = this.initializeValues();
          let min = defaultValues.min;
          let max = defaultValues.max;
          let step = defaultValues.step;
          let precision = defaultValues.precision;
          let newDefaultValue;
          if (
            value === FIELD_TYPE_DECIMAL ||
            value === FIELD_TYPE_PERCENT ||
            value === FIELD_TYPE_INTEGER
          ) {
            newDefaultValue = 0;
          } else {
            newDefaultValue = ``;
          }
          this.setState({
            data: Object.assign({}, this.state.data, {
              defaultValue: newDefaultValue,
              min: min,
              max: max,
              step: step,
              precision: precision,
            }),
          });
        }
      }
    );
  };

  doSomethingAfterGetItemById = () => {
    this.status.setSelected(this.state.data.status);
    this.fieldType.setSelected(this.state.data.fieldType);
    if (this.processorId) {
      this.processorId.setSelected(this.state.data.processorId);
    }
    if (this.state.data.fieldType === FIELD_TYPE_DROP_DOWN) {
      this.testClassificationId.setSelected(
        this.state.data.testClassificationId
      );

      const testClassificationId = this.state.data.testClassificationId;
      //reload data for classification item dropdown
      const url =
        testClassificationId === null ||
        testClassificationId === undefined ||
        testClassificationId === ``
          ? ``
          : `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${testClassificationId}`;
      this.setState(
        {
          urlApiClassificationItem: url,
        },
        () => {
          if (this.defaultValue) {
            this.defaultValue.setSelected(this.state.data.defaultValue);
            this.defaultValue.reload(url);
          }
        }
      );
    }
  };

  handleChangeNumericDefaultValue = (value) => {
    this.handleChange({
      target: { name: "defaultValue", value: value },
    });
  };

  handleFocus = (e) => {
    // if (e != null && e.target != null && e.target.type === "text") {
    //   e.target.select();
    //   if (
    //     (e.target.value && e.target.value === "0.00") ||
    //     (e.target.value && e.target.value === "0.0") ||
    //     (e.target.value && e.target.value === "0")
    //   ) {
    //     e.target.value = "";
    //   }
    // }
  };

  renderDefaultValue = () => {
    switch (this.state.data.fieldType) {
      case FIELD_TYPE_TEXT_SMALL:
        return (
          <input
            type="text"
            name="defaultValue"
            className="form-control"
            placeholder=""
            value={this.state.data.defaultValue}
            maxLength={this.state.data.maxLength}
            onChange={this.handleChange}
            required={this.state.data.isMandatory}
          />
        );
      case FIELD_TYPE_TEXT_BOX:
        return (
          <textarea
            rows={5}
            name="defaultValue"
            className="form-control"
            placeholder=""
            value={this.state.data.defaultValue}
            maxLength={this.state.data.maxLength}
            onChange={this.handleChange}
            required={this.state.data.isMandatory}
          />
        );
      case FIELD_TYPE_DROP_DOWN:
        return (
          <FilterSelectControl
            handleChangeSelectControl={this.handleChangeSelectControl}
            onRef={(ref) => (this.defaultValue = ref)}
            placeholder="Select Default Value"
            label="Select Default Value"
            controlId="defaultValue"
            urlapilist={this.state.urlApiClassificationItem}
            required={this.state.data.isMandatory}
          />
        );
      case FIELD_TYPE_DECIMAL:
        return (
          <div className="input-group">
            <NumericInput
              inputMode="numeric"
              strict
              // title={"Max is " + this.props.max}
              className="form-control"
              noStyle
              readOnly={false}
              min={this.state.data.min}
              max={this.state.data.max}
              precision={this.state.data.precision}
              step={this.state.data.step}
              name="defaultValue"
              value={this.state.data.defaultValue}
              onChange={this.handleChangeNumericDefaultValue}
              required={this.state.data.isMandatory}
              onFocus={this.handleFocus}
              style={{ zIndex: 1 }}
            />
          </div>
        );
      case FIELD_TYPE_PERCENT:
        return (
          <div className="input-group">
            <NumericInput
              inputMode="numeric"
              strict
              // title={"Max is " + this.props.max}
              className="form-control"
              noStyle
              readOnly={false}
              min={this.state.data.min}
              max={this.state.data.max}
              precision={this.state.data.precision}
              step={this.state.data.step}
              name="defaultValue"
              value={this.state.data.defaultValue}
              onChange={this.handleChangeNumericDefaultValue}
              required={this.state.data.isMandatory}
              onFocus={this.handleFocus}
              style={{ zIndex: 1 }}
            />
            <span className="input-group-addon">%</span>
          </div>
        );
      case FIELD_TYPE_INTEGER:
        return (
          <div className="input-group">
            <NumericInput
              inputMode="numeric"
              strict
              // title={"Max is " + this.props.max}
              className="form-control"
              noStyle
              readOnly={false}
              min={this.state.data.min}
              max={this.state.data.max}
              precision={this.state.data.precision}
              step={this.state.data.step}
              name="defaultValue"
              value={this.state.data.defaultValue}
              onChange={this.handleChangeNumericDefaultValue}
              required={this.state.data.isMandatory}
              onFocus={this.handleFocus}
              style={{ zIndex: 1 }}
            />
          </div>
        );
      case FIELD_TYPE_CALCULATED:
        return (
          <>
            <textarea
              rows={5}
              name="defaultValue"
              className="form-control"
              placeholder=""
              value={this.state.data.defaultValue}
              // maxLength={this.state.data.maxLength}
              onChange={this.handleChange}
              required={true}
            />
            <label>
              (?) Enter name of other test fields or name of standard fields
              (Net Weight/Process Weight, XPool Percent, XPool Weight, Regular
              Percent, Regular Weight).
              <br />
              Supported maths symbols
              <br />
              <strong>+</strong>&nbsp;&nbsp;&nbsp;Addition Operator eg. 2+3
              results 5
              <br />
              <strong>-</strong>&nbsp;&nbsp;&nbsp;Subtraction Operator eg. 3-2
              results 1
              <br />
              <strong>*</strong>&nbsp;&nbsp;&nbsp;Multiplication Operator eg.
              2*3 results 6
              <br />
              <strong>/</strong>&nbsp;&nbsp;&nbsp;Division operator eg 3/2
              results 1.5
              <br />
              <strong>(</strong>&nbsp;&nbsp;&nbsp;Opening Parenthesis
              <br />
              <strong>)</strong>&nbsp;&nbsp;&nbsp;Closing Parenthesis
              <br />
              Example: 1 - ( (Net Weight + XPool Weight) * XPool Percent)
            </label>
          </>
        );
      default:
        return <></>;
    }
  };

  initializeValues = () => {
    let min = 0;
    let max = 0;
    let step = 0;
    let precision = 0;
    switch (this.state.data.fieldType) {
      case FIELD_TYPE_DECIMAL:
        min = 0;
        max = 999999999;
        step = 1;
        precision = 2;
        break;
      case FIELD_TYPE_PERCENT:
        min = 0;
        max = 999999999;
        step = 0.1;
        precision = 1;
        break;
      case FIELD_TYPE_INTEGER:
        min = 0;
        max = 999999999;
        step = 1;
        precision = 0;
        break;
      default:
        min = 0;
        max = 999999999;
        step = 1;
        precision = 0;
        break;
    }
    return {
      min: min,
      max: max,
      step: step,
      precision: precision,
    };
  };

  renderDefaultValueExt = () => {
    const defaultValues = this.initializeValues();
    let min = defaultValues.min;
    let max = defaultValues.max;
    let step = defaultValues.step;
    // let precision = defaultValues.precision;

    return (
      <>
        <div className="form-group">
          <label className="col-sm-3 control-label">Min Value</label>
          <div className="col-sm-9">
            <div className="input-group">
              <NumericInput
                inputMode="numeric"
                strict
                className="form-control"
                noStyle
                readOnly={false}
                min={min}
                max={max}
                precision={this.state.data.precision}
                step={step}
                name="min"
                value={this.state.data.min}
                onChange={(value) => {
                  this.handleChange({
                    target: { name: "min", value: value },
                  });
                }}
                onFocus={this.handleFocus}
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">Max Value</label>
          <div className="col-sm-9">
            <div className="input-group">
              <NumericInput
                inputMode="numeric"
                strict
                className="form-control"
                noStyle
                readOnly={false}
                min={min}
                max={max}
                precision={this.state.data.precision}
                step={step}
                name="max"
                value={this.state.data.max}
                onChange={(value) => {
                  this.handleChange({
                    target: { name: "max", value: value },
                  });
                }}
                onFocus={this.handleFocus}
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 control-label">Increment</label>
          <div className="col-sm-9">
            <div className="input-group">
              <NumericInput
                inputMode="numeric"
                strict
                className="form-control"
                noStyle
                readOnly={false}
                min={min}
                max={max}
                precision={this.state.data.precision}
                step={step}
                name="step"
                value={this.state.data.step}
                onChange={(value) => {
                  this.handleChange({
                    target: { name: "step", value: value },
                  });
                }}
                onFocus={this.handleFocus}
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        </div>
        {this.state.data.fieldType !== FIELD_TYPE_INTEGER ? (
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Precision (Decimal Number)
            </label>
            <div className="col-sm-9">
              <div className="input-group">
                <NumericInput
                  inputMode="numeric"
                  strict
                  className="form-control"
                  noStyle
                  readOnly={false}
                  min={0}
                  max={8}
                  precision={0}
                  step={1}
                  name="precision"
                  value={this.state.data.precision}
                  onChange={(value) => {
                    this.handleChange({
                      target: { name: "precision", value: value },
                    });
                  }}
                  onFocus={this.handleFocus}
                  style={{ zIndex: 1 }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="form-group">
          <label className="col-sm-3 control-label">Unit</label>
          <div className="col-sm-9">
            <div className="input-group">
              <input
                type="text"
                name="unit"
                className="form-control"
                placeholder=""
                value={this.state.data.unit}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="col-md-8 col-lg-6">
          {loggedUser.role === "Administrators" ? (
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Apply Processor <span className="required">(*)</span>
              </label>
              <div className="col-sm-9">
                <FilterSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorId = ref)}
                  placeholder="Select Processor"
                  label="Select Processor"
                  controlId="processorId"
                  urlapilist={this.state.urlApiProcessorFilter}
                  required={true}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Field Name <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="name"
                autoFocus={true}
                className="form-control"
                placeholder=""
                required
                value={this.state.data.name}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Status <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <SelectConfig
                configType="Test Field Status"
                required={true}
                onRef={(ref) => (this.status = ref)}
                name="status"
                controlId="status"
                selectedIds={this.state.data.status}
                handleChangeSelectControl={this.handleChangeSelectControl}
              />
            </div>
          </div>
          {this.state.data.fieldType === FIELD_TYPE_CALCULATED ? (
            ""
          ) : (
            <div className="form-group">
              <label className="col-sm-3 control-label">Required</label>
              <div className="col-sm-9">
                <Switch
                  name="isMandatory"
                  onChange={this.handleChangeSwitchIsMandatory}
                  checked={this.state.data.isMandatory}
                  className="react-switch"
                />
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="col-sm-3 control-label">
              Type <span className="required">(*)</span>
            </label>
            <div className="col-sm-9">
              <SelectConfig
                configType="Test Field Type"
                required={true}
                onRef={(ref) => (this.fieldType = ref)}
                name="fieldType"
                controlId="fieldType"
                value={this.state.data.fieldType}
                handleChangeSelectControl={this.handleChangeSelectControl}
              />
            </div>
          </div>
          {this.state.data.fieldType === FIELD_TYPE_DROP_DOWN ? (
            <div className="form-group">
              <label className="col-sm-3 control-label">Classification</label>
              <div className="col-sm-9">
                <FilterSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.testClassificationId = ref)}
                  placeholder="Select Classification"
                  label="Select Classification"
                  controlId="testClassificationId"
                  urlapilist={this.getClassificationUrl()}
                  required
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.data.fieldType === undefined ||
          this.state.data.fieldType === null ||
          this.state.data.fieldType === `` ? (
            ""
          ) : (
            <>
              <div className="form-group">
                {this.state.data.fieldType === FIELD_TYPE_CALCULATED ? (
                  <label className="col-sm-3 control-label">
                    Equation <span className="required">(*)</span>
                  </label>
                ) : (
                  <label className="col-sm-3 control-label">
                    Default Value
                  </label>
                )}
                <div className="col-sm-9">{this.renderDefaultValue()}</div>
              </div>
              {this.state.data.fieldType === FIELD_TYPE_DECIMAL ||
              this.state.data.fieldType === FIELD_TYPE_PERCENT ||
              this.state.data.fieldType === FIELD_TYPE_INTEGER
                ? this.renderDefaultValueExt()
                : ""}
              {this.state.data.fieldType.indexOf(`Text`) !== -1 ? (
                <div className="form-group">
                  <label className="col-sm-3 control-label">Max Length</label>
                  <div className="col-sm-9">
                    <NumericInput
                      inputMode="numeric"
                      strict
                      className="form-control"
                      noStyle
                      min={1}
                      max={5000}
                      precision={0}
                      step={1}
                      name="maxLength"
                      value={this.state.data.maxLength}
                      onChange={(value) => {
                        this.handleChange({
                          target: { name: "maxLength", value: value },
                        });
                      }}
                      onFocus={this.handleFocus}
                      style={{ zIndex: 1 }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
          <div className="form-group">
            <label className="col-sm-3 control-label">PF Core Sync</label>
            <div className="col-sm-9">
              <Switch
                name="isSync"
                onChange={this.handleChangeSwitchIsSync}
                checked={this.state.data.isSync}
                className="react-switch"
              />
            </div>
          </div>
          {this.state.data.isSync ? (
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Column Name <span className="required">(*)</span>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="pfCoreField"
                  autoFocus={true}
                  className="form-control"
                  placeholder="Enter column name in view PF Core database"
                  required
                  value={this.state.data.pfCoreField}
                  onChange={this.handleChange}
                  maxLength={100}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="form-group">
            <label className="col-sm-3 control-label">Category</label>
            <div className="col-sm-9">
              <input
                type="text"
                name="category"
                className="form-control"
                placeholder=""
                value={this.state.data.category}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-3 control-label">Can Bulk Update</label>
            <div className="col-sm-9">
              <Switch
                name="allowBulkUpdate"
                onChange={(checked) =>
                  this.setState({
                    data: { ...this.state.data, allowBulkUpdate: checked },
                  })
                }
                checked={this.state.data.allowBulkUpdate}
                className="react-switch"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}
export default App;
