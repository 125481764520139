import React from "react";
import CommonUtilities from "../Utilities/Common";
import Chart from "react-apexcharts";
import FilterSelectControl from "../FormControl/FilterSelectControl";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isHide: props.isHide,
      urlApiCropYear:
        GlobalConfig.REACT_APP_API_CROPYEAR_URL +
        "getall/" +
        props.processorId +
        (props.cropYearId === undefined || props.cropYearId === null
          ? ""
          : "/" + props.cropYearId),
      cropYearId: props.cropYearId,
      data: [0, 0],
      dataChart: [0, 0],
      isHideChart: true,
      chart: {
        options: {
          title: {
            text: "Payment Percent",
            align: "center",
          },
          labels: ["Owe", "Paid"],
          colors: ["#e36159", "#1891E5"],
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -15,
                // customScale: 0.8,
                size: 50,
              },
            },
          },
          legend: {
            show: true,
            position: "right",
          },
          dataLabels: {
            textAnchor: "middle",
            enabled: true,
            formatter: function (val) {
              return parseFloat(val).toFixed(2) + "%";
            },
            // style: {
            //   colors: ["#111"],
            // },
            // background: {
            //   enabled: true,
            //   foreColor: "#fff",
            //   borderWidth: 0,
            // },
          },
          tooltip: {
            // x: {
            //   show: true,
            //   formatter: function (val) {
            //     return val + "a%";
            //   },
            // },
            y: {
              show: true,
              formatter: function (val) {
                return (
                  "$" +
                  CommonUtilities.numberWithCommas(parseFloat(val).toFixed(2))
                );
              },
            },
            // z: {
            //   show: true,
            //   formatter: function (val) {
            //     return val + "c%";
            //   },
            // },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  bindData(data, cropYearId) {
    let dataChart = [...data];
    let isHideChart = false;
    if (data === undefined || data.length <= 0) {
      isHideChart = true;
    } else {
      if (data[0] == 0 || data[1] == 0) {
        isHideChart = true;
      } else {
        dataChart[0] = Math.abs(dataChart[0]);
        dataChart[1] = Math.abs(dataChart[1]);
      }
    }

    this.setState({
      cropYearId: cropYearId,
      data: data,
      dataChart: dataChart,
      isHideChart: isHideChart,
    });
  }

  setIsHide(isHide) {
    this.setState({ isHide: isHide });
  }

  handleRefresh() {
    this.props.handleRefresh();
  }

  handleChangeSelectControl = (controlId, value, text) => {
    this.setState(
      {
        [controlId]: value,
      },
      function callBack() {
        this.props.handleChangeSelectControl(value, text);
      }
    );
  };

  render() {
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
              <i className="fa fa-refresh fa-lg"></i>
            </a>
          </div>

          <div className="form-group">
            <div className="panel-title row mb-md">
              {this.props.cropYearShortName &&
              this.props.cropYearShortName !== "" ? (
                <div className="col-md-10 col-lg-12">
                  Summary: {this.props.cropYearShortName}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-12 col-lg-4 form-group selectCropYearDashboardContainer row">
              <label
                className="control-label labelCropYearDashboard"
                htmlFor="filterCropYear"
              >
                Crop Year
              </label>
              <div className="selectCropYearDashboard">
                <FilterSelectControl
                  isSetCheckedFromSource={true}
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.filterCropYearControl = ref)}
                  placeholder=""
                  label="Filter Crop Year"
                  controlId="filterCropYear"
                  urlapilist={this.state.urlApiCropYear}
                  isOptionDisabled={this.props.progressPending}
                  isLoading={this.props.progressPending}
                />
              </div>
            </div>
          </div>

          <p className="panel-subtitle"></p>
        </header>
        {this.state.isHide !== true ? (
          <div className="panel-body">
            <div className="row">
              {this.state.isHideChart ? (
                ""
              ) : (
                <div className="col-md-6 col-lg-3">
                  <Chart
                    options={this.state.chart.options}
                    series={this.state.dataChart}
                    type="pie"
                    width={280}
                  />
                </div>
              )}
              <div className="col-md-6 col-lg-9">
                <div className="col-md-12 col-lg-4">
                  <section className="panel panel-featured-left panel-featured-tertiary">
                    <div className="panel-body">
                      <div className="widget-summary">
                        <div className="widget-summary-col widget-summary-col-icon">
                          <div className="summary-icon bg-tertiary">
                            <i className="fa fa-usd"></i>
                          </div>
                        </div>
                        <div className="widget-summary-col">
                          <div className="summary">
                            <h4 className="title">Total for Crop Year</h4>
                            <div className="info">
                              {this.props.progressPending ? (
                                <span>Loading ...</span>
                              ) : (
                                <strong className="amount">
                                  {this.state.data[0] + this.state.data[1] < 0
                                    ? "(" +
                                      CommonUtilities.numberWithCommas(
                                        parseFloat(
                                          Math.abs(
                                            this.state.data[0] +
                                              this.state.data[1]
                                          )
                                        ).toFixed(2)
                                      ) +
                                      ")"
                                    : CommonUtilities.numberWithCommas(
                                        parseFloat(
                                          this.state.data[0] +
                                            this.state.data[1]
                                        ).toFixed(2)
                                      )}
                                </strong>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-12 col-lg-4">
                  <section className="panel panel-featured-left panel-featured-primary">
                    <div className="panel-body">
                      <div className="widget-summary">
                        <div className="widget-summary-col widget-summary-col-icon">
                          <div className="summary-icon bg-primary">
                            <i className="fa fa-usd"></i>
                          </div>
                        </div>
                        <div className="widget-summary-col">
                          <div className="summary">
                            <h4 className="title">Total Paid To Date</h4>
                            <div className="info">
                              {this.props.progressPending ? (
                                <span>Loading ...</span>
                              ) : (
                                <strong className="amount">
                                  {this.state.data[1] < 0
                                    ? "(" +
                                      CommonUtilities.numberWithCommas(
                                        parseFloat(
                                          Math.abs(this.state.data[1])
                                        ).toFixed(2)
                                      ) +
                                      ")"
                                    : CommonUtilities.numberWithCommas(
                                        parseFloat(this.state.data[1]).toFixed(
                                          2
                                        )
                                      )}
                                </strong>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-md-12 col-lg-4">
                  <section className="panel panel-featured-left panel-featured-tertiary">
                    <div className="panel-body">
                      <div className="widget-summary">
                        <div className="widget-summary-col widget-summary-col-icon">
                          <div className="summary-icon bg-secondary">
                            <i className="fa fa-usd"></i>
                          </div>
                        </div>
                        <div className="widget-summary-col">
                          <div className="summary">
                            <h4 className="title">Total Still Owe</h4>
                            <div className="info">
                              {this.props.progressPending ? (
                                <span>Loading ...</span>
                              ) : (
                                <strong className="amount">
                                  {this.state.data[0] < 0
                                    ? "(" +
                                      CommonUtilities.numberWithCommas(
                                        parseFloat(
                                          Math.abs(this.state.data[0])
                                        ).toFixed(2)
                                      ) +
                                      ")"
                                    : CommonUtilities.numberWithCommas(
                                        parseFloat(this.state.data[0]).toFixed(
                                          2
                                        )
                                      )}
                                </strong>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}
export default App;
