import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.urlapi =
      loggedUser.role == "Administrators"
        ? GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL
        : GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL +
          "processor/" +
          loggedUser.referenceUserId;
    this.state.urllist = process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST;
    this.state.urladd =
      loggedUser.role == "Administrators"
        ? process.env.REACT_APP_URL_MAIL_TEMPLATE_LIST + "-add"
        : "";
    this.state.title = "Mail Template";

    this.state.pageName = "Mail Template";
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  filterItems = () => {};

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Subject",
        selector: "subject",
        sortable: true,
      },
      {
        name: "Allow Custom?",
        selector: "isAllowCustom",
        center: true,
        cell: (row) => (row.isAllowCustom === true ? "Yes" : "No"),
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      // {
      //   name: "Created Date",
      //   selector: "createdDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return <DateFormat date={row.createdDate}></DateFormat>;
      //   },
      // },
      {
        name: "",
        cell: (row) => (
          <>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            {loggedUser.role === "Administrators" ? (
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            ) : (
              ""
            )}
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };
}
export default App;
