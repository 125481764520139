import React from "react";

import Textbox from "../FormControl/Textbox";
import ModalPopup from "../Base/ModalPopup";
import { checkValidEmailList } from "../Utilities/Common";
import { Form } from "react-bootstrap";

import { EMAIL_SETTING_EMAIL_ADDRESS_NOTE } from "../Utilities/Constant";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      show: false,
      emailAddress: "",
      invalidEmails: [],
    };
  }

  handleOpenSendTestEmailModal = () => {
    this.props.handleHideResultMsg();

    this.setState({
      show: true,
    });
  };

  handleCloseSendTestEmailModal = () => {
    this.handleResetPopup();

    this.setState({
      show: false,
    });
  };

  handleChangeEmailAddress = (e) => {
    const value = e.target.value;

    this.setState({
      emailAddress: value,
      invalidEmails: [],
    });
  };

  handleResetPopup = () => {
    this.setState({
      emailAddress: "",
      invalidEmails: [],
    });
  };

  handleValidateEmail = () => {
    const result = checkValidEmailList(this.state.emailAddress);
    return result;
  };

  sendTestEmail = async (e) => {
    e.preventDefault();

    const result = this.handleValidateEmail();

    const invalidEmailsCondition =
      result.invalidEmails && result.invalidEmails.length > 0;

    if (invalidEmailsCondition) {
      const invalidEmailsList = result.invalidEmails;

      this.setState({
        invalidEmails: invalidEmailsList,
      });
    } else {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            to: this.state.emailAddress,
          }),
        };
        const result = await fetch(
          `${process.env.REACT_APP_API_EMAIL_URL}/sendtestemail`,
          requestOptions
        );
        const response = await result.json();
        const alertStatus = {
          status: null,
          message: response.message,
        };

        this.handleCloseSendTestEmailModal();
        this.handleResetPopup();

        if (response.result === "OK") {
          alertStatus.status = "success";
        } else {
          alertStatus.status = "error";
        }

        await this.props.handleSendTestMailStatus(alertStatus);
        await this.props.handleShowResultMsg();
      } catch (e) {
        window.showAlert(
          "Error",
          "Failed to send test email. Server doesn't response",
          e.message
        );
      }
    }
  };

  handleValidationEmailLabel = () => {
    const { invalidEmails } = this.state;

    if (invalidEmails && invalidEmails.length > 0) {
      const pluralAddition = invalidEmails.length > 1 ? "s" : "";

      return `Invalid Email${pluralAddition}: ${invalidEmails.join(", ")}`;
    }
  };

  render() {
    const { show, emailAddress } = this.state;

    return (
      <>
        <Form onSubmit={(e) => this.sendTestEmail(e)} id="send-test-email">
          <ModalPopup
            show={show}
            handleClose={this.handleCloseSendTestEmailModal}
            extendButton={
              <button
                type="submit"
                disabled={!emailAddress}
                className="btn btn-primary m-none"
                onClick={this.sendTestEmail}
              >
                <i className="fa fa-send fa-lg mr-xs"></i>
                Send Test
              </button>
            }
            title="Send Test Mail"
          >
            <Textbox
              type="text"
              label={"Email Address"}
              name="email-address"
              value={emailAddress}
              labelClass={3}
              inputClass={9}
              required
              handleChange={(e) => this.handleChangeEmailAddress(e)}
              note={EMAIL_SETTING_EMAIL_ADDRESS_NOTE}
              validation={this.handleValidationEmailLabel()}
            />
          </ModalPopup>
        </Form>
        <button
          type="button"
          className="btn btn-primary m-none"
          onClick={this.handleOpenSendTestEmailModal}
        >
          <i className="fa fa-send fa-lg mr-xs"></i>
          Send Test Email
        </button>
      </>
    );
  }
}
export default App;
