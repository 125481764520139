import { queryDataExt } from "../BaseService/index";
const GlobalConfig = new window.globalConfig();

export const getClassificationItemsWithConditions = async (
  whereClause,
  selectFields,
  sortFields
) => {
  return await queryDataExt(
    `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}getWithConditions`,
    whereClause,
    selectFields,
    sortFields
  );
};