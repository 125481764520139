import ListComponent from "../Base/ListComponent";
import SubTable from "./SubTable";
import DataTable from "react-data-table-component";

const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    this.state.useFilter = false;
    this.state.urladd = "";
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_IMPORT_LOG_URL}importLogError/${props.match.params.id}`;
    this.state.title = "Import Error Logs";
    this.state.pluraltitle = "Import Error Logs";
    this.state.pageName = "Import Error Logs";
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "LOT Number",
        selector: "lotNumber",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <span>
              LOT:: <strong>{row.lotNumber}</strong>
            </span>
          );
        },
      },
    ];
  };

  defaultTable = () => {
    return (
      <DataTable
        key="editTable"
        id="editTable"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        expandableRows={true}
        expandableRowsComponent={
          <SubTable
            expandableRowExpanded={(row) => {
              return true;
            }}
          />
        }
        conditionalRowStyles={this.conditionalRowStyles()}
        noTableHead={true}
        expandableRowExpanded={(row) => {
          return true;
        }}
        pagination={true}
      />
    );
  };
}
export default App;
