export async function exportPdfWalnutTest(urlapi, id, userId) {
  window.showAlert("Info", "Exporting...", "info");

  try {
    const dataRequest = {
      _id: id,
      createdUserId: userId,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    };
    fetch(urlapi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          window.closeAllAlerts();
          window.open(
            process.env.REACT_APP_API_URL + resultObject.downloadUrl,
            "_blank"
          );
        }
        //If failed, show error
        else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  } catch (err) {
    window.showAlert("Error", err.message, "error");
  }
}
