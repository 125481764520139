import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import { Helmet } from "react-helmet";
import ListComponent from "../Base/ListComponent";

class App extends ListComponent {
  constructor(props) {
    super();
  }

  getItems = async () => { };

  render() {
    return (
      <>
        <Helmet>
          <title>
            {"Billing Details | " + process.env.REACT_APP_SITE_TITLE}
          </title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Billing Details</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>Billing History</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="panel">
                <div className="panel-body">
                  <div className="invoice">
                    <header className="clearfix">
                      <div className="row">
                        <div className="col-sm-6 mt-md">
                          <h2 className="h2 mt-none mb-sm text-dark text-bold">
                            INVOICE
                          </h2>
                          <h4 className="h4 m-none text-dark text-bold">
                            #76598345
                          </h4>
                        </div>
                        <div className="col-sm-6 text-right mt-md mb-md">
                          <address className="ib mr-xlg">
                            Parity Factory
                            <br />
                            24 Henrietta Street, London, England
                            <br />
                            Phone: +12 3 4567-8901
                            <br />
                            okler@okler.net
                          </address>
                          <div className="ib">
                            <img
                              src="/assets/images/logo_black.png"
                              alt="Parity Factory"
                              style={{ width: "174px", height: "69px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </header>
                    <div className="bill-info">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bill-to">
                            <p className="h5 mb-xs text-dark text-semibold">
                              To:
                            </p>
                            <address>
                              Envato
                              <br />
                              121 King Street, Melbourne, Australia
                              <br />
                              Phone: +61 3 8376 6284
                              <br />
                              info@envato.com
                            </address>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bill-data text-right">
                            <p className="mb-none">
                              <span className="text-dark">Invoice Date:</span>
                              <span className="value">05/20/2014</span>
                            </p>
                            <p className="mb-none">
                              <span className="text-dark">Due Date:</span>
                              <span className="value">06/20/2014</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table invoice-items">
                        <thead>
                          <tr className="h4 text-dark">
                            <th id="cell-id" className="text-semibold">
                              #
                            </th>
                            <th id="cell-item" className="text-semibold">
                              Item
                            </th>
                            <th id="cell-desc" className="text-semibold">
                              Description
                            </th>
                            <th
                              id="cell-price"
                              className="text-center text-semibold"
                            >
                              Price
                            </th>
                            <th
                              id="cell-qty"
                              className="text-center text-semibold"
                            >
                              Quantity
                            </th>
                            <th
                              id="cell-total"
                              className="text-center text-semibold"
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>123456</td>
                            <td className="text-semibold text-dark">
                              Porto HTML5 Template
                            </td>
                            <td>Multipourpouse Website Template</td>
                            <td className="text-center">$14.00</td>
                            <td className="text-center">2</td>
                            <td className="text-center">$28.00</td>
                          </tr>
                          <tr>
                            <td>654321</td>
                            <td className="text-semibold text-dark">
                              Tucson HTML5 Template
                            </td>
                            <td>Awesome Website Template</td>
                            <td className="text-center">$17.00</td>
                            <td className="text-center">1</td>
                            <td className="text-center">$17.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="invoice-summary">
                      <div className="row">
                        <div className="col-sm-4 col-sm-offset-8">
                          <table className="table h5 text-dark">
                            <tbody>
                              <tr className="b-top-none">
                                <td colspan="2">Subtotal</td>
                                <td className="text-left">$73.00</td>
                              </tr>
                              <tr>
                                <td colspan="2">Shipping</td>
                                <td className="text-left">$0.00</td>
                              </tr>
                              <tr className="h4">
                                <td colspan="2">Grand Total</td>
                                <td className="text-left">$73.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-right mr-lg">
                    <a href="#" className="btn btn-default">
                      Submit Invoice
                    </a>
                    <a
                      href="#"
                      target="_blank"
                      className="btn btn-primary ml-sm"
                    >
                      <i className="fa fa-print"></i> Print
                    </a>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
