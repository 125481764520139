import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      labelClass:
        "col-md-" +
        (props.labelClass === null || props.labelClass === undefined
          ? 4
          : props.labelClass) +
        " control-label",
      inputClass:
        "col-md-" +
        (props.inputClass === null || props.inputClass === undefined
          ? 8
          : props.inputClass),
    };
  }

  handleFocus = (e) => {
    if (e != null && e.target != null && e.target.type == "text") {
      e.target.select();
    }
  };

  render() {
    return (
      <div
        className={
          this.props.classProps
            ? this.props.classProps + " form-group"
            : "form-group"
        }
      >
        <label className={this.state.labelClass} htmlFor={this.props.name}>
          {this.props.label}
          {this.props.required ? (
            <>
              {" "}
              <span className="required">(*)</span>
            </>
          ) : (
            ""
          )}
          {this.props.equation ? (
            <>
              {" "}
              <a
                href={this.props.detailUrl}
                target="_blank"
                title={this.props.equation}
              >
                (E)
              </a>
            </>
          ) : (
            ""
          )}
        </label>
        {this.props.unit === undefined || this.props.unit == null ? (
          <div className={this.state.inputClass} key={this.props.name}>
            <input
              step={this.props.step}
              maxLength={this.props.maxLength}
              type={this.props.type}
              name={this.props.name}
              key={this.props.name}
              className="form-control"
              placeholder={this.props.placeholder}
              required={
                this.props.required && this.props.displayRequired !== true
              }
              readOnly={this.props.readOnly}
              autoFocus={this.props.autoFocus}
              value={this.props.value ?? ""}
              onChange={this.props.handleChange}
              onFocus={this.handleFocus}
            />
            {!this.props.validation ? (
              ""
            ) : (
              <label style={{ color: "red" }}>{this.props.validation}</label>
            )}
            {this.props.note === null ||
            this.props.note === undefined ||
            this.props.note === `` ? (
              ""
            ) : (
              <label>{this.props.note}</label>
            )}
          </div>
        ) : (
          <div className={this.state.inputClass}>
            <div className="input-group">
              <input
                step={this.props.step}
                maxLength={this.props.maxLength}
                type={this.props.type}
                name={this.props.name}
                className="form-control"
                placeholder={this.props.placeholder}
                required={
                  this.props.required && this.props.displayRequired !== true
                }
                readOnly={this.props.readOnly}
                autoFocus={this.props.autoFocus}
                value={this.props.value ?? ""}
                onChange={this.props.handleChange}
                onFocus={this.handleFocus}
              />
              <span className="input-group-addon">{this.props.unit}</span>
            </div>
            {this.props.note === null ||
            this.props.note === undefined ||
            this.props.note === `` ? (
              ""
            ) : (
              <label>{this.props.note}</label>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default App;
