import React from "react";
import { Bar } from "react-chartjs-2";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      data: {},
      loading: false,
      expand: true,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.currentProcessorId !== this.props.currentProcessorId ||
      previousProps.currentCropYearId !== this.props.currentCropYearId ||
      previousProps.currentGrowerId !== this.props.currentGrowerId
    ) {
      this.getData();
    }
  }

  getData = async () => {
    this.setState({ loading: true });
    let parameters = "processorId=" + this.props.currentProcessorId;
    parameters += "&growerId=" + this.props.currentGrowerId;
    parameters += "&cropYearId=" + this.props.currentCropYearId;
    const url =
      GlobalConfig.REACT_APP_API_DASHBOARD_URL +
      "testtotalweightovertime?" +
      parameters;
    const that = this;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({ data: resultObject.data, loading: false });
      })
      .catch(function (error) {
        that.setState({ loading: false });
        console.log(error);
      });
  };

  onChangeExpandToggle = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  render() {
    const options = {
      elements: {
        rectangle: {
          borderWidth: 1,
          borderColor: "rgb(0, 153, 198)",
          borderSkipped: "bottom",
        },
      },
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
      },
    };
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a
              href="javascript:void(0);"
              className={`fa fa-caret-${this.state.expand ? "up" : "down"}`}
              onClick={this.onChangeExpandToggle}
            ></a>
          </div>
          <h2 className="panel-title">Total Weight Over Time</h2>
        </header>
        <div
          className="panel-body report-panel-body"
          style={{
            maxHeight: this.state.expand ? 500 : 0,
          }}
        >
          <hr className="dotted short"></hr>
          {this.state.loading === true ? (
            <span>Loading...</span>
          ) : (
            <div className="chart-responsive">
              <Bar data={this.state.data} options={options} />
            </div>
          )}
        </div>
      </section>
    );
  }
}
export default App;
