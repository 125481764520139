import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import Textbox from "../FormControl/Textbox";
import TypeContainer from "../FormControl/TypeContainer";
import NumericInput from "../FormControl/NumericInput";
import HandlerControl from "./HandlerControl";
import SelectControl from "../FormControl/SelectControl";
import SelectControlGrower from "../Grower/SelectControl";
import SelectWalnutVarieties from "../TestType/SelectWalnutVarieties";
import { exportPdfWalnutTest } from "./export";
import { Helmet } from "react-helmet";
import PageInvalidAccess from "../Pages/PageInvalidAccess";
import CommonUtilities from "../Utilities/Common";
import NodeUuid from "node-uuid";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import SaveButton from "../Controls/SaveButton";

const moment = require("moment");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const query = new URLSearchParams(props.location.search);
    const nutTypeId = query.get("type");
    const nutTypeName = query.get("name");

    this.state = {
      nutTypeName: nutTypeName,
      extendBodyMessage: "",
      title:
        nutTypeName === null ||
        nutTypeName === undefined ||
        nutTypeName.trim() === ""
          ? "Test"
          : nutTypeName.trim() + " Test",
      listtitle:
        nutTypeName === null ||
        nutTypeName === undefined ||
        nutTypeName.trim() === ""
          ? "Tests"
          : nutTypeName.trim() + " Tests",
      urllist:
        process.env.REACT_APP_URL_WALNUT_TEST_LIST +
        "?type=" +
        nutTypeId +
        "&name=" +
        nutTypeName,
      urlapi: GlobalConfig.REACT_APP_API_WALNUT_TEST_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageMaxSize: parseInt(GlobalConfig.REACT_APP_MAX_SIZE_WALNUT_TEST_IMAGE),
      //virtual field not belong to schema model, to be retrieved from processor
      processorName: "",
      certificateNo: "",
      print: false,
      isAllowEdit: true,
      nutTypeId: nutTypeId,
      data: {
        _id: "",
        inspectionAddress: "",
        lotNumber: "",
        growerName: "",
        varitySubmitted: "",
        wcNumber: "",
        typeNumberOfContainers: "Bin",
        numberOfContainers: 0,
        netWeight: 0,
        sampleDrawnByDfa: true,
        sampleSubmittedByHandler: false,
        sampleSubmittedBy: "",
        dfaInspector: "",
        employeeNo: "",

        s1NetWeight: 0,
        s1Jumbo: 0,
        s1Large: 0,
        s1Medium: 0,
        s1Baby: 0,
        s1PeeWee: 0,
        s1Splits: 0,
        s1Broken: 0,
        s1AdheringHull: 0,

        s2Insect: 0,
        s2Rancid: 0,
        s2Mold: 0,
        s2Shrivel: 0,
        s2Other: 0,
        s2Blows: 0,
        s2Light: 0,
        s2LightAmber: 0,
        s2Amber: 0,
        s2Black: 0,

        s3Weight: 0,
        s3EdibleWeight: 0,
        s3DefectWeight: 0,
        s3TotalKernelWeight: 0,
        s3EdibleYield: 0,

        s4OtherVarietiesIncluded: 0,
        s4SeriousStain: 0,
        moisture: 0,
        s4Remarks: "",
        fieldName: "",
        photo: "",

        processorId: loggedUser.processorId,
        labUserId:
          loggedUser.role !== "Lab Users" ? "" : loggedUser.referenceUserId,

        nutTypeId: nutTypeId,
        testDate: moment(new Date()).format("MM/DD/yyyy"),

        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      //image showing on popup
      images: [],
      image: "",
      size: "",
      name: "",
      showImage: false,
      index: -1,
      addWalnutTestLoading: false,
      printLoading: false,
    };
    this.handleChangeHandlerControl =
      this.handleChangeHandlerControl.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeNumberContainers =
      this.handleChangeNumberContainers.bind(this);
    this.handleChangeSelectTypeContainers =
      this.handleChangeSelectTypeContainers.bind(this);
  }

  async componentDidMount() {
    const that = this;
    fetch(
      GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "validateprocessor/" +
        loggedUser.processorId
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          window.toggleNavigation();
          that.setState(
            { useExtendBody: false, extendBodyMessage: "" },
            function () {
              that.updateStateFromLoggedUser(resultObject);
            }
          );
        } else {
          that.setState({
            useExtendBody: true,
            extendBodyMessage: resultObject.message,
            alertmsg: resultObject.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const that = this;
    that.setState(
      {
        [that.state.print ? "printLoading" : "addWalnutTestLoading"]: true,
      },
      () => {
        fetch(
          GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
            "validateprocessor/" +
            loggedUser.processorId
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObject) {
            if (resultObject.result === "OK") {
              that.handleSave();
            } else {
              window.showAlert("Error", resultObject.message, "Error");
            }
            that.setState({
              [that.state.print
                ? "printLoading"
                : "addWalnutTestLoading"]: false,
            });
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              [that.state.print
                ? "printLoading"
                : "addWalnutTestLoading"]: false,
            });
          });
      }
    );
  };

  extendBody = () => {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {loggedUser.role == "Processors" || loggedUser.role == "Staffs"
          ? this.state.extendBodyMessage
          : this.state.extendBodyMessage}
      </div>
    );
  };

  updateStateFromLoggedUser = async (processorItem) => {
    try {
      if (loggedUser.role == "Lab Users") {
        this.setState({
          processorName: processorItem.processorName,
          certificateNo: processorItem.certificateNo,
        });
        this.setState({
          data: Object.assign({}, this.state.data, {
            ["inspectionAddress"]: loggedUser.address,
            ["dfaInspector"]: loggedUser.referenceUserId,
          }),
        });
        this.dfaInspectorControl.setSelected(loggedUser.referenceUserId);
      } else if (
        loggedUser.role == "Processors" ||
        loggedUser.role == "Staffs"
      ) {
        this.setState({
          processorName: processorItem.processorName,
          certificateNo: processorItem.certificateNo,
        });
        this.setState({
          data: Object.assign({}, this.state.data, {
            inspectionAddress: processorItem.address,
          }),
        });
      }

      if (loggedUser.isInvalidProcessor) {
        this.setState({
          alertmsg:
            "Opp! Your account under processor <strong>" +
            loggedUser.processorName +
            "</strong> may inactive. Please switch to other processor to continue.",
          alerttype: "success",
          alertshow: true,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeRadio = (event) => {
    const { name, value } = event.target;

    let data = this.state.data;
    data[name] = value;
    this.setState({
      isChanged: true,
      data: data,
    });

    if (value == "true") {
      this.setState({
        data: Object.assign({}, this.state.data, {
          ["sampleSubmittedBy"]: "",
        }),
      });
      this.handlerControl.resetValue();
    }
    this.handlerControl.setReadonly(value == "true");
  };

  //This method is called from child control handler
  handleChangeHandlerControl = (controlId, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleChangeNumberContainers(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        numberOfContainers: value,
      }),
    });
  }

  handleChangeSelectTypeContainers(event) {
    const id = event.target.name;
    const value = event.target.value;
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  getTitle = () => {
    return this.state.title;
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        inspectionAddress: this.state.data.inspectionAddress,
        lotNumber: "",
        growerName: "",
        varitySubmitted: "",
        wcNumber: "",
        typeNumberOfContainers: "Bin",
        numberOfContainers: 0,
        netWeight: 0,
        sampleDrawnByDfa: true,
        sampleSubmittedByHandler: false,
        sampleSubmittedBy: "",
        dfaInspector: this.state.data.dfaInspector,
        employeeNo: "",

        s1NetWeight: 0,
        s1Jumbo: 0,
        s1Large: 0,
        s1Medium: 0,
        s1Baby: 0,
        s1PeeWee: 0,
        s1Splits: 0,
        s1Broken: 0,
        s1AdheringHull: 0,

        s2Insect: 0,
        s2Rancid: 0,
        s2Mold: 0,
        s2Shrivel: 0,
        s2Other: 0,
        s2Blows: 0,
        s2Light: 0,
        s2LightAmber: 0,
        s2Amber: 0,
        s2Black: 0,

        s3Weight: 0,
        s3EdibleWeight: 0,
        s3DefectWeight: 0,
        s3TotalKernelWeight: 0,
        s3EdibleYield: 0,

        s4OtherVarietiesIncluded: 0,
        s4SeriousStain: 0,
        moisture: 0,
        s4Remarks: "",
        fieldName: "",

        photo: "",
        // photoExtension: "",

        processorId: loggedUser.processorId,
        labUserId:
          loggedUser.role !== "Lab Users" ? "" : loggedUser.referenceUserId,

        nutTypeId: this.state.nutTypeId,
        testDate: moment(new Date()).format("MM/DD/yyyy"),
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      images: [],
      image: "",
      size: "",
      name: "",
      showImage: false,
      index: -1,
    });
    this.varitySubmitted.setSelected("");
    this.growerControl.setSelected("");
    this.dfaInspectorControl.setSelected("");
    this.handlerControl.resetValue();
    this.handlerControl.setReadonly(true);
  };

  handleNormalSubmit = async (e) => {
    this.setState({
      print: false,
    });
  };

  handleExtraSubmit = async (e) => {
    this.setState({
      print: true,
    });
  };

  countTotalNuts = () => {
    return (
      this.state.data.s1Jumbo +
      this.state.data.s1Large +
      this.state.data.s1Medium +
      this.state.data.s1Baby +
      this.state.data.s1PeeWee +
      this.state.data.s1Splits +
      this.state.data.s1Broken +
      this.state.data.s1AdheringHull
    );
  };

  checkSomethingBeforeSave = () => {
    if (!CommonUtilities.isValidDate(this.state.data.testDate, true)) {
      this.testDate.focus();
      window.showAlert(
        "Error",
        "Test Date must be MM/DD/YYYY. Please check and try again.",
        "Error"
      );
      return false;
    }
    var totalStep1 =
      this.state.data.s1Jumbo +
      this.state.data.s1Large +
      this.state.data.s1Medium +
      this.state.data.s1Baby +
      this.state.data.s1PeeWee +
      this.state.data.s1Splits +
      this.state.data.s1Broken +
      this.state.data.s1AdheringHull;
    if (totalStep1 != 100) {
      window.showAlert(
        "Error",
        "Sizes and Defects under Step 1 should be 100. Please check and try again.",
        "Error"
      );
      return false;
    }
    if (
      this.state.data.typeNumberOfContainers === "Bin" &&
      (isNaN(this.state.data.numberOfContainers) ||
        this.state.data.numberOfContainers <= 0)
    ) {
      window.showAlert("Error", "Please enter number of containers.", "Error");
      return false;
    }
    return true;
  };

  doSomethingAfterSaved = (data) => {
    if (this.state.print === true) {
      exportPdfWalnutTest(this.state.urlapi + "exportpdf", data._id);
    }
  };

  handleFocus = (e) => {
    if (this.state.isAllowEdit === true) {
      if (e != null && e.target != null && e.target.type == "text") {
        e.target.select();
        if (
          (e.target.value && e.target.value === "0.00") ||
          (e.target.value && e.target.value === "0.0") ||
          (e.target.value && e.target.value === "0")
        ) {
          e.target.value = "";
        }
      }
    }
  };

  handleChange4Image(event) {
    var files = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type == "image/jpeg" || file.type == "image/png") {
          let filesBase64 = "";
          this.getBase64(file, (result) => {
            filesBase64 = result;
            const arrTemp = file.name.split(".");
            const fileExtension = arrTemp[arrTemp.length - 1];
            const images =
              this.state.data && this.state.images
                ? [...this.state.images]
                : [];
            const totalBytes = file.size;
            let size = undefined;
            let unit = "";
            if (totalBytes < 1000000) {
              size = Math.floor(totalBytes / 1000);
              unit = "KB";
            } else {
              size = Math.floor(totalBytes / 1000000);
              unit = "MB";
            }
            if (totalBytes / 1000 > this.state.imageMaxSize) {
              window.showAlert(
                "Error",
                "Photo " +
                  file.name +
                  " does not allow upload. Maximum size allows " +
                  this.state.imageMaxSize / 1000 +
                  "MB.",
                "error"
              );
            } else {
              images.push({
                _id: NodeUuid.v4(),
                image: filesBase64,
                name: file.name,
                size: size,
                extension: fileExtension,
              });
            }
            this.setState({
              isChanged: true,
              data: Object.assign({}, this.state.data, {
                ["photo"]: JSON.stringify(images),
              }),
            });
            this.setState({ images: images });
          });
        } else {
          window.showAlert("Error", "Please choose PNG or JPG", "error");
        }
      }
    }
  }

  handleCloseModalImage = () => {
    this.setState({ showImage: false });
  };

  handleMoveImage = (index) => {
    const data = this.state.images[index];
    this.setState({
      image: data.image,
      name: data.name,
      size: data.size,
      index: index,
    });
  };

  handleOpenModalImage = (data) => {
    let index = this.state.images.indexOf(data);
    this.setState({
      showImage: true,
      image: data.image,
      name: data.name,
      size: data.size,
      index: index,
    });
  };

  handleRemoveImage = (data) => {
    if (this.state.images && this.state.images.length > 0) {
      const images = this.state.images.filter(function (item) {
        return item._id !== data._id;
      });
      this.setState({
        images: images,
      });

      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          ["photo"]: JSON.stringify(images),
        }),
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Can't be blank", "error");
    }
  }

  render() {
    const title = "Create new " + this.getTitle();

    return loggedUser.role == "Administrators" ? (
      <PageInvalidAccess />
    ) : (
      <>
        <Modal
          show={this.state.showImage}
          onHide={this.handleCloseModalImage}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header style={{ border: "none" }}></Modal.Header>
          <Modal.Body
            style={{
              marginLeft: "0px",
              marginBottom: "-10px",
              marginTop: "-25px",
              paddingLeft: "5px",
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
          >
            {this.state.image && this.state.image !== "" ? (
              <img
                src={
                  this.state.image.indexOf("data:image") === -1
                    ? process.env.REACT_APP_API_URL + this.state.image
                    : this.state.image
                }
                alt="No image"
                style={{
                  maxWidth: "568px",
                  maxHeight: "400px",
                  minWidth: "568px",
                  minHeight: "400px",
                }}
              ></img>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {this.state.images && this.state.images.length > 1 ? (
                <Button
                  variant="info"
                  onClick={() => this.handleMoveImage(this.state.index - 1)}
                  disabled={!this.state.index > 0}
                  title="Previous photo"
                >
                  <span className="fa fa-angle-left fa-lg"></span>
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="warning"
                onClick={this.handleCloseModalImage}
                title="Close"
              >
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
              {this.state.images && this.state.images.length > 1 ? (
                <Button
                  variant="info"
                  onClick={() => this.handleMoveImage(this.state.index + 1)}
                  disabled={!(this.state.index < this.state.images.length - 1)}
                  title="Next photo"
                >
                  <span className="fa fa-angle-right fa-lg"></span>
                </Button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              {loggedUser.isInvalidProcessor ||
              (this.state.useExtendBody != null &&
                this.state.useExtendBody != undefined &&
                this.state.useExtendBody == true) ? (
                ""
              ) : (
                <form
                  className="form-horizontal form-bordered"
                  onSubmit={this.handleSubmit}
                  id="form"
                >
                  <div className="row">
                    <section className="panel">
                      {loggedUser.role == "Lab Users" ? (
                        <header className="panel-heading">
                          <p className="panel-subtitle">
                            Entering Test Data for:{" "}
                            <strong>{this.state.processorName}</strong>
                          </p>
                        </header>
                      ) : (
                        ""
                      )}
                      <div className="panel-body">
                        <fieldset>
                          <div className="form-group">
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="Inspection Address"
                                name="inspectionAddress"
                                value={this.state.data.inspectionAddress}
                                handleChange={this.handleChange}
                                maxLength={55}
                                required={true}
                                placeholder=""
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="col-xs-4 control-label mt-xs pt-none"
                                  htmlFor="testDate"
                                >
                                  Test Date{" "}
                                  <span className="required">(*)</span>
                                </label>
                                <div
                                  className="form-group input-daterange input-group"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <span className="input-group-addon">
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                  <DatePicker
                                    className="form-control"
                                    placeholderText="Enter datetime"
                                    selected={
                                      new Date(this.state.data.testDate)
                                    }
                                    onChange={this.handleDateChange}
                                    onBlur={this.handleOnBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="Handler Name"
                                name="processorName"
                                value={this.state.processorName}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="Lot Number"
                                name="lotNumber"
                                value={this.state.data.lotNumber}
                                handleChange={this.handleChange}
                                maxLength={20}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <SelectControlGrower
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                onRef={(ref) => (this.growerControl = ref)}
                                placeholder="Grower Name"
                                label="Grower Name"
                                popupTitle="Grower"
                                controlId="growerName"
                                value={this.state.growerName}
                                required
                                maxLength={50}
                                processorId={this.state.data.processorId}
                                urlapi={GlobalConfig.REACT_APP_API_GROWER_URL}
                                urlapilist={
                                  GlobalConfig.REACT_APP_API_GROWER_URL +
                                  "simpleGrowersByProcessorAndTestType/" +
                                  this.state.data.processorId +
                                  "/" +
                                  this.state.nutTypeId
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="Field Name"
                                name="fieldName"
                                value={this.state.data.fieldName}
                                handleChange={this.handleChange}
                                maxLength={200}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="col-xs-4 control-label mt-xs pt-none"
                                  htmlFor="varitySubmitted"
                                >
                                  Variety Submitted{" "}
                                  <span className="required">(*)</span>
                                </label>
                                <div className="col-md-8">
                                  <SelectWalnutVarieties
                                    required={true}
                                    onRef={(ref) =>
                                      (this.varitySubmitted = ref)
                                    }
                                    name="varitySubmitted"
                                    controlId="varitySubmitted"
                                    selectedIds={
                                      this.state.data.varitySubmitted
                                    }
                                    handleChangeSelectControl={
                                      this.handleChangeSelectControl
                                    }
                                    urlApi={
                                      GlobalConfig.REACT_APP_API_VARIETY_URL +
                                      "byParentId/" +
                                      this.state.nutTypeId
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="W/C Number"
                                name="wcNumber"
                                value={this.state.data.wcNumber}
                                handleChange={this.handleChange}
                                maxLength={20}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="col-md-4 control-label">
                                  Type of Containers{" "}
                                  <span className="required">(*)</span>
                                </label>
                                <div className="col-md-8">
                                  <TypeContainer
                                    className="mb-md"
                                    handleChangeSelectTypeContainers={
                                      this.handleChangeSelectTypeContainers
                                    }
                                    typeNumberOfContainers={
                                      this.state.data.typeNumberOfContainers
                                    }
                                    numberOfContainers={
                                      this.state.data.numberOfContainers
                                    }
                                    handleChangeNumberContainers={
                                      this.handleChangeNumberContainers
                                    }
                                    handleFocus={this.handleFocus}
                                    isAllowEdit={this.state.isAllowEdit}
                                    processorId={loggedUser.processorId}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <NumericInput
                                precision={1}
                                min={0}
                                max={999999999}
                                step={1}
                                label="Net Weight"
                                name="netWeight"
                                value={this.state.data.netWeight}
                                handleChange={this.handleChange}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="rad1"
                              >
                                Sample Drawn by DFA
                              </label>
                              <div className="col-md-8">
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="sampleDrawnByDfa"
                                      id="rad1"
                                      onChange={this.handleChangeRadio}
                                      value={true}
                                      required
                                      defaultChecked={true}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="col-md-4 control-label"
                                  htmlFor="rad2"
                                >
                                  Sample Submitted by Handler
                                </label>
                                <div className="col-md-8">
                                  <div className="input-group mb-md">
                                    <span className="input-group-addon">
                                      <input
                                        type="radio"
                                        name="sampleDrawnByDfa"
                                        id="rad2"
                                        onChange={this.handleChangeRadio}
                                        value={false}
                                        required
                                      />
                                    </span>
                                    <HandlerControl
                                      readOnly={
                                        this.state.data.sampleDrawnByDfa == true
                                      }
                                      handleChangeSelectControl={
                                        this.handleChangeSelectControl
                                      }
                                      onRef={(ref) =>
                                        (this.handlerControl = ref)
                                      }
                                      placeholder="Handler Name"
                                      label=""
                                      popupTitle="Handler"
                                      controlId="sampleSubmittedBy"
                                      value={this.state.sampleSubmittedBy}
                                      required
                                      maxLength={50}
                                      processorId={this.state.data.processorId}
                                      urlapi={
                                        GlobalConfig.REACT_APP_API_HANDLER_URL
                                      }
                                      urlapilist={
                                        GlobalConfig.REACT_APP_API_HANDLER_URL +
                                        "byprocessor/" +
                                        this.state.data.processorId
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6">
                              <SelectControl
                                handleChangeSelectControl={
                                  this.handleChangeSelectControl
                                }
                                onRef={(ref) =>
                                  (this.dfaInspectorControl = ref)
                                }
                                placeholder="Inspector"
                                label="Inspector"
                                popupTitle="DFA Inspector"
                                controlId="dfaInspector"
                                value={this.state.dfaInspector}
                                required
                                maxLength={40}
                                processorId={this.state.data.processorId}
                                urlapi={GlobalConfig.REACT_APP_API_LABUSER_URL}
                                urlapilist={
                                  GlobalConfig.REACT_APP_API_LABUSER_URL +
                                  "simplebyprocessor/" +
                                  this.state.data.processorId
                                }
                                isHideAddButton={true}
                                textField="displayName"
                                readOnly={loggedUser.role === "Lab Users"}
                              />
                            </div>
                            <div className="col-md-6">
                              <Textbox
                                type="text"
                                label="Employee #"
                                name="employeeNo"
                                value={this.state.data.employeeNo}
                                handleChange={this.handleChange}
                                maxLength={20}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-md-6 p-none">
                              <label
                                className="col-md-4 control-label mt-xs pt-none"
                                htmlFor="photo"
                              >
                                Take Photo
                              </label>
                              <div className="col-md-7">
                                <div
                                  className="fileupload fileupload-new"
                                  data-provides="fileupload"
                                >
                                  <div className="input-append">
                                    <div className="uneditable-input">
                                      <i className="fa fa-file fileupload-exists"></i>
                                      <span className="fileupload-preview"></span>
                                    </div>
                                    <span className="btn btn-default btn-file m-none mt-sm-ipad">
                                      <span
                                        className="fileupload-exists"
                                        title="Change"
                                      >
                                        <i
                                          className="fa fa-camera fa-lg"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span className="fileupload-new">
                                        <i
                                          className="fa fa-camera fa-lg"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        multiple
                                        type="file"
                                        name="photo"
                                        onChange={this.handleChange4Image}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.images &&
                          this.state.images &&
                          this.state.images.length > 0 ? (
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="col-md-4"></div>
                                <div
                                  className="col-md-8"
                                  style={{ paddingLeft: "5px" }}
                                >
                                  <div className="table-responsive">
                                    <table className="table mb-none">
                                      <tbody>
                                        {this.state.images.map((u, key) => {
                                          return (
                                            <tr key={key}>
                                              <td>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  title="View image"
                                                  onClick={() => {
                                                    this.handleOpenModalImage(
                                                      u
                                                    );
                                                  }}
                                                >
                                                  {u.name}
                                                </a>
                                              </td>
                                              <td>{u.size}</td>
                                              <td>
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  title="View image"
                                                  onClick={() => {
                                                    this.handleOpenModalImage(
                                                      u
                                                    );
                                                  }}
                                                >
                                                  <span>
                                                    <i
                                                      className="fa fa-eye fa-lg"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a
                                                  style={{ cursor: "pointer" }}
                                                  title="Remove image"
                                                  onClick={() => {
                                                    this.handleRemoveImage(u);
                                                  }}
                                                >
                                                  <span>
                                                    <i
                                                      className="fa fa-trash-o fa-lg"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </a>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-md-12">
                            <hr className="dotted short"></hr>
                            <div className="col-md-3">
                              <section
                                className="panel-success"
                                style={{
                                  borderStyle: "solid",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                  borderWidth: "thin",
                                }}
                              >
                                <header className="panel-heading">
                                  <h2 className="panel-title">
                                    Step 1 - External
                                  </h2>
                                </header>
                                <div className="panel-body">
                                  <fieldset>
                                    <h4 className="mb-xlg">
                                      Sample Size: 100 {this.state.nutTypeName}
                                    </h4>
                                    <hr className="dotted short"></hr>
                                    <NumericInput
                                      noStyle
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Net wt. 100 Inshell Nuts"
                                      name="s1NetWeight"
                                      value={this.state.data.s1NetWeight}
                                      handleChange={this.handleChange}
                                      unit="GRAMS"
                                    />
                                    <h4 className="mb-xlg">Sizes</h4>
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Jumbo"
                                      name="s1Jumbo"
                                      value={this.state.data.s1Jumbo}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Large"
                                      name="s1Large"
                                      value={this.state.data.s1Large}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Medium"
                                      name="s1Medium"
                                      value={this.state.data.s1Medium}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Baby"
                                      name="s1Baby"
                                      value={this.state.data.s1Baby}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Pee Wee"
                                      name="s1PeeWee"
                                      value={this.state.data.s1PeeWee}
                                      handleChange={this.handleChange}
                                    />
                                    <h4 className="mb-xlg">Defects</h4>
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Splits"
                                      name="s1Splits"
                                      value={this.state.data.s1Splits}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Broken"
                                      name="s1Broken"
                                      value={this.state.data.s1Broken}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={100}
                                      step={1}
                                      label="Adhering Hull"
                                      name="s1AdheringHull"
                                      value={this.state.data.s1AdheringHull}
                                      handleChange={this.handleChange}
                                    />
                                  </fieldset>
                                </div>
                                <footer className="panel-footer">
                                  <div className="row">
                                    <div className="col-sm-9 col-sm-offset-3">
                                      <label
                                        style={{
                                          color:
                                            this.countTotalNuts() == 100
                                              ? "#27AA78"
                                              : "red",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Total Nut Count: {this.countTotalNuts()}
                                      </label>
                                    </div>
                                  </div>
                                </footer>
                              </section>
                            </div>

                            <div className="col-md-3">
                              <section
                                className="panel-warning"
                                style={{
                                  borderStyle: "solid",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                  borderWidth: "thin",
                                }}
                              >
                                <header className="panel-heading">
                                  <h2 className="panel-title">
                                    Step 2 - Kernel
                                  </h2>
                                </header>
                                <div className="panel-body">
                                  <fieldset>
                                    <h4 className="mb-xlg">
                                      Sample Size: 100 {this.state.nutTypeName}
                                    </h4>
                                    <hr className="dotted short"></hr>
                                    <h4 className="mb-xlg">Serious Defects</h4>
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Insect"
                                      name="s2Insect"
                                      value={this.state.data.s2Insect}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Rancid"
                                      name="s2Rancid"
                                      value={this.state.data.s2Rancid}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Mold"
                                      name="s2Mold"
                                      value={this.state.data.s2Mold}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Shrivel"
                                      name="s2Shrivel"
                                      value={this.state.data.s2Shrivel}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Other"
                                      name="s2Other"
                                      value={this.state.data.s2Other}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Blows"
                                      name="s2Blows"
                                      value={this.state.data.s2Blows}
                                      handleChange={this.handleChange}
                                    />
                                    <h4 className="mb-xlg">Color</h4>
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Light"
                                      name="s2Light"
                                      value={this.state.data.s2Light}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Light Amber"
                                      name="s2LightAmber"
                                      value={this.state.data.s2LightAmber}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Amber"
                                      name="s2Amber"
                                      value={this.state.data.s2Amber}
                                      handleChange={this.handleChange}
                                    />
                                    <NumericInput
                                      readOnly={!this.state.isAllowEdit}
                                      precision={0}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      label="Black"
                                      name="s2Black"
                                      value={this.state.data.s2Black}
                                      handleChange={this.handleChange}
                                    />
                                  </fieldset>
                                </div>
                              </section>
                            </div>

                            <div className="col-md-3">
                              <section
                                className="panel-info"
                                style={{
                                  borderStyle: "solid",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                  borderWidth: "thin",
                                }}
                              >
                                <header className="panel-heading">
                                  <h2 className="panel-title">
                                    Step 3 - Totals
                                  </h2>
                                </header>
                                <div className="panel-body">
                                  <fieldset>
                                    <h4 className="mb-xlg">Net Weights Only</h4>
                                    <hr className="dotted short"></hr>
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Net wt. 100 Inshell Nuts"
                                      name="s1NetWeight"
                                      value={this.state.data.s1NetWeight}
                                      handleChange={this.handleChange}
                                      unit="GRAMS"
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Edible Weight"
                                      name="s3EdibleWeight"
                                      value={this.state.data.s3EdibleWeight}
                                      handleChange={this.handleChange}
                                      unit="GRAMS"
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Defect Weight"
                                      name="s3DefectWeight"
                                      value={this.state.data.s3DefectWeight}
                                      handleChange={this.handleChange}
                                      unit="GRAMS"
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Total Kernel Weight"
                                      name="s3TotalKernelWeight"
                                      value={(
                                        parseFloat(
                                          this.state.data.s3DefectWeight
                                        ) +
                                        parseFloat(
                                          this.state.data.s3EdibleWeight
                                        )
                                      ).toFixed(1)}
                                      unit="GRAMS"
                                      readOnly
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Edible Yield"
                                      name="s3EdibleYield"
                                      value={
                                        parseFloat(
                                          this.state.data.s1NetWeight
                                        ) <= 0
                                          ? 0
                                          : (
                                              (parseFloat(
                                                this.state.data.s3EdibleWeight
                                              ) /
                                                parseFloat(
                                                  this.state.data.s1NetWeight
                                                )) *
                                              100
                                            ).toFixed(1)
                                      }
                                      handleChange={this.handleChange}
                                      unit="%"
                                      readOnly
                                    />
                                  </fieldset>
                                </div>
                              </section>
                            </div>

                            <div className="col-md-3">
                              <section
                                className="panel-primary"
                                style={{
                                  borderStyle: "solid",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                  borderWidth: "thin",
                                }}
                              >
                                <header className="panel-heading">
                                  <h2 className="panel-title">
                                    Other Varieties Included
                                  </h2>
                                </header>
                                <div className="panel-body">
                                  <fieldset>
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Other Varieties Included"
                                      name="s4OtherVarietiesIncluded"
                                      value={
                                        this.state.data.s4OtherVarietiesIncluded
                                      }
                                      handleChange={this.handleChange}
                                      unit="%"
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Serious Stain"
                                      name="s4SeriousStain"
                                      value={this.state.data.s4SeriousStain}
                                      handleChange={this.handleChange}
                                      unit="%"
                                    />
                                    <NumericInput
                                      precision={1}
                                      min={0}
                                      max={999999999}
                                      step={0.1}
                                      label="Moisture"
                                      name="moisture"
                                      value={this.state.data.moisture}
                                      handleChange={this.handleChange}
                                      unit="%"
                                    />
                                    <div className="form-group">
                                      <label
                                        className="col-md-4 control-label"
                                        htmlFor="s4Remarks"
                                      >
                                        Remarks
                                      </label>
                                      <div className="col-md-8">
                                        <textarea
                                          name="s4Remarks"
                                          value={this.state.data.s4Remarks}
                                          onChange={this.handleChange}
                                          className="form-control"
                                          rows="10"
                                          data-plugin-maxlength="200"
                                          maxLength="200"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </section>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <footer className="panel-footer">
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            <a
                              href={this.state.urllist}
                              title="Back to list"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-backward fa-lg"></i> Back
                            </a>
                            <SaveButton
                              loading={this.state.addWalnutTestLoading}
                              onClick={this.handleNormalSubmit}
                            />
                            <SaveButton
                              text={"Save & Print Form"}
                              icon={
                                <i
                                  className="fa fa-print fa-lg"
                                  style={{ marginRight: 2.5 }}
                                ></i>
                              }
                              loading={this.state.printLoading}
                              onClick={this.handleExtraSubmit}
                            />
                          </div>
                        </div>
                      </footer>
                    </section>
                  </div>
                </form>
              )}
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
