import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = ({
  csvData,
  fileName,
  buttonLabel,
  buttonClassName,
  className,
  getCsvData = null,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // function fitToColumn(arrayOfArray) {
  //   // get maximum character of each column
  //   return arrayOfArray.map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i].toString().length)) }));
  // }

  const exportToCSV = async (csvData, fileName) => {
    window.showAlert(
      "Info",
      `Exporting...the file is ready download once done.`,
      "info"
    );
    if (getCsvData) {
      csvData = await getCsvData();
    }
    // if (csvData && csvData.length > 0) {
    const ws = XLSX.utils.json_to_sheet(csvData);
    // ws['!cols'] = fitToColumn(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    // }
    //  else {
    //   return <Alert message="No data export!" type="info" show="true"></Alert>;
    // }
  };
  if (buttonLabel === "") {
    buttonLabel = "Export excel";
  }
  return csvData && csvData.length > 0 ? (
    <a
      className={buttonClassName}
      href="# "
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      <div className={`option-dropdown-table-grower ${className}`}>
        <div className="icon-dropdown-table-grower">
          <i className={`fa fa-file-excel-o fa-lg`}></i>
        </div>
        <div className="title-dropdown-table-grower">{buttonLabel}</div>
      </div>
    </a>
  ) : (
    ""
  );
};

export default ExportExcel;
