import React from "react";
import ListComponentPayment from "../Base/ListComponentPayment";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SubTable from "../StaticMessage/SubTable";
import SelectGrowers from "../Grower/SelectGrowers";
import ListGrower from "../Grower/ListGrower";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.popupUrlApi = GlobalConfig.REACT_APP_API_STATICMESSAGE_URL;
    this.state.parentId = props.match.params.id;
    this.state.urlapi = GlobalConfig.REACT_APP_API_STATICMESSAGE_URL;
    this.state.urllist =
      process.env.REACT_APP_URL_CROPYEAR_LIST +
      process.env.REACT_APP_URL_STATICMESSAGE_LIST +
      "/" +
      props.match.params.id;
    this.state.urledit =
      process.env.REACT_APP_URL_CROPYEAR_LIST +
      process.env.REACT_APP_URL_STATICMESSAGE_LIST +
      "-edit/";
    this.state.title = "Grower Statement Message";
    this.state.pluraltitle = "Grower Statement Messages";
    this.state.labeladd = "Grower Statement Message";
    this.state.urladd = "";
    this.state.expandableRows = true;
    this.state.viewMode = "edit";
    this.state.isExpand = true;
    this.state.isUseHeading = false;
    this.state.cropYearName = "";
    //Using for popup add/edit
    this.state.data = {
      _id: "",
      parentId: props.match.params.id,
      growerId: "",
      text: "",
      createdUserId: loggedUser._id,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    };
    this.state.previousGrowerId = "";
    this.state.isChanged = false;
    this.state.errorMessage = "";
    this.state.color = "red";
    this.state.isEditMode = true;
    this.state.removedGrowerId = ""; //This state is using for remove row later
    this.state.showRefresh2 = true;

    this.state.pagination = false;
    this.state.saveLoading = false;

    this.state.testType = {
      xpool: false,
      isDynamic: false,
      _id: "",
      basePriceByPayment: false,
      paymentPercentBased: true,
      multiPricesSameLot: false,
      varietyOptional: false,
      taxFeeByMeta: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
  }

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions" style={{ top: "0px" }}>
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg" style={{ color: "#1891E5" }}></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  extendRender = () => {
    return (
      <>
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={true}
        />
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
          size="lg"
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {this.state.data._id === "" ? "Add Message" : "Edit Message"}{" "}
                {this.state.cropYearName !== ""
                  ? " - " + this.state.cropYearName
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  <div className="form-group">
                    <label
                      className="col-md-2 control-label"
                      htmlFor="growerId"
                    >
                      Default/Grower <span className="required">(*)</span>
                    </label>
                    <div className="col-md-10">
                      <SelectGrowers
                        required={false}
                        onRef={(ref) => (this.growerId = ref)}
                        name="growerId"
                        value={this.state.data.growerId}
                        selectedIds={this.state.data.growerId}
                        handleChangeSelectGrower={this.handleChangeSelectGrower}
                        testTypeId={this.state.testType._id}
                        cropYearId={this.state.parentId}
                        checkExistPaymentSetting={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-2 control-label">
                      Text <span className="required">(*)</span>
                    </label>
                    <div className="col-md-10">
                      <textarea
                        rows={25}
                        type="text"
                        name="text"
                        autoFocus={true}
                        className="form-control"
                        placeholder=""
                        required
                        value={this.state.data.text}
                        onChange={this.handleChangeObjectData}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected content no longer exists. Please refresh and try
                    again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                  this.state.errorMessage === "" ||
                  this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: "center" }}>
              {this.state.data ? (
                <Button
                  variant="success"
                  type="submit"
                  disabled={this.state.saveLoading}
                >
                  <span className="fa fa-save fa-lg"></span> Save
                </Button>
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }

    this.setState({ data: convertedJSON }, function callBack() {
      this.setState({
        show: true,
        errorMessage: "",
        isChanged: false,
        isEditMode: isEditMode,
      });
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
      saveLoading: true,
    });

    const text = this.state.data.text;
    let errorMessage = "";
    if (text == null || text === undefined || text === "") {
      errorMessage = "Please enter text to continue.";
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
        saveLoading: false,
      });
    } else {
      let previousGrowerId = this.state.data.growerId;
      //Using for edit
      let urlApi =
        GlobalConfig.REACT_APP_API_STATICMESSAGE_URL + this.state.data._id;
      let method = "PATCH";
      let isEditMode = true;
      if (
        this.state.data._id == null ||
        this.state.data._id === undefined ||
        this.state.data._id === ""
      ) {
        //Using for create new
        method = "POST";
        urlApi = GlobalConfig.REACT_APP_API_STATICMESSAGE_URL;
        isEditMode = false;
      }

      let requestData = { ...this.state.data };
      requestData = JSON.parse(JSON.stringify(requestData));
      requestData.testType = this.state.testType;

      const requestOptions = {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };

      const that = this;
      fetch(urlApi, requestOptions).then(function (response) {
        return response.json();
      })
        .then(function (resultObject) {
          //If created successful
          if (resultObject.result === "OK") {
            window.showAlert("", "Save successful!", "");
            that.setState(
              {
                isChanged: true,
                previousGrowerId: previousGrowerId,
                saveLoading: false,
              },
              function callBack() {
                that.updateStateAfterClosePopup(resultObject.data);
                if (!isEditMode) {
                  that.handResetForm();
                }
              }
            );
          } else {
            window.showAlert("Error", resultObject.message, "error");
            that.setState({
              saveLoading: false,
            });
          }
        }).catch(function (error) {
          window.showAlert("Error", error, "error");
          that.setState({
            saveLoading: false,
          });
        });
    }
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        parentId: this.state.parentId,
        growerId: this.state.previousGrowerId,
        text: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });

    //Set selected dropdown
    this.growerId.setSelected(this.state.previousGrowerId);
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  getItems = async () => {
    this.setState({
      alertmsg: "Loading...",
      alerttype: "info",
      alertshow: true,
    });
    try {
      const that = this;
      fetch(
        this.state.urlapi + "parent/" + this.state.parentId
      ).then(function (response) {
        return response.json();
      })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.setState({
              alertshow: false,
              alertmsg: "",
            });
            if (resultObject.cropYear) {
              const cropYearName =
                resultObject.cropYear.name +
                " (" +
                resultObject.cropYear.startDate +
                "-" +
                resultObject.cropYear.endDate +
                ")";

              const handleTruncate = that.truncateTitle(
                cropYearName,
                that.setTitleLength()
              );

              that.setState({
                pluraltitle: "Grower Statement Messages - " + handleTruncate,
                cropYearName: cropYearName,
              });
            }

            that.setState(
              {
                items: resultObject.data,
                originalItems: resultObject.data,
                growers: [],
              },
              function callBack() {
                that.refreshTable();
                that.doSomethingAfterGetItems(resultObject);
              }
            );
          }
        }).catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  setColumns = () => {
    return [
      {
        name: "Default/Grower",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.growerId === "" ? (
            `Default`
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {`${row.grower}`}
            </a>
          );
        },
      },
    ];
  };

  defaultTable = () => {
    return (
      <DataTable
        highlightOnHover
        striped
        id="emp"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        expandableRows={this.state.expandableRows}
        expandableRowsComponent={
          <SubTable
            handleDelete={this.handleDelete.bind(this)}
            handleShow={this.handleShow}
            expandableRowExpanded={(row) => {
              return this.state.isExpand;
            }}
            loggedUser={loggedUser}
            testType={this.state.testType}
          />
        }
        conditionalRowStyles={this.conditionalRowStyles()}
        noTableHead={true}
        expandableRowExpanded={(row) => {
          return this.state.isExpand;
        }}
        pagination={this.state.pagination}
        paginationPerPage={this.state.paginationPerPage}
        paginationRowsPerPageOptions={this.state.rowPerPageOption}
        paginationServer={true}
        paginationTotalRows={this.state.totalRows}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
      />
    );
  };

  extendButtons = () => {
    return (
      <>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>
        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <Button
              variant="primary"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  growerId: "",
                  text: "",
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              type="button"
              className="mb-sm"
            >
              <span className="fa fa-plus fa-lg"></span> {this.state.labeladd}
            </Button>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
          </>
        )}
      </>
    );
  };
}
export default App;
