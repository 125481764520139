import React from "react";

export const Column = ({
  name,
  id,
  handleRemoveField,
  disableRemove,
  disableMove,
  defaultData,
  description,
}) => {
  return (
    <div className={`${disableMove ? "" : "panel-dnd"} mt-xs mb-xs`}>
      <div className="row panel-height-body">
        <section className="panel-height-body">
          <header
            className={`${
              defaultData ? "panel-dnd-item-default" : "panel-dnd-item"
            } panel-nut-type`}
          >
            <div
              className={`panel-dnd-title ${
                disableMove ? "" : "user-select-none"
              }`}
            >
              <div>{name}</div>

              {description ? (
                <div className="panel-dnd-description">{description}</div>
              ) : (
                ""
              )}
            </div>

            {disableRemove ? (
              ""
            ) : (
              <div
                className="panel-item-action panel-dnd-btn panel-action-cursor"
                onClick={() => handleRemoveField(id)}
              >
                <div className="ml-lg " title="Remove">
                  <i className="fa fa-times fa-lg m-none"></i>
                </div>
              </div>
            )}
          </header>
        </section>
      </div>
    </div>
  );
};

export default Column;
