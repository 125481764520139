import React from "react";
import Select from "react-select";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      name: props.name,
      value: props.value,
      label: props.label,
      data: props.data,
      errorMessage: "",
    };
    this.selectRef = null;
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  bindData(data) {
    this.setState({ data: data }, () => {
      this.getItems();
    });
  }

  getItems = async () => {
    const defaultItem = { value: "", name: this.state.label };

    try {
      const parsedItems = [];


      if (this.state.data) {
        this.state.data.map((item) => {
          parsedItems.push({
            _id: item,
            name: item,
            checked:
              this.state.value === null ||
                this.state.value === undefined ||
                this.state.value !== item
                ? false
                : true,
          });
        });
      }
      this.setState({
        data: [defaultItem].concat(parsedItems),
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    // When reset input then set state(value, label) to default value
    if (!event) {
      this.setState({
        value: "",
        label: this.props.label,
      });
      return;
    }
    const valueItem = event;
    this.setState({ value: valueItem.value, label: valueItem.label });
    this.props.handleChangeSelectControl(this.state.name, valueItem.value);
  }

  reset = () => {
    this.selectRef.select.clearValue();
  };

  render() {
    return (
      <Select
        ref={(ref) => {
          this.selectRef = ref;
        }}
        name={this.state.name}
        className="formGroupHeaderCropYear"
        onChange={this.handleChangeSelect}
        placeholder={this.state.label}
        value={this.state.value || ""}
        style={{ marginBottom: "15px" }}
        options={
          this.state.data
            ? this.state.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
            : ""
        }
      />
    );
  }
}
export default App;
