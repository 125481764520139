import React from "react";
import { getLoggedUser } from "../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import HelpDocumentationMenu from "./Base/HelpDocumentationMenu";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { overrideLoadingStyle } from "./Utilities/Styles";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const ClassName = {
  1: ["nav-active"],
  2: ["nav-expanded", "nav-active"],
};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      processorName: "",
      selectedProcessorId: "",
      errorMessage: "",
      data: [],
    };
    this.myRef = React.createRef();

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getInvitedProcessors = this.getInvitedProcessors.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  setStateFromQueryString = () => {
    var index = window.location.href.indexOf("openSwitchProcessor");
    if (index !== -1) {
      this.handleShow();
    }
  };

  async componentDidMount() {
    //Handle active, expend menu parent based on selected menu item
    const activeRoute = this.props.navRoute;
    if (!activeRoute) return;
    let children = this.myRef.current.children;
    let arrChildren = Array.from(children);
    const className = ClassName[activeRoute.length];
    arrChildren.map((item) => {
      activeRoute.map((route, i) => {
        if (item.getAttribute("name") === route) {
          item.classList.toggle(className[i]);
        }
      });
    });
    this.setStateFromQueryString();
  }

  getInvitedProcessors = () => {
    try {
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_PROCESSOR_URL +
        "invitedProcessorsByLabUser/" +
        loggedUser.referenceUserId
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            resultObject.data.map((item) => {
              if (item._id != loggedUser.processorId) {
                parsedItems.push({
                  _id: item._id,
                  name: item.name,
                  checked: false,
                });
              }
            });
            that.setState({
              data: parsedItems,
              show: true,
              errorMessage: "",
            });
          }
        })
        .catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    const id = event.target.name;
    const value = event.target.value;
    this.setState({ [id]: value });
  }

  handleShow = () => {
    this.getInvitedProcessors();
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const selectedProcessorId = this.state.selectedProcessorId;
    if (
      selectedProcessorId == null ||
      selectedProcessorId === undefined ||
      selectedProcessorId === ""
    ) {
      this.setState({
        errorMessage: "Please select a processor to switch.",
      });
    } else {
      const that = this;
      const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          processorId: selectedProcessorId,
          createdUserId: loggedUser._id,
        }),
      };

      fetch(
        GlobalConfig.REACT_APP_API_LABUSER_URL +
        "switchprocessor/" +
        loggedUser.referenceUserId,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If created successful
          if (resultObject.result === "OK") {
            localStorage.clear();
            window.location.href = process.env.REACT_APP_URL_LOGIN;
          } else {
            that.setState({
              errorMessage: resultObject.message,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            errorMessage: error,
          });
        });
    }
  };

  navExpandHandler(e) {
    window.expandNavigation();
    const element = e.currentTarget;
    element.classList.toggle("nav-expanded");
  }

  render() {
    let pathName = window.location.pathname;
    return (
      <>
        <ul ref={this.myRef} className="nav nav-main">
          <li
            className={pathName === "" || pathName === "/" ? "nav-active" : ""}
          >
            <a href="/" tittle="Dashboards">
              <i className="fa fa-tachometer" aria-hidden="true"></i>
              <span>Dashboards</span>
            </a>
          </li>
          <li
            className="nav-parent"
            onClick={this.navExpandHandler}
            name="grading-test"
          >
            <a>
              <i className="fa fa-file-code-o" aria-hidden="true"></i>
              <span>Grading & Tests</span>
            </a>
            {this.props.testTypesLoading && (
              <ul className="nav nav-children">
                <li>
                  <a className="navigation-item-loading">
                    <ClipLoader
                      cssOverride={overrideLoadingStyle}
                      loading={this.props.testTypesLoading}
                    />
                    <div style={{ marginLeft: 7 }}>Loading...</div>
                  </a>
                </li>
              </ul>
            )}
            {!this.props.testTypesLoading &&
            this.props.menuItemTestTypes &&
            this.props.menuItemTestTypes.length > 0 ? (
              <ul className="nav nav-children">
                {this.props.menuItemTestTypes.map((x) => {
                  return (
                    <li
                      key={x._id}
                      className={
                        (pathName + window.location.search).indexOf(x._id) !==
                          -1
                          ? "nav-active"
                          : ""
                      }
                    >
                      <a
                        href={
                          process.env.REACT_APP_URL_WALNUT_TEST_LIST +
                          "?type=" +
                          x._id +
                          "&name=" +
                          x.name +
                          "&dynamic=" +
                          x.isDynamic
                        }
                        title={x.name}
                      >
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                        <span>{x.name}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </li>
          <HelpDocumentationMenu></HelpDocumentationMenu>
          <li>
            <a href="#" onClick={this.handleShow} tittle="Switch Processor">
              <i className="fa fa-sign-in" aria-hidden="true"></i>
              <span>Switch Processor</span>
            </a>
          </li>
        </ul>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form>
            <Modal.Header>
              <Modal.Title>
                {this.state.data && this.state.data.length
                  ? "Switch Processor"
                  : "Information"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data && this.state.data.length > 0 ? (
                <>
                  <Form.Group as={Row}>
                    <label className="col-md-3 control-label">
                      Selected Processor
                    </label>
                    <label className="col-md-9 control-label">
                      {this.state.processorName}
                    </label>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Switch To
                      </label>
                      <div className="col-md-9">
                        <select
                          name="selectedProcessorId"
                          className="form-control"
                          required={true}
                          onChange={this.handleChangeSelect}
                        >
                          <option value="" hidden>
                            Select one
                          </option>
                          {this.state.data.map((item, key) => {
                            return (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </Form.Group>
                </>
              ) : (
                <Form.Group as={Row}>
                  {this.state.processorName !== "Processor No Longer Exists" ? (
                    <Form.Label column sm="12">
                      Recently there is only one processor{" "}
                      <strong>{this.state.processorName}</strong> invited you.
                    </Form.Label>
                  ) : (
                    <Form.Label column sm="12">
                      Processor No Longer Exists
                    </Form.Label>
                  )}
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3"></Form.Label>
                {this.state.errorMessage == null ||
                  this.state.errorMessage === "" ||
                  this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <Form.Label column sm="9" style={{ color: "red" }}>
                    {this.state.errorMessage}
                  </Form.Label>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="modal-footer-center">
              {this.state.data && this.state.data.length > 0 ? (
                <Button
                  variant="success"
                  onClick={this.handleSave}
                  type="submit"
                >
                  <span className="fa fa-sign-in fa-lg"></span> Switch
                </Button>
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testTypesLoading: state.loading.testTypesLoading,
  };
};

export default connect(mapStateToProps)(App);
