import React from "react";
import ListComponentPayment from "../Base/ListComponentPayment";
import { getLoggedUser } from "../../context/auth";
import SelectConfig from "../Base/SelectConfig";
import { Button, Form, Row, Modal } from "react-bootstrap";
import NumericInput from "../FormControl/NumericInput";
import DataTable from "react-data-table-component";
import SubTable from "../TaxFee/SubTable";
import SelectGrowers from "../Grower/SelectGrowers";
import ListGrower from "../Grower/ListGrower";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import TableAttribute from "../BasePrice/TableAttribute";
import SaveButton from "../Controls/SaveButton";
import Template from "./Template";
import NodeUuid from "node-uuid";
import { parseViewModel, wizardColumns } from "./WizardHandler";
import CommonUtilities from "../Utilities/Common";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.popupUrlApi = GlobalConfig.REACT_APP_API_TAXFEE_URL;
    this.state.parentId = props.match.params.id;
    this.state.urlapi = GlobalConfig.REACT_APP_API_TAXFEE_URL;
    this.state.urllist =
      process.env.REACT_APP_URL_CROPYEAR_LIST +
      process.env.REACT_APP_URL_TAXFEE_LIST +
      "/" +
      props.match.params.id;
    this.state.urledit =
      process.env.REACT_APP_URL_CROPYEAR_LIST +
      process.env.REACT_APP_URL_TAXFEE_LIST +
      "-edit/";
    this.state.title = "Tax & Fee";
    this.state.pluraltitle = "Taxes & Fees";
    this.state.labeladd = "Tax & Fee";
    this.state.urladd = "";
    this.state.expandableRows = true;
    this.state.viewMode = "edit";
    this.state.isExpand = false;
    this.state.isUseHeading = false;
    this.state.cropYearName = "";
    this.state.progressPending = false;
    //Using for popup add/edit
    this.state.data = {
      _id: "",
      parentId: props.match.params.id,
      growerId: "",
      name: "",
      type: "",
      applyType: "",
      value: 0,
      customAttributes: "",
      createdUserId: loggedUser._id,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    };
    this.state.previousGrowerId = "";
    this.state.previousApplyType = "";
    this.state.previousType = "";
    this.state.isChanged = false;
    this.state.errorMessage = "";
    this.state.color = "red";
    this.state.isEditMode = true;
    this.state.removedGrowerId = ""; //This state is using for remove row later
    this.state.defaulApplyType = `All in First Payment`;
    this.state.defaulRuleType = "One Time Fee";
    this.state.showRefresh2 = true;
    this.state.valueDecimals = parseInt(
      GlobalConfig.REACT_APP_TAX_FEE_DECIMALS
    );
    this.state.valueDecimalStep = parseFloat(
      GlobalConfig.REACT_APP_TAX_FEE_DECIMAL_STEP
    );

    this.state.pagination = false;
    this.state.saveLoading = false;

    this.state.testType = {
      xpool: false,
      isDynamic: false,
      _id: "",
      basePriceByPayment: false,
      paymentPercentBased: true,
      multiPricesSameLot: false,
      varietyOptional: false,
      taxFeeByMeta: false,
    };
    this.state.isLoadedTabTemplate = false;
    //This state used for Wizard mode
    this.state.wizardMode = false;
    this.state.isWizardEdit = false;

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleTableAttributeChange =
      this.handleTableAttributeChange.bind(this);
  }

  handleTableAttributeChange = (name, value) => {
    if (
      value !== undefined &&
      value !== null &&
      value.length > 0 &&
      this.state.errorMessage === this.state.errorEmptyAttribute
    ) {
      this.setState({
        errorMessage: "",
      });
    }
    this.setState({
      data: Object.assign({}, this.state.data, {
        [name]: value,
      }),
    });
  };

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div className="panel-actions" style={{ top: "0px" }}>
        {this.state.viewMode !== "view" ? (
          <>
            <a href="#" title={GlobalConfig.REACT_APP_MSG_TAX_FEE_WARNING}>
              <i
                className="fa fa-exclamation fa-lg"
                style={{ color: "red" }}
              ></i>
            </a>
            &nbsp;&nbsp;&nbsp;
          </>
        ) : (
          ""
        )}
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg" style={{ color: "#1891E5" }}></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleChangeValue(value) {
    this.setState({
      data: Object.assign({}, this.state.data, {
        ["value"]: value,
      }),
    });
  }

  extendRender = () => {
    return (
      <>
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={true}
          isWizard={true}
          loggedUser={loggedUser}
          baseApiUrl={GlobalConfig.REACT_APP_API_TAXFEE_URL}
          handleShowWithWizardMode={this.handleShowWithWizardMode}
          changeWizardMode={this.changeWizardMode}
          parseViewModel={parseViewModel}
          columns={wizardColumns(
            (row, index) => {
              if (this.listGrower) {
                this.listGrower.setEditedIndex(index);
              }
              this.handleShowWithWizardMode(row);
            },
            this.state.testType,
            loggedUser,
            (index) => {
              if (this.listGrower) {
                this.listGrower.removeRow(index);
              }
            }
          )}
        />
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {this.state.data._id === ""
                  ? "Add Tax & Fee"
                  : "Edit Tax & Fee"}{" "}
                {this.state.cropYearName !== ""
                  ? " - " + this.state.cropYearName
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  {!this.state.wizardMode && (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="growerId"
                      >
                        Default/Grower <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <SelectGrowers
                          required={false}
                          onRef={(ref) => (this.growerId = ref)}
                          name="growerId"
                          value={this.state.data.growerId}
                          selectedIds={this.state.data.growerId}
                          handleChangeSelectGrower={
                            this.handleChangeSelectGrower
                          }
                          testTypeId={this.state.testType._id}
                          cropYearId={this.state.parentId}
                          checkExistPaymentSetting={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label
                      className="col-md-4 control-label"
                      htmlFor="applyType"
                    >
                      Tax or Fee Applied <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <SelectConfig
                        configType="Tax or Fee Applied"
                        required={true}
                        onRef={(ref) => (this.applyType = ref)}
                        name="applyType"
                        controlId="applyType"
                        selectedIds={this.state.data.applyType}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4 control-label" htmlFor="type">
                      Rule Type <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <SelectConfig
                        configType="Tax & Fee"
                        required={true}
                        onRef={(ref) => (this.type = ref)}
                        name="type"
                        controlId="type"
                        selectedIds={this.state.data.type}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4 control-label">
                      Rule Name <span className="required">(*)</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        name="name"
                        autoFocus={true}
                        className="form-control"
                        placeholder=""
                        required
                        value={this.state.data.name}
                        onChange={this.handleChangeObjectData}
                        maxLength={200}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <NumericInput
                      precision={this.state.valueDecimals}
                      min={0}
                      max={999999999}
                      step={this.state.valueDecimalStep}
                      label="Value"
                      name="value"
                      value={this.state.data.value}
                      handleChange={this.handleChangeObjectData}
                      unit="$"
                      labelClass="4"
                      inputClass="8"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>
                  {this.state.testType.taxFeeByMeta ? (
                    <div className="form-group">
                      <TableAttribute
                        name="customAttributes"
                        onRef={(ref) => (this.customAttributes = ref)}
                        handleTableAttributeChange={
                          this.handleTableAttributeChange
                        }
                        processorId={loggedUser.processorId}
                        value={this.state.data.customAttributes}
                        testTypeId={this.state.testType._id}
                        apiUrlDynamicAttribute={
                          GlobalConfig.REACT_APP_API_NUTTYPE_URL +
                          "getMetaDataFieldsByTestType/" +
                          this.state.testType._id
                        }
                        loggedUser={loggedUser}
                        GlobalConfig={GlobalConfig}
                        emptyClass="col-md-4"
                        tableClass="col-md-8"
                        objectName="Tax & Fee"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected tax & fee no longer exists. Please refresh and try
                    again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage == "" ||
                this.state.errorMessage == undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer style={{ textAlign: "center" }}>
              {this.state.data ? (
                <SaveButton loading={this.state.saveLoading} />
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
              <hr></hr>
              <div className="col-md-12">
                <Form.Label
                  column
                  md="12"
                  style={{
                    color: this.state.color,
                    paddingLeft: "0px",
                  }}
                >
                  {GlobalConfig.REACT_APP_MSG_TAX_FEE_WARNING}
                </Form.Label>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };

  changeWizardMode = (toggle) => {
    this.setState({
      wizardMode: toggle,
    });
  };

  filterItems = ({ testType, existItems, compareObj }) => {
    let itemsByParent = [];
    if (existItems && existItems.length > 0) {
      itemsByParent = existItems.filter(({ name }) => {
        return (
          compareObj.name.trim().toLowerCase() === name.trim().toLowerCase()
        );
      });
      if (
        testType.taxFeeByMeta === true &&
        itemsByParent &&
        itemsByParent.length > 0
      ) {
        const newCustomAttributes =
          compareObj.customAttributes === undefined ||
          compareObj.customAttributes === null ||
          compareObj.customAttributes === ``
            ? []
            : JSON.parse(compareObj.customAttributes);
        const errorMessage = CommonUtilities.checkDuplicateMetaAttribute(
          testType,
          newCustomAttributes,
          itemsByParent
        );
        if (errorMessage !== ``) {
          return itemsByParent;
        } else {
          return [];
        }
      }
    }

    return itemsByParent;
  };

  handleShowWithWizardMode = (data) => {
    let isWizardEdit = data ? true : false;
    const dataModel = data
      ? data
      : {
          _id: "",
          parentId: this.state.parentId,
          name: "",
          applyType: this.state.defaulApplyType,
          type: this.state.defaulRuleType,
          value: 0,
          customAttributes: "",
        };
    var convertedJSON = JSON.parse(JSON.stringify(dataModel));
    this.setState({ data: convertedJSON }, () => {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isWizardEdit: isWizardEdit,
        },
        () => {
          //Set selected dropdown
          this.type.setSelected(this.state.data.type);
          this.applyType.setSelected(this.state.data.applyType);
        }
      );
    });
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }

    this.setState(
      { data: convertedJSON, wizardMode: false },
      function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
            isEditMode: isEditMode,
          },
          function callBack() {
            //Set selected dropdown
            this.type.setSelected(this.state.data.type);
            this.applyType.setSelected(this.state.data.applyType);
          }
        );
      }
    );
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
      saveLoading: true,
    });

    const applyType = this.state.data.applyType;
    const type = this.state.data.type;
    const name = this.state.data.name;
    let errorMessage = "";
    if (applyType == null || applyType === undefined || applyType === "") {
      errorMessage = "Please select a Tax or Fee Applied to continue.";
    } else if (type == null || type === undefined || type === "") {
      errorMessage = "Please select a type to continue.";
    }
    if (errorMessage === "") {
      if (name == null || name === undefined || name.trim() === "") {
        errorMessage = "Please enter name to continue.";
      }
    }
    if (errorMessage === "") {
      if (
        this.state.data.value == null ||
        this.state.data.value === undefined ||
        this.state.data.value === 0
      ) {
        errorMessage = "Please enter value.";
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
        saveLoading: false,
      });
      return;
    }
    try {
      if (this.state.wizardMode) {
        this.saveWizard();
      } else {
        this.save();
      }
    } catch (error) {
      window.showAlert("Error", error, "error");
      this.setState({ saveLoading: false });
    }
  };

  save = async () => {
    let previousGrowerId = this.state.data.growerId;
    let previousType = this.state.data.type;
    let previousApplyType = this.state.data.applyType;
    //Using for edit
    let urlApi = GlobalConfig.REACT_APP_API_TAXFEE_URL + this.state.data._id;
    let method = "PATCH";
    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_TAXFEE_URL;
      isEditMode = false;
    }

    let requestData = { ...this.state.data };
    requestData = JSON.parse(JSON.stringify(requestData));
    requestData.testType = this.state.testType;

    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        //If created successful
        if (resultObject.result === "OK") {
          window.showAlert("", "Save successful!", "");
          that.setState(
            {
              isChanged: true,
              previousGrowerId: previousGrowerId,
              previousType: previousType,
              previousApplyType: previousApplyType,
              saveLoading: false,
            },
            function callBack() {
              that.updateStateAfterClosePopup(resultObject.data);
              if (!isEditMode) {
                that.handResetForm();
              }
            }
          );
        } else {
          window.showAlert("Error", resultObject.message, "error");
          that.setState({
            saveLoading: false,
          });
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
        that.setState({
          saveLoading: false,
        });
      });
  };

  saveWizard = () => {
    if (this.state.isWizardEdit) {
      let dataObj = {
        ...this.state.data,
      };
      const existItems = this.listGrower.state.defaultSettings.filter(
        (_, index) => index !== this.listGrower.state.editedIndex
      );
      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        existItems: existItems,
        compareObj: {
          name: dataObj.name,
          customAttributes: dataObj.customAttributes,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: false,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other date.",
          "error"
        );
        return;
      }
      this.listGrower.editRow(dataObj);
      this.setState({
        show: false,
        saveLoading: false,
      });
    } else {
      //Add new setting from popup copy wizard
      let { name, type, applyType, value, customAttributes } = this.state.data;
      const existItems = this.listGrower.state.defaultSettings;

      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        existItems,
        compareObj: {
          name,
          customAttributes,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: false,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other values.",
          "error"
        );
        return;
      }
      let newItemList = [
        {
          _id: NodeUuid.v4(),
          name: name,
          type: type,
          applyType: applyType,
          value: value,
          customAttributes: customAttributes,
        },
      ];
      this.listGrower.appendRows(newItemList);
      this.setState({
        show: false,
        saveLoading: false,
      });
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          name: "",
          type: this.state.previousType,
          applyType: this.state.previousApplyType,
          value: 0,
          customAttributes: "",
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        if (this.customAttributes) {
          this.customAttributes.reset();
        }
      }
    );

    //Set selected dropdown
    this.applyType.setSelected(this.state.previousApplyType);
    this.type.setSelected(this.state.previousType);
    this.growerId.setSelected(this.state.previousGrowerId);
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  getItems = async () => {
    try {
      const that = this;
      that.setState({
        progressPending: true,
      });
      fetch(this.state.urlapi + "parent/" + this.state.parentId)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            if (resultObject.cropYear) {
              const cropYearName =
                resultObject.cropYear.name +
                " (" +
                resultObject.cropYear.startDate +
                "-" +
                resultObject.cropYear.endDate +
                ")";

              const handleTruncate = that.truncateTitle(
                cropYearName,
                that.setTitleLength()
              );

              that.setState({
                pluraltitle: "Taxes & Fees - Crop Year " + handleTruncate,
                cropYearName: cropYearName,
              });
            }

            that.setState(
              {
                items: resultObject.data,
                originalItems: resultObject.data,
                growers: [],
                progressPending: false,
              },
              function callBack() {
                that.refreshTable();
                that.doSomethingAfterGetItems(resultObject);
              }
            );
          }
        })
        .catch(function (error) {
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
            progressPending: false,
          });
        });
    } catch (err) {
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
        progressPending: false,
      });
    }
  };

  defaultTable = () => {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs tabs-primary">
          <li className="active">
            <a href="#Default" data-toggle="tab">
              Taxes & Fees
            </a>
          </li>
          <li>
            <a
              href="#Template"
              data-toggle="tab"
              onClick={() => {
                if (this.state.isLoadedTabTemplate === false) {
                  this.template.getItems();
                  this.template.getGrowers();
                  this.setState({
                    isLoadedTabTemplate: true,
                  });
                }
              }}
            >
              Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="Default"
            className="tab-pane active"
            style={{ margin: "-10px" }}
          >
            <DataTable
              highlightOnHover
              striped
              id="emp"
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              expandableRows={this.state.expandableRows}
              expandableRowsComponent={
                <SubTable
                  handleDelete={this.handleDelete.bind(this)}
                  handleShow={this.handleShow}
                  expandableRowExpanded={(row) => {
                    return this.state.isExpand;
                  }}
                  loggedUser={loggedUser}
                  testType={this.state.testType}
                />
              }
              conditionalRowStyles={this.conditionalRowStyles()}
              noTableHead={true}
              expandableRowExpanded={(row) => {
                return this.state.isExpand;
              }}
              pagination={this.state.pagination}
              paginationPerPage={this.state.paginationPerPage}
              paginationRowsPerPageOptions={this.state.rowPerPageOption}
              paginationServer={true}
              paginationTotalRows={this.state.totalRows}
              paginationDefaultPage={this.state.currentPage}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              progressPending={this.state.progressPending}
              progressComponent={this.defaultProgressComponent()}
            />
          </div>
          <div id="Template" className="tab-pane" style={{ margin: "-10px" }}>
            <Template
              onRef={(ref) => (this.template = ref)}
              cropYearId={this.props.match.params.id}
              loggedUser={loggedUser}
              processorId={loggedUser.processorId}
              testTypeId={this.state.testType._id}
              testType={this.state.testType}
              valueDecimals={this.state.valueDecimals}
              valueDecimalStep={this.state.valueDecimalStep}
              refreshMainTab={this.getItems}
            />
          </div>
        </div>
      </div>
    );
  };

  extendButtons = () => {
    return (
      <>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>
        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <Button
              variant="primary"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  growerId: "",
                  name: "",
                  applyType: this.state.defaulApplyType,
                  type: this.state.defaulRuleType,
                  value: 0,
                  customAttributes: "",
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              type="button"
              className="mb-sm"
            >
              <span className="fa fa-plus fa-lg"></span> {this.state.labeladd}
            </Button>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
          </>
        )}
      </>
    );
  };
}
export default App;
