import AddComponent from "../Base/AddComponent";
import String from "../Utilities/String";
import JoditEditor from "jodit-react";
import MultiSelect from "../FormControl/MultiSelect";
import Switch from "react-switch";
import SaveButton from "../Controls/SaveButton";
import { connect } from "react-redux";
import {
  setHelpCategories,
  addHelpCategory,
} from "../../redux/actions/helpActions";

const GlobalConfig = new window.globalConfig();

class Add extends AddComponent {
  constructor(props) {
    super(props);
    const parentState = { ...this.state };
    this.state = {
      title: "Help",
      listtitle: "Helps",
      urlapi: GlobalConfig.REACT_APP_API_HELP_URL,
      urllist: process.env.REACT_APP_URL_HELP_LIST,
      data: {
        title: "",
        category: "",
        subCategory: "",
        order: 1,
        pages: "",
        isPublished: false,
        content: "",
      },
      //This states are used for call api to get data for dropdown Category , Sub category and Pages
      categoryType: "Help Category",
      subCategoryType: "Help Subcategory",
      pageType: "Help Page",
      loading: parentState.loading,
    };
  }

  onChangePages = (pages) => {
    let values = "";
    if (pages.length > 0) {
      values = pages.map((page) => page.value).join(",");
    }
    this.setState({
      isChanged: true,
      data: { ...this.state.data, pages: values },
    });
  };

  onChangePublish = (checked) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, isPublished: checked },
    });
  };

  onChangeCategory = (category) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, category: category.value },
    });
  };

  // onChangeSubCategory = (subCategory) => {
  //   this.setState({
  //     isChanged: true,
  //     data: { ...this.state.data, subCategory: subCategory.value },
  //   });
  // };

  onChangeContent = (content) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, content: content },
    });
  };

  handResetForm = () => {
    //Add new category to be new menu item under Help Documentation
    const newCategory = this.state.data.category;
    if (this.props.categories) {
      const existCategoryIndex = [...this.props.categories].findIndex(
        (category) => category.name === newCategory
      );
      if (existCategoryIndex === -1) {
        this.props.addHelpCategory({
          name: newCategory,
          count: 1,
        });
      } else {
        const newCategories = [...this.props.categories];
        newCategories[existCategoryIndex].count++;
        this.props.setHelpCategories(newCategories);
      }
    }
    this.setState({
      data: {
        title: "",
        category: "",
        subCategory: "",
        order: 1,
        pages: "",
        isPublished: false,
        content: "",
      },
    });
  };

  validateBeforeAddHelp = (help) => {
    const messages = [];
    if (String.isEmpty(help.title)) {
      messages.push("Title is required");
    }
    if (String.isEmpty(help.content)) {
      messages.push("Content is required");
    }
    if (String.isEmpty(help.category)) {
      messages.push("Category is required");
    }
    return messages;
  };

  checkSomethingBeforeSave = () => {
    const help = { ...this.state.data };
    const messages = this.validateBeforeAddHelp(help);
    if (messages.length > 0) {
      messages.forEach((message) => {
        window.showAlert("Warning", message, "error");
      });
      return false;
    } else {
      return true;
    }
  };

  renderForm = () => {
    return (
      <>
        <div className="panel-body">
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Title <span className="required">(*)</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                name="title"
                autoFocus={true}
                className="form-control"
                placeholder=""
                required
                value={this.state.data.title}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Sub Category
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `likename/` +
                  this.state.subCategoryType
                }
                closeMenuOnSelect={true}
                isMulti={false}
                value={this.state.data.subCategory}
                onChange={this.onChangeSubCategory}
                parseObjectItem={this.parseObjectItem}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <NumericInput
                precision={0}
                min={1}
                max={100}
                step={1}
                label="Order"
                name="order"
                value={this.state.data.order}
                handleChange={this.handleChange}
                unit=""
                labelClass="2 field-help-label"
                inputClass="10"
                required={false}
                labelrequired=""
              />
            </div>
          </div> */}
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Content <span className="required">(*)</span>
            </label>
            <div className="col-sm-10 content-editor">
              <JoditEditor
                name="message"
                value={this.state.data.content}
                onChange={this.onChangeContent}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Category <span className="required">(*)</span>
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `likename/` +
                  this.state.categoryType
                }
                closeMenuOnSelect={true}
                isMulti={false}
                value={this.state.data.category}
                onChange={this.onChangeCategory}
                parseObjectItem={(data) => {
                  return {
                    label: data,
                    value: data,
                  };
                }}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Pages
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `helppages/` +
                  this.state.pageType
                }
                closeMenuOnSelect={false}
                isMulti={true}
                value={this.state.data.pages}
                onChange={this.onChangePages}
                parseObjectItem={(data) => {
                  return {
                    label: data.label,
                    value: data.value,
                  };
                }}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Publish
            </label>
            <div className="col-sm-10">
              <Switch
                name="isPublished"
                onChange={this.onChangePublish}
                checked={this.state.data.isPublished}
                className="react-switch"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row">
          <div
            className="col-sm-12 col-sm-offset-3"
            style={{ textAlign: "center", marginLeft: 0 }}
          >
            <SaveButton loading={this.state.loading} />
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          </div>
        </div>
      </footer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    categories: state.helpCategories,
  };
};

export default connect(mapStateToProps, { setHelpCategories, addHelpCategory })(
  Add
);
