import React from "react";
import DataTable from "react-data-table-component";

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: props.data.errors.map((x) => {
        return { error: x.replace(`INVALID: `, ``) };
      }),
    };
  }

  setColumns = () => {
    return [
      {
        name: "Errors",
        selector: "error",
        wrap: true,
      },
    ];
  };

  render() {
    return (
      <DataTable
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
