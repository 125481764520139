import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{"Access Denied | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Access Denied</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>Access Denied</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="body-error error-inside">
                <div className="center-error">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-error mb-xlg">
                        {/* <h2 className="error-code text-dark text-center text-semibold m-none">
                        404 <i className="fa fa-file"></i>
                      </h2> */}
                        <div className="center">
                          <img src="/assets/images/access-denied.png"></img>
                        </div>
                        <p className="error-explanation text-center">
                          We're sorry, but the page you were looking for not match
                          with your role!
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
