import { Popover as PopoverBootstrap, OverlayTrigger } from "react-bootstrap";

const Popover = (props) => {
  const {
    children,
    placement,
    trigger,
    content,
    className,
    isUseDefaultOverlay,
    overlayCustom,
    rootClose,
    customContentClassName,
  } = props;

  const overlay = isUseDefaultOverlay ? (
    <PopoverBootstrap className={className}>
      <PopoverBootstrap.Content>
        <div
          className={
            customContentClassName
              ? customContentClassName
              : "popover-content-wrapper"
          }
        >
          {content}
        </div>
      </PopoverBootstrap.Content>
    </PopoverBootstrap>
  ) : (
    overlayCustom
  );

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      transition={false}
      overlay={overlay}
      rootClose={rootClose}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Popover;
