import React from "react";
import EditComponent from "../Base/EditComponent";
import JoditEditor from "jodit-react";
import String from "../Utilities/String";
import MultiSelect from "../FormControl/MultiSelect";
import Switch from "react-switch";
import SaveButton from "../Controls/SaveButton";
import { connect } from "react-redux";
import { setHelpCategories } from "../../redux/actions/helpActions";

const GlobalConfig = new window.globalConfig();

class Edit extends EditComponent {
  constructor(props) {
    super(props);
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Helps",
      urlapi: GlobalConfig.REACT_APP_API_HELP_URL,
      urllist: process.env.REACT_APP_URL_HELP_LIST,
      data: {
        _id: props.match.params.id,
        title: "",
        category: "",
        subCategory: "",
        order: 1,
        pages: "",
        isPublished: false,
        content: "",
      },
      previousData: null,
      //This states are used for call api to get data for dropdown Category , Sub category and Pages
      categoryType: "Help Category",
      subCategoryType: "Help Subcategory",
      pageType: "Help Page",
      loading: parentState.loading,
      newCategory: "",
    };
  }

  onChangePages = (pages) => {
    let values = "";
    if (pages.length > 0) {
      values = pages.map((page) => page.value).join(",");
    }
    this.setState({
      isChanged: true,
      data: { ...this.state.data, pages: values },
    });
  };

  onChangePublish = (checked) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, isPublished: checked },
    });
  };

  onChangeCategory = (category) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, category: category.value },
    });
  };

  // onChangeSubCategory = (subCategory) => {
  //   this.setState({
  //     isChanged: true,
  //     data: { ...this.state.data, subCategory: subCategory.value },
  //   });
  // };

  onChangeContent = (content) => {
    this.setState({
      isChanged: true,
      data: { ...this.state.data, content: content },
    });
  };

  getTitle = () => {
    return this.state.data.title;
  };

  validateBeforeEditHelp = (help) => {
    const messages = [];
    if (String.isEmpty(help.title)) {
      messages.push("Title is required");
    }
    if (String.isEmpty(help.content)) {
      messages.push("Content is required");
    }
    if (String.isEmpty(help.category)) {
      messages.push("Category is required");
    }
    return messages;
  };

  checkSomethingBeforeSave = () => {
    const help = { ...this.state.data };
    const messages = this.validateBeforeEditHelp(help);
    if (messages.length > 0) {
      messages.forEach((message) => {
        window.showAlert("Warning", message, "error");
      });
      return false;
    } else {
      return true;
    }
  };

  doSomethingAfterSaved = () => {
    //This function updates menu items in left navigation (Help Documentation)
    if (!this.state.previousData || !this.props.categories) {
      return;
    }
    if (this.state.previousData.category !== this.state.data.category) {
      const previousCategory = this.state.previousData.category;
      const newCategory = this.state.data.category;
      const currentCategories = [...this.props.categories];
      //decrease previous category count
      const decreasedCategories = this.decreaseCategory(
        currentCategories,
        previousCategory
      );
      //Increase new category count
      const increasedCategories = this.increaseCategory(
        decreasedCategories,
        newCategory
      );
      this.props.setHelpCategories(increasedCategories);
    }
    this.setState({
      previousData: this.state.data,
    });
  };

  decreaseCategory = (categories, decreasedCategoryName) => {
    const decreasedCategoryIndex = [...categories].findIndex(
      (category) => category.name === decreasedCategoryName
    );
    if (decreasedCategoryIndex === -1) {
      return categories;
    }
    if (
      ![null, undefined].includes(categories[decreasedCategoryIndex].count) &&
      typeof categories[decreasedCategoryIndex].count === "number" &&
      categories[decreasedCategoryIndex].count > 1
    ) {
      categories[decreasedCategoryIndex].count--;
    } else {
      categories = [...categories].filter(
        (_, index) => index !== decreasedCategoryIndex
      );
    }
    return categories;
  };

  increaseCategory = (categories, increasedCategoryName) => {
    const increasedCategoryIndex = [...categories].findIndex(
      (category) => category.name === increasedCategoryName
    );
    if (increasedCategoryIndex === -1) {
      categories.push({
        name: increasedCategoryName,
        count: 1,
      });
      categories.sort((a, b) => a.name.localeCompare(b.name));
      return categories;
    }
    if (
      ![null, undefined].includes(categories[increasedCategoryIndex].count) &&
      typeof categories[increasedCategoryIndex].count === "number"
    ) {
      categories[increasedCategoryIndex].count++;
    }
    return categories;
  };

  doSomethingAfterGetItemById = () => {
    this.setState({
      previousData: this.state.data,
    });
  };

  renderForm = () => {
    return (
      <>
        <div className="panel-body">
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Title <span className="required">(*)</span>
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                name="title"
                autoFocus={true}
                className="form-control"
                placeholder=""
                required
                value={this.state.data.title}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Sub Category
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `likename/` +
                  this.state.subCategoryType
                }
                closeMenuOnSelect={true}
                isMulti={false}
                value={this.state.data.subCategory}
                onChange={this.onChangeSubCategory}
                parseObjectItem={this.parseObjectItem}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-12">
              <NumericInput
                precision={0}
                min={1}
                max={100}
                step={1}
                label="Order"
                name="order"
                value={this.state.data.order}
                handleChange={this.handleChange}
                unit=""
                labelClass="2 field-help-label"
                inputClass="10"
                required={false}
                labelrequired=""
              />
            </div>
          </div> */}
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Content <span className="required">(*)</span>
            </label>
            <div className="col-sm-10 content-editor">
              <JoditEditor
                name="message"
                value={this.state.data.content}
                onChange={this.onChangeContent}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Category <span className="required">(*)</span>
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `likename/` +
                  this.state.categoryType
                }
                closeMenuOnSelect={true}
                isMulti={false}
                value={this.state.data.category}
                onChange={this.onChangeCategory}
                parseObjectItem={(data) => {
                  return { value: data, label: data };
                }}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Pages
            </label>
            <div className="col-sm-10">
              <MultiSelect
                api={
                  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                  `helppages/` +
                  this.state.pageType
                }
                closeMenuOnSelect={false}
                isMulti={true}
                value={this.state.data.pages}
                onChange={this.onChangePages}
                parseObjectItem={(data) => {
                  return {
                    label: data.label,
                    value: data.value,
                  };
                }}
              ></MultiSelect>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label field-help-label">
              Publish
            </label>
            <div className="col-sm-10">
              <Switch
                name="isPublished"
                onChange={this.onChangePublish}
                checked={this.state.data.isPublished}
                className="react-switch"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row">
          <div
            className="col-sm-12 col-sm-offset-3"
            style={{ textAlign: "center", marginLeft: 0 }}
          >
            <SaveButton loading={this.state.loading} />
            {this.state.urllist === null ||
            this.state.urllist === undefined ||
            this.state.urllist === "" ? (
              ""
            ) : (
              <a
                href={this.state.urllist}
                title="Back to list"
                className="btn btn-primary"
              >
                <i className="fa fa-backward fa-lg"></i> Back
              </a>
            )}
            {this.extendButtons()}
          </div>
        </div>
      </footer>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    categories: state.helpCategories,
  };
};

export default connect(mapStateToProps, {
  setHelpCategories,
})(Edit);
