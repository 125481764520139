import React from "react";
import Switch from "react-switch";
import { Button, Form, Modal } from "react-bootstrap";
// import DraggableModalDialog from "../Base/DraggableModalDialog";
import LoadingScreen from "../Base/LoadingScreen";
import String from "../Utilities/String";
import WizardPopup from "../Base/WizardPopup";
import AlertMessage from "../Base/AlertMessage";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      processorId: props.processorId,
      userId: props.userId,
      value: props.value,
      parentId: props.parentId,
      growers: [],
      data: [],
      dataAfterFilter: [],
      filterGrowersObject: {
        name: "",
        address: "",
        city: "",
        state: "",
        region: "",
      },
      //State using for popup add/edit payment setting
      show: false,
      isChanged: false,
      isLoading: 0,
      errorMessage: "",
      color: "red",
      //State is used for wizard logic
      showWizard: false,
      selectedGrower: { _id: "", name: "", checked: false },
      previousDefaultSettings: [],
      defaultSettings: [],
      editedIndex: 0,
      errorDuplicate: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleCheckSameWithDefault =
      this.handleCheckSameWithDefault.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      let urlapi = ``;
      if (this.props.checkExistPaymentSetting) {
        urlapi = `${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersExistPayment/${this.props.processorId}/${this.props.testType._id}/${this.state.parentId}`;
      } else {
        urlapi = `${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersByProcessorAndTestType/${this.props.processorId}/${this.props.testType._id}`;
      }

      const fetchItem = await fetch(urlapi);
      const response = await fetchItem.json();
      if (response && response.result === "OK" && response.data) {
        const parsedItems = [];
        response.data.map((item) => {
          parsedItems.push({
            _id: item._id,
            name: item.name,
            checked:
              this.state.value == null ||
              this.state.value === undefined ||
              this.state.value.indexOf(item._id) === -1
                ? false
                : true,
          });
        });

        this.setState({
          data: parsedItems,
          dataAfterFilter: parsedItems,
          growers: response.data,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    this.setState({ value: selectedIds });
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds == null ||
          selectedIds == undefined ||
          selectedIds.indexOf(item._id) == -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleChange(index, info) {
    const checked = !this.state.data[index].checked;
    let newValue = "";
    if (checked === true) {
      if (this.props.isWizard === true) {
        // Handle open wizard, hide Apply Grower popup, save Grower info to state
        this.props.changeWizardMode(true);
        this.setState({
          showWizard: true,
          show: false,
          selectedGrower: info,
        });
        this.getDefaultSettings();
        return;
      }
      if (
        this.state.value === null ||
        this.state.value === undefined ||
        this.state.value === ``
      ) {
        newValue = info._id;
      } else if (this.state.value.indexOf(info._id) === -1) {
        newValue = this.state.value + "," + info._id;
      }
    } else {
      if (
        this.state.value !== null &&
        this.state.value !== undefined &&
        this.state.value.indexOf(info._id) !== -1
      ) {
        newValue = this.state.value
          .split(`,`)
          .filter((x) => {
            return x !== info._id;
          })
          .join(`,`);
      }
    }

    this.setState(
      {
        data: this.state.data.map((u) =>
          u._id === info._id
            ? Object.assign({}, u, {
                checked: checked,
              })
            : u
        ),
        dataAfterFilter: this.state.dataAfterFilter.map((u) =>
          u._id === info._id
            ? Object.assign({}, u, {
                checked: checked,
              })
            : u
        ),
        value: newValue,
      },
      function callBack() {
        this.handleSave(this.state.data[index]._id, checked);
        this.setState({
          data: this.state.data,
        });
      }
    );
  }

  handleClose = () => {
    this.setState({ show: false });
    this.resetFilterGrowersObject();
    if (this.state.isChanged) {
      //fire event to parent
      this.props.handleChangeListGrower();
    }
  };

  handleSave = async (growerId, checked) => {
    //Reset message
    this.setState({
      errorMessage: "",
      isLoading: 1,
    });

    let urlApi =
      growerId === undefined
        ? this.props.urlApi +
          "copy/" +
          this.state.processorId +
          "/" +
          this.props.testType._id +
          "/" +
          this.props.parentId
        : this.props.urlApi +
          "copy/" +
          this.state.processorId +
          "/" +
          this.props.testType._id +
          "/" +
          this.props.parentId +
          "/" +
          growerId;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        parentId: this.state.parentId,
        checked: checked,
        userId: this.state.userId, //using for audit log
        createdUserId: this.state.userId, //using for audit log
      }),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          window.showAlert("", resultObject.message, "");
          that.setState({
            isChanged: true,
            isLoading: 0,
          });
          //action is called from copy/reset all then update radio on/off
          if (
            growerId === undefined &&
            that.state.data &&
            that.state.data.length > 0
          ) {
            let copyData = [...that.state.data];
            copyData.map((x) => {
              x.checked = checked;
            });
            that.setState({
              data: copyData,
            });
          }
        } else {
          that.setState({
            isLoading: 0,
          });
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        that.setState({
          isLoading: 0,
        });
        window.showAlert("Error", error, "error");
      });
  };

  handleShow = (selectedIds) => {
    //call get items to reset
    this.getItems().then(() => {
      this.setState({ value: selectedIds });
      if (this.state.data) {
        let updatedData = this.state.data;
        updatedData.forEach((item) => {
          if (
            selectedIds == null ||
            selectedIds === undefined ||
            selectedIds.indexOf(item._id) === -1
          ) {
            item.checked = false;
          } else {
            item.checked = true;
          }
        });
        this.setState({ data: updatedData });
      }

      this.setState({
        show: true,
        errorMessage: "",
        isChanged: false,
      });
    });
  };

  handleChangeFilter = (e) => {
    this.setState(
      {
        filterGrowersObject: {
          ...this.state.filterGrowersObject,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.filterGrowers();
      }
    );
  };

  filterGrowers = () => {
    let growersAfterFilter = [];
    let conditions = Object.keys({ ...this.state.filterGrowersObject }); // convert object properties to an array of properties.
    conditions = conditions.filter(
      (condition) => !String.isEmpty(this.state.filterGrowersObject[condition])
    );
    if (conditions.length > 0) {
      //If there is one or more filter field, then filter , else not filter
      growersAfterFilter = [...this.state.growers]
        .filter((grower) => this.isCorrect(grower, conditions))
        .map((grower) => {
          return {
            _id: grower._id,
            name: grower.name,
            checked:
              this.state.value === null ||
              this.state.value === undefined ||
              this.state.value.indexOf(grower._id) === -1
                ? false
                : true,
          };
        });
    } else {
      growersAfterFilter = [...this.state.growers].map((grower) => {
        return {
          _id: grower._id,
          name: grower.name,
          checked:
            this.state.value === null ||
            this.state.value === undefined ||
            this.state.value.indexOf(grower._id) === -1
              ? false
              : true,
        };
      });
    }
    this.setState({
      dataAfterFilter: growersAfterFilter,
    });
  };

  isCorrect = (grower, conditions) => {
    let isCorrect = true;
    //Loop to check each condition with passed grower
    for (let i = 0; i < conditions.length; i++) {
      if (String.isEmpty(grower[conditions[i]])) {
        isCorrect = false;
        break;
      }
      if (
        grower[conditions[i]]
          .toLowerCase()
          .includes(
            this.state.filterGrowersObject[conditions[i]].toLowerCase()
          ) === false
      ) {
        isCorrect = false;
        break;
      }
    }
    return isCorrect;
  };

  resetFilterGrowersObject = () => {
    this.setState({
      filterGrowersObject: {
        name: "",
        address: "",
        city: "",
        state: "",
        region: "",
      },
    });
  };

  handleCheckSameWithDefault = (growerId) => {
    fetch(
      `${this.props.baseApiUrl}checkduplicatedefault/${this.state.parentId}/${growerId}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          window.showAlert("", resultObject.message, "");
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  handleWizardValidate = () => {
    const { previousDefaultSettings, defaultSettings } = this.state;
    if (previousDefaultSettings.length === defaultSettings.length) {
      const length = previousDefaultSettings.length;
      for (let i = 0; i < length; i++) {
        let isSame = CommonUtilities.isDeepEqual(
          previousDefaultSettings[i],
          defaultSettings[i]
        );
        if (isSame === false) {
          return this.setState({
            errorDuplicate: false,
          });
        }
      }
      return this.setState({
        errorDuplicate: true,
      });
    } else {
      return this.setState({
        errorDuplicate: false,
      });
    }
  };

  handleCloseWizard = () => {
    this.props.changeWizardMode(false);
    this.setState({ showWizard: false, show: true });
  };

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  getDefaultSettings = async () => {
    try {
      const apiGetDefaultSettings = `${this.props.baseApiUrl}children/${
        this.props.parentId
      }/${null}/1/0`;
      const that = this;
      fetch(apiGetDefaultSettings)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const defaultSettings = resultObject.data.map((e) => {
              return that.props.parseViewModel(e);
            });

            that.setState({
              previousDefaultSettings: defaultSettings,
              defaultSettings: defaultSettings,
            });
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err, "error");
    }
  };

  renderWizardFirstStep = () => {
    return (
      <>
        <div
          className="flex-box-align-items-center"
          style={{
            width: "100%",
            justifyContent: "right",
            paddingBottom: 15,
          }}
        >
          <Button
            variant="primary"
            onClick={() => this.props.handleShowWithWizardMode()}
            type="button"
            className="flex-box-align-items-center"
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
              style={{ marginRight: 5, fontSize: 20 }}
            ></i>
            Add
          </Button>
        </div>

        <DataTable
          highlightOnHover
          striped
          id="emp"
          title=""
          noHeader={true}
          columns={this.props.columns}
          data={this.state.defaultSettings}
          className="table table-bordered table-striped mb-none"
          pagination={true}
          noDataComponent={`There is no setting to copy. Please close this popup and update default setting then try again or click on Add button to manual add new setting.`}
        />
      </>
    );
  };

  handleWizardPopup() {
    const renderWizardSecondStep = () => {
      if (!this.state.errorDuplicate) {
        return (
          <AlertMessage
            type="success"
            title="Congratulation! Grower's settings are different with default. Please continue next step."
          />
        );
      }
      return (
        <AlertMessage
          type="error"
          title="Does not allow proceed next step as grower's settings are same with default. Please back first step and modify."
        />
      );
    };

    const renderWizardThirdStep = (
      <AlertMessage
        title={`There is ${
          this.state.defaultSettings && this.state.defaultSettings.length
        } settings will be copied for ${
          this.state.selectedGrower.name
        }. Please confirm
            to proceed.`}
      />
    );

    const tabConfigs = [
      {
        tabId: "#modifiedSettings",
        tabTitle: "Modified Settings",
        sequence: 0,
        content: this.renderWizardFirstStep(),
      },
      {
        tabId: "#validate",
        tabTitle: "Validate",
        sequence: 1,
        onClick: this.handleWizardValidate,
        content: renderWizardSecondStep(),
      },
      {
        tabId: "#confirm",
        tabTitle: "Confirm",
        sequence: 2,
        content: renderWizardThirdStep,
      },
    ];

    if (this.state.defaultSettings && this.state.defaultSettings.length > 0) {
      if (this.state.errorDuplicate) {
        return this.disableFromStep(tabConfigs, 2);
      } else {
        return tabConfigs;
      }
    } else {
      return this.disableFromStep(tabConfigs, 0);
    }
  }

  setEditedIndex(index) {
    this.setState({
      editedIndex: index,
    });
  }

  appendRows(rows) {
    const defaultSettings = [...this.state.defaultSettings];
    const newRows = defaultSettings.concat(rows);
    this.setState({
      defaultSettings: newRows,
    });
  }

  editRow(row) {
    let defaultSettings = [...this.state.defaultSettings];
    defaultSettings[this.state.editedIndex] = row;
    this.setState({
      defaultSettings: defaultSettings,
    });
  }

  removeRow(removedIndex) {
    const defaultSettings = [...this.state.defaultSettings].filter(
      (_, index) => index !== removedIndex
    );
    this.setState({
      defaultSettings: defaultSettings,
    });
  }

  disableFromStep = (arr, step) => {
    return arr.map((item) => {
      if (item.sequence >= step && item.sequence !== 0) {
        return {
          ...item,
          disabled: true,
        };
      } else {
        return item;
      }
    });
  };

  handleWizardFinish = () => {
    const urlApi = `${this.props.baseApiUrl}growerbulkinsert/${this.state.parentId}/${this.state.selectedGrower._id}`;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        parentId: this.state.parentId,
        checked: true,
        processorId: this.props.loggedUser.processorId,
        data: this.state.defaultSettings,
        testType: this.props.testType,
        createdUserId: this.state.userId, //using for audit log
      }),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          window.showAlert("", resultObject.message, "");
          that.setState({
            isChanged: true,
            isLoading: 0,
          });
          //action is called from copy/reset all then update radio on/off
          that.setState({
            data: that.state.data.map((u) =>
              u._id === that.state.selectedGrower._id
                ? Object.assign({}, u, {
                    checked: true,
                  })
                : u
            ),
            dataAfterFilter: that.state.dataAfterFilter.map((u) =>
              u._id === that.state.selectedGrower._id
                ? Object.assign({}, u, {
                    checked: true,
                  })
                : u
            ),
            showWizard: false,
            show: true,
          });
        } else {
          that.setState({
            isLoading: 0,
          });
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        that.setState({
          isLoading: 0,
        });
        window.showAlert("Error", error, "error");
      });
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          className="apply-growers-popup"
          centered
        >
          <Form id="fromPopup">
            <Modal.Header>
              <Modal.Title>Growers - Copy/Reset Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "5px" }}>
              <div
                className="alert alert-success"
                role="alert"
                style={{ marginBottom: 0 }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="alert-heading">How to use?</h4>

                <ul>
                  <li>
                    Turn-on to copy default setings for grower. Action to be
                    ignored if custom setting exists.
                  </li>
                  <li>
                    Turn-off to delete custom settings. Default settings to be
                    used for a grower.
                  </li>
                  <li>
                    <strong>
                      Please do note any change will effect immediately.
                    </strong>
                  </li>
                </ul>
              </div>
              <Modal.Header style={{ padding: "10px 0", marginBottom: 10 }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 padding-right-apply-growers-filter-field">
                      <input
                        type="text"
                        name="name"
                        autoFocus={true}
                        className="form-control"
                        placeholder="Enter grower name to filter"
                        value={this.state.filterGrowersObject.name}
                        onChange={this.handleChangeFilter}
                        maxLength={500}
                      />
                    </div>
                    <div className="col-md-6 padding-left-apply-growers-filter-field">
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Enter address to filter"
                        value={this.state.filterGrowersObject.address}
                        onChange={this.handleChangeFilter}
                        maxLength={500}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="col-md-6 padding-right-apply-growers-filter-field">
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        placeholder="Enter city to filter"
                        value={this.state.filterGrowersObject.city}
                        onChange={this.handleChangeFilter}
                        maxLength={500}
                      />
                    </div>
                    <div className="col-md-6 padding-left-apply-growers-filter-field">
                      <input
                        type="text"
                        name="state"
                        className="form-control"
                        placeholder="Enter state to filter"
                        value={this.state.filterGrowersObject.state}
                        onChange={this.handleChangeFilter}
                        maxLength={500}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div
                      className="col-md-12"
                      style={{ padding: "5px 0px 5px 0px" }}
                    >
                      <input
                        type="text"
                        name="region"
                        className="form-control"
                        placeholder="Enter region to filter"
                        value={this.state.filterGrowersObject.region}
                        onChange={this.handleChangeFilter}
                        maxLength={500}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Header>
              <div
                style={{
                  overflowY: "auto",
                  position: "relative",
                  maxHeight: "500px",
                }}
              >
                {this.state.dataAfterFilter.map((v, index) => (
                  <div className="form-group" key={v._id}>
                    <div key={index} className="col-md-2">
                      <Switch
                        checked={this.state.dataAfterFilter[index].checked}
                        onChange={() =>
                          this.handleChange(
                            index,
                            this.state.dataAfterFilter[index]
                          )
                        }
                      />
                    </div>
                    <div className="col-md-6">{v.name}</div>
                    {this.props.showCheckDuplicate === true ? (
                      <div className="col-md-4">
                        <a
                          href="#"
                          onClick={() => this.handleCheckSameWithDefault(v._id)}
                        >
                          Check same with default
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-center">
                <Button
                  variant="warning"
                  onClick={() => this.handleSave(undefined, false)}
                >
                  <span className="fa fa-eraser fa-lg"></span> Reset All
                </Button>
                <Button variant="info" onClick={() => this.handleClose()}>
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </div>
            </Modal.Footer>
          </Form>

          <LoadingScreen
            loading={this.state.isLoading}
            bgColor="#00000060"
            spinnerColor="#0275d8"
            textColor="#0275d8"
            text="Processing..."
          />
        </Modal>
        {this.props.isWizard === true && (
          <WizardPopup
            show={this.state.showWizard}
            onHide={this.handleCloseWizard}
            selectedGrower={this.state.selectedGrower}
            wizardData={this.handleWizardPopup()}
            onFinish={this.handleWizardFinish}
            onValidate={this.handleWizardValidate}
          />
        )}
      </>
    );
  }
}

export default App;
