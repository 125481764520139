import React from "react";
import CommonUtilities from "../Utilities/Common";
import String from "../Utilities/String";

const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      currentYear: new Date().getFullYear(),
      data: {},
      loading: false,
      expand: true,
    };
  }

  async componentDidUpdate(previousProps) {
    if (previousProps.currentCropYearId !== this.props.currentCropYearId) {
      this.getData();
    }
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    if (
      !String.isEmpty(this.props.currentProcessorId) &&
      !String.isEmpty(this.props.currentCropYearId)
    ) {
      this.setState({ loading: true });
      let urlApi =
        GlobalConfig.REACT_APP_API_DASHBOARD_URL +
        "summaryprocessor/" +
        this.props.currentProcessorId +
        "/" +
        this.props.currentCropYearId;

      const that = this;
      fetch(urlApi)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          that.setState({ data: resultObject.data, loading: false });
        })
        .catch(function (error) {
          that.setState({ loading: false });
          console.log(error);
        });
    }
  };

  onChangeExpandToggle = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  render() {
    return (
      <section className="panel">
        <header className="panel-heading">
          <div className="panel-actions">
            <a
              href="javascript:void(0);"
              className={`fa fa-caret-${this.state.expand ? "up" : "down"}`}
              onClick={this.onChangeExpandToggle}
            ></a>
          </div>
          <h2 className="panel-title">Summary</h2>
          <p className="panel-subtitle"></p>
        </header>
        <div
          className="panel-body report-panel-body"
          style={{
            maxHeight: this.state.expand ? 500 : 0,
          }}
        >
          <hr className="dotted short"></hr>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="row">
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-tertiary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-tertiary">
                          <i className="fa fa-file-text-o"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Today's Tests</h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalTestByToday}
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-secondary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-secondary">
                          <i className="fa fa-file-text-o"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col titleWidgetContainer">
                        <div className="summary">
                          <h4 className="title titleWidget">
                            {this.props.currentCropYearName &&
                            this.props.currentCropYearName !== "" ? (
                              <span
                                title={`Total Tests (${this.props.currentCropYearName})`}
                              >
                                Total Tests
                              </span>
                            ) : (
                              "Total Tests (All Crop Year)"
                            )}
                          </h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {this.state.data.totalTest}
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-quartenary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-quartenary">
                          <i className="fa fa-shopping-cart"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col titleWidgetContainer">
                        <div className="summary">
                          <h4 className="title titleWidget">
                            {this.props.currentCropYearName &&
                            this.props.currentCropYearName !== "" ? (
                              <span
                                title={`Total Weight (${this.props.currentCropYearName})`}
                              >
                                Total Weight
                              </span>
                            ) : (
                              "Total Weight (All Crop Year)"
                            )}
                          </h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <strong className="amount">
                                {CommonUtilities.numberWithCommas(
                                  (isNaN(this.state.data.totalWeight)
                                    ? 0
                                    : this.state.data.totalWeight
                                  ).toFixed(1)
                                ) + " (LBS)"}
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-3">
                <section className="panel panel-featured-left panel-featured-primary">
                  <div className="panel-body">
                    <div className="widget-summary">
                      <div className="widget-summary-col widget-summary-col-icon">
                        <div className="summary-icon bg-primary">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                      <div className="widget-summary-col">
                        <div className="summary">
                          <h4 className="title">Growers</h4>
                          <div className="info">
                            {this.state.loading === true ? (
                              <span>Loading...</span>
                            ) : (
                              <>
                                <strong className="amount">
                                  {this.state.data.totalGrower}
                                </strong>
                                <a
                                  className="text-muted text-uppercase"
                                  href={process.env.REACT_APP_URL_GROWER_LIST}
                                >
                                  <span className="text-primary">( View )</span>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  getInitialState() {
    return {
      labels: [
        "Aug 2020",
        "Sep  2020",
        "Oct  2020",
        "Nov  2020",
        "Dec  2020",
        "Jan 2021",
        "Fev 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
      ],
      datasets: [
        {
          label: "",
          backgroundColor: "rgb(0, 153, 198)",
          data: [
            500, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 125.55,
          ],
        },
      ],
    };
  }
}
export default App;
