import axios from "axios";
import { queryData, queryDataExt } from "../BaseService/index";
const GlobalConfig = new window.globalConfig();

export const getGrowerIdsExistPaymentSetting = async (cropYearId) => {
  return await queryData(
    `${GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}growerIdsExistPaymentSetting/${cropYearId}`
  );
};

export const getPaymentSettingsWithConditions = async (
  whereClause,
  selectFields,
  sortFields
) => {
  return await queryDataExt(
    `${GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}getWithConditions`,
    whereClause,
    selectFields,
    sortFields
  );
};

export const countPaymentSettingDefault = async (cropYearId) => {
  try {
    let res = await axios({
      url: `${GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}countPaymentSettingDefault/${cropYearId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      if (res.data.result !== "OK") {
        console.log(res.message);
        return 0;
      }
      return res.data.data;
    }
    console.log(
      `There is error while query countPaymentSettingDefault with crop year id ${cropYearId}.`
    );
    return 0;
  } catch (e) {
    console.log(e.message);
  }
};
