import { combineReducers } from "redux";
import { helpReducer } from "./helpReducer";
import { testTypeReducer } from "./testTypeReducer";
import { loadingReducer } from "./loadingReducer";
import { USER_LOGOUT } from "../actions/types";

const createReducers = combineReducers({
  helpCategories: helpReducer,
  testTypes: testTypeReducer,
  loading: loadingReducer,
});

const initialState = {
  helpCategories: [],
  testTypes: [],
  loading: {
    helpCategoriesLoading: false,
    testTypesLoading: false,
  },
};

const reducers = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return initialState; //reset state once user logouts
  } else {
    return createReducers(state, action);
  }
};

export default reducers;
