import styled from "styled-components";

export const ScreenWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: ${(props) => props.bgColor || "#ffffff"};
  opacity: ${(props) => (props.loading ? 1 : 0)};
  visibility: ${(props) => (props.loading ? "visible" : "hidden")};
  transition: opacity 0.4s, visibility -0.3s linear 0.5s;
  z-index: ${(props) => (props.loading ? 999999999 : 0)};
`;

export const LoadingComponents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
`;

export const LoadableData = styled.div`
  display: ${(props) => (props.loading ? "none" : "block")};
`;

export const Text = styled.div`
  color: ${(props) => props.textColor || "#000000"};
  font-size: 1.6rem;
`;
