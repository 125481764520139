import React from "react";
import ListComponentPayment from "../Base/ListComponentPayment";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import SelectWalnutVarieties from "../TestType/SelectWalnutVarieties";
import NumericInput from "../FormControl/NumericInput";
import DataTable from "react-data-table-component";
import SubTable from "./SubTable";
import SelectGrowers from "../Grower/SelectGrowers";
import ListGrower from "../Grower/ListGrower";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import SaveButton from "../Controls/SaveButton";
import Template from "./Template";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state = {
      popupUrlApi: GlobalConfig.REACT_APP_API_CASH_CONTRACT_PRICE_URL,
      parentId: props.match.params.id,
      urlapi: GlobalConfig.REACT_APP_API_CASH_CONTRACT_PRICE_URL,
      urllist:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
        "/" +
        props.match.params.id,
      urledit:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
        "-edit/",
      urladd:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
        "-add/" +
        props.match.params.id,
      pluraltitle: "Cash Contract Prices",
      title: "Cash Contract Price",
      labeladd: "Cash Contract Price",
      pagination: false,
      excludeDefaultButtons: true,
      expandableRows: true,
      viewMode: "edit",
      isExpand: false,
      isUseHeading: false,
      cropYearName: "",
      noteContractCashPrice: GlobalConfig.REACT_APP_MSG_CONTRACT_CASH_PRICE,
      progressPending: false,

      //Using for popup add/edit
      data: {
        _id: "",
        parentId: props.match.params.id,
        growerId: "",
        varietyId: "",
        amount: 0,
        valueCashPrice: 0,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      previousGrowerId: "",
      isChanged: false,
      errorMessage: "",
      color: "red",
      isEditMode: true,
      //This state is using for remove row later
      removedGrowerId: "",
      invalidVarietyNames: [],
      showError: false,
      nameVarieties: "",
      showRefresh2: true,
      testType: {
        isDynamic: false,
        _id: "",
      },
      saveLoading: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  getItems = async () => {
    const that = this;
    that.setState({
      progressPending: true,
    });
    fetch(this.state.urlapi + "parent/" + this.state.parentId)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.cropYear) {
          const cropYearName =
            resultObject.cropYear.name +
            " (" +
            resultObject.cropYear.startDate +
            "-" +
            resultObject.cropYear.endDate +
            ")";

          const handleTruncate = that.truncateTitle(
            cropYearName,
            that.setTitleLength()
          );

          that.setState({
            pluraltitle: "Cash Contract Prices - " + handleTruncate,
            cropYearName: cropYearName,
          });
        }

        that.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            growers: [],
            progressPending: false,
          },
          function callBack() {
            that.refreshTable();
          }
        );
      })
      .catch(function (error) {
        console.log(error);
        that.setState({ progressPending: false });
      });
  };

  setColumnsInvalidPopup = () => {
    return [
      {
        name: "Variety",
        selector: "name",
        sortable: true,
        left: true,
      },
      {
        name: "Reason",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          return "Missing cash contract price";
        },
      },
    ];
  };

  defaultTable = () => {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs tabs-primary">
          <li className="active">
            <a href="#Default" data-toggle="tab">
              Cash Contract Prices
            </a>
          </li>
          <li>
            <a
              href="#Template"
              data-toggle="tab"
              onClick={() => {
                if (this.state.isLoadedTabTemplate !== true) {
                  this.template.getItems();
                  this.template.getGrowers();
                  this.setState({
                    isLoadedTabTemplate: true,
                  });
                }
              }}
            >
              Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="Default"
            className="tab-pane active"
            style={{ margin: "-10px" }}
          >
            <DataTable
              id="emp"
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={this.state.pagination}
              paginationPerPage={10}
              expandableRows={this.state.expandableRows}
              expandableRowsComponent={
                <SubTable
                  handleDelete={this.handleDelete.bind(this)}
                  handleShow={this.handleShow}
                  expandableRowExpanded={(row) => {
                    return this.state.isExpand;
                  }}
                  loggedUser={loggedUser}
                />
              }
              conditionalRowStyles={this.conditionalRowStyles()}
              noTableHead={true}
              expandableRowExpanded={(row) => {
                return this.state.isExpand;
              }}
              progressPending={this.state.progressPending}
              progressComponent={this.defaultProgressComponent()}
            />
          </div>
          <div id="Template" className="tab-pane" style={{ margin: "-10px" }}>
            <Template
              onRef={(ref) => (this.template = ref)}
              cropYearId={this.props.match.params.id}
              loggedUser={loggedUser}
              processorId={loggedUser.processorId}
              testTypeId={this.state.testType._id}
              testType={this.state.testType}
              refreshMainTab={this.getItems}
            />
          </div>
        </div>
      </div>
    );
  };

  extendButtons = () => {
    return (
      <>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>

        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <a
              href="#"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  growerId: "",
                  varietyId: "",
                  amount: 0,
                  valueCashPrice: 0,
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              className="btn btn-primary mb-sm"
            >
              <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
            </a>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
            <button
              type="button"
              onClick={() => this.handleValidate()}
              title="Check cash contract prices."
              className="btn btn-warning mb-sm"
            >
              <i className="fa fa-bolt fa-lg"></i> Validate
            </button>
          </>
        )}
      </>
    );
  };

  extendRender = () => {
    return (
      <>
        <Modal
          show={this.state.showError}
          onHide={this.handleCloseError}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>Invalid Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ margin: "-15px", marginBottom: "-30px" }}>
            <div className="alert alert-success" role="alert">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="alert-heading">Why invalid?</h4>
              <p>
                <ul>
                  <li>
                    Cash contract price must be set for varieties those using in
                    tests.
                  </li>
                  <li>
                    {this.state.nameVarieties &&
                    this.state.nameVarieties.indexOf(", ") !== -1
                      ? "Varieties are "
                      : "Variety is "}
                    using for tests includes{" "}
                    <strong>{this.state.nameVarieties}</strong>.
                  </li>
                </ul>
              </p>
            </div>
            <div
              style={{
                overflowY: "auto",
                position: "relative",
                maxHeight: "500px",
              }}
            >
              <DataTable
                highlightOnHover
                striped
                noHeader={true}
                columns={this.setColumnsInvalidPopup()}
                data={this.state.invalidVarietyNames}
                className="table table-bordered table-striped mb-none"
                pagination={false}
              ></DataTable>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button variant="warning" onClick={this.handleCloseError}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={true}
        />
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {this.state.data._id == ""
                  ? "Add Cash Contract Price"
                  : "Edit Cash Contract Price"}{" "}
                {this.state.cropYearName !== ""
                  ? " - " + this.state.cropYearName
                  : ""}
              </Modal.Title>

              {GlobalConfig.REACT_APP_MSG_CASH_CONTRACT_PRICE == null ||
              GlobalConfig.REACT_APP_MSG_CASH_CONTRACT_PRICE === "" ||
              GlobalConfig.REACT_APP_MSG_CASH_CONTRACT_PRICE === undefined ? (
                ""
              ) : (
                <>
                  <div
                    style={{
                      textAlign: `left`,
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                    className="col-md-12"
                    dangerouslySetInnerHTML={{
                      __html: GlobalConfig.REACT_APP_MSG_CASH_CONTRACT_PRICE,
                    }}
                  ></div>
                </>
              )}
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Default/Grower <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <SelectGrowers
                        required={false}
                        onRef={(ref) => (this.growerId = ref)}
                        name="growerId"
                        value={this.state.data.growerId}
                        selectedIds={this.state.data.growerId}
                        handleChangeSelectGrower={this.handleChangeSelectGrower}
                        testTypeId={this.state.testType._id}
                        cropYearId={this.state.parentId}
                        checkExistPaymentSetting={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Variety <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <SelectWalnutVarieties
                        required={true}
                        onRef={(ref) => (this.varietyId = ref)}
                        name="varietyId"
                        controlId="varietyId"
                        selectedIds={this.state.data.varietyId}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        urlApi={
                          GlobalConfig.REACT_APP_API_VARIETY_URL +
                          "byParentId/" +
                          this.state.testType._id
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <NumericInput
                      precision={2}
                      min={0}
                      max={999999999}
                      step={0.01}
                      label="Amount"
                      name="amount"
                      value={this.state.data.amount}
                      handleChange={this.handleChangeObjectData}
                      unit="lbs"
                      labelClass="3"
                      inputClass="5"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>
                  <div className="form-group">
                    <NumericInput
                      precision={2}
                      min={0}
                      max={999999999}
                      step={0.01}
                      label="Cash Price"
                      name="valueCashPrice"
                      value={this.state.data.valueCashPrice}
                      handleChange={this.handleChangeObjectData}
                      unit="$"
                      labelClass="3"
                      inputClass="5"
                      required={true}
                      labelrequired="(*)"
                    />
                  </div>
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected crop year no longer exists. Please refresh and try
                    again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ textAlign: `center` }}>
                {this.state.data ? (
                  <SaveButton loading={this.state.saveLoading} />
                ) : (
                  ""
                )}
                <Button variant="warning" onClick={this.handleClose}>
                  <span className="fa fa-times fa-lg"></span> Close
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }

    this.setState({ data: convertedJSON }, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isEditMode: isEditMode,
        },
        function callBack() {
          //Set selected dropdown
          this.varietyId.setSelected(data.varietyId);
        }
      );
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
      saveLoading: true,
    });

    const varietyId = this.state.data.varietyId;

    let errorMessage = "";

    if (
      varietyId == null ||
      varietyId === undefined ||
      varietyId.trim() === ""
    ) {
      errorMessage = "Select variety to continue.";
    } else {
      if (this.state.data.amount <= 0) {
        errorMessage = "Amount must be greater than zero.";
      } else if (this.state.data.valueCashPrice <= 0) {
        errorMessage = "Cash Price must be greater than zero.";
      }
    }

    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
        saveLoading: false,
      });
    } else {
      let previousGrowerId = this.state.data.growerId;
      //Using for edit
      let urlApi =
        GlobalConfig.REACT_APP_API_CASH_CONTRACT_PRICE_URL +
        this.state.data._id;
      let method = "PATCH";
      let isEditMode = true;
      if (
        this.state.data._id == null ||
        this.state.data._id === undefined ||
        this.state.data._id === ""
      ) {
        //Using for create new
        method = "POST";
        urlApi = GlobalConfig.REACT_APP_API_CASH_CONTRACT_PRICE_URL;
        isEditMode = false;
      }

      const requestOptions = {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.data),
      };
      const that = this;
      fetch(urlApi, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If created successful
          if (resultObject.result === "OK") {
            window.showAlert("", "Save successful!", "");
            that.setState(
              {
                isChanged: true,
                previousGrowerId: previousGrowerId,
                saveLoading: false,
              },
              function callBack() {
                that.updateStateAfterClosePopup(resultObject.data);
                if (!isEditMode) {
                  that.handResetForm();
                }
              }
            );
          } else {
            window.showAlert("Error", resultObject.message, "error");
            that.setState({ saveLoading: false });
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
          that.setState({ saveLoading: false });
        });
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          varietyId: "",
          amount: 0,
          valueCashPrice: 0,
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        //Set selected dropdown
        this.varietyId.setSelected("");
        this.growerId.setSelected(this.state.previousGrowerId);
      }
    );
  };

  handleCloseError = () => {
    this.setState({ showError: false });
  };

  handleValidate = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          parentId: this.state.parentId,
        }),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_CASH_CONTRACT_PRICE_URL + "validate/",
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            that.setState({ nameVarieties: resultObject.nameVarieties });
            if (
              resultObject.invalidVarietyNames &&
              resultObject.invalidVarietyNames !== ""
            ) {
              that.setState({
                showError: true,
                invalidVarietyNames: resultObject.invalidVarietyNames,
              });
            } else {
              window.showAlert("", "Cash contract price is valid.", "");
            }
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };
}
export default App;
