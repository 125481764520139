import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { Button, Form, Modal } from "react-bootstrap";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
} from "../Utilities/Constant";
import String from "../Utilities/String";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    this.state.parentId = props.match.params.id;
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${this.state.parentId}/all`;
    this.state.urllist =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST +
      "/classification-items/" +
      props.match.params.id;
    this.state.urledit =
      process.env.REACT_APP_URL_CLASSIFICATION_LIST +
      "/classification-item-edit/";
    this.state.urlapidelete =
      GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL;
    this.state.urlApiProcessor = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}`;

    this.state.urlApiProcessorFilter =
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + "filterDataProcessor";

    this.state.urlApiClassification =
      GlobalConfig.REACT_APP_API_CLASSIFICATION_URL;

    this.state.title = "Classification Item";

    this.state.pageName = "Classification Item";
    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.state.filterName = "";
    this.state.filterProcessorId = "";
    this.state.classification = null;
    this.state.data = {
      _id: "",
      parentId: props.match.params.id,
      // processorID pass to backend for create log audit
      processorId: loggedUser.processorId,
      name: "",
      createdUserId: loggedUser._id,
    };
    this.state.childOverrideComponentDidMount = true;
    this.state.addClassItemLoading = false;

    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.backClassificationButton = this.backClassificationButton.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleApplyProcessor = this.handleApplyProcessor.bind(this);
    this.getClassification = this.getClassification.bind(this);
  }

  async componentDidMountChild() {
    if (this.state.isServerPaging === true) {
      this.getItemsServerPaging(this.state.currentPage);
    } else {
      this.getItems();
    }
    this.getClassification();
    this.loadColumnSettings();
  }

  async getClassification() {
    try {
      const that = this;
      fetch(
        `${GlobalConfig.REACT_APP_API_CLASSIFICATION_URL}${this.state.parentId}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.setState({ classification: resultObject.data });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  }

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  backClassificationButton = () => {
    return (
      <a
        href={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
        title="Back Classifications"
        className="btn btn-primary"
      >
        <i className="fa fa-backward fa-lg"></i> Back Classifications
      </a>
    );
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  filterItems = async () => {
    const { currentPage, perPage } = this.state;
    let parentId = this.state.parentId;

    let filterProcessorId = !!this.state.filterProcessorId
      ? this.state.filterProcessorId
      : "all";

    const urlapi = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${parentId}/${filterProcessorId}`;

    this.setState({ urlapi, currentPage: 1 }, () =>
      this.getItemsServerPaging(currentPage, perPage)
    );
  };

  handleReset = () => {
    // this.processorControl maybe null, so use ? (optional chaining) is a safe way to access nested object properties,
    // even if an intermediate property doesn’t exist.
    this.processorControl?.reset();
    this.setState(
      {
        filterName: "",
        filterProcessorId: "",
      },
      () => this.filterItems()
    );
  };

  getItemsServerPaging = async (page, size = this.state.perPage) => {
    const filterName = String.isEmpty(this.state.filterName)
      ? ""
      : encodeURIComponent(this.state.filterName.trim());
    let searchUrl =
      this.state.urlapi + `/${page}/${size}` + `?name=${filterName}`;
    //If loged in user is staff or processor
    if (
      loggedUser.role === USER_ROLE_STAFF ||
      loggedUser.role === USER_ROLE_PROCESSOR
    ) {
      searchUrl += `&byProcessorId=${loggedUser.processorId}`;
    }
    const that = this;
    that.setState({
      progressPending: true,
    });
    fetch(searchUrl)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            progressPending: false,
          },
          () => {
            that.doSomethingAfterGetItems(resultObject);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
        that.setState({
          progressPending: false,
        });
      });
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    window.showAlert("Success", "Removed successful!", "");
    //If updated successful
    this.setState(
      {
        currentPage: 1,
      },
      function () {
        this.doSomethingAfterDeletedItem();
        this.getItemsServerPaging(this.state.currentPage, this.state.perPage);
      }
    );
  };

  handleResetForm = () => {
    this.setState({
      data: {
        id: "",
        parentId: this.state.parentId,
        // processorID pass to backend for create log audit
        processorId: loggedUser.processorId ? loggedUser.processorId : "",
        name: "",
        createdUserId: loggedUser._id,
      },
    });
  };

  handleChange(event, handleValidate) {
    if (handleValidate) handleValidate.handleChange(event);
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleShow = (data) => {
    if (data) {
      let convertedJSON = JSON.parse(JSON.stringify(data));
      if (data._id === "") {
        convertedJSON.createdUserId = loggedUser._id;
      } else {
        convertedJSON.modifiedUserId = loggedUser._id;
      }

      this.setState({ data: convertedJSON }, function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
          },
          function callBack() {
            //Set selected dropdown
            if (loggedUser.role === "Administrators") {
              this.processorId.setSelected(data.processorId);
            }
          }
        );
      });
    } else {
      this.setState({}, function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
          },
          function callBack() {
            //Set selected dropdown
          }
        );
      });

      this.setState({
        data: Object.assign({}, this.state.data, {
          _id: "",
          processorId: loggedUser.processorId,
          name: "",
          createdUserId: loggedUser._id,
        }),
      });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleApplyProcessor = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  defaultButtons = () => {
    if (this.hasPermission()) {
      return (
        <div className="panel-btn-group-item-position">
          <a onClick={() => this.handleShow()} className="btn btn-primary">
            <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
          </a>
          <span>{this.backClassificationButton()}</span>
        </div>
      );
    }
  };

  checkSomethingBeforeSave = () => {
    return true;
  };

  handleSave = async (e) => {
    e.preventDefault();

    if (this.checkSomethingBeforeSave() === false) {
      return;
    }
    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }

    let urlApi =
      GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL + this.state.data._id;
    let method = "PATCH";
    if (
      this.state.data._id === null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL;
    }

    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          createdUserId: loggedUser._id,
        }),
        addClassItemLoading: true,
      },
      function callBack() {
        try {
          const requestOptions = {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          const that = this;
          fetch(urlApi, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If created successful
              if (resultObject && resultObject.result === "OK") {
                //reset form
                if (method === "POST") {
                  that.handleResetForm();
                }

                if (that.state.isServerPaging === true) {
                  that.getItemsServerPaging(that.state.currentPage);
                } else {
                  that.getItems();
                }

                that.doSomethingAfterSaved(resultObject.data);

                if (
                  that.state.isShowSaveSuccessfulMessage == true ||
                  that.state.isShowSaveSuccessfulMessage == undefined ||
                  that.state.isShowSaveSuccessfulMessage == null
                ) {
                  window.showAlert("", "Save successful!", "");
                }
              }
              //If failed, show error
              else {
                that.doSomethingAfterErrorSaved(resultObject);
              }
              that.setState({ addClassItemLoading: false });
            })
            .catch(function (error) {
              window.showAlert("Error", error, "Error");
              that.setState({ addClassItemLoading: false });
            });
        } catch (err) {
          window.showAlert("Error", err.message, "Error");
          this.setState({ addClassItemLoading: false });
        }
      }
    );
  };

  hasPermission = () => {
    return (
      loggedUser.role === "Administrators" ||
      !!this.state?.classification?.editable
    );
  };

  permissionOnRow = (row) => {
    return (
      loggedUser.role === USER_ROLE_ADMINISTRATOR ||
      ((loggedUser.role === USER_ROLE_STAFF ||
        loggedUser.role === USER_ROLE_PROCESSOR) &&
        row.processorId === loggedUser.processorId &&
        !!this.state?.classification?.editable)
    );
  };

  extendRender = () => {
    return (
      <Modal
        // dialogAs={DraggableModalDialog}
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form onSubmit={this.handleSave} id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.data._id === "" || this.state.data._id === undefined
                ? "Add " + this.state.pageName
                : "Edit " +
                this.state.pageName +
                (this.state.data.name !== undefined
                  ? " - " + this.state.data.name
                  : "")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="col-sm-3 control-label">
                Name <span className="required">*</span>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder=""
                  required
                  value={this.state.data.name}
                  onChange={this.handleChangeObjectData}
                  maxLength={100}
                />
              </div>
            </div>

            {loggedUser.role === "Administrators" ? (
              <div className="form-group">
                <label className="col-sm-3 control-label">
                  Apply Processor
                </label>
                <div className="col-sm-9">
                  <FilterSelectControl
                    handleChangeSelectControl={this.handleApplyProcessor}
                    onRef={(ref) => (this.processorId = ref)}
                    label="All Processors"
                    controlId="processorId"
                    value={this.state.data.processorId}
                    urlapilist={this.state.urlApiProcessorFilter}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer-test-field">
            {this.state.data ? (
              <SaveButton loading={this.state.addClassItemLoading} />
            ) : (
              ""
            )}
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterName"
                placeholder="Filter Name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterName}
              />
            </div>

            {loggedUser.role === "Administrators" && (
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorControl = ref)}
                  placeholder="Filter Processor"
                  label="Select All"
                  controlId="filterProcessorId"
                  urlapilist={this.state.urlApiProcessor}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) =>
          this.permissionOnRow(row) ? (
            <a
              onClick={() => this.handleShow(row)}
              title={row.name}
              style={{ cursor: "pointer" }}
            >
              {row.name}
            </a>
          ) : (
            <span title={row.name}>{row.name}</span>
          ),
      },
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
        omit: loggedUser.role !== "Administrators",
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        cell: (row) =>
          this.permissionOnRow(row) ? (
            <>
              <a
                href="# "
                onClick={() => this.handleShow(row)}
                className="on-default edit-row"
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                title="Click here to remove"
                href="# "
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
              &nbsp;&nbsp;
              <a
                target="_blank"
                title="Revision history"
                href={
                  process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                  "/object/" +
                  row._id
                }
                className="on-default remove-row"
              >
                <i className="fa fa-history fa-lg"></i>
              </a>
            </>
          ) : (
            ""
          ),
      },
    ];
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a
          href={process.env.REACT_APP_URL_CLASSIFICATION_LIST}
          title="Back to list"
        >
          Classifications
        </a>
      </li>
    );
  };
}

export default App;
