import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const overrideLoadingStyle = {
  width: 20,
  height: 20,
  borderRadius: "100%",
  borderWidth: 3,
  borderStyle: "solid",
  borderColor: "#FFFFFF #FFFFFF transparent",
  borderImage: "initial",
  display: "inline-block",
  animation:
    "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
};

export default class SaveButton extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <Button
        variant={this.props.variant ? this.props.variant : "success"}
        disabled={
          this.props.disabled
            ? this.props.disabled
            : this.props.loading
            ? this.props.loading
            : false
        }
        type={this.props.type ? this.props.type : "submit"}
        onClick={this.props.onClick ? this.props.onClick : () => {}}
        form={this.props.form ? this.props.form : null}
        className={this.props.propClassName ? this.props.propClassName : ""}
      >
        {this.props.loading === undefined || this.props.loading === null ? (
          <div className="flex-box-align-items-center">
            {this.props.icon ? (
              this.props.icon
            ) : (
              <i className="fa fa-save fa-lg" style={{ marginRight: 2.5 }}></i>
            )}
            <div>{this.props.text ? this.props.text : "Save"}</div>
          </div>
        ) : (
          <div className="flex-box-align-items-center">
            <ClipLoader
              cssOverride={overrideLoadingStyle}
              loading={this.props.loading}
            />
            {this.props.loading === false ? (
              this.props.icon ? (
                this.props.icon
              ) : (
                <i
                  className="fa fa-save fa-lg"
                  style={{ marginRight: 2.5 }}
                ></i>
              )
            ) : (
              ""
            )}
            <div
              style={{
                marginLeft: this.props.textMarginLeft
                  ? this.props.textMarginLeft
                  : 2,
              }}
            >
              {this.props.text ? this.props.text : "Save"}
            </div>
          </div>
        )}
      </Button>
    );
  }
}
