import { getLoggedUser } from "../../context/auth";
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

export const handleExportPdf = async (e, callBack, objThis) => {
  try {
    loggedUser = getLoggedUser();

    const dataRequest = {
      cropYearId: e.currentTarget.dataset.cropyearid,
      growerId: e.currentTarget.dataset.growerid,
      paymentId: e.currentTarget.dataset.paymentid,
      varietyId: e.currentTarget.dataset.varietyid,
      createdUserId: loggedUser._id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    };

    fetch(
      GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL + "exportpdf",
      requestOptions
    ).then(function (response) {
      return response.json();
    })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          window.closeAllAlerts();
          callBack(resultObject, resultObject.data, objThis);
        }
        //If failed, show error
        else {
          window.showAlert("Error", resultObject.message, "error");
        }
      }).catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  } catch (err) {
    window.showAlert("Error", err.message, "error");
  }
};