// Using for add new grower Test
import React from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import { getLoggedUser } from "../../context/auth";
import SaveButton from "../Controls/SaveButton";
let loggedUser = {};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      labelClass:
        "col-md-" +
        (props.labelClass === null || props.labelClass === undefined
          ? 4
          : props.labelClass) +
        " control-label",
      inputClass:
        "col-md-" +
        (props.inputClass === null || props.inputClass === undefined
          ? 8
          : props.inputClass),
      controlId: props.controlId,
      urlapi: props.urlapi,
      urlapilist: props.urlapilist,
      processorId: props.processorId,
      value: props.value,
      name: "",
      data: [],
      errorMessage: "",
      isHideAddButton:
        props.isHideAddButton == null || props.isHideAddButton == undefined
          ? false
          : props.isHideAddButton,
      textField:
        props.textField == null || props.textField == undefined
          ? "name"
          : props.textField,

      errorName: "",
      errorcontactName: "",
      errorcontactEmail: "",
      dataUpdate: {
        _id: "",
        name: "",
        contactName: "",
        contactEmail: "",
        address: "",
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
        processorId: props.processorId,
        isSendInvite: false,
        status: "Active",
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        testTypeId: props.testTypeId ? props.testTypeId : "",
      },
      loading: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      if (
        this.props.urlapilist === undefined ||
        this.props.urlapilist == null ||
        this.props.urlapilist === ""
      ) {
        return;
      }
      const that = this;
      fetch(this.props.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            if (resultObject.data) {
              resultObject.data.map((item) => {
                parsedItems.push({
                  _id: item._id,
                  name: item[that.state.textField],
                  checked:
                    that.state.value == null ||
                      that.state.value === undefined ||
                      that.state.value.indexOf(item._id) === -1
                      ? false
                      : true,
                });
              });
            }
            that.setState({
              data: parsedItems,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    if (
      this.state.data == null ||
      this.state.data == undefined ||
      this.state.data.length <= 0
    ) {
      this.getItems().then(() => {
        this.setState({ value: selectedIds }, () => {
          if (this.state.data) {
            let updatedData = this.state.data;
            updatedData.forEach((item) => {
              if (
                selectedIds == null ||
                selectedIds == undefined ||
                selectedIds.indexOf(item._id) == -1
              ) {
                item.checked = false;
              } else {
                item.checked = true;
              }
            });
            this.setState({ data: updatedData });
          }
        });
      });
    } else {
      this.setState({ value: selectedIds }, () => {
        if (this.state.data) {
          let updatedData = this.state.data;
          updatedData.forEach((item) => {
            if (
              selectedIds == null ||
              selectedIds == undefined ||
              selectedIds.indexOf(item._id) == -1
            ) {
              item.checked = false;
            } else {
              item.checked = true;
            }
          });
          this.setState({ data: updatedData });
        }
      });
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    event.preventDefault();
    let updatedData = this.state.data;
    let isExist = false;
    let isValid = true;
    let name = this.state.dataUpdate.name;

    if (name == undefined || name == null || name.trim() == "") {
      this.setState({ errorName: "Required field." });
      isValid = false;
    } else {
      this.setState({ errorName: "" });
    }
    if (this.state.data.isSendInvite) {
      let contactName = this.state.dataUpdate.contactName;
      let contactEmail = this.state.dataUpdate.contactEmail;
      if (
        contactName == undefined ||
        contactName == null ||
        contactName.trim() == ""
      ) {
        this.setState({ errorContactName: "Required field." });
        isValid = false;
      } else {
        this.setState({ errorContactName: "" });
      }
      if (
        contactEmail == undefined ||
        contactEmail == null ||
        contactEmail.trim() == ""
      ) {
        this.setState({ errorContactEmail: "Required field." });
        isValid = false;
      } else {
        this.setState({ errorContactEmail: "" });
      }
    }
    if (isValid == false) {
      return;
    }
    if (updatedData && name != null && name != undefined && name.trim() != "") {
      updatedData.filter(function (memItem) {
        if (memItem.name == name) {
          isExist = true;
          return memItem;
        }
      });
    }
    if (isExist) {
      // this.refs.name.focus();
      this.setState({
        errorMessage: "Grower name already used. Please try with other.",
      });
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.dataUpdate),
    };
    const that = this;
    that.setState({ loading: true }, () => {
      fetch(this.state.urlapi, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If created successful
          if (resultObject && resultObject.result === "OK") {
            if (that.state.data) {
              let updatedData = that.state.data;
              updatedData.forEach((item) => {
                item.checked = false;
              });
              updatedData = [
                {
                  _id: resultObject.data._id,
                  name: resultObject.data.name,
                  testTypeId: resultObject.data.testTypeId,
                  checked: true,
                },
              ].concat(updatedData);
              that.setState({
                data: updatedData,
                value: resultObject.data._id,
              });
            } else {
              const updatedData = [
                {
                  _id: resultObject.data._id,
                  name: resultObject.data.name,
                  testTypeId: resultObject.data.testTypeId,
                  checked: true,
                },
              ];
              that.setState({
                data: updatedData,
                value: resultObject.data._id,
              });
            }
            that.setState({ show: false });
            //fire event and call method in parent to update state of parent
            that.props.handleChangeSelectControl(
              that.state.controlId,
              that.state.value
            );
          } else {
            that.setState({
              errorMessage: resultObject.message,
            });
          }
          that.setState({ loading: false });
        })
        .catch(function (error) {
          that.setState({
            errorMessage: error,
            loading: false,
          });
        });
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
      value: "",
      errorMessage: "",
      errorName: "",
      errorContactName: "",
      errorContactEmail: "",
      dataUpdate: Object.assign({}, this.state.dataUpdate, {
        name: "",
        contactName: "",
        contactEmail: "",
        address: "",
      }),
    });
  };

  handleChangeSelect(event) {
    var id = event.target.name;
    const value = event.target.value;
    this.setState({ value: value });
    this.props.handleChangeSelectControl(this.state.controlId, value);
  }

  handleChange(event, handleValidate) {
    if (handleValidate) handleValidate.handleChange(event);
    var id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      dataUpdate: Object.assign({}, this.state.dataUpdate, {
        [id]: value,
      }),
    });
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  render() {
    const myModelStyle = {
      background: "none",
    };
    return (
      <div className="form-group">
        <label className={this.state.labelClass} htmlFor={this.props.name}>
          {this.props.label}
          {this.props.required ? (
            <>
              {" "}
              <span className="required">(*)</span>
            </>
          ) : (
            ""
          )}
        </label>
        <div className={this.state.inputClass}>
          {this.props.readOnly ? (
            <select
              disabled={this.props.readOnly}
              name={this.state.controlId}
              className="form-control"
              required={
                this.props.required && this.props.displayRequired !== true
              }
              onChange={this.handleChangeSelect}
              value={this.state.value}
            >
              <option value="" hidden>
                Choose one
              </option>
              {this.state.data
                ? this.state.data.map((item, key) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })
                : ""}
            </select>
          ) : this.state.isHideAddButton ? (
            <select
              disabled={this.props.readOnly}
              name={this.state.controlId}
              className="form-control"
              required={
                this.props.required && this.props.displayRequired !== true
              }
              onChange={this.handleChangeSelect}
              value={this.state.value}
            >
              <option value="" hidden>
                Choose one
              </option>
              {this.state.data
                ? this.state.data.map((item, key) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })
                : ""}
            </select>
          ) : (
            <div className="input-group">
              <select
                disabled={this.props.readOnly}
                name={this.state.controlId}
                className="form-control"
                required={
                  this.props.required && this.props.displayRequired !== true
                }
                onChange={this.handleChangeSelect}
                value={this.state.value}
              >
                <option value="" hidden>
                  Choose one
                </option>
                {this.state.data
                  ? this.state.data.map((item, key) => {
                    return (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    );
                  })
                  : ""}
              </select>
              {this.state.urlapilist == undefined ||
                this.state.urlapilist == null ||
                this.state.urlapilist == "" ? (
                ""
              ) : (
                <div className="input-group-btn">
                  <Button variant="primary" onClick={this.handleShow}>
                    Add
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form>
            <Modal.Header>
              <Modal.Title>{this.props.popupTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Name <span className="required">(*)</span>
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    autoFocus={true}
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.data.name}
                    name="name"
                    placeholder="Enter grower name"
                    maxLength={100}
                    required={true}
                  />
                </Col>
              </Form.Group>
              {this.state.errorName == null ||
                this.state.errorName == "" ||
                this.state.errorName == undefined ? (
                ""
              ) : (
                <div className="row">
                  <div
                    className="col-sm-9 col-sm-offset-3"
                    style={{ marginTop: "-10px" }}
                  >
                    <label style={{ color: "red" }}>
                      {this.state.errorName}
                    </label>
                  </div>
                </div>
              )}
              {!this.state.data.isSendInvite ? (
                ""
              ) : (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Contact Name <span className="required">(*)</span>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.data.contactName}
                        name="contactName"
                        placeholder="e.g: John Doe"
                        maxLength={100}
                        required={this.state.data.isSendInvite}
                      />
                    </Col>
                  </Form.Group>
                  {this.state.errorContactName == null ||
                    this.state.errorContactName == "" ||
                    this.state.errorContactName == undefined ? (
                    ""
                  ) : (
                    <div className="row">
                      <div
                        className="col-sm-9 col-sm-offset-3"
                        style={{ marginTop: "-10px" }}
                      >
                        <label style={{ color: "red" }}>
                          {this.state.errorContactName}
                        </label>
                      </div>
                    </div>
                  )}
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Contact Email <span className="required">(*)</span>
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.data.contactEmail}
                        name="contactEmail"
                        placeholder="e.g: email@email.com"
                        maxLength={100}
                        required={this.state.data.isSendInvite}
                      />
                    </Col>
                  </Form.Group>
                  {this.state.errorContactEmail == null ||
                    this.state.errorContactEmail == "" ||
                    this.state.errorContactEmail == undefined ? (
                    ""
                  ) : (
                    <div className="row">
                      <div
                        className="col-sm-9 col-sm-offset-3"
                        style={{ marginTop: "-10px" }}
                      >
                        <label style={{ color: "red" }}>
                          {this.state.errorContactEmail}
                        </label>
                      </div>
                    </div>
                  )}
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Address
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.data.address}
                        name="address"
                        placeholder="Enter address of grower"
                        maxLength={200}
                        required={false}
                      />
                    </Col>
                  </Form.Group>
                </>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3"></Form.Label>
                {this.state.errorMessage == null ||
                  this.state.errorMessage == "" ||
                  this.state.errorMessage == undefined ? (
                  ""
                ) : (
                  <Form.Label column sm="10" style={{ color: "red" }}>
                    {this.state.errorMessage}
                  </Form.Label>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <SaveButton
                type={"button"}
                loading={this.state.loading}
                onClick={this.handleSave}
              />
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default App;
