import React from "react";
import ReactSelect from "../Base/ReactSelect";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      controlId: props.controlId,
      value:
        props.value === null || props.value === undefined ? `` : props.value,
      data: [],
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const that = this;
      fetch(this.props.urlApi)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            resultObject.data.map((item) => {
              parsedItems.push({
                _id: item._id,
                value: item._id,
                name: item.name,
                label: item.name,
                isSelected:
                  that.state.value == null ||
                  that.state.value === undefined ||
                  that.state.value.indexOf(item._id) === -1
                    ? false
                    : true,
                checked:
                  that.state.value == null ||
                  that.state.value === undefined ||
                  that.state.value.indexOf(item._id) === -1
                    ? false
                    : true,
              });
            });
            that.setState({
              data: parsedItems,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    this.setState({ value: selectedIds });
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds == null ||
          selectedIds === undefined ||
          selectedIds.indexOf(item._id) === -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleChangeSelect(event) {
    const value = event.target.value;
    let selectedName = ``;
    let selectedItem = undefined;
    if (this.state.data && this.state.data.length > 0) {
      selectedItem = this.state.data.find((x) => x.value === value);
      if (selectedItem) {
        selectedName = selectedItem.name;
      }
    }
    this.setState({ value: value });
    this.props.handleChangeSelectControl(
      this.state.controlId,
      value,
      selectedName
    );
  }

  handleChangeSelectControl(controlId, value) {
    let selectedName = [];
    if (this.state.data && this.state.data.length > 0) {
      const selectedItems = this.state.data.filter(
        (x) => value.indexOf(x.value) !== -1
      );
      if (selectedItems) {
        selectedName = selectedItems.map((x) => x.name);
      }
    }
    this.props.handleChangeSelectControl(controlId, value, selectedName);
  }

  render() {
    return this.props.isMulti ? (
      <ReactSelect
        options={this.state.data}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        showSelectAll={this.props.showSelectAll}
        components={{
          Option,
        }}
        handleChangeSelectControl={this.handleChangeSelectControl}
        allowSelectAll={true}
        value={this.state.value}
        isMulti
        controlId={this.state.controlId}
      />
    ) : (
      <select
        multiple={this.props.multiple}
        disabled={this.props.readOnly}
        name={this.state.controlId}
        className="form-control"
        required={this.props.required}
        onChange={this.handleChangeSelect}
        value={this.state.value}
      >
        <option value="" hidden>
          Choose one
        </option>
        {this.state.data
          ? this.state.data.map((item, key) => {
              return (
                <option value={item._id} key={item._id}>
                  {item.name}
                </option>
              );
            })
          : ""}
      </select>
    );
  }
}
export default App;
