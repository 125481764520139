import React from "react";
import { USER_ROLE_GROWER } from "../Utilities/Constant";
import { Button, Modal } from "react-bootstrap";
const moment = require("moment");

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      data: {},
      totalCount: 0,
      items: [],
      readMessageIds: [],
    };
  }

  async componentDidMount() {
    this.getMessages();
  }

  async getMessages() {
    const result = await fetch(
      `${this.props.GlobalConfig.REACT_APP_API_MESSAGE_URL}bygrower/${this.props.loggedUser.referenceUserId}/New/1/10`
    );
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      this.setState({
        items: response.data,
        totalCount: response.totalCount,
      });
    }
  }

  renderMessages() {
    if (this.state.totalCount > 0) {
      return (
        <ul>
          {this.state.items.map((x) => {
            return (
              <li key={x._id}>
                <a
                  href="# "
                  className="clearfix"
                  onClick={() => this.onRowClicked(x)}
                  style={{ fontWeight: x.isUnread && "bold" }}
                  title="Click to view full message"
                >
                  {/* <div className="image">
                    <i className="fa bg-success"></i>
                  </div> */}
                  <span className="title">{x.processorName}</span>
                  <span className="message truncate">{x.message}</span>
                </a>
              </li>
            );
          })}
        </ul>
      );
    }
    return <span>There is no new message</span>;
  }

  onRowClicked = (row) => {
    this.setState({ show: true, data: row }, () => {
      const that = this;
      const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          modifiedUserId: this.props.loggedUser._id,
        }),
      };

      fetch(
        `${this.props.GlobalConfig.REACT_APP_API_MESSAGE_URL}markasread/${that.state.data._id}/${this.props.loggedUser.referenceUserId}`,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            //Update status messages
            that.setState(
              {
                readMessageIds: [...that.state.readMessageIds, row._id],
              },
              () => {
                that.setState({
                  items: [...that.state.items].map((message) => {
                    if (message._id === that.state.data._id) {
                      message.isUnread = false;
                    }
                    return message;
                  }),
                });
              }
            );
          } else {
            console.log(resultObject.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const currentDate = moment(new Date()).format("MM/DD/YYYY");
    const date = moment(this.state.data.createdDate)
      .format("MM/DD/YYYY hh:mm A")
      .replace(currentDate, "");
    return this.props.loggedUser.role === USER_ROLE_GROWER ? (
      <>
        <ul className="notifications">
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-envelope"></i>
              {this.state.totalCount > 0 ? (
                <span className="badge">{this.state.totalCount}</span>
              ) : (
                ""
              )}
            </a>

            <div className="dropdown-menu notification-menu">
              <div className="notification-title">
                {/* {this.state.totalCount > 0 ? (
                  <span className="pull-right label label-default">
                    {this.state.totalCount}
                  </span>
                ) : (
                  ""
                )} */}
                Messages
              </div>

              <div className="content">
                {this.renderMessages()}

                <hr />

                <div className="text-right">
                  <a
                    href={this.props.GlobalConfig.REACT_APP_URL_MAILBOX_LIST}
                    className="view-more"
                  >
                    View All
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <span className="separator"></span>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <>
              <div className="mailbox-email-container">
                <div className="mailbox-email-screen">
                  <div className="panel">
                    {this.props.loggedUser.role === USER_ROLE_GROWER ? (
                      <div className="panel-heading">
                        {/* <div class="panel-actions">
                        <a href="#" class="fa fa-caret-down"></a>
                        <a href="#" class="fa fa-mail-reply"></a>
                        <a href="#" class="fa fa-mail-reply-all"></a>
                        <a href="#" class="fa fa-star-o"></a>
                      </div> */}
                        <p className="panel-title">
                          {this.state.data.processorName}{" "}
                          <i className="fa fa-angle-right fa-fw"></i> You
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="panel-body">
                      <p>{this.state.data.message}</p>
                      {this.props.loggedUser.role !== USER_ROLE_GROWER ? (
                        <>
                          <hr></hr>
                          <p>Sent To: {this.state.data.growerName}</p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="panel-footer">
                      <p className="m-none">
                        <small>{date}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer style={{ textAlign: "center", marginTop: "-20px" }}>
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      ""
    );
  }
}
export default App;
