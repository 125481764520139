import React, { useState, useEffect } from "react";
import { useAuth, getLoggedUser } from "../context/auth";
import DataTable from "react-data-table-component";
import {
  METHOD_POST,
  VALUE_QUERY_PARAM_IGNORE,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
} from "./Utilities/Constant";
import lodash from "lodash";
import SenderMessage from "./Mailbox/SenderMessage";
import NotificationMessage from "./Mailbox/NotificationMessage";
import { useDispatch } from "react-redux";
import { logout } from "./../redux/actions/userActions";
import { socketAnalytics } from "./../setupSockets";

function App(props) {
  const [widthHelpPopup] = useState(500);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [helpGroups, setHelpGroups] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [filterObject, setFilterObject] = useState(defaultFilterObject());
  const dispatch = useDispatch();

  useEffect(() => {
    if (showHelpPopup === true && isExpand === false) {
      filterItems(filterObject);
    }
  }, [showHelpPopup, isExpand]);

  //This is function
  const { setAuthTokens } = useAuth();

  //This is variable
  const loggedUser = getLoggedUser();
  const GlobalConfig = new window.globalConfig();

  function logOut() {
    socketAnalytics.disconnect();
    dispatch(logout());
    setAuthTokens();
  }

  async function filterItems(filterObject) {
    try {
      const urlapi = process.env.REACT_APP_API_HELP_URL + "filter";
      const requestOptions = {
        method: METHOD_POST,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(filterObject),
      };
      const result = await fetch(urlapi, requestOptions);
      const response = await result.json();
      if (response.result === "OK" && response.data) {
        const groups = separateHelpsByCategory(response.data);
        setHelpGroups(groups); //separate helps by category
        setIsExpand(true);
      } else {
        setIsExpand(true);
        window.showAlert("Error", response.message, "error");
      }
    } catch (e) {
      setIsExpand(true);
      window.showAlert(
        "Error",
        "Failed to fetch help documentation. Server doesn't response",
        e.message
      );
    }
  }

  function defaultFilterObject() {
    return {
      titleAndContent: VALUE_QUERY_PARAM_IGNORE,
      categories: "all",
      subCategories: "all",
      pages: VALUE_QUERY_PARAM_IGNORE,
    };
  }

  function onChangeTitleAndContent(e) {
    setFilterObject({
      ...filterObject,
      titleAndContent: e.target.value
        ? e.target.value
        : VALUE_QUERY_PARAM_IGNORE,
    });
  }

  function separateHelpsByCategory(helps) {
    if (!helps || !helps.length || !helps.length > 0) {
      return [];
    } else {
      let groups = [];
      const groupByResult = lodash.groupBy(helps, "category");
      for (let category in groupByResult) {
        groups.push({
          category: category,
          children: groupByResult[category],
        });
      }
      return groups;
    }
  }

  function handleSearch() {
    if (isExpand === true) {
      setIsExpand(false);
    } else {
      filterItems(filterObject);
    }
  }

  function renderLabelHeader() {
    switch (loggedUser.role) {
      case "Lab Users": {
        if (
          loggedUser.processorName === null ||
          loggedUser.processorName === undefined ||
          loggedUser.processorName === ""
        ) {
          return (
            <span className="role" style={{ color: "red" }}>
              Selected processor does not exist longer. Click{" "}
              <a
                href={
                  window.location.href +
                  (window.location.href.indexOf("?") !== -1
                    ? "&openSwitchProcessor=true"
                    : "?openSwitchProcessor=true")
                }
                target="_self"
              >
                here
              </a>{" "}
              switch to other processor.
            </span>
          );
        }
        return (
          <span className="role">
            Logged in under processor{" "}
            <strong>{loggedUser.processorName}</strong>
          </span>
        );
      }
      case "Processors":
      case "Staffs": {
        if (loggedUser.expiredMessage && loggedUser.expiredMessage !== ``) {
          return (
            <span className="role" style={{ color: "red" }}>
              {loggedUser.expiredMessage}
            </span>
          );
        }
        return "";
      }
      case "Growers": {
        if (loggedUser.isManager !== true) {
          return "";
        }
        if (
          (loggedUser.viewingGrowerName === null ||
            loggedUser.viewingGrowerName === undefined ||
            loggedUser.viewingGrowerName === "") &&
          loggedUser.viewingGrowerId !== ``
        ) {
          return (
            <span className="role" style={{ color: "red" }}>
              Selected grower does not exist longer. Click{" "}
              <a
                href={
                  window.location.href +
                  (window.location.href.indexOf("?") !== -1
                    ? "&openSwitchGrower=true"
                    : "?openSwitchGrower=true")
                }
                target="_self"
              >
                here
              </a>{" "}
              switch to other grower.
            </span>
          );
        }
        return (
          <span className="role">
            Viewing data of grower{" "}
            <strong>{loggedUser.viewingGrowerName}</strong>
          </span>
        );
      }
      default:
        return "";
    }
  }

  return (
    <header className="header">
      <div className="logo-container">
        <a href="../" className="logo">
          <img
            src="/assets/images/logo_black.png"
            height="35"
            alt="PF Grading Portal"
          />
        </a>
        <div
          className="visible-xs toggle-sidebar-left"
          data-toggle-class="sidebar-left-opened"
          data-target="html"
          data-fire-event="sidebar-left-opened"
        >
          <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>
      <div className="header-right">
        {/* <form action="pages-search-results.html" className="search nav-form">
          <div className="input-group input-search">
            <input
              type="text"
              className="form-control"
              name="q"
              id="q"
              placeholder="Search..."
            ></input>
          </div>
        </form>

        <span className="separator"></span>

        <ul className="notifications">
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-tasks"></i>
              <span className="badge">3</span>
            </a>

            <div className="dropdown-menu notification-menu large">
              <div className="notification-title">
                <span className="pull-right label label-default">3</span>
                Tasks
              </div>

              <div className="content">
                <ul>
                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Generating Sales Report
                      </span>
                      <span className="message pull-right text-dark">60%</span>
                    </p>
                    <div className="progress progress-xs light"></div>
                  </li>

                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Importing Contacts
                      </span>
                      <span className="message pull-right text-dark">98%</span>
                    </p>
                    <div className="progress progress-xs light"></div>
                  </li>

                  <li>
                    <p className="clearfix mb-xs">
                      <span className="message pull-left">
                        Uploading something big
                      </span>
                      <span className="message pull-right text-dark">33%</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-envelope"></i>
              <span className="badge">4</span>
            </a>

            <div className="dropdown-menu notification-menu">
              <div className="notification-title">
                <span className="pull-right label label-default">230</span>
                Messages
              </div>

              <div className="content">
                <ul>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Doe Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Doe</span>
                      <span className="message">Lorem ipsum dolor sit.</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Junior</span>
                      <span className="message truncate">
                        Truncated message. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Donec sit amet lacinia
                        orci. Proin vestibulum eget risus non luctus. Nunc
                        cursus lacinia lacinia. Nulla molestie malesuada est ac
                        tincidunt. Quisque eget convallis diam, nec venenatis
                        risus. Vestibulum blandit faucibus est et malesuada. Sed
                        interdum cursus dui nec venenatis. Pellentesque non nisi
                        lobortis, rutrum eros ut, convallis nisi. Sed tellus
                        turpis, dignissim sit amet tristique quis, pretium id
                        est. Sed aliquam diam diam, sit amet faucibus tellus
                        ultricies eu. Aliquam lacinia nibh a metus bibendum, eu
                        commodo eros commodo. Sed commodo molestie elit, a
                        molestie lacus porttitor id. Donec facilisis varius
                        sapien, ac fringilla velit porttitor et. Nam tincidunt
                        gravida dui, sed pharetra odio pharetra nec. Duis
                        consectetur venenatis pharetra. Vestibulum egestas nisi
                        quis elementum elementum.
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joe Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joe Junior</span>
                      <span className="message">Lorem ipsum dolor sit.</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <figure className="image">
                        <img
                          src="/assets/images/!sample-user.jpg"
                          alt="Joseph Junior"
                          className="img-circle"
                        />
                      </figure>
                      <span className="title">Joseph Junior</span>
                      <span className="message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec sit amet lacinia orci. Proin vestibulum eget risus
                        non luctus. Nunc cursus lacinia lacinia. Nulla molestie
                        malesuada est ac tincidunt. Quisque eget convallis diam.
                      </span>
                    </a>
                  </li>
                </ul>

                <hr />

                <div className="text-right">
                  <a href="#" className="view-more">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              className="dropdown-toggle notification-icon"
              data-toggle="dropdown"
            >
              <i className="fa fa-bell"></i>
              <span className="badge">3</span>
            </a>

            <div className="dropdown-menu notification-menu">
              <div className="notification-title">
                <span className="pull-right label label-default">3</span>
                Alerts
              </div>

              <div className="content">
                <ul>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-thumbs-down bg-danger"></i>
                      </div>
                      <span className="title">Server is Down!</span>
                      <span className="message">Just now</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-lock bg-warning"></i>
                      </div>
                      <span className="title">User Locked</span>
                      <span className="message">15 minutes ago</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="clearfix">
                      <div className="image">
                        <i className="fa fa-signal bg-success"></i>
                      </div>
                      <span className="title">Connection Restaured</span>
                      <span className="message">10/10/2014</span>
                    </a>
                  </li>
                </ul>

                <hr />

                <div className="text-right">
                  <a href="#" className="view-more">
                    View All
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul> */}
        {renderLabelHeader()}
        {loggedUser.role === USER_ROLE_GROWER ? (
          <>
            <span className="separator"></span>
            <NotificationMessage
              loggedUser={loggedUser}
              GlobalConfig={GlobalConfig}
            ></NotificationMessage>
          </>
        ) : (
          ""
        )}
        <div id="userbox" className="userbox">
          <a href="#" data-toggle="dropdown">
            {loggedUser.avatar == null ||
            loggedUser.avatar === undefined ||
            loggedUser.avatar === "" ? (
              ""
            ) : (
              <figure className="profile-picture">
                <img
                  src={loggedUser.avatar}
                  alt={loggedUser.name}
                  className="img-circle"
                  data-lock-picture={loggedUser.avatar}
                />
              </figure>
            )}
            <div className="profile-info" data-lock-name={loggedUser.name}>
              <span className="name">{loggedUser.name}</span>
              <span className="role">
                {loggedUser.role
                  .replace("Administrators", "Administrator")
                  .replace("Lab Users", "Lab User")
                  .replace("Processors", "Processor")
                  .replace("Staffs", "Staff")
                  .replace("Growers", "Grower")}
              </span>
            </div>

            <i className="fa custom-caret"></i>
          </a>

          <div className="dropdown-menu">
            <ul className="list-unstyled">
              <li className="divider"></li>
              <li>
                <a role="menuitem" tabIndex="-1" href="/my-profile">
                  <i className="fa fa-user"></i> My Profile
                </a>
              </li>
              <li>
                <a role="menuitem" tabIndex="-1" onClick={logOut} href="#">
                  <i className="fa fa-power-off"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        {loggedUser.role === USER_ROLE_STAFF ||
        loggedUser.role === USER_ROLE_PROCESSOR ? (
          <i
            className="fa fa-envelope-o help-popup-icon"
            aria-hidden="true"
            onClick={() => setShowMessagePopup(!showMessagePopup)}
            title="Send message to growers"
          ></i>
        ) : (
          ""
        )}
        <i
          className="fa fa-question-circle help-popup-icon"
          aria-hidden="true"
          onClick={() => setShowHelpPopup(!showHelpPopup)}
        ></i>
      </div>
      <div
        className="help-popup-background"
        style={{
          display: showHelpPopup ? "block" : "none",
        }}
        onClick={() => setShowHelpPopup(!showHelpPopup)}
      ></div>
      <div
        className="help-popup"
        style={{
          right: showHelpPopup ? `0px` : `-${widthHelpPopup}px`,
          width: widthHelpPopup,
          boxShadow: showHelpPopup
            ? "-2px 0 10px 2px rgb(157, 156, 156)"
            : null,
        }}
      >
        <div className="help-popup-header">
          <div className="help-popup-header-title">Help</div>
          <div
            className="close-help-popup-button"
            onClick={() => setShowHelpPopup(!showHelpPopup)}
          >
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{
                fontSize: "20px",
                marginRight: 2,
              }}
            ></i>
            Close
          </div>
        </div>
        <div className="help-popup-body">
          <div className="col-md-12">
            <input
              className="form-control"
              placeholder="Search"
              type={"text"}
              value={
                filterObject.titleAndContent !== VALUE_QUERY_PARAM_IGNORE
                  ? filterObject.titleAndContent
                  : ""
              }
              onChange={(e) => onChangeTitleAndContent(e)}
            ></input>
          </div>
          {/* <div className="col-md-12" style={{ marginBottom: 10 }}>
            <MultiSelect
              api={
                GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                `likename/` +
                "Help Category"
              }
              placeholder={"Filter Category"}
              closeMenuOnSelect={false}
              value={
                filterObject.categories !== "all" ? filterObject.categories : ""
              }
              isMulti={true}
              onChange={(e) => onChangeCategories(e)}
              parseObjectItem={(data) => {
                return {
                  label: data,
                  value: data,
                };
              }}
            />
          </div>
          <div className="col-md-12">
            <MultiSelect
              api={
                GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                `likename/` +
                "Help Subcategory"
              }
              placeholder={"Filter Sub Category"}
              closeMenuOnSelect={false}
              value={
                filterObject.subCategories !== "all"
                  ? filterObject.subCategories
                  : ""
              }
              isMulti={true}
              onChange={(e) => onChangeSubCategories(e)}
              parseObjectItem={(data) => {
                return {
                  label: data,
                  value: data,
                };
              }}
            />
          </div> */}
          <div style={{ float: "right", marginRight: 15 }}>
            <button
              className="help-popup-button"
              onClick={() => handleSearch()}
            >
              <i
                className="fa fa-search"
                aria-hidden="true"
                style={{
                  marginRight: 5,
                }}
              ></i>
              Search
            </button>
          </div>
          <DataTable
            className="table table-bordered table-striped mb-none"
            noHeader={true}
            noTableHead={true}
            columns={[
              {
                name: "Category",
                selector: "",
                sortable: true,
                left: true,
                cell: (group) => {
                  return <div>{group.category}</div>;
                },
              },
            ]}
            data={helpGroups}
            expandableRows
            expandableRowsComponent={<HelpRowTableComponent />}
            expandableRowExpanded={() => {
              return isExpand;
            }}
          ></DataTable>
        </div>
        <div className="help-popup-footer"></div>
      </div>
      {loggedUser.role === USER_ROLE_STAFF ||
      loggedUser.role === USER_ROLE_PROCESSOR ? (
        <SenderMessage
          GlobalConfig={GlobalConfig}
          loggedUser={loggedUser}
          showMessagePopup={showMessagePopup}
          setShowMessagePopup={() => setShowMessagePopup(!showMessagePopup)}
        ></SenderMessage>
      ) : (
        ""
      )}
    </header>
  );
}
export default App;

function HelpRowTableComponent(props) {
  const group = props.data;
  return (
    <>
      <DataTable
        noHeader
        noTableHead
        columns={[
          {
            name: "Title",
            cell: (help) => (
              <a
                target="_blank"
                href={
                  process.env.REACT_APP_URL_HELP_LIST + `/content/${help._id}`
                }
              >
                {help.title}
              </a>
            ),
          },
        ]}
        data={group.children}
      />
    </>
  );
}
