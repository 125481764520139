import React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import { getLoggedUser } from "../../context/auth";
import ColumnOptions from "./ColumnOptions";
import TableLoadingComponent from "./TableLoadingComponent";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super(props);
    loggedUser = getLoggedUser();
    this.state = {
      useExtendBody: false,
      pagination:
        props === undefined ||
        props.pagination == null ||
        props.pagination === undefined
          ? true
          : props.pagination,

      paginationPerPage:
        props === undefined ||
        props.paginationPerPage == null ||
        props.paginationPerPage === undefined
          ? parseInt(GlobalConfig.REACT_APP_PAGE_SIZE)
          : props.paginationPerPage,

      labeladd:
        props === undefined ||
        props.labeladd === null ||
        props.labeladd === undefined ||
        props.labeladd === ""
          ? "Add"
          : props.labeladd,
      urlapi: "",
      urlapidelete: "",
      urllist: "",
      urladd: "",
      title: "",
      pluraltitle: "",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      items: [],
      originalItems: [],
      confirmDeleteMessage: "",
      isUseHeading: true,
      showRefresh1: true,
      showRefresh2: false,
      childOverrideComponentDidMount: false,
      filterControlUrls: {},

      //server paging
      isServerPaging: false,
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: parseInt(GlobalConfig.REACT_APP_PAGE_SIZE),
      rowPerPageOption: GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION,
    };

    this.state.btnColumnOptions = false;
    this.state.hideColumns = [];
    this.state.dataCheck = false;
    this.state.searchPanelOpen = false;
    this.state.userSettingUrlApi = GlobalConfig.REACT_APP_API_USERSETTING_URL;
    this.state.data = {
      pageName: "",
    };

    // Custom heading component
    this.state.useFilter = true;
    this.state.useColumnOptions = true;

    this.state.progressPending = false;

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.doSomethingAfterGetItems = this.doSomethingAfterGetItems.bind(this);
    this.doSomethingAfterDeletedItem =
      this.doSomethingAfterDeletedItem.bind(this);
    this.handleDeleteAfterDeletedOnServer =
      this.handleDeleteAfterDeletedOnServer.bind(this);
  }

  async componentDidMount() {
    if (this.state.childOverrideComponentDidMount !== true) {
      if (this.state.isServerPaging === true) {
        this.getItemsServerPaging(this.state.currentPage);
      } else {
        this.getItems();
      }

      this.loadColumnSettings();
    } else {
      this.componentDidMountChild();
    }
  }

  async componentDidMountChild() {}

  loadColumnSettings = () => {
    this.getColumnSettingsByPage(
      this.state.pageName.replaceAll(" ", "") + loggedUser.referenceUserId,
      "table" +
        this.state.pageName.replaceAll(" ", "") +
        loggedUser.referenceUserId,
      loggedUser.referenceUserId
    );
  };

  columnOptionOpening = () => {
    this.setState({ btnColumnOptions: true });
    this.getColumnSettingsByPage(
      this.state.pageName.replaceAll(" ", "") + loggedUser.referenceUserId,
      "table" +
        this.state.pageName.replaceAll(" ", "") +
        loggedUser.referenceUserId,
      loggedUser.referenceUserId
    );
  };

  columnOptionClosed = () => {
    this.setState({ btnColumnOptions: false });
  };

  columnOptionReset = () => {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          pageName: this.state.pageName.replaceAll(" ", ""),
          value: "",
        }),
        hideColumns: [],
      },
      () => {
        try {
          const that = this;
          const requestOptions = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          const url =
            this.state.userSettingUrlApi +
            this.state.pageName.replaceAll(" ", "") +
            loggedUser.referenceUserId +
            "/" +
            "table" +
            this.state.pageName.replaceAll(" ", "") +
            loggedUser.referenceUserId +
            "/" +
            loggedUser.referenceUserId;
          fetch(url, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If updated successful
              if (resultObject && resultObject.result === "OK") {
                that.setState({
                  alertshow: false,
                });
                window.showAlert("", "Save successful!", "");

                that.doSomethingAfterSaved(resultObject);
              }
              //If failed, show error
              else {
                that.setState({
                  alertshow: false,
                });
                that.setState({
                  alertmsg: resultObject.message,
                  alerttype: "error",
                  alertshow: true,
                });
              }
            })
            .catch(function (error) {
              that.setState({
                alertshow: false,
              });
              that.setState({
                alertmsg: error,
                alerttype: "error",
                alertshow: true,
              });
            });
        } catch (err) {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: err.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      }
    );
  };

  getColumnSettingsByPage = async (pageName, objectName, userId) => {
    try {
      const that = this;
      const url = encodeURI(
        `${this.state.userSettingUrlApi}${pageName}/${objectName}/${userId}`
      );
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            if (resultObject.data) {
              that.setState({
                data: Object.assign({}, that.state.data, {
                  value: resultObject.data.value.split(","),
                }),
                hideColumns: resultObject.data.value.split(","),
                dataCheck: true,
                alertshow: false,
              });
            } else {
              //Turn-off message
              that.setState({
                alertshow: false,
              });
            }
          } else {
            that.setState({
              alertshow: false,
            });
            that.setState({
              alertmsg: resultObject.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  columnOptionChanged = (event) => {
    const that = this;
    var index = this.state.hideColumns.indexOf(event.target.name);

    this.state.hideColumns.indexOf(event.target.name) !== -1
      ? this.state.hideColumns.splice(index, 1)
      : this.state.hideColumns.push(event.target.name);

    this.state.dataCheck === false
      ? this.setState(
          {
            data: Object.assign({}, this.state.data, {
              pageName:
                this.state.pageName.replaceAll(" ", "") +
                loggedUser.referenceUserId,
              objectName:
                "table" +
                this.state.pageName.replaceAll(" ", "") +
                loggedUser.referenceUserId,
              userId: loggedUser.referenceUserId,
              value: this.state.hideColumns.toString(),
            }),
            dataCheck: true,
          },
          () => {
            try {
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.state.data),
              };
              const url = this.state.userSettingUrlApi;
              fetch(url, requestOptions)
                .then(function (response) {
                  return response.json();
                })
                .then(function (resultObject) {
                  //If created successful
                  if (resultObject && resultObject.result === "OK") {
                    that.doSomethingAfterSaved(resultObject.data);
                    if (
                      that.state.isShowSaveSuccessfulMessage == true ||
                      that.state.isShowSaveSuccessfulMessage === undefined ||
                      that.state.isShowSaveSuccessfulMessage == null
                    ) {
                      window.showAlert("", "Save successful!", "");
                    }
                  }
                  //If failed, show error
                  else {
                    that.doSomethingAfterErrorSaved(resultObject);
                  }
                })
                .catch(function (error) {
                  window.showAlert("Error", error, "Error");
                });
            } catch (err) {
              window.showAlert("Error", err.message, "Error");
            }
          }
        )
      : this.setState(
          {
            data: Object.assign({}, this.state.data, {
              value: this.state.hideColumns.toString(),
            }),
          },
          () => {
            try {
              const that = this;
              const requestOptions = {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.state.data),
              };
              const url =
                this.state.userSettingUrlApi +
                this.state.pageName.replaceAll(" ", "") +
                loggedUser.referenceUserId +
                "/" +
                "table" +
                this.state.pageName.replaceAll(" ", "") +
                loggedUser.referenceUserId +
                "/" +
                loggedUser.referenceUserId;
              fetch(url, requestOptions)
                .then(function (response) {
                  return response.json();
                })
                .then(function (resultObject) {
                  //If updated successful
                  if (resultObject && resultObject.result === "OK") {
                    that.setState({
                      alertshow: false,
                    });
                    window.showAlert("", "Save successful!", "");

                    that.doSomethingAfterSaved(resultObject);
                  }
                  //If failed, show error
                  else {
                    that.setState({
                      alertshow: false,
                    });
                    that.setState({
                      alertmsg: resultObject.message,
                      alerttype: "error",
                      alertshow: true,
                    });
                  }
                })
                .catch(function (error) {
                  that.setState({
                    alertshow: false,
                  });
                  that.setState({
                    alertmsg: error,
                    alerttype: "error",
                    alertshow: true,
                  });
                });
            } catch (err) {
              this.setState({
                alertshow: false,
              });
              this.setState({
                alertmsg: err.message,
                alerttype: "error",
                alertshow: true,
              });
            }
          }
        );
  };

  doSomethingAfterSaved = (data) => {};

  doSomethingAfterErrorSaved(res) {
    window.showAlert("Error", res.message, "Error");
  }

  getItems = async () => {
    this.setState({
      progressPending: true,
    });
    try {
      const request = await fetch(this.state.urlapi);
      const responseData = await request.json();
      if (responseData && responseData.result === "OK") {
        this.setState(
          { items: responseData.data, originalItems: responseData.data },
          () => {
            this.doSomethingAfterGetItems(responseData);
          }
        );
        this.setState({
          progressPending: false,
        });
      } else {
        this.setState(
          {
            alertshow: false,
            progressPending: false,
            alertmsg: ``,
          },
          () => {
            this.setState({
              alertmsg: responseData.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        );
      }
    } catch (err) {
      this.setState({
        alertshow: false,
        progressPending: false,
        alertmsg: ``,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  getItemsServerPaging = async (page, size = this.state.perPage) => {
    this.setState({
      progressPending: true,
    });
    try {
      const url = this.state.urlapi + `/${page}/${size}`;
      const request = await fetch(url);
      const resultObject = await request.json();
      if (resultObject && resultObject.result === "OK") {
        this.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            totalRows: resultObject.data ? resultObject.totalCount : 0,
            loading: false,
            progressPending: false,
          },
          () => {
            this.doSomethingAfterGetItems(resultObject);
          }
        );
      } else {
        this.setState(
          {
            alertshow: false,
            progressPending: false,
            alertmsg: ``,
          },
          () => {
            this.setState(
              {
                alertmsg: resultObject.message,
                alerttype: "error",
                alertshow: true,
              },
              () => {
                this.doSomethingAfterGetItemsFailed(resultObject);
              }
            );
          }
        );
      }
    } catch (err) {
      this.setState({
        alertshow: false,
        progressPending: false,
        alertmsg: ``,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  doSomethingAfterGetItems = (data) => {
    if (this.state.isServerPaging === true) {
      this.setState({ totalRows: data ? data.totalCount : 0, loading: false });
    }
  };

  doSomethingAfterGetItemsFailed = (data) => {
    if (this.state.isServerPaging === true) {
      this.setState({ totalRows: data ? data.totalCount : 0, loading: false });
    }
  };

  handleDeleteAfterDeletedOnServer = (removedId) => {
    //If deleted successful
    const newItems = this.state.items.filter((item) => {
      return item._id !== removedId;
    });
    const newOriginalItems = this.state.originalItems.filter((item) => {
      return item._id !== removedId;
    });
    this.setState(
      {
        items: newItems,
        originalItems: newOriginalItems,
      },
      () => {
        this.doSomethingAfterDeletedItem();
      }
    );
    window.showAlert("Success", "Removed successful!", "");
  };

  doSomethingAfterDeletedItem = () => {};
  doSomethingBeforeDeleteItem = (e) => {};

  handleDelete = (e) => {
    this.doSomethingBeforeDeleteItem(e);
    const removedId = e.currentTarget.dataset.id;
    confirmAlert({
      title: "Confirm to delete",
      message:
        this.state.confirmDeleteMessage === undefined ||
        this.state.confirmDeleteMessage == ""
          ? "Are you sure to delete selected " + this.state.title + "?"
          : this.state.confirmDeleteMessage,
      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            try {
              const that = this;
              const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  userId: loggedUser._id,
                  modifiedUserId: loggedUser._id,
                }),
              };
              const urlapiDelete =
                this.state.urlapidelete == null ||
                this.state.urlapidelete === undefined ||
                this.state.urlapidelete === ""
                  ? this.state.urlapi
                  : this.state.urlapidelete;
              const url = urlapiDelete + removedId;
              fetch(url, requestOptions)
                .then(function (response) {
                  return response.json();
                })
                .then(function (resultObject) {
                  if (resultObject && resultObject.result === "OK") {
                    that.handleDeleteAfterDeletedOnServer(removedId);
                  } else {
                    //If failed, show error
                    window.showAlert("Error", resultObject.message, "error");
                  }
                })
                .catch(function (error) {
                  window.showAlert("Error", error, "error");
                });
            } catch (err) {
              window.showAlert("Error", err.message, "error");
            }
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  updateStateAfterClosePopup = (data) => {
    if (this.state.isChanged) {
      if (this.state.isEditMode) {
        //update item in array
        this.setState({
          items: this.state.items.map((el) =>
            el._id === data._id ? Object.assign({}, el, data) : el
          ),
        });
        this.setState({
          originalItems: this.state.originalItems.map((el) =>
            el._id === data._id ? Object.assign({}, el, data) : el
          ),
        });
      } else {
        //insert new item in array
        this.setState({
          items: [...this.state.items, data],
        });
        this.setState({
          originalItems: [...this.state.originalItems, data],
        });
      }
    }
  };

  setColumns = () => {
    return [];
  };

  extendBody = () => {
    return "";
  };

  extendRender = () => {
    return "";
  };

  extendBreadcrumb = () => {
    return "";
  };

  conditionalRowStyles = () => {};

  onRowClicked = (row) => {};

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItemsServerPaging(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    this.getItemsServerPaging(page);
    this.setState({ currentPage: page });
  };

  extendRenderBeforeTable = () => {
    return "";
  };

  highlightOnHover = () => {
    return true;
  };

  striped = () => {
    return true;
  };

  extendButtonsBeforeColumnOptions = () => {
    return this.defaultButtons();
  };

  columnOptions = () => {
    return (
      <ColumnOptions
        btnColumnOptions={this.state.btnColumnOptions}
        columnOptionOpening={this.columnOptionOpening}
        columnOptionClosed={this.columnOptionClosed}
        defaultColumns={this.defaultColumns()}
        hideColumns={this.state.hideColumns}
        columnOptionChanged={this.columnOptionChanged}
        columnOptionReset={this.columnOptionReset}
      />
    );
  };

  handleSearch = () => {
    if (this.state.isServerPaging === true) {
      this.setState({ currentPage: 1 }, () => {
        this.filterItems();
      });
    } else {
      this.filterItems();
    }
  };

  handleReset = () => {};

  handleChange = (event) => {
    if (event.target) {
      const data = event.target.value;
      const id = event.target.name;
      this.setState({
        [id]: data,
      });
    }
  };

  handleChangeObjectData = (event) => {
    if (event.target) {
      const data = event.target.value;
      const id = event.target.name;
      this.setState({
        data: Object.assign({}, this.state.data, {
          [id]: data,
        }),
      });
    }
  };

  handleChangeSearchPanel = () => {
    this.setState({
      searchPanelOpen: !this.state.searchPanelOpen,
    });
    const filterControl = document.getElementById("filterControl");
    if (!filterControl) {
      return;
    }
    const isOpened = filterControl.getAttribute("aria-expanded");
    if (isOpened === "true") {
      this.setUrlsForSearchControl();
    }
  };

  setUrlsForSearchControl = () => {
    if (!this.state.filterControlUrls) {
      return;
    }
    if (this.urlsUpdatedAlready() === true) {
      return;
    }
    this.updateFilterControlUrls();
  };

  urlsUpdatedAlready = () => {
    const urlProperties = Object.keys(this.state.filterControlUrls);
    if (!urlProperties.length > 0) {
      return false;
    }
    return !urlProperties.some(
      (urlProperty) =>
        !this.state.filterControlUrls[urlProperty] ||
        !this.state.filterControlUrls[urlProperty].trim()
    );
  };

  updateFilterControlUrls = () => {};

  renderSearchButtons = () => {
    return (
      <>
        <a
          data-toggle="collapse"
          href="#filterControl"
          aria-expanded="true"
          aria-controls="filterControl"
          className="panel-btn-group-item-position mb-sm"
        >
          <button
            className="btn btn-column-chooser panel-btn-group-color"
            title={
              this.state.searchPanelOpen
                ? "Close Filter Control"
                : "Filter Control"
            }
            onClick={this.handleChangeSearchPanel}
          >
            {this.state.searchPanelOpen ? (
              <i className="fa fa-times fa-lg"></i>
            ) : (
              <i className="fa fa-filter fa-lg"></i>
            )}
          </button>
        </a>

        {this.state.searchPanelOpen ? (
          <>
            <span className="panel-btn-group-item-position mb-sm">
              <button className="btn btn-primary" onClick={this.handleReset}>
                <i className="fa fa-refresh fa-lg"></i> Reset
              </button>
            </span>
            <span className="panel-btn-group-item-position mb-sm">
              <button className="btn btn-primary" onClick={this.handleSearch}>
                <i className="fa fa-search fa-lg"></i> Search
              </button>
            </span>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  defaultProgressComponent = () => {
    return (
      <TableLoadingComponent
        loadingMessage={this.state.loadingMessage}
        hideLoadingIcon={this.state.hideTableLoadingIcon}
      ></TableLoadingComponent>
    );
  };

  defaultTable = () => {
    return this.state.urlapi === null || this.state.urlapi === undefined ? (
      ""
    ) : this.state.isServerPaging === true ? (
      <>
        <DataTable
          highlightOnHover={this.highlightOnHover()}
          striped={this.striped()}
          pointerOnHover={true}
          id="emp"
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          conditionalRowStyles={this.conditionalRowStyles()}
          pagination={true}
          paginationPerPage={this.state.paginationPerPage}
          paginationServer={true}
          paginationTotalRows={this.state.totalRows}
          paginationRowsPerPageOptions={this.state.rowPerPageOption}
          paginationDefaultPage={this.state.currentPage}
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          progressPending={this.state.progressPending}
          progressComponent={this.defaultProgressComponent()}
          onRowClicked={this.onRowClicked}
        />
      </>
    ) : (
      <>
        <DataTable
          highlightOnHover={this.highlightOnHover()}
          striped={this.striped()}
          pointerOnHover={true}
          id="emp"
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          pagination={this.state.pagination}
          paginationPerPage={this.state.paginationPerPage}
          paginationRowsPerPageOptions={this.state.rowPerPageOption}
          // subHeader={this.state.subHeader}
          // subHeaderComponent={
          //   this.renderHeader()
          // }
          conditionalRowStyles={this.conditionalRowStyles()}
          progressPending={this.state.progressPending}
          progressComponent={this.defaultProgressComponent()}
          onRowClicked={this.onRowClicked}
        />
      </>
    );
  };

  defaultButtons = () => {
    if (
      this.state.excludeDefaultButtons === true ||
      this.state.urladd === null ||
      this.state.urladd === undefined ||
      this.state.urladd === ""
    ) {
      return "";
    }
    return (
      <a
        href={this.state.urladd}
        className="btn btn-primary panel-btn-group-item-position"
      >
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  extendButtons = () => {
    return "";
  };

  renderSearch = () => {
    return "";
    // <div style={{ display: 'flex', alignItems: 'center' }}>
    //   <input id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} placeholder="Search" className="form-control" maxLength={100} onChange={this.handleSearch} />
    // </div>
  };

  handleRefresh = () => {
    if (this.state.isServerPaging === true) {
      this.setState(
        {
          currentPage: 1,
        },
        function () {
          this.getItemsServerPaging(this.state.currentPage);
        }
      );
    } else {
      this.getItems();
    }
  };

  renderRefresh1 = () => {
    return this.state.showRefresh1 ? (
      <div className="panel-actions panel-actions-list-view">
        <a
          href="#"
          className="panel-btn-group-color"
          title="Refresh"
          onClick={() => this.handleRefresh()}
        >
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  renderRefresh2 = () => {
    return "";
  };

  defaultTitle = () => {
    const isNotUsePrural = ["Staff"];

    if (
      this.state.pluraltitle !== undefined &&
      this.state.pluraltitle !== null &&
      this.state.pluraltitle !== ""
    ) {
      return this.state.pluraltitle;
    } else {
      if (isNotUsePrural.includes(this.state.title)) {
        return this.state.title;
      } else {
        return this.state.title + "s";
      }
    }
  };

  defaultTitleSetting = () => {
    const length = this.getTotalItems();

    switch (true) {
      case length <= 0:
        return "Not found!";
      case length === 1:
        return `${length} ${this.state.title} found`;
      default:
        if (this.state.pluraltitle === "") {
          return `${length} ${this.state.title}s found`;
        } else {
          return `${length} ${this.state.pluraltitle} found`;
        }
    }
  };

  getTotalItems = () => {
    return this.state.isServerPaging === true
      ? this.state.totalRows
        ? this.state.totalRows
        : 0
      : this.state.items
      ? this.state.items.length
      : 0;
  };

  render() {
    const title = this.defaultTitle();

    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    {this.extendBreadcrumb()}
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              {this.state.useExtendBody == null ||
              this.state.useExtendBody === undefined ||
              this.state.useExtendBody !== true ? (
                <section className="panel">
                  {this.state.isUseHeading === true ? (
                    <header className="panel-heading panel-heading-list-view">
                      {this.renderRefresh1()}
                      <h2 className="panel-title">
                        {this.defaultTitleSetting()}
                      </h2>
                    </header>
                  ) : (
                    ""
                  )}

                  <div className="panel-body panel-body-spacing panel-datatable-content">
                    <div className="collapse" id="filterControl">
                      <div className="row panel-filter-control-wrapper">
                        {this.renderSearch()}
                      </div>
                      <hr className="dotted tall line-search"></hr>
                    </div>

                    <div className="col-lg-12">
                      <div className="row">
                        {this.state.useColumnOptions
                          ? this.columnOptions()
                          : ""}

                        {this.extendButtons()}

                        {this.extendButtonsBeforeColumnOptions()}

                        {this.state.useFilter ? this.renderSearchButtons() : ""}
                      </div>
                    </div>
                    {this.extendRenderBeforeTable()}
                    {this.defaultTable()}
                    {this.state.alertshow === true ||
                    (this.state.alertmsg && this.state.alertmsg !== ``) ? (
                      <Alert
                        message={this.state.alertmsg}
                        type={this.state.alerttype}
                        show={this.state.alertshow}
                      ></Alert>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              ) : (
                this.extendBody()
              )}
            </section>
          </div>
        </section>
        {this.extendRender()}
      </>
    );
  }
}
export default App;
