import React from "react";

class App extends React.Component {
    constructor(props) {
        super();
        this.state = {
            labelClass: "col-md-" + (props.labelClass === null || props.labelClass === undefined ? 4 : props.labelClass) + " control-label",
            inputClass: "col-md-" + (props.inputClass === null || props.inputClass === undefined ? 8 : props.inputClass)
        }
    }

    handleFocus = (e) => {
        if (e != null && e.target != null && e.target.type == "text") {
            e.target.select();
        }
    };

    render() {
        return <div className={this.props.classProps ? this.props.classProps + " form-group" : "form-group"}>
            <label
                className={this.state.labelClass}
                htmlFor={this.props.name}
            >
                {this.props.label}
                {this.props.required ? (<>{" "} <span className="required">(*)</span></>) : ""}
            </label>
            {
                this.props.unit === undefined || this.props.unit == null ?
                    <div className={this.state.inputClass}>
                        <textarea
                            rows={this.props.rows}
                            data-plugin-maxlength={this.props.maxLength}
                            maxLength={this.props.maxLength}
                            name={this.props.name}
                            className="form-control"
                            placeholder={this.props.placeholder}
                            required={this.props.required && this.props.displayRequired !== true}
                            readOnly={this.props.readOnly}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            onFocus={this.handleFocus}
                        />
                    </div> :

                    <div className={this.state.inputClass}>
                        <div className="input-group">
                            <textarea
                                rows={this.props.rows}
                                data-plugin-maxlength={this.props.maxLength}
                                maxLength={this.props.maxLength}
                                name={this.props.name}
                                className="form-control"
                                placeholder={this.props.placeholder}
                                required={this.props.required}
                                readOnly={this.props.readOnly}
                                value={this.props.value}
                                onChange={this.props.handleChange}
                                onFocus={this.handleFocus}
                            />
                            <span className="input-group-addon">
                                {this.props.unit}
                            </span>
                        </div>
                    </div>
            }
        </div >
    }
}
export default App;
