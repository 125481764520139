import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import axios from "axios";
import TableOwner from "./TableOwner";
import CommonUtilities from "../Utilities/Common";
import AttachmentsByObject from "../Attachment/AttachmentsByObject";
import SaveButton from "../Controls/SaveButton";
import { Button } from "react-bootstrap";
import RequestUpdatePopup from "./RequestUpdatePopup";

var jwt = require("jsonwebtoken");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "",
      urllist: "",
      urlapi: GlobalConfig.REACT_APP_API_GROWER_URL,
      urlsubmitapi: GlobalConfig.REACT_APP_API_GROWER_URL + "profile/",
      currentProcessorName: "",
      data: {
        _id: loggedUser.referenceUserId,
        name: "",
        contactName: "",
        contactEmail: "",
        address: "",
        city: "",
        state: "",
        region: "",
        note: "",
        newPassword: "",
        currentPassword: "",
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      errors: {
        email: "",
      },
      selectedTab: `Profile`,
      loading: parentState.loading,
      hintUpdateMessage:
        "To update this field, please click on button Request Update at bottom",
    };
    this.queryProcessor = this.queryProcessor.bind(this);
    this.updateSelectedTabProfile = this.updateSelectedTabProfile.bind(this);
    this.updateSelectedTabContract = this.updateSelectedTabContract.bind(this);
  }

  queryProcessor = async () => {
    try {
      let processorId = loggedUser.processorId;
      if (
        processorId !== null &&
        processorId !== undefined &&
        processorId !== ""
      ) {
        const that = this;
        fetch(GlobalConfig.REACT_APP_API_PROCESSOR_URL + processorId)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObject) {
            if (
              resultObject &&
              resultObject.result === "OK" &&
              resultObject.data
            ) {
              that.setState({
                currentProcessorName: resultObject.data.companyName,
              });
            } else {
              that.setState({
                currentProcessorName:
                  "Processor does not exist longer. Please switch to other processor.",
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  doSomethingAfterGetItemById = () => {
    this.queryProcessor();

    //Set data table owners
    if (this.owners) {
      //this.owners still undefined so cannot call
      this.owners.bindData(this.state.data.owners);
    }
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(
      this.state.data.contactEmail
    );
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return "My Profile";
  };

  doSomethingAfterSaved = (resultObject) => {
    axios
      .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/queryprofilebytoken", {
        id: this.state.data._id,
      })
      .then((result) => {
        if (result.status === 200) {
          const token = jwt.sign(
            result.data.data,
            process.env.REACT_APP_TOKEN_SECRET
          );
          // localStorage.clear();
          localStorage.setItem("isActive", result.data.data.isActive);
          localStorage.setItem("tokens", JSON.stringify(token));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  updateSelectedTabProfile = () => {
    this.setState({
      selectedTab: `Profile`,
    });
  };

  updateSelectedTabContract = () => {
    this.setState({
      selectedTab: `Contract`,
    });
  };

  render() {
    const title = "My Profile";
    return (
      <>
        <RequestUpdatePopup
          loggedUser={loggedUser}
          onRef={(ref) => (this.requestUpdatePopup = ref)}
          currentGrower={this.state.data}
          GlobalConfig={GlobalConfig}
        ></RequestUpdatePopup>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>
                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-12">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a
                          href="#Profile"
                          data-toggle="tab"
                          onClick={this.updateSelectedTabProfile}
                        >
                          Profile & Account
                        </a>
                      </li>
                      <li>
                        <a
                          href="#Contracts"
                          data-toggle="tab"
                          onClick={this.updateSelectedTabContract}
                        >
                          Contracts
                        </a>
                      </li>
                    </ul>
                    <form
                      className="form-horizontal form-bordered"
                      onSubmit={this.handleSubmit}
                      id="form"
                    >
                      <div className="tab-content">
                        <div id="Profile" className="tab-pane active">
                          <h4 className="mb-xlg">Grower Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Invited Processor
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  <strong>
                                    {this.state.currentProcessorName}
                                  </strong>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Is Manager
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  <strong>
                                    {this.state.data.isManager ? "Yes" : "No"}
                                  </strong>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="name"
                              >
                                Grower Name{" "}
                                <span
                                  className="request-update-info"
                                  title={this.state.hintUpdateMessage}
                                >
                                  (?)
                                </span>
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {this.state.data.name}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="contactName"
                              >
                                Contact Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  name="contactName"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.contactName}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="contactEmail"
                              >
                                Contact Email{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="email"
                                  name="contactEmail"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.contactEmail}
                                  onChange={this.handleChange}
                                  maxLength={100}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="address"
                              >
                                Address{" "}
                                <span
                                  className="request-update-info"
                                  title={this.state.hintUpdateMessage}
                                >
                                  (?)
                                </span>
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {this.state.data.address}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="city"
                              >
                                City{" "}
                                <span
                                  className="request-update-info"
                                  title={this.state.hintUpdateMessage}
                                >
                                  (?)
                                </span>
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {this.state.data.city}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="state"
                              >
                                State{" "}
                                <span
                                  className="request-update-info"
                                  title={this.state.hintUpdateMessage}
                                >
                                  (?)
                                </span>
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {this.state.data.state}
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="region"
                              >
                                Region
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  name="region"
                                  className="form-control"
                                  value={this.state.data.region}
                                  onChange={this.handleChange}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="note"
                              >
                                Note
                              </label>
                              <div className="col-md-8">
                                <textarea
                                  type="text"
                                  name="note"
                                  rows={5}
                                  className="form-control"
                                  value={this.state.data.note}
                                  onChange={this.handleChange}
                                  maxLength={2000}
                                />
                              </div>
                            </div>
                            {this.state.data.isMultiOwner ? (
                              <TableOwner
                                readOnly={true}
                                value={this.state.data.owners}
                                name="owners"
                                onRef={(ref) => (this.owners = ref)}
                              />
                            ) : (
                              ""
                            )}
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Change Password</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="currentPassword"
                              >
                                Current Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="currentPassword"
                                  id="currentPassword"
                                  maxLength={100}
                                  value={this.state.data.currentPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="newPassword"
                              >
                                New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="newPassword"
                                  name="newPassword"
                                  maxLength={100}
                                  value={this.state.data.newPassword ?? ""}
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-3 control-label"
                                htmlFor="newPasswordRepeat"
                              >
                                Repeat New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="newPasswordRepeat"
                                  name="newPasswordRepeat"
                                  maxLength={100}
                                  value={
                                    this.state.data.newPasswordRepeat ?? ""
                                  }
                                  onChange={this.handleChange}
                                  autoComplete="new-password"
                                ></input>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div
                          id="Contracts"
                          className="tab-pane"
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "0px",
                          }}
                        >
                          <AttachmentsByObject
                            objectId={this.state.data._id}
                            objectType="Grower"
                            loggedUser={loggedUser}
                            processorId={loggedUser.processorId}
                          />
                        </div>
                      </div>
                      <div className="panel-footer">
                        {this.state.selectedTab === `Profile` ? (
                          <div className="row">
                            <div className="col-md-9 col-md-offset-3">
                              <SaveButton loading={this.state.loading} />
                              <Button
                                variant="primary"
                                onClick={
                                  this.requestUpdatePopup
                                    ?.showRequestUpdatePopup
                                }
                              >
                                <div className="flex-box-align-items-center">
                                  <i
                                    className="fa fa-paper-plane"
                                    aria-hidden="true"
                                    style={{
                                      marginRight: 2.5,
                                      fontSize: 18,
                                    }}
                                  ></i>
                                  Request Update
                                </div>
                              </Button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
