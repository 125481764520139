import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

var DateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super();

    let labelValueInshell = ``;
    let labelValueShell = `Shelled`;
    if (props.testType.isDynamic) {
      if (props.testType.xpool === true) {
        labelValueInshell = "Regular Price";
        labelValueShell = "Alternate Price";
      } else {
        labelValueInshell = "Price";
      }
    } else {
      labelValueInshell = "Inshell";
    }
    this.state = {
      items: props.data.children,
      labelValueInshell: labelValueInshell,
      labelValueShell: labelValueShell,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = (e) => {
    this.props.handleDelete(e);
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        cell: (row) => {
          return this.props.readOnly === true ||
            this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            row.varietyName
          ) : (
            <a
              onClick={() => this.props.handleShow(row)}
              href="#"
              title="Edit base price"
            >
              {row.varietyName}
            </a>
          );
        },
        omit: this.props.testType.varietyOptional,
      },
      {
        name: "Primary Price",
        selector: "primaryPrice",
        sortable: true,
        center: true,
        omit: this.props.testType.isDynamic,
      },
      {
        name: this.state.labelValueInshell,
        selector: "valueInshell",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueInshell == null ||
              row.valueInshell === undefined ||
              isNaN(row.valueInshell)
              ? 0
              : parseFloat(row.valueInshell)
            )
          ),
        width: "160px",
      },
      {
        name: this.state.labelValueShell,
        selector: "valueShelled",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueShelled == null ||
              row.valueShelled === undefined ||
              isNaN(row.valueShelled)
              ? 0
              : parseFloat(row.valueShelled)
            )
          ),
        omit:
          this.props.testType.isDynamic && this.props.testType.xpool !== true,
      },
      {
        name: "Meta Attribute",
        selector: "customAttributes",
        sortable: false,
        cell: (row) => {
          let customAttributesText = ``;
          if (row.customAttributes && row.customAttributes !== "") {
            const customAttributes = JSON.parse(row.customAttributes);
            if (customAttributes && customAttributes.length > 0) {
              customAttributes.map((x) => {
                if (customAttributesText !== ``) {
                  customAttributesText += `, `;
                }
                customAttributesText += `${x.attribute} (${x.value})`;
              });
            }
          }
          return customAttributesText;
        },
        omit: !this.props.testType.isDynamic,
      },
      {
        name: "Payment",
        selector: "paymentName",
        sortable: false,
        omit: !this.props.testType.basePriceByPayment,
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.modifiedDate
            ? DateFormat(row.modifiedDate, "mm/dd/yyyy hh:MM TT")
            : "";
        },
        width: "160px",
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.createdDate, "mm/dd/yyyy hh:MM TT");
        },
        width: "160px",
      },
      {
        omit: this.props.readOnly,
        name: "",
        cell: (row) => (
          <div>
            {this.props.loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
              ""
            ) : (
              <>
                <a
                  title="Edit base price"
                  href="#"
                  onClick={() => this.props.handleShow(row)}
                  className="on-default edit-row"
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <a
                  title="Remove base price"
                  href="#"
                  className="on-default remove-row"
                  data-id={row._id}
                  data-growerid={row.growerId}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
              </>
            )}

            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
        width: "100px",
      },
    ];
  };

  render() {
    return (
      <>
        <DataTable
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          pagination={true}
          noDataComponent=""
          noTableHead={false}
        />
      </>
    );
  }
}
export default App;
