import React from "react";
import ListComponentPayment from "../Base/ListComponentPayment";
import { Button, Form, Row, Modal } from "react-bootstrap";
import { getLoggedUser } from "../../context/auth";
import NumericInput from "../FormControl/NumericInput";
import moment from "moment";
import ListGrower from "../Grower/ListGrower";
import SelectGrowers from "../Grower/SelectGrowers";
import DataTable from "react-data-table-component";
import SubTable from "../PaymentSetting/SubTable";
import CommonUtilities from "../Utilities/Common";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { USER_ROLE_ADMINISTRATOR, METHOD_POST } from "../Utilities/Constant";
import SelectMultiGrowers from "../Grower/SelectMultiGrowers";
import lodash from "lodash";
import MultiSelectVarieties from "../TestType/MultiSelectVarieties";
import SaveButton from "../Controls/SaveButton";
import Template from "./Template";
import NodeUuid from "node-uuid";
import { parseViewModel, wizardColumns } from "./WizardHandler";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponentPayment {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      popupUrlApi: GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL,
      parentId: props.match.params.id,
      urlapi: GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL,
      urllist:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
        "/" +
        props.match.params.id,
      urledit:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
        "-edit/",
      urladd:
        process.env.REACT_APP_URL_CROPYEAR_LIST +
        process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
        "-add/" +
        props.match.params.id,
      title: "Payment Setting",
      pluraltitle: "Payment Settings",
      labeladd: "Payment",
      pagination: false,
      excludeDefaultButtons: true,
      expandableRows: true,
      viewMode: "edit",
      isExpand: false,
      isUseHeading: false,
      cropYearName: "",
      progressPending: false,

      //State using for popup add/edit payment setting
      data: {
        _id: "",
        parentId: props.match.params.id,
        growerId: "",
        paymentDate: "",
        paymentPercent: 0,
        varietyId: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      previousGrowerId: "",
      isChanged: false,
      errorMessage: "",
      color: "red",
      isEditMode: true,
      //This state is used for bulk apply
      showBulkApply: false,
      showAddPaymentsModal: false,
      isAdd: true,
      payments: [],
      payment: {
        _id: "",
        parentId: props.match.params.id,
        growerId: "",
        paymentDate: "",
        paymentPercent: 0,
        varietyId: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      paymentPercentError: "Payment percent must be greater than zero",
      paymentExistError: "Payment date is already exist",
      selectedGrowers: [],
      isLoadedTabTemplate: false,
      //This state is using for remove row later
      removedGrowerId: "",
      invalidGrowers: [],
      showError: false,
      showRefresh2: true,

      initDate: new Date(),
      saveLoading: false,
      //This state used for Wizard mode
      wizardMode: false,
      isWizardEdit: false,

      testType: {
        xpool: false,
        isDynamic: false,
        _id: "",
        basePriceByPayment: false,
        paymentPercentBased: true,
        paymentByVariety: false,
      },
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onChangePaymentDate = this.onChangePaymentDate.bind(this);
    this.onChangePaymentPercent = this.onChangePaymentPercent.bind(this);
    this.openAddPaymentPopup = this.openAddPaymentPopup.bind(this);
    this.openEditPaymentPopup = this.openEditPaymentPopup.bind(this);
    this.showPaymentPopup = this.showPaymentPopup.bind(this);
    this.showBulkApplyPopup = this.showBulkApplyPopup.bind(this);
    this.hidePaymentPopup = this.hidePaymentPopup.bind(this);
    this.hideBulkApplyPopup = this.hideBulkApplyPopup.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.handleEditPayment = this.handleEditPayment.bind(this);
    this.handleApplyPaymentsForGrowers =
      this.handleApplyPaymentsForGrowers.bind(this);
    this.onChangeSelectedGrowers = this.onChangeSelectedGrowers.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
  }

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  getItems = async () => {
    const that = this;
    that.setState({
      progressPending: true,
    });
    fetch(this.state.urlapi + "parent/" + this.state.parentId)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.cropYear) {
          const cropYearName =
            resultObject.cropYear.name +
            " (" +
            resultObject.cropYear.startDate +
            "-" +
            resultObject.cropYear.endDate +
            ")";

          const handleTruncate = that.truncateTitle(
            cropYearName,
            that.setTitleLength()
          );

          that.setState({
            pluraltitle: "Payment Settings - Crop Year " + handleTruncate,
            cropYearName: cropYearName,
          });
        }

        that.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            growers: [],
            progressPending: false,
          },
          function callBack() {
            that.refreshTable();
          }
        );
      })
      .catch(function (error) {
        console.log(error);
        that.setState({
          progressPending: false,
        });
      });
  };

  conditionalRowStyles = () => {
    return this.state.testType.basePriceByPayment === true
      ? [
          {
            when: (row) => row.growerId === "",
            style: {
              backgroundColor: "#33ffcc",
              color: "black",
            },
            when: (row) => row.paymentPercent !== 100,
            style: {
              backgroundColor: "#33ffcc",
              color: "black",
            },
          },
        ]
      : [
          {
            when: (row) => row.growerId === "",
            style: {
              backgroundColor: "#33ffcc",
              color: "black",
            },
            when: (row) => row.paymentPercent !== 100,
            style: {
              color: "red",
            },
          },
        ];
  };

  setColumnsInvalidPopup = () => {
    return [
      {
        name: "Default/Grower",
        selector: "growerName",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.growerId === undefined ||
            row.growerId === null ||
            row.growerId === "" ? (
            row.growerName
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.growerName}
            >
              {row.growerName}
            </a>
          );
        },
      },
      {
        name: "Total Percent",
        selector: "total",
        sortable: true,
        right: true,
        cell: (row) => {
          return parseFloat(row.total).toFixed(1) + "%";
        },
      },
      {
        name: "Reason",
        selector: "total",
        sortable: true,
        right: true,
        cell: (row) => {
          return "Missing " + parseFloat(100 - row.total).toFixed(1) + "%";
        },
      },
    ];
  };

  setColumns = () => {
    return [
      {
        name: "Default/Grower",
        selector: "",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.growerId === "" ? (
            "Default"
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {row.grower}
            </a>
          );
        },
        width: "200px",
      },
      {
        name: "Payment Percent",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        cell: (row) => {
          const paymentPercent = (
            row.paymentPercent == null ||
            row.paymentPercent === undefined ||
            isNaN(row.paymentPercent)
              ? 0
              : parseFloat(row.paymentPercent)
          ).toFixed(1);
          return CommonUtilities.numberWithCommas(paymentPercent) + "%";
        },
        width: "150px",
        omit: this.state.testType.basePriceByPayment === true,
      },
      {
        name: "",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        cell: (row) => {
          return row.paymentPercent !== 100
            ? "Warning: Growers without 100% assigned payments will be excluded from actual payments."
            : "";
        },
        omit: this.state.testType.basePriceByPayment === true,
      },
      {
        name: "",
        selector: "paymentPercent",
        sortable: true,
        right: true,
        omit: this.state.testType.basePriceByPayment === true,
      },
    ];
  };

  defaultTable = () => {
    return (
      <div className="tabs">
        <ul className="nav nav-tabs tabs-primary">
          <li className="active">
            <a href="#Default" data-toggle="tab">
              Payment Settings
            </a>
          </li>
          <li>
            <a
              href="#Template"
              data-toggle="tab"
              onClick={() => {
                if (this.state.isLoadedTabTemplate === false) {
                  this.template.getItems();
                  this.template.getGrowers();
                  this.setState({
                    isLoadedTabTemplate: true,
                  });
                }
              }}
            >
              Templates
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="Default"
            className="tab-pane active"
            style={{ margin: "-10px" }}
          >
            <DataTable
              key="editTable"
              id="editTable"
              title=""
              noHeader={true}
              columns={this.setColumns()}
              data={this.state.items}
              className="table table-bordered table-striped mb-none"
              pagination={this.state.pagination}
              paginationPerPage={10}
              expandableRows={this.state.expandableRows}
              expandableRowsComponent={
                <SubTable
                  handleDelete={this.handleDelete.bind(this)}
                  handleShow={this.handleShow}
                  expandableRowExpanded={(row) => {
                    return this.state.isExpand;
                  }}
                  loggedUser={loggedUser}
                  testType={this.state.testType}
                />
              }
              conditionalRowStyles={this.conditionalRowStyles()}
              noTableHead={true}
              expandableRowExpanded={(row) => {
                return this.state.isExpand;
              }}
              progressPending={this.state.progressPending}
              progressComponent={this.defaultProgressComponent()}
            />
          </div>
          <div id="Template" className="tab-pane" style={{ margin: "-10px" }}>
            <Template
              onRef={(ref) => (this.template = ref)}
              cropYearId={this.props.match.params.id}
              loggedUser={loggedUser}
              processorId={loggedUser.processorId}
              testTypeId={this.state.testType._id}
              testType={this.state.testType}
              refreshMainTab={this.getItems}
            />
          </div>
        </div>
      </div>
    );
  };

  extendButtons = () => {
    return (
      <>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back Crop Years"
          className="btn btn-primary mb-sm"
        >
          <i className="fa fa-backward fa-lg"></i> Back Crop Years
        </a>
        <button
          type="button"
          onClick={() => {
            this.setState({
              isExpand: this.state.isExpand === true ? false : true,
            });
          }}
          className="btn btn-primary mb-sm"
        >
          <i
            className={
              this.state.isExpand === true
                ? "fa fa-angle-down fa-lg"
                : "fa fa-angle-right fa-lg"
            }
          ></i>{" "}
          {this.state.isExpand === true ? "Collapse All" : "Expand All"}
        </button>

        {this.state.viewMode === "view" ? (
          ""
        ) : loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
          ""
        ) : (
          <>
            <button
              type="button"
              onClick={() =>
                this.handleShow({
                  _id: "",
                  parentId: this.state.parentId,
                  paymentDate: moment(new Date()).format("L"),
                  paymentPercent: 0,
                  varietyId: "",
                  growerId: "",
                  createdUserId: loggedUser._id,
                  createdDate: new Date(),
                  modifiedUserId: "",
                  modifiedDate: null,
                })
              }
              className="btn btn-primary mb-sm"
            >
              <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
            </button>
            <button
              type="button"
              onClick={() => this.handleApplyGrowers()}
              title="Allow copy default settings for growers or reset custom settings back to default settings."
              className="btn btn-info mb-sm"
            >
              <i className="fa fa-users fa-lg"></i> Apply Growers
            </button>
            <button
              type="button"
              onClick={this.showBulkApplyPopup}
              title="Allow create many payment settings for many growers."
              className="btn btn-primary mb-sm"
            >
              <i className="fa fa-plus-square" aria-hidden="true"></i> Bulk
              Apply
            </button>
            {this.state.testType.basePriceByPayment === true ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => this.handleValidate()}
                title="Check payment settings."
                className="btn btn-warning mb-sm"
              >
                <i className="fa fa-bolt fa-lg"></i> Validate
              </button>
            )}
          </>
        )}
      </>
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Can't be blank", "error");
    }
  }

  changeWizardMode = (toggle) => {
    this.setState({
      wizardMode: toggle,
    });
  };

  filterItems = ({ testType, varietyIds, existItems, compareObj }) => {
    const dateCompare = moment(compareObj.paymentDate).format("L");
    let itemsByParent = [];
    if (existItems && existItems.length > 0) {
      if (testType.paymentByVariety === true) {
        itemsByParent = existItems.filter(({ varietyId = "", paymentDate }) => {
          return (
            varietyIds?.includes(varietyId?.toString()) &&
            moment(paymentDate).format("L") === dateCompare
          );
        });
      } else {
        itemsByParent = existItems.filter(({ paymentDate }) => {
          return moment(paymentDate).format("L") === dateCompare;
        });
      }
    }

    return itemsByParent;
  };

  extendRender = () => {
    return (
      <>
        <Modal
          show={this.state.showError}
          onHide={this.handleCloseError}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>Invalid Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ margin: "-15px", marginBottom: "-30px" }}>
            <div className="alert alert-success" role="alert">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="alert-heading">Why invalid?</h4>
              <p>
                <ul>
                  <li>Total payment percent of default/grower must be 100%.</li>
                </ul>
              </p>
            </div>
            <div
              style={{
                overflowY: "auto",
                position: "relative",
                maxHeight: "500px",
              }}
            >
              <DataTable
                highlightOnHover
                striped
                noHeader={true}
                columns={this.setColumnsInvalidPopup()}
                data={this.state.invalidGrowers}
                className="table table-bordered table-striped mb-none"
                pagination={false}
              ></DataTable>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button variant="warning" onClick={this.handleCloseError}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <ListGrower
          testType={this.state.testType}
          parentId={this.state.parentId}
          value=""
          onRef={(ref) => (this.listGrower = ref)}
          handleChangeListGrower={this.handleChangeListGrower}
          urlApi={this.state.popupUrlApi}
          processorId={loggedUser.processorId}
          userId={loggedUser._id}
          checkExistPaymentSetting={false}
          isWizard={true}
          loggedUser={loggedUser}
          baseApiUrl={GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL}
          handleShowWithWizardMode={this.handleShowWithWizardMode}
          changeWizardMode={this.changeWizardMode}
          parseViewModel={parseViewModel}
          columns={wizardColumns(
            (row, index) => {
              if (this.listGrower) {
                this.listGrower.setEditedIndex(index);
              }
              this.handleShowWithWizardMode(row);
            },
            this.state.testType,
            loggedUser,
            (index) => {
              if (this.listGrower) {
                this.listGrower.removeRow(index);
              }
            }
          )}
        />
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {this.state.data._id === null ||
                this.state.data._id === undefined ||
                this.state.data._id === ""
                  ? "Add Payment Setting"
                  : "Edit Payment Setting"}{" "}
                {this.state.cropYearName !== ""
                  ? " - " + this.state.cropYearName
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  {!this.state.wizardMode && (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="attribute"
                      >
                        Default/Grower <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <SelectGrowers
                          required={false}
                          onRef={(ref) => (this.growerId = ref)}
                          name="growerId"
                          value={this.state.data.growerId}
                          selectedIds={this.state.data.growerId}
                          handleChangeSelectGrower={
                            this.handleChangeSelectGrower
                          }
                          testTypeId={this.state.testType._id}
                          cropYearId={this.state.parentId}
                          checkExistPaymentSetting={false}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.testType.paymentByVariety === true ? (
                    <div className="form-group">
                      <label
                        className="col-md-4 control-label"
                        htmlFor="attribute"
                      >
                        Variety <span className="required">(*)</span>
                      </label>
                      <div className="col-md-8">
                        <MultiSelectVarieties
                          isMulti={
                            (this.state.wizardMode === false &&
                              (this.state.data._id == null ||
                                this.state.data._id === undefined ||
                                this.state.data._id === "")) ||
                            (this.state.wizardMode && !this.state.isWizardEdit)
                          }
                          showSelectAll
                          required={true}
                          onRef={(ref) => (this.varietyId = ref)}
                          name="varietyId"
                          controlId="varietyId"
                          selectedIds={this.state.data.varietyId}
                          handleChangeSelectControl={
                            this.handleChangeSelectControl
                          }
                          urlApi={
                            GlobalConfig.REACT_APP_API_VARIETY_URL +
                            "byParentId/" +
                            this.state.testType._id
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      className="col-md-4 control-label"
                      htmlFor="attribute"
                    >
                      Payment Date <span className="required">(*)</span>
                    </label>
                    <div className="col-md-5">
                      <div className="input-daterange input-group">
                        <span className="input-group-addon">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          className="form-control"
                          placeholderText="Enter datetime"
                          selected={
                            this.state.data.paymentDate
                              ? new Date(this.state.data.paymentDate)
                              : this.state.initDate
                          }
                          onChange={this.handleDateChange}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.testType.basePriceByPayment === true ? (
                    ""
                  ) : (
                    <div className="form-group">
                      <NumericInput
                        precision={1}
                        min={0}
                        max={100}
                        step={0.1}
                        label="Payment Percent"
                        name="paymentPercent"
                        value={this.state.data.paymentPercent}
                        handleChange={this.handleChangeObjectData}
                        unit="%"
                        labelClass="4"
                        inputClass="5"
                        required={false}
                        labelrequired=""
                      />
                    </div>
                  )}
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected payment setting no longer exists. Please refresh
                    and try again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-center">
              {this.state.data ? (
                <SaveButton loading={this.state.saveLoading} />
              ) : (
                ""
              )}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          show={this.state.showBulkApply}
          onHide={this.hideBulkApplyPopup}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          className="bulk-apply-payments-modal"
          centered
        >
          <Modal.Header>
            <Modal.Title>Bulk Apply</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bulk-apply-body">
            <div className="bulk-apply-button-group">
              <Button
                className="add-payment-button"
                variant="primary"
                onClick={this.openAddPaymentPopup}
              >
                <i className="fa fa-plus fa-lg"></i> Add
              </Button>
            </div>
            {this.state.payments.length > 0 && (
              <>
                <DataTable
                  key="bulk-aplly"
                  id="bulk-apply"
                  columns={this.getPaymentColumns()}
                  noHeader={true}
                  highlightOnHover
                  striped
                  data={this.state.payments}
                ></DataTable>
                <SelectMultiGrowers
                  selectedGrowers={this.state.selectedGrowers}
                  onChangeSelectedGrowers={this.onChangeSelectedGrowers}
                  api={`${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersByProcessorAndTestType/${loggedUser.referenceUserId}/${this.state.testType._id}`}
                ></SelectMultiGrowers>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="bulk-apply-payments-modal-footer">
            <SaveButton
              text={"Apply"}
              type={"button"}
              icon={
                <span
                  className="fa fa-check fa-lg"
                  style={{ marginRight: 3 }}
                ></span>
              }
              disabled={
                this.state.payments.length > 0 &&
                this.state.selectedGrowers.length > 0 &&
                this.state.saveLoading === false
                  ? false
                  : true
              }
              loading={this.state.saveLoading}
              onClick={this.handleApplyPaymentsForGrowers}
            />
            <Button
              style={{ margin: "0 5px" }}
              variant="warning"
              onClick={this.hideBulkApplyPopup}
            >
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showAddPaymentsModal}
          onHide={this.hidePaymentPopup}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          className="add-payments-modal"
          centered
        >
          <Modal.Header>
            <Modal.Title>Add Payment Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="col-md-4 control-label" htmlFor="attribute">
                Payment Date <span className="required">(*)</span>
              </label>
              <div className="col-md-8">
                <div
                  className="input-daterange input-group"
                  style={{ width: "100%" }}
                >
                  <span className="input-group-addon">
                    <i className="fa fa-calendar"></i>
                  </span>
                  <DatePicker
                    className="form-control"
                    placeholderText="Enter datetime"
                    selected={
                      this.state.payment.paymentDate
                        ? new Date(this.state.payment.paymentDate)
                        : new Date()
                    }
                    onChange={this.onChangePaymentDate}
                    onBlur={this.handleOnBlur}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <NumericInput
                precision={1}
                min={0}
                max={100}
                step={0.1}
                label="Payment Percent"
                name="paymentPercent"
                value={this.state.payment.paymentPercent}
                handleChange={this.onChangePaymentPercent}
                unit="%"
                labelClass="4"
                inputClass="8"
                required={true}
                labelrequired="{*}"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="add-payments-modal-footer">
            {this.state.isAdd ? (
              <Button
                style={{ margin: "0 5px" }}
                variant="primary"
                type="button"
                onClick={this.handleAddPayment}
              >
                <span className="fa fa-plus fa-lg"></span> Add
              </Button>
            ) : (
              <Button
                style={{ margin: "0 5px" }}
                variant="success"
                type="button"
                onClick={this.handleEditPayment}
              >
                <span className="fa fa-check fa-lg"></span> Save
              </Button>
            )}
            <Button
              style={{ margin: "0 5px" }}
              variant="warning"
              onClick={this.hidePaymentPopup}
            >
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  getPaymentColumns = () => {
    const columns = [
      {
        name: "Payment Date",
        center: true,
        cell: (payment) => moment(payment.paymentDate).format("L"),
      },
      {
        name: "Payment Percent",
        right: true,
        cell: (payment) => payment.paymentPercent + "%",
      },
      {
        name: "",
        right: true,
        cell: (payment, index) => (
          <div className="test-type-action-table" style={{ color: "blue" }}>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.openEditPaymentPopup(payment, index)}
            >
              <span>
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </span>
            </div>
            <div
              style={{ cursor: "pointer", color: "#1891E5" }}
              onClick={() => this.handleRemovePayment(index)}
            >
              <span>
                <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        ),
      },
    ];
    return columns;
  };

  handleShowWithWizardMode = (data) => {
    let isWizardEdit = data ? true : false;
    const dataModel = data
      ? data
      : {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          paymentDate: moment(new Date()).format("L"),
          paymentPercent: 0,
          varietyId: this.state.previousVarietyId,
        };
    var convertedJSON = JSON.parse(JSON.stringify(dataModel));
    if (dataModel.paymentDate) {
      convertedJSON.paymentDate = moment(convertedJSON.paymentDate).format(
        "MM/DD/YYYY"
      );
    } else {
      convertedJSON.paymentDate = moment(this.state.initDate).format(
        "MM/DD/YYYY"
      );
    }
    this.setState({ data: convertedJSON }, () => {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isWizardEdit: isWizardEdit,
        },
        () => {
          //Set selected dropdown
          if (this.varietyId) {
            this.varietyId.setSelected(convertedJSON.varietyId);
          }
        }
      );
    });
  };

  handleShow = (data) => {
    var convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }
    if (data.paymentDate) {
      convertedJSON.paymentDate = moment(convertedJSON.paymentDate).format(
        "MM/DD/YYYY"
      );
    } else {
      convertedJSON.paymentDate = moment(this.state.initDate).format(
        "MM/DD/YYYY"
      );
    }
    this.setState(
      { data: convertedJSON, wizardMode: false },
      function callBack() {
        this.setState(
          {
            show: true,
            errorMessage: "",
            isChanged: false,
            isEditMode: isEditMode,
          },
          () => {
            //Set selected dropdown
            if (this.varietyId) {
              this.varietyId.setSelected(data.varietyId);
            }
          }
        );
      }
    );
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
      saveLoading: true,
    });

    const paymentDate = this.state.data.paymentDate;
    let errorMessage = "";
    const varietyId = this.state.data.varietyId;
    if (
      this.state.testType.paymentByVariety === true &&
      (varietyId === null || varietyId === undefined || varietyId.trim() === "")
    ) {
      errorMessage = "Select variety to continue.";
    }
    if (errorMessage === ``) {
      if (
        paymentDate == null ||
        paymentDate === undefined ||
        paymentDate === ""
      ) {
        errorMessage = "Enter payment date to continue.";
      } else {
        if (!CommonUtilities.isValidDate(paymentDate)) {
          errorMessage = "Format date must be MM/DD/YYYY";
        }
      }
    }
    if (errorMessage !== "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
        saveLoading: false,
      });
      return;
    }
    if (this.state.wizardMode) {
      this.saveWizard();
    } else {
      this.save();
    }
  };

  save = async () => {
    //Using for edit
    let urlApi =
      GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL + this.state.data._id;
    let method = "PATCH";
    let isEditMode = true;
    if (
      this.state.data._id == null ||
      this.state.data._id === undefined ||
      this.state.data._id === ""
    ) {
      //Using for create new
      method = "POST";
      urlApi = GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL;
      isEditMode = false;
    }
    let requestData = Object.assign({}, this.state.data, {});
    requestData = JSON.parse(JSON.stringify(requestData));
    requestData.testType = this.state.testType;

    const requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    };

    const response = await fetch(urlApi, requestOptions);
    const resultObject = await response.json();

    if (resultObject.result === "OK") {
      window.showAlert("", "Save successful!", "");
      this.setState(
        {
          isChanged: true,
          saveLoading: false,
          previousGrowerId: this.state.data.growerId,
          previousVarietyId: this.state.data.varietyId,
        },
        function callBack() {
          this.getItems();
          if (!isEditMode) {
            this.handleResetForm();
          }
        }
      );
    } else {
      window.showAlert("Error", resultObject.message, "error");
      this.setState({
        saveLoading: false,
      });
    }
  };

  saveWizard = () => {
    if (this.state.isWizardEdit) {
      let dataObj = {
        ...this.state.data,
      };
      if (this.state.testType.paymentByVariety) {
        const variety = this.varietyId.state.data.find(
          (variety) => variety._id === this.state.data.varietyId
        );
        const varietyName = variety ? variety.name : "";
        dataObj.varietyName = varietyName;
      }
      const existItems = this.listGrower.state.defaultSettings.filter(
        (_, index) => index !== this.listGrower.state.editedIndex
      );
      let arrVarietyId = !!dataObj.varietyId
        ? dataObj.varietyId.split(",")
        : [];
      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        existItems: existItems,
        compareObj: {
          paymentDate: dataObj.paymentDate,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: false,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other date.",
          "error"
        );
        return;
      }
      if (this.state.testType.paymentByVariety !== true) {
        dataObj.varietyId = ``;
      }
      this.listGrower.editRow(dataObj);
      this.setState({
        show: false,
        saveLoading: false,
      });
    } else {
      //Add new setting from popup copy wizard
      let { paymentDate, paymentPercent, varietyId } = this.state.data;
      let arrVarietyId = !!varietyId ? varietyId.split(",") : [];
      const existItems = this.listGrower.state.defaultSettings;

      const itemsByParent = this.filterItems({
        testType: this.state.testType,
        varietyIds: arrVarietyId,
        existItems,
        compareObj: {
          paymentDate,
        },
      });
      if (itemsByParent && itemsByParent.length > 0) {
        this.setState({
          saveLoading: false,
        });
        window.showAlert(
          "Error",
          "Setting values is duplicated. Please try with other values.",
          "error"
        );
        return;
      }
      if (
        arrVarietyId === undefined ||
        arrVarietyId === null ||
        arrVarietyId.length <= 0
      ) {
        arrVarietyId = [{ undefined }];
      }
      const newItemList = arrVarietyId.map((varietyId) => {
        let varietyName = "";
        if (this.state.testType.paymentByVariety === true) {
          const variety = this.varietyId.state.data.find(
            (variety) => variety._id === varietyId
          );
          varietyName = variety ? variety.name : "";
        }
        if (this.state.testType.paymentByVariety !== true) {
          varietyId = ``;
        }
        let newObj = {
          _id: NodeUuid.v4(),
          paymentDate: paymentDate,
          paymentPercent: paymentPercent,
          varietyId: varietyId,
          varietyName: varietyName,
        };
        if (this.state.testType.paymentByVariety === true) {
          newObj = JSON.parse(JSON.stringify(newObj));
          newObj.varietyId = varietyId;
        }
        return newObj;
      });
      this.listGrower.appendRows(newItemList);
      this.setState({
        show: false,
        saveLoading: false,
      });
    }
  };

  doSomethingOnParent(parent) {
    let totalPaymentPercent = 0;
    if (parent) {
      if (parent.children && parent.children.length > 0) {
        parent.children.map((x) => {
          totalPaymentPercent += x.paymentPercent;
        });
      }
      parent.paymentPercent = totalPaymentPercent;
    }
    return parent;
  }

  handleResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          parentId: this.state.parentId,
          growerId: this.state.previousGrowerId,
          paymentDate: moment(new Date()).format("L"),
          paymentPercent: 0,
          varietyId: this.state.previousVarietyId,
          createdUserId: loggedUser._id,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        //Set selected dropdown
        if (this.varietyId) {
          this.varietyId.setSelected(this.state.previousVarietyId);
        }
        this.growerId.setSelected(this.state.previousGrowerId);
      }
    );
  };

  handleCloseError = () => {
    this.setState({ showError: false });
  };

  handleValidate = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          parentId: this.state.parentId,
        }),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL + "validatepayment/",
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject.result === "OK") {
            if (resultObject.data && resultObject.data.length > 0) {
              that.setState({
                showError: true,
                invalidGrowers: resultObject.data,
              });
            } else {
              window.showAlert("", "Payment settings is valid.", "");
            }
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleApplyGrowers = async () => {
    this.openPopupGrowers();
  };

  handleApplyPaymentsForGrowers = () => {
    if (this.state.payments.length <= 0) {
      return;
    }
    const payments = this.initPayments();
    const body = JSON.stringify({
      payments: payments,
      processorId: loggedUser.processorId,
      createdUserId: loggedUser._id,
      parentId: this.state.parentId,
    });
    const requestOptions = {
      method: METHOD_POST,
      headers: { "Content-Type": "application/json" },
      body: body,
    };
    const api = GlobalConfig.REACT_APP_API_PAYMENTSETTING_URL + "payments";
    this.setState({ saveLoading: true }, async () => {
      const response = await fetch(api, requestOptions);
      const data = await response.json();
      if (data.result === "OK" && data.pass && data.fail) {
        await this.updatePaymentsAfterApply(data.pass);
        this.showNotifications(data, payments);
      } else {
        window.showAlert("Error", `${data.message}`, "error");
      }
      this.setState({ saveLoading: false });
    });
  };

  showNotifications = (data, payments) => {
    //data.pass are payments which are successfully saved
    if (data.pass.length > 0) {
      window.showAlert(
        "",
        `${data.pass.length}/${payments.length} ` + "was successfully saved!",
        ""
      );
    }
    if (data.fail.length > 0) {
      if (data.fail.length === payments.length) {
        window.showAlert("Error", `All of payments are already exist`, "error");
      } else {
        data.fail.map((payment) => {
          window.showAlert(
            "Error",
            `${moment(payment.paymentDate).format(
              "L"
            )} is already exists with ${
              payment.grower ? payment.grower : "Default"
            }`,
            "error"
          );
        });
      }
    }
  };

  updatePaymentsAfterApply = (payments) => {
    if (payments.length <= 0) return;
    const paymentGroups = [...this.state.items];
    const groupByGrowerId = lodash.groupBy(payments, "growerId");
    for (let growerId in groupByGrowerId) {
      const index = paymentGroups.findIndex(
        (group) => group.growerId === growerId
      );
      if (index !== -1) {
        paymentGroups[index].children = paymentGroups[index].children.concat(
          paymentGroups[index].growerId === "" || paymentGroups.grower === ""
            ? groupByGrowerId[growerId].map((payment) => {
                delete payment.growerId;
                return payment;
              })
            : groupByGrowerId[growerId]
        );
        const totalPercent = this.calculateTotalPercent(
          paymentGroups[index].children
        );
        paymentGroups[index].paymentPercent = totalPercent;
      } else {
        const totalPercent = this.calculateTotalPercent(
          groupByGrowerId[growerId]
        );
        const newGroup = {
          children: groupByGrowerId[growerId],
          growerId: growerId,
          grower: groupByGrowerId[growerId][0].grower,
          status: groupByGrowerId[growerId][0].status,
          paymentPercent: totalPercent,
        };
        if (growerId === "") {
          paymentGroups.unshift(newGroup);
        } else {
          paymentGroups.push(newGroup);
        }
      }
    }
    this.setState(
      {
        isExpand: true,
        items: paymentGroups,
      },
      () => {
        this.setState(
          {
            isExpand: false,
          },
          () => {
            this.setState({
              isExpand: true,
            });
          }
        );
      }
    );
  };

  calculateTotalPercent = (payments) => {
    let total = 0;
    if (!payments || !payments.length > 0) return total;
    payments.forEach((payment) => {
      total += payment.paymentPercent;
    });
    return total;
  };

  initPayments = () => {
    let payments = [];
    this.state.selectedGrowers.forEach((grower) => {
      this.state.payments.forEach((payment) => {
        payments.push({
          ...payment,
          growerId: grower.value,
          grower: grower.label,
          paymentDate: this.convertDateToNumber(payment.paymentDate),
        });
      });
    });
    return payments;
  };

  showBulkApplyPopup() {
    this.setState({
      showBulkApply: true,
    });
  }

  hideBulkApplyPopup() {
    this.setState({
      showBulkApply: false,
      selectedGrowers: [],
      payments: [],
    });
  }

  showPaymentPopup() {
    this.setState({
      showAddPaymentsModal: true,
    });
  }

  hidePaymentPopup() {
    this.setState({
      showAddPaymentsModal: false,
    });
  }

  onChangePaymentDate(date) {
    const formatDate = moment(date).format("L");
    if (date) {
      this.setState({
        payment: { ...this.state.payment, paymentDate: formatDate },
      });
    }
  }

  convertDateToNumber(date) {
    const momentTypeDate = moment(date, "MM/DD/YYYY");
    return new Date(
      momentTypeDate.year(),
      momentTypeDate.month(),
      momentTypeDate.date()
    ).setHours(23, 59, 59);
  }

  onChangePaymentPercent(e) {
    if (e.target) {
      this.setState({
        payment: { ...this.state.payment, paymentPercent: e.target.value },
      });
    }
  }

  onChangeSelectedGrowers(growers) {
    this.setState({
      selectedGrowers: growers,
    });
  }

  handleAddPayment() {
    if (this.state.payment.paymentPercent === 0) {
      window.showAlert("ERROR", this.state.paymentPercentError, "error");
      return;
    }
    let newPayments = [...this.state.payments];
    let newPayment = this.state.payment;
    if (newPayment.paymentDate === "") {
      newPayment.paymentDate = moment(new Date()).format("L");
    }

    //Check payment date is exist or not
    if (
      newPayments.some(
        (payment) => payment.paymentDate === newPayment.paymentDate
      )
    ) {
      window.showAlert(
        "ERROR",
        newPayment.paymentDate + " " + this.state.paymentExistError,
        "error"
      );
      return;
    }

    newPayment.createdDate = new Date();
    newPayment = JSON.parse(JSON.stringify(newPayment));
    newPayments.push(newPayment);
    this.setState(
      {
        payments: newPayments,
      },
      () => {
        this.resetPayment();
        this.hidePaymentPopup();
      }
    );
  }

  handleEditPayment() {
    if (this.state.payment.paymentPercent === 0) {
      window.showAlert("ERROR", this.state.paymentPercentError, "error");
      return;
    }
    let newPayments = [...this.state.payments];
    if (
      this.state.editingPaymentIndex !== null &&
      this.state.editingPaymentIndex !== undefined
    ) {
      newPayments[this.state.editingPaymentIndex] = this.state.payment;
      this.setState(
        {
          payments: newPayments,
        },
        () => this.hidePaymentPopup()
      );
    }
  }

  handleRemovePayment(index) {
    let newPayments = [...this.state.payments].filter((_, i) => i !== index);
    this.setState({
      payments: newPayments,
    });
  }

  openAddPaymentPopup() {
    this.resetPayment();
    this.setState(
      {
        isAdd: true,
      },
      () => {
        this.showPaymentPopup();
      }
    );
  }

  openEditPaymentPopup(editingPayment, index) {
    this.setState(
      {
        isAdd: false,
        payment: editingPayment,
        editingPaymentIndex: index,
      },
      () => this.showPaymentPopup()
    );
  }

  resetPayment() {
    this.setState({
      payment: {
        _id: "",
        parentId: this.props.match.params.id,
        growerId: "",
        paymentDate: moment(new Date()).format("L"),
        paymentPercent: 0,
        varietyId: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
  }
}
export default App;
