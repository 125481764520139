import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import NumericInput from "../FormControl/NumericInput";
import CommonUtilities from "../Utilities/Common";
import Switch from "react-switch";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      title: "Processor",
      listtitle: "Processors",
      urllist: process.env.REACT_APP_URL_PROCESSOR_LIST,
      urlapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageMaxSize: parseInt(process.env.REACT_APP_MAX_SIZE_PROFILE_IMAGE),
      defaultCompanyPhoto: process.env.REACT_APP_DEFAULT_COMPANY_PHOTO,
      data: {
        _id: "",
        name: "",
        email: "",
        avatar: "",
        avatarExtension: "",
        address: "",
        businessName: "",
        businessAddress: "",
        phoneNumber: "",
        dayOfDelayRelease: 0,
        companyName: "",
        certificateNo: "",
        certificatePrefix: "",
        certificateSeed: "",
        status: "Not Activate",
        isContractCashPrice: false,
        isUseDetailedGrowerStatement: false,
        showImportResult: false,
        allowNotifyImportResult: false,
        notifyImportResult: false,
        tierId: "",
        activationUrl:
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_URL_ACTIVATION +
          "?token=",
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      tiers: [],
      errors: {
        email: "",
      },
      loading: parentState.loading,
    };

    this.handleContractCashPriceChangeSwitch =
      this.handleContractCashPriceChangeSwitch.bind(this);
    this.handleUseDetailedGrowerStatementChangeSwitch =
      this.handleUseDetailedGrowerStatementChangeSwitch.bind(this);
    this.handleShowImportResultChangeSwitch =
      this.handleShowImportResultChangeSwitch.bind(this);
    this.handleAllowNotifyImportResultChangeSwitch =
      this.handleAllowNotifyImportResultChangeSwitch.bind(this);
  }

  componentDidMount() {
    this.getTiers();
  }

  handleContractCashPriceChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isContractCashPrice: checked,
      }),
    });
  }

  handleUseDetailedGrowerStatementChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isUseDetailedGrowerStatement: checked,
      }),
    });
  }

  handleShowImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        showImportResult: checked,
      }),
    });
  }

  handleAllowNotifyImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        allowNotifyImportResult: checked,
      }),
    });
  }

  getTiers = async () => {
    const that = this;
    fetch(GlobalConfig.REACT_APP_API_TIER_URL)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState({
          tiers: resultObject.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTitle = () => {
    return this.state.title;
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        name: "",
        email: "",
        avatar: "",
        avatarExtension: "",
        address: "",
        businessName: "",
        businessAddress: "",
        phoneNumber: "",
        dayOfDelayRelease: 0,
        companyName: "",
        certificateNo: "",
        certificatePrefix: "",
        certificateSeed: "",
        status: "Not Activate",
        isContractCashPrice: false,
        isUseDetailedGrowerStatement: false,
        showImportResult: false,
        tierId: "",
        activationUrl:
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_URL_ACTIVATION +
          "?token=",
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      errors: {
        email: "",
      },
    });
  };

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  render() {
    const title = "Create new " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#Profile" data-toggle="tab">
                          Profile & Account
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="Profile" className="tab-pane active">
                        <form
                          className="form-horizontal form-bordered"
                          onSubmit={this.handleSubmit}
                          id="form"
                        >
                          <h4 className="mb-xlg">Processor Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="name"
                              >
                                Contact Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="150"
                                  type="text"
                                  // ref="name"
                                  name="name"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="email"
                              >
                                Email <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.email}
                                  onChange={this.handleChange}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="companyName"
                              >
                                Processor Company Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="100"
                                  type="text"
                                  name="companyName"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.companyName}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="certificatePrefix"
                              >
                                Certification Prefix
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="text"
                                  name="certificatePrefix"
                                  className="form-control"
                                  value={this.state.data.certificatePrefix}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <NumericInput
                              precision={0}
                              min={0}
                              max={999999999}
                              step={1}
                              label="Certification Seed No"
                              name="certificateSeed"
                              value={this.state.data.certificateSeed}
                              handleChange={this.handleChange}
                            />
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Sample Certificate No
                              </label>
                              <label
                                className="control-label"
                                style={{ paddingLeft: "15px" }}
                              >
                                {this.state.data.certificatePrefix}
                                {this.state.data.certificateSeed}
                              </label>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="address"
                              >
                                Processor Address{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="address"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.address}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessName"
                              >
                                Business Name
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="businessName"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessName}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessAddress"
                              >
                                Business Address
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="300"
                                  type="text"
                                  name="businessAddress"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessAddress}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="phoneNumber"
                              >
                                Phone Number
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.phoneNumber}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <NumericInput
                              precision={0}
                              min={0}
                              max={100}
                              step={1}
                              label="Grower Data Delay"
                              name="dayOfDelayRelease"
                              value={this.state.data.dayOfDelayRelease}
                              handleChange={this.handleChange}
                              note="Number of days to delay release Test data to Growers"
                            />
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Contract Cash Price
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isContractCashPrice"
                                  onChange={
                                    this.handleContractCashPriceChangeSwitch
                                  }
                                  checked={this.state.data.isContractCashPrice}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Use Detailed Grower Statement
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isUseDetailedGrowerStatement"
                                  onChange={
                                    this
                                      .handleUseDetailedGrowerStatementChangeSwitch
                                  }
                                  checked={
                                    this.state.data.isUseDetailedGrowerStatement
                                  }
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Show Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="showImportResult"
                                  onChange={
                                    this.handleShowImportResultChangeSwitch
                                  }
                                  checked={this.state.data.showImportResult}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Allow Notify Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="allowNotifyImportResult"
                                  onChange={
                                    this
                                      .handleAllowNotifyImportResultChangeSwitch
                                  }
                                  checked={
                                    this.state.data.allowNotifyImportResult
                                  }
                                  className="react-switch"
                                />
                              </div>
                            </div>
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Account</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-xs-4 control-label mt-xs pt-none"
                                htmlFor="tierId"
                              >
                                Tier <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <select
                                  ref={this.refInputFocus}
                                  name="tierId"
                                  className="form-control mb-md"
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option
                                    value=""
                                    selected={this.state.data.tierId === ``}
                                  >
                                    Choose one
                                  </option>
                                  {this.state.tiers.map((tier, i) => {
                                    return (
                                      <option key={i} value={tier._id}>
                                        {tier.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="icon mr-sm">
                                  <i
                                    className="fa fa-question-circle fa-lg"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  <a
                                    href={process.env.REACT_APP_URL_TIER_LIST}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Tiers setting
                                  </a>
                                </span>
                              </div>
                            </div>
                          </fieldset>
                          <div className="panel-footer">
                            <div className="row">
                              <div className="col-md-8 col-md-offset-4">
                                <SaveButton loading={this.state.loading} />
                                <a
                                  href={this.state.urllist}
                                  title="Back to list"
                                  className="btn btn-primary"
                                >
                                  <i className="fa fa-backward fa-lg"></i> Back
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
