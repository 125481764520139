import React from "react";
import TestOverTime from "./Report/TestOverTime";
import TestTotalWeightOverTime from "./Report/TestTotalWeightOverTime";
import TestAverage from "./Report/TestAverage";
import SummaryAdmin from "./Report/SummaryAdmin";
import SummaryProcessor from "./Report/SummaryProcessor";
import { getLoggedUser } from "../context/auth";
import { Redirect } from "react-router-dom";
import DashboardSelect from "./DashboardSelect";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_LAB_USER,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
} from "./Utilities/Constant";
import String from "./Utilities/String";
import CommonUtilities from "./Utilities/Common";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const temp = CommonUtilities.extractGrowerInfo(loggedUser);
    let processorId = temp.processorId;
    let viewingGrowerId = temp.viewingGrowerId;

    const isActive = localStorage.getItem("isActive");
    const currentProcessorId = localStorage.getItem(
      "dashboard-admin-currentProcessorId"
    );
    const currentCropYearId = localStorage.getItem(
      "dashboard-admin-currentCropYearId"
    );
    this.state = {
      role: loggedUser.role,
      isActive: isActive,
      view: "",
      currentProcessorId:
        currentProcessorId !== null && currentProcessorId !== undefined
          ? currentProcessorId
          : "",
      currentCropYearId:
        currentCropYearId !== null && currentCropYearId !== undefined
          ? currentCropYearId
          : "",
      currentProcessor: null,
      currentCropYearOfProcessor: null,
      cropYearsOfProcessor: [],
      processors: [],
      processorsAndCropYears: [],
      processorId: processorId,
      viewingGrowerId: viewingGrowerId,
    };
    this.renderSwitch = this.renderSwitch.bind(this);
    this.changeProcessor = this.changeProcessor.bind(this);
    this.changeCropYear = this.changeCropYear.bind(this);
  }

  async componentDidMount() {
    this.getProcessorsAndCropYears();
  }

  getProcessorsAndCropYears() {
    const that = this;
    //Call api to get list of processors and their crop years
    const urlApi =
      loggedUser.role === USER_ROLE_ADMINISTRATOR
        ? GlobalConfig.REACT_APP_API_CROPYEAR_URL + "allprocessorsandcropyears"
        : GlobalConfig.REACT_APP_API_CROPYEAR_URL +
          "getall/" +
          this.state.processorId;

    fetch(urlApi)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        //Successfully get data
        if (
          resultObject &&
          resultObject.result === "OK" &&
          resultObject.data &&
          resultObject.data.length > 0
        ) {
          if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
            const processorInformation = that.getProcessorsFromResponseData(
              resultObject.data
            ); // from response get list of processor
            that.setState(
              {
                processors: processorInformation.processors,
                currentProcessor: processorInformation.currentProcessor,
                currentProcessorId: processorInformation.currentProcessorId,
                processorsAndCropYears: resultObject.data,
              },
              //After set data to state , rerender to get new view
              () => {
                that.setState({ view: that.renderSwitch() }, () =>
                  that.getCropYearsByProcessor(
                    that.state.processorsAndCropYears,
                    that.state.currentProcessorId
                  )
                );
              }
            );
          } else {
            const cropYearInformation = that.getCropYearsFromResponse(
              resultObject.data
            );
            that.setState(
              {
                cropYearsOfProcessor: cropYearInformation.cropYears,
                currentCropYearOfProcessor: cropYearInformation.currentCropYear,
                currentCropYearId: cropYearInformation.currentCropYearId,
              },
              () => that.setState({ view: that.renderSwitch() })
            );
          }
        }
      })
      .catch(function (error) {
        that.setState({
          alertshow: false,
        });
        that.setState({
          alertmsg: error,
          alerttype: "error",
          alertshow: true,
        });
      });
  }

  changeProcessor(processor) {
    //Store current processor (id) to local storage
    localStorage.setItem("dashboard-admin-currentProcessorId", processor.value);
    //Set state
    this.setState(
      {
        currentProcessor: processor,
        currentProcessorId: processor.value,
      },
      () => {
        this.getCropYearsByProcessor(
          this.state.processorsAndCropYears,
          processor.value
        );
      }
    );
  }

  changeCropYear(cropYear) {
    //Store current crop year of processor to local storage
    localStorage.setItem("dashboard-admin-currentCropYearId", cropYear.value);
    //Set state
    this.setState(
      {
        currentCropYearOfProcessor: cropYear,
        currentCropYearId: cropYear.value,
      },
      //After set state, then rerender
      () => {
        this.setState({
          view: this.renderSwitch(),
        });
      }
    );
  }

  getProcessorsFromResponseData(responseData) {
    let processors = [];
    let currentProcessor;
    let existProcessor = false;
    responseData.map((processor) => {
      if (
        this.state.currentProcessorId &&
        processor._id === this.state.currentProcessorId
      ) {
        existProcessor = true;
        currentProcessor = {
          value: processor._id,
          label: processor.name,
        };
      }
      processors.push({
        value: processor._id,
        label: processor.name,
      });
    });
    if (existProcessor === false) {
      currentProcessor = processors[0];
    }
    return {
      currentProcessor: currentProcessor,
      currentProcessorId: currentProcessor.value,
      processors: processors,
    };
  }

  getCropYearsByProcessor(processorsAndCropYears, processorId) {
    if (String.isEmpty(processorId)) return [];
    let cropYearsByProcessor = [];
    processorsAndCropYears.forEach((processor) => {
      if (processor._id.toString() === processorId.toString()) {
        cropYearsByProcessor = processor.cropYears;
      }
    });
    if (!cropYearsByProcessor.length > 0) {
      this.setState(
        {
          cropYearsOfProcessor: [],
          currentCropYearId: "",
          currentCropYearOfProcessor: null,
        },
        () =>
          this.setState({
            view: this.renderSwitch(),
          })
      );
      return;
    }
    let existCurrentCropYear = false;
    let currentCropYearOfProcessor;
    let data = [];
    cropYearsByProcessor.forEach((cropYear) => {
      if (
        this.state.currentCropYearId &&
        cropYear._id == this.state.currentCropYearId
      ) {
        existCurrentCropYear = true;
        currentCropYearOfProcessor = {
          value: cropYear._id,
          label: cropYear.name,
        };
      }
      data.push({
        value: cropYear._id,
        label: cropYear.name,
      });
    });
    if (existCurrentCropYear === false) {
      currentCropYearOfProcessor = data[0];
    }
    this.setState(
      {
        currentCropYearOfProcessor: currentCropYearOfProcessor,
        currentCropYearId: currentCropYearOfProcessor.value,
        cropYearsOfProcessor: data,
      },
      () => {
        this.setState({ view: this.renderSwitch() });
      }
    );
  }

  getCropYearsFromResponse(responseData) {
    let currentCropYear;
    let existCropYear = false;
    let cropYears = [];
    const activeCropYearId = this.getActiveCropYearId(responseData);
    responseData.forEach((cropYear) => {
      if (activeCropYearId !== "") {
        if (cropYear._id === activeCropYearId) {
          existCropYear = true;
          currentCropYear = {
            value: cropYear._id,
            label: cropYear.name,
          };
        }
      } else {
        if (cropYear._id === this.state.currentCropYearId) {
          existCropYear = true;
          currentCropYear = {
            value: cropYear._id,
            label: cropYear.name,
          };
        }
      }
      cropYears.push({
        value: cropYear._id,
        label: cropYear.name,
      });
    });
    if (!existCropYear) {
      currentCropYear = cropYears[0];
    }
    return {
      currentCropYear: currentCropYear,
      currentCropYearId: currentCropYear.value,
      cropYears: cropYears,
    };
  }

  getActiveCropYearId = (cropYears) => {
    if (cropYears && cropYears.length && cropYears.length > 0) {
      const activeCropYear = cropYears.find((cropYear) => cropYear.checked);
      if (activeCropYear && activeCropYear._id && activeCropYear._id !== "") {
        return activeCropYear._id;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  renderSwitch() {
    switch (loggedUser.role) {
      case USER_ROLE_ADMINISTRATOR:
        return (
          <>
            <DashboardSelect
              changeProcessor={this.changeProcessor}
              changeCropYear={this.changeCropYear}
              currentProcessor={this.state.currentProcessor}
              currentCropYearOfProcessor={this.state.currentCropYearOfProcessor}
              processors={this.state.processors}
              cropYearsOfProcessor={this.state.cropYearsOfProcessor}
            />
            <SummaryAdmin
              currentProcessorId={this.state.currentProcessorId}
              currentCropYearId={this.state.currentCropYearId}
              currentCropYearName={
                this.state.currentCropYearOfProcessor
                  ? this.state.currentCropYearOfProcessor.label
                  : ""
              }
            ></SummaryAdmin>
            <div className="row">
              <div className="col-md-6">
                <TestOverTime
                  currentProcessorId={this.state.currentProcessorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestOverTime>
              </div>
              <div className="col-md-6">
                <TestTotalWeightOverTime
                  currentProcessorId={this.state.currentProcessorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestTotalWeightOverTime>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TestAverage
                  currentProcessorId={this.state.currentProcessorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestAverage>
              </div>
            </div>
          </>
        );
      case USER_ROLE_LAB_USER:
        return loggedUser.name == null ||
          loggedUser.name === undefined ||
          loggedUser.name === "" ? (
          <Redirect to={process.env.REACT_APP_URL_MY_PROFILE} />
        ) : (
          <>
            <DashboardSelect
              changeCropYear={this.changeCropYear}
              cropYearsOfProcessor={this.state.cropYearsOfProcessor}
              currentCropYearOfProcessor={this.state.currentCropYearOfProcessor}
              hideProcessor={true}
            />
            <div className="row">
              <div className="col-md-6">
                <TestOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestOverTime>
              </div>
              <div className="col-md-6">
                <TestTotalWeightOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestTotalWeightOverTime>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TestAverage
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestAverage>
              </div>
            </div>
          </>
        );
      case USER_ROLE_PROCESSOR:
      case USER_ROLE_STAFF:
        return this.state.isActive === false ||
          this.state.isActive === "false" ? (
          <Redirect to={process.env.REACT_APP_URL_MY_PROFILE} />
        ) : (
          <>
            <DashboardSelect
              changeCropYear={this.changeCropYear}
              cropYearsOfProcessor={this.state.cropYearsOfProcessor}
              currentCropYearOfProcessor={this.state.currentCropYearOfProcessor}
              hideProcessor={true}
            />
            <SummaryProcessor
              currentProcessorId={this.state.processorId}
              currentCropYearId={this.state.currentCropYearId}
              currentCropYearName={
                this.state.currentCropYearOfProcessor
                  ? this.state.currentCropYearOfProcessor.label
                  : ""
              }
            ></SummaryProcessor>
            <div className="row">
              <div className="col-md-6">
                <TestOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestOverTime>
              </div>
              <div className="col-md-6">
                <TestTotalWeightOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestTotalWeightOverTime>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TestAverage
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={""}
                ></TestAverage>
              </div>
            </div>
          </>
        );
      case USER_ROLE_GROWER:
        return loggedUser.name == null ||
          loggedUser.name === undefined ||
          loggedUser.name === "" ? (
          <Redirect to={process.env.REACT_APP_URL_MY_PROFILE} />
        ) : (
          <>
            <DashboardSelect
              changeCropYear={this.changeCropYear}
              cropYearsOfProcessor={this.state.cropYearsOfProcessor}
              currentCropYearOfProcessor={this.state.currentCropYearOfProcessor}
              hideProcessor={true}
            />
            <div className="row">
              <div className="col-md-6">
                <TestOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={this.state.viewingGrowerId}
                ></TestOverTime>
              </div>
              <div className="col-md-6">
                <TestTotalWeightOverTime
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={this.state.viewingGrowerId}
                ></TestTotalWeightOverTime>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TestAverage
                  currentProcessorId={this.state.processorId}
                  currentCropYearId={this.state.currentCropYearId}
                  currentGrowerId={this.state.viewingGrowerId}
                ></TestAverage>
              </div>
            </div>
          </>
        );
      default:
        return "";
    }
  }

  render() {
    return (
      <section role="main" className="content-body">
        <header className="page-header">
          <h2>Dashboards</h2>
          <div className="right-wrapper pull-right">
            <ol className="breadcrumbs">
              <li>
                <a href="/">
                  <i className="fa fa-home"></i>
                </a>
              </li>
              <li>
                <span>Dashboards</span>
              </li>
            </ol>

            <a className="sidebar-right-toggle" data-open="sidebar-right">
              <i className="fa fa-chevron-left"></i>
            </a>
          </div>
        </header>
        {this.state.view == undefined ||
        this.state.view == null ||
        this.state.view == ""
          ? ""
          : this.state.view}
      </section>
    );
  }
}
export default App;
