const rules = {
  "Lab Users": {
    static: ["tests", "helps"],
  },
  Growers: {
    static: ["tests", "helps", "mailbox"],
  },
  Staffs: {
    static: [
      "tests",
      "lab-users",
      "handlers",
      "growers",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "cash-contract-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "test-types",
      "grower-statements",
      "grower-payment-report",
      "attribute",
      "classifications",
      "classification-items",
      "test-fields",
      "payment-detail-view",
      "static-message",
      "helps",
      "mailbox",
      "import-logs",
    ],
    // ,dynamic: {
    //   "growers": ({ id}) => {
    //     if (!id || !postOwnerId) return false;
    //     return userId === postOwnerId;
    //   }
    // }
  },
  Processors: {
    static: [
      "tests",
      "lab-users",
      "handlers",
      "growers",
      "bills",
      "subscriptions",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "cash-contract-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "test-types",
      "staffs",
      "grower-statements",
      "grower-payment-report",
      "attribute",
      "classifications",
      "classification-items",
      "test-fields",
      "payment-detail-view",
      "static-message",
      "helps",
      "mailbox",
      "import-logs",
    ],
    // ,dynamic: {
    //   "growers": ({ id}) => {
    //     if (!id || !postOwnerId) return false;
    //     return userId === postOwnerId;
    //   }
    // }
  },
  Administrators: {
    static: [
      "tests",
      "growers",
      "lab-users",
      "handlers",
      "bills",
      "subscriptions",
      "processors",
      "mail-templates",
      "membership-types",
      "test-types",
      "classifications",
      "classification-items",
      "tiers",
      "administrators",
      "system-settings",
      "growerpayments",
      "payment-settings",
      "base-prices",
      "cash-contract-prices",
      "bonus-deduct",
      "tax-fee",
      "audit-logs",
      "staffs",
      "attribute",
      "test-fields",
      "grower-statements",
      "grower-payment-report",
      "payment-detail-view",
      "static-message",
      "helps",
      "helps-add",
      "helps-edit",
      "import-logs",
      "system-logs",
      "time-reports",
      "site-performance-report",
      "email-settings",
      "scheduled-tasks",
    ],
  },
};

export default rules;
