import React from "react";
import { Button, Modal } from "react-bootstrap";
import Switch from "react-switch";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showTestType: false,
    };
  }

  openTestTypePopup = () => {
    this.setState({ showTestType: true });
  };

  closeTestTypePopup = () => {
    this.setState({ showTestType: false });
  };

  getTestTypeAttributes = () => {
    return [
      {
        name: "Base Price By Payments",
        value: this.props.testType.basePriceByPayment,
      },
      {
        name: "Payment Percent Based",
        value: this.props.testType.paymentPercentBased,
      },
      {
        name: "Multiple Prices Same LOT",
        value: this.props.testType.multiPricesSameLot,
      },
      {
        name: "Single Price Per LOT",
        value: this.props.testType.singlePricePerLot,
      },
      {
        name: "Variety Optional",
        value: this.props.testType.varietyOptional,
      },
      {
        name: "XPool",
        value: this.props.testType.xpool,
      },
    ];
  };

  render() {
    return (
      <>
        <div className="icon-test-type-show-popup">
          <i
            title="Test Type Settings"
            onClick={() => this.openTestTypePopup()}
            className="fa fa-info-circle"
            aria-hidden="true"
          ></i>
        </div>
        <Modal
          show={this.state.showTestType}
          onHide={() => this.closeTestTypePopup()}
          animation={false}
          className=""
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <div className="test-type-popup-header">Test Type Settings</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ margin: "-15px", marginBottom: "-30px" }}>
            {this.getTestTypeAttributes().map((attribute, index) => {
              return typeof attribute.value !== "boolean" ? (
                <div className="test-type-field-item" key={index}>
                  <div className="test-type-lable">{attribute.name}</div>
                  <div style={{ fontSize: 15, fontWeight: 500 }}>
                    {attribute.value}
                  </div>
                </div>
              ) : (
                <div className="test-type-field-item" key={index}>
                  <div className="test-type-lable">{attribute.name}</div>
                  <Switch
                    checked={attribute.value}
                    onChange={() => {}}
                    disabled
                  ></Switch>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer style={{ textAlign: "center" }}>
            <Button
              variant="warning"
              type="button"
              onClick={() => this.closeTestTypePopup()}
            >
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default App;
