import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import SaveButton from "../Controls/SaveButton";
import { overrideLoadingStyle } from "./../Utilities/Styles";

class App extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <Modal
        // dialogAs={DraggableModalDialog}
        show={this.props.show}
        onHide={this.props.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form onSubmit={this.props.handleSave} id="fromPopup">
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
          <Modal.Footer className="modal-footer-test-field">
            {this.props.hint && (
              <div className="hint-text">{this.props.hint}</div>
            )}

            <div>
              {this.props.data && (
                <SaveButton
                  disabled={
                    this.props.loading !== undefined
                      ? this.props.loading
                      : false
                  }
                  loading={this.props.loading}
                />
              )}
              {this.props.extendButton ? this.props.extendButton : ""}
              <Button variant="warning" onClick={this.props.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
export default App;
