import React from "react";
import Select from "react-select";

import WalnutTestContext from "../../context/WalnutTestContext";

class App extends React.Component {
  static contextType = WalnutTestContext;

  constructor(props) {
    super();
    this.state = {
      isSetCheckedFromSource: props.isSetCheckedFromSource,
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      label: props.label,
      value: props.value,
      name: "",
      data: [],
      errorMessage: "",
      isLoading: false,
    };
    this.selectRef = null;
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getItems = this.getItems.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.urlapilist !== this.props.urlapilist) {
      this.setState(
        {
          urlapilist: this.props.urlapilist,
        },
        () => this.getItems()
      );
    }
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      if (
        this.state.urlapilist === undefined ||
        this.state.urlapilist == null ||
        this.state.urlapilist === ""
      ) {
        return;
      }
      const defaultItem = { value: "", name: this.state.label };
      const that = this;
      that.setState({
        isLoading: true,
      });
      fetch(this.state.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            let selectedValue = "";
            let selectedName = "";
            if (resultObject.data) {
              if (that.state.isSetCheckedFromSource === true) {
                resultObject.data.map((item) => {
                  if (item.checked === true) {
                    selectedValue = item._id;
                    selectedName = item.name;
                  }
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked: item.checked,
                  });
                });
              } else {
                resultObject.data.map((item) => {
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked:
                      that.state.value == null ||
                      that.state.value === undefined ||
                      that.state.value.indexOf(item._id) === -1
                        ? false
                        : true,
                  });
                });
              }
            }

            that.setState(
              {
                isLoading: false,
                data: [defaultItem].concat(parsedItems),
              },
              function callBack() {
                if (that.props.isSetCheckedFromSource === true) {
                  //fire event select item
                  that.setState({
                    value: selectedValue,
                    label: selectedName,
                  });
                  that.props.handleChangeSelectControl(
                    that.state.controlId,
                    selectedValue,
                    selectedName
                  );
                }
              }
            );
          }
        })
        .catch(function (error) {
          that.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    // When reset input then set state(value, label) to default value
    if (!event) {
      this.setState({
        value: "",
        label: this.props.label,
      });
      return;
    }
    const valueItem = event;

    this.setState({ value: valueItem.value, label: valueItem.label });

    this.props.handleChangeSelectControl(
      this.state.controlId,
      valueItem.value,
      valueItem.value
    );
  }

  setSelected(value) {
    this.setState({ value: value });
  }

  reload(urlapilist) {
    this.setState(
      {
        urlapilist: urlapilist,
      },
      () => {
        this.getItems();
      }
    );
  }

  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          block: "nearest",
        });
      }
    }, 15);
  };

  reset = () => {
    this.selectRef.select.clearValue();
  };

  render() {
    const { controlId, label = "", value = "", data } = this.state;
    const { valueDateChange } = this.context;
    const labelFromProps = this.props.label;

    return (
      <>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          name={controlId}
          onChange={this.handleChangeSelect}
          placeholder={label}
          value={
            valueDateChange === undefined
              ? value
              : {
                value: valueDateChange === "all" ? "" : value,
                label: valueDateChange === "all" ? labelFromProps : label,
              }
          }
          classNamePrefix={"MyDropdown"}
          onMenuOpen={this.onMenuOpen}
          options={
            data
              ? data.map((item) => {
                return {
                  value: item._id,
                  label: item.name,
                };
              })
              : ""
          }
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}
export default App;
