import React from "react";
import DataTable from "react-data-table-component";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { getLoggedUser } from "../../context/auth";
import { Helmet } from "react-helmet";
import readXlsxFile from "read-excel-file";

var lodash = require("lodash");
var validator = require("email-validator");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const schema = {
  Name: {
    prop: "name",
    type: String,
    required: true,
    parse(value) {
      if (value.trim().length > 100) {
        throw new Error("Max characters must be less/equal 100");
      }
      return value.trim();
    },
  },
  Invite: {
    prop: "invite",
    type: String,
  },
  "Contact Name": {
    prop: "contactName",
    type: String,
    parse(value) {
      if (value.trim().length > 100) {
        throw new Error("Max characters must be less/equal 100");
      }
      return value.trim();
    },
  },
  "Contact Email": {
    prop: "contactEmail",
    type: String,
    parse(value) {
      if (value.trim().length > 100) {
        throw new Error("Max characters must be less/equal 100");
      } else if (validator.validate(value.trim()) == false) {
        throw new Error("Invalid email");
      }
      return value.trim();
    },
  },
  Address: {
    prop: "address",
    type: String,
    parse(value) {
      if (value.trim().length > 200) {
        throw new Error("Max characters must be less/equal 200");
      }
      return value.trim();
    },
  },
  Status: {
    prop: "status",
    type: String,
    oneOf: ["Active", "Inactive"],
  },
};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      isImported: false,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      totalRows: 0,
      totalRowsError: 0,
      processorId: loggedUser.processorId,
      data: {
        items: [],
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
        createdUserId: loggedUser._id,
      },
      resultTotal: 0,
      resultTotalError: 0,
      resultTotalSuccess: 0,
    };
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  columnsError = () => {
    return [
      {
        name: "Row",
        selector: "row",
        center: true,
      },
      {
        name: "Column",
        selector: "column",
      },
      {
        name: "Value",
        selector: "value",
      },
      {
        name: "Error",
        selector: "error",
      },
    ];
  };

  columnsResult = () => {
    return [
      {
        name: "Row",
        selector: "row",
        center: true,
      },
      {
        name: "Result",
        selector: "result",
        left: true,
        cell: (row) =>
          row.isError ? (
            <span style={{ color: "red" }}>Failed: {row.result}</span>
          ) : (
            <span style={{ color: "blue" }}>{row.result}</span>
          ),
      },
      {
        name: "Name",
        selector: "name",
        left: true,
      },
      {
        name: "Invite",
        selector: "invite",
        center: true,
      },
      {
        name: "Contact Name",
        selector: "contactName",
        left: true,
      },
      {
        name: "Contact Email",
        selector: "contactEmail",
        left: true,
      },
      {
        name: "Address",
        selector: "address",
        left: true,
      },
      {
        name: "Status",
        selector: "status",
        left: true,
      },
    ];
  };

  columns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        left: true,
      },
      {
        name: "Invite",
        selector: "invite",
        center: true,
      },
      {
        name: "Contact Name",
        selector: "contactName",
        left: true,
      },
      {
        name: "Contact Email",
        selector: "contactEmail",
        left: true,
      },
      {
        name: "Address",
        selector: "address",
        left: true,
      },
      {
        name: "Status",
        selector: "status",
        left: true,
      },
    ];
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    window.showAlert("Info", "Processing...", "Info");
    this.setState({ isImported: true });
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.state.data),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_GROWER_URL +
          "import/" +
          this.state.processorId,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //successful
          if (resultObject && resultObject.result === "OK") {
            that.setState({
              data: Object.assign({}, that.state.data, {
                items: resultObject.data,
              }),
              resultTotal: resultObject.total,
              resultTotalError: resultObject.totalError,
              resultTotalSuccess: resultObject.totalSuccess,
            });
            window.showAlert(
              "",
              "Please check result import on table below.",
              ""
            );
          } else {
            window.showAlert("Error", resultObject.message, "Error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  handleUploadFile(event) {
    var files = event.target.files[0];
    this.setState(
      {
        isImported: false,
        data: Object.assign({}, this.state.data, {
          items: [],
        }),
        totalRows: 0,
        totalRowsError: 0,
      },
      function () {
        if (files) {
          if (
            files.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            readXlsxFile(files, { schema }).then(({ rows, errors }) => {
              console.log(rows);
              console.log(errors);
              let errorsClone = [...errors];
              if (rows && rows.length > 0) {
                let index = 1;
                rows.filter(function (row) {
                  if (row.invite && row.invite.trim().toLowerCase() == "yes") {
                    if (
                      row.contactName == null ||
                      row.contactName.trim() == ""
                    ) {
                      errorsClone.push({
                        error: "required",
                        row: index,
                        column: "Contact Name",
                        value: row.contactName,
                      });
                    }
                    if (
                      row.contactEmail == null ||
                      row.contactEmail.trim() == ""
                    ) {
                      errorsClone.push({
                        error: "required",
                        row: index,
                        column: "Contact Email",
                        value: row.contactEmail,
                      });
                    }
                    if (row.status == null || row.status.trim() == "") {
                      errorsClone.push({
                        error: "required",
                        row: index,
                        column: "Status",
                        value: row.status,
                      });
                    }
                  }
                  index++;
                });
              }

              let temp = lodash.groupBy(errorsClone, "row");
              let lengthErrors = Object.keys(temp).length;
              this.setState({
                totalRows: rows.length,
                totalRowsError: lengthErrors,
              });
              if (errorsClone && errorsClone.length > 0) {
                errorsClone = lodash.sortBy(errorsClone, ["row", "column"]);
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    items: errorsClone,
                  }),
                });
              } else {
                this.setState({
                  data: Object.assign({}, this.state.data, {
                    items: rows,
                  }),
                });
              }
            });
          } else {
            window.showAlert("Error", "Please choose Excel", "error");
          }
        }
      }
    );
  }

  handleRemoveFile = () => {
    this.setState({
      data: Object.assign({}, this.state.data, {
        items: [],
      }),
      totalRows: 0,
      totalRowsError: 0,
    });
  };

  setColumns = () => {
    return [];
  };

  render() {
    return (
      <>
        <Helmet>
          <title>
            {"Import Growers | " + process.env.REACT_APP_SITE_TITLE}
          </title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>Import Growers</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>Import Growers</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <section className="panel">
                <header className="panel-heading">
                  <h2 className="panel-title">Upload file to upload</h2>
                  <p className="panel-subtitle">
                    1. Click on upload icon to select excel file. Template of
                    excel can download at{" "}
                    <a href="/assets/public/template/import_template_growers.xlsx">
                      here
                    </a>
                    .
                    <br />
                    2. Once file uploaded, data to be validated. And details of
                    validation to be displayed on table.
                    <br />
                    3. If whole rows in excel file valid the{" "}
                    <strong>Submit</strong> button visible to allow insert data
                    into module.
                    <br />
                    4. Result of import to be response detail of each row.
                  </p>
                </header>
                <div className="panel-body">
                  <div
                    className="row"
                    style={{ paddingBottom: "10px", paddingLeft: "5px" }}
                  >
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="col-md-8 p-none">
                          <div
                            className="fileupload fileupload-new"
                            data-provides="fileupload"
                          >
                            <div className="input-append">
                              <div className="uneditable-input">
                                <i className="fa fa-file fileupload-exists"></i>
                                <span className="fileupload-preview"></span>
                              </div>
                              <span className="btn btn-default btn-file m-none mt-sm-ipad">
                                <span
                                  className="fileupload-exists"
                                  title="Change"
                                >
                                  <i
                                    className="fa fa-upload fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="fileupload-new">
                                  <i
                                    className="fa fa-upload fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <input
                                  key="fileUpload"
                                  placeholder="Browse excel file"
                                  type="file"
                                  onClick={(e) => (e.target.value = null)}
                                  onChange={this.handleUploadFile.bind(this)}
                                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                              </span>
                              <a
                                href="#"
                                className="btn btn-default fileupload-exists m-none mt-sm-ipad"
                                data-dismiss="fileupload"
                                title="Remove"
                                onClick={this.handleRemoveFile}
                              >
                                <span>
                                  <i
                                    className="fa fa-trash-o fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </a>
                              {this.state.totalRows > 0 &&
                              this.state.totalRowsError <= 0 &&
                              !this.state.isImported ? (
                                <button
                                  onClick={(e) => this.handleSubmit(e)}
                                  className="btn btn-success"
                                >
                                  <i className="fa fa-save"></i> Submit
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.data.items == null ||
                  this.state.data.items.length <= 0 ? (
                    ""
                  ) : (
                    <DataTable
                      id="emp"
                      key="emp"
                      title={
                        this.state.isImported ? (
                          <h5>
                            Total rows: {this.state.resultTotal} |{" "}
                            <span
                              style={{
                                color:
                                  this.state.resultTotalError > 0
                                    ? "red"
                                    : "blue",
                              }}
                            >
                              Rows error: {this.state.resultTotalError}
                            </span>{" "}
                            |{" "}
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              Rows imported: {this.state.resultTotalSuccess}
                            </span>
                            {this.state.resultTotalSuccess > 0 ? (
                              <p className="panel-subtitle">
                                Click{" "}
                                <a href={process.env.REACT_APP_URL_GROWER_LIST}>
                                  here
                                </a>{" "}
                                to view growers imported.
                              </p>
                            ) : (
                              <p className="panel-subtitle">
                                Please check details of error below.
                              </p>
                            )}
                          </h5>
                        ) : (
                          <h5>
                            Total rows: {this.state.totalRows} |{" "}
                            <span
                              style={{
                                color:
                                  this.state.totalRowsError > 0
                                    ? "red"
                                    : "blue",
                              }}
                            >
                              Total rows error: {this.state.totalRowsError}
                            </span>
                            {this.state.totalRowsError > 0 ? (
                              <p className="panel-subtitle">
                                A few rows in excel file is invalid. Please
                                correct data upload again.
                              </p>
                            ) : (
                              <p className="panel-subtitle">
                                Data is valid, please click on Submit to
                                continue import growers.
                              </p>
                            )}
                          </h5>
                        )
                      }
                      noHeader={false}
                      columns={
                        this.state.isImported === true
                          ? this.columnsResult()
                          : this.state.totalRowsError > 0
                          ? this.columnsError()
                          : this.columns()
                      }
                      data={this.state.data.items}
                      className="table table-bordered table-striped mb-none"
                      pagination={false}
                    />
                  )}
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
