import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import { Helmet } from "react-helmet";

class App extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { title, children, navRoute, location } = this.props;

    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation navRoute={navRoute} location={location}></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <span>{title}</span>
                    </li>
                  </ol>

                  <a
                    href="# "
                    className="sidebar-right-toggle"
                    data-open="sidebar-right"
                  >
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-6">{children}</div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
