import React from "react";
import { qualityAttributeNamesMapping } from "../Utilities/Object";

class App extends React.Component {
  constructor(props) {
    super();
  }

  getCheckedValue = (column) => {
    let checked = this.props.hideColumns.indexOf(column.selector) === -1;
    if (this.props.includesQualityColumn) {
      let isQualityColumn = false;
      switch (this.props.testType) {
        case "dynamic": {
          if (
            this.props.customFields &&
            this.props.customFields.some(
              (field) => field.name === column.selector
            )
          ) {
            isQualityColumn = true;
          }
          break;
        }
        case "static": {
          for (let attribute in qualityAttributeNamesMapping) {
            if (attribute === column.selector) {
              isQualityColumn = true;
              break;
            }
          }
          break;
        }
        default:
      }
      if (isQualityColumn) {
        checked = !(this.props.hideColumns.indexOf(column.selector) === -1);
      }
      return checked;
    } else {
      return checked;
    }
  };

  render() {
    return (
      <div className="columnChooser panel-btn-group-item-position mb-sm">
        <span
          className={
            this.props.btnColumnOptions === true
              ? "btnOpenColumnChooserContainerHide"
              : " "
          }
        >
          <button
            className="btn btn-column-chooser panel-btn-group-color"
            onClick={this.props.columnOptionOpening}
            title="Column Options"
          >
            <i className="fa fa-list fa-lg"></i>
          </button>
        </span>

        <div
          className={
            this.props.btnColumnOptions
              ? "columnChooserContainer"
              : "columnChooserContainer columnChooserContainerHide"
          }
        >
          <div className="columnChooserContainerTitle">
            <div>Column Options</div>

            <i
              className="fa fa-times fa-lg btnCloseColumnChooser"
              onClick={this.props.columnOptionClosed}
            ></i>
          </div>

          <div className="columnChooserItemWrapper">
            {this.props.defaultColumns.map((item, index) => {
              return (
                item.omit !== true &&
                item.name !== "" &&
                item.name !== "Actions" && (
                  <div key={index} className="columnChooserItem">
                    <input
                      className="columnChooserItemCheckbox"
                      type="checkbox"
                      id={item.name}
                      name={item.selector}
                      checked={this.getCheckedValue(item)}
                      onChange={this.props.columnOptionChanged}
                    />
                    <label
                      className="columnChooserItemLabel"
                      htmlFor={item.name}
                    >
                      {item.name}
                    </label>
                  </div>
                )
              );
            })}
          </div>

          <div className="columnChooserFooter">
            <button
              className="btn btn-primary"
              onClick={this.props.columnOptionReset}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
