/* eslint-disable no-undef */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  FIELD_TYPE_CALCULATED,
  SECTION_QUALITY_DATA,
  SECTION_META_DATA,
} from "../Utilities/Constant";
import FilterMultiSelectFieldType from "./FilterMultiSelectFieldType";
import moment from "moment";
import SaveButton from "../Controls/SaveButton";
const GlobalConfig = new window.globalConfig();

const CONFIG_TYPE = "Test Field Type";
const TEST_FIELD_TYPE_API_URL =
  GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL + `likename/` + CONFIG_TYPE;

const SelectFieldPopup = (props) => {
  const {
    show,
    handleCloseTestField,
    handleSearchTestField,
    allFields,
    currentSection,
    checkField,
    handleChecked,
    handleChangeFieldType,
    handleChangeFieldName,
    handleOpenPopupCreateTestField,
    loading,
  } = props;

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      left: true,
    },
    {
      name: "Type",
      selector: "fieldType",
      sortable: true,
      left: true,
    },
    {
      name: "Created Date",
      selector: "createdDate",
      cell: (row) => moment(row.createdDate).format("MM/DD/yyyy"),
      sortable: true,
      left: true,
    },
    {
      name: "In Used",
      selector: "_id",
      left: true,
      cell: (row) => (
        <div className="select-filed-checkbox">
          <label>
            <input
              type="checkbox"
              key={row._id}
              checked={checkField(row._id)}
              onChange={handleChecked}
              value={row._id}
            />
          </label>
        </div>
      ),
    },
  ];

  const filterFields = (section, fields) => {
    if (fields && fields.length > 0) {
      let filterFields = [];
      const numericTypes = [
        FIELD_TYPE_INTEGER,
        FIELD_TYPE_DECIMAL,
        FIELD_TYPE_CALCULATED,
        FIELD_TYPE_PERCENT,
      ];
      switch (section) {
        case SECTION_META_DATA:
          const notNumericFields = fields.filter(
            (f) => !numericTypes.includes(f.fieldType) // get field which is not numeric fieldType
          );
          filterFields = notNumericFields;
          break;
        case SECTION_QUALITY_DATA:
          const numericFields = fields.filter(
            (f) => numericTypes.includes(f.fieldType) // get field which is numeric fieldType
          );
          filterFields = numericFields;
          break;
        default:
          filterFields = fields;
      }
      return filterFields;
    } else {
      return [];
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseTestField}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        className="select-field-popup"
        centered
      >
        <Modal.Header>
          <Modal.Title>Select Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body className="select-field-popup-body">
          <div className="row">
            <div className="col-lg-5 col-xs-12 mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterFieldName"
                placeholder="Filter Name"
                onChange={handleChangeFieldName}
              />
            </div>
            <div className="col-lg-5 col-xs-12 mb-sm">
              <FilterMultiSelectFieldType
                handleChangeSelectControl={handleChangeFieldType}
                placeholder="Filter Type"
                currentSection={currentSection}
                label="Select All"
                controlId="filterFieldType"
                urlapilist={TEST_FIELD_TYPE_API_URL}
                onRef={(ref) => {}}
              />
            </div>
            <div className="col-lg-2 col-xs-12 mb-sm">
              <SaveButton
                propClassName={
                  "panel-btn-group-item-position btn btn-primary select-field-popup-btn flex-box-align-items-center"
                }
                variant="primary"
                text={"Search"}
                type={"button"}
                icon={
                  <i
                    className="fa fa-search fa-lg"
                    style={{ marginRight: 2.5 }}
                  ></i>
                }
                loading={loading}
                onClick={handleSearchTestField}
              />
            </div>
          </div>

          <div className="mt-xs">
            <DataTable
              highlightOnHover
              striped
              noHeader
              default
              columns={columns}
              data={filterFields(currentSection, allFields)}
              className="test-field-table table table-bordered table-striped mb-none"
            ></DataTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <Button
              variant="success"
              type="button"
              onClick={handleOpenPopupCreateTestField}
            >
              <span className="fa fa-plus fa-lg"></span> Add Field
            </Button>
            <Button variant="warning" onClick={handleCloseTestField}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectFieldPopup;
