import { Error } from "../../Components/AuthForms";
import React, { createRef } from "react";
import AddComponent from "../Base/AddComponent";
import { Helmet } from "react-helmet";

var jwt = require("jsonwebtoken");
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    this.refInputFocus = createRef();
    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL + "activate",
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        id: "",
        password: "",
        confirmPassword: "",
        baseUrl: process.env.REACT_APP_BASE_URL,
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      processor: {},
      alertProcessingHidden: true,
      isShowSaveSuccessfulMessage: false,
    };
  }

  setStateFromQueryString = () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    if (token && token.trim().length > 0) {
      this.setState(
        {
          data: Object.assign({}, this.state.data, {
            "id": token.trim(),
          }),
        },
        function () {
          this.getProcessorByToken(token.trim());
        }
      );
    } else {
      this.setState({
        alertshow: true,
        alertmsg: "Invalid token!",
      });
    }
  };

  componentDidMount() {
    localStorage.clear();
    // this.refInputFocus.current.focus();
    this.setStateFromQueryString();
  }

  getProcessorByToken = async (token) => {
    try {
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_PROCESSOR_URL + "getprocessorbytoken/" + token
      ).then(function (response) {
        return response.json();
      })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            if (resultObject.data) {
              that.setState({
                processor: resultObject.data,
              });
              //Turn-off message
              that.setState({
                alertshow: false,
              });

              if (resultObject.data == null || resultObject.data === undefined) {
                that.setState({
                  alertshow: true,
                  alertmsg: "Processor does not exist longer to activate!",
                });
              } else {
                if (resultObject.data.status !== "Not Activate") {
                  that.setState({
                    alertshow: true,
                    alertmsg:
                      "Activation request is not valid! Your account might activated already, please try to login.",
                  });
                } else {
                  that.doSomethingAfterGetProcessorByToken();
                }
              }
            } else {
              that.setState({
                alertshow: true,
                alertmsg:
                  "Could not found processor to activate! Please double check and try again.",
              });
            }
          } else {
            that.setState({
              alertshow: false,
            });
            that.setState({
              alertmsg: resultObject.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        }).catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  doSomethingAfterGetProcessorByToken = () => { };

  doSomethingAfterSaved = async (data) => {
    this.setState({
      alertshow: false,
    });
    this.setState({
      alertmsg:
        "Your account is activated successfull. Please login to continue using " +
        process.env.REACT_APP_SITE_TITLE,
      alerttype: "success",
      alertshow: true,
    });
    setTimeout(() => {
      window.location.replace(process.env.REACT_APP_URL_LOGIN);
    }, 4000);
  };

  doSomethingAfterErrorSaved(res) {
    this.setState({
      alertshow: true,
      alertmsg: res.message,
    });
  }

  render() {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>
            {"Activation Processor | " + process.env.REACT_APP_SITE_TITLE}
          </title>
        </Helmet>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left">
              <img
                src="/assets/images/logo.png"
                height="54"
                alt={process.env.REACT_APP_SITE_TITLE}
              />
            </a>
            <form
              onSubmit={this.handleSubmit}
              id="form"
              className="form-horizontal"
            >
              <div className="panel panel-sign">
                <div className="panel-title-sign mt-xl text-right">
                  <h2 className="title text-uppercase text-bold m-none">
                    <i className="fa fa-user mr-xs"></i> Activation
                  </h2>
                </div>
                <div className="panel-body">
                  {this.state.processor == null ||
                    this.state.processor == undefined ||
                    this.state.processor.status != "Not Activate" ? (
                    this.state.alertshow ? (
                      <Error
                        className="form-group mb-lg"
                        style={{
                          minHeight: "100px",
                          color:
                            this.state.alerttype != "success" ? "red" : "green",
                        }}
                      >
                        {this.state.alertmsg}
                      </Error>
                    ) : (
                      ""
                    )
                  ) : (
                    <>
                      <div className="form-group mb-lg">
                        <h4>Welcome {this.state.processor.name}</h4>
                      </div>
                      {this.state.alertshow ? (
                        <Error
                          className="form-group mb-lg"
                          style={{
                            color:
                              this.state.alerttype != "success"
                                ? "red"
                                : "green",
                          }}
                        >
                          {this.state.alertmsg}
                        </Error>
                      ) : (
                        ""
                      )}
                      <div className="form-group mb-lg">
                        <label>Password</label>
                        <div className="input-group input-group-icon">
                          <input
                            required
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            autoFocus
                            placeholder="Enter password"
                            type="password"
                            maxLength={50}
                            className="form-control input-lg"
                          />
                          <span className="input-group-addon">
                            <span className="icon icon-lg">
                              <i className="fa fa-lock"></i>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="form-group mb-lg">
                        <div className="clearfix">
                          <label className="pull-left">Confirm Password</label>
                        </div>
                        <div className="input-group input-group-icon">
                          <input
                            required
                            name="confirmPassword"
                            type="password"
                            maxLength={50}
                            className="form-control input-lg"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            placeholder="Enter Confirm password"
                          />
                          <span className="input-group-addon">
                            <span className="icon icon-lg">
                              <i className="fa fa-lock"></i>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8"></div>
                        <div className="col-sm-4 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary hidden-xs"
                          >
                            Submit
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block btn-lg visible-xs mt-lg"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row">
                    <hr className="dotted short"></hr>
                    <p className="text-center">
                      Is account activated?{" "}
                      <a href={process.env.REACT_APP_URL_LOGIN}>Sign In!</a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; ParityFactory All rights reserved |{" "}
                <a
                  href="https://parityfactory.com/privacy-policy/"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </section>
      </div>
    );
  }
}
export default App;
