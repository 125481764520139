import React from "react";
import ListComponent from "../Base/ListComponent";
import { exportPdfWalnutTest } from "./export";
import { getLoggedUser } from "../../context/auth";
import CommonUtilities from "../Utilities/Common";
import { Button, Dropdown } from "react-bootstrap";
import FilterSelectContextControl from "../FormControl/FilterSelectContextControl";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import FilterMultiSelectControlFromSystemSetting from "../FormControl/FilterMultiSelectControlFromSystemSetting";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { WalnutTestProvider } from "../../context/WalnutTestContext";
import OptionDropdown from "../Base/OptionDropdown";
import BulkUpdateTestsPopup from "./BulkUpdateTestsPopup";
import {
  RELEASE_TYPE_AFTER_APPROVED,
  TEST_STATUS_NEW,
  TEST_STATUS_APPROVED,
  TEST_STATUS_WAITING_APPROVAL,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
  VALUE_QUERY_PARAM_IGNORE,
  FIELD_TYPE_CALCULATED,
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_PROCESSOR,
  USER_ROLE_GROWER,
  USER_ROLE_LAB_USER,
  USER_ROLE_STAFF,
} from "../Utilities/Constant";
import _, { isArray } from "lodash";
import { SECTION_META_DATA, SECTION_QUALITY_DATA } from "../Utilities/Constant";
import {
  configDataExportDynamic,
  configDataExportStatic,
} from "./configDataExport";
import String from "../Utilities/String";

var DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const query = new URLSearchParams(props.location.search);
    const nutTypeId = query.get("type");
    let nutTypeName = query.get("name");
    const isDynamic = query.get("dynamic");
    let viewingGrowerId = ``;
    if (
      nutTypeName === null ||
      nutTypeName === undefined ||
      nutTypeName.trim() === ""
    ) {
      nutTypeName = "Test";
    }
    if (loggedUser.role === USER_ROLE_GROWER) {
      const temp = CommonUtilities.extractGrowerInfo(loggedUser);
      viewingGrowerId = temp.viewingGrowerId;
    }

    let urladd = "";
    let urlapi = "";

    let filterStartDate = "";
    let filterEndDate = "";
    const filterGrowerName =
      loggedUser.role === "Growers" ? loggedUser.referenceUserId : "";
    const filterCropYear = "";
    const filterProcessorName = "";
    const filterVariety = "";
    const filterCertificateNo = "";
    const filterLotNumber = "";
    const filterFieldName = "";
    const filterStatus = "";

    let filterGrowerName4Api =
      filterGrowerName && filterGrowerName !== "" ? filterGrowerName : "all";
    let filterCropYear4Api = "all";
    let filterProcessorName4Api = "all";
    let filterVariety4Api = "all";
    let filterStartDate4Api = "all";
    let filterEndDate4Api = "all";
    let filterCertificateNo4Api = "all";
    let filterLotNumber4Api = "all";
    let filterFieldName4Api = "all";
    let filterStatus4Api = "all";

    if (loggedUser.role === "Lab Users") {
      urladd =
        process.env.REACT_APP_URL_WALNUT_TEST_LIST +
        "-add?type=" +
        nutTypeId +
        "&name=" +
        nutTypeName +
        "&dynamic=" +
        isDynamic;

      //View tests based on selected processor of lab user
      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "bylabuser/" +
        loggedUser.processorId +
        "/" +
        filterGrowerName4Api +
        "/" +
        filterCropYear4Api +
        "/" +
        filterVariety4Api +
        "/all/all" +
        "/" +
        filterCertificateNo4Api +
        "/" +
        filterLotNumber4Api +
        "/" +
        filterFieldName4Api +
        "/" +
        filterStatus4Api;
    } else if (
      loggedUser.role === "Processors" ||
      loggedUser.role === "Staffs"
    ) {
      urladd =
        process.env.REACT_APP_URL_WALNUT_TEST_LIST +
        "-add?type=" +
        nutTypeId +
        "&name=" +
        nutTypeName +
        "&dynamic=" +
        isDynamic;

      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "byprocessor/" +
        loggedUser.processorId +
        "/" +
        filterGrowerName4Api +
        "/" +
        filterCropYear4Api +
        "/" +
        filterVariety4Api +
        "/all/all" +
        "/" +
        filterCertificateNo4Api +
        "/" +
        filterLotNumber4Api +
        "/" +
        filterFieldName4Api +
        "/" +
        filterStatus4Api;
    } else if (loggedUser.role === "Growers") {
      urladd = "";
      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "bygrower/" +
        viewingGrowerId +
        "/" +
        filterCropYear4Api +
        "/" +
        filterVariety4Api +
        "/all/all" +
        "/" +
        filterCertificateNo4Api +
        "/" +
        filterLotNumber4Api +
        "/" +
        filterFieldName4Api;
    } else {
      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "byadmin/" +
        filterGrowerName4Api +
        "/" +
        filterProcessorName4Api +
        "/" +
        filterVariety4Api +
        "/" +
        filterStartDate4Api +
        "/" +
        filterEndDate4Api +
        "/" +
        filterCertificateNo4Api +
        "/" +
        filterLotNumber4Api +
        "/" +
        filterFieldName4Api +
        "/" +
        filterStatus4Api;
    }

    this.state.isFirstLoad = true;
    this.state.isValidProcessor = false;
    this.state.isValidTestType = true;
    //This state does not use in case administrator
    this.state.labeladd = urladd !== "" ? "Add" : "";
    this.state.urladd = urladd;
    this.state.urlapi = urlapi;
    this.state.urlapidelete = GlobalConfig.REACT_APP_API_WALNUT_TEST_URL;
    this.state.viewingGrowerId = viewingGrowerId;
    this.state.urllist = process.env.REACT_APP_URL_WALNUT_TEST_LIST;
    this.state.title = nutTypeName;
    this.state.pluraltitle = nutTypeName;

    this.state.filterStartDate = filterStartDate;
    this.state.filterEndDate = filterEndDate;
    this.state.filterCropYear = filterCropYear;
    this.state.filterGrowerName = filterGrowerName;
    this.state.filterProcessorName = filterProcessorName;
    this.state.filterVariety = filterVariety;
    this.state.filterCertificateNo = filterCertificateNo;
    this.state.filterLotNumber = filterLotNumber;
    this.state.filterFieldName = filterFieldName;
    this.state.filterStatus = filterStatus;
    this.state.firstChangeFilterCropYear = true;
    this.state.urlApiCropYearFilter = `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}cropyearsbytesttype/${loggedUser.processorId}/${nutTypeId}`;

    this.state.nutTypeId = nutTypeId;
    this.state.nutTypeName = nutTypeName;
    this.state.valueDateChange = "";

    this.state.images = [];
    this.state.image = "";
    this.state.size = "";
    this.state.name = "";
    this.state.showImage = false;
    this.state.index = -1;

    //paging
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;
    this.state.progressPending = false;
    this.state.startDate = "";
    this.state.endDate = "";

    //Using for column options
    this.state.pageName = "Tests";

    this.handleExportPdf = this.handleExportPdf.bind(this);

    this.state.selectedRows = [];
    this.state.releaseType = ``;
    this.state.dynamic = isDynamic;
    //Custom fields by test type
    this.state.customFields = [];
    this.state.testTypeSetting = {};
    this.state.isShowOverridePrice = false;
    this.state.netWeightUnit = ``;

    this.state.clearSelectedRows = false;
    this.state.childOverrideComponentDidMount = true;
  }

  componentDidMountChild() {
    if (
      loggedUser.role === USER_ROLE_ADMINISTRATOR &&
      this.state.isFirstLoad === true
    ) {
      this.setState({ isFirstLoad: false });
      this.filterItems();
    }
  }

  updateFilterControlUrls = () => {
    const query = new URLSearchParams(this.props.location.search);
    const nutTypeId = query.get("type");

    //Use for all roles below
    const urlApiVarietyFilter =
      GlobalConfig.REACT_APP_API_VARIETY_URL + "byParentId/" + nutTypeId;
    const urlApiStatusFilter =
      GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL + "likename/Test Status";

    //Only use for role is Administrator below
    const urlApiProcessorFilter =
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + "filterDataProcessor";

    //Use for role is Processor, Lab user or staff below
    const urlApiGrowerFilter =
      GlobalConfig.REACT_APP_API_GROWER_URL +
      "byprocessor/" +
      loggedUser.processorId;

    let filterControlUrls;
    if (loggedUser.role === USER_ROLE_GROWER) {
      filterControlUrls = {
        urlApiVarietyFilter: urlApiVarietyFilter,
      };
    }

    if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
      filterControlUrls = {
        urlApiGrowerFilter: GlobalConfig.REACT_APP_API_GROWER_URL,
        urlApiProcessorFilter: urlApiProcessorFilter,
        urlApiVarietyFilter: urlApiVarietyFilter,
        urlApiStatusFilter: urlApiStatusFilter,
      };
    }

    if (
      loggedUser.role === USER_ROLE_PROCESSOR ||
      loggedUser.role === USER_ROLE_LAB_USER ||
      loggedUser.role === USER_ROLE_STAFF
    ) {
      filterControlUrls = {
        urlApiGrowerFilter: urlApiGrowerFilter,
        urlApiVarietyFilter: urlApiVarietyFilter,
        urlApiStatusFilter:
          GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL + "likename/Test Status",
      };
    }

    this.setState({
      filterControlUrls: filterControlUrls,
    });
  };

  queryTests = () => {
    //If user is not admin and invalid processor (checked at first load), do not get data
    if (this.state.isFirstLoad === true) {
      this.setState({ isFirstLoad: false });
      if (loggedUser.role !== "Administrators") {
        this.checkValidProcessor();
      } else {
        this.filterItems();
      }
    }
  };

  proceedQuery() {
    if (this.state.dynamic === `true`) {
      const that = this;
      fetch(
        `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}getByProcessorAndId/${loggedUser.processorId}/${this.state.nutTypeId}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (responseOrderFields) {
          if (responseOrderFields.result === "OK") {
            if (responseOrderFields.data.isInUse === false) {
              const errorTestTypeUnpublished =
                "Selected test type is unpublished! Please refresh and switch to other type.";
              that.setState({
                useExtendBody: true,
                extendBodyMessage: errorTestTypeUnpublished,
                alertmsg: errorTestTypeUnpublished,
                alerttype: "error",
                alertshow: true,
              });
            } else {
              let isShowOverridePrice = false;
              if (responseOrderFields.data) {
                isShowOverridePrice =
                  !responseOrderFields.data.overridePriceHide &&
                  that.state.dynamic === `true`;
              }
              that.setState({
                testTypeSetting: responseOrderFields.data,
                isShowOverridePrice: isShowOverridePrice,
              });
            }
          } else {
            that.setState({
              useExtendBody: true,
              extendBodyMessage: responseOrderFields.message,
              alertmsg: responseOrderFields.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            useExtendBody: true,
            extendBodyMessage: error,
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });

      //Query dynamic fields belong to test type stand on
      fetch(
        `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}getDynamicFieldsByTestType/${this.state.nutTypeId}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          if (response && response.result === "OK") {
            let isValidTestType = false;
            if (response.testType.isInUse === false) {
              that.setState({
                isValidTestType: isValidTestType,
                invalidTestTypeMessage: response.message,
                useExtendBody: true,
              });
            } else {
              isValidTestType = true;
              that.setState(
                {
                  isValidTestType: isValidTestType,
                  customFields: response.data,
                  netWeightUnit: response.testType.netWeightUnit,
                },
                () => {
                  that.filterItems();
                  that.loadColumnSettings();
                  if (
                    loggedUser.role === `Staffs` ||
                    loggedUser.role === `Processors`
                  ) {
                    that.queryProcessorInfo();
                  }
                }
              );
            }
          } else {
            that.setState({
              isValidTestType: false,
              invalidTestTypeMessage: response.message,
              useExtendBody: true,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            isValidTestType: false,
            invalidTestTypeMessage: error,
            useExtendBody: true,
          });
        });
    } else {
      this.filterItems();
      this.loadColumnSettings();
      if (loggedUser.role === `Staffs` || loggedUser.role === `Processors`) {
        this.queryProcessorInfo();
      }
    }
  }

  async queryProcessorInfo() {
    const that = this;
    //Query processor's info
    fetch(
      `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}getprocessorproperty/${loggedUser.processorId}/releaseType`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState({
            releaseType: resultObject.data[`releaseType`],
          });
        } else {
          that.setState({
            releaseType: ``,
          });
        }
      })
      .catch(function (error) {
        that.setState({
          releaseType: ``,
        });
        console.log(error);
      });
  }

  handleDeleteAfterDeletedOnServer = (removedId) => {
    window.showAlert("Success", "Removed successful!", "");
    //If updated successful
    this.setState(
      {
        currentPage: 1,
      },
      function () {
        this.doSomethingAfterDeletedItem();
        this.getItems(this.state.currentPage);
      }
    );
  };

  checkValidProcessor() {
    if (loggedUser.role !== "Administrators") {
      const query = new URLSearchParams(this.props.location.search);
      const nutTypeId = query.get("type");
      //Missing parameter test type
      if (
        nutTypeId === undefined ||
        nutTypeId === null ||
        nutTypeId.trim() === ""
      ) {
        this.setState({
          urladd: "",
        });
        window.showAlert(
          "Error",
          "Could not find test type for query tests. Please ensure link correctly.",
          "error"
        );
        return;
      }
      let isValidProcessor = true;
      const that = this;
      fetch(GlobalConfig.REACT_APP_API_PROCESSOR_URL + loggedUser.processorId)
        .then(function (response) {
          return response.json();
        })
        .then(function (item) {
          if (item != null && item.result === "OK" && item.data) {
            let useExtendBody4LabUser = false;
            if (
              loggedUser.role === "Processors" ||
              loggedUser.role === "Staffs" ||
              loggedUser.role === "Lab Users"
            ) {
              if (
                item.data.certificatePrefix == null ||
                item.data.certificatePrefix === undefined ||
                item.data.certificatePrefix === "" ||
                item.data.certificateSeed == null ||
                item.data.certificateSeed === undefined
              ) {
                isValidProcessor = false;
              } else if (loggedUser.role === "Lab Users") {
                if (
                  loggedUser.name == null ||
                  loggedUser.name === undefined ||
                  loggedUser.name === ""
                ) {
                  isValidProcessor = false;
                  useExtendBody4LabUser = true;
                }
              }
            }

            if (isValidProcessor === false) {
              that.setState({
                useExtendBody: true,
                useExtendBody4LabUser: useExtendBody4LabUser,
              });
            }
            that.setState({ isValidProcessor: isValidProcessor }, () =>
              that.proceedQuery()
            );
          } else {
            isValidProcessor = false;
            window.showAlert(
              "Error",
              "Processor does not exist longer. Please switch to other processor and try again.",
              "error"
            );

            that.setState({ isValidProcessor: isValidProcessor }, () =>
              that.proceedQuery()
            );
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    }
  }

  checkTestTypeExistBulkUpdateField(fields) {
    if (fields.length > 0) {
      return fields.some((field) => field.allowBulkUpdate);
    } else return false;
  }

  getItems = async (page, size = this.state.perPage) => {
    const that = this;
    that.setState({
      progressPending: true,
    });
    fetch(
      this.state.urlapi +
      `/${page}/${size}` +
      "?nutTypeId=" +
      this.state.nutTypeId
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            progressPending: false,
          },
          () => {
            that.doSomethingAfterGetItems(resultObject);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
        that.setState({
          progressPending: false,
        });
      });
  };

  getTotalItems = () => {
    return this.state.totalRows;
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.getItems(page, newPerPage);
    this.setState({ perPage: newPerPage });
  };

  handlePageChange = (page) => {
    this.getItems(page);
    this.setState({ currentPage: page });
  };

  doSomethingAfterGetItems = (data) => {
    this.setState({ totalRows: data ? data.totalCount : 0, loading: false });
  };

  onSelectedRowsChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    this.setClearSelectedRows(false);
    this.setSelectedRows(state.selectedRows);
  };

  defaultTable = () => {
    return this.state.urlapi === null || this.state.urlapi === undefined ? (
      ""
    ) : (
      <>
        <DataTable
          selectableRows={
            (loggedUser.role === "Processors" ||
              loggedUser.role === "Staffs") &&
            (this.state.releaseType === RELEASE_TYPE_AFTER_APPROVED ||
              this.state.dynamic === `true`)
          }
          clearSelectedRows={this.state.clearSelectedRows}
          onSelectedRowsChange={this.onSelectedRowsChange}
          highlightOnHover
          striped
          pointerOnHover={false}
          id="emp"
          title=""
          noHeader={true}
          columns={this.setColumns()}
          data={this.state.items}
          className="table table-bordered table-striped mb-none"
          conditionalRowStyles={this.conditionalRowStyles()}
          pagination={true}
          paginationPerPage={this.state.paginationPerPage}
          paginationRowsPerPageOptions={this.state.rowPerPageOption}
          paginationServer={true}
          paginationTotalRows={this.state.totalRows}
          paginationDefaultPage={this.state.currentPage}
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          progressPending={this.state.progressPending}
          progressComponent={this.defaultProgressComponent()}
        />
      </>
    );
  };

  renderCertificateNoByUser = (row) => {
    let isAllowEdit = false;
    if (loggedUser.role === "Lab Users") {
      if (loggedUser.referenceUserId == row.labUserId) {
        isAllowEdit = true;
      }
    } else if (loggedUser.role === "Processors") {
      if (loggedUser.referenceUserId == row.processorId) {
        isAllowEdit = true;
      }
    }
    if (isAllowEdit) {
      return (
        <a
          href={
            this.state.urllist +
            "/" +
            row._id +
            "?type=" +
            this.state.nutTypeId +
            "&name=" +
            this.state.nutTypeName +
            "&dynamic=" +
            this.state.dynamic
          }
          className="on-default edit-row"
          title="Click here to edit"
        >
          {row.certificateNo}
        </a>
      );
    }
    return (
      <a
        href={
          this.state.urllist +
          "/" +
          row._id +
          "?type=" +
          this.state.nutTypeId +
          "&name=" +
          this.state.nutTypeName +
          "&dynamic=" +
          this.state.dynamic
        }
        className="on-default edit-row"
        title="Click here to view"
      >
        {row.certificateNo}
      </a>
    );
  };

  renderActionEditByUser = (row) => {
    let isAllowEdit = false;
    if (loggedUser.role == "Lab Users") {
      if (loggedUser.referenceUserId == row.labUserId) {
        isAllowEdit = true;
      }
    } else if (loggedUser.role == "Processors") {
      if (loggedUser.referenceUserId == row.processorId) {
        isAllowEdit = true;
      }
    }
    if (isAllowEdit) {
      return (
        <Dropdown.Item
          href={
            this.state.urllist +
            "/" +
            row._id +
            "?type=" +
            this.state.nutTypeId +
            "&name=" +
            this.state.nutTypeName +
            "&dynamic=" +
            this.state.dynamic
          }
          title="Click here to edit"
        >
          <OptionDropdown icon="pencil" title="Edit" />
        </Dropdown.Item>
      );
    }
    return "";
  };

  renderActionWorkflow = (row) => {
    if (
      (loggedUser.role === "Processors" || loggedUser.role === "Staffs") &&
      this.state.releaseType === RELEASE_TYPE_AFTER_APPROVED
    ) {
      return (
        <>
          <Dropdown.Item
            onClick={(e) =>
              this.updateStatusSingleTest(row, TEST_STATUS_APPROVED)
            }
            title="Approve for release"
          >
            <OptionDropdown icon="check" title="Approve for release" />
          </Dropdown.Item>

          <Dropdown.Item
            onClick={(e) =>
              this.updateStatusSingleTest(row, TEST_STATUS_WAITING_APPROVAL)
            }
            className="on-default edit-row"
            title="Revert approve"
          >
            <OptionDropdown icon="repeat" title="Revert approve" />
          </Dropdown.Item>
        </>
      );
    }
    return "";
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  formatStatusColumn = (row) => {
    switch (row.status) {
      case TEST_STATUS_APPROVED:
        return (
          <span
            className="label label-success"
            style={{
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            {row.status}
          </span>
        );
      case TEST_STATUS_WAITING_APPROVAL:
        return (
          <span
            className="label label-warning"
            style={{
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            {row.status}
          </span>
        );
      case TEST_STATUS_NEW:
        return (
          <span
            className="label label-primary"
            style={{
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            {row.status}
          </span>
        );
      default:
        return row.status;
    }
  };

  handleGroupSelectedField = (array) => {
    const grouped = _.groupBy(array, (arrayItem) =>
      arrayItem.nutTypeGroupId !== ""
        ? arrayItem.nutTypeGroupId
        : arrayItem.section
    );

    return grouped;
  };

  defaultColumns = () => {
    let columns = [
      {
        name: "Certificate No",
        selector: "certificateNo",
        sortable: true,
        left: true,
        cell: (row) => this.renderCertificateNoByUser(row),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        omit:
          loggedUser.role === "Growers" ||
          this.state.releaseType !== RELEASE_TYPE_AFTER_APPROVED,
        cell: (row) => {
          return row.status === undefined || row.status === ``
            ? `N/A`
            : this.formatStatusColumn(row);
        },
      },
      {
        name: "Test Date",
        selector: "testDate",
        sortable: true,
        center: true,
        cell: (row) => {
          return DateFormat(row.testDate, "mm/dd/yyyy");
        },
        minWidth: "160px",
      },
      {
        name: "Field Name",
        selector: "fieldName",
        sortable: true,
        left: true,
        omit: this.state.testTypeSetting.fieldNameHide === true,
      },
      {
        name: "Lot Number",
        selector: "lotNumber",
        sortable: true,
        left: true,
      },
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
        left: true,
        omit: loggedUser.role !== "Administrators",
      },
      {
        name: "Grower Name",
        selector: "growerNameExt",
        sortable: true,
        left: true,
        omit: loggedUser.role === "Growers",
      },
      {
        name: "Variety Submitted",
        selector: "varitySubmitted",
        sortable: true,
        left: true,
      },
      {
        name: "DFA Inspector",
        selector: "dfaInspectorExt",
        sortable: true,
        left: true,
        omit: this.state.testTypeSetting.dfaInspectorHide === true,
      },
      {
        name:
          this.state.dynamic === `true`
            ? this.state.netWeightUnit !== ``
              ? `Net Weight (${this.state.netWeightUnit.toUpperCase()})`
              : `Net Weight`
            : "Net Weight (LBS)",
        selector: "netWeight",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.netWeight == null ||
              row.netWeight === undefined ||
              isNaN(row.netWeight)
              ? 0
              : row.netWeight
            ).toFixed(1)
          ),
      },
      {
        name: "Edible Yield",
        selector: "s3EdibleYield",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.s3EdibleYield == null ||
              row.s3EdibleYield === undefined ||
              isNaN(row.s3EdibleYield)
              ? 0
              : parseFloat(row.s3EdibleYield)
            ).toFixed(1)
          ) + "%",
        omit: this.state.dynamic === `true`,
      },
      {
        name: "Override Price",
        selector: "overridePrice",
        sortable: true,
        right: true,
        cell: (row) => {
          const isValueJustTouched = row.overridePrice === "";
          if (isValueJustTouched) return "";

          return CommonUtilities.numberWithCommas(
            (row.overridePrice == null ||
              row.overridePrice === undefined ||
              isNaN(row.overridePrice)
              ? 0
              : parseFloat(row.overridePrice)
            ).toFixed(2)
          );
        },
        omit:
          this.state.dynamic !== `true` ||
          this.state.testTypeSetting.overridePriceHide === true,
      },
    ];

    if (this.state.customFields && this.state.customFields.length > 0) {
      const combineFields = CommonUtilities.handleCombineFields(
        this.state.testTypeSetting.selectedFields,
        this.state.customFields
      );

      const selectedFields = this.handleGroupSelectedField(combineFields);
      const sections = [SECTION_META_DATA, SECTION_QUALITY_DATA];

      sections.map((section) => {
        const groups = this.state.testTypeSetting
          ? section === SECTION_META_DATA
            ? this.state.testTypeSetting.groupMetaData
              ? this.state.testTypeSetting.groupMetaData
              : []
            : this.state.testTypeSetting.groupQualityData
              ? this.state.testTypeSetting.groupQualityData
              : []
          : [];

        selectedFields?.[section]?.map((f) => {
          const center = f.fieldType === FIELD_TYPE_DROP_DOWN;
          const right =
            f.fieldType === FIELD_TYPE_INTEGER ||
            f.fieldType === FIELD_TYPE_PERCENT ||
            f.fieldType === FIELD_TYPE_DECIMAL ||
            f.fieldType === FIELD_TYPE_CALCULATED;
          columns.push({
            name: f.name,
            selector: f.name,
            sortable: false,
            center: center,
            right: right,
            cell: (row) => {
              if (f.fieldType === FIELD_TYPE_PERCENT) {
                return `${row[f.name]}%`;
              }
              return row[f.name];
            },
          });
        });

        groups.map((g) => {
          return selectedFields?.[g._id]?.map((f) => {
            const center = f.fieldType === FIELD_TYPE_DROP_DOWN;
            const right =
              f.fieldType === FIELD_TYPE_INTEGER ||
              f.fieldType === FIELD_TYPE_PERCENT ||
              f.fieldType === FIELD_TYPE_DECIMAL ||
              f.fieldType === FIELD_TYPE_CALCULATED;
            columns.push({
              name: f.name,
              selector: f.name,
              sortable: false,
              center: center,
              right: right,
              cell: (row) => {
                if (f.fieldType === FIELD_TYPE_PERCENT) {
                  return `${row[f.name]}%`;
                }
                return row[f.name];
              },
            });
          });
        });
      });
    }

    columns.push({
      name: "",
      selector: "actions",
      sortable: false,
      center: true,
      allowOverflow: true,
      width: "180px",
      cell: (row) => {
        const itemsLength = isArray(this.state.items)
          ? this.state.items.length
          : 0;
        // When items length in table is smaller than 7, adjust action dropdown to overflow table and position always show down
        // When items length in table is greater than 7, action dropdown maybe show (up, down)
        const popperConfig = itemsLength < 7 && {
          strategy: "fixed",
        };
        return (
          <Dropdown>
            <Dropdown.Toggle align="center" className="dropdown-toggle-custom">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={popperConfig}>
              {this.renderActionWorkflow(row)}
              {this.renderActionEditByUser(row)}
              {loggedUser.role === "Administrators" ||
                loggedUser.role === "Growers" ? (
                ""
              ) : (
                <>
                  <Dropdown.Item
                    data-id={row._id}
                    onClick={this.handleDelete.bind(this)}
                    title="Click here to remove"
                  >
                    <OptionDropdown icon="trash-o" title="Remove" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-id={row._id}
                    onClick={this.handleExportPdf}
                    title="Click here to print form"
                  >
                    <OptionDropdown icon="print" title="Export PDF" />
                  </Dropdown.Item>
                </>
              )}
              {loggedUser.role === "Administrators" ||
                loggedUser.role === "Processors" ||
                loggedUser.role === "Staffs" ? (
                <Dropdown.Item
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                >
                  <OptionDropdown icon="history" title="Revision History" />
                </Dropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    });

    return columns;
  };

  handleExportPdf = async (e) => {
    exportPdfWalnutTest(
      GlobalConfig.REACT_APP_API_WALNUT_TEST_URL + "exportpdf",
      e.currentTarget.dataset.id,
      loggedUser._id
    );
  };

  exportExcel = async () => {
    window.showAlert(
      "Info",
      `Exporting...the file is ready download once done.`,
      "info"
    );
    let items = [];
    const totalPages = Math.ceil(this.state.totalRows / this.state.perPage);
    if (totalPages > 0) {
      for (let i = 1; i <= totalPages; i++) {
        const response = await fetch(
          `${this.state.urlapi}/${i}/${this.state.perPage}?nutTypeId=${this.state.nutTypeId}&isExport=true`
        );
        const data = await response.json();
        if (data.data && data.data.length > 0) {
          items = items.concat(data.data);
        }
      }

      if (items && items.length > 0) {
        items = _.sortBy(items, [
          { propertyName: "testDate", order: "desc" },
          { propertyName: "createdDate", order: "desc" },
        ]);
      }
    }

    const parsedItems = [];
    if (items) {
      items.map((item) => {
        var dataAfterHandle = {};
        var dataContainer = {};

        if (this.state.dynamic === `true`) {
          dataAfterHandle = {};
          dataContainer = {
            data: item,
            loggedUser: loggedUser,
            releaseType: this.state.releaseType,
            dynamic: this.state.dynamic,
            netWeightUnit: this.state.netWeightUnit,
            testTypeSetting: this.state.testTypeSetting,
            customFields: this.state.customFields,
            RELEASE_TYPE_AFTER_APPROVED: RELEASE_TYPE_AFTER_APPROVED,
            SECTION_META_DATA: SECTION_META_DATA,
            SECTION_QUALITY_DATA: SECTION_QUALITY_DATA,
          };
          const getConfigDataExport = configDataExportDynamic(dataContainer);

          getConfigDataExport
            .filter((item) => {
              return item.omit === undefined || item.omit === false;
            })
            .map((item) => (dataAfterHandle[item.name] = item.value));

          parsedItems.push(dataAfterHandle);
        } else {
          dataAfterHandle = {};
          dataContainer = {
            data: item,
            loggedUser: loggedUser,
          };
          const getConfigDataExport = configDataExportStatic(dataContainer);

          getConfigDataExport
            .filter((item) => {
              return item.omit === undefined || item.omit === false;
            })
            .map((item) => (dataAfterHandle[item.name] = item.value));

          parsedItems.push(dataAfterHandle);
        }
      });

      //Export excel
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const ws = XLSX.utils.json_to_sheet(parsedItems);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Tests.xlsx");
    }
    return parsedItems;
  };

  renderInvalidProcessorProfile() {
    return loggedUser.role === "Processors" ? (
      <>
        Please fill out{" "}
        <strong>Certification Prefix and Certification Seed No</strong>. It is
        required data for creating tree nut tests. Click{" "}
        <a href={process.env.REACT_APP_URL_MY_PROFILE} className="alert-link">
          here
        </a>{" "}
        to update profile.{" "}
      </>
    ) : loggedUser.role === "Lab Users" ? (
      this.state.useExtendBody4LabUser !== true ? (
        <>
          Please inform processor <strong>{loggedUser.processorName}</strong>{" "}
          fill out{" "}
          <strong>Certification Prefix and Certification Seed No</strong>. It is
          required data for creating tree nut tests.
        </>
      ) : (
        <>
          Please complete update your profile. It is required data for creating
          tree nut tests. Click{" "}
          <a href={process.env.REACT_APP_URL_MY_PROFILE} className="alert-link">
            here
          </a>{" "}
          to update profile.{" "}
        </>
      )
    ) : (
      ""
    );
  }

  renderInvalidTestType() {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {this.state.invalidTestTypeMessage}
      </div>
    );
  }

  extendBody = () => {
    return this.state.isValidTestType !== true ? (
      this.renderInvalidTestType()
    ) : (
      <div className="alert alert-success">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {this.renderInvalidProcessorProfile()}
      </div>
    );
  };

  handleCloseModalImage = () => {
    this.setState({ showImage: false });
  };

  handleMoveImage = (index) => {
    const data = this.state.images[index];
    this.setState({
      image: data.image,
      name: data.name,
      size: data.size,
      index: index,
    });
  };

  updateStatusSingleTest = async (row, status) => {
    const result = this.updateStatusToDatabase(status, row._id, false, row);
    row.status = status;
  };

  updateStatus = async (status) => {
    if (this.state.selectedRows && this.state.selectedRows.length > 0) {
      const ids = this.state.selectedRows.map((x) => x._id).join(`,`);
      this.updateStatusToDatabase(status, ids, true);
    } else {
      window.showAlert(
        "Info",
        GlobalConfig.REACT_APP_MSG_EMPTY_SELECT_ROW,
        "info"
      );
    }
  };

  setClearSelectedRows = (value) => {
    this.setState({
      clearSelectedRows: value,
    });
  };

  setSelectedRows = (value) => {
    this.setState({
      selectedRows: value,
    });
  };

  updateStatusToDatabase = async (status, ids, isMultiple, row) => {
    const that = this;
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ids: ids,
        status: status,
        modifiedUserId: loggedUser._id,
      }),
    };
    fetch(
      `${GlobalConfig.REACT_APP_API_WALNUT_TEST_URL}updatestatus`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          window.showAlert("", "Update successful!", "");
          if (isMultiple) {
            const selectedRows = [...that.state.selectedRows];
            selectedRows.map((x) => {
              x.status = status;
            });
            that.setState({ selectedRows: selectedRows });
          } else {
            row.status = status;
          }
          return "OK";
        }
        window.showAlert("Error", resultObject.message, "error");
        return "ERROR";
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
        return "ERROR";
      });
  };

  extendButtonsBeforeColumnOptions = () => {
    return (loggedUser.role === "Processors" || loggedUser.role === "Staffs") &&
      (this.state.releaseType === RELEASE_TYPE_AFTER_APPROVED ||
        this.state.dynamic === `true`) &&
      this.state.totalRows > 0 ? (
      <div className="panel-btn-group-item-position">
        {this.defaultButtons()}
        {this.exportButton()}

        <Button
          variant="info"
          onClick={(e) => this.updateStatus(TEST_STATUS_WAITING_APPROVAL)}
          className="mb-sm"
        >
          <i className="fa fa-repeat"></i> Revert Approve
        </Button>
        <Button
          variant="success"
          onClick={(e) => this.updateStatus(TEST_STATUS_APPROVED)}
          title="Approve for release"
          className="mb-sm"
        >
          <i className="fa fa-check"></i> Approve
        </Button>
        {this.state.dynamic === `true` ? (
          <BulkUpdateTestsPopup
            selectedRows={this.state.selectedRows}
            filterItems={this.filterItems}
            setClearSelectedRows={this.setClearSelectedRows}
            setSelectedRows={this.setSelectedRows}
            processorId={loggedUser.processorId}
            testTypeId={
              this.state.testTypeSetting ? this.state.testTypeSetting._id : ""
            }
            isShowOverridePrice={this.state.isShowOverridePrice}
            existBulkUpdateField={this.checkTestTypeExistBulkUpdateField(
              this.state.testTypeSetting.allFields
                ? this.state.testTypeSetting.allFields
                : []
            )}
          />
        ) : (
          ""
        )}
      </div>
    ) : (
      <div className="panel-btn-group-item-position">
        {this.defaultButtons()}
        {this.exportButton()}
        <BulkUpdateTestsPopup
          selectedRows={this.state.selectedRows}
          filterItems={this.filterItems}
          setClearSelectedRows={this.setClearSelectedRows}
          setSelectedRows={this.setSelectedRows}
          processorId={loggedUser.processorId}
          testTypeId={
            this.state.testTypeSetting ? this.state.testTypeSetting._id : ""
          }
          isShowOverridePrice={this.state.isShowOverridePrice}
          existBulkUpdateField={this.checkTestTypeExistBulkUpdateField(
            this.state.testTypeSetting.allFields
              ? this.state.testTypeSetting.allFields
              : []
          )}
        />
      </div>
    );
  };

  exportButton = () => {
    return this.state.totalRows > 0 ? (
      <Button
        variant="warning"
        onClick={(e) => this.exportExcel()}
        className="mb-sm"
      >
        <i className="fa fa-file-excel-o fa-lg"></i> Export
      </Button>
    ) : (
      ""
    );
  };

  addButton = () => {
    if (
      this.state.excludeDefaultButtons === true ||
      this.state.urladd === null ||
      this.state.urladd === undefined ||
      this.state.urladd === ""
    ) {
      return "";
    }
    return (
      <a href={this.state.urladd} className="btn btn-primary mb-sm">
        <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
      </a>
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      loggedUser.role === "Administrators" ||
        loggedUser.role === "Processors" ||
        loggedUser.role === "Staffs" ||
        loggedUser.role === "Lab Users" ||
        loggedUser.role === "Growers"
        ? function callBack() {
            if (controlId === "filterCropYear") {
              if (this.state.firstChangeFilterCropYear) {
                this.queryTests();
              }
              this.setState({
                firstChangeFilterCropYear: false,
                valueDateChange: "",
                filterStartDate: "",
                filterEndDate: "",
                startDate: "",
                endDate: "",
              });
            }
          }
        : function callBack() {
            this.filterItems();
          }
    );
  };

  handleStartDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState(
        {
          filterStartDate: getDate,
          startDate: date,
          valueDateChange: "all",
          filterCropYear: "",
        },
        loggedUser.role === "Administrators" ||
          loggedUser.role === "Processors" ||
          loggedUser.role === "Staffs" ||
          loggedUser.role === "Lab Users" ||
          loggedUser.role === "Growers"
          ? function callBack() {
            return;
          }
          : function callBack() {
            this.filterItems();
          }
      );
    } else {
      this.setState(
        {
          filterStartDate: "",
          startDate: "",
        },
        loggedUser.role === "Administrators" ||
          loggedUser.role === "Processors" ||
          loggedUser.role === "Staffs" ||
          loggedUser.role === "Lab Users" ||
          loggedUser.role === "Growers"
          ? function callBack() {
            return;
          }
          : function callBack() {
            this.filterItems();
          }
      );
    }
  };

  handleEndDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState(
        {
          filterEndDate: getDate,
          endDate: date,
          valueDateChange: "all",
          filterCropYear: "",
        },
        loggedUser.role === "Administrators" ||
          loggedUser.role === "Processors" ||
          loggedUser.role === "Staffs" ||
          loggedUser.role === "Lab Users" ||
          loggedUser.role === "Growers"
          ? function callBack() {
            return;
          }
          : function callBack() {
            this.filterItems();
          }
      );
    } else {
      this.setState(
        {
          filterEndDate: "",
          endDate: "",
        },
        loggedUser.role === "Administrators" ||
          loggedUser.role === "Processors" ||
          loggedUser.role === "Staffs" ||
          loggedUser.role === "Lab Users" ||
          loggedUser.role === "Growers"
          ? function callBack() {
            return;
          }
          : function callBack() {
            this.filterItems();
          }
      );
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      return;
    }
  }

  filterItems = async () => {
    let urlapi = "";
    let filterGrowerName =
      this.state.filterGrowerName && this.state.filterGrowerName !== ""
        ? this.state.filterGrowerName
        : "all";
    let filterCropYear =
      this.state.filterCropYear && this.state.filterCropYear !== ""
        ? this.state.filterCropYear
        : "all";
    let filterProcessorName =
      this.state.filterProcessorName && this.state.filterProcessorName !== ""
        ? this.state.filterProcessorName
        : "all";
    let filterVariety =
      this.state.filterVariety && this.state.filterVariety !== ""
        ? this.state.filterVariety
        : "all";

    let filterCertificateNo = String.isEmpty(this.state.filterCertificateNo)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterCertificateNo.trim());

    let filterLotNumber = String.isEmpty(this.state.filterLotNumber)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterLotNumber.trim());

    let filterFieldName = String.isEmpty(this.state.filterFieldName)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterFieldName.trim());

    let filterStatus =
      this.state.filterStatus && this.state.filterStatus !== ""
        ? this.state.filterStatus
        : "all";

    if (loggedUser.role === "Lab Users") {
      const startDate =
        this.state.filterStartDate && this.state.filterStartDate !== ""
          ? this.state.filterStartDate
          : "all";
      const endDate =
        this.state.filterEndDate && this.state.filterEndDate !== ""
          ? this.state.filterEndDate
          : "all";

      if (
        (startDate != null &&
          startDate !== undefined &&
          startDate !== "" &&
          startDate !== "all" &&
          !CommonUtilities.isValidDate(startDate)) ||
        (endDate != null &&
          endDate !== undefined &&
          endDate !== "" &&
          endDate !== "all" &&
          !CommonUtilities.isValidDate(endDate))
      ) {
        return;
      }

      let sDate = undefined;
      let eDate = undefined;
      if (startDate !== "all") {
        sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
      }
      if (endDate !== "all") {
        eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
      }

      const startDateFilter = startDate !== "all" ? sDate : startDate;
      const endDateFilter = endDate !== "all" ? eDate : endDate;

      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "bylabuser/" +
        loggedUser.processorId +
        "/" +
        filterGrowerName +
        "/" +
        filterCropYear +
        "/" +
        filterVariety +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter +
        "/" +
        filterCertificateNo +
        "/" +
        filterLotNumber +
        "/" +
        filterFieldName +
        "/" +
        filterStatus;
    } else if (
      loggedUser.role === "Processors" ||
      loggedUser.role === "Staffs"
    ) {
      const startDate =
        this.state.filterStartDate && this.state.filterStartDate !== ""
          ? this.state.filterStartDate
          : "all";
      const endDate =
        this.state.filterEndDate && this.state.filterEndDate !== ""
          ? this.state.filterEndDate
          : "all";

      if (
        (startDate != null &&
          startDate !== undefined &&
          startDate !== "" &&
          startDate !== "all" &&
          !CommonUtilities.isValidDate(startDate)) ||
        (endDate != null &&
          endDate !== undefined &&
          endDate !== "" &&
          endDate !== "all" &&
          !CommonUtilities.isValidDate(endDate))
      ) {
        return;
      }

      let sDate = undefined;
      let eDate = undefined;
      if (startDate !== "all") {
        sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
      }
      if (endDate !== "all") {
        eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
      }

      const startDateFilter = startDate !== "all" ? sDate : startDate;
      const endDateFilter = endDate !== "all" ? eDate : endDate;

      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "byprocessor/" +
        loggedUser.processorId +
        "/" +
        filterGrowerName +
        "/" +
        filterCropYear +
        "/" +
        filterVariety +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter +
        "/" +
        filterCertificateNo +
        "/" +
        filterLotNumber +
        "/" +
        filterFieldName +
        "/" +
        filterStatus;
    } else if (loggedUser.role === "Growers") {
      const startDate =
        this.state.filterStartDate && this.state.filterStartDate !== ""
          ? this.state.filterStartDate
          : "all";
      const endDate =
        this.state.filterEndDate && this.state.filterEndDate !== ""
          ? this.state.filterEndDate
          : "all";

      if (
        (startDate != null &&
          startDate !== undefined &&
          startDate !== "" &&
          startDate !== "all" &&
          !CommonUtilities.isValidDate(startDate)) ||
        (endDate != null &&
          endDate !== undefined &&
          endDate !== "" &&
          endDate !== "all" &&
          !CommonUtilities.isValidDate(endDate))
      ) {
        return;
      }

      let sDate = undefined;
      let eDate = undefined;
      if (startDate !== "all") {
        sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
      }
      if (endDate !== "all") {
        eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
      }

      const startDateFilter = startDate !== "all" ? sDate : startDate;
      const endDateFilter = endDate !== "all" ? eDate : endDate;

      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "bygrower/" +
        this.state.viewingGrowerId +
        "/" +
        filterCropYear +
        "/" +
        filterVariety +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter +
        "/" +
        filterCertificateNo +
        "/" +
        filterLotNumber +
        "/" +
        filterFieldName;
    } else if (loggedUser.role === "Administrators") {
      const startDate =
        this.state.filterStartDate && this.state.filterStartDate !== ""
          ? this.state.filterStartDate
          : "all";
      const endDate =
        this.state.filterEndDate && this.state.filterEndDate !== ""
          ? this.state.filterEndDate
          : "all";

      if (
        (startDate != null &&
          startDate !== undefined &&
          startDate !== "" &&
          startDate !== "all" &&
          !CommonUtilities.isValidDate(startDate)) ||
        (endDate != null &&
          endDate !== undefined &&
          endDate !== "" &&
          endDate !== "all" &&
          !CommonUtilities.isValidDate(endDate))
      ) {
        return;
      }

      let sDate = undefined;
      let eDate = undefined;
      if (startDate !== "all") {
        sDate = moment(startDate + " 00:00:00", "MM/DD/YYYY HH:mm:ss");
      }
      if (endDate !== "all") {
        eDate = moment(endDate + " 23:59:59", "MM/DD/YYYY HH:mm:ss");
      }

      const startDateFilter = startDate !== "all" ? sDate : startDate;
      const endDateFilter = endDate !== "all" ? eDate : endDate;
      urlapi =
        GlobalConfig.REACT_APP_API_WALNUT_TEST_URL +
        "byadmin/" +
        filterGrowerName +
        "/" +
        filterProcessorName +
        "/" +
        filterVariety +
        "/" +
        startDateFilter +
        "/" +
        endDateFilter +
        "/" +
        filterCertificateNo +
        "/" +
        filterLotNumber +
        "/" +
        filterFieldName +
        "/" +
        filterStatus;
    } else {
      urlapi = GlobalConfig.REACT_APP_API_WALNUT_TEST_URL + filterGrowerName;
    }
    this.setState({ urlapi: urlapi, currentPage: 1 }, () => {
      this.getItems(this.state.currentPage, this.state.perPage);
    });
  };

  handleReset = () => {
    this.filterCropYearControl?.reset();
    this.growerControl?.reset();
    this.processorControl?.reset();
    this.varietyControl?.reset();
    this.statusControl?.reset();
    this.setState(
      {
        filterStartDate: "",
        startDate: "",
        filterEndDate: "",
        endDate: "",
        filterCropYear: "",
        filterGrowerName: "",
        filterProcessorName: "",
        filterVariety: "",
        filterCertificateNo: "",
        filterLotNumber: "",
        filterFieldName: "",
        filterStatus: "",
      },
      () => this.filterItems()
    );
  };

  renderSearch = () => {
    if (loggedUser.role === "Growers") {
      return (
        <WalnutTestProvider value={this.state}>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.varietyControl = ref)}
                  placeholder="Filter Variety"
                  label="Filter Variety"
                  controlId="filterVariety"
                  urlapilist={this.state.filterControlUrls?.urlApiVarietyFilter}
                />
              </div>

              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <input
                  type="text"
                  className="form-control inputRenderSearch inputFilter"
                  name="filterCertificateNo"
                  placeholder="Filter Certificate No"
                  onChange={this.handleChange}
                  maxLength={100}
                  value={this.state.filterCertificateNo}
                />
              </div>

              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <input
                  type="text"
                  className="form-control inputRenderSearch inputFilter"
                  name="filterLotNumber"
                  placeholder="Filter Lot Number"
                  onChange={this.handleChange}
                  maxLength={100}
                  value={this.state.filterLotNumber}
                />
              </div>

              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <FilterSelectContextControl
                  isSetCheckedFromSource={true}
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.filterCropYearControl = ref)}
                  placeholder="Filter Crop Year"
                  label="Filter Crop Year"
                  controlId="filterCropYear"
                  urlapilist={this.state.urlApiCropYearFilter}
                />
              </div>

              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <div className="inputDateTimeContainer">
                  <div className="input-group inputDateTime">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <DatePicker
                      className="form-control inputRenderSearch dateInputWidth"
                      placeholderText="Filter Test Date"
                      selected={
                        this.state.valueDateChange === ""
                          ? ""
                          : this.state.startDate
                      }
                      onChange={this.handleStartDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onBlur={this.handleOnBlur}
                    />
                  </div>
                  <div className="input-group">
                    <span className="input-group-addon">to</span>
                    <DatePicker
                      className="form-control inputRenderSearch dateInputWidth"
                      placeholderText="Filter Test Date"
                      selected={
                        this.state.valueDateChange === ""
                          ? ""
                          : this.state.endDate
                      }
                      onChange={this.handleEndDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                      onBlur={this.handleOnBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 select-render-search mb-sm">
                <input
                  type="text"
                  className="form-control inputRenderSearch inputFilter"
                  name="filterFieldName"
                  placeholder="Filter Field Name"
                  onChange={this.handleChange}
                  maxLength={100}
                  value={this.state.filterFieldName}
                />
              </div>
            </div>
          </div>
        </WalnutTestProvider>
      );
    } else {
      return (
        <>
          {loggedUser.role === "Administrators" ||
            loggedUser.role === "Processors" ||
            loggedUser.role === "Staffs" ||
            loggedUser.role === "Lab Users" ? (
            ""
          ) : (
            <div className="col-md-12 col-lg-3 select-render-search">
              <FilterSelectContextControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.growerControl = ref)}
                placeholder="Filter Grower"
                label="All Growers"
                controlId="filterGrowerName"
                urlapilist={this.state.filterControlUrls?.urlApiGrowerFilter}
              />
            </div>
          )}
          {loggedUser.role === "Administrators" ? (
            <>
              <div className="col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <FilterMultiSelectControl
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.growerControl = ref)}
                      placeholder="Filter Grower"
                      label="Select All"
                      controlId="filterGrowerName"
                      urlapilist={
                        this.state.filterControlUrls?.urlApiGrowerFilter
                      }
                    />
                  </div>
                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <FilterMultiSelectControl
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.processorControl = ref)}
                      placeholder="Filter Processor"
                      label="Select All"
                      controlId="filterProcessorName"
                      urlapilist={
                        this.state.filterControlUrls?.urlApiProcessorFilter
                      }
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <FilterMultiSelectControl
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.varietyControl = ref)}
                      placeholder="Filter Variety"
                      label="Select All"
                      controlId="filterVariety"
                      urlapilist={
                        this.state.filterControlUrls?.urlApiVarietyFilter
                      }
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <div className="inputDateTimeContainer">
                      <div className="input-group inputDateTime">
                        <span className="input-group-addon">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          className="form-control inputRenderSearch dateInputWidth"
                          placeholderText="Filter Test Date"
                          selected={this.state.startDate}
                          onChange={this.handleStartDate}
                          selectsStart
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-addon">to</span>
                        <DatePicker
                          className="form-control inputRenderSearch dateInputWidth"
                          placeholderText="Filter Test Date"
                          selected={this.state.endDate}
                          onChange={this.handleEndDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          minDate={this.state.startDate}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                    </div>
                  </div>

                  {this.state.releaseType === RELEASE_TYPE_AFTER_APPROVED ? (
                    <div className="col-md-6 col-lg-6 select-render-search mb-sm">
                      <FilterMultiSelectControlFromSystemSetting
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        onRef={(ref) => (this.statusControl = ref)}
                        placeholder="Filter Status"
                        label="Select All"
                        controlId="filterStatus"
                        urlapilist={
                          this.state.filterControlUrls?.urlApiStatusFilter
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <input
                      type="text"
                      className="form-control inputRenderSearch inputFilter"
                      name="filterCertificateNo"
                      placeholder="Filter Certificate No"
                      onChange={this.handleChange}
                      maxLength={100}
                      value={this.state.filterCertificateNo}
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <input
                      type="text"
                      className="form-control inputRenderSearch inputFilter"
                      name="filterLotNumber"
                      placeholder="Filter Lot Number"
                      onChange={this.handleChange}
                      maxLength={100}
                      value={this.state.filterLotNumber}
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <input
                      type="text"
                      className="form-control inputRenderSearch inputFilter"
                      name="filterFieldName"
                      placeholder="Filter Field Name"
                      onChange={this.handleChange}
                      maxLength={100}
                      value={this.state.filterFieldName}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <WalnutTestProvider value={this.state}>
              <div className="col-md-6 col-lg-6">
                <div className="row">
                  {loggedUser.role === "Growers" ? (
                    ""
                  ) : (
                    <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                      <FilterMultiSelectControl
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        onRef={(ref) => (this.growerControl = ref)}
                        placeholder="Filter Grower"
                        label="Select All"
                        controlId="filterGrowerName"
                        urlapilist={
                          this.state.filterControlUrls?.urlApiGrowerFilter
                        }
                      />
                    </div>
                  )}

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <FilterSelectContextControl
                      isSetCheckedFromSource={true}
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.filterCropYearControl = ref)}
                      placeholder="Filter Crop Year"
                      label="Filter Crop Year"
                      controlId="filterCropYear"
                      urlapilist={this.state.urlApiCropYearFilter}
                    />
                  </div>

                  <div
                    className={
                      loggedUser.role === "Growers"
                        ? "col-md-12 col-lg-6 select-render-search"
                        : "col-md-12 col-lg-6 select-render-search mb-sm"
                    }
                  >
                    <FilterMultiSelectControl
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.varietyControl = ref)}
                      placeholder="Filter Variety"
                      label="Filter Variety"
                      controlId="filterVariety"
                      urlapilist={
                        this.state.filterControlUrls?.urlApiVarietyFilter
                      }
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 select-render-search mb-sm">
                    <div className="inputDateTimeContainer">
                      <div className="input-group inputDateTime">
                        <span className="input-group-addon">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          className="form-control inputRenderSearch dateInputWidth"
                          placeholderText="Filter Test Date"
                          selected={
                            this.state.valueDateChange === ""
                              ? ""
                              : this.state.startDate
                          }
                          onChange={this.handleStartDate}
                          selectsStart
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-addon">to</span>
                        <DatePicker
                          className="form-control inputRenderSearch dateInputWidth"
                          placeholderText="Filter Test Date"
                          selected={
                            this.state.valueDateChange === ""
                              ? ""
                              : this.state.endDate
                          }
                          onChange={this.handleEndDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          minDate={this.state.startDate}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-md-12 col-lg-12 select-render-search mb-sm">
                    <input
                      type="text"
                      className="form-control inputRenderSearch inputFilter"
                      name="filterCertificateNo"
                      placeholder="Filter Certificate No"
                      onChange={this.handleChange}
                      maxLength={100}
                      value={this.state.filterCertificateNo}
                    />
                  </div>
                  <div className="col-md-12 col-lg-12 select-render-search mb-sm">
                    <input
                      type="text"
                      className="form-control inputRenderSearch inputFilter"
                      name="filterLotNumber"
                      placeholder="Filter Lot Number"
                      onChange={this.handleChange}
                      maxLength={100}
                      value={this.state.filterLotNumber}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-md-12 col-lg-12 select-render-search mb-sm">
                    <FilterMultiSelectControlFromSystemSetting
                      handleChangeSelectControl={this.handleChangeSelectControl}
                      onRef={(ref) => (this.statusControl = ref)}
                      placeholder="Filter Status"
                      label="Select All"
                      controlId="filterStatus"
                      urlapilist={
                        this.state.filterControlUrls?.urlApiStatusFilter
                      }
                    />
                  </div>
                </div>

                {this.state.dynamic === `true` &&
                this.state.testTypeSetting.fieldNameHide === true ? (
                  ""
                ) : (
                  <div className="row">
                    <div className="col-md-12 col-lg-12 select-render-search mb-sm">
                      <input
                        type="text"
                        className="form-control inputRenderSearch inputFilter"
                        name="filterFieldName"
                        placeholder="Filter Field Name"
                        onChange={this.handleChange}
                        maxLength={100}
                        value={this.state.filterFieldName}
                      />
                    </div>
                  </div>
                )}
              </div>
            </WalnutTestProvider>
          )}
        </>
      );
    }
  };
}
export default App;
