import React from "react";
import Select from "react-select";
import { isArray } from "lodash";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isSetCheckedFromSource: props.isSetCheckedFromSource,
      isUseDefaultData: props.isUseDefaultData,
      defaultData: props.defaultData,
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      label: props.label,
      value: props.value,
      name: "",
      data: [],
      errorMessage: "",
      loading: false,
    };
    this.selectRef = null;
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getItems = this.getItems.bind(this);
    this.setDefaultData = this.setDefaultData.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();

    // set default value if isUseDefaultData is true and has defaultData
    this.setDefaultData();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.urlapilist &&
      this.props.urlapilist !== prevProps.urlapilist
    ) {
      this.getItems();
    }
  }

  getItems = async () => {
    try {
      const defaultItem = { value: "", name: this.state.label };
      if (
        this.props.urlapilist === undefined ||
        this.props.urlapilist == null ||
        this.props.urlapilist === "" ||
        this.props.urlapilist.indexOf(`undefined`) !== -1
      ) {
        this.setState({
          data: [],
        });
        return;
      }
      const that = this;
      that.setState({
        isLoading: true,
      });
      fetch(this.props.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            let selectedValue = "";
            let selectedName = "";
            if (resultObject.data) {
              if (that.state.isSetCheckedFromSource === true) {
                resultObject.data.map((item) => {
                  if (item.checked === true) {
                    selectedValue = item._id;
                    selectedName = item.name;
                  }
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked: item.checked,
                  });
                });
              } else {
                resultObject.data.map((item) => {
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked:
                      that.state.value == null ||
                      that.state.value === undefined ||
                      that.state.value.toString().indexOf(item._id) === -1
                        ? false
                        : true,
                  });
                });
              }
            }

            that.setState(
              {
                isLoading: false,
                data: [defaultItem].concat(parsedItems),
              },
              function callBack() {
                if (that.props.isSetCheckedFromSource === true) {
                  //fire event select item
                  that.setState({
                    value: selectedValue,
                    label: selectedName,
                  });
                  that.props.handleChangeSelectControl(
                    that.state.controlId,
                    selectedValue,
                    selectedName
                  );
                }
              }
            );
          }
        })
        .catch(function (error) {
          that.setState({
            isLoading: false,
          });
          console.log(error);
        });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      console.log(err.message);
    }
  };

  setDefaultData = () => {
    const defaultItem = { value: "", name: this.state.label };
    if (this.state.isUseDefaultData && isArray(this.state.defaultData)) {
      this.setState({
        data: [{ ...defaultItem }, ...this.state.defaultData],
      });
    }
  };

  handleChangeSelect(event) {
    // When reset input then set state(value, label) to default value
    if (!event) {
      this.setState({
        value: "",
        label: this.props.label,
      });
      return;
    }
    const valueItem = event;

    this.setState({ value: valueItem.value, label: valueItem.label });

    if (this.props.handleChange) {
      this.props.handleChange(this.state.controlId, valueItem);
    } else {
      this.props.handleChangeSelectControl(
        this.state.controlId,
        valueItem.value,
        valueItem.label
      );
    }
  }

  setSelected(value) {
    this.setState({ value: value });
  }

  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          block: "nearest",
        });
      }
    }, 15);
  };

  reload(urlapilist) {
    if (urlapilist === null || urlapilist === undefined || urlapilist === ``) {
      this.setState({
        urlapilist: urlapilist,
        data: [],
      });
    } else {
      this.setState(
        {
          urlapilist: urlapilist,
        },
        () => {
          this.getItems();
        }
      );
    }
  }

  reset = () => {
    this.selectRef.select.clearValue();
  };

  render() {
    const getLabelData = this.state.data
      .filter((item) => item._id === this.state.value)
      .map((item) => item.name)
      .toString();

    return (
      <>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          name={this.state.controlId}
          className="select-container"
          onChange={this.handleChangeSelect}
          classNamePrefix={"MyDropdown"}
          onMenuOpen={this.onMenuOpen}
          placeholder={this.state.label}
          value={
            this.props.value === ""
              ? {
                  value: "",
                  label: this.props.label,
                }
              : this.state.value
              ? {
                  label: getLabelData,
                  value: this.state.value,
                }
              : {
                  value: "",
                  label: this.state.label,
                }
          }
          options={
            this.state.data
              ? this.state.data.map((item) => {
                  return {
                    value: item._id,
                    label: item.name,
                  };
                })
              : ""
          }
          isOptionDisabled={() => this.props.isOptionDisabled}
          isDisabled={this.props.isDisabled}
          isLoading={this.props.isLoading ? this.props.isLoading : this.state.isLoading}
        />

        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
          }}
          onChange={() => {}}
          value={this.state.value ? this.state.value : ""}
          required={this.props.required ? this.props.required : false}
        />
      </>
    );
  }
}
export default App;
