import React from "react";
import { Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Switch from "react-switch";
import MultiSelectTestType from "./MultiSelectTestType";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import {
  USER_ROLE_PROCESSOR,
  USER_ROLE_STAFF,
  VALUE_QUERY_PARAM_IGNORE,
} from "../Utilities/Constant";
import String from "../Utilities/String";

var DateFormatExport = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const filterGrowerName = "";
    const filterContactName = "";
    const filterContactEmail = "";
    const filterSendInvite = "";
    const filterMultiOwner = "";
    const filterTestTypes = "";
    const filterAddress = "";
    const filterCity = "";
    const filterState = "";
    const filterRegion = "";

    let filterGrowerName4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterContactName4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterContactEmail4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterAddress4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterCity4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterState4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterRegion4Api = VALUE_QUERY_PARAM_IGNORE;
    let filterSendInvite4Api = "all";
    let filterMultiOwner4Api = "all";
    let filterTestTypes4Api = "all";

    this.state.urlapidelete = GlobalConfig.REACT_APP_API_GROWER_URL;
    this.state.urlapi =
      loggedUser.role == "Administrators"
        ? GlobalConfig.REACT_APP_API_GROWER_URL +
          "byadmin/" +
          filterGrowerName4Api +
          "/" +
          filterContactName4Api +
          "/" +
          filterContactEmail4Api +
          "/" +
          filterAddress4Api +
          "/" +
          filterCity4Api +
          "/" +
          filterState4Api +
          "/" +
          filterRegion4Api +
          "/" +
          filterSendInvite4Api +
          "/" +
          filterMultiOwner4Api +
          "/" +
          filterTestTypes4Api
        : GlobalConfig.REACT_APP_API_GROWER_URL +
          "byprocessor/" +
          loggedUser.processorId +
          "/" +
          filterGrowerName4Api +
          "/" +
          filterContactName4Api +
          "/" +
          filterContactEmail4Api +
          "/" +
          filterAddress4Api +
          "/" +
          filterCity4Api +
          "/" +
          filterState4Api +
          "/" +
          filterRegion4Api +
          "/" +
          filterSendInvite4Api +
          "/" +
          filterMultiOwner4Api +
          "/" +
          filterTestTypes4Api;
    this.state.urllist = process.env.REACT_APP_URL_GROWER_LIST;
    this.state.urladd =
      loggedUser.role === "Processors" || loggedUser.role === "Staffs"
        ? process.env.REACT_APP_URL_GROWER_LIST + "-add"
        : "";
    this.state.title = "Grower";
    this.state.isServerPaging = true;

    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);

    this.state.filterGrowerName = filterGrowerName;
    this.state.filterContactName = filterContactName;
    this.state.filterContactEmail = filterContactEmail;
    this.state.filterSendInvite = filterSendInvite;
    this.state.filterMultiOwner = filterMultiOwner;
    this.state.filterCheckSendInvite = false;
    this.state.filterCheckMultiOwner = false;
    this.state.filterTestTypes = filterTestTypes;
    this.state.filterAddress = filterAddress;
    this.state.filterCity = filterCity;
    this.state.filterState = filterState;
    this.state.filterRegion = filterRegion;

    this.state.pageName = "Grower";
  }

  setTestTypeIds = (selectedTestTypes) => {
    const ids = selectedTestTypes
      ? selectedTestTypes.map((testType) => testType.value).join(`,`)
      : "";
    this.setState({
      filterTestTypes: ids,
    });
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  columnsFull = () => {
    return [
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
      },
      {
        name: "Grower Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Contact Name",
        selector: "contactName",
        sortable: true,
      },
      {
        name: "Contact Email",
        selector: "contactEmail",
        sortable: true,
        cell: (row) => (
          <a href={"mailto:" + row.contactEmail} title={row.contactEmail}>
            {row.contactEmail}
          </a>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return !row.isSendInvite ? (
            ""
          ) : row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-default"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate ? (
      //       <DateFormat date={row.modifiedDate}></DateFormat>
      //     ) : (
      //       ""
      //     );
      //   },
      // },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <div>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  columnsLimit = () => {
    return [
      {
        name: "Grower Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Contact Name",
        selector: "contactName",
        sortable: true,
      },
      {
        name: "Contact Email",
        selector: "contactEmail",
        sortable: true,
        cell: (row) => (
          <a href={"mailto:" + row.contactEmail} title={row.contactEmail}>
            {row.contactEmail}
          </a>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return !row.isSendInvite ? (
            ""
          ) : row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-default"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };

  defaultColumns = () => {
    return loggedUser.role == "Administrators"
      ? this.columnsFull()
      : this.columnsLimit();
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value,
      },
      loggedUser.role === "Administrators" ||
        loggedUser.role === "Processors" ||
        loggedUser.role === "Staffs"
        ? function callBack() {
            return;
          }
        : function callBack() {
            this.filterItems();
          }
    );
  };

  getTotalItems = () => {
    return this.state.totalRows;
  };

  handleCheckSendInviteElement = (event) => {
    this.state.filterCheckSendInvite
      ? this.setState(
          { filterCheckSendInvite: false, filterSendInvite: false },
          loggedUser.role === "Administrators" ||
            loggedUser.role === "Processors" ||
            loggedUser.role === "Staffs"
            ? function callBack() {
                return;
              }
            : function callBack() {
                this.filterItems();
              }
        )
      : this.setState(
          { filterCheckSendInvite: true, filterSendInvite: true },
          loggedUser.role === "Administrators" ||
            loggedUser.role === "Processors" ||
            loggedUser.role === "Staffs"
            ? function callBack() {
                return;
              }
            : function callBack() {
                this.filterItems();
              }
        );
  };

  handleCheckMultiOwnerElement = (event) => {
    this.state.filterCheckMultiOwner
      ? this.setState(
          { filterCheckMultiOwner: false, filterMultiOwner: false },
          loggedUser.role === "Administrators" ||
            loggedUser.role === "Processors" ||
            loggedUser.role === "Staffs"
            ? function callBack() {
                return;
              }
            : function callBack() {
                this.filterItems();
              }
        )
      : this.setState(
          { filterCheckMultiOwner: true, filterMultiOwner: true },
          loggedUser.role === "Administrators" ||
            loggedUser.role === "Processors" ||
            loggedUser.role === "Staffs"
            ? function callBack() {
                return;
              }
            : function callBack() {
                this.filterItems();
              }
        );
  };

  filterItems = async () => {
    let urlapi = "";
    let filterGrowerName = String.isEmpty(this.state.filterGrowerName)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterGrowerName.trim());

    let filterContactName = String.isEmpty(this.state.filterContactName)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterContactName.trim());

    let filterContactEmail = String.isEmpty(this.state.filterContactEmail)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterContactEmail.trim());

    let filterAddress = String.isEmpty(this.state.filterAddress)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterAddress.trim());

    let filterCity = String.isEmpty(this.state.filterCity)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterCity.trim());

    let filterState = String.isEmpty(this.state.filterState)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterState.trim());

    let filterRegion = String.isEmpty(this.state.filterRegion)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterRegion.trim());

    let filterSendInvite =
      this.state.filterSendInvite && this.state.filterSendInvite !== ""
        ? this.state.filterSendInvite
        : "all";

    let filterMultiOwner =
      this.state.filterMultiOwner && this.state.filterMultiOwner !== ""
        ? this.state.filterMultiOwner
        : "all";

    let filterTestTypes =
      this.state.filterTestTypes && this.state.filterTestTypes !== ""
        ? this.state.filterTestTypes
        : "all";

    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      urlapi =
        GlobalConfig.REACT_APP_API_GROWER_URL +
        "byprocessor/" +
        loggedUser.processorId +
        "/" +
        filterGrowerName +
        "/" +
        filterContactName +
        "/" +
        filterContactEmail +
        "/" +
        filterAddress +
        "/" +
        filterCity +
        "/" +
        filterState +
        "/" +
        filterRegion +
        "/" +
        filterSendInvite +
        "/" +
        filterMultiOwner +
        "/" +
        filterTestTypes;
    } else if (loggedUser.role === "Administrators") {
      urlapi =
        GlobalConfig.REACT_APP_API_GROWER_URL +
        "byadmin/" +
        filterGrowerName +
        "/" +
        filterContactName +
        "/" +
        filterContactEmail +
        "/" +
        filterAddress +
        "/" +
        filterCity +
        "/" +
        filterState +
        "/" +
        filterRegion +
        "/" +
        filterSendInvite +
        "/" +
        filterMultiOwner +
        "/" +
        filterTestTypes;
    } else {
      urlapi = GlobalConfig.REACT_APP_API_GROWER_URL + filterGrowerName;
    }
    this.setState({ urlapi: urlapi, currentPage: 1 }, () => {
      this.getItems(this.state.currentPage, this.state.perPage);
    });
  };

  handleReset = () => {
    this.setState(
      {
        filterGrowerName: "",
        filterContactName: "",
        filterContactEmail: "",
        filterAddress: "",
        filterCity: "",
        filterState: "",
        filterRegion: "",
        filterSendInvite: "",
        filterMultiOwner: "",
        filterTestTypes: "",
        filterCheckSendInvite: false,
        filterCheckMultiOwner: false,
      },
      () => {
        this.filterItems();
        if (
          loggedUser.role === USER_ROLE_PROCESSOR ||
          loggedUser.role === USER_ROLE_STAFF
        ) {
          this.testTypeSelector.resetSelect();
        }
      }
    );
  };

  exportExcel = async () => {
    let items = [];
    fetch(
      this.state.urlapi +
        `/1/${
          this.getTotalItems() > 1
            ? this.getTotalItems()
            : this.getTotalItems() - 1
        }`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        items = resultObject.data;
        const parsedItems = [];
        if (items) {
          items.map((item) => {
            parsedItems.push({
              "Grower Name": item.name,
              "Contact Name": item.contactName,
              "Contact Email": item.contactEmail,
              Status: item.status,
              Address: item.address,
              City: item.city,
              State: item.state,
              Region: item.region,
              Note: item.note,
              "Created Date": DateFormatExport(item.createdDate, "mm/dd/yyyy"),
            });
          });

          //Export excel
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const ws = XLSX.utils.json_to_sheet(parsedItems);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, "Grower.xlsx");
        }
        return parsedItems;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  exportButtons = () => {
    return this.state.totalRows > 0 ? (
      <Button
        className="mb-sm"
        variant="warning"
        onClick={(e) => this.exportExcel()}
      >
        <i className="fa fa-file-excel-o fa-lg"></i> Export
      </Button>
    ) : null;
  };

  defaultButtons = () => {
    return (
      <div className="panel-btn-group-item-position">
        <span>{this.exportButtons()}</span>
        <a
          href={process.env.REACT_APP_URL_GROWER_LIST + "-import"}
          className="btn btn-success mb-sm"
        >
          <i className="fa fa-upload"></i> Import
        </a>
        {this.state.excludeDefaultButtons === true ||
        this.state.urladd === null ||
        this.state.urladd === undefined ||
        this.state.urladd === "" ? (
          ""
        ) : (
          <a href={this.state.urladd} className="btn btn-primary mb-sm">
            <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
          </a>
        )}
      </div>
    );
  };

  renderSearch = () => {
    return loggedUser.role == "Administrators" ? (
      <>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterGrowerName"
                placeholder="Filter Grower Name"
                value={this.state.filterGrowerName}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterContactName"
                placeholder="Filter Contact Name"
                value={this.state.filterContactName}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterContactEmail"
                placeholder="Filter Contact Email"
                value={this.state.filterContactEmail}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterAddress"
                placeholder="Filter Address"
                value={this.state.filterAddress}
                onChange={this.handleChange}
                maxLength={200}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterCity"
                placeholder="Filter City"
                value={this.state.filterCity}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterState"
                placeholder="Filter State"
                value={this.state.filterState}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterRegion"
                placeholder="Filter Region"
                value={this.state.filterRegion}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <div className="checkbox-container">
                <Switch
                  name="isSendInvite"
                  onChange={this.handleCheckSendInviteElement}
                  checked={this.state.filterCheckSendInvite}
                  className="react-switch"
                />

                <label className="control-label ml-md">
                  Invite Grower to See Their Data
                </label>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <div className="checkbox-container">
                <Switch
                  name="isSendInvite"
                  onChange={this.handleCheckMultiOwnerElement}
                  checked={this.state.filterCheckMultiOwner}
                  className="react-switch"
                />

                <label className="control-label ml-md">Multiple Owners</label>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterGrowerName"
                placeholder="Filter Grower Name"
                value={this.state.filterGrowerName}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterContactName"
                placeholder="Filter Contact Name"
                value={this.state.filterContactName}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterContactEmail"
                placeholder="Filter Contact Email"
                value={this.state.filterContactEmail}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <MultiSelectTestType
                setTestTypeIds={this.setTestTypeIds}
                urlapi={this.state.filterControlUrls?.urlApiTestTypeFilter}
                placeholder={"Filter Test Type"}
                onRef={(ref) => (this.testTypeSelector = ref)}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterAddress"
                placeholder="Filter Address"
                value={this.state.filterAddress}
                onChange={this.handleChange}
                maxLength={200}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterCity"
                placeholder="Filter City"
                value={this.state.filterCity}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterState"
                placeholder="Filter State"
                value={this.state.filterState}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterRegion"
                placeholder="Filter Region"
                value={this.state.filterRegion}
                onChange={this.handleChange}
                maxLength={100}
              />
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <div className="checkbox-container">
                <Switch
                  name="isSendInvite"
                  onChange={this.handleCheckSendInviteElement}
                  checked={this.state.filterCheckSendInvite}
                  className="react-switch"
                />

                <label className="control-label ml-md">
                  Invite Grower to See Their Data
                </label>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 select-render-search mb-sm">
              <div className="checkbox-container">
                <Switch
                  name="isSendInvite"
                  onChange={this.handleCheckMultiOwnerElement}
                  checked={this.state.filterCheckMultiOwner}
                  className="react-switch"
                />

                <label className="control-label ml-md">Multiple Owners</label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  updateFilterControlUrls = () => {
    this.setState({
      filterControlUrls: {
        urlApiTestTypeFilter: `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}byprocessor/${loggedUser.processorId}/${VALUE_QUERY_PARAM_IGNORE}/-1/-1`,
      },
    });
  };
}
export default App;
