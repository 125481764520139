// https://www.npmjs.com/package/react-moment
// npm install --save moment react-moment
import React from "react";
import Moment from "react-moment";

export default class DateFormat extends React.Component {
  constructor(props) {
    super();
    this.state = {
      format: props.format == undefined || props.format == null || props.format == "" ? "MM/DD/YYYY hh:mm A" : props.format
    };
  }

  render() {
    //12h format
    return <Moment format={this.state.format}>{this.props.date}</Moment>;
  }
}
