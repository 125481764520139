import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import { Helmet } from "react-helmet";
import NumericInput from "../FormControl/NumericInput";
import CommonUtilities from "../Utilities/Common";
import Switch from "react-switch";
import SaveButton from "../Controls/SaveButton";
import ResetPassword from "../Base/ResetPassword";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Processors",
      urllist: process.env.REACT_APP_URL_PROCESSOR_LIST,
      urlapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL,
      imageMaxSize: parseInt(process.env.REACT_APP_MAX_SIZE_PROFILE_IMAGE),
      defaultCompanyPhoto: process.env.REACT_APP_DEFAULT_COMPANY_PHOTO,
      data: {
        _id: props.match.params.id,
        role: "",
        name: "",
        avatar: "",
        avatarExtension: "",
        address: "",
        businessName: "",
        businessAddress: "",
        phoneNumber: "",
        dayOfDelayRelease: 0,
        companyName: "",
        certificateNo: "",
        certificatePrefix: "",
        certificateSeed: "",
        email: "",
        status: "",
        isContractCashPrice: false,
        isUseDetailedGrowerStatement: false,
        showImportResult: false,
        allowNotifyImportResult: false,
        notifyImportResult: false,
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),
      },
      errors: {
        email: "",
      },
      loading: parentState.loading,
    };

    this.handleContractCashPriceChangeSwitch =
      this.handleContractCashPriceChangeSwitch.bind(this);
    this.handleUseDetailedGrowerStatementChangeSwitch =
      this.handleUseDetailedGrowerStatementChangeSwitch.bind(this);
    this.handleShowImportResultChangeSwitch =
      this.handleShowImportResultChangeSwitch.bind(this);
    this.handleAllowNotifyImportResultChangeSwitch =
      this.handleAllowNotifyImportResultChangeSwitch.bind(this);
    this.handleNotifyImportResultChangeSwitch =
      this.handleNotifyImportResultChangeSwitch.bind(this);
  }

  handleContractCashPriceChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isContractCashPrice: checked,
      }),
    });
  }

  handleUseDetailedGrowerStatementChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isUseDetailedGrowerStatement: checked,
      }),
    });
  }

  handleShowImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        showImportResult: checked,
      }),
    });
  }

  handleAllowNotifyImportResultChangeSwitch(checked) {
    let notifyImportResult = checked
      ? this.state.data.notifyImportResult
      : false;
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        allowNotifyImportResult: checked,
        notifyImportResult: notifyImportResult,
      }),
    });
  }

  handleNotifyImportResultChangeSwitch(checked) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        notifyImportResult: checked,
      }),
    });
  }

  checkSomethingBeforeSave = () => {
    const isValidEmail = CommonUtilities.isValidEmail(this.state.data.email);
    this.setState({
      errors: Object.assign({}, this.state.errors, {
        email: isValidEmail,
      }),
    });
    if (isValidEmail && isValidEmail !== "") {
      this.email.focus();
      return false;
    } else {
      return true;
    }
  };

  getTitle = () => {
    return this.state.data.name;
  };

  render() {
    const title = "Edit - " + this.getTitle();
    return (
      <>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>

                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                    <li>
                      <span>{this.getTitle()}</span>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>

              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="tabs">
                    <ul className="nav nav-tabs tabs-primary">
                      <li className="active">
                        <a href="#Profile" data-toggle="tab">
                          Profile & Account
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="Profile" className="tab-pane active">
                        <form
                          className="form-horizontal form-bordered"
                          onSubmit={this.handleSubmit}
                          id="form"
                        >
                          <h4 className="mb-xlg">Processor Information</h4>
                          <fieldset>
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Processor ID
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {this.state.data._id}
                                </label>
                                <br></br>
                                <label>
                                  Using for{" "}
                                  <a
                                    href={
                                      process.env.REACT_APP_URL_DOCS_TEST_LIST
                                    }
                                  >
                                    API Export
                                  </a>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="name"
                              >
                                Contact Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="150"
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="email"
                              >
                                Email <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.email}
                                  onChange={this.handleChange}
                                  ref={(input) => {
                                    this.email = input;
                                  }}
                                />
                                {this.state.errors.email &&
                                this.state.errors.email !== "" ? (
                                  <label style={{ color: "#d2322d" }}>
                                    {this.state.errors.email}
                                  </label>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="companyName"
                              >
                                Processor Company Name{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="100"
                                  type="text"
                                  name="companyName"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.companyName}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="certificatePrefix"
                              >
                                Certification Prefix
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="text"
                                  name="certificatePrefix"
                                  className="form-control"
                                  value={this.state.data.certificatePrefix}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <NumericInput
                              precision={0}
                              min={0}
                              max={999999999}
                              step={1}
                              label="Certification Seed No"
                              name="certificateSeed"
                              value={this.state.data.certificateSeed}
                              handleChange={this.handleChange}
                            />
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Sample Certificate No
                              </label>
                              <label
                                className="control-label"
                                style={{ paddingLeft: "15px" }}
                              >
                                {this.state.data.certificatePrefix}
                                {this.state.data.certificateSeed}
                              </label>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="address"
                              >
                                Processor Address{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="address"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  value={this.state.data.address}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessName"
                              >
                                Business Name
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="200"
                                  type="text"
                                  name="businessName"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessName}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="businessAddress"
                              >
                                Business Address
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="300"
                                  type="text"
                                  name="businessAddress"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.businessAddress}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="phoneNumber"
                              >
                                Phone Number
                              </label>
                              <div className="col-md-8">
                                <input
                                  maxLength="50"
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder=""
                                  value={this.state.data.phoneNumber}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <NumericInput
                              precision={0}
                              min={0}
                              max={100}
                              step={1}
                              label="Grower Data Delay"
                              name="dayOfDelayRelease"
                              value={this.state.data.dayOfDelayRelease}
                              handleChange={this.handleChange}
                              note="Number of days to delay release Test data to Growers"
                            />
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Contract Cash Price
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isContractCashPrice"
                                  onChange={
                                    this.handleContractCashPriceChangeSwitch
                                  }
                                  checked={this.state.data.isContractCashPrice}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Use Detailed Grower Statement
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="isUseDetailedGrowerStatement"
                                  onChange={
                                    this
                                      .handleUseDetailedGrowerStatementChangeSwitch
                                  }
                                  checked={
                                    this.state.data.isUseDetailedGrowerStatement
                                  }
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Show Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="showImportResult"
                                  onChange={
                                    this.handleShowImportResultChangeSwitch
                                  }
                                  checked={this.state.data.showImportResult}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Allow Notify Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="allowNotifyImportResult"
                                  onChange={
                                    this
                                      .handleAllowNotifyImportResultChangeSwitch
                                  }
                                  checked={
                                    this.state.data.allowNotifyImportResult
                                  }
                                  className="react-switch"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-md-4">
                                Notify Import Result
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  name="notifyImportResult"
                                  onChange={
                                    this.handleNotifyImportResultChangeSwitch
                                  }
                                  checked={this.state.data.notifyImportResult}
                                  className="react-switch"
                                  disabled={
                                    !this.state.data.allowNotifyImportResult
                                  }
                                />
                              </div>
                            </div>
                          </fieldset>
                          <hr className="dotted tall"></hr>
                          <h4 className="mb-xlg">Account</h4>
                          <fieldset className="mb-xl">
                            <div className="form-group">
                              <label
                                className="col-xs-4 control-label mt-xs pt-none"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <div className="col-md-8">
                                <select
                                  name="status"
                                  className="form-control"
                                  required
                                  value={this.state.data.status}
                                  onChange={this.handleChange}
                                  disabled={
                                    this.state.data.status == "Not Activate"
                                  }
                                >
                                  {this.state.data.status == "Not Activate" ? (
                                    <option value="Not Activate">
                                      Not Activate
                                    </option>
                                  ) : (
                                    ""
                                  )}
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </fieldset>
                          <div className="panel-footer">
                            <div className="row">
                              <div className="col-md-12 profile-panel-footer-wrapper">
                                {this.state.data.status === "Active" &&
                                  this.state.data.email && (
                                    <ResetPassword
                                      email={this.state.data.email}
                                    />
                                  )}
                                <SaveButton
                                  loading={this.state.loading}
                                  propClassName="ml-0"
                                />
                                <a
                                  href={this.state.urllist}
                                  title="Back to list"
                                  className="btn btn-primary ml-0"
                                >
                                  <i className="fa fa-backward fa-lg"></i> Back
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
              {this.state.alertshow === true ? (
                <Alert
                  message={this.state.alertmsg}
                  type={this.state.alerttype}
                  show={this.state.alertshow}
                  showBtnClose={true}
                ></Alert>
              ) : (
                ""
              )}
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
