import React from "react";
import TableAttribute from "./TableAttribute";
import CommonUtilities from "../Utilities/Common";

class App extends React.Component {
  constructor(props) {
    super();
    let randomParentId = Math.random().toString(36).substring(2);
    this.state = {
      data: props.data.children,
      parentId: randomParentId,
    };
    this.bindData = this.bindData.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    // this.props.onRef(this);
    this.composeList();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    // this.props.onRef(undefined);
  }

  bindData(data) {
    this.setState({ data: data }, function callBack() {
      this.composeList();
    });
  }

  composeList() {
    try {
      const newItems = [];
      if (this.state.data && this.state.data.length > 0) {
        this.state.data.map((u) => {
          let children = [];
          if (u.children && u.children.length > 0) {
            u.children.map((c) => {
              for (var i = c.max; i >= c.min; i--) {
                children.push({
                  percent: i,
                  value: c.value,
                });
              }
            });
          } else {
            for (var i = 100; i >= 0; i--) {
              children.push({
                percent: i,
                value: 0,
              });
            }
          }
          newItems.push({
            attribute: u.attribute,
            children: children,
          });
        });
        newItems.sort(CommonUtilities.getSortOrder("attribute"));
      }
      //Set data for tables
      const newItemsSize = newItems.filter((u) => {
        return u.attribute.indexOf("Size: ") != -1;
      });
      this.tableSize.bindData(newItemsSize);

      const newItemsDefect = newItems.filter((u) => {
        return u.attribute.indexOf("Defect: ") != -1 || u.attribute === `Moisture %`;
      });
      this.tableDefect.bindData(newItemsDefect);

      const newItemsSerious = newItems.filter((u) => {
        return u.attribute.indexOf("Serious: ") != -1;
      });
      this.tableSerious.bindData(newItemsSerious);

      const newItemsColor = newItems.filter((u) => {
        return u.attribute.indexOf("Color: ") != -1;
      });
      this.tableColor.bindData(newItemsColor);
    } catch (err) {
      console.log(err.message);
    }
  }

  render() {
    let i = 0;
    let j = 0;
    return (
      <>
        <ul className="nav nav-tabs" role="tablist">
          <li role="presentation" className="active" key="liColor">
            <a
              href={"#" + this.state.parentId + "_tableColor"}
              role="tab"
              data-toggle="tab"
            >
              Color
            </a>
          </li>
          <li role="presentation" key="liDefect">
            <a
              href={"#" + this.state.parentId + "_tableDefect"}
              role="tab"
              data-toggle="tab"
            >
              Defect
            </a>
          </li>
          <li role="presentation" key="liSerious">
            <a
              href={"#" + this.state.parentId + "_tableSerious"}
              role="tab"
              data-toggle="tab"
            >
              Serious
            </a>
          </li>
          <li role="presentation" key="liSize">
            <a
              href={"#" + this.state.parentId + "_tableSize"}
              role="tab"
              data-toggle="tab"
            >
              Size
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            id={this.state.parentId + "_tableColor"}
            className="tab-pane active"
          >
            <TableAttribute
              onRef={(ref) => (this.tableColor = ref)}
              title="Color"
              attributeFilter="Color: "
            />
          </div>
          <div
            role="tabpanel"
            id={this.state.parentId + "_tableDefect"}
            className="tab-pane"
          >
            <TableAttribute
              onRef={(ref) => (this.tableDefect = ref)}
              title="Defect"
              attributeFilter="Defect: "
            />
          </div>
          <div
            role="tabpanel"
            id={this.state.parentId + "_tableSerious"}
            className="tab-pane"
          >
            <TableAttribute
              onRef={(ref) => (this.tableSerious = ref)}
              title="Serious"
              attributeFilter="Serious: "
            />
          </div>
          <div
            role="tabpanel"
            id={this.state.parentId + "_tableSize"}
            className="tab-pane"
          >
            <TableAttribute
              onRef={(ref) => (this.tableSize = ref)}
              title="Size"
              attributeFilter="Size: "
            />
          </div>
        </div>
      </>
    );
  }
}
export default App;
