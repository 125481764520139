import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      controlId: props.controlId,
      value: props.value,
      data: [],
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const that = this;
      fetch(this.props.urlApi)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            resultObject.data.map((item) => {
              parsedItems.push({
                _id: item._id,
                name: item.name,
                checked:
                  that.state.value == null ||
                  that.state.value === undefined ||
                  that.state.value.indexOf(item._id) === -1
                    ? false
                    : true,
              });
            });
            that.setState({
              data: parsedItems,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setSelected(selectedIds) {
    this.setState({ value: selectedIds });
    if (this.state.data) {
      let updatedData = this.state.data;
      updatedData.forEach((item) => {
        if (
          selectedIds == null ||
          selectedIds === undefined ||
          selectedIds.indexOf(item._id) === -1
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
      this.setState({ data: updatedData });
    }
  }

  handleChangeSelect(event) {
    const value = event.target.value;
    this.setState({ value: value });
    this.props.handleChangeSelectControl(this.state.controlId, value);
  }

  render() {
    return (
      <select
        multiple={this.props.multiple}
        disabled={this.props.readOnly}
        name={this.state.controlId}
        className="form-control"
        required={this.props.required}
        onChange={this.handleChangeSelect}
        value={this.state.value}
      >
        <option value="" hidden>
          Choose one
        </option>
        {this.state.data
          ? this.state.data.map((item, key) => {
              return (
                <option value={item._id} key={item._id}>
                  {item.name}
                </option>
              );
            })
          : ""}
      </select>
    );
  }
}
export default App;
