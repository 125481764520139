import React from "react";
import EditComponent from "../Base/EditComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Modal } from "react-bootstrap";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import Switch from "react-switch";
import TextareaAutosize from "react-textarea-autosize";
import NumericInput from "../FormControl/NumericInput";
import SelectConfig from "../Base/SelectConfig";
import NutTypeGroup from "./NutTypeGroup";
import { v4 as uuidv4 } from "uuid";
import Textbox from "../FormControl/Textbox";
import CheckboxHideRequired from "./CheckboxHideRequired";
import CheckboxSync from "./CheckboxSync";
import SelectControl from "../FormControl/SelectControl";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import SelectControlGrower from "../Grower/SelectControl";
import SelectWalnutVarieties from "./SelectWalnutVarieties";
import TypeContainer from "../FormControl/TypeContainer";
import { renderFieldByType } from "../TestField/TestFieldUtilities";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  TEST_TYPE_WALNUT,
} from "../Utilities/Constant";
import { confirmAlert } from "react-confirm-alert";
import FilterSelectControlWithoutDefaultOption from "../FormControl/FilterSelectControlWithoutDefaultOption";
import AddPopupTestField from "../TestField/popup";
import SelectFieldPopup from "./SelectFieldPopup";
import Popover from "../Base/Popover";
import { ReactSortable } from "react-sortablejs";
import _ from "lodash";
import CommonUtilities from "../Utilities/Common";
import SaveButton from "../Controls/SaveButton";
import String from "../Utilities/String";
import { connect } from "react-redux";
import {
  addTestType,
  removeTestType,
  updateTestType,
} from "./../../redux/actions/testTypeActions";

const moment = require("moment");
const GlobalConfig = new window.globalConfig();
let loggedUser = {};
let defaultColor = "#4d6aff";

const defaultSizeGroupOptions = [
  { _id: "col-xs-12 col-md-6 col-lg-3", name: "25%" },
  { _id: "col-xs-12 col-md-6", name: "50%" },
  { _id: "col-xs-12 col-md-9", name: "75%" },
  { _id: "col-xs-12 col-md-12", name: "100%" },
];

class App extends EditComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    let urlApiProcessorFilter = "";
    let urlApiDfaInspector = "";
    let urlApiGrowerName = "";

    urlApiProcessorFilter =
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + `filterDataProcessor`;
    const parentState = { ...this.state };
    this.state = {
      listtitle: "Test Types",
      urllist: process.env.REACT_APP_URL_NUTTYPE_LIST,
      urlapi: GlobalConfig.REACT_APP_API_NUTTYPE_URL,
      errorMessage: "",
      data: {
        _id: props.match.params.id,
        name: "",
        processorId: "",
        processorsHide: "",
        isInUse: false,
        isDynamic: false,
        isSystem:
          loggedUser.role === USER_ROLE_PROCESSOR ||
          loggedUser.role === USER_ROLE_STAFF
            ? false
            : true,
        xpool: false,
        xpoolPercent: 0,
        xpoolWeight: 0,
        regularPercent: 100,
        regularWeight: 0,
        basePriceByPayment: false,
        paymentPercentBased: true,
        multiPricesSameLot: false,
        basePriceField: ``,
        multiplePriceField: ``,
        singlePricePerLot: false,
        singlePricePerLotField: ``,
        multiTestsPerLot: false,
        varietyOptional: false,
        taxFeeByMeta: false,
        paymentByVariety: false,
        multiTestGrowerPaymentType: `Average`,
        multiTestNumberSamples: 0,
        //Dummy field on form only
        overridePrice: 0,
        createdUserId: "",
        createdDate: null,
        modifiedUserId: loggedUser._id,
        modifiedDate: new Date(),

        //meta fields
        testDate: new Date(),
        testDateEditable: true,
        netWeightUnit: ``,
        fieldNameRequired: false,
        fieldNameHide: false,
        numberOfContainers: 0,
        typeNumberOfContainers: "",
        typeNumberOfContainersRequired: true,
        typeNumberOfContainersHide: false,
        dfaInspectorRequired: true,
        dfaInspectorHide: false,
        photoRequired: false,
        photoHide: false,
        overridePriceHide: true,
        //Sync fields
        testDateIsSync: false,
        testDatePfCoreField: ``,
        lotNumberIsSync: false,
        lotNumberPfCoreField: ``,
        growerNameIsSync: false,
        growerNamePfCoreField: ``,
        fieldNameIsSync: false,
        fieldNamePfCoreField: ``,
        varietyIsSync: false,
        varietyPfCoreField: ``,
        typeNumberOfContainersIsSync: false,
        typeNumberOfContainersPfCoreField: ``,
        numberOfContainersIsSync: false,
        numberOfContainersPfCoreField: ``,
        netWeightIsSync: false,
        netWeightPfCoreField: ``,
        dfaInspectorIsSync: false,
        dfaInspectorPfCoreField: ``,
        overridePriceIsSync: false,
        overridePricePfCoreField: ``,
        xpoolPercentIsSync: false,
        xpoolPercentPfCoreField: ``,
        xpoolWeightIsSync: false,
        xpoolWeightPfCoreField: ``,
        regularPercentIsSync: false,
        regularPercentPfCoreField: ``,
        regularWeightIsSync: false,
        regularWeightPfCoreField: ``,

        groupMetaData: [],
        groupQualityData: [],
        selectedFields: null,
      },
      dataPopupGroup: {
        _id: "",
        showAddGroupModal: false,
        name: "",
        nutTypeId: "",
        section: "",
        color: "#4d6aff",
        size: "col-xs-12 col-md-6 col-lg-3",
        sequence: 0,
      },
      formType: "",
      //Using for popup field
      currentSection: "",
      currentGroupId: "",

      allFields: [],
      filterFieldName: "",
      filterFieldType: "",
      urlApiProcessorFilter: urlApiProcessorFilter,
      urlApiDfaInspector: urlApiDfaInspector,
      urlApiGrowerName: urlApiGrowerName,
      show: false,
      showCreateTestFieldPopup: false,
      searchLoading: false,
      loading: parentState.loading,
    };

    this.handleInUseChangeSwitch = this.handleInUseChangeSwitch.bind(this);
    this.handleIsDynamicChangeSwitch =
      this.handleIsDynamicChangeSwitch.bind(this);
    this.handleOpenAddGroup = this.handleOpenAddGroup.bind(this);
    this.handleSaveGroup = this.handleSaveGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.checkSequenceExist = this.checkSequenceExist.bind(this);
    this.checkSequenceIsSmallest = this.checkSequenceIsSmallest.bind(this);
    this.checkSequenceIsLargest = this.checkSequenceIsLargest.bind(this);
    this.handleResetGroupForm = this.handleResetGroupForm.bind(this);
    this.switchData = this.switchData.bind(this);
    this.switchUpSequenceGroup = this.switchUpSequenceGroup.bind(this);
    this.checkGroupNameExist = this.checkGroupNameExist.bind(this);
    this.handleOpenEditGroup = this.handleOpenEditGroup.bind(this);
    this.increaseSequenceRecursive = this.increaseSequenceRecursive.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeSelectPopupGroupControl =
      this.handleChangeSelectPopupGroupControl.bind(this);
    this.handleSelectedCheckboxTestField =
      this.handleSelectedCheckboxTestField.bind(this);
    this.handleOpenAddTestField = this.handleOpenAddTestField.bind(this);
    this.handleCloseTestField = this.handleCloseTestField.bind(this);
    this.switchDownSequenceGroup = this.switchDownSequenceGroup.bind(this);
    this.handleChangeSelectTypeContainers =
      this.handleChangeSelectTypeContainers.bind(this);
    this.handleHeaderFormChangeSwitch =
      this.handleHeaderFormChangeSwitch.bind(this);
    this.handleChangeNumberContainers =
      this.handleChangeNumberContainers.bind(this);
  }

  getTitle = () => {
    return this.state.data.name;
  };

  async componentDidMount() {
    await this.queryFields();
    await this.getItemById(this.state.data._id);
    if (this.state.data.isDynamic) {
      await this.handleGetDataByProcessor();
    }
  }

  handleChange(event) {
    const id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(
      {
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          [id]: value,
        }),
      },
      () => {
        if (id === `xpoolPercent`) {
          this.setState({
            data: Object.assign({}, this.state.data, {
              regularPercent: 100 - value,
            }),
          });
        }
        if (id === `xpoolWeight` || id === `netWeight`) {
          const regularWeight =
            this.state.data.netWeight - this.state.data.xpoolWeight;
          this.setState({
            data: Object.assign({}, this.state.data, {
              regularWeight: regularWeight,
            }),
          });
        }
      }
    );
  }

  handleConvertSelectedFieldsToArray = () => {
    let keys = [];
    if (this.state.data) {
      keys = Object.keys(
        this.state.data.selectedFields ? this.state.data.selectedFields : {}
      );
    }

    const result = [];
    keys.forEach((key) => result.push(...this.state.data.selectedFields[key]));

    return result;
  };

  handleSubmitSelectedFields = () => {
    const keys = Object.keys(
      this.state.data.selectedFields ? this.state.data.selectedFields : {}
    );

    const result = [];
    keys.forEach((key) => result.push(...this.state.data.selectedFields[key]));
    result.map((item) => delete item._id);

    return result;
  };

  handleGroupSelectedField = (array) => {
    if (array) {
      const grouped = _.groupBy(array, (arrayItem) =>
        arrayItem.nutTypeGroupId !== ""
          ? arrayItem.nutTypeGroupId
          : arrayItem.section
      );
      return grouped;
    }
    return [];
  };

  handleGroupSelectedFieldDelete = (filter) => {
    if (filter) {
      const grouped = _.groupBy(filter, (arrayItem) =>
        arrayItem.nutTypeGroupId !== ""
          ? arrayItem.nutTypeGroupId
          : arrayItem.section
      );
      return grouped;
    }
    return [];
  };

  getItemById = async (id) => {
    this.setState({
      alertmsg: "Query data...",
      alerttype: "info",
      alertshow: this.state.data.isDynamic ? true : false,
    });
    try {
      const that = this;
      var url = this.composeQueryUrlApi(id);
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            if (resultObject.data) {
              const combineFields = CommonUtilities.handleCombineFields(
                resultObject.data.selectedFields,
                that.state.allFields
              );
              that.setState({
                data: {
                  ...resultObject.data,
                  selectedFields: that.handleGroupSelectedField(combineFields),
                },
              });

              if (that.processorId) {
                that.processorId.setSelected(that.state.data.processorId);
              }

              //Turn-off message
              that.setState(
                {
                  alertshow: false,
                },
                () => {
                  that.doSomethingAfterGetItemById();
                }
              );
            } else {
              // window.location.href = process.env.REACT_APP_URL_PAGE_NOT_FOUND;
              that.setState({
                alertshow: false,
              });
              that.setState({
                alertmsg: resultObject.message
                  ? resultObject.message
                  : GlobalConfig.REACT_APP_MSG_CONTENT_NOT_EXIST,
                alerttype: "error",
                alertshow: true,
              });
            }
          } else {
            that.setState({
              alertshow: false,
            });
            that.setState({
              alertmsg: resultObject.message,
              alerttype: "error",
              alertshow: true,
            });
          }
        })
        .catch(function (error) {
          that.setState({
            alertshow: false,
          });
          that.setState({
            alertmsg: error,
            alerttype: "error",
            alertshow: true,
          });
        });
    } catch (err) {
      this.setState({
        alertshow: false,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  handleIsDynamicChangeSwitch(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isDynamic: value,
      }),
    });
  }

  handleInUseChangeSwitch(value) {
    const { processorsHide } = this.state.data;
    const isProcessorOrStaff =
      loggedUser.role === USER_ROLE_PROCESSOR ||
      loggedUser.role === USER_ROLE_STAFF;

    const isProcessorTurnOn = isProcessorOrStaff && value;
    const isProcessorTurnOff = isProcessorOrStaff && !value;

    const processorsHideList =
      processorsHide === "" ? [] : processorsHide.split(",");

    if (isProcessorTurnOff) {
      const processorsHideListAppend = [...processorsHideList]
        .concat(loggedUser.processorId)
        .join(",");

      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          processorsHide: processorsHideListAppend,
          isInUse: value,
        }),
      });
      return;
    }

    if (isProcessorTurnOn) {
      const processorsHideListRemove = processorsHideList
        .filter((item) => item !== loggedUser.processorId)
        .join(",");

      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          processorsHide: processorsHideListRemove,
          isInUse: value,
        }),
      });
      return;
    }

    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        isInUse: value,
      }),
    });
  }

  handleHeaderFormChangeSwitch(checked, event, id) {
    const getId = id.replaceAll('"', "");
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [getId]: checked,
      }),
    });
    //set false hide if required tunr-on and vice versa.
    if (getId.indexOf(`Hide`) !== -1) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            [getId.replaceAll(`Hide`, `Required`)]: false,
          }),
        });
      }
    } else if (getId.indexOf(`Required`) !== -1) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            [getId.replaceAll(`Required`, `Hide`)]: false,
          }),
        });
      }
    } else if (getId.indexOf(`IsSync`) !== -1) {
      if (!checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            [getId.replaceAll(`IsSync`, `PfCoreField`)]: ``,
          }),
        });
      }
    } else if (getId === `multiPricesSameLot`) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            singlePricePerLot: false,
            basePriceByPayment: false,
            paymentPercentBased: false,
            paymentByVariety: false,
          }),
        });
      }
    } else if (getId === `singlePricePerLot`) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            multiPricesSameLot: false,
            paymentPercentBased: false,
            paymentByVariety: false,
          }),
        });
      }
    } else if (getId === `basePriceByPayment`) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            multiPricesSameLot: false,
            paymentPercentBased: false,
            paymentByVariety: false,
          }),
        });
      }
    } else if (getId === `paymentPercentBased`) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            multiPricesSameLot: false,
            singlePricePerLot: false,
            basePriceByPayment: false,
            paymentByVariety: false,
          }),
        });
      }
    } else if (getId === `paymentByVariety`) {
      if (checked) {
        this.setState({
          data: Object.assign({}, this.state.data, {
            multiPricesSameLot: false,
            singlePricePerLot: false,
            basePriceByPayment: false,
            paymentPercentBased: false,
          }),
        });
      }
    }
  }

  showPanelBeforeSubmit = () => {
    const toggle = document.getElementById("advance-settings-toggle");
    const expanded = toggle.getAttribute("aria-expanded");
    if (
      expanded === "false" &&
      this.state.data.multiPricesSameLot &&
      String.isEmpty(this.state.data.multiplePriceField)
    ) {
      toggle.click();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.checkSomethingBeforeSave() === false) {
      return;
    }

    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          modifiedUserId: loggedUser._id,
        }),
        loading: true,
      },
      function callBack() {
        try {
          const api =
            this.state.urlsubmitapi === null ||
            this.state.urlsubmitapi === undefined ||
            this.state.urlsubmitapi === ""
              ? this.state.urlapi
              : this.state.urlsubmitapi;
          const requestOptions = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...this.state.data,
              selectedFields: this.handleSubmitSelectedFields(),
            }),
          };
          const that = this;
          fetch(api + this.state.data._id, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If updated successful
              if (resultObject && resultObject.result === "OK") {
                that.setState({
                  isChanged: false,
                  alertshow: false,
                });
                window.showAlert("", "Save successful!", "");
                that.doSomethingAfterSaved(resultObject);
              }
              //If failed, show error
              else {
                that.setState({
                  alertshow: false,
                });
                that.setState({
                  alertmsg: resultObject.message,
                  alerttype: "error",
                  alertshow: true,
                });
              }
              that.setState({ loading: false });
            })
            .catch(function (error) {
              that.setState({
                alertshow: false,
              });
              that.setState({
                alertmsg: error,
                alerttype: "error",
                alertshow: true,
              });
              that.setState({ loading: false });
            });
        } catch (err) {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: err.message,
            alerttype: "error",
            alertshow: true,
          });
          this.setState({ loading: false });
        }
      }
    );
  };

  doSomethingAfterSaved = () => {
    //This function updates test type menu items in left navigation (Grading & Tests)
    if (this.props.testTypes) {
      const testType = JSON.parse(JSON.stringify(this.state.data));
      switch (testType.isInUse) {
        case true: {
          const testTypeExist = this.getTestTypeInMenuItem(testType._id);
          if (testTypeExist) {
            //If test type exists in Gradings & Tests then update name if it's changed
            if (testType.name !== testTypeExist.name) {
              this.props.updateTestType({
                _id: testType._id,
                name: testType.name,
                isDynamic: testType.isDynamic,
              });
            }
          } else {
            //If test type doesn't exist then add new
            if (!testType.isDynamic || this.allowShowDynamicTestTypes()) {
              this.props.addTestType({
                _id: testType._id,
                name: testType.name,
                isDynamic: testType.isDynamic,
              });
            }
          }
          break;
        }
        case false: {
          this.props.removeTestType(testType._id);
          break;
        }
        default:
      }
    }
  };

  getTestTypeInMenuItem = (testTypeId) => {
    return this.props.testTypes.find((testType) => testType._id === testTypeId);
  };

  allowShowDynamicTestTypes = () => {
    return (
      loggedUser.titerInfo && loggedUser.titerInfo.isAllowDynamicTest === true
    );
  };

  renderFooter = () => {
    return (
      <footer className="panel-footer">
        <div className="row footer-button-group-container">
          {loggedUser.role === "Administrators" ||
          this.state.data.name !== TEST_TYPE_WALNUT ? (
            <SaveButton
              loading={this.state.loading}
              onClick={this.showPanelBeforeSubmit}
            />
          ) : (
            ""
          )}
          {this.state.urllist === null ||
          this.state.urllist === undefined ||
          this.state.urllist === "" ? (
            ""
          ) : (
            <a
              href={this.state.urllist}
              title="Back to list"
              className="btn btn-primary"
            >
              <i className="fa fa-backward fa-lg"></i> Back
            </a>
          )}
          {this.extendButtons()}
        </div>
      </footer>
    );
  };

  handleDeleteTestField = (testFieldId) => {
    const convertArray = this.handleConvertSelectedFieldsToArray();
    const filter = convertArray.filter((x) => x._id !== testFieldId);
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        selectedFields: this.handleGroupSelectedFieldDelete(filter),
      }),
    });
  };

  handleOpenEditGroup = ({ _id, section }) => {
    const nutTypeGroupData =
      section === "Meta Data"
        ? this.state.data.groupMetaData
        : this.state.data.groupQualityData;

    const nutTypeGroup = nutTypeGroupData.find(
      (nutTypeGroup) => nutTypeGroup._id === _id
    );

    this.setState({
      dataPopupGroup: Object.assign({}, this.state.dataPopupGroup, {
        _id: nutTypeGroup._id,
        name: nutTypeGroup.name,
        nutTypeId: nutTypeGroup.nutTypeId,
        section: section,
        color: nutTypeGroup.color,
        sequence: nutTypeGroup.sequence,
        size: nutTypeGroup.size,
        showAddGroupModal: true,
      }),
      formType: "edit",
      errorMessage: "",
    });
  };

  handleResetGroupForm = () => {
    this.setState({
      dataPopupGroup: Object.assign({}, this.state.dataPopupGroup, {
        _id: "",
        showAddGroupModal: false,
        name: "",
        nutTypeId: "",
        section: "",
        color: "#4d6aff",
        size: "col-xs-12 col-md-6 col-lg-3",
        sequence: 0,
      }),
      formType: "",
      errorMessage: "",
    });
  };

  handleDeleteGroup = ({ _id, section }) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete selected group?",

      buttons: [
        {
          className: "btn btn-warning",
          label: "Yes",
          onClick: async () => {
            //Delete fields in deleted group

            const convertArray = this.handleConvertSelectedFieldsToArray();
            const filter = convertArray
              ? convertArray.filter((x) => x.nutTypeGroupId !== _id)
              : [];

            this.setState(
              {
                isChanged: true,
                data: Object.assign({}, this.state.data, {
                  selectedFields: this.handleGroupSelectedFieldDelete(filter),
                }),
              },
              () => {
                //Delete group
                if (section === "Meta Data") {
                  this.setState({
                    data: Object.assign({}, this.state.data, {
                      groupMetaData: this.state.data.groupMetaData.filter(
                        (nutTypeGroup) => nutTypeGroup._id !== _id
                      ),
                    }),
                  });
                } else {
                  this.setState({
                    data: Object.assign({}, this.state.data, {
                      groupQualityData: this.state.data.groupQualityData.filter(
                        (nutTypeGroup) => nutTypeGroup._id !== _id
                      ),
                    }),
                  });
                }
              }
            );
            window.showAlert("Success", "Removed successful!", "");
          },
        },
        {
          label: "No",
          className: "btn btn-default",
        },
      ],
    });
  };

  handleOpenAddGroup = (section) => {
    this.setState({
      dataPopupGroup: Object.assign({}, this.state.dataPopupGroup, {
        showAddGroupModal: true,
        section: section,
      }),
      formType: "add",
      errorMessage: "",
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleChangeFieldType = (_, value) => {
    this.setState({
      isChanged: true,
      filterFieldType: value,
    });
  };

  handleChangeFieldName = (event) => {
    this.setState({
      isChanged: true,
      filterFieldName: event.target.value,
    });
  };

  handleGetDataByProcessor = () => {
    // load dropdown data by apply processor
    this.setState(
      {
        urlApiDfaInspector:
          GlobalConfig.REACT_APP_API_LABUSER_URL +
          "simplebyprocessor/" +
          this.state.data.processorId,
        urlApiGrowerName:
          GlobalConfig.REACT_APP_API_GROWER_URL +
          "byprocessor/" +
          this.state.data.processorId,
      },
      async () => {
        await this.dfaInspectorControl.getItems();
        await this.growerControl.getItems();
        await this.queryFields();
      }
    );
  };

  handleResetByApplyProcessor = (controlId, value) => {
    if (this.state.data.processorId !== value) {
      this.setState(
        {
          isChanged: true,
          data: Object.assign({}, this.state.data, {
            processorId: value,
            groupMetaData: [],
            groupQualityData: [],
            selectedFields: [],
          }),
          urlApiDfaInspector:
            GlobalConfig.REACT_APP_API_LABUSER_URL +
            "simplebyprocessor/" +
            value,
          urlApiGrowerName:
            GlobalConfig.REACT_APP_API_GROWER_URL + "byprocessor/" + value,
        },
        async () => {
          await this.dfaInspectorControl.getItems();
          await this.growerControl.getItems();
          await this.queryFields();
        }
      );
    }
  };

  handleChangeColor = (e) => {
    defaultColor = e.target.value;
  };

  handleSubmitColor = (e) => {
    this.setState({
      dataPopupGroup: Object.assign({}, this.state.dataPopupGroup, {
        [e.target.id]: defaultColor,
      }),
    });
  };

  handleChangeSelectPopupGroupControl = (controlId, value) => {
    this.setState({
      dataPopupGroup: Object.assign({}, this.state.dataPopupGroup, {
        [controlId]: value,
      }),
    });
  };

  checkSequenceExist = (group, sequence) => {
    let isExist = false;
    isExist = group.some((x) => x.sequence === sequence);
    return isExist;
  };

  increaseSequenceRecursive = (group) => {
    const increaseSequence = () => {
      let sequence = this.state.dataPopupGroup.sequence;
      while (this.checkSequenceExist(group, sequence)) {
        sequence += 1;
      }
      return sequence;
    };
    return increaseSequence();
  };

  checkSequenceIsSmallest = (index) => {
    if (index === 0) {
      return true;
    }
  };

  checkSequenceIsLargest = (index, data) => {
    if (index === data.length - 1) {
      return true;
    }
  };

  checkProcessorExistInProcessorsHide = (processorsHide) => {
    if (processorsHide === "") {
      return false;
    }
    const processorsHideList = processorsHide.split(",");
    return processorsHideList.includes(loggedUser.processorId);
  };

  handleSaveGroup = () => {
    const nutTypeGroupData =
      this.state.dataPopupGroup.section === "Meta Data"
        ? [...this.state.data.groupMetaData]
        : [...this.state.data.groupQualityData];

    const nutTypeGroupDataForEditOrAdd =
      this.state.formType === "edit"
        ? nutTypeGroupData.filter(
            (nutType, _index) => nutType._id !== this.state.dataPopupGroup._id
          )
        : nutTypeGroupData;
    const sequence = this.increaseSequenceRecursive(
      nutTypeGroupDataForEditOrAdd
    );

    const nutTypeGroup = nutTypeGroupData.find(
      (nutTypeGroup) => nutTypeGroup._id === this.state.dataPopupGroup._id
    );

    if (!this.state.dataPopupGroup.name) {
      this.setState({
        errorMessage: "Group Name is required",
      });
      return;
    }

    if (
      this.checkGroupNameExist(
        this.state.dataPopupGroup.name,
        nutTypeGroupDataForEditOrAdd
      )
    ) {
      this.setState({
        errorMessage: "Group Name already exists. Please try another one.",
      });
      return;
    }

    if (!this.state.dataPopupGroup.color) {
      this.setState({
        errorMessage: "Color is required",
      });
      return;
    }

    const addNutTypeGroupForm = {
      _id: nutTypeGroup ? nutTypeGroup._id : uuidv4(),
      name: this.state.dataPopupGroup.name,
      nutTypeId: this.state.dataPopupGroup.nutTypeId,
      section: this.state.dataPopupGroup.section,
      color: this.state.dataPopupGroup.color,
      size: this.state.dataPopupGroup.size,
      sequence: sequence,
    };

    if (this.state.dataPopupGroup.section === "Meta Data") {
      const groupMetaData = [
        ...nutTypeGroupDataForEditOrAdd,
        addNutTypeGroupForm,
      ];
      this.setState(
        {
          data: Object.assign({}, this.state.data, {
            groupMetaData: groupMetaData,
          }),
        },
        () => {
          this.handleResetGroupForm();
        }
      );
    } else if (this.state.dataPopupGroup.section === "Quality Data") {
      const groupQualityData = [
        ...nutTypeGroupDataForEditOrAdd,
        addNutTypeGroupForm,
      ];
      this.setState(
        {
          data: Object.assign({}, this.state.data, {
            groupQualityData: groupQualityData,
          }),
        },
        () => {
          this.handleResetGroupForm();
        }
      );
    }
  };

  checkGroupNameExist = (name, nutTypeGroupData) => {
    let isExist = false;
    isExist = nutTypeGroupData.some(
      (nutTypeGroup) => nutTypeGroup.name === name
    );
    return isExist;
  };

  switchData = (data, index, key, isUp) => {
    if (!!data.length && data.length <= 1) return data;
    const newData = data.map((nutTypeGroup, i) => {
      if (i === index) {
        return {
          ...nutTypeGroup,
          sequence: isUp ? data[index + 1][key] : data[index - 1][key],
        };
      }
      if ((i === index + 1 && isUp) || (i === index - 1 && !isUp)) {
        return {
          ...nutTypeGroup,
          sequence: data[index][key],
        };
      }
      return nutTypeGroup;
    });
    return newData;
  };

  switchUpSequenceGroup = (index, section) => {
    if (section === "Meta Data") {
      const newGroupMetaData = this.switchData(
        this.state.data.groupMetaData,
        index,
        "sequence",
        true
      );
      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          groupMetaData: newGroupMetaData,
        }),
      });
    }

    if (section === "Quality Data") {
      const newGroupQualityData = this.switchData(
        this.state.data.groupQualityData,
        index,
        "sequence",
        true
      );
      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          groupQualityData: newGroupQualityData,
        }),
      });
    }
  };

  switchDownSequenceGroup = (index, section) => {
    if (section === "Meta Data") {
      const newGroupMetaData = this.switchData(
        this.state.data.groupMetaData,
        index,
        "sequence",
        false
      );
      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          groupMetaData: newGroupMetaData,
        }),
      });
    }

    if (section === "Quality Data") {
      const newGroupQualityData = this.switchData(
        this.state.data.groupQualityData,
        index,
        "sequence",
        false
      );
      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          groupQualityData: newGroupQualityData,
        }),
      });
    }
  };

  renderAddButtons = (section) => {
    const className = section === "Meta Data" ? "warning" : "primary";
    return (
      <div>
        <div
          onClick={() => this.handleOpenAddGroup(section)}
          className="panel-heading-button"
        >
          <i className="fa fa-plus fa-lg"></i> Group
        </div>
        <div
          onClick={() => this.handleOpenAddTestField(section, ``)}
          className="panel-heading-button"
        >
          <i className="fa fa-list-ul fa-lg"></i> Select Field
        </div>
      </div>
    );
  };

  handleOpenAddTestField = (section, groupId) => {
    this.setState({
      currentSection: section,
      currentGroupId: groupId === undefined || groupId === null ? "" : groupId,
      show: true,
      errorMessage: "",
      isChanged: false,
    });
  };

  handleCloseTestField = () => {
    this.setState(
      { show: false, filterFieldName: "", filterFieldType: "" },
      () => {
        this.queryFields();
      }
    );
  };

  handleSelectedCheckboxTestField(event, newlyId) {
    const newTestFieldId = newlyId ? newlyId : event.target.value;
    const keyCheck = !!this.state.data.selectedFields?.[
      this.state.currentSection && this.state.currentGroupId === ""
        ? this.state.currentSection
        : this.state.currentGroupId
    ]
      ? this.state.data.selectedFields?.[
          this.state.currentSection && this.state.currentGroupId === ""
            ? this.state.currentSection
            : this.state.currentGroupId
        ]
      : [];

    const existFields = this.handleConvertSelectedFieldsToArray().filter(
      (x) => {
        return x.testFieldId === newTestFieldId;
      }
    );
    if (existFields.length <= 0) {
      const newFields = this.state.allFields.filter((x) => {
        return x._id === newTestFieldId;
      });

      if (newFields && newFields.length > 0) {
        const newNutTypeField = {
          nutTypeId: this.state.data._id,
          section: this.state.currentSection,
          nutTypeGroupId:
            this.state.currentGroupId === undefined
              ? ""
              : this.state.currentGroupId,
          testFieldId: newTestFieldId,
          sequence: 0, //will handle later
          isMandatory: false, //will handle later
          createdUserId: loggedUser._id,
          createdDate: new Date(),
        };

        const combineFields = Object.assign({}, newFields[0], newNutTypeField);

        this.setState(
          {
            isChanged: true,
            data: Object.assign({}, this.state.data, {
              selectedFields: {
                ...this.state.data.selectedFields,
                [this.state.currentSection && this.state.currentGroupId === ""
                  ? this.state.currentSection
                  : this.state.currentGroupId]: [...keyCheck, combineFields],
              },
            }),
          },
          () => {
            //If there is no more field, close popup as well
            if (
              this.state.allFields.filter((f) => {
                const length = this.handleConvertSelectedFieldsToArray().filter(
                  (x) => {
                    return x.testFieldId === f._id;
                  }
                );
                return length <= 0;
              }).length <= 0
            ) {
              this.setState({
                show: false,
              });
            }
          }
        );
      }
    } else {
      //Remove
      const convertArray = this.handleConvertSelectedFieldsToArray();
      const filter = convertArray.filter(
        (x) => x.testFieldId !== newTestFieldId
      );

      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          selectedFields: this.handleGroupSelectedFieldDelete(filter),
        }),
      });
    }
  }

  extendBody = () => {
    return (
      <Modal
        show={this.state.dataPopupGroup.showAddGroupModal}
        onHide={this.handleResetGroupForm}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form id="fromPopup">
          <Modal.Header>
            <Modal.Title>
              {this.state.formType === "add" ? "Add Group" : "Edit Group"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="attribute">
                  Group Name <span className="required">(*)</span>
                </label>
                <div className="col-md-9">
                  <TextareaAutosize
                    type="text"
                    name="name"
                    maxRows={3}
                    minRows={1}
                    data-autoresize
                    autoFocus={true}
                    className="form-control textarea-crop-year"
                    placeholder="Enter group name"
                    required
                    value={this.state.dataPopupGroup.name}
                    onChange={(event) => {
                      var id = event.target.name;
                      const value = event.target.value;
                      this.setState({
                        dataPopupGroup: Object.assign(
                          {},
                          this.state.dataPopupGroup,
                          {
                            [id]: value,
                          }
                        ),
                      });
                    }}
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="attribute">
                  Width <span className="required">(*)</span>
                </label>
                <div className="col-md-9">
                  <FilterSelectControlWithoutDefaultOption
                    handleChangeSelectControl={
                      this.handleChangeSelectPopupGroupControl
                    }
                    onRef={(ref) => (this.sizeControl = ref)}
                    label="Select Size"
                    controlId="size"
                    value={this.state.dataPopupGroup.size}
                    isUseDefaultData
                    defaultData={defaultSizeGroupOptions}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label" htmlFor="attribute">
                  Color <span className="required">(*)</span>
                </label>
                <div className="col-md-3">
                  <input
                    type="color"
                    id="color"
                    name="color"
                    className="form-control"
                    value={this.state.dataPopupGroup.color}
                    onChange={this.handleChangeColor}
                    onBlur={this.handleSubmitColor}
                  />
                </div>
              </div>

              <div className="col-md-12">
                {!!this.state.errorMessage && (
                  <Form.Label
                    column
                    md="12"
                    style={{ color: "red", paddingLeft: "0px" }}
                  >
                    {this.state.errorMessage}
                  </Form.Label>
                )}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer-center">
            {this.state.dataPopupGroup ? (
              <Button
                variant="success"
                type="button"
                onClick={this.handleSaveGroup}
              >
                <span className="fa fa-save fa-lg"></span> Save
              </Button>
            ) : (
              ""
            )}
            <Button variant="warning" onClick={this.handleResetGroupForm}>
              <span className="fa fa-times fa-lg"></span> Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  checkField = (id) => {
    const selectedFields = this.handleConvertSelectedFieldsToArray();
    if (selectedFields.length > 0) {
      return (
        selectedFields.filter((x) => {
          return x.testFieldId === id;
        }).length > 0
      );
    }
    return false;
  };

  handleSearchTestField = () => {
    this.setState(
      {
        searchLoading: true,
      },
      () => this.queryFields()
    );
  };

  extendRender = () => {
    return (
      <SelectFieldPopup
        show={this.state.show}
        handleCloseTestField={this.handleCloseTestField}
        allFields={this.state.allFields}
        currentSection={this.state.currentSection}
        checkField={this.checkField}
        handleChecked={this.handleSelectedCheckboxTestField}
        handleChangeFieldType={this.handleChangeFieldType}
        handleChangeFieldName={this.handleChangeFieldName}
        handleSearchTestField={this.handleSearchTestField}
        handleOpenPopupCreateTestField={this.handleOpenPopupCreateTestField}
        loading={this.state.searchLoading}
      />
    );
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Can't be blank", "error");
    }
  }

  handleChangeSelectTypeContainers(event) {
    const id = event.target.name;
    const value = event.target.value;
    // When user change select from Bulk to Bin, then reset value Sync Number
    // if (value === "Bin") {
    //   this.setState({
    //     data: Object.assign({}, this.state.data, {
    //       [id]: value,
    //       numberOfContainersIsSync: false,
    //       numberOfContainersPfCoreField: "",
    //     }),
    //   });
    //   return;
    // }
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  handleChangeNumberContainers(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        numberOfContainers: value,
      }),
    });
  }

  getTestDate4Edit() {
    try {
      return this.state.data.testDate
        ? moment(this.state.data.testDate).format("MM/DD/yyyy")
        : moment(new Date()).format("MM/DD/yyyy");
    } catch {
      return "";
    }
  }

  doSomethingAfterGetItemById = () => {
    if (this.netWeightUnit) {
      this.netWeightUnit.setSelected(this.state.data.netWeightUnit);
    }
    this.setState({
      data: {
        ...this.state.data,
        numberOfContainers:
          this.state.data.typeNumberOfContainers === "Bin"
            ? this.state.data.numberOfContainers
              ? this.state.data.numberOfContainers
              : 0
            : 0,
      },
    });
  };

  handleOpenPopupCreateTestField = () => {
    this.setState({
      show: false,
      showCreateTestFieldPopup: true,
    });
  };

  handleClosePopupCreateTestField = () => {
    this.setState(
      {
        show: true,
        showCreateTestFieldPopup: false,
      },
      async () => {
        await this.queryFields(() => {
          const getNewlyItem = this.state.allFields[0]._id;
          this.handleSelectedCheckboxTestField(null, getNewlyItem);
        });
      }
    );
  };

  handleCloseAllPopup = () => {
    this.setState({
      show: false,
      showCreateTestFieldPopup: false,
    });
  };

  renderForm = () => {
    return (
      <div className="panel-body panel-body-spacing">
        {this.extendBody()}

        <AddPopupTestField
          show={this.state.showCreateTestFieldPopup}
          handleCloseAllPopup={this.handleCloseAllPopup}
          handleClosePopupCreateTestField={this.handleClosePopupCreateTestField}
          currentSection={this.state.currentSection}
        />

        <fieldset>
          <div className="form-group form-group-spacing">
            <div className="col-sm-12 col-md-8 col-lg-6">
              {this.state.data.processorId === loggedUser.processorId ||
              loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                <Textbox
                  autoFocus={true}
                  required
                  type="text"
                  label="Test Name"
                  name="name"
                  value={this.state.data.name}
                  handleChange={this.handleChange}
                  maxLength={100}
                  placeholder="Enter test name"
                />
              ) : (
                <>
                  <label className="col-md-4 control-label">Test Name</label>
                  <label
                    className="col-md-8 control-label"
                    style={{ textAlign: "left" }}
                  >
                    {this.state.data.name}
                  </label>
                </>
              )}
            </div>
          </div>
          <div className="form-group form-group-spacing">
            <div className="col-md-8 col-lg-6">
              <div className="form-group">
                <label className="col-md-4 control-label">Publish</label>
                <div className="col-md-8 btn-switch-control">
                  <Switch
                    name="isInUse"
                    onChange={this.handleInUseChangeSwitch}
                    checked={this.state.data.isInUse}
                    className="react-switch"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr style={{ marginBottom: "0px", marginTop: "10px" }}></hr>
          {loggedUser.role === "Administrators" ||
          this.state.data.isDynamic === true ? (
            <div className="col-lg-12 mt-sm">
              <div className="row">
                <section className="panel-info">
                  <a
                    id="advance-settings-toggle"
                    data-toggle="collapse"
                    aria-expanded="false"
                    href="#advanced-settings"
                    className="panel-heading-custom panelNutType panel-heading"
                  >
                    <h2 className="panel-title panel-title-main">
                      Advanced Settings
                    </h2>
                    <div className="icon-collapse">
                      <i className="fa fa-chevron-up fa-lg"></i>
                      <i className="fa fa-chevron-down fa-lg"></i>
                    </div>
                  </a>
                  <div
                    id="advanced-settings"
                    className="panel-collapse collapse"
                  >
                    <div className="panel-body panel-body-success panel-body-spacing">
                      <div className="form-group form-group-spacing">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="col-md-4 control-label">
                              Base Price By Payments
                            </label>
                            <div className="col-md-8 btn-switch-control">
                              <Switch
                                id="basePriceByPayment"
                                name="basePriceByPayment"
                                onChange={this.handleHeaderFormChangeSwitch}
                                checked={this.state.data.basePriceByPayment}
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-group-spacing">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="col-md-4 control-label">
                              Payment Percent Based
                            </label>
                            <div className="col-md-8 btn-switch-control">
                              <Switch
                                id="paymentPercentBased"
                                name="paymentPercentBased"
                                onChange={this.handleHeaderFormChangeSwitch}
                                checked={this.state.data.paymentPercentBased}
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group form-group-spacing">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="col-md-4 control-label">
                              Multiple Prices Same LOT
                            </label>
                            <div className="col-md-8 btn-switch-control">
                              <Switch
                                id="multiPricesSameLot"
                                name="multiPricesSameLot"
                                onChange={this.handleHeaderFormChangeSwitch}
                                checked={this.state.data.multiPricesSameLot}
                                className="react-switch"
                              />
                            </div>
                            <label style={{ paddingLeft: "15px" }}>
                              Allow setup multiplease price same LOT
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.data.multiPricesSameLot ? (
                        <>
                          <div className="form-group form-group-spacing">
                            <div className="col-md-6 col-lg-6">
                              <div className="form-group">
                                <Textbox
                                  autoFocus={true}
                                  required
                                  type="text"
                                  label="Multiple Price Field Name"
                                  name="multiplePriceField"
                                  value={this.state.data.multiplePriceField}
                                  handleChange={this.handleChange}
                                  maxLength={500}
                                  placeholder=""
                                  note="Enter a Drop Down field name in Meta Data section."
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group form-group-spacing">
                            <div className="col-md-6 col-lg-6">
                              <div className="form-group">
                                <Textbox
                                  type="text"
                                  label="Base Price Meta Attribute"
                                  name="basePriceField"
                                  value={this.state.data.basePriceField}
                                  handleChange={this.handleChange}
                                  maxLength={100}
                                  placeholder=""
                                  note="Enter field name in Meta Data section to allow base price setup based on. Multiple fields separated by comma."
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="form-group form-group-spacing">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="col-md-4 control-label">
                              Single Price Per LOT
                            </label>
                            <div className="col-md-8 btn-switch-control">
                              <Switch
                                id="singlePricePerLot"
                                name="singlePricePerLot"
                                onChange={this.handleHeaderFormChangeSwitch}
                                checked={this.state.data.singlePricePerLot}
                                className="react-switch"
                              />
                            </div>
                            <label style={{ paddingLeft: "15px" }}>
                              Price based on meta attribute
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.data.singlePricePerLot ? (
                        <div className="form-group form-group-spacing">
                          <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                              <Textbox
                                autoFocus={true}
                                required
                                type="text"
                                label="Base Price Meta Attribute"
                                name="singlePricePerLotField"
                                value={this.state.data.singlePricePerLotField}
                                handleChange={this.handleChange}
                                maxLength={100}
                                placeholder=""
                                note="Enter field name in Meta Data section to allow base price setup based on. Multiple fields separated by comma."
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group form-group-spacing">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="col-md-4 control-label">
                              Payment by Variety
                            </label>
                            <div className="col-md-8 btn-switch-control">
                              <Switch
                                id="paymentByVariety"
                                name="paymentByVariety"
                                onChange={this.handleHeaderFormChangeSwitch}
                                checked={this.state.data.paymentByVariety}
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.data.isDynamic && (
                        <>
                          <hr
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          ></hr>
                          <div className="form-group form-group-spacing">
                            <div className="col-md-6 col-lg-6">
                              <div className="form-group">
                                <label className="col-md-4 control-label">
                                  Variety Optional
                                </label>
                                <div className="col-md-8 btn-switch-control">
                                  <Switch
                                    id="varietyOptional"
                                    name="varietyOptional"
                                    onChange={this.handleHeaderFormChangeSwitch}
                                    checked={this.state.data.varietyOptional}
                                    className="react-switch"
                                  />
                                </div>
                                <label style={{ paddingLeft: "15px" }}>
                                  Variety to be optional when setting Base
                                  Prices & Adjustments
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group form-group-spacing">
                            <div className="col-md-6 col-lg-6">
                              <div className="form-group">
                                <label className="col-md-4 control-label">
                                  Taxes & Fees Based On Meta
                                </label>
                                <div className="col-md-8 btn-switch-control">
                                  <Switch
                                    id="taxFeeByMeta"
                                    name="taxFeeByMeta"
                                    onChange={this.handleHeaderFormChangeSwitch}
                                    checked={this.state.data.taxFeeByMeta}
                                    className="react-switch"
                                  />
                                </div>
                                <label style={{ paddingLeft: "15px" }}>
                                  Allow setup tax & fee based on meta attributes
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          ></hr>
                          <div className="form-group form-group-spacing">
                            <div className="col-md-6 col-lg-6">
                              <div className="form-group">
                                <label className="col-md-4 control-label">
                                  Multiple Tests Per LOT
                                </label>
                                <div className="col-md-8 btn-switch-control">
                                  <Switch
                                    id="multiTestsPerLot"
                                    name="multiTestsPerLot"
                                    onChange={this.handleHeaderFormChangeSwitch}
                                    checked={this.state.data.multiTestsPerLot}
                                    className="react-switch"
                                  />
                                </div>
                                <label style={{ paddingLeft: "15px" }}>
                                  Allow enter multiple rows Quality Data
                                </label>
                              </div>
                            </div>
                          </div>
                          {this.state.data.multiTestsPerLot === true ? (
                            <>
                              <div className="form-group form-group-spacing">
                                <div className="col-md-6 col-lg-6">
                                  <NumericInput
                                    precision={0}
                                    min={0}
                                    max={1000}
                                    step={1}
                                    label="Samples"
                                    name="multiTestNumberSamples"
                                    value={
                                      this.state.data.multiTestNumberSamples
                                    }
                                    handleChange={this.handleChange}
                                    note="Configure how many samples per test"
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group form-group-spacing"
                                style={{ marginBottom: "10px" }}
                              >
                                <div className="col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label className="col-md-4 control-label">
                                      Grower Payment Value
                                    </label>
                                    <div className="col-md-8 btn-switch-control">
                                      <SelectConfig
                                        configType="Test Type Multiple Test Value"
                                        required={true}
                                        onRef={(ref) => (this.fieldType = ref)}
                                        name="multiTestGrowerPaymentType"
                                        controlId="multiTestGrowerPaymentType"
                                        value={
                                          this.state.data
                                            .multiTestGrowerPaymentType
                                        }
                                        handleChangeSelectControl={
                                          this.handleChangeSelectControl
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6">
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Dynamic Test
                              </label>
                              <div className="col-md-8 btn-switch-control">
                                <Switch
                                  name="isDynamic"
                                  onChange={this.handleIsDynamicChangeSwitch}
                                  checked={this.state.data.isDynamic}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {loggedUser.role === USER_ROLE_ADMINISTRATOR &&
                      this.state.data.isDynamic ? (
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6">
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Apply Processor
                              </label>
                              <div className="col-md-8">
                                <FilterSelectControl
                                  handleChangeSelectControl={
                                    this.handleResetByApplyProcessor
                                  }
                                  onRef={(ref) => (this.processorId = ref)}
                                  placeholder="Select Processor"
                                  label="Select Processor"
                                  controlId="processorId"
                                  value={this.state.data.processorId}
                                  urlapilist={this.state.urlApiProcessorFilter}
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          ) : (
            ""
          )}
        </fieldset>
        {this.state.data.isDynamic && (
          <>
            <div className="col-lg-12 mt-sm">
              <div className="row">
                <section className="panel-success">
                  <a
                    data-toggle="collapse"
                    href="#header"
                    aria-expanded="true"
                    className="panel-heading-custom panelNutType panel-heading"
                  >
                    <h2 className="panel-title panel-title-main">Header</h2>
                    <div className="icon-collapse">
                      <i className="fa fa-chevron-up fa-lg"></i>
                      <i className="fa fa-chevron-down fa-lg"></i>
                    </div>
                  </a>
                  <div id="header" className="panel-collapse collapse in">
                    <div className="panel-body panel-body-success panel-body-spacing">
                      <fieldset>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6">
                            <div className="form-group">
                              <label className="col-md-4 control-label input-header-spacing">
                                Cert Number
                              </label>
                              <div className="col-md-8">
                                <label className="control-label input-header-spacing">
                                  Auto generate
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6">
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Handler Name (Processor Name)
                              </label>
                              <div className="col-md-8">
                                <label className="control-label">
                                  {loggedUser.role === USER_ROLE_STAFF ||
                                  loggedUser.role === USER_ROLE_PROCESSOR
                                    ? loggedUser.processorName
                                    : "{Apply Processor}"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label mt-xs pt-none"
                                htmlFor="testDate"
                              >
                                Test Date <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8 col-lg-6 field-editable-container">
                                <div className="input-daterange input-group">
                                  <span className="input-group-addon test-date-addon">
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                  <DatePicker
                                    className="form-control test-date-input-border"
                                    placeholderText="Enter datetime"
                                    selected={new Date(this.getTestDate4Edit())}
                                    onChange={this.handleDateChange}
                                    onBlur={this.handleOnBlur}
                                  />
                                </div>
                                <div className="switch-control-container ml-md">
                                  <div className="label-switch-control">
                                    Editable
                                  </div>
                                  <div className="btn-switch-control ml-md">
                                    <Switch
                                      id="testDateEditable"
                                      onChange={
                                        this.handleHeaderFormChangeSwitch
                                      }
                                      checked={this.state.data.testDateEditable}
                                      className="react-switch"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="testDateIsSync"
                              valueSync={this.state.data.testDateIsSync}
                              nameCoreField="testDatePfCoreField"
                              valueCoreField={
                                this.state.data.testDatePfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <Textbox
                              type="text"
                              label="Lot Number"
                              name="lotNumber"
                              value={this.state.data.lotNumber}
                              handleChange={this.handleChange}
                              maxLength={20}
                              required={true}
                              displayRequired={true}
                            />
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="lotNumberIsSync"
                              valueSync={this.state.data.lotNumberIsSync}
                              nameCoreField="lotNumberPfCoreField"
                              valueCoreField={
                                this.state.data.lotNumberPfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <SelectControlGrower
                              handleChangeSelectControl={
                                this.handleChangeSelectControl
                              }
                              onRef={(ref) => (this.growerControl = ref)}
                              placeholder="Grower Name"
                              label="Grower Name"
                              popupTitle="Grower"
                              controlId="growerName"
                              value={this.state.growerName}
                              required
                              displayRequired={true}
                              maxLength={50}
                              processorId={loggedUser.processorId}
                              urlapi={GlobalConfig.REACT_APP_API_GROWER_URL}
                              urlapilist={this.state.urlApiGrowerName}
                              isHideAddButton={true}
                            />
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="growerNameIsSync"
                              valueSync={this.state.data.growerNameIsSync}
                              nameCoreField="growerNamePfCoreField"
                              valueCoreField={
                                this.state.data.growerNamePfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <Textbox
                              type="text"
                              label="Field Name"
                              name="fieldName"
                              value={this.state.data.fieldName}
                              handleChange={this.handleChange}
                              maxLength={200}
                              required={this.state.data.fieldNameRequired}
                              displayRequired={true}
                            />
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="fieldNameIsSync"
                              valueSync={this.state.data.fieldNameIsSync}
                              nameCoreField="fieldNamePfCoreField"
                              valueCoreField={
                                this.state.data.fieldNamePfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                            <CheckboxHideRequired
                              nameHide="fieldNameHide"
                              valueHide={this.state.data.fieldNameHide}
                              nameRequired="fieldNameRequired"
                              valueRequired={this.state.data.fieldNameRequired}
                              onChange={this.handleHeaderFormChangeSwitch}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <div className="form-group">
                              <label
                                className="col-md-4 control-label mt-xs pt-none"
                                htmlFor="varitySubmitted"
                              >
                                Variety Submitted{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-8">
                                <SelectWalnutVarieties
                                  urlApi={`${GlobalConfig.REACT_APP_API_VARIETY_URL}byParentId/${this.state.data._id}`}
                                  require={true}
                                  handleChangeSelectControl={
                                    this.handleChangeSelectControl
                                  }
                                  onRef={(ref) => (this.varitySubmitted = ref)}
                                  name="varitySubmitted"
                                  controlId="varitySubmitted"
                                  selectedIds={this.state.data.varitySubmitted}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="varietyIsSync"
                              valueSync={this.state.data.varietyIsSync}
                              nameCoreField="varietyPfCoreField"
                              valueCoreField={
                                this.state.data.varietyPfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                Type of Containers{" "}
                                {this.state.data
                                  .typeNumberOfContainersRequired === true ? (
                                  <span className="required">(*)</span>
                                ) : (
                                  ""
                                )}
                              </label>
                              <div className="col-md-8">
                                <TypeContainer
                                  typeNumberOfContainers={
                                    this.state.data.typeNumberOfContainers
                                  }
                                  handleChangeNumberContainers={
                                    this.handleChangeNumberContainers
                                  }
                                  handleFocus={this.handleFocus}
                                  handleChangeSelectTypeContainers={
                                    this.handleChangeSelectTypeContainers
                                  }
                                  numberOfContainers={
                                    this.state.data.numberOfContainers
                                  }
                                  processorId={loggedUser.processorId}
                                />
                                <CheckboxSync
                                  // isHide={
                                  //   this.state.data.typeNumberOfContainers !==
                                  //   "Bin"
                                  // }
                                  title="Sync Number"
                                  nameSync="numberOfContainersIsSync"
                                  className="checkbox-bin"
                                  valueSync={
                                    this.state.data.numberOfContainersIsSync
                                  }
                                  nameCoreField="numberOfContainersPfCoreField"
                                  valueCoreField={
                                    this.state.data
                                      .numberOfContainersPfCoreField
                                  }
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  handleChange={this.handleChange}
                                  noContainer={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="checkbox-test-type-container">
                              <CheckboxSync
                                title="Sync"
                                nameSync="typeNumberOfContainersIsSync"
                                valueSync={
                                  this.state.data.typeNumberOfContainersIsSync
                                }
                                nameCoreField="typeNumberOfContainersPfCoreField"
                                valueCoreField={
                                  this.state.data
                                    .typeNumberOfContainersPfCoreField
                                }
                                onChange={this.handleHeaderFormChangeSwitch}
                                handleChange={this.handleChange}
                                noContainer={true}
                              />
                              <CheckboxHideRequired
                                nameHide="typeNumberOfContainersHide"
                                valueHide={
                                  this.state.data.typeNumberOfContainersHide
                                }
                                nameRequired="typeNumberOfContainersRequired"
                                valueRequired={
                                  this.state.data.typeNumberOfContainersRequired
                                }
                                onChange={this.handleHeaderFormChangeSwitch}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 form-group-spacing">
                            <div className="row">
                              <label
                                className="col-md-4 control-label"
                                htmlFor="netWeight"
                              >
                                {this.state.data.xpool === true
                                  ? "Process Weight"
                                  : "Net Weight"}{" "}
                                <span className="required">(*)</span>
                              </label>
                              <div className="col-md-4">
                                <NumericInput
                                  precision={1}
                                  min={0}
                                  max={999999999}
                                  step={1}
                                  label=""
                                  name="netWeight"
                                  value={this.state.data.netWeight}
                                  handleChange={this.handleChange}
                                />
                              </div>
                              <div className="col-md-4 net-weight-unit-container">
                                <label className="net-weight-unit-label">
                                  {" "}
                                  Unit <span className="required">(*)</span>
                                </label>

                                <SelectConfig
                                  label="Select Unit"
                                  configType="Weight Unit"
                                  required={true}
                                  onRef={(ref) => (this.netWeightUnit = ref)}
                                  name="netWeightUnit"
                                  controlId="netWeightUnit"
                                  selectedIds={this.state.data.netWeightUnit}
                                  handleChangeSelectControl={
                                    this.handleChangeSelectControl
                                  }
                                  className="net-weight-unit"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="netWeightIsSync"
                              valueSync={this.state.data.netWeightIsSync}
                              nameCoreField="netWeightPfCoreField"
                              valueCoreField={
                                this.state.data.netWeightPfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs">
                            <SelectControl
                              handleChangeSelectControl={
                                this.handleChangeSelectControl
                              }
                              onRef={(ref) => (this.dfaInspectorControl = ref)}
                              placeholder="Inspector/Tested By"
                              label="Inspector/Tested By"
                              popupTitle="Inspector/Tested By"
                              controlId="dfaInspector"
                              required={this.state.data.dfaInspectorRequired}
                              displayRequired={true}
                              maxLength={40}
                              processorId={loggedUser.processorId}
                              urlapi={GlobalConfig.REACT_APP_API_LABUSER_URL}
                              urlapilist={this.state.urlApiDfaInspector}
                              isHideAddButton={true}
                              textField="displayName"
                            />
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="dfaInspectorIsSync"
                              valueSync={this.state.data.dfaInspectorIsSync}
                              nameCoreField="dfaInspectorPfCoreField"
                              valueCoreField={
                                this.state.data.dfaInspectorPfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                            <CheckboxHideRequired
                              nameHide="dfaInspectorHide"
                              valueHide={this.state.data.dfaInspectorHide}
                              nameRequired="dfaInspectorRequired"
                              valueRequired={
                                this.state.data.dfaInspectorRequired
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 mb-xs p-none">
                            <label
                              className="col-md-4 control-label mt-xs pt-none"
                              htmlFor="photo"
                            >
                              Take Photo{" "}
                              {this.state.data.photoRequired ? (
                                <span className="required">(*)</span>
                              ) : (
                                ""
                              )}
                            </label>
                            <div className="col-md-8">
                              <div
                                className="fileupload fileupload-new"
                                data-provides="fileupload"
                              >
                                <div className="input-append input-take-photo-container">
                                  <div className="uneditable-input input-take-photo-size input-take-photo-border">
                                    <i className="fa fa-file fileupload-exists"></i>
                                    <span className="fileupload-preview"></span>
                                  </div>
                                  <span className="btn btn-default btn-file m-none btn-take-photo-border">
                                    <span
                                      className="fileupload-exists"
                                      title="Change"
                                    >
                                      <i
                                        className="fa fa-camera fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="fileupload-new">
                                      <i
                                        className="fa fa-camera fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <input
                                      type="file"
                                      name="photo"
                                      onChange={this.handleChange4Image}
                                      multiple
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxHideRequired
                              nameHide="photoHide"
                              valueHide={this.state.data.photoHide}
                              nameRequired="photoRequired"
                              valueRequired={this.state.data.photoRequired}
                              onChange={this.handleHeaderFormChangeSwitch}
                            />
                          </div>
                        </div>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6 form-group-spacing">
                            <NumericInput
                              precision={2}
                              min={0}
                              max={999999999}
                              step={0.1}
                              label="Override Price"
                              name="overridePrice"
                              value={this.state.data.overridePrice}
                              handleChange={this.handleChange}
                              displayRequired={true}
                            />
                          </div>
                          <div className="checkbox-test-type-container col-lg-6 col-md-12">
                            <CheckboxSync
                              title="Sync"
                              nameSync="overridePriceIsSync"
                              valueSync={this.state.data.overridePriceIsSync}
                              nameCoreField="overridePricePfCoreField"
                              valueCoreField={
                                this.state.data.overridePricePfCoreField
                              }
                              onChange={this.handleHeaderFormChangeSwitch}
                              handleChange={this.handleChange}
                              noContainer={true}
                            />
                            <div className="hide-require-component-container">
                              <div className="label-switch-control">Hide</div>
                              <div className="btn-switch-control ml-xs">
                                <Switch
                                  id="overridePriceHide"
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  checked={this.state.data.overridePriceHide}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        ></hr>
                        <div className="form-group form-group-spacing">
                          <div className="col-md-8 col-lg-6">
                            <div className="form-group">
                              <label className="col-md-4 control-label">
                                XPool
                              </label>
                              <div className="col-md-8 btn-switch-control">
                                <Switch
                                  id="xpool"
                                  name="xpool"
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  checked={this.state.data.xpool}
                                  className="react-switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.data.xpool === true ? (
                          <>
                            <div className="form-group form-group-spacing">
                              <div className="col-md-8 col-lg-6 form-group-spacing">
                                <NumericInput
                                  precision={2}
                                  min={0}
                                  max={100}
                                  step={0.1}
                                  label="XPool Percent"
                                  name="xpoolPercent"
                                  value={this.state.data.xpoolPercent}
                                  handleChange={this.handleChange}
                                  displayRequired={true}
                                  unit="%"
                                />
                              </div>
                              <div className="checkbox-test-type-container col-lg-6 col-md-12">
                                <CheckboxSync
                                  title="Sync"
                                  nameSync="xpoolPercentIsSync"
                                  valueSync={this.state.data.xpoolPercentIsSync}
                                  nameCoreField="xpoolPercentPfCoreField"
                                  valueCoreField={
                                    this.state.data.xpoolPercentPfCoreField
                                  }
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  handleChange={this.handleChange}
                                  noContainer={true}
                                />
                              </div>
                            </div>
                            <div className="form-group form-group-spacing">
                              <div className="col-md-8 col-lg-6 form-group-spacing">
                                <NumericInput
                                  precision={1}
                                  min={0}
                                  max={999999999}
                                  step={1}
                                  label="XPool Weight"
                                  name="xpoolWeight"
                                  value={this.state.data.xpoolWeight}
                                  handleChange={this.handleChange}
                                  displayRequired={true}
                                  unit={this.state.data.netWeightUnit}
                                />
                              </div>
                              <div className="checkbox-test-type-container col-lg-6 col-md-12">
                                <CheckboxSync
                                  title="Sync"
                                  nameSync="xpoolWeightIsSync"
                                  valueSync={this.state.data.xpoolWeightIsSync}
                                  nameCoreField="xpoolWeightPfCoreField"
                                  valueCoreField={
                                    this.state.data.xpoolWeightPfCoreField
                                  }
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  handleChange={this.handleChange}
                                  noContainer={true}
                                />
                              </div>
                            </div>
                            <div className="form-group form-group-spacing">
                              <div className="col-md-8 col-lg-6 form-group-spacing">
                                <NumericInput
                                  precision={2}
                                  min={0}
                                  max={100}
                                  step={0.1}
                                  label="Regular Percent"
                                  name="regularPercent"
                                  value={this.state.data.regularPercent}
                                  handleChange={this.handleChange}
                                  displayRequired={true}
                                  unit="%"
                                  readOnly={true}
                                />
                              </div>
                              <div className="checkbox-test-type-container col-lg-6 col-md-12">
                                <CheckboxSync
                                  title="Sync"
                                  nameSync="regularPercentIsSync"
                                  valueSync={
                                    this.state.data.regularPercentIsSync
                                  }
                                  nameCoreField="regularPercentPfCoreField"
                                  valueCoreField={
                                    this.state.data.regularPercentPfCoreField
                                  }
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  handleChange={this.handleChange}
                                  noContainer={true}
                                />
                              </div>
                            </div>
                            <div className="form-group form-group-spacing">
                              <div className="col-md-8 col-lg-6 form-group-spacing">
                                <NumericInput
                                  precision={1}
                                  min={0}
                                  max={999999999}
                                  step={1}
                                  label="Regular Weight"
                                  name="regularWeight"
                                  value={this.state.data.regularWeight}
                                  handleChange={this.handleChange}
                                  displayRequired={true}
                                  readOnly={true}
                                  unit={this.state.data.netWeightUnit}
                                />
                              </div>
                              <div className="checkbox-test-type-container col-lg-6 col-md-12">
                                <CheckboxSync
                                  title="Sync"
                                  nameSync="regularWeightIsSync"
                                  valueSync={
                                    this.state.data.regularWeightIsSync
                                  }
                                  nameCoreField="regularWeightPfCoreField"
                                  valueCoreField={
                                    this.state.data.regularWeightPfCoreField
                                  }
                                  onChange={this.handleHeaderFormChangeSwitch}
                                  handleChange={this.handleChange}
                                  noContainer={true}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="col-md-12 mt-sm">
              <div className="row">
                <section className="panel-warning">
                  <a
                    data-toggle="collapse"
                    href="#metadata"
                    aria-expanded="true"
                    className="panel-heading-custom panel-nut-type panel-heading"
                  >
                    <div className="panel-heading-wrapper">
                      <h2 className="panel-title panel-title-main">
                        Meta Data
                      </h2>

                      <Popover
                        trigger={["hover", "focus"]}
                        placement="top"
                        content='"Meta data is available for use when assigning
                                Base Prices. If you use data to assign a
                                distinct price, it belongs here."'
                        isUseDefaultOverlay
                      >
                        <i
                          className="fa fa-info-circle panel-heading-info-icon"
                          aria-hidden="true"
                        />
                      </Popover>
                    </div>

                    <div className="panel-group-action">
                      {this.renderAddButtons(`Meta Data`)}
                      <div className="icon-collapse">
                        <i className="fa fa-chevron-up fa-lg"></i>
                        <i className="fa fa-chevron-down fa-lg"></i>
                      </div>
                    </div>
                  </a>
                  <div id="metadata" className="panel-collapse collapse in">
                    <div className="panel-body panel-body-warning panel-body-spacing">
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset>
                            {this.renderFields(`Meta Data`, ``)}
                          </fieldset>
                        </div>
                      </div>

                      {this.state.data.groupMetaData &&
                      this.state.data.groupMetaData.length > 0 ? (
                        <div className="panel-group-container">
                          {this.state.data.groupMetaData
                            .sort(
                              (item1, item2) => item1.sequence - item2.sequence
                            )
                            .map((group, index) => (
                              <NutTypeGroup
                                key={index}
                                index={index}
                                {...group}
                                handleOpenAddTestField={
                                  this.handleOpenAddTestField
                                }
                                handleDeleteGroup={this.handleDeleteGroup}
                                isSmallest={this.checkSequenceIsSmallest(index)}
                                isLargest={this.checkSequenceIsLargest(
                                  index,
                                  this.state.data.groupMetaData
                                )}
                                switchUpSequenceGroup={
                                  this.switchUpSequenceGroup
                                }
                                switchDownSequenceGroup={
                                  this.switchDownSequenceGroup
                                }
                                editNutTypeGroup={this.handleOpenEditGroup}
                                bodyTestField={this.renderFields(
                                  `Meta Data`,
                                  group._id
                                )}
                              />
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="col-md-12 mt-sm">
              <div className="row">
                <section className="panel-primary">
                  <a
                    data-toggle="collapse"
                    href="#qualitydata"
                    aria-expanded="true"
                    className="panel-heading-custom panel-nut-type panel-heading"
                  >
                    <div className="panel-heading-wrapper">
                      <h2 className="panel-title panel-title-main">
                        Quality Data
                      </h2>

                      <Popover
                        trigger={["hover", "focus"]}
                        placement="top"
                        content={
                          '"Quality data is available for use when adjusting prices up or down based on quality or grades. If you use data to adjust a base price, it belongs here."'
                        }
                        isUseDefaultOverlay
                      >
                        <i
                          className="fa fa-info-circle panel-heading-info-icon"
                          aria-hidden="true"
                        />
                      </Popover>
                    </div>
                    <div className="panel-group-action">
                      {this.renderAddButtons(`Quality Data`)}
                      <div className="icon-collapse">
                        <i className="fa fa-chevron-up fa-lg"></i>
                        <i className="fa fa-chevron-down fa-lg"></i>
                      </div>
                    </div>
                  </a>
                  <div id="qualitydata" className="panel-collapse collapse in">
                    <div className="panel-body panel-body-primary panel-body-spacing">
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset>
                            {this.renderFields(`Quality Data`, ``)}
                          </fieldset>
                        </div>
                      </div>

                      {this.state.data.groupQualityData &&
                      this.state.data.groupQualityData.length > 0 ? (
                        <div className="panel-group-container">
                          {this.state.data.groupQualityData
                            .sort(
                              (item1, item2) => item1.sequence - item2.sequence
                            )
                            .map((group, index) => (
                              <NutTypeGroup
                                key={index}
                                index={index}
                                {...group}
                                handleOpenAddTestField={
                                  this.handleOpenAddTestField
                                }
                                handleDeleteGroup={this.handleDeleteGroup}
                                isSmallest={this.checkSequenceIsSmallest(index)}
                                isLargest={this.checkSequenceIsLargest(
                                  index,
                                  this.state.data.groupQualityData
                                )}
                                switchUpSequenceGroup={
                                  this.switchUpSequenceGroup
                                }
                                switchDownSequenceGroup={
                                  this.switchDownSequenceGroup
                                }
                                editNutTypeGroup={this.handleOpenEditGroup}
                                bodyTestField={this.renderFields(
                                  `Quality Data`,
                                  group._id
                                )}
                              />
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )}
        {this.extendRender()}
      </div>
    );
  };

  queryFields = async (callBack) => {
    //Query fields belong to test type stand on
    const processorId =
      loggedUser.role === USER_ROLE_PROCESSOR ||
      loggedUser.role === USER_ROLE_STAFF
        ? loggedUser.processorId
        : "all";

    const processorIdAdmin = this.state.data.processorId
      ? this.state.data.processorId
      : "all";

    const { filterFieldName } = this.state;

    const filterFieldType = this.state.filterFieldType
      ? this.state.filterFieldType
      : "all";
    const that = this;
    await fetch(
      `${GlobalConfig.REACT_APP_API_TEST_FIELD_URL}bynuttype/${
        this.state.data._id
      }/${
        loggedUser.role === USER_ROLE_ADMINISTRATOR
          ? processorIdAdmin
          : processorId
      }/${filterFieldType}?name=${filterFieldName}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        const fields = resultObject.data;
        that.setState(
          {
            allFields: fields,
            searchLoading: false,
          },
          () => {
            if (callBack) {
              callBack();
            }
          }
        );
        //Set default value for fields, this also help for handleChange of controls
        if (fields && fields.length > 0) {
          fields.map((x) => {
            that.setState({
              data: Object.assign({}, that.state.data, {
                [x._id]: x.defaultValue,
              }),
            });
          });
        }
      })
      .catch(function (error) {
        that.setState({ searchLoading: false });
      });
  };

  renderFields = (section, groupId) => {
    if (this.state.allFields && this.state.allFields.length > 0) {
      return (
        <div className="row">
          <ReactSortable
            list={
              this.state.data.selectedFields?.[groupId ? groupId : section] ||
              []
            }
            setList={(newState) => {
              this.setState({
                data: Object.assign({}, this.state.data, {
                  selectedFields: {
                    ...this.state.data.selectedFields,
                    [groupId ? groupId : section]: newState,
                  },
                }),
              });
            }}
            animation={200}
            delay={2}
            handle=".handle-move-test-field"
          >
            {this.state.data.selectedFields?.[groupId ? groupId : section]?.map(
              (x, index) => {
                if (
                  groupId === `` ||
                  groupId === undefined ||
                  groupId === null
                ) {
                  return (
                    <div
                      className="col-md-12 col-lg-6 form-group-spacing test-field-item-container"
                      key={index}
                    >
                      <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                        {renderFieldByType(
                          x,
                          this.state.data[x._id],
                          loggedUser,
                          GlobalConfig,
                          this.handleChange,
                          this.handleChangeSelectControl,
                          true
                        )}
                      </div>
                      {this.state.data.selectedFields?.[
                        groupId ? groupId : section
                      ]?.length <= 1 ? (
                        <span
                          draggable={false}
                          className="test-field-control mr-sm handle-move-test-field"
                          style={{ opacity: "0" }}
                        >
                          <i className="fa fa-bars fa-lg"></i>
                        </span>
                      ) : (
                        <span
                          className="panel-move-cursor test-field-control mr-sm handle-move-test-field"
                          title="Move Test Field"
                        >
                          <i className="fa fa-bars fa-lg"></i>
                        </span>
                      )}

                      <span
                        className="panel-action-cursor test-field-control"
                        title="Remove Test Field"
                        onClick={() => this.handleDeleteTestField(x._id)}
                      >
                        <i className="fa fa-times fa-lg"></i>
                      </span>
                    </div>
                  );
                }
                return (
                  <div
                    className="form-group-spacing test-field-item-container"
                    key={index}
                  >
                    <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                      {renderFieldByType(
                        x,
                        this.state.data[x._id],
                        loggedUser,
                        GlobalConfig,
                        this.handleChange,
                        this.handleChangeSelectControl,
                        true
                      )}
                    </div>
                    {this.state.data.selectedFields?.[
                      groupId ? groupId : section
                    ]?.length <= 1 ? (
                      <span
                        draggable={false}
                        className="test-field-control mr-sm handle-move-test-field"
                        style={{ opacity: "0", pointerEvents: "none" }}
                      >
                        <i className="fa fa-bars fa-lg"></i>
                      </span>
                    ) : (
                      <span
                        className="panel-move-cursor test-field-control mr-sm handle-move-test-field"
                        title="Move Test Field"
                      >
                        <i className="fa fa-bars fa-lg"></i>
                      </span>
                    )}
                    <span
                      className="panel-action-cursor test-field-control"
                      title="Remove Test Field"
                      onClick={() => this.handleDeleteTestField(x._id)}
                    >
                      <i className="fa fa-times fa-lg"></i>
                    </span>
                  </div>
                );
              }
            )}
          </ReactSortable>
        </div>
      );
    }
    return <></>;
  };

  // renderFieldByType = (field) => {
  //   switch (field.fieldType) {
  //     case FIELD_TYPE_TEXT_SMALL:
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <Textbox
  //               type="text"
  //               label={field.name}
  //               name={field._id}
  //               value={this.state.data[field._id]}
  //               handleChange={this.handleChange}
  //               maxLength={field.maxLength}
  //               required={field.isMandatory}
  //               placeholder={field.name}
  //             />
  //           </div>
  //         </div>
  //       );
  //     case FIELD_TYPE_TEXT_BOX:
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <Textarea
  //               rows={5}
  //               type="text"
  //               label={field.name}
  //               name={field._id}
  //               value={this.state.data[field._id]}
  //               handleChange={this.handleChange}
  //               maxLength={field.maxLength}
  //               required={field.isMandatory}
  //               placeholder={field.name}
  //             />
  //           </div>
  //         </div>
  //       );
  //     case FIELD_TYPE_DROP_DOWN:
  //       const url = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${field.testClassificationId}`;
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <SelectControl
  //               handleChangeSelectControl={this.handleChangeSelectControl}
  //               onRef={(ref) => (this[field._id] = ref)}
  //               placeholder={field.name}
  //               label={field.name}
  //               popupTitle={field.name}
  //               controlId={field._id}
  //               value={field.defaultValue}
  //               required={field.isMandatory}
  //               maxLength={200}
  //               processorId={loggedUser.processorId}
  //               urlapi={url}
  //               urlapilist={url}
  //               isHideAddButton={true}
  //               textField="name"
  //             />
  //           </div>
  //         </div>
  //       );
  //     case FIELD_TYPE_DECIMAL:
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <NumericInput
  //               min={field.min}
  //               max={field.max}
  //               precision={field.precision}
  //               step={field.step}
  //               label={field.name}
  //               name={field._id}
  //               value={this.state.data[field._id]}
  //               handleChange={this.handleChange}
  //               required={field.isMandatory}
  //               labelrequired={field.isMandatory ? "(*)" : undefined}
  //               unit={field.unit}
  //             />
  //           </div>
  //         </div>
  //       );
  //     case FIELD_TYPE_PERCENT:
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <NumericInput
  //               label={field.name}
  //               min={field.min}
  //               max={field.max}
  //               precision={field.precision}
  //               step={field.step}
  //               name={field._id}
  //               value={this.state.data[field._id]}
  //               handleChange={this.handleChange}
  //               required={field.isMandatory}
  //               labelrequired={field.isMandatory ? "(*)" : undefined}
  //               unit={field.unit ? field.unit : "%"}
  //             />
  //           </div>
  //         </div>
  //       );
  //     case FIELD_TYPE_INTEGER:
  //       return (
  //         <div className="form-group form-group-spacing">
  //           <div className="col-md-12">
  //             <NumericInput
  //               min={field.min}
  //               max={field.max}
  //               precision={0}
  //               step={field.step}
  //               label={field.name}
  //               name={field._id}
  //               value={this.state.data[field._id]}
  //               handleChange={this.handleChange}
  //               required={field.isMandatory}
  //               labelrequired={field.isMandatory ? "(*)" : undefined}
  //               unit={field.unit}
  //             />
  //           </div>
  //         </div>
  //       );
  //     default:
  //       return <></>;
  //   }
  // };

  handleFocus = (e) => {
    // if (e != null && e.target != null && e.target.type === "text") {
    //   e.target.select();
    //   if (
    //     (e.target.value && e.target.value === "0.00") ||
    //     (e.target.value && e.target.value === "0.0") ||
    //     (e.target.value && e.target.value === "0")
    //   ) {
    //     e.target.value = "";
    //   }
    // }
  };
}

const mapStateToProps = (state) => {
  return {
    testTypes: state.testTypes,
  };
};

export default connect(mapStateToProps, {
  addTestType,
  removeTestType,
  updateTestType,
})(App);
