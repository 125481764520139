import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import SelectControl from "../Base/SelectControl";
import { handleExportPdf, composePdfFile } from "./export";
import { handleExportDetailedPdf } from "./exportDetailed";
import { handleExportDetailedPdfNew } from "./exportDetailedNew";
import ExportExcel from "../Utilities/ExportExcelGrowerStatement";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import OptionDropdown from "../Base/OptionDropdown";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_PROCESSOR,
  USER_ROLE_STAFF,
} from "../Utilities/Constant";
// import OutsideTests from "../GrowerStatement/OutsideTests";
import SubTable from "../GrowerStatement/SubTable";
import { sortBy, uniqBy } from "lodash";
import { queryGrowerStatementsByCropYear } from "../../Services/GrowerStatement";

var DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const nutTypeId = query.get("type");
    const processorId = query.get("processorId");
    const currentDate = parseInt(DateFormat(new Date(), "yyyymmdd"));
    loggedUser = getLoggedUser();
    let urlApiGrower = "";
    let urlapi = "";
    if (
      loggedUser.role === "Processors" ||
      loggedUser.role === "Staffs" ||
      loggedUser.role === "Administrators"
    ) {
      urlapi =
        GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL +
        "bycropyear/" +
        props.match.params.id +
        "/all";

      // Get all growers with Admin role
      const processorIdCondition =
        loggedUser.role === USER_ROLE_ADMINISTRATOR
          ? processorId
          : loggedUser.processorId;

      urlApiGrower = `${GlobalConfig.REACT_APP_API_GROWER_URL}simpleGrowersExistPayment/${processorIdCondition}/${nutTypeId}/${props.match.params.id}`;
    }
    this.state.childOverrideComponentDidMount = true;
    this.state.nutTypeId = nutTypeId;
    this.state.currentDate = currentDate;
    this.state.urlapidelete = "";
    this.state.urladd = "";
    this.state.urlapi = urlapi;
    this.state.urlApiGrower = urlApiGrower;
    this.state.urllist = process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST;
    this.state.title = "Grower Statement";
    this.state.pluraltitle = "Grower Statements";

    this.state.cropYear = {};
    this.state.isEmptyWalnutTest = false;
    this.state.statusItems = ["Preview", "Unpaid", "Paid"];
    this.state.paymentNoItems = [];
    this.state.paymentDateItems = [];
    // this.state.walnutTestItemsByCropYear = [];
    this.state.countPreview = 0;
    this.state.countPaid = 0;
    this.state.countUnpaid = 0;

    this.state.countExcludedGrower = 0;
    this.state.excludedGrowers = [];
    this.state.growers = [];
    //Use for export excel
    this.state.allGrowers = [];
    this.state.allGrowersStatements = [];

    //server paging
    this.state.isExpand = false;
    this.state.expandableRows = true;
    this.state.pagination = true;
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(
      GlobalConfig.REACT_APP_PAGE_SIZE_GROWER_STATEMENT
    );
    this.state.paginationPerPage = parseInt(
      GlobalConfig.REACT_APP_PAGE_SIZE_GROWER_STATEMENT
    );
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;

    this.state.customFields = [];
    this.state.testType = {
      isDynamic: false,
      paymentByVariety: false,
      _id: "",
    };

    //Filter
    this.state.filterGrower = "";
    this.state.filterStatus = "";
    this.state.filterPaymentNo = "";
    this.state.filterPaymentDate = "";

    //Using for popup
    this.state.showError = false;
    this.state.pagination = true;

    this.state.loadingMessage =
      "Calculating payments. This may take a while. Please wait!";

    const urlApiContainer = {
      cropYearList: process.env.REACT_APP_URL_CROPYEAR_LIST,
      growerStatementsList: process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST,
      paymentDetailViewList: process.env.REACT_APP_URL_PAYMENT_DETAIL_VIEW_LIST,
    };
    const { cropYearList, growerStatementsList, paymentDetailViewList } =
      urlApiContainer;

    this.state.getUrlApiContainer = `${cropYearList}${growerStatementsList}${paymentDetailViewList}`;

    this.state.isUseDetailedGrowerStatement = false;
    this.state.pageName = "Grower Statement";
    this.state.urlTestList = process.env.REACT_APP_URL_WALNUT_TEST_LIST;
    this.state.urlApiVarietyFilter =
      GlobalConfig.REACT_APP_API_VARIETY_URL + "byParentId/" + nutTypeId;
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenPopupGrowersExcluded =
      this.handleOpenPopupGrowersExcluded.bind(this);
  }

  defaultTitleSetting = () => {
    const length = this.getTotalItems();

    switch (true) {
      case length <= 0:
        return "Not found!";
      case length === 1:
        return `${length} Grower found`;
      default:
        return `${length} Growers found`;
    }
  };

  handleUpdateStatus = async (
    cropYearId,
    growerId,
    paymentId,
    status,
    resultObject
  ) => {
    if (resultObject && resultObject.result === "OK") {
      //Update status of selected row in table
      let newItems = this.state.items;
      newItems.map((g) => {
        if (g.children && g.children.length > 0) {
          g.children.map((x) => {
            if (
              x.cropYearId === cropYearId &&
              x.paymentId === paymentId &&
              x.growerId === growerId
            ) {
              if (x.createdDate === undefined || x.createdDate === null) {
                x.createdDate = resultObject.currentDate;
              }
              x.status = status;
              x.modifiedDate = resultObject.currentDate;
              x.reportId = resultObject.data._id;
            }
          });
        }
      });

      let newOriginalItems = this.state.originalItems;
      newOriginalItems.map((g) => {
        if (g.children && g.children.length > 0) {
          g.children.map((x) => {
            if (
              x.cropYearId === cropYearId &&
              x.paymentId === paymentId &&
              x.growerId === growerId
            ) {
              if (x.createdDate === undefined || x.createdDate === null) {
                x.createdDate = resultObject.currentDate;
              }
              x.status = status;
              x.modifiedDate = resultObject.currentDate;
              x.reportId = resultObject.data._id;
            }
          });
        }
      });

      this.setState(
        {
          items: newItems,
          originalItems: newOriginalItems,
        },
        () => {
          this.updateStatus(this.state.items);
        }
      );
    }
  };

  childrenItems = () => {
    const items = [];
    this.state.items.map((g) => {
      if (g.children && g.children.length > 0) {
        g.children.map((x) => {
          items.push(x);
        });
      }
    });
    return items;
  };

  handleBulkExport = async () => {
    try {
      const items = this.childrenItems();
      if (items === null || items === undefined || items.length <= 0) {
        window.closeAllAlerts();
        window.showAlert("Info", "There is no statement to download.", "info");
        return;
      }

      window.showAlert("Info", "Generating...", "info");

      items.map(async (g) => {
        const dataRequest = {
          cropYearId: g.cropYearId,
          growerId: g.growerId,
          paymentId: g.paymentId,
          varietyId: g.varietyId ? g.varietyId : ``,
          createdUserId: loggedUser._id,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        };

        fetch(
          GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL + "exportpdf",
          requestOptions
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObject) {
            if (resultObject && resultObject.result === "OK") {
              composePdfFile(
                resultObject.data,
                false,
                false,
                resultObject.processorName,
                resultObject.processorImage,
                resultObject.isRectangleLogo,
                resultObject.staticMessage
              );
            }
            //If failed, show error
            else {
              window.showAlert("Error", resultObject.message, "error");
            }
          })
          .catch(function (error) {
            window.showAlert("Error", error, "error");
          });
      });
      window.closeAllAlerts();
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleStatementsPaid = async () => {
    try {
      const items = this.childrenItems();
      const statements = [];
      items.map((g) => {
        if (g.status === "Unpaid") {
          statements.push({
            cropYearId: g.cropYearId,
            growerId: g.growerId,
            paymentId: g.paymentId,
            varietyId: g.varietyId,
          });
        }
      });
      if (statements.length <= 0) {
        window.showAlert(
          "Error",
          "There is no unpaid statement to update.",
          "error"
        );
        return;
      }
      window.showAlert("Info", "Processing...", "info");
      const dataRequest = {
        statements: JSON.stringify(statements),
        createdUserId: loggedUser._id,
        modifiedUserId: loggedUser._id,
        status: "Paid",
      };
      const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL + "bulkupdatestatus",
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            const resultStatementsSuccess =
              resultObject.resultStatements.filter((x) => {
                return x.isSuccess;
              });
            if (resultStatementsSuccess && resultStatementsSuccess.length > 0) {
              const newItems = that.state.items;
              const newOriginalItems = that.state.originalItems;
              resultStatementsSuccess.forEach((r) => {
                //Update status of unpaid rows in table
                newItems.map((p) => {
                  if (p.children && p.children.length > 0) {
                    p.children.map((u) => {
                      if (
                        u.growerId === r.growerId &&
                        u.cropYearId === r.cropYearId &&
                        u.paymentId === r.paymentId &&
                        u.status === "Unpaid"
                      ) {
                        u.status = "Paid";
                        u.modifiedDate = resultObject.currentDate;
                      }
                    });
                  }
                });

                newOriginalItems.map((p) => {
                  if (p.children && p.children.length > 0) {
                    p.children.map((u) => {
                      if (
                        u.growerId === r.growerId &&
                        u.cropYearId === r.cropYearId &&
                        u.paymentId === r.paymentId &&
                        u.status === "Unpaid"
                      ) {
                        u.status = "Paid";
                        u.modifiedDate = resultObject.currentDate;
                      }
                    });
                  }
                });
              });

              that.setState(
                {
                  items: newItems,
                  originalItems: newOriginalItems,
                },
                () => {
                  that.updateStatus(that.state.items);
                }
              );
            }

            window.showAlert("", resultObject.message, "");
            that.setState({ show: false });
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleStatementsUnpaid = async () => {
    try {
      const items = this.childrenItems();
      const statements = [];
      items.map((g) => {
        if (g.status === "Preview") {
          statements.push({
            cropYearId: g.cropYearId,
            growerId: g.growerId,
            paymentId: g.paymentId,
            varietyId: g.varietyId,
          });
        }
      });
      if (statements.length <= 0) {
        window.showAlert(
          "Error",
          "There is no statement in preview to update.",
          "error"
        );
        return;
      }
      window.showAlert("Info", "Processing...", "info");
      const dataRequest = {
        statements: JSON.stringify(statements),
        createdUserId: loggedUser._id,
        modifiedUserId: loggedUser._id,
        status: "Unpaid",
      };
      const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL + "bulkupdatestatus",
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            const resultStatementsSuccess =
              resultObject.resultStatements.filter((x) => {
                return x.isSuccess;
              });
            if (resultStatementsSuccess && resultStatementsSuccess.length > 0) {
              const newItems = that.state.items;
              const newOriginalItems = that.state.originalItems;
              resultStatementsSuccess.forEach((r) => {
                //Update status of unpaid rows in table
                newItems.map((p) => {
                  if (p.children && p.children.length > 0) {
                    p.children.map((u) => {
                      if (
                        u.growerId === r.growerId &&
                        u.cropYearId === r.cropYearId &&
                        u.paymentId === r.paymentId &&
                        u.status === "Preview"
                      ) {
                        u.status = "Unpaid";
                        u.modifiedDate = resultObject.currentDate;
                        u.createdDate = resultObject.currentDate;
                      }
                    });
                  }
                });

                newOriginalItems.map((p) => {
                  if (p.children && p.children.length > 0) {
                    p.children.map((u) => {
                      if (
                        u.growerId === r.growerId &&
                        u.cropYearId === r.cropYearId &&
                        u.paymentId === r.paymentId &&
                        u.status === "Preview"
                      ) {
                        u.status = "Unpaid";
                        u.modifiedDate = resultObject.currentDate;
                        u.createdDate = resultObject.currentDate;
                      }
                    });
                  }
                });
              });

              that.setState(
                {
                  items: newItems,
                  originalItems: newOriginalItems,
                },
                () => {
                  that.updateStatus(that.state.items);
                }
              );
            }

            window.showAlert("", resultObject.message, "");
            that.setState({ show: false });
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "error");
    }
  };

  handleOpenPopupGrowersExcluded = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  async componentDidMountChild() {
    if (
      (loggedUser.titerInfo &&
        loggedUser.titerInfo.isAllowGrowerPayment === true) ||
      loggedUser.role === USER_ROLE_ADMINISTRATOR
    ) {
      await this.getItemsServerPaging(this.state.currentPage);
      await this.queryTestType();
      this.queryFilterPayments(this.props.match.params.id);
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
      });
    }
  }

  queryFilterPayments = (cropYearId) => {
    const that = this;
    fetch(
      `${GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL}paymentsbycropyear/${cropYearId}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState(
            {
              paymentNoItems: resultObject.paymentNoItems
                ? resultObject.paymentNoItems
                : [],
              paymentDateItems: resultObject.paymentDateItems
                ? resultObject.paymentDateItems.map((item) =>
                    DateFormat(item, "mm/dd/yyyy")
                  )
                : [],
            },
            () => {
              that.filterPaymentNo.bindData(that.state.paymentNoItems);
              that.filterPaymentDate.bindData(that.state.paymentDateItems);
            }
          );
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  queryExcludedGrowers = () => {
    const that = this;
    fetch(
      `${GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL}excludedgrowersbycropyear/${this.props.match.params.id}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          let countExcludedGrower = 0;
          let excludedGrowers = resultObject.data;
          if (excludedGrowers && excludedGrowers.length > 0) {
            if (that.state.testType.paymentByVariety === true) {
              countExcludedGrower = uniqBy(
                excludedGrowers.map((x) => x.growerId),
                (a) => a
              ).length;
              excludedGrowers = sortBy(
                excludedGrowers,
                [`growerName`, `varietyName`],
                [`asc`, `asc`]
              );
            } else {
              excludedGrowers = sortBy(
                excludedGrowers,
                [`growerName`],
                [`asc`]
              );
              countExcludedGrower = excludedGrowers.length;
            }
          }

          that.setState(
            {
              excludedGrowers: excludedGrowers,
              countExcludedGrower: countExcludedGrower,
            },
            () => {
              that.handleOpenPopupGrowersExcluded();
            }
          );
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  async queryProcessorInfo() {
    const that = this;
    //Query processor's info
    fetch(
      `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}getprocessorproperty/${loggedUser.processorId}/isUseDetailedGrowerStatement`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState({
            isUseDetailedGrowerStatement:
              resultObject.data[`isUseDetailedGrowerStatement`],
          });
        } else {
          that.setState({
            isUseDetailedGrowerStatement: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  queryTestType = async () => {
    const that = this;
    fetch(
      `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}testTypeByCropYear/${this.props.match.params.id}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject && resultObject.result === "OK") {
          that.setState({
            testType: resultObject.data,
          });
          that.loadColumnSettings();
          if (
            loggedUser.role === `Staffs` ||
            loggedUser.role === `Processors`
          ) {
            that.queryProcessorInfo();
          }
        } else {
          that.setState({
            useExtendBody: true,
            extendBodyMessage: resultObject.message,
            alertmsg: resultObject.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      })
      .catch(function (error) {
        that.setState({
          useExtendBody: true,
          extendBodyMessage: error,
          alertmsg: error,
          alerttype: "error",
          alertshow: true,
        });
      });
  };

  extendRender = () => {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.countExcludedGrower} Grower
              {this.state.countExcludedGrower > 1 ? "s" : ""} Excluded
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ margin: "-15px", marginBottom: "-30px" }}>
            <div
              className="alert alert-success"
              role="alert"
              style={{ marginBottom: "5px" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="alert-heading">Why excluded?</h4>
              <div>
                <ul>
                  <li>
                    Total payment percent that does not add up to 100%. Check
                    settings at{" "}
                    <a
                      href={
                        process.env.REACT_APP_URL_CROPYEAR_LIST +
                        process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
                        "/" +
                        this.props.match.params.id
                      }
                    >
                      here
                    </a>
                    .
                  </li>
                  <li>
                    There is no test in crop year. Browse tests at Grading &
                    Tests.
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                overflowY: "auto",
                position: "relative",
                maxHeight: "500px",
              }}
            >
              <DataTable
                highlightOnHover
                striped
                noHeader={true}
                columns={this.setColumnsExcludedGrowers()}
                data={this.state.excludedGrowers}
                className="table table-bordered table-striped mb-none"
                pagination={false}
              ></DataTable>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  extendBody = () => {
    return (
      <div
        className={
          this.state.isEmptyWalnutTest
            ? "alert alert-success"
            : "alert alert-danger"
        }
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {this.state.extendBodyMessage}
        {this.state.isEmptyWalnutTest ? (
          <span>
            {" "}
            To continue using this report, please complete{" "}
            <a href={process.env.REACT_APP_URL_CROPYEAR_LIST}>crop year</a>{" "}
            setting and tests.
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };

  doSomethingAfterGetItems = (responseData) => {
    this.handleDoSomethingAfterGetItems(responseData);
  };

  // doSomethingAfterGetItemsFailed = (responseData) => {
  //   this.handleDoSomethingAfterGetItems(responseData);
  // };

  handleDoSomethingAfterGetItems = (responseData) => {
    if (this.state.isServerPaging === true) {
      this.setState({
        totalRows: responseData ? responseData.totalCount : 0,
        loading: false,
      });
    }
    if (responseData) {
      const newData = [];
      const growers = responseData.growers ? responseData.growers : [];
      const totalCount = responseData.totalCount ? responseData.totalCount : 0;

      const data = responseData.data ? responseData.data : [];
      if (growers && data) {
        growers.map((g) => {
          const dataByGrower = data.filter((x) => {
            return x.growerId == g._id;
          });
          let error = ``;
          newData.push({
            growerId: g._id,
            grower: g.name,
            error: error,
            children: dataByGrower,
          });
        });
      }

      this.setState(
        {
          cropYear: responseData.cropYear,
          isError: false,
          isEmptyWalnutTest: false,
          // walnutTestItemsByCropYear: responseData.walnutTestItemsByCropYear,
          totalRows: totalCount,
          loading: false,
          growers: growers,
          items: newData,
          originalItems: newData,
          isExpand: false,
        },
        () => {
          this.updateStatus(newData);
          this.setState({ isExpand: true });
          this.filterChildItems();
        }
      );
    }
  };

  updateStatus(items) {
    let countPreview = 0;
    let countPaid = 0;
    let countUnpaid = 0;
    const children = [];

    if (items && items.length > 0) {
      items.map((x) => {
        if (x.children && x.children.length > 0) {
          x.children.map((c) => {
            children.push(c);
          });
        }
      });

      countPreview = children.filter((x) => {
        return x.status === "Preview";
      }).length;

      countPaid = children.filter((x) => {
        return x.status === "Paid";
      }).length;

      countUnpaid = children.filter((x) => {
        return x.status === "Unpaid";
      }).length;
    }

    this.setState({
      countPreview: countPreview,
      countPaid: countPaid,
      countUnpaid: countUnpaid,
    });
  }

  formatStatus = (row) => {
    if (row.status === "Preview") {
      return (
        <span
          className="label label-primary"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    if (row.status === "Paid") {
      return (
        <span
          className="label label-success"
          style={{
            fontSize: "80%",
            paddingTop: "5px",
            paddingBottom: "7px",
            width: "55px",
          }}
        >
          {row.status}
        </span>
      );
    }
    return (
      <span
        className="label label-warning"
        style={{
          fontSize: "80%",
          paddingTop: "5px",
          paddingBottom: "7px",
          width: "55px",
        }}
      >
        {row.status}
      </span>
    );
  };

  conditionalRowStyles = () => {
    return [
      {
        when: (row) =>
          row.children === null ||
          row.children === undefined ||
          row.children.length <= 0,
        style: {
          backgroundColor: "#ffdd99",
        },
      },
    ];
  };

  setColumnsExcludedGrowers = () => {
    return [
      {
        name: "Grower",
        selector: "growerName",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.growerId === undefined ||
            row.growerId === null ||
            row.growerId === "" ? (
            row.growerName
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.growerName}
            >
              {row.growerName}
            </a>
          );
        },
      },
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        left: true,
        omit: this.state.testType.paymentByVariety !== true,
      },
      {
        name: "Reason",
        selector: "error",
        sortable: true,
        left: true,
      },
    ];
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  handleShow = (data) => {};

  defaultTable = () => {
    return (
      <DataTable
        key="editTable"
        id="editTable"
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        expandableRows={this.state.expandableRows}
        expandableRowsComponent={
          <SubTable
            getUrlApiContainer={this.state.getUrlApiContainer}
            handleUpdateStatus={this.handleUpdateStatus}
            handleExportPdf={handleExportPdf.bind(this)}
            handleExportDetailedPdf={handleExportDetailedPdf.bind(this)}
            handleExportDetailedPdfNew={handleExportDetailedPdfNew.bind(this)}
            loggedUser={loggedUser}
            GlobalConfig={GlobalConfig}
            expandableRowExpanded={(row) => {
              return this.state.isExpand;
            }}
            testType={this.state.testType}
            isUseDetailedGrowerStatement={
              this.state.isUseDetailedGrowerStatement
            }
          />
        }
        conditionalRowStyles={this.conditionalRowStyles()}
        noTableHead={true}
        expandableRowExpanded={(row) => {
          return this.state.isExpand;
        }}
        pagination={true}
        paginationPerPage={this.state.paginationPerPage}
        paginationServer={true}
        paginationTotalRows={this.state.totalRows}
        paginationRowsPerPageOptions={this.state.rowPerPageOption}
        paginationDefaultPage={this.state.currentPage}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        progressPending={this.state.progressPending}
        progressComponent={this.defaultProgressComponent()}
      />
    );
  };

  defaultColumns = () => {
    return [
      {
        name: "Grower",
        selector: "grower",
        sortable: true,
        left: true,
        cell: (row) => {
          return row.error && row.error !== `` ? (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {`${row.grower} (${row.error})`}
            </a>
          ) : (
            <a
              href={process.env.REACT_APP_URL_GROWER_LIST + "/" + row.growerId}
              title={row.grower}
            >
              {row.grower}
            </a>
          );
        },
      },
    ];
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  defaultTitle = () => {
    if (this.state.cropYear && this.state.cropYear.name) {
      const title =
        this.state.cropYear.name +
        " (" +
        this.state.cropYear.startDate +
        " - " +
        this.state.cropYear.endDate +
        ")";
      return (
        "Grower Statements - Crop Year " +
        this.truncateTitle(title, this.setTitleLength())
      );
    }
    return "Grower Statements";
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState(
      {
        [controlId]: value ? value : "",
      },
      () => {
        if (
          controlId === `filterStatus` ||
          controlId === `filterPaymentNo` ||
          controlId === `filterPaymentDate`
        ) {
          this.filterChildItems();
        }
      }
    );
  };

  //Call this method once filter status, payment number, payment date
  filterChildItems = () => {
    if (this.state.originalItems && this.state.originalItems.length > 0) {
      const filterStatus = this.state.filterStatus;
      const filterPaymentNo = this.state.filterPaymentNo;
      const filterPaymentDate = this.state.filterPaymentDate;

      //Without filter > reset
      if (
        (filterStatus === null ||
          filterStatus === undefined ||
          filterStatus === "") &&
        (filterPaymentNo === null ||
          filterPaymentNo === undefined ||
          filterPaymentNo === "") &&
        (filterPaymentDate === null ||
          filterPaymentDate === undefined ||
          filterPaymentDate === "")
      ) {
        this.setState({ items: this.state.originalItems });
        return;
      }

      const newItems = [];
      this.state.originalItems.map((p) => {
        let newChildren = [];
        if (p.children && p.children.length > 0) {
          newChildren = p.children.filter((c) => {
            return (
              (filterStatus === null ||
                filterStatus === undefined ||
                filterStatus === "" ||
                c.status === filterStatus) &&
              (filterPaymentNo === null ||
                filterPaymentNo === undefined ||
                filterPaymentNo === "" ||
                c.paymentNo === filterPaymentNo) &&
              (filterPaymentDate === null ||
                filterPaymentDate === undefined ||
                filterPaymentDate === "" ||
                DateFormat(c.paymentDate, "mm/dd/yyyy") === filterPaymentDate)
            );
          });
        }

        newItems.push({
          growerId: p.growerId,
          grower: p.grower,
          error: ``,
          children: newChildren,
        });
      });

      this.setState({ items: newItems, isExpand: false }, () => {
        this.setState({ isExpand: true });
      });
    }
  };

  getItemsServerPaging = async (page, size = this.state.perPage) => {
    this.setState({
      progressPending: true,
    });
    try {
      let resultObject;
      if (GlobalConfig.REACT_APP_CALCULATE_STATEMENT_IN_SERVER === true) {
        const url = this.state.urlapi + `/${page}/${size}`;
        const response = await fetch(url);
        resultObject = await response.json();
      } else {
        resultObject = await queryGrowerStatementsByCropYear(
          this.props.match.params.id,
          this.state.filterGrower && this.state.filterGrower !== ``
            ? this.state.filterGrower
            : ``,
          page,
          size
        );
      }
      if (resultObject && resultObject.result === "OK") {
        this.setState(
          {
            items: resultObject.data,
            originalItems: resultObject.data,
            totalRows: resultObject.data ? resultObject.totalCount : 0,
            loading: false,
            progressPending: false,
            alertmsg: ``,
            alerttype: "",
            alertshow: false,
          },
          () => {
            this.doSomethingAfterGetItems(resultObject);
          }
        );
      } else {
        this.setState(
          {
            items: [],
            originalItems: [],
            totalRows: 0,
            loading: false,
            countPreview: 0,
            countUnpaid: 0,
            countPaid: 0,
            alertshow: false,
            progressPending: false,
            alertmsg: ``,
          },
          () => {
            this.setState(
              {
                alertmsg: resultObject.message,
                alerttype: "error",
                alertshow: true,
              },
              () => {
                this.doSomethingAfterGetItemsFailed(resultObject);
              }
            );
          }
        );
      }
    } catch (err) {
      this.setState({
        alertshow: false,
        progressPending: false,
        alertmsg: ``,
      });
      this.setState({
        alertmsg: err.message,
        alerttype: "error",
        alertshow: true,
      });
    }
  };

  //Call this method once filter by grower
  filterItems = () => {
    const urlapi =
      GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL +
      "bycropyear/" +
      this.props.match.params.id +
      "/" +
      (this.state.filterGrower && this.state.filterGrower !== ``
        ? this.state.filterGrower
        : `all`);
    this.setState({ urlapi: urlapi, currentPage: 1 }, () => {
      this.getItemsServerPaging(this.state.currentPage);
    });
  };

  handleReset = () => {
    this.growerControl.reset();
    this.filterStatus.reset();
    this.filterPaymentNo.reset();
    this.filterPaymentDate.reset();
    this.setState(
      {
        filterGrower: "",
        filterStatus: "",
        filterPaymentNo: "",
        filterPaymentDate: "",
      },
      () => this.filterItems()
    );
  };

  extendButtons = () => {
    return "";
  };

  defaultButtons = () => {
    return (
      <div className="panel-btn-group-item-position group-btn-grower-statement">
        {/* <OutsideTests
          showPopupFallOutSidePaymentDate={
            this.state.showPopupFallOutSidePaymentDate
          }
          paginationPerPage={this.state.paginationPerPage}
          rowPerPageOption={this.state.rowPerPageOption}
          urlTestList={this.state.urlTestList}
          testType={this.state.testType}
          loggedUser={loggedUser}
          // getItems={() => this.getItems()}
          // queryTestType={() => this.queryTestType()}
          urlApiGrower={this.state.urlApiGrower}
          urlApiVarietyFilter={this.state.urlApiVarietyFilter}
          items={this.state.items}
          walnutTestItemsByCropYear={this.state.walnutTestItemsByCropYear}
        /> */}

        <div>
          <button
            title="Click to view details"
            className="btn btn-primary mb-sm"
            onClick={this.queryExcludedGrowers}
          >
            <div className="option-dropdown-table-grower">
              <div className="title-dropdown-table-grower">
                View Excluded Grower
              </div>
            </div>
          </button>
        </div>
        {this.state.items && this.state.items.length > 0 ? (
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle ml-sm mb-sm"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a
                  href="# "
                  title="Download statements in PDF"
                  onClick={() => this.handleBulkExport()}
                >
                  <OptionDropdown
                    icon="file-pdf-o"
                    title="Download Statements"
                  />
                </a>
              </li>
              {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                ""
              ) : (
                <>
                  <li>
                    {this.state.countPreview > 0 ? (
                      <a
                        href="# "
                        title="Generate all statements"
                        onClick={() => this.handleStatementsUnpaid()}
                      >
                        <OptionDropdown icon="cog" title="Generate Statement" />
                      </a>
                    ) : (
                      ""
                    )}
                  </li>

                  <li>
                    {this.state.countUnpaid > 0 ? (
                      <a
                        href="# "
                        title="Mark statements as paid"
                        onClick={() => this.handleStatementsPaid()}
                      >
                        <OptionDropdown
                          icon="check"
                          title="Mark Statements Paid"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </li>
                </>
              )}

              <li>
                <ExportExcel
                  csvData={[""]}
                  fileName="GrowerStatement"
                  buttonLabel="Export"
                  className="dropdown-option-color"
                  getCsvData={this.getItemsForExport}
                />
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back to crop year"
        >
          Crop Years
        </a>
      </li>
    );
  };

  getGrowerStatements = async () => {
    let items = [];
    let growers = [];
    //Get page amounts
    let pageNumber = parseInt(this.state.totalRows / this.state.perPage);
    if (pageNumber * this.state.perPage < this.state.totalRows) {
      pageNumber += 1;
    }
    for (let i = 1; i <= pageNumber; i++) {
      let itemsPerPage = [];
      let growersPerpage = [];
      const api = `${GlobalConfig.REACT_APP_API_GROWER_STATEMENT_URL}bycropyear/${this.props.match.params.id}/all/${i}/${this.state.perPage}`;
      await fetch(api)
        .then((response) => response.json())
        .then((responseData) => {
          growersPerpage = responseData.growers;
          responseData.growers.map((g) => {
            const dataByGrower = responseData.data.filter((x) => {
              return x.growerId === g._id;
            });
            let error = ``;
            itemsPerPage.push({
              growerId: g._id,
              grower: g.name,
              error: error,
              children: dataByGrower,
            });
          });
        });
      growers = growers.concat(growersPerpage);
      items = items.concat(itemsPerPage);
    }
    return {
      growers: growers,
      items: items,
    };
  };

  getItemsForExport = async () => {
    if (
      ![USER_ROLE_ADMINISTRATOR, USER_ROLE_PROCESSOR, USER_ROLE_STAFF].includes(
        loggedUser.role
      )
    ) {
      return;
    }

    let items = [];
    let growers = [];
    const data = await this.getGrowerStatements();
    items = data.items;
    growers = data.growers;

    let parsedItems = [];
    if (items && items.length > 0) {
      items.map((p) => {
        if (p.children && p.children.length > 0) {
          p.children.map((item) => {
            if (item.owners && item.owners !== "") {
              const owners = JSON.parse(item.owners);
              owners.forEach((o) => {
                const ownerFullName = o.firstName + " " + o.lastName;
                const ownerPayment = (o.ownership / 100) * item.payment;
                this.composeItemExport(
                  parsedItems,
                  item,
                  ownerFullName,
                  o.ownership,
                  ownerPayment
                );
              });
            } else {
              this.composeItemExport(parsedItems, item, "", "", "");
            }
          });
        }
      });
    }
    //Base on filter criteria to export excel
    parsedItems = parsedItems.filter((item) =>
      this.validGrowerStatement(item, growers)
    );
    return parsedItems;
  };

  validGrowerStatement = (growerStatement, growers) => {
    let valid = true;
    const filterObject = {
      Grower: this.state.filterGrower,
      Date: this.state.filterPaymentDate,
      Status: this.state.filterStatus,
      No: this.state.filterPaymentNo,
    };
    for (let property in filterObject) {
      if (!filterObject[property]) {
        delete filterObject[property];
      }
    }
    for (let property in filterObject) {
      if (property === "Grower") {
        const growerNames = this.getGrowerNamesByGrowerIds(
          filterObject[property],
          growers
        );
        valid = growerNames.includes(growerStatement[property]);
      } else {
        if (growerStatement[property] !== filterObject[property]) {
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  getGrowerNamesByGrowerIds = (growerIdsString, growers) => {
    const growerIdsArray = growerIdsString.split(",");
    let growerNames = [];
    growers.forEach((grower) => {
      if (growerIdsArray.includes(grower._id)) {
        growerNames.push(grower.name);
      }
    });
    return growerNames;
  };

  composeItemExport = (
    parsedItems,
    item,
    ownerFullName,
    ownerShip,
    ownerPayment
  ) => {
    parsedItems.push({
      Grower: item.growerName,
      No: item.paymentNo,
      Date: DateFormat(item.paymentDate, "mm/dd/yyyy"),
      Percent:
        item.paymentPercent == null ||
        item.paymentPercent === undefined ||
        isNaN(item.paymentPercent)
          ? 0
          : parseFloat(item.paymentPercent) / 100.0,
      "Net Amount": parseFloat(item.netPayment),
      "Gross Amount": parseFloat(item.payment),
      Status: item.status,
      "Generated Date": item.createdDate
        ? DateFormat(item.createdDate, "mm/dd/yyyy hh:MM TT")
        : "",
      Owner: ownerFullName,
      Ownership:
        ownerShip === null ||
        ownerShip === undefined ||
        ownerShip.toString().trim() === ""
          ? 0
          : parseFloat(ownerShip) / 100.0,
      "Owner Amount": ownerPayment !== "" ? parseFloat(ownerPayment) : 0,
    });
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-6 ">
              <div className="row">
                <div className="col-lg-6 select-render-search mb-sm">
                  <FilterMultiSelectControl
                    handleChangeSelectControl={this.handleChangeSelectControl}
                    onRef={(ref) => (this.growerControl = ref)}
                    placeholder="Filter Grower"
                    label="Filter Grower"
                    controlId="filterGrower"
                    urlapilist={this.state.urlApiGrower}
                  />
                </div>

                <div className="col-lg-6 select-render-search mb-sm">
                  <SelectControl
                    handleChangeSelectControl={this.handleChangeSelectControl}
                    onRef={(ref) => (this.filterStatus = ref)}
                    placeholder=""
                    label="Filter Status"
                    name="filterStatus"
                    data={this.state.statusItems}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="row">
                <div className="col-lg-6 select-render-search mb-sm">
                  <SelectControl
                    handleChangeSelectControl={this.handleChangeSelectControl}
                    onRef={(ref) => (this.filterPaymentNo = ref)}
                    placeholder=""
                    label="Filter Payment Number"
                    name="filterPaymentNo"
                    data={this.state.paymentNoItems}
                  />
                </div>

                <div className="col-lg-6 select-render-search mb-sm">
                  <SelectControl
                    handleChangeSelectControl={this.handleChangeSelectControl}
                    onRef={(ref) => (this.filterPaymentDate = ref)}
                    placeholder=""
                    label="Filter Payment Date"
                    name="filterPaymentDate"
                    data={this.state.paymentDateItems}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-12 select-render-search mb-sm">
              {this.state.countPreview <= 0 ? (
                ""
              ) : (
                <span
                  className="label label-primary"
                  style={{
                    marginRight: "10px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {this.state.countPreview} Preview
                </span>
              )}{" "}
              {this.state.countUnpaid <= 0 ? (
                ""
              ) : (
                <span
                  className="label label-warning"
                  style={{
                    marginRight: "10px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {this.state.countUnpaid} Unpaid
                </span>
              )}{" "}
              {this.state.countPaid <= 0 ? (
                ""
              ) : (
                <span
                  className="label label-success"
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {this.state.countPaid} Paid
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
}
export default App;
