import { queryDataExt } from "../BaseService/index";
const GlobalConfig = new window.globalConfig();

export const getAttributes = async (whereClause, selectFields, sortFields) => {
  return await queryDataExt(
    `${GlobalConfig.REACT_APP_API_ATTRIBUTE}getWithConditions`,
    whereClause,
    selectFields,
    sortFields
  );
};
