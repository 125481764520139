import React from "react";
import DataTable from "react-data-table-component";
import CommonUtilities from "../Utilities/Common";
const { sortBy } = require("lodash");

class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
      items: this.composeData(props),
    };
  }

  composeData = (props) => {
    let viewData = [];
    if (props.data.data && props.data.data !== ``) {
      viewData = JSON.parse(props.data.data);
      // if (viewData && viewData.length > 0) {
      //   if (props.testType.varietyOptional === true) {
      //     viewData = sortBy(viewData, [`primaryPrice`], ["asc"]);
      //   } else {
      //     viewData = sortBy(viewData, [`varietyName`, `primaryPrice`], ["asc", `asc`]);
      //   }
      // }
    }

    return viewData;
  };

  setColumns = () => {
    return [
      {
        name: "Variety",
        selector: "varietyName",
        sortable: true,
        // omit: this.props.testType.varietyOptional,
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
        right: true,
        cell: (row) =>
          CommonUtilities.numberWithCommas(
            (row.amount == null || row.amount === undefined || isNaN(row.amount)
              ? 0
              : parseFloat(row.amount)
            ).toFixed(2)
          ) + ` lbs`,
      },
      {
        name: "Cash Price",
        selector: "valueCashPrice",
        sortable: true,
        right: true,
        cell: (row) =>
          "$" +
          CommonUtilities.numberWithCommas(
            (row.valueCashPrice == null ||
            row.valueCashPrice === undefined ||
            isNaN(row.valueCashPrice)
              ? 0
              : parseFloat(row.valueCashPrice)
            ).toFixed(2)
          ),
      },
    ];
  };

  render() {
    return (
      <DataTable
        key="subTableTemplate"
        id="subTableTemplate"
        highlightOnHover
        striped
        title=""
        noHeader={true}
        columns={this.setColumns()}
        data={this.state.items}
        className="table table-bordered table-striped mb-none"
        pagination={false}
        noDataComponent=""
        noTableHead={false}
      />
    );
  }
}
export default App;
