import {
  SET_HELP_CATEGORIES_LOADING,
  SET_TEST_TYPES_LOADING,
} from "../actions/types";

const initialState = {
  helpCategoriesLoading: false,
  testTypesLoading: false,
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP_CATEGORIES_LOADING: {
      return { ...state, helpCategoriesLoading: action.payload };
    }
    case SET_TEST_TYPES_LOADING: {
      return { ...state, testTypesLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};
