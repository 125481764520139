import React from "react";
import Switch from "react-switch";
class App extends React.Component {
  constructor(props) {
    super();
  }

  renderCheckboxes() {
    return (
      <div className="hide-require-component-container">
        <div className="switch-control-container">
          <div className="label-switch-control">Hide</div>
          <div className="btn-switch-control ml-xs">
            <Switch
              id={this.props.nameHide}
              onChange={this.props.onChange}
              checked={this.props.valueHide}
              className="react-switch"
            />
          </div>
        </div>
        <div className="switch-control-container ml-sm">
          <div className="label-switch-control">Required</div>
          <div className="btn-switch-control ml-xs">
            <Switch
              id={this.props.nameRequired}
              onChange={this.props.onChange}
              checked={this.props.valueRequired}
              className="react-switch"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.noContainer === true ? (
      this.renderCheckboxes()
    ) : (
      <div>{this.renderCheckboxes()}</div>
    );
  }
}
export default App;
