import React from "react";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      name: props.name,
      value: props.value,
      data: [
        "Create",
        "Update",
        "Delete",
        "Approve",
        "Waiting Approval",
        "Copy",
        "Signup",
        "Import",
        "Import (Error)",
        "Invite",
        "Remove Invite",
        "Login",
        "Print",
      ],
      errorMessage: "",
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      const parsedItems = [];
      if (this.state.data) {
        this.state.data.map((item) => {
          parsedItems.push({
            _id: item,
            name: item,
            checked:
              this.state.value === null ||
                this.state.value === undefined ||
                this.state.value !== item
                ? false
                : true,
          });
        });
      }
      this.setState({
        data: parsedItems,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeSelect(event) {
    const value = event.target.value;
    this.setState({ value: value });
    this.props.handleChangeSelectControl(this.state.name, value);
  }

  reset = () => {
    this.setState({
      value: "",
    });
  };

  render() {
    return (
      <select
        name={this.state.name}
        className="form-control select-config-size"
        onChange={this.handleChangeSelect}
        value={this.state.value}
        style={this.props.style}
      >
        <option value="">{this.props.label}</option>
        {this.state.data
          ? this.state.data.map((item, key) => {
            return (
              <option value={item._id} key={key}>
                {item.name}
              </option>
            );
          })
          : ""}
      </select>
    );
  }
}
export default App;
