import React, { useState } from "react";
import axios from "axios";
import { Error } from "../../Components/AuthForms";
import Alert from "../Utilities/Alert";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import moment from "moment";
var validator = require("email-validator");
const GlobalConfig = new window.globalConfig();

function App(props) {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState("Email is required!");

  //USING FOR ALERT
  const [alertshow, setAlertShow] = useState(false);
  const [alerttype, setAlertType] = useState("success");
  const [alertmsg, setAlertMsg] = useState("");

  function handleServer(props) {
    if (
      userName === undefined ||
      userName === "" ||
      userName === "undefined" ||
      userName.trim().length == 0
    ) {
      setErrorMessage("Email is required!");
      setIsError(true);
      return;
    }
    let email = userName.trim();
    if (validator.validate(email) == false) {
      setErrorMessage(
        "Please enter correct email. Example: john@paritycorp.com."
      );
      setIsError(true);
      return;
    }
    axios
      .post(GlobalConfig.REACT_APP_API_AUTH_URL + "/recovery", {
        userName,
      })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.result === "OK") {
            axios
              .get(
                GlobalConfig.REACT_APP_API_MAIL_TEMPLATE_URL +
                  "/name/EMAIL_TEMPLATE_RECOVERY_PASSWORD"
              )
              .then((result1) => {
                const token = result.data.data.token;
                const timeExpired = moment(
                  new Date(result.data.data.timeExpired)
                ).format("MM/DD/YYYY HH:mm:ss");
                const resultObject = result.data;
                const resultObjectConfig = result1.data;

                const name =
                  resultObject.data.name == null ||
                  resultObject.data.name == undefined ||
                  resultObject.data.name == ""
                    ? resultObject.data.email
                    : resultObject.data.name;

                console.log(resultObjectConfig.data);
                var bodyEmailTemplate = resultObjectConfig.data.message;
                bodyEmailTemplate = bodyEmailTemplate.replace(
                  "#email",
                  resultObject.data.email
                );
                bodyEmailTemplate = bodyEmailTemplate.replace("#name", name);
                bodyEmailTemplate = bodyEmailTemplate.replace(
                  "#timeExpired",
                  timeExpired
                );
                bodyEmailTemplate = bodyEmailTemplate.replace(
                  "#url",
                  `<a href="${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_URL_RESET_PASSWORD}?token=${token}&timeExpired=${timeExpired}">Link</a>`
                );
                //send mail recovery password
                axios
                  .post(GlobalConfig.REACT_APP_API_MAIL_URL, {
                    to: resultObject.data.email,
                    subject: "Reset password - " + name + " | Grading portal",
                    body: bodyEmailTemplate,
                  })
                  .then((result2) => {
                    console.log("Sent mail to " + resultObject.data.email);
                  });
              });
            setAlertMsg(
              "Recovery password is sent your e-mail. Please check inbox."
            );
            setAlertShow(true);
            //reset form
            setUserName("");

            setTimeout(() => {
              setAlertShow(false);
            }, 2000);

            setIsError(false);
          } else {
            setErrorMessage(result.data.message);
            setIsError(true);
          }
        } else {
          setErrorMessage("The email provided was incorrect!");
          setIsError(true);
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsError(true);
        console.log(err.message);
      });
  }

  return (
    <div
      style={{
        backgroundImage:
          "url(/assets/images/PF-Grading-Background-login@2x.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>
          {"Recover Password | " + process.env.REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <div>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left">
              <img
                src="/assets/images/logo.png"
                height="54"
                alt={process.env.REACT_APP_SITE_TITLE}
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Recover Password
                </h2>
              </div>
              <div className="panel-body">
                {alertshow === true ? (
                  <Alert
                    message={alertmsg}
                    type={alerttype}
                    show={alertshow}
                  ></Alert>
                ) : (
                  ""
                )}
                {isError && (
                  <Error className="form-group mb-lg">
                    {ReactHtmlParser(errorMessage)}
                  </Error>
                )}
                <div className="alert alert-info">
                  <p className="m-none text-semibold h6">
                    Enter your e-mail below and we will send you reset
                    instructions!
                  </p>
                </div>
                <div className="form-group mb-none">
                  <div className="input-group">
                    <input
                      required
                      name="username"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      autoFocus
                      type="email"
                      placeholder="E-mail"
                      className="form-control input-lg"
                      maxLength={100}
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary btn-lg"
                        type="button"
                        onClick={handleServer}
                      >
                        Reset!
                      </button>
                    </span>
                  </div>
                </div>
                <p className="text-center mt-lg">
                  Remembered? <a href="/login">Sign In!</a>
                </p>
              </div>
            </div>

            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; ParityFactory All rights reserved |{" "}
                <a
                  href="https://parityfactory.com/privacy-policy/"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}
export default App;
