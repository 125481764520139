import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Modal } from "react-bootstrap";
import moment from "moment";
import SelectConfig from "../Base/SelectConfig";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import FilterMultiSelectControlFromSystemSetting from "../FormControl/FilterMultiSelectControlFromSystemSetting";
import CommonUtilities from "../Utilities/Common";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import OptionDropdown from "../Base/OptionDropdown";
import { Dropdown } from "react-bootstrap";
import { isValid } from "date-fns";
import { isArray } from "lodash";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";
import String from "../Utilities/String";
import SaveButton from "../Controls/SaveButton";
import CopyAdjustmentsPopup from "./CopyAdjustmentsPopup";
import Popover from "../Base/Popover";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();
var DateFormat = require("dateformat");

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const currentYear = new Date().getFullYear();
    const start = "08/01/" + currentYear;
    const end = "07/31/" + (currentYear + 1);

    let filterStatus = "";
    let filterNutType = "";
    let filterName = "";

    this.state.urlapidelete = GlobalConfig.REACT_APP_API_CROPYEAR_URL;
    this.state.urlapi = `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}byprocessor/${loggedUser.processorId}/all/all/`;
    this.state.urllist = process.env.REACT_APP_URL_CROPYEAR_LIST;
    this.state.urlNutType = process.env.REACT_APP_URL_NUTTYPE_LIST;
    this.state.urladd =
      loggedUser.role == "Processors"
        ? process.env.REACT_APP_URL_CROPYEAR_LIST + "-add"
        : "";
    this.state.pluraltitle = "Crop Years";
    this.state.title = "Crop Year";
    this.state.labeladd = "Crop Year";
    this.state.pagination = false;
    this.state.initStartDate = start;
    this.state.initEndDate = end;
    this.state.filterStatus = filterStatus;
    this.state.filterNutType = filterNutType;
    this.state.filterName = filterName;
    this.state.urlApiProcessorFilter =
      GlobalConfig.REACT_APP_API_PROCESSOR_URL + `filterDataProcessor`;

    //Using for popup add/edit
    this.state.data = {
      _id: "",
      name: "",
      startDate: start,
      endDate: end,
      status: "Published",
      nutTypeId: "",
      isDefault: false,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
      pageName: "",
    };
    this.state.isEditMode = true;
    this.state.showRefresh2 = true;

    //detect if processor turn-on cash contract price
    this.state.isContractCashPrice = false;

    this.state.pageName = "Crop Year";
    this.state.childOverrideComponentDidMount = true;

    //Using for popup add/edit
    this.state.isChanged = false;
    this.state.errorMessage = "";
    this.state.color = "red";
    this.state.loading = false;

    this.state.urlApiNutTypeFilter =
      GlobalConfig.REACT_APP_API_NUTTYPE_URL +
      "filterDataNutTypeByProcessor" +
      "/" +
      loggedUser.processorId;

    this.state.selectedProcessorId = "";
    this.state.isContractCashPriceArr = [];

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.handleChangeObjectData = this.handleChangeObjectData.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClickTestCropYear = this.handleClickTestCropYear.bind(this);
    this.handleClickCropYear = this.handleClickCropYear.bind(this);
  }

  handleApplyProcessorCropYears = (controlId, value) => {
    if (this.state.data.processorId !== value) {
      this.setState(
        {
          urlapi: `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}byprocessor/${
            value ? value : "all"
          }/all/all/`,
          selectedProcessorId: value ? value : "all",
        },
        async () => {
          this.getItems();
          this.queryProcessorInfo();
        }
      );
    }
  };

  defaultButtons = () => {
    if (loggedUser.role === "Administrators") {
      return (
        <div className="col-xs-7 col-sm-6 col-md-6 mb-sm">
          <div className="row">
            <div className="form-group">
              <label className="select-processor-crop-year-label control-label mr-sm">
                You viewing data of processor
              </label>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 p-none">
                <FilterSelectControl
                  handleChangeSelectControl={this.handleApplyProcessorCropYears}
                  onRef={(ref) => (this.processorId = ref)}
                  placeholder="All Processors"
                  label="All Processors"
                  controlId="processorId"
                  style={{ width: "100%" }}
                  value={this.state.selectedProcessorId}
                  urlapilist={this.state.urlApiProcessorFilter}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="panel-btn-group-item-position">
          <a
            href=" #"
            onClick={this.handleClickCropYear}
            className="btn btn-success mb-sm"
          >
            <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
          </a>
          <a
            href=" #"
            onClick={this.handleClickTestCropYear}
            className="btn btn-warning mb-sm"
          >
            <i className="fa fa-plus fa-lg"></i> Test Crop Year
          </a>

          <div className="btn-group more-dropdown">
            <button
              type="button"
              className="btn btn-info dropdown-toggle btn-more-dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              More
            </button>
            <div className="dropdown-menu more-dropdown-menu">
              <a
                className="dropdown-item more-dropdown-item"
                href=" #"
                onClick={() => {
                  this.copyAdjustmentsPopup.showCopyAdjustmentsPopup();
                }}
              >
                <i
                  className="fa fa-files-o more-dropdown-item-icon"
                  aria-hidden="true"
                ></i>{" "}
                Copy Adjustments
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  handleChangeSelectControl = (controlId, value) => {
    if (controlId === "filterStatus" || controlId === "filterNutType") {
      this.setState({
        [controlId]: value,
      });
    } else {
      this.setState({
        data: Object.assign({}, this.state.data, {
          [controlId]: value,
        }),
      });
    }
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (name && name !== "") {
      this.setState({
        [name]: value,
      });
    }
  };

  handleClickTestCropYear = () => {
    this.handleShow({
      _id: "",
      name: "",
      startDate: this.state.initStartDate,
      endDate: this.state.initEndDate,
      status: "Test",
      nutTypeId: "",
      isDefault: false,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    });
  };

  handleClickCropYear = () => {
    this.handleShow({
      _id: "",
      name: "",
      startDate: this.state.initStartDate,
      endDate: this.state.initEndDate,
      status: "Published",
      nutTypeId: "",
      isDefault: false,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
      createdDate: new Date(),
      modifiedUserId: "",
      modifiedDate: null,
    });
  };

  filterItems = () => {
    let filterName = String.isEmpty(this.state.filterName)
      ? ""
      : encodeURIComponent(this.state.filterName.trim());

    let filterStatus =
      this.state.filterStatus && this.state.filterStatus !== ""
        ? this.state.filterStatus
        : "all";

    let filterNutType =
      this.state.filterNutType && this.state.filterNutType !== ""
        ? this.state.filterNutType
        : "all";

    const urlapi =
      GlobalConfig.REACT_APP_API_CROPYEAR_URL +
      "byprocessor/" +
      (loggedUser.role === USER_ROLE_ADMINISTRATOR
        ? !this.state.selectedProcessorId
          ? "all"
          : this.state.selectedProcessorId
        : loggedUser.processorId) +
      "/" +
      filterNutType +
      "/" +
      filterStatus +
      "/" +
      filterName;
    this.setState({ urlapi: urlapi }, () => this.getItems());
  };

  handleReset = () => {
    this.statusControl.reset();
    this.filterNutType.reset();
    this.setState(
      {
        filterName: "",
        filterStatus: "",
        filterNutType: "",
      },
      () => this.filterItems()
    );
  };

  async componentDidMountChild() {
    if (
      loggedUser.titerInfo &&
      loggedUser.titerInfo.isAllowGrowerPayment === true
    ) {
      this.getItems();
      this.queryProcessorInfo();
    } else if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
      this.setState(
        {
          urlapi: `${GlobalConfig.REACT_APP_API_CROPYEAR_URL}byprocessor/all/all/all/`,
        },
        async () => {
          this.getItems();
          this.queryProcessorInfo();
          this.processorId.setSelected(this.state.selectedProcessorId);
        }
      );
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
      });
    }

    this.loadColumnSettings();
  }

  async queryProcessorInfo() {
    const that = this;
    //Query processor's info
    if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
      fetch(this.state.urlApiProcessorFilter)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          that.setState({
            isContractCashPriceArr: resultObject.data,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      fetch(
        `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}getprocessorproperty/${loggedUser.processorId}/isContractCashPrice`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (resultObject && resultObject.result === "OK") {
            that.setState({
              isContractCashPrice: resultObject.data[`isContractCashPrice`],
            });
          } else {
            that.setState({
              isContractCashPrice: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  renderRefresh2 = () => {
    return this.state.showRefresh2 ? (
      <div
        className="panel-actions panelActionsCropYear"
        style={{ top: "0px" }}
      >
        <a href="#" title="Refresh" onClick={() => this.handleRefresh()}>
          <i className="fa fa-refresh fa-lg"></i>
        </a>
      </div>
    ) : (
      ""
    );
  };

  extendBody = () => {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {loggedUser.role == "Processors" || loggedUser.role == "Staffs"
          ? this.state.extendBodyMessage
          : this.state.extendBodyMessage}
      </div>
    );
  };

  conditionalRowStyles = () => {
    return [
      {
        when: (row) => row.status === "Test",
        style: {
          backgroundColor: "#ffdd99",
        },
      },
    ];
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    const customActionsStyle = {
      position: "sticky",
      right: 0,
      top: 0,
      borderLeft: "1px solid #e0e0e0",
      padding: 0,
      "& :hover": {
        backgroundColor: "#EEEEEE",
        transitionDuration: "0.15s",
        transitionProperty: "background-color",
      },
    };

    return [
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
        left: true,
        width: "200px",
        cell: (row) => <span>{row.processorName}</span>,
        omit: loggedUser.role !== USER_ROLE_ADMINISTRATOR,
      },
      {
        name: "Crop Year",
        selector: "startDate",
        sortable: true,
        left: true,
        width: "200px",
        cell: (row) => {
          return loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
            row.name
          ) : (
            <a
              href="#"
              onClick={() => this.handleShow(row)}
              title={row.name}
              className="titleCropYearDataTable"
            >
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Test Type",
        selector: "nutTypeName",
        sortable: true,
        left: true,
        cell: (row) => {
          return (
            <a
              href={this.state.urlNutType + "/" + row.nutTypeId}
              title={row.nutTypeName}
            >
              {row.nutTypeName}
            </a>
          );
        },
      },
      {
        name: "Dates",
        selector: "dates",
        sortable: true,
        center: true,
        width: "180px",
        cell: (row) => {
          return (
            DateFormat(row.startDate, "mm/dd/yyyy") +
            " - " +
            DateFormat(row.endDate, "mm/dd/yyyy")
          );
        },
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        width: "90px",
        cell: (row) => {
          let className =
            row.status === "Published"
              ? "label label-success"
              : row.status === "Test"
              ? "label label-warning"
              : "label label-default";
          return (
            <span
              className={className}
              style={{
                paddingTop: "5px",
                paddingBottom: "7px",
                width: "55px",
              }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Payment Settings",
        selector: "paymentSettings",
        center: true,
        width: "130px",
        cell: (row) => (
          <a
            href={
              this.state.urllist +
              process.env.REACT_APP_URL_PAYMENTSETTING_LIST +
              "/" +
              row._id +
              ""
            }
            title="Edit payment setting"
          >
            {loggedUser.role === USER_ROLE_ADMINISTRATOR ? "View" : "Edit"}
            {/* Payments{" "}
            {row.countPaymentSetting > 0
              ? "(" + row.countPaymentSetting + ")"
              : ""} */}
          </a>
        ),
      },
      {
        name: "Base Prices",
        selector: "basePrices",
        center: true,
        width: "110px",
        cell: (row) => (
          <a
            href={
              this.state.urllist +
              process.env.REACT_APP_URL_BASEPRICE_LIST +
              "/" +
              row._id +
              ""
            }
            title="Edit base price"
          >
            {loggedUser.role === USER_ROLE_ADMINISTRATOR ? "View" : "Edit"}
            {/* Base Prices{" "}
            {row.countBasePrice > 0 ? "(" + row.countBasePrice + ")" : ""} */}
          </a>
        ),
      },
      {
        name: "Cash Contract Prices",
        selector: "cashContractPrices",
        center: true,
        width: "150px",
        cell: (row) => {
          if (row.isDynamic === true) {
            return "Not supported";
          }
          if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
            const filter = this.state.isContractCashPriceArr.filter((item) => {
              return item._id === row.processorId;
            });

            return filter.map((item, index) =>
              !item.isContractCashPrice ? (
                ""
              ) : (
                <a
                  key={index}
                  href={
                    this.state.urllist +
                    process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
                    "/" +
                    row._id +
                    ""
                  }
                  title="Edit cash contract price"
                >
                  {loggedUser.role === USER_ROLE_ADMINISTRATOR
                    ? "View"
                    : "Edit"}
                </a>
              )
            );
          } else {
            return (
              <a
                href={
                  this.state.urllist +
                  process.env.REACT_APP_URL_CASH_CONTRACT_PRICE_LIST +
                  "/" +
                  row._id +
                  ""
                }
                title="Edit cash contract price"
              >
                Edit
              </a>
            );
          }
        },
        omit:
          loggedUser.role !== USER_ROLE_ADMINISTRATOR &&
          this.state.isContractCashPrice === false,
      },
      {
        name: "Adjustments",
        selector: "adjustments",
        center: true,
        width: "110px",
        cell: (row) => (
          <a
            href={
              this.state.urllist +
              process.env.REACT_APP_URL_BONUSDEDUCT_LIST +
              "/" +
              row._id +
              ""
            }
            title="Edit bonus & deduct"
          >
            {loggedUser.role === USER_ROLE_ADMINISTRATOR ? "View" : "Edit"}
            {/* Bonuses & Deducts{" "}
            {row.countBonusDeduct > 0 ? "(" + row.countBonusDeduct + ")" : ""} */}
          </a>
        ),
      },
      {
        name: "Taxes & Fees",
        selector: "taxesAndFees",
        center: true,
        width: "110px",
        cell: (row) => (
          <a
            href={
              this.state.urllist +
              process.env.REACT_APP_URL_TAXFEE_LIST +
              "/" +
              row._id +
              ""
            }
            title="Edit tax & fee"
          >
            {loggedUser.role === USER_ROLE_ADMINISTRATOR ? "View" : "Edit"}
            {/* Taxes & Fees{" "}
            {row.countTaxFee > 0 ? "(" + row.countTaxFee + ")" : ""} */}
          </a>
        ),
      },
      {
        name: "",
        center: true,
        width: "110px",
        cell: (row) => (
          <>
            {row.isDefault === true ? (
              "Active"
            ) : row.status === "Published" ? (
              loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                ""
              ) : (
                <a
                  title="Set crop year as active. Active crop year to be selected as default on dashboard and reports."
                  href="#"
                  onClick={() => this.handleSetDefault(row)}
                >
                  Set Active
                </a>
              )
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        name: "",
        center: true,
        allowOverflow: true,
        conditionalCellStyles: [
          {
            when: (row) => row,
            style: { ...customActionsStyle, backgroundColor: "white" },
          },
          {
            when: (row) => row.status === "Test",
            style: { ...customActionsStyle, backgroundColor: "#ffdd99" },
          },
        ],

        cell: (row) => {
          return (
            <Popover
              trigger={"click"}
              rootClose
              className="popover-custom"
              placement="bottom"
              customContentClassName="p-none"
              content={
                <div>
                  {loggedUser.role === USER_ROLE_ADMINISTRATOR ? (
                    ""
                  ) : (
                    <>
                      <Dropdown.Item
                        title="Edit crop year"
                        onClick={() => this.handleShow(row)}
                      >
                        <OptionDropdown icon="pencil" title="Edit" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        title="Clone crop year"
                        onClick={() => this.handleCopy(row)}
                      >
                        <OptionDropdown icon="copy" title="Clone" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        title="Remove crop year"
                        data-id={row._id}
                        onClick={this.handleDelete.bind(this)}
                      >
                        <OptionDropdown icon="trash-o" title="Remove" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        title="Open copy adjustments popup"
                        data-id={row._id}
                        onClick={() => {
                          this.copyAdjustmentsPopup.showCopyAdjustmentsPopup(
                            row._id
                          );
                        }}
                      >
                        <OptionDropdown icon="copy" title="Copy Adjustments" />
                      </Dropdown.Item>
                    </>
                  )}

                  <Dropdown.Item
                    target="_blank"
                    title="Revision history"
                    href={
                      process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                      "/object/" +
                      row._id
                    }
                  >
                    <OptionDropdown icon="history" title="Revision" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    target="_blank"
                    title="Report"
                    href={
                      this.state.urllist +
                      process.env.REACT_APP_URL_GROWER_PAYMENT_REPORT +
                      "/" +
                      row._id +
                      (loggedUser.role === USER_ROLE_ADMINISTRATOR
                        ? "/" + row.processorId
                        : "")
                    }
                  >
                    <OptionDropdown icon="signal" title="Report" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    title="Grower Statement Messages"
                    href={
                      this.state.urllist +
                      process.env.REACT_APP_URL_STATICMESSAGE_LIST +
                      "/" +
                      row._id +
                      "?type=" +
                      row.nutTypeId
                    }
                  >
                    <OptionDropdown
                      icon="fa fa-file-text"
                      title="Grower Statement Messages"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    target="_blank"
                    title="Grower Statements"
                    href={
                      this.state.urllist +
                      process.env.REACT_APP_URL_GROWER_STATEMENTS_LIST +
                      "/" +
                      row._id +
                      "?type=" +
                      row.nutTypeId +
                      (loggedUser.role === USER_ROLE_ADMINISTRATOR
                        ? "&processorId=" + row.processorId
                        : "")
                    }
                  >
                    <OptionDropdown icon="print" title="Grower Statements" />
                  </Dropdown.Item>
                </div>
              }
              isUseDefaultOverlay
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Actions</span>
                <i
                  className="fa fa-angle-down panel-heading-info-icon ml-xs"
                  aria-hidden="true"
                  style={{
                    color: "#000000de",
                  }}
                />
              </div>
            </Popover>
          );
        },
      },
    ];
  };

  renderSearch = () => {
    return (
      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <input
              type="text"
              className="form-control inputRenderSearch inputFilter"
              placeholder="Filter Crop Year"
              name="filterName"
              value={this.state.filterName}
              onChange={this.handleChangeTextField}
              maxLength={100}
            />
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <FilterMultiSelectControlFromSystemSetting
              handleChangeSelectControl={this.handleChangeSelectControl}
              onRef={(ref) => (this.statusControl = ref)}
              placeholder="Filter Status"
              label="Select All"
              controlId="filterStatus"
              urlapilist={this.state.filterControlUrls?.urlApiStatusFilter}
            />
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <FilterMultiSelectControl
              handleChangeSelectControl={this.handleChangeSelectControl}
              onRef={(ref) => (this.filterNutType = ref)}
              placeholder="Filter Test Type"
              label="Select All"
              controlId="filterNutType"
              urlapilist={this.state.filterControlUrls?.urlApiNutType}
            />
          </div>
        </div>
      </div>
    );
  };

  extendRender = () => {
    return (
      <>
        <Modal
          // dialogAs={DraggableModalDialog}
          show={this.state.show}
          onHide={this.handleClose}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Form onSubmit={this.handleSave} id="fromPopup">
            <Modal.Header>
              <Modal.Title>
                {(this.state.data._id == ""
                  ? "Add Crop Year"
                  : "Edit Crop Year") +
                  (this.state.data.name !== ""
                    ? " - " + this.state.data.name
                    : "")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.data ? (
                <>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Crop Year <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <TextareaAutosize
                        type="text"
                        name="name"
                        maxRows={3}
                        minRows={1}
                        data-autoresize
                        autoFocus={true}
                        className="form-control textarea-crop-year"
                        placeholder="Enter name of crop year"
                        required
                        value={this.state.data.name}
                        onChange={this.handleChangeObjectData}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Test Type <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <FilterSelectControl
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                        onRef={(ref) => (this.nutTypeIdControl = ref)}
                        label="Choose one"
                        required
                        value={this.state.data.nutTypeId}
                        controlId="nutTypeId"
                        urlapilist={this.state.urlApiNutTypeFilter}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3 control-label">
                      Dates <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <div
                        className="input-daterange input-group"
                        data-plugin-datepicker
                      >
                        <span className="input-group-addon">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          className="form-control inputRenderSearch"
                          placeholderText="Start date"
                          selected={new Date(this.state.data.startDate)}
                          onChange={this.handleStartDate}
                          selectsStart
                          startDate={new Date(this.state.data.startDate)}
                          endDate={new Date(this.state.data.endDate)}
                          onBlur={this.handleOnBlur}
                        />
                        <span className="input-group-addon">to</span>

                        <DatePicker
                          className="form-control inputRenderSearch"
                          placeholderText="End date"
                          selected={new Date(this.state.data.endDate)}
                          onChange={this.handleEndDate}
                          selectsEnd
                          startDate={new Date(this.state.data.startDate)}
                          endDate={new Date(this.state.data.endDate)}
                          minDate={new Date(this.state.data.startDate)}
                          onBlur={this.handleOnBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="col-md-3 control-label"
                      htmlFor="attribute"
                    >
                      Status <span className="required">(*)</span>
                    </label>
                    <div className="col-md-9">
                      <SelectConfig
                        configType="Crop Year Status"
                        required={true}
                        onRef={(ref) => (this.status = ref)}
                        name="status"
                        controlId="status"
                        selectedIds={this.state.data.status}
                        handleChangeSelectControl={
                          this.handleChangeSelectControl
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column md="12">
                    Selected crop year no longer exists. Please refresh and try
                    again.
                  </Form.Label>
                </Form.Group>
              )}
              <div className="col-md-12">
                {this.state.errorMessage == null ||
                this.state.errorMessage == "" ||
                this.state.errorMessage == undefined ? (
                  ""
                ) : (
                  <>
                    <Form.Label
                      column
                      md="12"
                      style={{ color: this.state.color, paddingLeft: "0px" }}
                    >
                      {this.state.errorMessage}
                    </Form.Label>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-center">
              {this.state.data && <SaveButton loading={this.state.loading} />}
              <Button variant="warning" onClick={this.handleClose}>
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <CopyAdjustmentsPopup
          GlobalConfig={GlobalConfig}
          loggedUser={loggedUser}
          filterName={this.state.filterName}
          filterStatus={this.state.filterStatus}
          filterNutType={this.state.filterNutType}
          onRef={(ref) => (this.copyAdjustmentsPopup = ref)}
        ></CopyAdjustmentsPopup>
      </>
    );
  };

  handleStartDate = (date) => {
    const getDate = moment(date).format("L");
    if (date) {
      this.setState({
        data: Object.assign({}, this.state.data, {
          startDate: getDate,
        }),
      });
    }
  };

  handleEndDate = (date) => {
    const getDate = moment(date).format("l");
    if (date) {
      this.setState({
        data: Object.assign({}, this.state.data, {
          endDate: getDate,
        }),
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Can't be blank", "error");
    }
  }

  handleSetDefault = async (data) => {
    let urlApi =
      GlobalConfig.REACT_APP_API_CROPYEAR_URL + "setdefault/" + data._id;

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: data._id,
        status: data.status,
        modifiedUserId: loggedUser._id,
      }),
    };
    const that = this;
    fetch(urlApi, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          //Loop all items on grid and update isDefault accordingly
          data.isDefault = true;
          that.setState({
            items: that.state.items.map((el) =>
              el._id === data._id ? Object.assign({}, el, data) : el
            ),
          });
          that.setState({
            originalItems: that.state.originalItems.map((el) =>
              el._id === data._id ? Object.assign({}, el, data) : el
            ),
          });

          if (that.state.items && that.state.items.length) {
            const itemsCopy = [...that.state.items];
            itemsCopy.map((el) => {
              if (
                el.status === "Published" &&
                el.isDefault &&
                el._id !== data._id &&
                el.nutTypeId === data.nutTypeId
              ) {
                el.isDefault = false;
              }
            });
            that.setState({
              items: itemsCopy,
            });
          }
          if (that.state.originalItems && that.state.originalItems.length) {
            const originalItemsCopy = [...that.state.originalItems];
            originalItemsCopy.map((el) => {
              if (
                el.status === "Published" &&
                el.isDefault &&
                el._id !== data._id &&
                el.nutTypeId === data.nutTypeId
              ) {
                el.isDefault = false;
              }
            });
            that.setState({
              originalItems: originalItemsCopy,
            });
          }

          window.showAlert("", "Updated successfully!", "");
        } else {
          window.showAlert("Error", resultObject.message, "error");
        }
      })
      .catch(function (error) {
        window.showAlert("Error", error, "error");
      });
  };

  handleShow = (data) => {
    let convertedJSON = JSON.parse(JSON.stringify(data));
    let isEditMode = true;
    if (data._id === "") {
      convertedJSON.createdUserId = loggedUser._id;
      isEditMode = false;
    } else {
      convertedJSON.modifiedUserId = loggedUser._id;
    }
    if (data.startDate) {
      convertedJSON.startDate = moment(data.startDate).format("MM/DD/YYYY");
    }
    if (data.endDate) {
      convertedJSON.endDate = moment(data.endDate).format("MM/DD/YYYY");
    }
    this.setState({ data: convertedJSON }, function callBack() {
      this.setState(
        {
          show: true,
          errorMessage: "",
          isChanged: false,
          isEditMode: isEditMode,
        },
        function callBack() {
          //Set selected dropdown
          this.status.setSelected(data.status);
        }
      );
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSave = async (event) => {
    event.preventDefault();
    //Reset message
    this.setState({
      errorMessage: "",
    });

    const name = this.state.data.name;
    const nutTypeId = this.state.data.nutTypeId;
    const startDate = moment(this.state.data.startDate).format("L");
    const endDate = moment(this.state.data.endDate).format("L");

    let errorMessage = "";

    if (name == null || name == undefined || name.trim() === "") {
      errorMessage = "Please enter name to continue.";
    }

    if (
      nutTypeId == null ||
      nutTypeId == undefined ||
      nutTypeId.trim() === ""
    ) {
      errorMessage = "Please select Test Type to continue.";
    }

    if (errorMessage === "") {
      if (
        startDate == null ||
        startDate == undefined ||
        startDate.trim() == ""
      ) {
        errorMessage = "Enter start date to continue.";
      } else {
        if (!CommonUtilities.isValidDate(startDate)) {
          errorMessage = "Format date must be MM/DD/YYYY";
        }
      }
    }
    if (errorMessage === "") {
      if (endDate == null || endDate == undefined || endDate.trim() == "") {
        errorMessage = "Enter start date to continue.";
      } else {
        if (!CommonUtilities.isValidDate(endDate)) {
          errorMessage = "Format date must be MM/DD/YYYY";
        }
      }
    }

    if (errorMessage === "") {
      const start = moment(new Date(startDate)).format("MM/DD/YYYY");
      const end = moment(new Date(endDate)).format("MM/DD/YYYY");
      if (moment(end).isSameOrBefore(moment(start))) {
        errorMessage = "Start date must be less than to date.";
      }
    }

    if (errorMessage != "") {
      this.setState({
        color: "red",
        errorMessage: errorMessage,
      });
    } else {
      //Using for edit
      let urlApi =
        GlobalConfig.REACT_APP_API_CROPYEAR_URL + this.state.data._id;
      let method = "PATCH";
      if (
        this.state.data._id == null ||
        this.state.data._id === undefined ||
        this.state.data._id === ""
      ) {
        //Using for create new
        method = "POST";
        urlApi = GlobalConfig.REACT_APP_API_CROPYEAR_URL;
      }

      const requestOptions = {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...this.state.data,
          startDate: moment(
            new Date(this.state.data.startDate).setHours(0, 0, 0)
          ).format(),
          endDate: moment(
            new Date(this.state.data.endDate).setHours(23, 59, 59)
          ).format(),
        }),
      };
      const that = this;
      that.setState({ loading: true }, () => {
        fetch(urlApi, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultObject) {
            //If created successful
            if (resultObject.result === "OK") {
              window.showAlert("", "Save successful!", "");
              that.setState(
                {
                  isChanged: true,
                },
                function callBack() {
                  that.updateStateAfterClosePopup(resultObject.data);
                }
              );
              if (!that.state.isEditMode) {
                that.handResetForm();
              }
            } else {
              window.showAlert("Error", resultObject.message, "error");
            }
            that.setState({ loading: false });
          })
          .catch(function (error) {
            window.showAlert("Error", error, "error");
            that.setState({ loading: false });
          });
      });
    }
  };

  handResetForm = () => {
    this.setState(
      {
        data: {
          _id: "",
          name: "",
          startDate: this.state.initStartDate,
          endDate: this.state.initEndDate,
          status: "",
          nutTypeId: "",
          isDefault: false,
          createdUserId: loggedUser._id,
          processorId: loggedUser.processorId,
          createdDate: new Date(),
          modifiedUserId: "",
          modifiedDate: null,
        },
      },
      function callBack() {
        //Set selected dropdown
        this.nutTypeIdControl.setSelected("");
        this.status.setSelected("");
      }
    );
  };

  handleCopy = async (data) => {
    const requestData = {
      _id: data._id,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };

    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_CROPYEAR_URL + "copy/" + data._id,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If copied successful
          if (resultObject && resultObject.result === "OK") {
            //reset form
            that.getItems();
            window.showAlert("", "Copy successful!", "");
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "Error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  updateFilterControlUrls = () => {
    this.setState({
      filterControlUrls: {
        urlApiStatusFilter: `${GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL}likename/Crop Year Status`,
        urlApiNutType: GlobalConfig.REACT_APP_API_NUTTYPE_URL,
      },
    });
  };
}
export default App;
