import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.urlapidelete = GlobalConfig.REACT_APP_API_STAFF_URL;
    this.state.urlapi =
      loggedUser.role == "Administrators"
        ? GlobalConfig.REACT_APP_API_STAFF_URL
        : GlobalConfig.REACT_APP_API_STAFF_URL +
          "byprocessor/" +
          loggedUser.referenceUserId;
    this.state.urllist = process.env.REACT_APP_URL_STAFF_LIST;
    this.state.urladd =
      loggedUser.role == "Processors"
        ? process.env.REACT_APP_URL_STAFF_LIST + "-add"
        : "";
    this.state.title = "Staff";

    this.state.pageName = "Staff";

    // Custom heading component
    this.state.useFilter = false;
  }

  columnsFull = () => {
    return [
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
      },
      {
        name: "Staff Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Email",
        selector: "contactEmail",
        sortable: true,
        cell: (row) => (
          <a href={"mailto:" + row.contactEmail} title={row.contactEmail}>
            {row.contactEmail}
          </a>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-default"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      // {
      //   name: "Modified Date",
      //   selector: "modifiedDate",
      //   sortable: false,
      //   center: true,
      //   cell: (row) => {
      //     return row.modifiedDate ? (
      //       <DateFormat date={row.modifiedDate}></DateFormat>
      //     ) : (
      //       ""
      //     );
      //   },
      // },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <div>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </div>
        ),
      },
    ];
  };

  columnsLimit = () => {
    return [
      {
        name: "Staff Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Email",
        selector: "contactEmail",
        sortable: true,
        cell: (row) => (
          <a href={"mailto:" + row.contactEmail} title={row.contactEmail}>
            {row.contactEmail}
          </a>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-default"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <>
            <a
              href={this.state.urllist + "/" + row._id}
              className="on-default edit-row"
            >
              <i className="fa fa-pencil-square-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              title="Click here to remove"
              href="#"
              className="on-default remove-row"
              data-id={row._id}
              onClick={this.handleDelete.bind(this)}
            >
              <i className="fa fa-trash-o fa-lg"></i>
            </a>
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return loggedUser.role == "Administrators"
      ? this.columnsFull()
      : this.columnsLimit();
  };
}
export default App;
