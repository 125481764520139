import _ from "lodash";
import CommonUtilities from "../Utilities/Common";
var DateFormat = require("dateformat");

export const configDataExportDynamic = (dataContainer) => {
  const {
    data,
    loggedUser,
    releaseType,
    dynamic,
    netWeightUnit,
    testTypeSetting,
    customFields,
    RELEASE_TYPE_AFTER_APPROVED,
    SECTION_META_DATA,
    SECTION_QUALITY_DATA,
  } = dataContainer;

  let columns = [
    {
      name: "Test Type",
      value: data.testType,
      omit: loggedUser.role !== "Administrators",
    },
    {
      name: "Ceritificate No",
      value: data.certificateNo,
    },
    {
      name: "Status",
      value: data.status,
      omit:
        loggedUser.role === "Growers" ||
        releaseType !== RELEASE_TYPE_AFTER_APPROVED,
    },
    {
      name: "Test Date",
      value: DateFormat(data.testDate, "mm/dd/yyyy"),
    },
    {
      name: "Field Name",
      value: data.fieldName,
    },
    {
      name: "Lot Number",
      value: data.lotNumber,
    },
    {
      name: "Processor",
      value: data.processorName,
      omit: loggedUser.role !== "Administrators",
    },
    {
      name: "Grower Name",
      value: data.growerNameExt,
      omit: loggedUser.role === "Growers",
    },
    {
      name: "Variety Submitted",
      value: data.varitySubmitted,
    },
    {
      name: "DFA Inspector",
      selector: data.dfaInspectorExt,
    },
    {
      name:
        dynamic === `true`
          ? netWeightUnit !== ``
            ? `Net Weight (${netWeightUnit.toUpperCase()})`
            : `Net Weight`
          : "Net Weight (LBS)",
      value: data.netWeight,
    },
    {
      name: "Edible Yield",
      value: data.s3EdibleYield,
      omit: dynamic === `true`,
    },
    {
      name: "Override Price",
      value: data.overridePrice,
      omit: dynamic !== `true` || testTypeSetting.overridePriceHide === true,
    },
    {
      name: "Photo",
      value: handlePhoto(data.photo),
    },
  ];

  if (customFields && customFields.length > 0) {
    const combineFields = CommonUtilities.handleCombineFields(
      testTypeSetting.selectedFields,
      customFields
    );

    const selectedFields = handleGroupSelectedField(combineFields);
    const sections = [SECTION_META_DATA, SECTION_QUALITY_DATA];

    sections.map((section) => {
      const groups = testTypeSetting
        ? section === SECTION_META_DATA
          ? testTypeSetting.groupMetaData
          : testTypeSetting.groupQualityData
        : [];

      selectedFields?.[section]?.map((f) => {
        columns.push({
          name: f.name,
          value: data[f.name],
        });
      });

      groups.map((g) => {
        return selectedFields?.[g._id]?.map((f) => {
          columns.push({
            name: f.name,
            value: data[f.name],
          });
        });
      });
    });
  }

  return columns;
};

export const configDataExportStatic = (dataContainer) => {
  const { data, loggedUser } = dataContainer;

  let columns = [
    {
      name: "Ceritificate No",
      value: data.certificateNo,
    },
    {
      name: "Status",
      value: data.status,
    },
    {
      name: "Test Date",
      value: DateFormat(data.testDate, "mm/dd/yyyy"),
    },
    {
      name: "Field Name",
      value: data.fieldName,
    },
    {
      name: "Inspection Address",
      value: data.inspectionAddress,
    },
    {
      name: "Handler Name",
      value: data.processorName,
    },
    {
      name: "Lot Number",
      value: data.lotNumber,
    },
    {
      name: "Processor",
      value: data.processorName,
      omit: loggedUser.role !== "Administrators",
    },
    {
      name: "Grower Name",
      value: data.growerNameExt,
      omit: loggedUser.role === "Growers",
    },
    {
      name: "Variety Submitted",
      value: data.varitySubmitted,
    },
    {
      name: "W/C Number",
      value: data.wcNumber,
    },
    {
      name: "Type & Number of Containers",
      value:
        data.typeNumberOfContainers === "Bin"
          ? data.typeNumberOfContainers + " " + data.numberOfContainers
          : data.typeNumberOfContainers,
    },
    {
      name: "Net Weight",
      value: data.netWeight,
    },
    {
      name: "Sample Drawn by DFA",
      value: data.sampleDrawnByDfa === true ? "Yes" : "No",
    },
    {
      name: "Sample Submitted by Handler",
      value: data.sampleSubmittedByExt,
    },
    {
      name: "DFA Inspector",
      value: data.dfaInspectorExt,
    },
    {
      name: "Employee #",
      value: data.employeeNo,
    },
    {
      name: "Photo",
      value: handlePhoto(data.photo),
    },
    {
      name: "Net wt. 100 Inshell Nuts",
      value: data.s1NetWeight,
    },
    {
      name: "Jumbo",
      value: data.s1Jumbo,
    },
    {
      name: "Large",
      value: data.s1Large,
    },
    {
      name: "Medium",
      value: data.s1Medium,
    },
    {
      name: "Baby",
      value: data.s1Baby,
    },
    {
      name: "Pee Wee",
      value: data.s1PeeWee,
    },
    {
      name: "Splits",
      value: data.s1Splits,
    },
    {
      name: "Broken",
      value: data.s1Broken,
    },
    {
      name: "Adhering Hull",
      value: data.s1AdheringHull,
    },
    {
      name: "Insect",
      value: data.s2Insect,
    },
    {
      name: "Rancid",
      value: data.s2Rancid,
    },
    {
      name: "Mold",
      value: data.s2Mold,
    },
    {
      name: "Shrivel",
      value: data.s2Shrivel,
    },
    {
      name: "Other",
      value: data.s2Other,
    },
    {
      name: "Blows",
      value: data.s2Blows,
    },
    {
      name: "Light",
      value: data.s2Light,
    },
    {
      name: "Light Amber",
      value: data.s2LightAmber,
    },
    {
      name: "Amber",
      value: data.s2Amber,
    },
    {
      name: "Black",
      value: data.s2Black,
    },
    {
      name: "Edible Weight",
      value: data.s3EdibleWeight,
    },
    {
      name: "Defect Weight",
      value: data.s3DefectWeight,
    },
    {
      name: "Total Kernel Weight",
      value: data.s3TotalKernelWeight,
    },
    {
      name: "Edible Yield",
      value: data.s3EdibleYield,
    },
    {
      name: "Other Varieties Included",
      value: data.s4OtherVarietiesIncluded,
    },
    {
      name: "Other Varieties Included",
      value: data.s4OtherVarietiesIncluded,
    },
    {
      name: "Serious Stain",
      value: data.s4SeriousStain,
    },
    {
      name: "Moisture",
      value: data.moisture,
    },
    {
      name: "Remarks",
      value: data.s4Remarks,
    },
  ];
  return columns;
};

const handleGroupSelectedField = (array) => {
  const grouped = _.groupBy(array, (arrayItem) =>
    arrayItem.nutTypeGroupId !== ""
      ? arrayItem.nutTypeGroupId
      : arrayItem.section
  );

  return grouped;
};

const handlePhoto = (photo) => {
  let photos = "";
  if (photo && photo !== "" && photo !== "[]") {
    if (photo.startsWith("/public/assets/images")) {
      photos = process.env.REACT_APP_API_URL + photo;
    } else {
      const arrPhoto = JSON.parse(photo);
      if (arrPhoto && arrPhoto.length > 0) {
        arrPhoto.map((x) => {
          if (photos !== "") {
            photos += ", ";
          }
          photos += process.env.REACT_APP_API_URL + x.image;
        });
      }
    }
  }

  return photos;
};
