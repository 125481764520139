import React from "react";
import Summary from "./Summary";
import UnpaidTable from "./UnpaidTable";
import PaidTable from "./PaidTable";
import OverdueTable from "./OverdueTable";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { USER_ROLE_ADMINISTRATOR } from "../Utilities/Constant";

var DateFormat = require("dateformat");
let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      useExtendBody: true,
      pluraltitle: "Grower Payment Report",
      urlapi: GlobalConfig.REACT_APP_API_CROPYEAR_URL + props.match.params.id,
      cropYearId: props.match.params.id,
      cropYear: {},
      cropYearName: "",
      cropYearShortName: "",
      testType: {
        isDynamic: false,
        paymentByVariety: false,
      },
      // isError: false,
      isEmptyWalnutTest: false,
      unpaidLength: 0,
      paidLength: 0,
      overdueLength: 0,
      defaulttitle: "Grower Payment Report",
      childOverrideComponentDidMount: true,
      progressPending: false,
    };
  }

  async componentDidMountChild() {
    if (
      (loggedUser.titerInfo &&
        loggedUser.titerInfo.isAllowGrowerPayment === true) ||
      loggedUser.role === USER_ROLE_ADMINISTRATOR
    ) {
      this.setState(
        {
          isError: false,
        },
        () => {
          this.getItems();
        }
      );
    } else {
      const errorMessage = process.env.REACT_APP_MSG_TIER_NOT_ALLOW_FEATURE;
      this.setState({
        useExtendBody: true,
        extendBodyMessage: errorMessage,
        alertmsg: errorMessage,
        alerttype: "error",
        alertshow: true,
        isError: true,
      });
    }
  }

  handleChangeSelectControl = (selectedCropYearId, selectedCropYearName) => {
    const isNotNull = selectedCropYearId && selectedCropYearId.trim();
    this.setState(
      {
        cropYearId: selectedCropYearId,
        cropYearShortName: isNotNull ? "Crop Year " + selectedCropYearName : "",
        pluraltitle: isNotNull
          ? "Grower Payment Report - Crop Year " + selectedCropYearName
          : "Grower Payment Report",
      },
      () => {
        if (isNotNull) {
          this.getItems();
        } else {
          this.resetData();
        }
      }
    );
  };

  setTitleLength = () => {
    return GlobalConfig.REACT_APP_HEADER_TITLE_SIZE;
  };

  truncateTitle = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  getItems = async () => {
    if (
      this.state.cropYearId === undefined ||
      this.state.cropYearId === null ||
      this.state.cropYearId.trim() === ""
    ) {
      return;
    }
    this.setState(
      {
        progressPending: true,
      },
      () => {
        const that = this;
        fetch(
          GlobalConfig.REACT_APP_API_GROWER_PAYMENT_REPORT_URL +
            "summarybycropyear/" +
            that.state.cropYearId
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (responseData) {
            if (responseData.result !== "OK") {
              window.showAlert("Error", responseData.message, "error");
              that.setState({
                progressPending: false,
                useExtendBody: true,
                extendBodyMessage: responseData.message,
                alertmsg: responseData.message,
                alerttype: "error",
                alertshow: true,
                // isError: true,
                isEmptyWalnutTest: responseData.result === "EMPTY_TEST",
                cropYearShortName: "",
                pluraltitle: "Grower Payment Report",
              });
              that.resetData();
            } else {
              that.setState(
                {
                  progressPending: false,
                  isError: false,
                  isEmptyWalnutTest: false,
                },
                () => {
                  const cropYearName = responseData.data.cropYear.name;
                  const cropYearShortName =
                    "Crop Year " + responseData.data.cropYear.name;

                  const handleTruncate = that.truncateTitle(
                    cropYearName,
                    that.setTitleLength()
                  );

                  let dataOverdue = [];
                  if (
                    responseData.data.dataUnpaid &&
                    responseData.data.dataUnpaid.length > 0
                  ) {
                    const currentDate = parseInt(
                      DateFormat(new Date(), "yyyymmdd")
                    );
                    dataOverdue = responseData.data.dataUnpaid.filter((p) => {
                      return (
                        parseInt(DateFormat(p.paymentDate, "yyyymmdd")) <
                        currentDate
                      );
                    });
                  }
                  that.setState({
                    pluraltitle:
                      "Grower Payment Report - Crop Year " + handleTruncate,
                    cropYearName: cropYearName,
                    cropYearShortName: cropYearShortName,
                    paidLength: responseData.data.dataPaid.length,
                    unpaidLength: responseData.data.dataUnpaid.length,
                    overdueLength: dataOverdue.length,
                    testType: responseData.data.testType,
                    isError: false,
                  });
                  //Bind data summary
                  that.summary.bindData(
                    responseData.data.total,
                    that.state.cropYearId
                  );
                  //Bind data tables unpaid, paid
                  that.paidTable.bindData(
                    responseData.data.dataPaid,
                    that.state.cropYearId
                  );
                  that.unPaidTable.bindData(
                    responseData.data.dataUnpaid,
                    that.state.cropYearId
                  );
                  that.overdueTable.bindData(
                    dataOverdue,
                    that.state.cropYearId
                  );
                }
              );
            }
          })
          .catch(() => {
            that.setState({
              progressPending: false,
            });
          });
      }
    );
  };

  resetData = () => {
    this.summary.bindData([0, 0], this.state.cropYearId);
    this.paidTable.bindData([], this.state.cropYearId);
    this.unPaidTable.bindData([], this.state.cropYearId);
    this.overdueTable.bindData([], this.state.cropYearId);
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a
          href={process.env.REACT_APP_URL_CROPYEAR_LIST}
          title="Back to crop year"
        >
          Crop Years
        </a>
      </li>
    );
  };

  extendBody = () => {
    switch (loggedUser.role) {
      case "Processors":
      case "Administrators":
      case "Staffs":
        return this.state.isError === true ? (
          <>
            <div
              className={
                this.state.isEmptyWalnutTest
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {this.state.extendBodyMessage}
              {this.state.isEmptyWalnutTest ? (
                <span>
                  {" "}
                  To continue using this report, please complete{" "}
                  <a href={process.env.REACT_APP_URL_CROPYEAR_LIST}>
                    crop year
                  </a>{" "}
                  setting and Grading & Tests.
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <Summary
              cropYearShortName={this.state.cropYearShortName}
              processorId={
                loggedUser.processorId
                  ? loggedUser.processorId
                  : this.props.match.params.processorId
              }
              cropYearId={this.state.cropYearId}
              handleRefresh={() => this.getItems()}
              handleChangeSelectControl={this.handleChangeSelectControl}
              onRef={(ref) => (this.summary = ref)}
              isHide={false}
              progressPending={this.state.progressPending}
            />
            <div className="tabs">
              <ul className="row nav nav-tabs tabs-primary">
                <li className="col-xs-4 col-md-4 col-lg-2 spacingNavTab active">
                  <a
                    className="tabItemContainer"
                    href="#Unpaid"
                    data-toggle="tab"
                  >
                    <div className="titleNavTab mr-xs">
                      Unpaid Payment Schedule
                    </div>
                    {/* <div className="badge badge-light">
                            {this.state.unpaidLength}
                          </div> */}
                  </a>
                </li>
                <li className="col-xs-4  col-md-4 col-lg-2 spacingNavTab">
                  <a
                    className="tabItemContainer"
                    href="#Overdue"
                    data-toggle="tab"
                  >
                    <div className="titleNavTab mr-xs">Overdue Payment</div>
                    {/* <div className="badge badge-light">
                            {this.state.overdueLength}
                          </div> */}
                  </a>
                </li>
                <li className="col-xs-4 col-md-4 col-lg-2 spacingNavTab">
                  <a
                    className="tabItemContainer"
                    href="#Paid"
                    data-toggle="tab"
                  >
                    <div className="titleNavTab mr-xs">
                      History Paid Payment
                    </div>
                    {/* <div className="badge badge-light">
                            {this.state.paidLength}
                          </div> */}
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="Unpaid" className="tab-pane active">
                  <UnpaidTable
                    cropYearId={this.state.cropYearId}
                    onRef={(ref) => (this.unPaidTable = ref)}
                    titlePage={this.state.defaulttitle + "Unpaid"}
                    testType={this.state.testType}
                    progressPending={this.state.progressPending}
                  />
                </div>
                <div id="Overdue" className="tab-pane">
                  <OverdueTable
                    cropYearId={this.state.cropYearId}
                    onRef={(ref) => (this.overdueTable = ref)}
                    titlePage={this.state.defaulttitle + "Overdue"}
                    testType={this.state.testType}
                    progressPending={this.state.progressPending}
                  />
                </div>
                <div id="Paid" className="tab-pane">
                  <PaidTable
                    cropYearId={this.state.cropYearId}
                    onRef={(ref) => (this.paidTable = ref)}
                    titlePage={this.state.defaulttitle + "Paid"}
                    testType={this.state.testType}
                    progressPending={this.state.progressPending}
                  />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return "";
    }
  };
}
export default App;
