import React, { createRef } from "react";
import AddComponent from "../Base/AddComponent";
import { Helmet } from "react-helmet";
import Alert from "../Utilities/Alert";
import * as Yup from "yup";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    this.refInputFocus = createRef();
    this.state = {
      urlapi: GlobalConfig.REACT_APP_API_PROCESSOR_URL + `${"signup"}`,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      data: {
        _id: "",
        name: "",
        email: "",
        companyName: "",
        tierId: "",
        activationUrl:
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_URL_ACTIVATION +
          "?token=",
        fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
      },
      ownAlertShow: false,
      ownAlerttype: "",
      ownAlertmsg: "",
      alertProcessingHidden: true,
      tiers: [],
      existedEmail: null,
      selectedTierName: "",
      isShowSaveSuccessfulMessage: false,
      isShowConfirmPopup: false,
    };
  }

  setStateFromQueryString = () => {
    const query = new URLSearchParams(this.props.location.search);
    const tierName = query.get("tier");
    if (tierName) {
      this.setState({ selectedTierName: tierName });
    }
  };

  componentDidMount() {
    this.getTiers();
    this.refInputFocus.current.focus();
  }

  getTiers = () => {
    const that = this;
    fetch(GlobalConfig.REACT_APP_API_TIER_URL)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        that.setState(
          {
            tiers: resultObject.data,
            data: { ...that.state.data, tierId: resultObject.data[0]._id },
          },
          () => {
            that.setStateFromQueryString();
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  doSomethingAfterSaved = async (data) => {
    this.props.history.push({
      pathname: process.env.REACT_APP_URL_SIGNUP_THANK_YOU,
    });
  };

  doSomethingAfterErrorSaved(res) {
    if (res.result === "EXIST") {
      this.setState({ existedEmail: res.message });
    } else {
      this.setState({
        ownAlertShow: true,
        ownAlerttype: "error",
        ownAlertmsg: res.message,
      });
    }
  }

  validate = Yup.object({
    name: Yup.string().required("Enter contact name"),
    email: Yup.string()
      .required("Enter email address")
      .email("Enter valid email. Example: john@paritycorp.com"),
    companyName: Yup.string().required("Enter company name"),
    agreeterms: Yup.boolean()
      .nullable()
      .oneOf([true], "You must accept the terms to register an account"),
  });

  formikRender(props) {
    return (
      <div
        style={{
          backgroundImage:
            "url(/assets/images/PF-Grading-Background-login@2x.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>
            {"Signup Processor | " + process.env.REACT_APP_SITE_TITLE}
          </title>
        </Helmet>
        <section className="body-sign">
          <div className="center-sign">
            <a href="/" className="logo pull-left">
              <img
                src="/assets/images/logo.png"
                height="54"
                alt="ParityFactory Grading"
              />
            </a>

            <div className="panel panel-sign">
              <div className="panel-title-sign mt-xl text-right">
                <h2 className="title text-uppercase text-bold m-none">
                  <i className="fa fa-user mr-xs"></i> Sign Up Processor
                </h2>
              </div>
              <div className="panel-body">
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={props.handleSubmit}
                >
                  <div className="form-group mb-lg">
                    <label>
                      Tier <span className="required">(*)</span>
                    </label>
                    <select
                      ref={this.refInputFocus}
                      name="tierId"
                      className="form-control input-lg mb-md"
                      onChange={this.handleChange}
                    >
                      {this.state.tiers.map((tier, i) => {
                        return this.state.selectedTierName == tier.name ? (
                          <option key={i} value={tier._id} selected>
                            {tier.name}
                          </option>
                        ) : (
                          <option key={i} value={tier._id}>
                            {tier.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="icon mr-sm">
                      <i
                        className="fa fa-question-circle fa-lg"
                        aria-hidden="true"
                      ></i>{" "}
                      <a
                        href={process.env.REACT_APP_URL_PLAN_PRICING}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Plans and pricing
                      </a>
                    </span>
                  </div>
                  <div
                    className={
                      props.touched.name && props.errors.name
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label>
                      Contact Name <span className="required">(*)</span>
                    </label>
                    <input
                      name="name"
                      maxLength="150"
                      type="text"
                      className="form-control input-lg"
                      onChange={(e) => this.handleChange(e, props)}
                    />
                    {props.touched.name && props.errors.name ? (
                      <label for="name" className="control-label">
                        {props.errors.name}
                      </label>
                    ) : null}
                  </div>

                  <div
                    className={
                      this.state.existedEmail ||
                      (props.touched.email && props.errors.email)
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label>
                      E-mail Address <span className="required">(*)</span>
                    </label>
                    <input
                      name="email"
                      maxLength="50"
                      type="email"
                      className="form-control input-lg"
                      onChange={(e) => {
                        this.setState({
                          existedEmail: null,
                        });
                        this.handleChange(e, props);
                      }}
                    />
                    {this.state.existedEmail ||
                    (props.touched.email && props.errors.email) ? (
                      <label for="email" className="control-label">
                        {this.state.existedEmail
                          ? this.state.existedEmail
                          : props.errors.email}
                      </label>
                    ) : null}
                  </div>

                  <div
                    className={
                      props.touched.companyName && props.errors.companyName
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label>
                      Company Name <span className="required">(*)</span>
                    </label>
                    <input
                      name="companyName"
                      maxLength="100"
                      type="text"
                      className="form-control input-lg"
                      onChange={(e) => this.handleChange(e, props)}
                    />
                    {props.touched.companyName && props.errors.companyName ? (
                      <label for="companyName" className="control-label">
                        {props.errors.companyName}
                      </label>
                    ) : null}
                  </div>

                  <div>
                    <div
                      className={
                        props.touched.agreeterms && props.errors.agreeterms
                          ? "has-error"
                          : ""
                      }
                    >
                      <div className="checkbox-custom checkbox-default">
                        <input
                          id="AgreeTerms"
                          name="agreeterms"
                          type="checkbox"
                          onChange={props.handleChange}
                        />
                        <label htmlFor="AgreeTerms">
                          I agree with{" "}
                          <a
                            href={process.env.REACT_APP_URL_TERMS_OF_USE}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms of Use
                          </a>
                        </label>
                      </div>
                      {props.touched.agreeterms && props.errors.agreeterms ? (
                        <label for="agreeterms" className="control-label">
                          {props.errors.agreeterms}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="mt-sm"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary hidden-xs"
                      >
                        Sign Up
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-lg visible-xs mt-lg"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                  {this.state.ownAlertShow ? (
                    <div className="mt-sm row">
                      <div className="col-sm-12">
                        <Alert
                          message={this.state.ownAlertmsg}
                          type={this.state.ownAlerttype}
                          show={this.state.ownAlertShow}
                          showBtnClose={false}
                        ></Alert>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <hr className="dotted short"></hr>
                    <p className="text-center">
                      Already have an account? <a href="/login">Sign In!</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>

            <p className="text-center text-muted mt-md mb-md">
              <span style={{ color: "white" }}>
                &copy; ParityFactory All rights reserved |{" "}
                <a
                  href="https://parityfactory.com/privacy-policy/"
                  style={{ color: "white" }}
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </section>
      </div>
    );
  }

  handleOpenConfirmPopup() {
    this.setState({
      isShowConfirmPopup: true,
    });
  }

  handleCloseConfirmPopup = () => {
    this.setState({
      isShowConfirmPopup: false,
    });
  };

  handleOKConfirmPopup = () => {
    this.setState({
      isShowConfirmPopup: false,
    });
    this.handleSave();
  };

  render() {
    return (
      <>
        <Formik
          initialValues={{
            name: "",
            email: "",
            companyName: "",
            agreeterms: false,
          }}
          validationSchema={this.validate}
          onSubmit={this.handleOpenConfirmPopup.bind(this)}
          render={this.formikRender.bind(this)}
          enableReinitialize={true}
        />
        <Modal
          show={this.state.isShowConfirmPopup}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          onHide={this.handleCloseConfirmPopup}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="confirm-signup-modal-wrapper">
              <i className="fa fa-question-circle text-primary fa-4x"></i>
              <div>
                This request will register you as new processor on PF Grading.
                Would you really to continue?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer-center">
            <Button variant="primary" onClick={this.handleOKConfirmPopup}>
              Confirm
            </Button>
            <Button variant="secondary" onClick={this.handleCloseConfirmPopup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default withRouter(App);
