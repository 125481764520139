import React from "react";
import { Form } from "react-bootstrap";
import SelectMultiGrowers from "../Grower/SelectMultiGrowers";
import String from "../Utilities/String";
import { METHOD_POST } from "./../Utilities/Constant";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      widthHelpPopup: 500,
      message: "",
      errorMessage: "",
      selectAllOption: {
        value: "all-growers",
        label: "All Growers",
        checked: false,
      },
      selectedGrowers: [],
      growerIds: "",
      growers: [],
      growersFiltered: [],
      filterGrowersObject: {
        name: "",
        address: "",
        city: "",
        state: "",
        region: "",
      },
      isDisabledOption: false,
    };
    this.setShowMessagePopup = this.setShowMessagePopup.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeSelectedGrowers = this.onChangeSelectedGrowers.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  async componentDidMount() {
    this.getGrowers();
  }

  async getGrowers() {
    const fields = encodeURIComponent("address city state region");
    const result = await fetch(
      `${this.props.GlobalConfig.REACT_APP_API_GROWER_URL}byProcessorWithFields/${this.props.loggedUser.processorId}/${fields}`
    );
    const response = await result.json();
    if (response && response.result === "OK" && response.data) {
      const growers = response.data;
      const growersFiltered = response.data.map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
        };
      });
      this.setState({
        growers: growers,
        growersFiltered:
          growersFiltered.length > 0
            ? [this.state.selectAllOption, ...growersFiltered]
            : growersFiltered,
      });
    }
  }

  handleChangeFilter = (e) => {
    this.setState(
      {
        filterGrowersObject: {
          ...this.state.filterGrowersObject,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.filterGrowers();
      }
    );
  };

  filterGrowers = () => {
    let growersFiltered = [];
    let conditions = Object.keys({ ...this.state.filterGrowersObject }); // convert object properties to an array of properties.
    conditions = conditions.filter(
      (condition) => !String.isEmpty(this.state.filterGrowersObject[condition])
    );
    if (conditions.length > 0) {
      //If there is one or more filter field, then filter , else not filter
      growersFiltered = [...this.state.growers]
        .filter((grower) => this.isCorrect(grower, conditions))
        .map((grower) => {
          return {
            value: grower._id,
            label: grower.name,
            checked:
              this.state.growerIds === null ||
              this.state.growerIds === undefined ||
              this.state.growerIds.indexOf(grower._id) === -1
                ? false
                : true,
          };
        });
    } else {
      growersFiltered = [...this.state.growers].map((grower) => {
        return {
          value: grower._id,
          label: grower.name,
          checked:
            this.state.growerIds === null ||
            this.state.growerIds === undefined ||
            this.state.growerIds.indexOf(grower._id) === -1
              ? false
              : true,
        };
      });
    }
    if (growersFiltered.length > 0) {
      growersFiltered = [this.state.selectAllOption, ...growersFiltered];
    }
    this.setState({
      growersFiltered: growersFiltered,
    });
  };

  isCorrect = (grower, conditions) => {
    //Loop to check each condition with passed grower
    for (let i = 0; i < conditions.length; i++) {
      if (String.isEmpty(grower[conditions[i]])) {
        return false;
      }
      if (
        grower[conditions[i]]
          .toLowerCase()
          .includes(
            this.state.filterGrowersObject[conditions[i]].toLowerCase()
          ) === false
      ) {
        return false;
      }
    }
    return true;
  };

  onChangeSelectedGrowers(growers) {
    //If user selects all grower option
    if (this.includesAllOption(growers)) {
      const growers = [...this.state.growersFiltered].filter(
        (grower) => grower.value !== this.state.selectAllOption.value
      );
      this.setState({
        isDisabledOption: true,
        growerIds: growers.map((grower) => grower.value).join(","),
      });
      //remove all current selected items except All Growers
      this.growerSelector.setSelected([this.state.selectAllOption.value]);
    } else {
      let growerIds = ``;
      if (growers && growers.length > 0) {
        growerIds = growers.map((x) => x.value).join(`,`);
      }
      this.setState({
        isDisabledOption: false,
        selectedGrowers: growers,
        growerIds: growerIds,
      });
    }
  }

  setShowMessagePopup = () => {
    this.props.setShowMessagePopup();
  };

  handleChange(event) {
    this.setState({
      message: event.target.value,
    });
  }

  handleSend = async () => {
    const message = this.state.message;
    if (message === null || message === undefined || message.trim() === ``) {
      this.setState({
        errorMessage: "Please enter message.",
      });
      return;
    }
    const growerIds = this.state.growerIds;
    if (
      growerIds === null ||
      growerIds === undefined ||
      growerIds.trim() === ``
    ) {
      this.setState({
        errorMessage: "Please select grower.",
      });
      return;
    }
    const processorId = this.props.loggedUser.processorId;
    const requestBody = {
      message,
      processorId: processorId,
      growerIds: growerIds,
      createdUserId: this.props.loggedUser._id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(
      `${this.props.GlobalConfig.REACT_APP_API_MESSAGE_URL}`,
      requestOptions
    );
    const resultObject = await response.json();

    //If created successful
    if (resultObject.result === "OK") {
      window.showAlert("", "Send successful!", "");
      this.sendNotifyToGrowers();
      this.setShowMessagePopup();
    } else {
      window.showAlert("Error", resultObject.message, "error");
    }
  };

  sendNotifyToGrowers = () => {
    const requestOptions = {
      method: METHOD_POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        growerIds: this.state.growerIds,
        portal: this.props.GlobalConfig.REACT_APP_BASE_URL,
      }),
    };
    fetch(
      `${this.props.GlobalConfig.REACT_APP_API_MESSAGE_URL}/sendNotifyToGrowers`,
      requestOptions
    )
      .then((responseData) => responseData.json())
      .then((response) => {
        console.log(response.message);
      })
      .catch((exception) => {
        console.log(exception);
      });
  };

  includesAllOption = (options) => {
    return options.some(
      (option) => option.value === this.state.selectAllOption.value
    );
  };

  render() {
    return (
      <>
        <div
          className="help-popup-background"
          style={{
            display: this.props.showMessagePopup ? "block" : "none",
          }}
          onClick={() => this.setShowMessagePopup()}
        ></div>
        <div
          className="help-popup"
          style={{
            right: this.props.showMessagePopup
              ? `0px`
              : `-${this.state.widthHelpPopup}px`,
            width: this.state.widthHelpPopup,
            boxShadow: this.props.showMessagePopup
              ? "-2px 0 10px 2px rgb(157, 156, 156)"
              : null,
          }}
        >
          <div className="help-popup-header">
            <div className="help-popup-header-title">Message</div>
            <div
              className="close-help-popup-button"
              onClick={() => this.setShowMessagePopup()}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{
                  fontSize: "20px",
                  marginRight: 2,
                }}
              ></i>
              Cancel
            </div>
          </div>
          <div className="help-popup-body">
            <div className="col-md-12">
              <div className="col-md-6 padding-right-apply-growers-filter-field">
                <input
                  type="text"
                  name="name"
                  autoFocus={true}
                  className="form-control"
                  placeholder="Enter grower name to filter"
                  value={this.state.filterGrowersObject.name}
                  onChange={this.handleChangeFilter}
                  maxLength={500}
                />
              </div>
              <div className="col-md-6 padding-left-apply-growers-filter-field">
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Enter address to filter"
                  value={this.state.filterGrowersObject.address}
                  onChange={this.handleChangeFilter}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-6 padding-right-apply-growers-filter-field">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="Enter city to filter"
                  value={this.state.filterGrowersObject.city}
                  onChange={this.handleChangeFilter}
                  maxLength={500}
                />
              </div>
              <div className="col-md-6 padding-left-apply-growers-filter-field">
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  placeholder="Enter state to filter"
                  value={this.state.filterGrowersObject.state}
                  onChange={this.handleChangeFilter}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="col-md-12" style={{ padding: "5px 0px 5px 0px" }}>
                <input
                  type="text"
                  name="region"
                  className="form-control"
                  placeholder="Enter region to filter"
                  value={this.state.filterGrowersObject.region}
                  onChange={this.handleChangeFilter}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-md-12">
                <SelectMultiGrowers
                  externalSource={true}
                  source={this.state.growersFiltered}
                  selectedGrowers={this.state.selectedGrowers}
                  onChangeSelectedGrowers={this.onChangeSelectedGrowers}
                  placeholder="Select growers"
                  withoutLabel={false}
                  onRef={(ref) => (this.growerSelector = ref)}
                  allowSelectAll
                  isOptionDisabled={() => this.state.isDisabledOption}
                />
              </div>
            </div>
            <div className="col-md-12">
              <textarea
                rows={10}
                className="form-control"
                placeholder="Enter message"
                value={this.state.message}
                onChange={(e) => this.handleChange(e)}
                required
              ></textarea>
            </div>
            <div className="col-md-12">
              {!!this.state.errorMessage && (
                <>
                  <Form.Label
                    column
                    md="12"
                    style={{ color: "red", paddingLeft: "0px" }}
                  >
                    {this.state.errorMessage}
                  </Form.Label>
                </>
              )}
            </div>
            <div style={{ float: "right", marginRight: 15 }}>
              <button
                className="help-popup-button"
                onClick={() => this.handleSend()}
              >
                <i
                  className="fa fa-paper-plane-o"
                  aria-hidden="true"
                  style={{
                    marginRight: 5,
                  }}
                ></i>
                Send
              </button>
            </div>
          </div>
          <div className="help-popup-footer"></div>
        </div>
      </>
    );
  }
}
export default App;
