import React from "react";
import DateFormat from "../Utilities/DateFormat";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import FilterMultiSelectControlFromSystemSetting from "../FormControl/FilterMultiSelectControlFromSystemSetting";
import FilterSelectControl from "../FormControl/FilterSelectControl";
import { Dropdown } from "react-bootstrap";
import OptionDropdown from "../Base/OptionDropdown";
import { isArray } from "lodash";
import qs from "query-string";
import TestTypesPopup from "./TestTypesPopup";
import {
  USER_ROLE_ADMINISTRATOR,
  USER_ROLE_STAFF,
  USER_ROLE_PROCESSOR,
  VALUE_QUERY_PARAM_IGNORE,
} from "../Utilities/Constant";
import Select from "../FormControl/MultiSelect";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const defaultOptions = [
  { _id: "true", name: "Yes" },
  { _id: "false", name: "No" },
];

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const filterProcessorId = "";
    const filterTestFieldName = "";
    const filterTestFieldType = "";
    const filterTestFieldStatus = "";
    const filterTestFieldRequired = "";
    const filterNutType = "";
    const filterCategory = "";

    this.state.urlapidelete = GlobalConfig.REACT_APP_API_TEST_FIELD_URL;
    this.state.urlapi =
      loggedUser.role === "Staffs" || loggedUser.role === "Processors"
        ? `${GlobalConfig.REACT_APP_API_TEST_FIELD_URL}byprocessor/${loggedUser.processorId}/all/all/all/all/all/${VALUE_QUERY_PARAM_IGNORE}`
        : `${GlobalConfig.REACT_APP_API_TEST_FIELD_URL}all/all/all/all/all/all/${VALUE_QUERY_PARAM_IGNORE}`;

    this.state.urllist = process.env.REACT_APP_URL_TEST_FIELD_LIST;
    this.state.urladd = process.env.REACT_APP_URL_TEST_FIELD_LIST + `-add`;

    this.state.title = "Test Field";

    this.state.pageName = "Test_Field";
    // this.state.pagination = true;
    //server paging
    this.state.isServerPaging = true;
    this.state.loading = false;
    this.state.totalRows = 0;
    this.state.currentPage = 1;
    this.state.perPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.paginationPerPage = parseInt(GlobalConfig.REACT_APP_PAGE_SIZE);
    this.state.rowPerPageOption = GlobalConfig.REACT_APP_ROW_PER_PAGE_OPTION;
    this.state.filterTestFieldName = filterTestFieldName;
    this.state.filterTestFieldType = filterTestFieldType;
    this.state.filterTestFieldStatus = filterTestFieldStatus;
    this.state.filterTestFieldRequired = filterTestFieldRequired;
    this.state.filterProcessorId = filterProcessorId;
    this.state.filterNutType = filterNutType;
    this.state.filterCategory = filterCategory;
    this.state.hideColumns = ["modifiedDate", "createdDate"];
    this.state.childOverrideComponentDidMount = true;
  }

  updateFilterControlUrls = () => {
    let urlApiStatusFilter = `${GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL}likename/Test Field Status`;
    let urlApiProcessorFilter = `${GlobalConfig.REACT_APP_API_PROCESSOR_URL}filterDataProcessor`;
    let urlApiTypeFilter = `${GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL}likename/Test Field Type`;
    let urlApiNutTypeFilter = "";
    let urlApiCategoryFilter = "";
    switch (loggedUser.role) {
      case USER_ROLE_ADMINISTRATOR: {
        urlApiNutTypeFilter = GlobalConfig.REACT_APP_API_NUTTYPE_URL;
        urlApiCategoryFilter = `${GlobalConfig.REACT_APP_API_TEST_FIELD_URL}allCategory`;
        break;
      }
      case USER_ROLE_PROCESSOR:
      case USER_ROLE_STAFF: {
        urlApiNutTypeFilter = `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}/filterDataNutTypeByProcessor/${loggedUser.processorId}`;
        urlApiCategoryFilter = `${GlobalConfig.REACT_APP_API_TEST_FIELD_URL}allCategoryByProcessor/${loggedUser.processorId}`;
        break;
      }
      default:
    }
    this.setState({
      filterControlUrls: {
        urlApiStatusFilter: urlApiStatusFilter,
        urlApiProcessorFilter: urlApiProcessorFilter,
        urlApiTypeFilter: urlApiTypeFilter,
        urlApiNutTypeFilter: urlApiNutTypeFilter,
        urlApiCategoryFilter: urlApiCategoryFilter,
      },
    });
  };

  async componentDidMountChild() {
    await this.getItemsServerPaging(this.state.currentPage);
    this.setSelectedFilter();
    this.loadColumnSettings();
  }

  // http://localhost:81/test-fields?name=test&type=Text%20Box%20(Multiple%20Line)&required=true&status=Active&testType=5ee03c8b91485a1d8ca96254,624fe1fe4ca13b435cd039db
  setSelectedFilter = () => {
    const queryData = qs.parse(this.props.location?.search);

    if (JSON.stringify(queryData) !== "{}") {
      const {
        name = "",
        type = "all",
        required = "all",
        processor = "all",
        status = "all",
        testType = "all",
        category = "all",
      } = queryData;

      if (!!type && type !== "all") {
        this.typeControl?.setSelected(type);
      }

      if (!!required && required !== "all") {
        this.requiredControl?.setSelected(required);
      }

      if (!!processor && processor !== "all") {
        this.processorControl?.setSelected(processor);
      }

      if (!!status && status !== "all") {
        this.statusControl?.setSelected(status);
      }

      if (!!testType && testType !== "all") {
        this.testTypeControl?.setSelected(testType);
      }

      if (!!category && category !== "all") {
        this.categoryControl?.setSelected(category);
      }

      this.setState(
        {
          filterTestFieldName: decodeURI(name),
          filterTestFieldType: decodeURI(type),
          filterTestFieldStatus: decodeURI(status),
          filterTestFieldRequired: required,
          filterProcessorId: decodeURI(processor),
          filterNutType: decodeURI(testType),
          filterCategory: decodeURI(category),
        },
        () => {
          this.filterItems();
        }
      );
    }
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  columnOptionReset = () => {
    this.setState(
      {
        data: Object.assign({}, this.state.data, {
          pageName: this.state.pageName.replaceAll(" ", ""),
          value: "",
        }),
        hideColumns: ["modifiedDate", "createdDate"],
      },
      async function callBack() {
        try {
          const requestOptions = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.data),
          };
          const that = this;
          fetch(
            this.state.userSettingUrlApi +
              this.state.pageName.replaceAll(" ", "") +
              loggedUser.referenceUserId +
              "/" +
              "table" +
              this.state.pageName.replaceAll(" ", "") +
              loggedUser.referenceUserId +
              "/" +
              loggedUser.referenceUserId,
            requestOptions
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              //If updated successful
              if (resultObject && resultObject.result === "OK") {
                that.setState({
                  alertshow: false,
                });
                window.showAlert("", "Save successful!", "");

                that.doSomethingAfterSaved(resultObject);
              }
              //If failed, show error
              else {
                that.setState({
                  alertshow: false,
                });
                that.setState({
                  alertmsg: resultObject.message,
                  alerttype: "error",
                  alertshow: true,
                });
              }
            })
            .catch(function (error) {
              that.setState({
                alertshow: false,
              });
              that.setState({
                alertmsg: error,
                alerttype: "error",
                alertshow: true,
              });
            });
        } catch (err) {
          this.setState({
            alertshow: false,
          });
          this.setState({
            alertmsg: err.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      }
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleChangeFilterStatus = (event) => {
    if (event) {
      this.setState({
        filterTestFieldStatus: event.value,
      });
    } else {
      this.setState({
        filterTestFieldStatus: "",
      });
    }
  };

  filterItems = async () => {
    let urlapi = "";
    const { currentPage, paginationPerPage } = this.state;
    let filterTestFieldName = this.state.filterTestFieldName;
    if (
      filterTestFieldName === null ||
      filterTestFieldName === undefined ||
      filterTestFieldName.trim() === ``
    ) {
      filterTestFieldName = VALUE_QUERY_PARAM_IGNORE;
    }

    let filterTestFieldType = !!this.state.filterTestFieldType
      ? this.state.filterTestFieldType
      : "all";

    let filterTestFieldStatus = !!this.state.filterTestFieldStatus
      ? this.state.filterTestFieldStatus
      : "all";

    let filterTestFieldRequired = !!this.state.filterTestFieldRequired
      ? this.state.filterTestFieldRequired
      : "all";

    let filterProcessorId = !!this.state.filterProcessorId
      ? this.state.filterProcessorId
      : "all";

    let filterNutType = !!this.state.filterNutType
      ? this.state.filterNutType
      : "all";

    let filterCategory = !!this.state.filterCategory
      ? this.state.filterCategory
      : "all";

    if (loggedUser.role === "Staffs" || loggedUser.role === "Processors") {
      filterProcessorId = loggedUser.processorId;
      urlapi =
        GlobalConfig.REACT_APP_API_TEST_FIELD_URL +
        "byprocessor/" +
        filterProcessorId +
        "/" +
        filterTestFieldType +
        "/" +
        filterNutType +
        "/" +
        filterCategory +
        "/" +
        filterTestFieldStatus +
        "/" +
        filterTestFieldRequired +
        "/" +
        encodeURIComponent(filterTestFieldName);
    } else {
      urlapi =
        GlobalConfig.REACT_APP_API_TEST_FIELD_URL +
        filterProcessorId +
        "/" +
        filterTestFieldType +
        "/" +
        filterNutType +
        "/" +
        filterCategory +
        "/" +
        filterTestFieldStatus +
        "/" +
        filterTestFieldRequired +
        "/" +
        encodeURIComponent(filterTestFieldName);
    }

    this.setState({ urlapi: urlapi }, () => {
      this.getItemsServerPaging(currentPage);
    });
  };

  handleReset = () => {
    this.typeControl.reset();
    this.requiredControl.reset();
    this.processorControl?.reset();
    this.statusControl.clearValue();
    this.testTypeControl.reset();
    this.categoryControl.reset();
    this.setState(
      {
        filterTestFieldName: "",
        filterTestFieldType: "all",
        filterTestFieldStatus: "all",
        filterTestFieldRequired: "all",
        filterProcessorId: "all",
        filterNutType: "all",
        filterCategory: "all",
        currentPage: 1,
      },
      () => this.filterItems()
    );
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterTestFieldName"
                placeholder="Filter by name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterTestFieldName}
              />
            </div>
            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <FilterMultiSelectControlFromSystemSetting
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.typeControl = ref)}
                placeholder="Filter Type"
                label="Select All"
                controlId="filterTestFieldType"
                urlapilist={this.state.filterControlUrls?.urlApiTypeFilter}
              />
            </div>

            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <FilterSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => {
                  this.requiredControl = ref;
                }}
                label="Filter Required"
                controlId="filterTestFieldRequired"
                isUseDefaultData
                defaultData={defaultOptions}
              />
            </div>

            {loggedUser.role === "Administrators" && (
              <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
                <FilterMultiSelectControl
                  handleChangeSelectControl={this.handleChangeSelectControl}
                  onRef={(ref) => (this.processorControl = ref)}
                  placeholder="Filter Processor"
                  label="Filter Processor"
                  controlId="filterProcessorId"
                  urlapilist={
                    this.state.filterControlUrls?.urlApiProcessorFilter
                  }
                />
              </div>
            )}
            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <FilterMultiSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.testTypeControl = ref)}
                placeholder="Filter Test Type"
                label="Select All"
                controlId="filterNutType"
                urlapilist={this.state.filterControlUrls?.urlApiNutTypeFilter}
              />
            </div>
            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <FilterMultiSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.categoryControl = ref)}
                placeholder="Filter Category"
                label="Select All"
                controlId="filterCategory"
                urlapilist={this.state.filterControlUrls?.urlApiCategoryFilter}
              />
            </div>
            <div className="col-md-3 col-lg-4 mb-sm select-config-container select-render-search">
              <Select
                api={this.state.filterControlUrls?.urlApiStatusFilter}
                placeholder="Filter Status"
                onRef={(ref) => (this.statusControl = ref)}
                parseObjectItem={(status) => {
                  return {
                    label: status,
                    value: status,
                  };
                }}
                onChange={this.handleChangeFilterStatus}
                isClearable
              ></Select>
            </div>
          </div>
        </div>
      </>
    );
  };

  handleCopy = async (data) => {
    const requestData = {
      _id: data._id,
      createdUserId: loggedUser._id,
      processorId: loggedUser.processorId,
    };

    if (!this.state.alertProcessingHidden) {
      window.showAlert("Info", "Processing...", "Info");
    }
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      };
      const that = this;
      fetch(
        GlobalConfig.REACT_APP_API_TEST_FIELD_URL + "copy/" + data._id,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          //If copied successful
          if (resultObject && resultObject.result === "OK") {
            //reset form
            that.filterItems();
            window.showAlert("", resultObject.message, "");
          }
          //If failed, show error
          else {
            window.showAlert("Error", resultObject.message, "Error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
        });
    } catch (err) {
      window.showAlert("Error", err.message, "Error");
    }
  };

  defaultColumns = () => {
    return [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <a href={this.state.urllist + "/" + row._id} title={row.name}>
            {row.name}
          </a>
        ),
      },
      {
        name: "Type",
        selector: "fieldType",
        sortable: true,
        center: true,
      },
      {
        name: "Required",
        selector: "isMandatory",
        sortable: true,
        center: true,
        cell: (row) => (row.isMandatory ? "Yes" : "No"),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          let className =
            row.status === "Active"
              ? "label label-success"
              : row.status === "Test"
              ? "label label-warning"
              : "label label-default";
          return (
            <span
              className={className}
              style={{
                paddingTop: "5px",
                paddingBottom: "7px",
                width: "55px",
              }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "Test Type",
        selector: "nutType",
        sortable: true,
        center: true,
        cell: (row) => (
          <>{row.nutType?.length > 0 && <TestTypesPopup testField={row} />}</>
        ),
      },
      {
        name: "Category",
        selector: "category",
        sortable: true,
        cell: (row) => <div>{row?.category}</div>,
      },
      {
        name: "Processor",
        selector: "processorName",
        sortable: true,
        cell: (row) => (
          <a
            href={
              process.env.REACT_APP_URL_PROCESSOR_LIST + "/" + row.processorId
            }
            title={row.processorName}
          >
            {row.processorName}
          </a>
        ),
        omit: loggedUser.role !== "Administrators",
      },
      {
        name: "Modified Date",
        selector: "modifiedDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return row.modifiedDate ? (
            <DateFormat date={row.modifiedDate}></DateFormat>
          ) : (
            ""
          );
        },
      },
      {
        name: "Created Date",
        selector: "createdDate",
        sortable: false,
        center: true,
        cell: (row) => {
          return <DateFormat date={row.createdDate}></DateFormat>;
        },
      },
      {
        name: "",
        center: true,
        allowOverflow: true,
        cell: (row) => {
          // When items length in table is smaller than 7, adjust action dropdown to overflow table and position always show down
          // When items length in table is greater than 7, action dropdown maybe show (up, down)
          const itemsLength = isArray(this.state.items)
            ? this.state.items.length
            : 0;
          const popperConfig = itemsLength < 7 && {
            strategy: "fixed",
          };
          return (
            <Dropdown>
              <Dropdown.Toggle
                align="center"
                className="dropdown-toggle-custom"
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu popperConfig={popperConfig}>
                {row.processorId !== loggedUser.processorId &&
                loggedUser.role !== "Administrators" ? (
                  ""
                ) : (
                  <>
                    <Dropdown.Item
                      href={this.state.urllist + "/" + row._id}
                      title="Click here to edit"
                    >
                      <OptionDropdown icon="pencil-square-o" title="Edit" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      title="Clone test field"
                      onClick={() => this.handleCopy(row)}
                    >
                      <OptionDropdown icon="copy" title="Clone" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      title="Click here to remove"
                      data-id={row._id}
                      onClick={this.handleDelete.bind(this)}
                    >
                      <OptionDropdown icon="trash-o" title="Remove" />
                    </Dropdown.Item>
                  </>
                )}
                <>
                  <Dropdown.Item
                    target="_blank"
                    title="Revision history"
                    href={
                      process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                      "/object/" +
                      row._id
                    }
                  >
                    <OptionDropdown icon="history" title="Revision" />
                  </Dropdown.Item>
                </>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ];
  };
}
export default App;
