import React from "react";
import {
  ChasingDots,
  Circle,
  CubeGrid,
  DoubleBounce,
  FadingCircle,
  FoldingCube,
  Pulse,
  RotatingPlane,
  ThreeBounce,
  WanderingCubes,
  Wave,
} from "better-react-spinkit";

import { LoadableData, LoadingComponents, ScreenWrapper, Text } from "./styles";

/**
 * @name LoadingScreen
 * @description LoadingScreen component
 * @param {*} props
 * - loading: boolean, true if loading, false if not loading
 * - bgColor: string, background color of loading screen
 * - textColor: string, text color of loading screen
 * - spinnerColor: string, color of spinner
 * - text: string, text to display
 * @returns {JSX}
 */
function LoadingScreen({
  children,
  bgColor,
  spinnerColor,
  textColor,
  loading,
  text,
  textStyle = {},
}) {
  return (
    <div>
      <ScreenWrapper bgColor={bgColor} loading={loading}>
        <LoadingComponents>
          {loading && spinnerColor && (
            <>
              <ThreeBounce size={30} color={spinnerColor} />
              {text && (
                <Text style={textStyle} textColor={textColor}>
                  {text}
                </Text>
              )}
            </>
          )}
        </LoadingComponents>
      </ScreenWrapper>

      <LoadableData loading={loading}>{children}</LoadableData>
    </div>
  );
}

export default LoadingScreen;
