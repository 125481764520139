import React from "react";
import { getLoggedUser } from "../../context/auth";
import ProfileAdmin from "../../Components/Administrator/profile";
import ProfileProcessor from "../../Components/Processor/profile";
import ProfileLabUser from "../../Components/LabUser/profile";
import ProfileGrower from "../../Components/Grower/profile";
import ProfileStaff from "../../Components/Staff/profile";

let loggedUser = {};

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
  }

  renderSwitch() {
    const { history, location } = this.props;
    const props = { history, location };
    switch (loggedUser.role) {
      case "Administrators":
        return <ProfileAdmin {...props} />;
      case "Lab Users":
        return <ProfileLabUser {...props} />;
      case "Processors":
        return <ProfileProcessor {...props} />;
      case "Growers":
        return <ProfileGrower {...props} />;
      case "Staffs":
        return <ProfileStaff {...props} />;
      default:
        return "";
    }
  }

  render() {
    return this.renderSwitch();
  }
}

export default App;
