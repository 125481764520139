import React, { useEffect, useState } from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state = {
      title: "Variety",
      listtitle: "Varieties",
      urllist:
        process.env.REACT_APP_URL_NUTTYPE_LIST +
        "/varieties/" +
        props.match.params.id,
      urlapi: GlobalConfig.REACT_APP_API_VARIETY_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      parentId: props.match.params.id,
      data: {
        _id: "",
        parentId: props.match.params.id,
        name: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    };
  }

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        parentId: this.state.parentId,
        name: "",
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
    });
  };

  renderForm = () => {
    return (
      <div className="panel-body">
        <div className="form-group">
          <label className="col-sm-3 control-label">
            Name <span className="required">*</span>
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="name"
              autoFocus={true}
              className="form-control"
              placeholder=""
              required
              value={this.state.data.name}
              onChange={this.handleChange}
              maxLength={100}
            />
          </div>
        </div>
      </div>
    );
  };

  extendBreadcrumb = () => {
    return (
      <li>
        <a href={process.env.REACT_APP_URL_NUTTYPE_LIST} title="Back to list">
          Test Types
        </a>
      </li>
    );
  };

  extendButtons = () => {
    return (
      <a href={this.state.urladd} className="btn btn-primary">
        <i className="fa fa-plus"></i> Add
      </a>
    );
  };
}
export default App;
