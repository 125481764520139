import React from "react";
import AddComponent from "../Base/AddComponent";
import { getLoggedUser } from "../../context/auth";
import Header from "../Header";
import Navigation from "../Navigation";
import Alert from "../Utilities/Alert";
import Textbox from "../FormControl/Textbox";
import TypeContainer from "../FormControl/TypeContainer";
import NumericInput from "../FormControl/NumericInput";
import SelectControl from "../FormControl/SelectControl";
import SelectControlGrower from "../Grower/SelectControl";
import SelectWalnutVarieties from "../TestType/SelectWalnutVarieties";
import { exportPdfWalnutTest } from "./export";
import { Helmet } from "react-helmet";
import PageInvalidAccess from "../Pages/PageInvalidAccess";
import CommonUtilities from "../Utilities/Common";
import NodeUuid from "node-uuid";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { renderFieldByType } from "../TestField/TestFieldUtilities";
import MultiQualityData from "./MultiQualityData";
import {
  SECTION_META_DATA,
  SECTION_QUALITY_DATA,
  FIELD_TYPE_DROP_DOWN,
  FIELD_TYPE_CALCULATED,
  FIELD_TYPE_INTEGER,
  FIELD_TYPE_PERCENT,
  FIELD_TYPE_DECIMAL,
} from "../Utilities/Constant";
import _ from "lodash";
import Equation from "equations";
import SaveButton from "../Controls/SaveButton";
const moment = require("moment");

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends AddComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    const query = new URLSearchParams(props.location.search);
    const nutTypeId = query.get("type");
    let nutTypeName = query.get("name");
    let dynamic = query.get("dynamic");
    if (
      nutTypeName === null ||
      nutTypeName === undefined ||
      nutTypeName.trim() === ""
    ) {
      nutTypeName = "Missed Test Type";
    }

    this.state = {
      nutTypeName: nutTypeName,
      extendBodyMessage: "",
      title: nutTypeName,
      listtitle: nutTypeName,
      urllist:
        process.env.REACT_APP_URL_WALNUT_TEST_LIST +
        "?type=" +
        nutTypeId +
        "&name=" +
        nutTypeName +
        "&dynamic=" +
        dynamic,
      urlapi: GlobalConfig.REACT_APP_API_WALNUT_TEST_URL,
      alertshow: false,
      alerttype: "",
      alertmsg: "",
      imageMaxSize: parseInt(GlobalConfig.REACT_APP_MAX_SIZE_WALNUT_TEST_IMAGE),
      //virtual field not belong to schema model, to be retrieved from processor
      processorName: "",
      certificateNo: "",
      print: false,
      nutTypeId: nutTypeId,
      isAllowEdit: true,
      data: {
        _id: "",
        lotNumber: "",
        growerName: "",
        varitySubmitted: "",
        typeNumberOfContainers: "Bin",
        numberOfContainers: 0,
        netWeight: 0,
        overridePrice: 0,
        dfaInspector: "",
        fieldName: "",
        photo: "",
        testDate: moment(new Date()).format("MM/DD/yyyy"),
        processorId: loggedUser.processorId,
        labUserId:
          loggedUser.role !== "Lab Users" ? "" : loggedUser.referenceUserId,
        nutTypeId: nutTypeId,
        customFields: ``,
        qualityData: ``,
        xpoolPercent: 0,
        xpoolWeight: 0,
        regularPercent: 100,
        regularWeight: 0,

        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
      },
      qualityData: [],
      classificationItems: [],
      //test type settings
      testTypeSetting: {},
      calculatedFields: [],
      numberFields: [],
      loading: false,
      //image showing on popup
      images: [],
      image: "",
      size: "",
      name: "",
      showImage: false,
      index: -1,
    };
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeNumberContainers =
      this.handleChangeNumberContainers.bind(this);
    this.handleChangeSelectTypeContainers =
      this.handleChangeSelectTypeContainers.bind(this);
    this.updateCalculatedFields = this.updateCalculatedFields.bind(this);
  }

  async componentDidMount() {
    const that = this;
    fetch(
      GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
        "validateprocessor/" +
        loggedUser.processorId
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          window.toggleNavigation();
          that.setState(
            { useExtendBody: false, extendBodyMessage: "" },
            function () {
              that.updateStateFromLoggedUser(resultObject, that);
            }
          );
          //Query nut type detail
          fetch(
            `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}getByProcessorAndId/${loggedUser.processorId}/${that.state.nutTypeId}`
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject1) {
              if (resultObject1.result === "OK") {
                that.getClassificationItems(that);
                if (resultObject1.data.isInUse === false) {
                  const errorTestTypeUnpublished =
                    "Selected test type is unpublished! Please refresh and switch to other type.";
                  that.setState({
                    useExtendBody: true,
                    extendBodyMessage: errorTestTypeUnpublished,
                    alertmsg: errorTestTypeUnpublished,
                    alerttype: "error",
                    alertshow: true,
                  });
                } else {
                  let calculatedFields = [];
                  let numberFields = [];
                  if (
                    resultObject1.data.allFields &&
                    resultObject1.data.allFields.length > 0
                  ) {
                    calculatedFields = resultObject1.data.allFields.filter(
                      (x) => {
                        return x.fieldType === FIELD_TYPE_CALCULATED;
                      }
                    );
                    numberFields = resultObject1.data.allFields.filter((x) => {
                      return (
                        x.fieldType === FIELD_TYPE_INTEGER ||
                        x.fieldType === FIELD_TYPE_PERCENT ||
                        x.fieldType === FIELD_TYPE_DECIMAL
                      );
                    });
                  }
                  that.setDefaultValues(resultObject1.data.allFields);
                  that.setState(
                    {
                      testTypeSetting: resultObject1.data,
                      calculatedFields: calculatedFields,
                      numberFields: numberFields,
                    },
                    () => {
                      const typeNumberOfContainers =
                        that.state.testTypeSetting.typeNumberOfContainers;
                      const numberOfContainers =
                        typeNumberOfContainers === "Bin"
                          ? that.state.testTypeSetting.numberOfContainers
                            ? that.state.testTypeSetting.numberOfContainers
                            : 0
                          : 0;
                      that.setState({
                        data: {
                          ...that.state.data,
                          typeNumberOfContainers: typeNumberOfContainers,
                          numberOfContainers: numberOfContainers,
                        },
                      });
                    }
                  );
                }
              } else {
                that.setState({
                  useExtendBody: true,
                  extendBodyMessage: resultObject1.message,
                  alertmsg: resultObject1.message,
                  alerttype: "error",
                  alertshow: true,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          that.setState({
            useExtendBody: true,
            extendBodyMessage: resultObject.message,
            alertmsg: resultObject.message,
            alerttype: "error",
            alertshow: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getClassificationItems(that) {
    fetch(`${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}`)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          that.setState({
            classificationItems: resultObject.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange(event) {
    const id = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(
      {
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          [id]: value,
        }),
      },
      () => {
        if (this.state.testTypeSetting.xpool === true) {
          if (id === `xpoolPercent`) {
            this.setState(
              {
                data: Object.assign({}, this.state.data, {
                  regularPercent: 100 - value,
                }),
              },
              () => {
                this.updateCalculatedFields();
              }
            );
          } else if (id === `xpoolWeight` || id === `netWeight`) {
            const regularWeight =
              this.state.data.netWeight - this.state.data.xpoolWeight;
            this.setState(
              {
                data: Object.assign({}, this.state.data, {
                  regularWeight: regularWeight,
                }),
              },
              () => {
                this.updateCalculatedFields();
              }
            );
          } else {
            this.updateCalculatedFields();
          }
        } else {
          this.updateCalculatedFields();
        }
      }
    );
  }

  updateCalculatedFields = () => {
    if (this.state.calculatedFields && this.state.calculatedFields.length > 0) {
      let updatedObjects = {};
      this.state.calculatedFields.map((f) => {
        const calculatedValue = CommonUtilities.updateCalculatedField(
          this.state.numberFields,
          this.state.data,
          f,
          Equation
        );
        updatedObjects = JSON.parse(JSON.stringify(updatedObjects));
        updatedObjects[[f._id]] = calculatedValue;
      });
      this.setState({
        data: Object.assign({}, this.state.data, updatedObjects),
      });
    }
  };

  setDefaultValues = (allFields) => {
    //Set default value for fields, this also help for handleChange of controls
    if (allFields && allFields.length > 0) {
      allFields.map((x) => {
        this.setState({
          data: Object.assign({}, this.state.data, {
            [x._id]: x.defaultValue,
          }),
        });
      });
      //This field is using for saving in database
      const customFields = allFields.map((x) => x._id).join(`,`);
      this.setState({
        data: Object.assign({}, this.state.data, {
          customFields: customFields,
        }),
      });

      //Set value for dropdown fields
      if (allFields && allFields.length > 0) {
        allFields
          .filter((f) => {
            return f.fieldType === FIELD_TYPE_DROP_DOWN;
          })
          .map((f) => {
            if (this[f._id]) {
              this[f._id].setSelected(this.state.data[f._id]);
            }
          });
      }
    }
  };

  handResetForm = () => {
    this.setState({
      data: {
        _id: "",
        lotNumber: "",
        growerName: "",
        varitySubmitted: "",
        typeNumberOfContainers:
          this.state.testTypeSetting.typeNumberOfContainers,
        numberOfContainers:
          this.state.testTypeSetting.typeNumberOfContainers === "Bin"
            ? this.state.testTypeSetting.numberOfContainers
              ? this.state.testTypeSetting.numberOfContainers
              : 0
            : 0,
        netWeight: 0,
        overridePrice: 0,
        dfaInspector: this.state.data.dfaInspector,
        photo: "",
        fieldName: "",
        testDate: moment(new Date()).format("MM/DD/yyyy"),
        processorId: loggedUser.processorId,
        labUserId:
          loggedUser.role !== "Lab Users" ? "" : loggedUser.referenceUserId,
        nutTypeId: this.state.nutTypeId,
        createdUserId: loggedUser._id,
        createdDate: new Date(),
        modifiedUserId: "",
        modifiedDate: null,
        customFields: "",
        xpoolPercent: 0,
        xpoolWeight: 0,
        regularPercent: 100,
        regularWeight: 0,
      },
      qualityData: [],
      images: [],
      image: "",
      size: "",
      name: "",
      showImage: false,
      index: -1,
    });
    if (this.varitySubmitted) {
      this.varitySubmitted.setSelected("");
    }
    if (this.growerControl) {
      this.growerControl.setSelected("");
    }
    if (this.dfaInspectorControl) {
      this.dfaInspectorControl.setSelected("");
    }
    if (this.state.testTypeSetting) {
      this.setDefaultValues(this.state.testTypeSetting.allFields);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const that = this;
    that.setState({ loading: true }, async () => {
      fetch(
        GlobalConfig.REACT_APP_API_SUBSCRIPTION_URL +
          "validateprocessor/" +
          loggedUser.processorId
      )
        .then(function (response) {
          return response.json();
        })
        .then(async function (resultObject) {
          if (resultObject.result === "OK") {
            await that.handleSave();
            that.setState({ loading: false });
          } else {
            window.showAlert("Error", resultObject.message, "Error");
            that.setState({ loading: false });
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "Error");
          that.setState({ loading: false });
        });
    });
  };

  extendBody = () => {
    return (
      <div className="alert alert-danger">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        {loggedUser.role == "Processors" || loggedUser.role == "Staffs"
          ? this.state.extendBodyMessage
          : this.state.extendBodyMessage}
      </div>
    );
  };

  updateStateFromLoggedUser = async (processorItem, that) => {
    try {
      if (loggedUser.role === "Lab Users") {
        that.setState({
          processorName: processorItem.processorName,
          certificateNo: processorItem.certificateNo,
        });
        that.setState({
          data: Object.assign({}, that.state.data, {
            ["dfaInspector"]: loggedUser.referenceUserId,
          }),
        });
        that.dfaInspectorControl.setSelected(loggedUser.referenceUserId);
      } else if (
        loggedUser.role === "Processors" ||
        loggedUser.role === "Staffs"
      ) {
        that.setState({
          processorName: processorItem.processorName,
          certificateNo: processorItem.certificateNo,
        });
      }

      if (loggedUser.isInvalidProcessor) {
        that.setState({
          alertmsg:
            "Opp! Your account under processor <strong>" +
            loggedUser.processorName +
            "</strong> may inactive. Please switch to other processor to continue.",
          alerttype: "success",
          alertshow: true,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  handleChangeRadio = (event) => {
    const { name, value } = event.target;
    let data = this.state.data;
    data[name] = value;
    this.setState({
      isChanged: true,
      data: data,
    });
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [controlId]: value,
      }),
    });
  };

  handleChangeNumberContainers(value) {
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        ["numberOfContainers"]: value,
      }),
    });
  }

  handleChangeSelectTypeContainers(event) {
    const id = event.target.name;
    const value = event.target.value;
    this.setState({
      isChanged: true,
      data: Object.assign({}, this.state.data, {
        [id]: value,
      }),
    });
  }

  getTitle = () => {
    return this.state.title;
  };

  handleNormalSubmit = async (e) => {
    this.setState({
      print: false,
    });
  };

  countTotalNuts = () => {
    return (
      this.state.data.s1Jumbo +
      this.state.data.s1Large +
      this.state.data.s1Medium +
      this.state.data.s1Baby +
      this.state.data.s1PeeWee +
      this.state.data.s1Splits +
      this.state.data.s1Broken +
      this.state.data.s1AdheringHull
    );
  };

  checkSomethingBeforeSave = () => {
    if (!CommonUtilities.isValidDate(this.state.data.testDate, true)) {
      this.testDate.focus();
      window.showAlert(
        "Error",
        "Test Date must be MM/DD/YYYY. Please check and try again.",
        "Error"
      );
      return false;
    }

    if (this.state.testTypeSetting.typeNumberOfContainersHide !== true) {
      if (
        this.state.data.typeNumberOfContainers === "Bin" &&
        (isNaN(this.state.data.numberOfContainers) ||
          this.state.data.numberOfContainers <= 0)
      ) {
        window.showAlert(
          "Error",
          "Please enter number of containers.",
          "Error"
        );
        return false;
      }
    }

    return true;
  };

  updateDataBeforeSaving = async () => {
    const qualityData = this.state.qualityData
      ? JSON.stringify(this.state.qualityData)
      : ``;
    this.setState({
      data: Object.assign({}, this.state.data, {
        qualityData: qualityData,
      }),
    });
  };

  doSomethingAfterSaved = (data) => {
    if (this.state.print === true) {
      exportPdfWalnutTest(this.state.urlapi + "exportpdf", data._id);
    }
  };

  handleFocus = (e) => {
    if (this.state.isAllowEdit === true) {
      if (e != null && e.target != null && e.target.type == "text") {
        e.target.select();
        if (
          (e.target.value && e.target.value === "0.00") ||
          (e.target.value && e.target.value === "0.0") ||
          (e.target.value && e.target.value === "0")
        ) {
          e.target.value = "";
        }
      }
    }
  };

  handleChange4Image(event) {
    var files = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type == "image/jpeg" || file.type == "image/png") {
          let filesBase64 = "";
          this.getBase64(file, (result) => {
            filesBase64 = result;
            const arrTemp = file.name.split(".");
            const fileExtension = arrTemp[arrTemp.length - 1];
            const images =
              this.state.data && this.state.images
                ? [...this.state.images]
                : [];
            const totalBytes = file.size;
            let size = undefined;
            let unit = "";
            if (totalBytes < 1000000) {
              size = Math.floor(totalBytes / 1000);
              unit = "KB";
            } else {
              size = Math.floor(totalBytes / 1000000);
              unit = "MB";
            }
            if (totalBytes / 1000 > this.state.imageMaxSize) {
              window.showAlert(
                "Error",
                "Photo " +
                  file.name +
                  " does not allow upload. Maximum size allows " +
                  this.state.imageMaxSize / 1000 +
                  "MB.",
                "error"
              );
            } else {
              images.push({
                _id: NodeUuid.v4(),
                image: filesBase64,
                name: file.name,
                size: size,
                extension: fileExtension,
              });
            }
            this.setState({
              isChanged: true,
              data: Object.assign({}, this.state.data, {
                ["photo"]: JSON.stringify(images),
              }),
            });
            this.setState({ images: images });
          });
        } else {
          window.showAlert("Error", "Please choose PNG or JPG", "error");
        }
      }
    }
  }

  handleCloseModalImage = () => {
    this.setState({ showImage: false });
  };

  handleMoveImage = (index) => {
    const data = this.state.images[index];
    this.setState({
      image: data.image,
      name: data.name,
      size: data.size,
      index: index,
    });
  };

  handleOpenModalImage = (data) => {
    let index = this.state.images.indexOf(data);
    this.setState({
      showImage: true,
      image: data.image,
      name: data.name,
      size: data.size,
      index: index,
    });
  };

  handleRemoveImage = (data) => {
    if (this.state.images && this.state.images.length > 0) {
      const images = this.state.images.filter(function (item) {
        return item._id !== data._id;
      });
      this.setState({
        images: images,
      });

      this.setState({
        isChanged: true,
        data: Object.assign({}, this.state.data, {
          ["photo"]: JSON.stringify(images),
        }),
      });
    }
  };

  handleOnBlur({ target: { value } }) {
    const date = new Date(value);
    if (!isValid(date) && value) {
      window.showAlert("Error", "Format date must be dd/MM/yyyy", "error");
    }
    if (value === "") {
      window.showAlert("Error", "Can't be blank", "error");
    }
  }

  renderHeader() {
    return (
      <>
        <div className="col-md-12 col-lg-6 form-group-spacing">
          <div className="form-group">
            <label className="col-md-4 control-label">
              Handler Name (Processor Name)
            </label>
            <div className="col-md-8">
              <label className="control-label">
                {loggedUser.role === "Staffs" ||
                loggedUser.role === "Processors"
                  ? this.state.processorName
                  : "{Apply Processor}"}
              </label>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 form-group-spacing">
          <div className="form-group ">
            <label
              className="col-md-4 control-label mt-xs pt-none"
              htmlFor="testDate"
            >
              Test Date <span className="required">(*)</span>
            </label>
            <div className="col-md-8">
              <div className="input-daterange input-group">
                <span className="input-group-addon test-date-addon">
                  <i className="fa fa-calendar"></i>
                </span>
                <DatePicker
                  className="form-control test-date-input-border"
                  placeholderText="Enter datetime"
                  selected={new Date(this.state.data.testDate)}
                  onChange={this.handleDateChange}
                  onBlur={this.handleOnBlur}
                  readOnly={!this.state.testTypeSetting.testDateEditable}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 form-group-spacing">
          <Textbox
            classProps="form-group-spacing"
            type="text"
            label="Lot Number"
            name="lotNumber"
            value={this.state.data.lotNumber}
            handleChange={this.handleChange}
            maxLength={20}
            required={true}
          />
        </div>

        {this.state.testTypeSetting.fieldNameHide ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-6 form-group-spacing">
            <Textbox
              type="text"
              label="Field Name"
              name="fieldName"
              value={this.state.data.fieldName}
              handleChange={this.handleChange}
              maxLength={200}
              required={this.state.testTypeSetting.fieldNameRequired}
            />
          </div>
        )}

        <div className="col-md-12 col-lg-6 form-group-spacing">
          <SelectControlGrower
            handleChangeSelectControl={this.handleChangeSelectControl}
            onRef={(ref) => (this.growerControl = ref)}
            placeholder="Grower Name"
            label="Grower Name"
            popupTitle="Grower"
            controlId="growerName"
            value={this.state.growerName}
            required
            maxLength={50}
            processorId={this.state.data.processorId}
            urlapi={GlobalConfig.REACT_APP_API_GROWER_URL}
            urlapilist={
              GlobalConfig.REACT_APP_API_GROWER_URL +
              "simpleGrowersByProcessorAndTestType/" +
              this.state.data.processorId +
              "/" +
              this.state.nutTypeId
            }
          />
        </div>
        <div className="col-md-12 col-lg-6 form-group-spacing">
          <div className="form-group">
            <label
              className="col-md-4 control-label mt-xs pt-none"
              htmlFor="varitySubmitted"
            >
              Variety Submitted <span className="required">(*)</span>
            </label>
            <div className="col-md-8">
              <SelectWalnutVarieties
                required={true}
                onRef={(ref) => (this.varitySubmitted = ref)}
                name="varitySubmitted"
                controlId="varitySubmitted"
                selectedIds={this.state.data.varitySubmitted}
                handleChangeSelectControl={this.handleChangeSelectControl}
                urlApi={
                  GlobalConfig.REACT_APP_API_VARIETY_URL +
                  "byParentId/" +
                  this.state.nutTypeId
                }
              />
            </div>
          </div>
        </div>

        {this.state.testTypeSetting.typeNumberOfContainersHide ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-6 form-group-spacing">
            <div className="form-group">
              <label className="col-md-4 control-label">
                Type of Containers <span className="required">(*)</span>
              </label>
              <div className="col-md-8">
                <TypeContainer
                  handleChangeSelectTypeContainers={
                    this.handleChangeSelectTypeContainers
                  }
                  typeNumberOfContainers={
                    this.state.data.typeNumberOfContainers
                  }
                  numberOfContainers={this.state.data.numberOfContainers}
                  handleChangeNumberContainers={
                    this.handleChangeNumberContainers
                  }
                  handleFocus={this.handleFocus}
                  isAllowEdit={this.state.isAllowEdit}
                  processorId={loggedUser.processorId}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-md-12 col-lg-6 form-group-spacing">
          <NumericInput
            precision={1}
            min={0}
            max={999999999}
            step={1}
            label={
              this.state.testTypeSetting.xpool === true
                ? "Process Weight"
                : "Net Weight"
            }
            name="netWeight"
            value={this.state.data.netWeight}
            handleChange={this.handleChange}
            required={true}
            labelrequired="(*)"
            unit={this.state.testTypeSetting.netWeightUnit}
          />
        </div>

        {this.state.testTypeSetting.dfaInspectorHide ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-6 form-group-spacing">
            <SelectControl
              handleChangeSelectControl={this.handleChangeSelectControl}
              onRef={(ref) => (this.dfaInspectorControl = ref)}
              placeholder="Inspector/Tested By"
              label="Inspector/Tested By"
              popupTitle="Inspector/Tested By"
              controlId="dfaInspector"
              value={this.state.dfaInspector}
              required={this.state.testTypeSetting.dfaInspectorRequired}
              maxLength={40}
              processorId={this.state.data.processorId}
              urlapi={GlobalConfig.REACT_APP_API_LABUSER_URL}
              urlapilist={
                GlobalConfig.REACT_APP_API_LABUSER_URL +
                "simplebyprocessor/" +
                this.state.data.processorId
              }
              isHideAddButton={true}
              textField="displayName"
              readOnly={loggedUser.role === "Lab Users"}
            />
          </div>
        )}

        {this.state.testTypeSetting.photoHide ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-6 p-none form-group-spacing">
            <label
              className="col-md-4 control-label mt-xs pt-none"
              htmlFor="photo"
            >
              Take Photo{" "}
              {this.state.testTypeSetting.photoRequired ? (
                <span className="required">(*)</span>
              ) : (
                ""
              )}
            </label>
            <div className="col-md-8">
              <div
                className="fileupload fileupload-new"
                data-provides="fileupload"
              >
                <div className="input-append input-take-photo-container">
                  <div className="uneditable-input input-take-photo-size input-take-photo-border">
                    <i className="fa fa-file fileupload-exists"></i>
                    <span className="fileupload-preview"></span>
                  </div>
                  <span className="btn btn-default btn-file m-none btn-take-photo-border">
                    <span className="fileupload-exists" title="Change">
                      <i className="fa fa-camera fa-lg" aria-hidden="true"></i>
                    </span>
                    <span className="fileupload-new">
                      <i className="fa fa-camera fa-lg" aria-hidden="true"></i>
                    </span>
                    <input
                      multiple
                      type="file"
                      name="photo"
                      onChange={this.handleChange4Image}
                    />
                  </span>
                </div>
              </div>
              <div className="row">
                {this.state.images &&
                this.state.images &&
                this.state.images.length > 0 ? (
                  <div className="col-md-12 mt-xs">
                    <div className="table-responsive">
                      <table className="table mb-none">
                        <tbody>
                          {this.state.images.map((u, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    title="View image"
                                    onClick={() => {
                                      this.handleOpenModalImage(u);
                                    }}
                                  >
                                    {u.name}
                                  </a>
                                </td>
                                <td>{u.size}</td>
                                <td>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    title="View image"
                                    onClick={() => {
                                      this.handleOpenModalImage(u);
                                    }}
                                  >
                                    <span>
                                      <i
                                        className="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a
                                    style={{ cursor: "pointer" }}
                                    title="Remove image"
                                    onClick={() => {
                                      this.handleRemoveImage(u);
                                    }}
                                  >
                                    <span>
                                      <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.testTypeSetting.overridePriceHide === true ? (
          ""
        ) : (
          <div className="col-md-12 col-lg-6 form-group-spacing">
            <NumericInput
              precision={2}
              min={0}
              max={999999999}
              step={1}
              label="Override Price"
              name="overridePrice"
              value={this.state.data.overridePrice}
              handleChange={this.handleChange}
              note="Override Price takes precedent over the Base Price and ignores any Adjustments (bonuses or deducts). Taxes and fees are still applied."
            />
          </div>
        )}
        {this.state.testTypeSetting.xpool !== true ? (
          ""
        ) : (
          <>
            <div className="col-md-12 col-lg-6 form-group-spacing">
              <NumericInput
                precision={2}
                min={0}
                max={100}
                step={0.1}
                label="XPool Percent"
                name="xpoolPercent"
                value={this.state.data.xpoolPercent}
                handleChange={this.handleChange}
                displayRequired={true}
                unit="%"
              />
            </div>
            <div className="col-md-12 col-lg-6 form-group-spacing">
              <NumericInput
                precision={2}
                min={0}
                max={100}
                step={0.1}
                label="Regular Percent"
                name="regularPercent"
                value={this.state.data.regularPercent}
                handleChange={this.handleChange}
                displayRequired={true}
                unit="%"
                readOnly={true}
              />
            </div>
            <div className="col-md-12 col-lg-6 form-group-spacing">
              <NumericInput
                precision={1}
                min={0}
                max={999999999}
                step={1}
                label="XPool Weight"
                name="xpoolWeight"
                value={this.state.data.xpoolWeight}
                handleChange={this.handleChange}
                displayRequired={true}
                unit={this.state.testTypeSetting.netWeightUnit}
              />
            </div>
            <div className="col-md-12 col-lg-6 form-group-spacing">
              <NumericInput
                precision={1}
                min={0}
                max={999999999}
                step={1}
                label="Regular Weight"
                name="regularWeight"
                value={this.state.data.regularWeight}
                handleChange={this.handleChange}
                displayRequired={true}
                readOnly={true}
                unit={this.state.testTypeSetting.netWeightUnit}
              />
            </div>
          </>
        )}
      </>
    );
  }

  handleGroupSelectedField = (array) => {
    const grouped = _.groupBy(array, (arrayItem) =>
      arrayItem.nutTypeGroupId !== ""
        ? arrayItem.nutTypeGroupId
        : arrayItem.section
    );

    return grouped;
  };

  renderFields = (section, groupId) => {
    if (
      this.state.testTypeSetting.allFields &&
      this.state.testTypeSetting.allFields.length > 0
    ) {
      const combineFields = CommonUtilities.handleCombineFields(
        this.state.testTypeSetting.selectedFields,
        this.state.testTypeSetting.allFields
      );
      const selectedFields = this.handleGroupSelectedField(combineFields);
      return selectedFields?.[groupId ? groupId : section]?.map((x, index) => {
        if (groupId === `` || groupId === undefined || groupId === null) {
          return (
            <div className="col-md-12 col-lg-6 form-group-spacing" key={index}>
              {x.fieldType === FIELD_TYPE_DROP_DOWN
                ? this.renderFieldDropdown(x, this.state.data[x.testFieldId])
                : renderFieldByType(
                    x,
                    this.state.data[x.testFieldId],
                    loggedUser,
                    GlobalConfig,
                    this.handleChange,
                    this.handleChangeSelectControl
                  )}
            </div>
          );
        }
        return (
          <React.Fragment key={index}>
            {x.fieldType === FIELD_TYPE_DROP_DOWN
              ? this.renderFieldDropdown(x, this.state.data[x.testFieldId])
              : renderFieldByType(
                  x,
                  this.state.data[x.testFieldId],
                  loggedUser,
                  GlobalConfig,
                  this.handleChange,
                  this.handleChangeSelectControl
                )}
          </React.Fragment>
        );
      });
    }
    return <></>;
  };

  renderFieldDropdown = (field, defaultValue) => {
    const url = `${GlobalConfig.REACT_APP_API_CLASSIFICATION_ITEM_URL}parent/${field.testClassificationId}`;
    return (
      <SelectControl
        handleChangeSelectControl={this.handleChangeSelectControl}
        onRef={(ref) => (this[field.testFieldId] = ref)}
        placeholder={field.name}
        label={field.name}
        popupTitle={field.name}
        controlId={field.testFieldId}
        value={defaultValue}
        required={field.isMandatory}
        maxLength={200}
        processorId={loggedUser.processorId}
        urlapi={url}
        urlapilist={url}
        isHideAddButton={true}
        textField="name"
      />
    );
  };

  checkQualityMultiTests = (section) => {
    const { multiTestsPerLot } = this.state.testTypeSetting;
    const isQualityDataSection = section === "Quality Data";
    return multiTestsPerLot && isQualityDataSection;
  };

  formatSectionForCollapse = (section) => {
    const formatSection = section.toLowerCase().replaceAll(" ", "");
    return formatSection;
  };

  renderSections() {
    const sections = [SECTION_META_DATA, SECTION_QUALITY_DATA];
    return sections.map((section, index) => {
      const groups = this.state.testTypeSetting
        ? section === SECTION_META_DATA
          ? this.state.testTypeSetting.groupMetaData
          : this.state.testTypeSetting.groupQualityData
        : [];
      return (
        <React.Fragment key={index}>
          <hr className="dotted short"></hr>
          <div className="col-md-12 mt-sm">
            <div className="row">
              <section
                className={
                  section === SECTION_META_DATA
                    ? "panel-warning"
                    : "panel-primary"
                }
              >
                <header className="panel-heading-custom panel-nut-type panel-heading">
                  <h2 className="panel-title panel-title-main">{section}</h2>
                  <a
                    data-toggle="collapse"
                    href={`#header-${this.formatSectionForCollapse(section)}`}
                    aria-expanded="true"
                    className="icon-collapse"
                  >
                    <i className="fa fa-chevron-up fa-lg"></i>
                    <i className="fa fa-chevron-down fa-lg"></i>
                  </a>
                </header>
                <div
                  id={`header-${this.formatSectionForCollapse(section)}`}
                  className="panel-collapse collapse in"
                >
                  {/* if quality data section and multi tests per lot is true, then render fields with table layout */}
                  {this.checkQualityMultiTests(section) && (
                    <div className="multi-quality-data-border">
                      <MultiQualityData
                        calculatedFields={this.state.calculatedFields}
                        numberFields={this.state.numberFields}
                        testTypeSetting={this.state.testTypeSetting}
                        qualityData={this.state.qualityData}
                        section={section}
                        setQualityData={this.setState.bind(this)}
                        classificationItems={this.state.classificationItems}
                        loggedUser={loggedUser}
                      />
                    </div>
                  )}
                  {!this.checkQualityMultiTests(section) && (
                    <div
                      className={`${
                        section === SECTION_META_DATA
                          ? "panel-body-warning"
                          : "panel-body-primary"
                      } panel-body panel-body-spacing`}
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset> {this.renderFields(section, ``)}</fieldset>
                        </div>
                      </div>

                      <div className="panel-group-container">
                        {groups &&
                          groups.length > 0 &&
                          groups.map((g, index) => {
                            return (
                              <div
                                className={`${g.size} panel-group-item`}
                                key={index}
                              >
                                <div className="row panel-group-item-spacing panel-height-body">
                                  <section
                                    className="panel-custom panel-height-body"
                                    style={{
                                      background: g.color,
                                    }}
                                  >
                                    <header
                                      className="panel-custom panel-heading-custom panel-nut-type"
                                      style={{
                                        background: g.color,
                                      }}
                                    >
                                      <h2 className="panel-title panel-title-custom">
                                        {g.name}
                                      </h2>
                                    </header>
                                    <div
                                      className="panel-body panel-body-custom"
                                      style={{
                                        boxShadow:
                                          "inset 0px 0px 0px 1px" +
                                          g.color +
                                          "52",
                                      }}
                                    >
                                      <fieldset>
                                        <div>
                                          {this.renderFields(section, g._id)}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  render() {
    const title = `Create new ${this.getTitle()}`;

    return loggedUser.role === "Administrators" ? (
      <PageInvalidAccess />
    ) : (
      <>
        <Modal
          show={this.state.showImage}
          onHide={this.handleCloseModalImage}
          animation={false}
          backdropClassName="modal-backdrop foo-modal-backdrop in"
          centered
        >
          <Modal.Header style={{ border: "none" }}></Modal.Header>
          <Modal.Body
            style={{
              marginLeft: "0px",
              marginBottom: "-10px",
              marginTop: "-25px",
              paddingLeft: "5px",
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
          >
            {this.state.image && this.state.image !== "" ? (
              <img
                src={
                  this.state.image.indexOf("data:image") === -1
                    ? process.env.REACT_APP_API_URL + this.state.image
                    : this.state.image
                }
                alt="No image"
                style={{
                  maxWidth: "568px",
                  maxHeight: "400px",
                  minWidth: "568px",
                  minHeight: "400px",
                }}
              ></img>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              {this.state.images && this.state.images.length > 1 ? (
                <Button
                  variant="info"
                  onClick={() => this.handleMoveImage(this.state.index - 1)}
                  disabled={!this.state.index > 0}
                  title="Previous photo"
                >
                  <span className="fa fa-angle-left fa-lg"></span>
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="warning"
                onClick={this.handleCloseModalImage}
                title="Close"
              >
                <span className="fa fa-times fa-lg"></span> Close
              </Button>
              {this.state.images && this.state.images.length > 1 ? (
                <Button
                  variant="info"
                  onClick={() => this.handleMoveImage(this.state.index + 1)}
                  disabled={!(this.state.index < this.state.images.length - 1)}
                  title="Next photo"
                >
                  <span className="fa fa-angle-right fa-lg"></span>
                </Button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Helmet>
          <title>{title + " | " + process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
        <section className="body">
          <Header></Header>
          <div className="inner-wrapper">
            <Navigation
              navRoute={this.props.navRoute}
              location={this.props.location}
            ></Navigation>

            <section role="main" className="content-body">
              <header className="page-header">
                <h2>{title}</h2>
                <div className="right-wrapper pull-right">
                  <ol className="breadcrumbs">
                    <li>
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.state.urllist}>{this.state.listtitle}</a>
                    </li>
                  </ol>

                  <a className="sidebar-right-toggle" data-open="sidebar-right">
                    <i className="fa fa-chevron-left"></i>
                  </a>
                </div>
              </header>
              <div className="row">
                <div className="col-md-12">
                  {loggedUser.isInvalidProcessor ||
                  (this.state.useExtendBody != null &&
                    this.state.useExtendBody !== undefined &&
                    this.state.useExtendBody === true) ? (
                    ""
                  ) : (
                    <form
                      className="form-horizontal"
                      onSubmit={this.handleSubmit}
                      id="form"
                    >
                      <section className="panel">
                        {loggedUser.role === "Lab Users" ? (
                          <header className="panel-heading">
                            <p className="panel-subtitle">
                              Entering Test Data for:{" "}
                              <strong>{this.state.processorName}</strong>
                            </p>
                          </header>
                        ) : (
                          ""
                        )}
                        <div className="panel-body panel-body-spacing">
                          <fieldset>{this.renderHeader()}</fieldset>
                          {this.renderSections()}
                        </div>
                        <footer className="panel-footer">
                          <div className="row">
                            <div className="col-sm-12 text-center">
                              <a
                                href={this.state.urllist}
                                title="Back to list"
                                className="btn btn-primary"
                              >
                                <i className="fa fa-backward fa-lg"></i> Back
                              </a>

                              <SaveButton
                                form={"form"}
                                loading={this.state.loading}
                                onClick={this.handleNormalSubmit}
                              />
                            </div>
                          </div>
                        </footer>
                      </section>
                    </form>
                  )}
                  {this.state.alertshow === true ? (
                    <Alert
                      message={this.state.alertmsg}
                      type={this.state.alerttype}
                      show={this.state.alertshow}
                    ></Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}
export default App;
