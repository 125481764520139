import React from "react";
import { getLoggedUser } from "../context/auth";
import NavigationAdmin from "./NavigationAdmin";
import NavigationGrower from "./NavigationGrower";
import NavigationProcessor from "./NavigationProcessor";
import NavigationLab from "./NavigationLab";
import NavigationStaff from "./NavigationStaff";
import CommonUtilities from "./Utilities/Common";
import { connect } from "react-redux";
import { setTestTypes } from "./../redux/actions/testTypeActions";
import { setTestTypesLoading } from "./../redux/actions/loadingActions";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

class App extends React.Component {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();

    const temp = CommonUtilities.extractGrowerInfo(loggedUser);
    let processorId = temp.processorId;
    let viewingGrowerId = temp.viewingGrowerId;

    this.state = {
      processorId: processorId,
      viewingGrowerId: viewingGrowerId,
    };
  }

  fetchMenuItemNavigation() {
    const that = this;
    const apiUrl =
      loggedUser.role === "Administrators"
        ? `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}allPublishedTestTypes`
        : `${GlobalConfig.REACT_APP_API_NUTTYPE_URL}allPublishedTestTypesByProcessor/${this.state.processorId}`;
    that.props.setTestTypesLoading(true);
    fetch(apiUrl)
      .then(function (response) {
        return response.json();
      })
      .then(function (resultObject) {
        if (resultObject.result === "OK") {
          let menuItemTestTypes = resultObject.data;
          if (
            loggedUser.titerInfo &&
            loggedUser.titerInfo.isAllowDynamicTest !== true
          ) {
            //If not dynamic exclude dynamic test types
            if (menuItemTestTypes && menuItemTestTypes.length > 0) {
              menuItemTestTypes = menuItemTestTypes.filter((x) => {
                return x.isDynamic !== true;
              });
            }
          }
          that.props.setTestTypesLoading(false);
          that.props.setTestTypes(menuItemTestTypes); // Save to redux
        }
      })
      .catch(function (error) {
        that.setState({
          alertshow: false,
        });
        that.setState({
          alertmsg: error,
          alerttype: "error",
          alertshow: true,
        });
        that.props.setTestTypesLoading(false);
      });
  }

  async componentDidMount() {
    if (
      !this.props.testTypes ||
      !this.props.testTypes.length ||
      !this.props.testTypes.length > 0
    ) {
      this.fetchMenuItemNavigation();
    }
  }

  renderSwitch() {
    switch (loggedUser.role) {
      case "Administrators":
        return (
          <NavigationAdmin
            navRoute={this.props.navRoute}
            menuItemTestTypes={this.props.testTypes}
          ></NavigationAdmin>
        );
      case "Lab Users":
        return (
          <NavigationLab
            navRoute={this.props.navRoute}
            menuItemTestTypes={this.props.testTypes}
          ></NavigationLab>
        );
      case "Processors":
        return (
          <NavigationProcessor
            navRoute={this.props.navRoute}
            menuItemTestTypes={this.props.testTypes}
            loggedUser={loggedUser}
            GlobalConfig={GlobalConfig}
          ></NavigationProcessor>
        );
      case "Growers":
        return (
          <NavigationGrower
            navRoute={this.props.navRoute}
            menuItemTestTypes={this.props.testTypes}
            loggedUser={loggedUser}
            GlobalConfig={GlobalConfig}
          ></NavigationGrower>
        );
      case "Staffs":
        return (
          <NavigationStaff
            navRoute={this.props.navRoute}
            menuItemTestTypes={this.props.testTypes}
            loggedUser={loggedUser}
            GlobalConfig={GlobalConfig}
          ></NavigationStaff>
        );
      default:
        return "";
    }
  }

  render() {
    return (
      <aside id="sidebar-left" className="sidebar-left">
        <div className="sidebar-header">
          <div className="sidebar-title" style={{ color: "#abb4be" }}>
            Navigation
          </div>
          <div
            className="sidebar-toggle hidden-xs"
            data-toggle-class="sidebar-left-collapsed"
            data-target="html"
            data-fire-event="sidebar-left-toggle"
          >
            <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
          </div>
        </div>

        <div className="nano">
          <div className="nano-content">
            <nav id="menu" className="nav-main" role="navigation">
              {this.renderSwitch()}
            </nav>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testTypes: state.testTypes,
  };
};

export default connect(mapStateToProps, { setTestTypes, setTestTypesLoading })(
  App
);
