import React from "react";
import ListComponent from "../Base/ListComponent";
import { getLoggedUser } from "../../context/auth";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import CommonUtilities from "../Utilities/Common";
import FilterMultiSelectControl from "../FormControl/FilterMultiSelectControl";
import SaveButton from "../Controls/SaveButton";

let loggedUser = {};
const GlobalConfig = new window.globalConfig();

const defaultOptions = [
  { _id: "Active", name: "Active" },
  { _id: "Inactive", name: "Inactive" },
];
class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      this.state.urlapi =
        GlobalConfig.REACT_APP_API_LABUSER_URL +
        "byprocessor/" +
        loggedUser.processorId;
      this.state.urlapidelete =
        GlobalConfig.REACT_APP_API_LABUSER_URL + "byprocessor/";
    } else {
      this.state.urlapi = GlobalConfig.REACT_APP_API_LABUSER_URL;
      this.state.urlapidelete = GlobalConfig.REACT_APP_API_LABUSER_URL;
    }
    this.state.urllist = process.env.REACT_APP_URL_LAB_USER_LIST;
    this.state.title = "Lab User";
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleInviteLabUser = this.handleInviteLabUser.bind(this);

    this.state.errorMessage = "";
    this.state.invitedEmailLabUser = "";
    this.state.startInvite = "";

    this.state.pageName = "Lab User";
    this.state.filterStatus = "";
    this.state.filterCompanyName = "";
    this.state.filterName = "";
    this.state.filterEmail = "";
    this.state.childOverrideComponentDidMount = true;
    this.state.loading = false;

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeSelectControl = this.handleChangeSelectControl.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.filterItems = this.filterItems.bind(this);
  }

  setStateFromQueryString = () => {
    const query = new URLSearchParams(this.props.location.search);
    const startInvite = query.get("startInvite");
    if (startInvite) {
      this.handleShow();
      this.setState({
        startInvite: "true",
      });
    }
  };

  async componentDidMountChild() {
    const that = this;
    if (loggedUser.role === "Processors" || loggedUser.role === "Staffs") {
      fetch(GlobalConfig.REACT_APP_API_PROCESSOR_URL + loggedUser.processorId)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject != null &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            let isValid = true;
            if (
              resultObject.data.certificatePrefix == null ||
              resultObject.data.certificatePrefix === undefined ||
              resultObject.data.certificatePrefix === "" ||
              resultObject.data.certificateSeed == null ||
              resultObject.data.certificateSeed === undefined
            ) {
              isValid = false;
            }
            if (isValid) {
              that.getItems();
              that.loadColumnSettings();
            } else {
              that.setState({ useExtendBody: true });
            }
          } else {
            window.showAlert("Error", resultObject.message, "error");
          }
        })
        .catch(function (error) {
          window.showAlert("Error", error, "error");
        });
    } else {
      this.getItems();
      this.loadColumnSettings();
      return;
    }
  }

  doSomethingAfterGetItems = () => {
    this.setStateFromQueryString();
  };

  handleShow = () => {
    this.setState({
      show: true,
      errorMessage: "",
      invitedEmailLabUser: "",
      startInvite: "false",
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleInviteLabUser = async (event) => {
    event.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const invitedEmailLabUser = this.state.invitedEmailLabUser;
    if (
      invitedEmailLabUser == null ||
      invitedEmailLabUser === undefined ||
      invitedEmailLabUser.trim() === ""
    ) {
      this.setState({ errorMessage: "Please enter email then try again." });
    } else {
      const isValidEmail = CommonUtilities.isValidEmail(invitedEmailLabUser);
      if (isValidEmail && isValidEmail !== "") {
        this.setState({ errorMessage: isValidEmail });
      } else {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            invitedEmailLabUser: invitedEmailLabUser,
            processorId: loggedUser.processorId,
            createdUserId: loggedUser._id,
            baseUrl: process.env.REACT_APP_BASE_URL,
            fromDisplayName: process.env.REACT_APP_DISPLAYNAMESENDEREMAIL,
          }),
        };
        const that = this;
        that.setState({ loading: true }, () => {
          fetch(
            GlobalConfig.REACT_APP_API_PROCESSOR_URL + "invitelabuser",
            requestOptions
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (resultObject) {
              if (resultObject && resultObject.result === "OK") {
                window.showAlert("Success", "Invite email sent lab user.", "");
                that.getItems();
                that.setState({ show: false, loading: false });
              } else {
                window.showAlert("Error", resultObject.message, "error");
                that.setState({
                  errorMessage: resultObject.message,
                  loading: false,
                });
              }
            })
            .catch(function (error) {
              window.showAlert("Error", error, "error");
              that.setState({
                errorMessage: error,
                loading: false,
              });
            });
        });
      }
    }
  };

  filterItems = async () => {
    const { filterStatus, filterCompanyName, filterName, filterEmail } =
      this.state;
    const { processorId } = loggedUser;
    const isProcessorOrStaff =
      loggedUser.role === "Processors" || loggedUser.role === "Staffs";

    const urlapi = isProcessorOrStaff
      ? `${GlobalConfig.REACT_APP_API_LABUSER_URL}byprocessor/${processorId}?name=${filterName}&email=${filterEmail}&companyName=${filterCompanyName}&status=${filterStatus}`
      : `${GlobalConfig.REACT_APP_API_LABUSER_URL}?name=${filterName}&email=${filterEmail}&companyName=${filterCompanyName}&status=${filterStatus}`;

    this.setState({ urlapi }, () => this.getItems());
  };

  handleReset = () => {
    this.statusControl.reset();
    this.setState(
      {
        filterStatus: "",
        filterCompanyName: "",
        filterName: "",
        filterEmail: "",
      },
      () => this.filterItems()
    );
  };

  handleChangeSelectControl = (controlId, value) => {
    this.setState({
      [controlId]: value,
    });
  };

  handleChangeTextField = (event) => {
    const { value, name } = event.target;
    if (!!name) {
      this.setState({
        [name]: value,
      });
    }
  };

  renderSearch = () => {
    return (
      <>
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterCompanyName"
                placeholder="Filter by company name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterCompanyName}
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterName"
                placeholder="Filter by name"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterName}
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <input
                type="text"
                className="form-control inputRenderSearch inputFilter"
                name="filterEmail"
                placeholder="Filter by email"
                onChange={this.handleChangeTextField}
                maxLength={100}
                value={this.state.filterEmail}
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-sm select-render-search">
              <FilterMultiSelectControl
                handleChangeSelectControl={this.handleChangeSelectControl}
                onRef={(ref) => (this.statusControl = ref)}
                placeholder="Filter by status"
                label="Select All"
                controlId="filterStatus"
                isUseDefaultData
                defaultData={defaultOptions}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  defaultButtons = () => {
    return loggedUser.role === "Processors" || loggedUser.role === "Staffs" ? (
      <a
        href="#"
        className="btn btn-primary panel-btn-group-item-position"
        onClick={this.handleShow}
      >
        <i className="fa fa-plus"></i> Invite User
      </a>
    ) : (
      ""
    );
  };

  extendBody = () => {
    return (
      <div className="alert alert-success">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        Please fill out{" "}
        <strong>Certification Prefix and Certification Seed No</strong>. It is
        required data for invite new lab users. Click{" "}
        <a href={process.env.REACT_APP_URL_MY_PROFILE} className="alert-link">
          here
        </a>{" "}
        to update profile.
      </div>
    );
  };

  handleChangeInvitedEmailLabUser = (event) => {
    this.setState({ invitedEmailLabUser: event.target.value });
  };

  extendRender = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        animation={false}
        backdropClassName="modal-backdrop foo-modal-backdrop in"
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Invite New Lab User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Enter Email
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  autoFocus={true}
                  type="email"
                  onChange={this.handleChangeInvitedEmailLabUser}
                  value={this.state.invitedEmailLabUser}
                  name="invitedEmailLabUser"
                  maxLength="100"
                  required={true}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2"></Form.Label>
              {this.state.errorMessage == null ||
                this.state.errorMessage === "" ||
                this.state.errorMessage === undefined ? (
                ""
              ) : (
                <Form.Label column sm="10" style={{ color: "red" }}>
                  {this.state.errorMessage}
                </Form.Label>
              )}
            </Form.Group>
            {this.state.startInvite == "true" ? (
              <>
                <hr className="dotted short"></hr>
                <div className="mb-lg">
                  Adding a Lab or DFA user will allow that person to create new
                  tests and enter information for your company. You can create
                  one now to get started or you can return to this process
                  later.
                </div>
              </>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <SaveButton
              text={"Invite User"}
              type={"button"}
              icon={
                <span
                  className="fa fa-send fa-lg"
                  style={{ marginRight: 2.5 }}
                ></span>
              }
              loading={this.state.loading}
              onClick={this.handleInviteLabUser}
            />
            <Button variant="warning" onClick={this.handleClose}>
              <span className="fa fa-ban fa-lg"></span> Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();

    //this.state.hideColumns; >> get from API first load
    defaultCols.map((x) => {
      x.omit =
        this.state.hideColumns.indexOf(x.selector) !== -1 || x.omit === true;
    });

    return defaultCols;
  };

  defaultColumns = () => {
    return [
      {
        name: "Company Name",
        selector: "companyName",
        sortable: true,
        left: true,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => {
          return loggedUser.referenceUserId === row._id ? (
            <a href={process.env.REACT_APP_URL_MY_PROFILE} title={row.name}>
              {row.name}
            </a>
          ) : (
            <a href={this.state.urllist + "/" + row._id} title={row.name}>
              {row.name}
            </a>
          );
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        left: true,
      },
      {
        name: "Phone",
        selector: "phone",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: (row) => {
          return row.status === "Active" ? (
            <span
              className="label label-success"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          ) : (
            <span
              className="label label-default"
              style={{ paddingTop: "5px", paddingBottom: "7px", width: "55px" }}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        name: "",
        center: true,
        cell: (row) => (
          <>
            {loggedUser.referenceUserId === row._id ? (
              <a
                href={process.env.REACT_APP_URL_MY_PROFILE}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            ) : (
              <a
                href={this.state.urllist + "/" + row._id}
                className="on-default edit-row"
              >
                <i className="fa fa-pencil-square-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            {loggedUser._id != row.createdUserId ||
              loggedUser.role === "Administrators" ? (
              ""
            ) : (
              <a
                title="Click here to remove"
                href="#"
                className="on-default remove-row"
                data-id={row._id}
                onClick={this.handleDelete.bind(this)}
              >
                <i className="fa fa-trash-o fa-lg"></i>
              </a>
            )}
            &nbsp;&nbsp;
            <a
              target="_blank"
              title="Revision history"
              href={
                process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                "/object/" +
                row._id
              }
              className="on-default remove-row"
            >
              <i className="fa fa-history fa-lg"></i>
            </a>
          </>
        ),
      },
    ];
  };
}
export default App;
