import React from "react";
import Select from "react-select";
import { isArray } from "lodash";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isSetCheckedFromSource: props.isSetCheckedFromSource,
      isUseDefaultData: props.isUseDefaultData,
      defaultData: props.defaultData,
      controlId: props.controlId,
      urlapilist: props.urlapilist,
      label: props.label,
      value: props.value,
      name: "",
      data: [],
      errorMessage: "",
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.getItems = this.getItems.bind(this);
    this.setDefaultData = this.setDefaultData.bind(this);
  }

  async componentDidMount() {
    //using for parent call method setSelected outside
    this.props.onRef(this);

    //query all items in module and render as checkbox list
    this.getItems();

    // set default value if isUseDefaultData is true and has defaultData
    this.setDefaultData();
  }

  componentWillUnmount() {
    //using for parent call method setSelected outside
    this.props.onRef(undefined);
  }

  getItems = async () => {
    try {
      if (
        this.state.urlapilist === undefined ||
        this.state.urlapilist == null ||
        this.state.urlapilist === ""
      ) {
        return;
      }
      const defaultItem = { value: "", name: this.state.label };
      const that = this;
      fetch(this.state.urlapilist)
        .then(function (response) {
          return response.json();
        })
        .then(function (resultObject) {
          if (
            resultObject &&
            resultObject.result === "OK" &&
            resultObject.data
          ) {
            const parsedItems = [];
            let selectedValue = "";
            let selectedName = "";
            if (resultObject.data) {
              if (that.state.isSetCheckedFromSource === true) {
                resultObject.data.map((item) => {
                  if (item.checked === true) {
                    selectedValue = item._id;
                    selectedName = item.name;
                  }
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked: item.checked,
                  });
                });
              } else {
                resultObject.data.map((item) => {
                  parsedItems.push({
                    _id: item._id,
                    name: item.name,
                    checked:
                      that.state.value == null ||
                        that.state.value === undefined ||
                        that.state.value.indexOf(item._id) === -1
                        ? false
                        : true,
                  });
                });
              }
            }

            that.setState(
              {
                data: [defaultItem].concat(parsedItems),
              },
              function callBack() {
                if (that.props.isSetCheckedFromSource === true) {
                  //fire event select item
                  that.setState({
                    value: selectedValue,
                    label: selectedName,
                  });
                  that.props.handleChangeSelectControl(
                    that.state.controlId,
                    selectedValue,
                    selectedName
                  );
                }
              }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  setDefaultData = () => {
    if (this.state.isUseDefaultData && isArray(this.state.defaultData)) {
      this.setState({
        data: this.state.defaultData,
      });
    }
  };

  handleChangeSelect(event) {
    const valueItem = event;

    this.setState({ value: valueItem.value, label: valueItem.label });

    this.props.handleChangeSelectControl(
      this.state.controlId,
      valueItem.value,
      valueItem.value
    );
  }

  setSelected(value) {
    this.setState({ value: value });
  }

  reload(urlapilist) {
    this.setState(
      {
        urlapilist: urlapilist,
      },
      () => {
        this.getItems();
      }
    );
  }

  render() {
    const getLabelData = this.state.data
      .filter((item) => item._id === this.state.value)
      .map((item) => item.name)
      .toString();

    return (
      <>
        <Select
          name={this.state.controlId}
          onChange={this.handleChangeSelect}
          placeholder="test"
          value={{
            value: this.props.value,
            label:
              this.props.value === "" || this.props.value === undefined
                ? this.props.label
                : getLabelData,
          }}
          options={
            this.state.data
              ? this.state.data.map((item) => {
                return {
                  value: item._id,
                  label: item.name,
                };
              })
              : ""
          }
        />

        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
          }}
          onChange={() => { }}
          value={this.state.value ? this.state.value : ""}
          required={this.props.required ? this.props.required : false}
        />
      </>
    );
  }
}
export default App;
