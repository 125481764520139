import React from "react";
import ListComponent from "../Base/ListComponent";
import String from "../Utilities/String";
import MultiSelect from "../FormControl/MultiSelect";
import { VALUE_QUERY_PARAM_IGNORE } from "../Utilities/Constant";
import { getLoggedUser } from "./../../context/auth";
import { USER_ROLE_ADMINISTRATOR } from "./../Utilities/Constant";
import lodash from "lodash";
import DataTable from "react-data-table-component";
import TableProgressComponent from "../Base/TableProgressComponent";
import ChildTable from "./ChildTable";
import { connect } from "react-redux";
import {
  setHelpCategories,
  removeHelpCategory,
} from "./../../redux/actions/helpActions";
import { setHelpCategoriesLoading } from "../../redux/actions/loadingActions";

const GlobalConfig = new window.globalConfig();
let loggedUser = {};

class App extends ListComponent {
  constructor(props) {
    super();
    loggedUser = getLoggedUser();
    this.state.urlapi = process.env.REACT_APP_API_HELP_URL;
    this.state.urlapidelete = process.env.REACT_APP_API_HELP_URL;
    this.state.urlapicategory = "";
    this.state.urlapisubcategory = "";
    this.state.title = "Help";
    this.state.pageName = "Help";
    this.state.filterTitleAndContent = "";
    this.state.helpGroups = [];
    this.state.filterCategories = String.isEmpty(props.match.params.category)
      ? ""
      : props.match.params.category;
    // this.state.filterSubCategories = "";
    this.state.filterPages = "";
    this.state.userSettingUrlApi = GlobalConfig.REACT_APP_API_USERSETTING_URL;
    this.state.pageName = "Help";
    this.state.categoryType = "Help Category";
    // this.state.subCategoryType = "Help Subcategory";
    this.state.pageType = "Help Page";
    this.state.isExpand = true;
  }

  async componentDidMount() {
    if (this.state.isServerPaging === true) {
      this.getItemsServerPaging(this.state.currentPage);
    } else {
      if (String.isEmpty(this.props.match.params.category)) {
        this.getItems();
      } else {
        this.filterItems();
      }
    }
    this.loadColumnSettings();
    //If help categories in redux persist (local storage is empty) then invoke api
    if (
      !this.props.categories ||
      !this.props.categories.length ||
      !this.props.categories.length > 0
    ) {
      this.fetchHelpCategories();
    }
  }

  fetchHelpCategories = () => {
    this.props.setHelpCategoriesLoading(true);
    fetch(GlobalConfig.REACT_APP_API_HELP_URL + "/categories")
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.result === "OK") {
          this.props.setHelpCategories(responseData.data);
          this.props.setHelpCategoriesLoading(false);
        }
      })
      .catch((exception) => {
        this.props.setHelpCategoriesLoading(false);
      });
  };

  doSomethingAfterGetItems = (responseData) => {
    this.setState({
      helpGroups: this.groupHelpsByCategory(responseData.data),
    });
  };

  defaultButtons = () => {
    if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
      return (
        <a
          className="panel-btn-group-item-position btn btn-primary"
          href={process.env.REACT_APP_URL_HELP_LIST + "-add"}
        >
          <i className="fa fa-plus fa-lg"></i> {this.state.labeladd}
        </a>
      );
    }
    return <></>;
  };

  defaultTable = () => {
    return this.state.urlapi === null || this.state.urlapi === undefined ? (
      ""
    ) : (
      <DataTable
        highlightOnHover
        striped={this.striped()}
        pointerOnHover={false}
        id="help-table"
        noHeader={true}
        noTableHead={true}
        columns={this.parentColumns()}
        data={this.state.helpGroups}
        className="table table-bordered table-striped mb-none"
        conditionalRowStyles={this.conditionalRowStyles()}
        progressPending={this.state.progressPending}
        progressComponent={this.defaultProgressComponent()}
        expandableRows={true}
        expandableRowsComponent={
          <ChildTable
            columns={this.defaultColumns}
            isAdmin={loggedUser.role === USER_ROLE_ADMINISTRATOR}
          ></ChildTable>
        }
        expandableRowExpanded={(row) => {
          return this.state.isExpand;
        }}
      />
    );
  };

  defaultTitle = () => {
    return "Help Documentation";
  };

  defaultColumns = () => {
    return [
      {
        name: "Title",
        selector: "title",
        sortable: true,
        cell: (row) => {
          if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
            return (
              <a href={process.env.REACT_APP_URL_HELP_LIST + `/${row._id}`}>
                {row.title}
              </a>
            );
          } else {
            return (
              <a
                target="_blank"
                href={
                  process.env.REACT_APP_URL_HELP_LIST + `/content/${row._id}`
                }
              >
                {row.title}
              </a>
            );
          }
        },
      },
      {
        name: "Published",
        selector: "isPublished",
        sortable: true,
        center: true,
        cell: (row) => <>{row.isPublished === true ? "Yes" : "No"}</>,
        omit: loggedUser.role !== USER_ROLE_ADMINISTRATOR ? true : false,
      },
      {
        name: "",
        selector: "action",
        cell: (row) => {
          if (loggedUser.role === USER_ROLE_ADMINISTRATOR) {
            return (
              <>
                <a
                  href={process.env.REACT_APP_URL_HELP_LIST + `/${row._id}`}
                  title="Click here to edit"
                  className="on-default edit-row help-action"
                  style={{ cursor: "pointer", color: "#0099E6" }}
                >
                  <i className="fa fa-pencil-square-o fa-lg"></i>
                </a>
                &nbsp;&nbsp;
                <div
                  title="Click here to remove"
                  className="on-default remove-row help-action"
                  style={{ cursor: "pointer", color: "#0099E6" }}
                  data-id={row._id}
                  onClick={this.handleDelete.bind(this)}
                >
                  <i className="fa fa-trash-o fa-lg"></i>
                </div>
                &nbsp;&nbsp;
                <a
                  target="_blank"
                  title="Revision history"
                  href={
                    process.env.REACT_APP_URL_REVISIONHISTORY_LOG_LIST +
                    "/object/" +
                    row._id
                  }
                  className="on-default remove-row"
                >
                  <i className="fa fa-history fa-lg"></i>
                </a>
              </>
            );
          }
        },
      },
    ];
  };

  handleDeleteAfterDeletedOnServer = (removedHelpId) => {
    //update after remove a help
    const currentHelps = this.unGroupHelps([...this.state.helpGroups]);
    let removedCategory = "";
    const newHelps = currentHelps.filter((help) => {
      if (help._id === removedHelpId) {
        removedCategory = help.category;
      }
      return help._id !== removedHelpId;
    });
    const groupNewHelpsByCategory = this.groupHelpsByCategory(newHelps);
    this.setState({
      helpGroups: groupNewHelpsByCategory,
    });
    //Update left menu items (under Help Documentation)
    let categories = [...this.props.categories];
    const decreasedCategoryIndex = categories.findIndex(
      (category) => category.name === removedCategory
    );
    if (decreasedCategoryIndex === -1) {
      return;
    }
    if (categories[decreasedCategoryIndex].count) {
      if (categories[decreasedCategoryIndex].count > 1) {
        categories[decreasedCategoryIndex].count--;
      } else {
        this.props.removeHelpCategory(removedCategory);
      }
    } else {
      this.props.removeHelpCategory(removedCategory);
    }
    window.showAlert("", "Successfully Removed", "");
  };

  setColumns = () => {
    let defaultCols = this.defaultColumns();
    const cols = defaultCols.map((column, index) => {
      return {
        ...column,
        omit:
          this.state.hideColumns.includes(column.selector.toString()) ||
          column.omit === true,
      };
    });
    return cols;
  };

  onChangeFilterTitleAndContent = (e) => {
    this.setState({
      filterTitleAndContent: e.target.value,
    });
  };

  onChangeFilterCategories = (categories) => {
    let values;
    if (categories.length > 0) {
      values = categories.map((category) => category.value).join(",");
    } else {
      values = "";
    }
    this.setState({
      filterCategories: values,
    });
  };

  // onChangeFilterSubCategories = (subCategories) => {
  //   let values;
  //   if (subCategories.length > 0) {
  //     values = subCategories.map((category) => category.value).join(",");
  //   } else {
  //     values = "";
  //   }
  //   this.setState({
  //     filterSubCategories: values,
  //   });
  // };

  onChangeFilterPages = (pages) => {
    let values;
    if (pages.length > 0) {
      values = pages.map((page) => page.value).join(",");
    } else {
      values = "";
    }
    this.setState({
      filterPages: values,
    });
  };

  mapCategoriesToSelectOptions = (categories) => {
    let selectOptions = [];
    if (categories && categories.length > 0) {
      selectOptions = categories.map((category) => {
        return {
          label: category.name,
          value: category.name,
        };
      });
    }
    return selectOptions;
  };

  renderSearch = () => {
    return (
      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <input
              type="text"
              className="form-control inputRenderSearch inputFilter"
              placeholder="Search"
              value={this.state.filterTitleAndContent}
              onChange={this.onChangeFilterTitleAndContent}
              maxLength={100}
            />
          </div>
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <MultiSelect
              placeholder="Filter Category"
              closeMenuOnSelect={false}
              isMulti={true}
              value={this.state.filterCategories}
              onChange={this.onChangeFilterCategories}
              defaultOptions={this.mapCategoriesToSelectOptions(
                this.props.categories
              )}
            ></MultiSelect>
          </div>
          {/* <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <MultiSelect
              api={
                GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                `likename/` +
                this.state.subCategoryType
              }
              placeholder="Filter Sub Category"
              closeMenuOnSelect={false}
              isMulti={true}
              value={this.state.filterSubCategories}
              onChange={this.onChangeFilterSubCategories}
              parseObjectItem={this.parseObjectItem}
            ></MultiSelect>
          </div> */}
          <div className="col-md-6 col-lg-3 select-render-search mb-sm">
            <MultiSelect
              api={
                GlobalConfig.REACT_APP_API_SYSTEMCONFIG_URL +
                `helppages/` +
                this.state.pageType
              }
              placeholder="Filter Pages"
              closeMenuOnSelect={false}
              isMulti={true}
              value={this.state.filterPages}
              onChange={this.onChangeFilterPages}
              parseObjectItem={(data) => {
                return {
                  label: data.label,
                  value: data.value,
                };
              }}
            ></MultiSelect>
          </div>
        </div>
      </div>
    );
  };

  filterItems = async () => {
    let filterTitleAndContent = String.isEmpty(this.state.filterTitleAndContent)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterTitleAndContent);

    let filterCategories = String.isEmpty(this.state.filterCategories)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterCategories);

    let filterPages = String.isEmpty(this.state.filterPages)
      ? VALUE_QUERY_PARAM_IGNORE
      : encodeURIComponent(this.state.filterPages);

    const urlapi =
      process.env.REACT_APP_API_HELP_URL +
      filterTitleAndContent +
      "/" +
      filterCategories +
      "/" +
      filterPages;

    this.setState(
      {
        urlapi: urlapi,
      },
      () => this.getItems()
    );
  };

  groupHelpsByCategory = (helps) => {
    if (!helps || !helps.length || !helps.length > 0) {
      return [];
    } else {
      let groups = [];
      const groupByResult = lodash.groupBy(helps, "category");
      for (let category in groupByResult) {
        groups.push({
          category: category,
          children: groupByResult[category],
        });
      }
      return groups;
    }
  };

  unGroupHelps = (helpGroups) => {
    let helps = [];
    if (helpGroups) {
      helpGroups.forEach((group) => {
        if (group.children) {
          helps = helps.concat(group.children);
        }
      });
    }
    return helps;
  };

  parentColumns = () => {
    return [
      {
        name: "Category",
        cell: (row) => row.category,
      },
    ];
  };

  handleReset = () => {
    this.setState(
      {
        filterTitleAndContent: "",
        filterCategories: "",
        filterSubCategories: "",
        filterPages: "",
      },
      () => this.filterItems()
    );
  };
}

const mapStateToProps = (state) => {
  return {
    categories: state.helpCategories,
  };
};

export default connect(mapStateToProps, {
  removeHelpCategory,
  setHelpCategories,
  setHelpCategoriesLoading,
})(App);
