import axios from "axios";

export const recoveryAccount = async (email) => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_AUTH_URL}/recovery`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ userName: email }),
    });

    if (res.data.result === "OK") {
      return res.data.data;
    }

    const errorMessage = res.data.message;
    throw new Error(errorMessage);
  } catch (ex) {
    throw ex;
  }
};