import axios from "axios";

export const getMailTemplateByName = async (name) => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_MAIL_TEMPLATE_URL}/name/${name}`,
      method: "GET",
    });

    if (res.data.result === "OK") {
      return res.data.data;
    }

    const errorMessage = res.data.message;
    throw new Error(errorMessage);
  } catch (ex) {
    throw ex;
  }
};
